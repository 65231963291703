import {
  Button,
  HS,
  PL,
  PM,
  Flex,
  Box,
  styled,
  Tooltip,
} from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { useLayout } from '~/hooks/useLayout';
import type { Pie } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Container } from '~/toolbox/container';

import { InactiveSlicesBanner } from '../Banners';
import { EditorSaveButton } from '../Buttons';
import { EditorBreadcrumbs } from '../EditorBreadcrumbs';

const StyledEditorHeader = styled(Box)`
  box-shadow: none;
  position: relative;
  transition: none 0.2s ease-in-out;
  transition-property: background-color, border-bottom-color;
`;

const SecondaryHeaderContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  width: 100%;
`;

const HeaderContents = styled(Flex)<{ $contentWidth: number }>`
  align-items: center;
  height: 152px;
  justify-content: space-between;
  width: 100%;
  padding: 40px 80px;
`;

type EditorHeaderProps = {
  backButton?: React.ReactElement<typeof BackArrow>;
  onCancel: () => void;
  onContinue: () => void;
  pieHasChanges?: boolean;
  rootPie: Pie | null | undefined;
  subtitle?: string | null | undefined;
  title: string;
  tooltipText?: ReadonlyArray<string | null | undefined> | null | undefined;
};

export const EditorHeader = ({
  backButton,
  onCancel,
  onContinue,
  pieHasChanges,
  rootPie,
  subtitle,
  title,
  tooltipText,
}: EditorHeaderProps) => {
  const { contentWidth } = useLayout();

  const isRootPie = useSelector<boolean>((state) => {
    return state.newFlows.PORTFOLIO_EDITOR.path.length === 0;
  });

  return (
    <StyledEditorHeader>
      <SecondaryHeaderContainer>
        <Flex
          height={152}
          justifyContent="center"
          position="relative"
          width="100%"
        >
          <HeaderContents $contentWidth={contentWidth}>
            <Flex flexDirection="column">
              <HS color="foregroundNeutralOnDark" content={title} mr={16} />
              <Flex alignItems="center" mt={4}>
                {subtitle && (
                  <PL
                    color="foregroundNeutralOnDark"
                    content={subtitle}
                    as="span"
                  />
                )}
                {tooltipText?.length && (
                  <Tooltip
                    placement="bottom-end"
                    iconColor="primaryTint"
                    body={
                      <Flex
                        alignItems="center"
                        borderRadius={8}
                        flexDirection="column"
                        justifyContent="center"
                        width={280}
                      >
                        {tooltipText.map((text, index) => (
                          <PM
                            key={index}
                            mb={index !== tooltipText.length - 1 ? 16 : 0}
                          >
                            {text}
                          </PM>
                        ))}
                      </Flex>
                    }
                  />
                )}
              </Flex>
            </Flex>

            <Flex>
              <ButtonGroup>
                <Button
                  kind="inverse-secondary"
                  label="Cancel"
                  onClick={onCancel}
                />
                <EditorSaveButton
                  onClick={onContinue}
                  pieHasChanges={pieHasChanges}
                  rootPie={rootPie}
                />
              </ButtonGroup>
            </Flex>
          </HeaderContents>
        </Flex>

        <InactiveSlicesBanner />

        <Flex
          alignItems="center"
          height={50}
          justifyContent={!isRootPie ? 'space-between' : 'flex-end'}
          maxWidth={1200}
          width="100%"
          mt={48}
        >
          {!isRootPie && <EditorBreadcrumbs />}
          {backButton && isRootPie && <Flex width="100%">{backButton}</Flex>}
        </Flex>
      </SecondaryHeaderContainer>
    </StyledEditorHeader>
  );
};
