import { Box, LS, PM } from '@m1/liquid-react';
import * as React from 'react';

import type { PieDetailsNodeFragment } from '~/graphql/types';

type PieLinkedLocationListItemProps = {
  portfolioLink: NonNullable<PieDetailsNodeFragment['portfolioLinks']>[number];
  pieName: string;
};

export const PieLinkedLocationListItem = ({
  portfolioLink,
  pieName,
}: PieLinkedLocationListItemProps) => {
  const ancestors =
    portfolioLink.__typename === 'ChildPortfolioSlice'
      ? portfolioLink.ancestors.reduce((acc, val) => {
          if (val?.to.name) {
            return [...acc, val.to.name];
          }
          return acc;
        }, [] as string[])
      : [];

  return (
    <Box>
      <PM fontWeight={600} content={pieName || 'My Account'} />
      <LS as="h5" color="foregroundNeutralMain">
        {[...ancestors, pieName].join(' > ')}
      </LS>
    </Box>
  );
};
