import { Button, type ButtonProps, useSkeleton } from '@m1/liquid-react';
import React from 'react';

import type { AppButtonFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';

type AppButtonProps = OmitUnion<ButtonProps, 'label'> & {
  appButton: AppButtonFragment;
};
type AppSkeletonButtonProps = OmitUnion<AppButtonProps, 'appButton'> & {
  appButton: Maybe<AppButtonFragment>;
};

export const AppButton = ({ appButton, ...rest }: AppButtonProps) => {
  const analytics = useAnalytics();
  const callback = rest.onClick;
  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (appButton.analyticsEvent) {
        analytics.recordAppAnalyticsEvent(appButton.analyticsEvent);
      }
      callback?.(e);
    },
    [analytics, appButton.analyticsEvent, callback],
  );

  return appButton.label ? (
    <Button
      {...rest}
      onClick={onClick}
      label={appButton.label}
      disabled={Boolean(appButton.disabled) || rest.disabled}
    />
  ) : null;
};

/** Use this and wrap this in a Skeleton for nullable appButton  */
export const AppSkeletonButton = ({
  appButton,
  ...rest
}: AppSkeletonButtonProps) => {
  const { isLoading } = useSkeleton();

  if (appButton) {
    return <AppButton appButton={appButton} {...rest} />;
  } else if (isLoading && !appButton) {
    return <Button {...rest} label="Loading" disabled />;
  }
  return null;
};
