import { Flex, LL, LS, PL } from '@m1/liquid-react';
import * as React from 'react';

import type { UnmanagedHoldingFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';
import { Checkbox } from '~/toolbox/checkbox';
import { GridTable } from '~/toolbox/grid-table';
import { GridTableNavigableRow, GridTableRow } from '~/toolbox/grid-table/row';
import { Logo } from '~/toolbox/logo';

export type DesktopTableRowProps = {
  hideCheckboxes: boolean;
  holding: UnmanagedHoldingFragment;
  isTradingInProgress: boolean;
  navProps:
    | {
        gridTemplateColumns: string;
        params: {
          id: string;
        };
        to: string;
      }
    | null
    | undefined;
  onCheckboxSelect: (checkboxId: string) => void;
  selectedCheckboxes: Array<string>;
};

type Quantity = UnmanagedHoldingFragment['quantity'];

function readQuantity(quantity: Quantity): string {
  return typeof quantity === 'number' ? String(quantity) : '--';
}

export const UnmanagedHoldingsDesktopTableRow = ({
  hideCheckboxes,
  holding,
  isTradingInProgress,
  navProps,
  onCheckboxSelect,
  selectedCheckboxes,
}: DesktopTableRowProps) => {
  const {
    allocationDate,
    id,
    isPendingLiquidation,
    value,
    label,
    quantity,
    security,
    sublabel,
    unmanagedHoldingPills,
  } = holding;

  const logo = security?.profile?.logoUrl;
  const isSelected = selectedCheckboxes.includes(id);

  const RowComponent = navProps ? GridTableNavigableRow : GridTableRow;

  return (
    <RowComponent {...navProps}>
      <GridTable.Cell textAlign="left">
        <Flex alignItems="center">
          {!hideCheckboxes && (
            <Checkbox
              checked={isSelected}
              disabled={isTradingInProgress || Boolean(isPendingLiquidation)}
              mr={8}
              onChange={() => onCheckboxSelect(id)}
              size="large"
            />
          )}
          {logo && (
            <Logo
              content={logo}
              placeholder=""
              style={{
                borderRadius: '4px',
                height: '32px',
                marginRight: '8px',
                paddingBottom: 0,
                userSelect: 'none',
                width: '32px',
              }}
            />
          )}
          <Flex flexDirection="column">
            <Flex mb={4}>
              {sublabel && (
                <LS color="foregroundNeutralSecondary" mr={8}>
                  {sublabel}
                </LS>
              )}
              {Array.isArray(unmanagedHoldingPills) &&
                unmanagedHoldingPills
                  .filter((pill) => pill)
                  .map(
                    (pill, index) =>
                      pill && <AppPill appPill={pill} key={index} mr={8} />,
                  )}
            </Flex>
            <LL>{label}</LL>
          </Flex>
        </Flex>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <LL>{allocationDate?.value || '--'}</LL>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <LL>{readQuantity(quantity)}</LL>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <PL fontWeight={600}>{value || '--'}</PL>
      </GridTable.Cell>
    </RowComponent>
  );
};
