import * as React from 'react';

import { useTransferParticipantCellQuery } from '~/graphql/hooks';
import type { TransferParticipantFragment } from '~/graphql/types';

import { TransferParticipantCell } from './TransferParticipantCell';

export const TransferParticipantCellRenderer = ({
  id,
  includeDescription,
}: {
  id: string | null | undefined;
  includeDescription?: boolean;
}) => {
  const { data, loading } = useTransferParticipantCellQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
  });

  if (loading) {
    return null;
  }

  if (!data?.node) {
    return null;
  }

  return (
    <TransferParticipantCell
      transferParticipant={data.node as TransferParticipantFragment}
      includeDescription={includeDescription}
    />
  );
};
