import { Button, HM, HXS, Card } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { LabelWithValue } from '~/components/label-with-value';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { WidthConstrainerWithArrow } from '~/components/WidthConstrainerWithArrow';
import { useWizardContext } from '~/flows/wizard';
import {
  useAutoPayValidationStepQuery,
  useSetScheduledTransferRuleMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts/useToast';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';
import { isNil, isNotNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

import { CreatePaymentContext } from '../CreatePaymentContext';

export const AutoPayValidationStep = () => {
  const { goTo } = useWizardContext();
  const { addToast } = useToast();

  const [setScheduledTransferRule, { loading: isSubmitting }] =
    useSetScheduledTransferRuleMutation();

  const handleBack = React.useCallback(() => {
    goTo('PAYMENT_DETAILS');
  }, [goTo]);

  const paymentContext = React.useContext(CreatePaymentContext);

  const { otherAmount, sourceId, destinationId, selectedPreset } = useSelector(
    (state) => {
      return state.reactHookForm['createPayment'] ?? {};
    },
  );

  const scheduledTransferAmountPresets =
    paymentContext?.data?.viewer.transfers?.requirements
      ?.scheduledTransferAmountPresets;

  const selectedPresetOption = scheduledTransferAmountPresets?.find(
    (option) => option?.indicator === selectedPreset,
  );

  const date = React.useMemo(() => moment().toISOString(), []);

  const hasInitialized =
    isNotNil(destinationId) && isNotNil(sourceId) && isNotNil(selectedPreset);

  const { data, loading } = useAutoPayValidationStepQuery({
    variables: {
      date,
      destinationId,
      schedule: {
        monthly: {
          autoPayPaymentValue: selectedPreset,
        },
      },
      sourceId,
    },
    skip: !hasInitialized,
  });
  const analytics = useAnalytics();

  const frequency = data?.viewer?.transfers?.scheduleDetails?.description;

  const [amount, displayedAmount] = React.useMemo(() => {
    if (isNotNil(selectedPresetOption)) {
      return [0, selectedPresetOption.label];
    }
    if (selectedPreset === 'FIXED_AMOUNT') {
      return [otherAmount, formatCurrency(otherAmount)];
    }
    return [0, '$--'];
  }, [selectedPreset, otherAmount, selectedPresetOption]);

  const isPersonalLoanPayment =
    paymentContext?.data?.viewer?.transfers?.requirements
      ?.isLoanPaymentTransfer;

  const handleConfirm = () => {
    return setScheduledTransferRule({
      variables: {
        input: {
          amount,
          fromParticipantId: sourceId,
          toParticipantId: destinationId,
          schedule: {
            monthly: {
              autoPayPaymentValue: selectedPreset,
            },
          },
        },
      },
      onCompleted: (result) => {
        const nodeId = result?.setScheduledTransferRule?.outcome?.rule?.id;
        paymentContext?.setCompletedPaymentNodeId(nodeId ?? null);
        if (isPersonalLoanPayment === true) {
          analytics.recordEvent('m1_personal_loan_autopay_confirmed');
        }
        goTo('PAYMENT_RECEIPT');
      },
      onError: (error) => {
        addToast({
          content: error.message,
          kind: 'alert',
          duration: 'short',
        });
        goTo('PAYMENT_DETAILS');
      },
    });
  };

  if (loading === true || !hasInitialized) {
    return <Spinner fullScreen />;
  }

  if (isNil(data) && hasInitialized) {
    return <GenericSystemError />;
  }

  return (
    <>
      <HXS content="Confirm AutoPay" />
      <Card mt={32} p={32}>
        <LabelWithValue
          label="Amount"
          value={<HM content={displayedAmount} />}
        />
        <Divider />

        <Grid.Row
          style={{
            marginTop: 16,
          }}
        >
          <Grid.Col xs={6}>
            <LabelWithValue
              label="From"
              value={
                isNotNil(data?.source) &&
                'transferParticipantName' in data.source ? (
                  <WidthConstrainerWithArrow>
                    <TransferParticipantCell
                      transferParticipant={data.source}
                    />
                  </WidthConstrainerWithArrow>
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <LabelWithValue
              label="To"
              value={
                isNotNil(data?.destination) &&
                'transferParticipantName' in data.destination ? (
                  <TransferParticipantCell
                    transferParticipant={data.destination}
                  />
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          {isNotNil(frequency) && (
            <Grid.Col xs={12}>
              <LabelWithValue
                label="Frequency"
                style={{
                  marginTop: 32,
                }}
                value={frequency}
              />
            </Grid.Col>
          )}
        </Grid.Row>
        <ButtonGroup mt={64}>
          <Button kind="secondary" onClick={handleBack} size="large" fullWidth>
            Back
          </Button>
          <Button
            kind="primary"
            onClick={handleConfirm}
            disabled={isSubmitting}
            size="large"
            fullWidth
          >
            Confirm
          </Button>
        </ButtonGroup>
      </Card>
    </>
  );
};
