import { Tooltip, PM, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useSelector } from '~/redux/hooks';
import { formatCurrency } from '~/utils/formatting';

type AutoInvestPopupProps = {
  autoInvestTriggerMinimum: number;
};

export const AutoInvestPopup = ({
  autoInvestTriggerMinimum,
}: AutoInvestPopupProps) => {
  const isCrypto = useSelector<boolean>(
    (state) => state.global.activeAccountIsCrypto,
  );

  return (
    <Flex py={12}>
      <PM content="Auto-invest deposits" />
      <Tooltip
        placement="top-start"
        iconColor="foregroundPrimary"
        body={
          <PM>
            Turn on auto-invest to automatically invest all of your cash.
            Trading will be initiated once you have at least $
            {autoInvestTriggerMinimum}. You can also set a minimum cash balance
            to remain uninvested in your account, and trading will be initiated
            once your balance is at least ${autoInvestTriggerMinimum} over that
            set amount.
            <br />
            <br />
            Example: If your set minimum is $1,000, trading will be initiated
            whenever your cash balance is at least&nbsp;
            {formatCurrency(autoInvestTriggerMinimum + 1000, 'SELECTIVE')}. The
            ${autoInvestTriggerMinimum} will be traded and the $1,000 will
            remain in your account.
            <br />
            <br />
            {`All deposits ${
              !isCrypto ? 'and dividends ' : ''
            }are added to your cash balance, and any withdrawals will be taken from your cash balance first.`}
          </PM>
        }
      />
    </Flex>
  );
};
