import {
  type AccountSetupFlowCanceledModernAction,
  type AccountSetupFlowFinishedModernAction,
  type BeginAccountSetupFlowModernAction,
  type ChangeAccountSetupFlowStepModernAction,
  type ClickedCancelAccountSetupModernAction,
  type CollectedHolderSsnModern,
  type SubmittedConfirmProfile,
  type CollectedTrustedContactAction,
  type ExplainedProfileCollectionModern,
  type FinishedAccountSetupReviewModernAction,
  type FinishedReadingOtherAccountReceiptModernAction,
  type FinishedReadingRolloverReceiptModernAction,
  IDENTITY_FIRST_ACTIONS,
  type IdentityFirstFlowFinished,
  type SelectedAccountTypeModernAction,
  type SelectedIndividualRetirementAccountTypeModernAction,
  type SelectedOtherAccountTypeModernAction,
  type SelectedRetirementAccountTypeModernAction,
  type SelectedRolloverAccountTypeModernAction,
  type SetIdentityFirstOnboardingQuestions,
  type SetInitialUserProfileInput,
  type SubmittedContactInfoFormModern,
  type SubmittedCustodialContactInfoFormModernAction,
  type SubmittedDisclosuresFormModern,
  type SubmittedEmploymentInfoModern,
  type SubmittedIdentityInfoFormModern,
  type SubmittedIntroductionSourceModernAction,
  type SubmittedProfileInputActionModern,
  type SubmittedAnnualIncome,
  type SubmittedLiquidNetWorth,
} from './identityFirstActions.types';

export const beginAccountSetupFlowModern = (
  payload: BeginAccountSetupFlowModernAction['payload'],
): BeginAccountSetupFlowModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW_MODERN,
});

export const changeAccountSetupFlowStepModern = (
  payload: ChangeAccountSetupFlowStepModernAction['payload'],
): ChangeAccountSetupFlowStepModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.CHANGE_ACCOUNT_SETUP_FLOW_STEP_MODERN,
});

export const clickedCancelAccountSetupModern = (
  payload: ClickedCancelAccountSetupModernAction['payload'],
): ClickedCancelAccountSetupModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.CLICKED_CANCEL_ACCOUNT_SETUP_MODERN,
});

export const accountSetupFlowCanceledModern = (
  payload: AccountSetupFlowCanceledModernAction['payload'],
): AccountSetupFlowCanceledModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.ACCOUNT_SETUP_FLOW_CANCELED_MODERN,
});

export const accountSetupFlowFinishedModern = (
  payload: AccountSetupFlowFinishedModernAction['payload'],
): AccountSetupFlowFinishedModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED_MODERN,
});

export const selectedAccountTypeModern = (
  payload: SelectedAccountTypeModernAction['payload'],
): SelectedAccountTypeModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SELECTED_ACCOUNT_TYPE_MODERN,
});

export const selectedRetirementAccountTypeModern = (
  payload: SelectedRetirementAccountTypeModernAction['payload'],
): SelectedRetirementAccountTypeModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE_MODERN,
});

export const selectedIndividualRetirementAccountTypeModern = (
  payload: SelectedIndividualRetirementAccountTypeModernAction['payload'],
): SelectedIndividualRetirementAccountTypeModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE_MODERN,
});

export const selectedRolloverAccountTypeModern = (
  payload: SelectedRolloverAccountTypeModernAction['payload'],
): SelectedRolloverAccountTypeModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE_MODERN,
});

export const selectedOtherAccountTypeModern = (
  payload: SelectedOtherAccountTypeModernAction['payload'],
): SelectedOtherAccountTypeModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE_MODERN,
});

export const submittedCustodialContactInfoFormModern = (
  payload: SubmittedCustodialContactInfoFormModernAction['payload'],
): SubmittedCustodialContactInfoFormModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN,
});

export const submittedIntroductionSourceModern = (
  payload: SubmittedIntroductionSourceModernAction['payload'],
): SubmittedIntroductionSourceModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_INTRODUCTION_SOURCE_MODERN,
});

export const finishedAccountSetupReviewModern = (
  payload: FinishedAccountSetupReviewModernAction['payload'],
): FinishedAccountSetupReviewModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW_MODERN,
});

export const finishedReadingRolloverReceiptModern = (
  payload: FinishedReadingRolloverReceiptModernAction['payload'],
): FinishedReadingRolloverReceiptModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.FINISHED_READING_ROLLOVER_RECEIPT_MODERN,
});

export const finishedReadingOtherAccountReceiptModern = (
  payload: FinishedReadingOtherAccountReceiptModernAction['payload'],
): FinishedReadingOtherAccountReceiptModernAction => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.FINISHED_READING_OTHER_ACCOUNT_RECEIPT_MODERN,
});

export const identityFirstFlowFinished = (
  payload: IdentityFirstFlowFinished['payload'],
): IdentityFirstFlowFinished => ({
  type: IDENTITY_FIRST_ACTIONS.IDENTITY_FIRST_FLOW_FINISHED,
  payload,
});

export const setInitialUserProfileInput = (
  payload: SetInitialUserProfileInput['payload'],
): SetInitialUserProfileInput => ({
  type: IDENTITY_FIRST_ACTIONS.SET_INITIAL_USER_PROFILE_INPUT,
  payload,
});

export const setIdentityFirstOnboardingQuestions = (
  payload: SetIdentityFirstOnboardingQuestions['payload'],
): SetIdentityFirstOnboardingQuestions => ({
  payload,
  type: IDENTITY_FIRST_ACTIONS.SET_IDENTITY_FIRST_ONBOARDING_QUESTIONS,
});

export const submittedDisclosuresFormModern = (
  payload: SubmittedDisclosuresFormModern['payload'],
): SubmittedDisclosuresFormModern => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_DISCLOSURES_FORM_MODERN,
  payload,
});

export const submittedIdentityInfoFormModern = (
  payload: SubmittedIdentityInfoFormModern['payload'],
): SubmittedIdentityInfoFormModern => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_IDENTITY_INFO_FORM_MODERN,
  payload,
});

export const submittedEmploymentInfoModern = (
  payload: SubmittedEmploymentInfoModern['payload'],
): SubmittedEmploymentInfoModern => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_EMPLOYMENT_INFO_MODERN,
  payload,
});

export const submittedContactInfoFormModern = (
  payload: SubmittedContactInfoFormModern['payload'],
): SubmittedContactInfoFormModern => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_CONTACT_INFO_FORM_MODERN,
  payload,
});

export const collectedHolderSsnModern = (
  payload: CollectedHolderSsnModern['payload'],
): CollectedHolderSsnModern => ({
  type: IDENTITY_FIRST_ACTIONS.COLLECTED_HOLDER_SSN_MODERN,
  payload,
});

export const submittedConfirmProfile = (
  payload: SubmittedConfirmProfile['payload'],
): SubmittedConfirmProfile => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_CONFIRM_PROFILE,
  payload,
});

export const submittedProfileInputModern = (
  payload: SubmittedProfileInputActionModern['payload'],
): SubmittedProfileInputActionModern => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_PROFILE_INPUT_MODERN,
  payload: payload,
});

export const submittedAnnualIncome = (
  payload: SubmittedProfileInputActionModern['payload'],
): SubmittedAnnualIncome => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_ANNUAL_INCOME_FORM,
  payload: payload,
});

export const submittedLiquidNetWorth = (
  payload: SubmittedProfileInputActionModern['payload'],
): SubmittedLiquidNetWorth => ({
  type: IDENTITY_FIRST_ACTIONS.SUBMITTED_LIQUID_NET_WORTH_FORM,
  payload: payload,
});

export const explainedProfileCollectionModern = (
  payload: ExplainedProfileCollectionModern['payload'],
): ExplainedProfileCollectionModern => ({
  type: IDENTITY_FIRST_ACTIONS.EXPLAINED_PROFILE_COLLECTION_MODERN,
  payload: payload,
});

export const collectedTrustedContact = (
  payload: CollectedTrustedContactAction['payload'],
): CollectedTrustedContactAction => ({
  type: IDENTITY_FIRST_ACTIONS.COLLECTED_TRUSTED_CONTACT,
  payload: payload,
});
