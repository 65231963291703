import { FloatingPanel, globalColors } from '@m1/liquid-react';
import React, { type ReactNode } from 'react';

import { useMoveMoneyOptionsQuery } from '~/graphql/hooks';

import { MoveMoneyOptions } from './MoveMoneyOptions';

export const MoveMoneyMenu = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { data } = useMoveMoneyOptionsQuery();

  const moveMoneyV2 = data?.viewer?.transfers?.moveMoneyV2;
  const callToAction = moveMoneyV2?.callToAction;
  const options = moveMoneyV2?.listItems;

  return (
    <FloatingPanel
      controlled
      active={isOpen}
      onShow={() => setIsOpen(true)}
      onHide={() => setIsOpen(false)}
      content={
        <MoveMoneyOptions
          callToAction={callToAction}
          setIsOpen={setIsOpen}
          options={options}
        />
      }
      placement="bottom-end"
      style={{
        boxShadow: `0px 15px 40px 0px rgba(${globalColors.achromaticPureBlack[0]} / 0.2)`,
        margin: '0 0 32px 0',
      }}
      trigger={children}
    />
  );
};
