import { Flex } from '@m1/liquid-react';
import * as React from 'react';
import { useFormState } from 'react-hook-form';

import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';
import { useGetInvestActivityQuery } from '~/graphql/hooks';
import type {
  GetInvestActivityQueryVariables,
  InvestActivityNodeFragment,
} from '~/graphql/types';
import { useApolloPrevNext } from '~/hooks/useApolloPrevNext';
import { Grid } from '~/toolbox/grid';
import { GridTable } from '~/toolbox/grid-table';

import { InvestActivityEmptyContainer } from './InvestActivityEmptyContainer';
import { InvestActivityRow } from './InvestActivityRow';
import { InvestFiltersContainer } from './InvestFiltersContainer';
import { SortHeader } from './SortHeader';

export const InvestActivityTable = ({
  variables,
}: {
  variables: GetInvestActivityQueryVariables;
}) => {
  const { data, loading, refetch } = useGetInvestActivityQuery({
    variables,
  });
  const { isDirty } = useFormState();

  React.useEffect(() => {
    refetch(variables);
  }, [variables, refetch]);

  const { handleNextPage, handlePreviousPage, hasPreviousPage } =
    useApolloPrevNext({
      refetch,
    });

  if (data?.node?.__typename !== 'Account') {
    return null;
  }

  const investActivity = data?.node.investActivity;

  if (!investActivity?.activity?.edges) {
    return null;
  }

  const { endCursor, hasNextPage } = investActivity.activity.pageInfo;

  const activities: InvestActivityNodeFragment[] =
    investActivity?.activity.edges.reduce((acc, edge) => {
      if (edge?.node) {
        acc.push(edge.node);
      }

      return acc;
    }, [] as InvestActivityNodeFragment[]);

  if (activities.length === 0 && !isDirty && !loading) {
    return <InvestActivityEmptyContainer />;
  }

  return (
    <Grid
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
      }}
    >
      <Flex flexWrap="wrap-reverse" width="100%" mt={24}>
        <Flex mb={16}>
          <PaginationButtonGroup
            hasNextPage={hasNextPage}
            handleNextPage={() => handleNextPage(endCursor)}
            handlePreviousPage={handlePreviousPage}
            hasPreviousPage={hasPreviousPage}
          />
        </Flex>
        <InvestFiltersContainer activities={activities} />
      </Flex>
      <GridTable
        defaultAlignSelf="start"
        emptyMessage="No matching activities"
        gridTemplateColumns="160px 230px auto 200px"
      >
        <GridTable.HeaderRow sticky>
          <SortHeader />
          <GridTable.HeaderCell label="Activity" />
          <GridTable.HeaderCell label="Summary" />
          <GridTable.HeaderCell justifySelf="end" label="Value" />
        </GridTable.HeaderRow>
        {activities?.map((activity) => {
          return <InvestActivityRow activity={activity} key={activity.id} />;
        })}
      </GridTable>
    </Grid>
  );
};
