import * as React from 'react';
import { useForm } from 'react-hook-form';

import type {
  BackupWithholding,
  ControlPersonDisclosure,
  EmploymentInput,
  ExchangeOrFinraAffiliationDisclosure,
  LiquidNetWorthEnum,
  PoliticalExposureDisclosure,
  Profile,
  TotalNetWorthEnum,
} from '~/graphql/types';

export type FinancialSuitabilityFormFields = {
  primary: {
    employment: EmploymentInput;
    exchangeOrFinraAffiliationDisclosure: ExchangeOrFinraAffiliationDisclosure;
    controlPersonDisclosure: ControlPersonDisclosure;
    politicalExposureDisclosure: PoliticalExposureDisclosure;
    backupWithholding: BackupWithholding;
  };
  suitability: {
    totalNetWorth: TotalNetWorthEnum | null;
    liquidNetWorth: LiquidNetWorthEnum | null;
  };
};

export const useFinancialSuitabilityWizardForm = (userProfile?: Profile) => {
  const formMethods = useForm<FinancialSuitabilityFormFields>({
    defaultValues: {
      primary: {
        employment: {
          status: undefined,
          employer: null,
          occupation: null,
        },
        exchangeOrFinraAffiliationDisclosure: {
          isAffiliated: false,
          firmName: null,
        },
        controlPersonDisclosure: {
          isControlPerson: false,
          companySymbols: null,
        },
        politicalExposureDisclosure: {
          isPoliticallyExposed: false,
          politicalOrganization: null,
          immediateFamilyMembers: null,
        },
        backupWithholding: {
          isSubjectToBackupWithholding: false,
        },
      },
      suitability: {
        totalNetWorth: null,
        liquidNetWorth: null,
      },
    },
    mode: 'onChange',
  });

  React.useEffect(() => {
    const defaults = {
      primary: {
        employment: userProfile?.primary?.employmentInfo ?? {
          status: undefined,
          employer: null,
          occupation: null,
        },
        exchangeOrFinraAffiliationDisclosure: userProfile?.primary
          ?.exchangeOrFinraAffiliationDisclosure ?? {
          isAffiliated: false,
          firmName: null,
        },
        controlPersonDisclosure: userProfile?.primary
          ?.controlPersonDisclosure ?? {
          isControlPerson: false,
          companySymbols: null,
        },
        politicalExposureDisclosure: userProfile?.primary
          ?.politicalExposureDisclosure ?? {
          isPoliticallyExposed: false,
          politicalOrganization: null,
          immediateFamilyMembers: null,
        },
        backupWithholding: userProfile?.primary?.backupWithholding ?? {
          isSubjectToBackupWithholding: false,
        },
      },
      suitability: {
        totalNetWorth: userProfile?.suitability?.totalNetWorth ?? null,
        liquidNetWorth: userProfile?.suitability?.liquidNetWorth ?? null,
      },
    };
    formMethods.reset(defaults);
  }, [formMethods.reset, userProfile]);

  return formMethods;
};
