import type { ScheduleKey } from '~/components/form/Dropdowns/schedule/types';
import type { EditScheduleTransferRuleFragment } from '~/graphql/types';
import { isNil } from '~/utils';

import { TransferFrequencyEnum } from '../../steps/TransferDetails.types';

export const isValidTransferFrequency = (
  frequency: string,
): frequency is TransferFrequencyEnum => {
  return Object.values(TransferFrequencyEnum).includes(
    frequency as TransferFrequencyEnum,
  );
};

export const FREQUENCY_TO_LABEL: Record<TransferFrequencyEnum, string> = {
  [TransferFrequencyEnum.OneTime]: 'Once',
  [TransferFrequencyEnum.Weekly]: 'Weekly',
  [TransferFrequencyEnum.EveryTwoWeeks]: 'Every 2 weeks',
  [TransferFrequencyEnum.Monthly]: 'Monthly',
};

type RecurringFrequencies =
  | TransferFrequencyEnum.Weekly
  | TransferFrequencyEnum.EveryTwoWeeks
  | TransferFrequencyEnum.Monthly;

export const FREQUENCY_TO_INPUT_KEYS: Record<
  RecurringFrequencies,
  ScheduleKey
> = {
  'weekly': 'weekly',
  'every-two-weeks': 'biweekly',
  'monthly': 'monthly',
};

type TransferScheduleTypename = Exclude<
  NonNullable<EditScheduleTransferRuleFragment['schedule']>['__typename'],
  'WeekOfMonthSchedule'
>;

export const TRANSFER_SCHEDULE_TYPENAME_TO_FREQUENCY: Record<
  TransferScheduleTypename,
  TransferFrequencyEnum
> = {
  'WeeklySchedule': TransferFrequencyEnum.Weekly,
  'BiweeklySchedule': TransferFrequencyEnum.EveryTwoWeeks,
  'MonthlySchedule': TransferFrequencyEnum.Monthly,
};

export const getScheduleDetailsFromTransferRule = (
  transferRule: Maybe<EditScheduleTransferRuleFragment>,
) => {
  if (isNil(transferRule) || isNil(transferRule.schedule)) {
    return {
      frequency: null,
      schedule: null,
    };
  }
  const transferRuleSchedule = transferRule?.schedule;
  const frequency =
    TRANSFER_SCHEDULE_TYPENAME_TO_FREQUENCY[
      transferRuleSchedule.__typename as TransferScheduleTypename
    ];

  switch (transferRuleSchedule.__typename) {
    case 'WeeklySchedule': {
      return {
        frequency,
        schedule: {
          weekly: {
            dayOfWeek: transferRuleSchedule.dayOfWeek,
          },
        },
      };
    }
    case 'BiweeklySchedule': {
      return {
        frequency,
        schedule: {
          biweekly: {
            dayOfWeek: transferRuleSchedule.dayOfWeek,
            isEvenWeeks: transferRuleSchedule.isEvenWeeks,
          },
        },
      };
    }
    case 'MonthlySchedule': {
      return {
        frequency,
        schedule: {
          monthly: {
            dayOfMonth: transferRuleSchedule.dayOfMonth,
          },
        },
      };
    }
    default: {
      return {
        frequency,
        schedule: null,
      };
    }
  }
};
