import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, spawn } from 'redux-saga/effects';

import type { CampaignAttributionInput } from '~/graphql/types';
import type { AppState } from '~/redux';
import { determinedCampaignAttribution } from '~/redux/actions';

export function* initializeCrossDomainStorage(
  location: Location,
): SagaIterator<void> {
  const sender = yield getContext('cdsSender');

  // First, check for attribution in the URL.
  const attribution: CampaignAttributionInput | null = yield call(
    [sender, 'readAttributionFromLocation'],
    location,
  );

  // Persist attribution to redux store.
  yield put(determinedCampaignAttribution(attribution));

  if (attribution) {
    // Async save back attribution into the CDS.
    yield spawn(function* () {
      try {
        yield call([sender, 'init'], location);
      } catch (e: any) {
        // Ignore CDS errors here.
      }
    });
  }
}

export function* readAttribution(): SagaIterator<
  CampaignAttributionInput | null | undefined
> {
  const attribution: CampaignAttributionInput | null | undefined = yield select(
    (state: AppState): CampaignAttributionInput | null | undefined =>
      state.config.attribution,
  );
  if (attribution) {
    return attribution;
  }

  // If we don't have attribution data in local state, try to read it from CDS.
  // This would include attribution recorded from another domain, like m1.com.
  const sender = yield getContext('cdsSender');
  try {
    yield call([sender, 'init']);

    const [source, medium, campaign, term, content] = yield call(
      [sender, 'get'],
      ...['source', 'medium', 'campaign', 'term', 'content'],
    );
    if (source && medium && campaign) {
      return {
        source,
        medium,
        campaign,
        term,
        content,
      };
    }
  } catch (e: any) {
    // Do nothing
  }

  return null;
}
