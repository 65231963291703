import type { SagaIterator } from 'redux-saga';
import { call, getContext } from 'redux-saga/effects';

import { IDENTITY_FIRST_FLOW_STEPS as STEPS } from '~/static-constants';

import { changeStep } from '../../../utils';
import type { IdentityFirstAccountSetupProfile } from '../identityFirstSaga';

export function* changeStepAfterAccountSelection(
  didSelectJointAccount: boolean,
): SagaIterator<void> {
  const profile: IdentityFirstAccountSetupProfile = yield getContext('profile');
  if (!profile?.primary) {
    yield call(changeStep, STEPS.COLLECT_PRIMARY_CONTACT_INFO);
  } else if (didSelectJointAccount && !profile.secondary) {
    yield call(changeStep, STEPS.INTRODUCE_SECONDARY_HOLDER);
  } else {
    yield call(changeStep, STEPS.REVIEW_QUICK);
  }
}
