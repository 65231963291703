import { Button, PL, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { type Pie, readPieTreeByPath } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';

export const PortfolioOrganizerPathBreadcrumbs = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.portfolioOrganizer);

  const pies: Array<Pie> = [];

  // Base case for the "root" Pie in the tree.
  pies.push(state.pieTree);

  // For every other segment of the path, push the Pie at that location onto the array.
  if (state.path.length > 0) {
    for (let i = 1; i <= state.path.length; i++) {
      pies.push(readPieTreeByPath(state.pieTree, state.path.slice(0, i)));
    }
  }

  return (
    <Flex alignItems="baseline" flexDirection="row">
      {pies.map((pie, index) => {
        const name = pie.name || '';
        const truncatedName =
          name.length > 20 ? `${name.slice(0, 20) || ''}...` : name;
        if (index === pies.length - 1) {
          return <PL content={truncatedName} flex="1 0 auto" key={pie.__id} />;
        }

        return (
          <React.Fragment key={pie.__id}>
            <Button
              kind="link"
              label={truncatedName}
              style={{
                flex: '1 0 auto',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: -0.25,
              }}
              onClick={() =>
                dispatch({
                  payload: state.path.slice(0, index),
                  type: 'CLICKED_PORTFOLIO_ORGANIZER_BREADCRUMB',
                })
              }
            />
            {index < pies.length && <Icon name="caretRight16" />}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};
