import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  HasAutoInvestTurnedOnSagaDocument,
  SetMaxCashThresholdDocument,
} from '~/graphql/hooks';
import type {
  BorrowAccount,
  SetMaxCashThresholdInput,
  SetMaxCashThresholdResult,
} from '~/graphql/types';

import { apolloMutationSaga } from '../../apolloMutationSaga';
import { apolloQuerySaga } from '../../apolloQuerySaga';

export function* queryHasAutoInvestTurnedOn(
  borrowAccountId: string,
): SagaIterator<BorrowAccount> {
  return yield call(apolloQuerySaga, {
    query: HasAutoInvestTurnedOnSagaDocument,
    variables: {
      borrowAccountId,
    },
    fetchPolicy: 'network-only',
  });
}

export function* mutateSetMaxCashThreshold(
  borrowAccountId: string,
): SagaIterator<SetMaxCashThresholdResult | null | undefined> {
  try {
    const { data } = yield call(queryHasAutoInvestTurnedOn, borrowAccountId);
    return yield call(apolloMutationSaga, {
      mutation: SetMaxCashThresholdDocument,
      variables: {
        input: {
          accountId: data?.borrowAccount?.investAccount.id,
          maxCashThreshold: null,
        } satisfies SetMaxCashThresholdInput,
      },
    });
  } catch (e: any) {
    // Do nothing
  }
}
