import { Flex, PM, Tooltip, type TooltipProps } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

type PartialTotalInvestmentsValuePopupProps = Omit<TooltipProps, 'body'> & {
  label: string;
};

export const PartialTotalInvestmentsValuePopup = (
  props: PartialTotalInvestmentsValuePopupProps,
) => {
  const { label, ...rest } = props;
  const content =
    'Some price information may be temporarily missing. Values will update shortly.';

  return (
    <Tooltip body={<PM content={content} />} {...rest}>
      <Flex alignItems="center" gap={4}>
        {label}
        <Icon name="tooltip20" color="primary" />
      </Flex>
    </Tooltip>
  );
};
