import { isNil } from '../typeAssertions';

export type NumberFormatEnum =
  | 'DECIMAL' // Always show 2 decimal places
  | 'SELECTIVE' // Show 2 decimal places only if value is not an integer
  | 'COMPACT'; // Show large numbers with a letter up to 4 significant digits

/**
 * NOTE: Do not use this function for currency or percentages.
 * Use formatCurrency or formatPercentage instead.
 */
export function formatNumber(
  value: number,
  format?: Maybe<NumberFormatEnum>,
): string;
/* eslint-disable-next-line no-redeclare */
export function formatNumber(
  value: Maybe<number>,
  format?: Maybe<NumberFormatEnum>,
): string | null;
/* eslint-disable-next-line no-redeclare */
export function formatNumber(
  value: Maybe<string>,
  format?: Maybe<NumberFormatEnum>,
): string | null;
/* eslint-disable-next-line no-redeclare */
export function formatNumber(
  value: Maybe<number> | Maybe<string>,
  format?: Maybe<NumberFormatEnum>,
): string | null {
  const numberFormat = format ?? 'DECIMAL';

  const numericValue = typeof value === 'number' ? value : Number(value);

  if (isNil(value) || isNaN(numericValue)) {
    return null;
  }

  switch (numberFormat) {
    case 'SELECTIVE':
      if (numericValue % 1 === 0) {
        return IntegerNumberFormatter.format(numericValue);
      }
      return DecimalNumberFormatter.format(numericValue);
    case 'COMPACT':
      return CompactNumberFormatter.format(numericValue);
    case 'DECIMAL':
    default:
      return DecimalNumberFormatter.format(numericValue);
  }
}

/**
 * Do not use this formatter directly.
 * Use formatNumber instead.
 */
const DecimalNumberFormatter = Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Do not use this formatter directly.
 * Use formatNumber instead.
 */
const IntegerNumberFormatter = Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

/**
 * Do not use this formatter directly.
 * Use formatNumber instead.
 */
const CompactNumberFormatter = Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 4,
});
