import type {
  CryptoSortTypeEnum,
  HistoricalQuotePeriodEnum,
} from '~/graphql/types';

export const PERIOD_TO_SORT: Partial<
  Record<HistoricalQuotePeriodEnum, CryptoSortTypeEnum>
> = {
  ONE_YEAR: 'PRICE_CHANGE_1Y',
  TWO_YEARS: 'PRICE_CHANGE_2Y',
  FIVE_YEARS: 'PRICE_CHANGE_5Y',
};
