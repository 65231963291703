import { useTheme, Flex } from '@m1/liquid-react';
import memoize from 'lodash-es/memoize';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { AnnouncementContext } from '~/components/announcement';
import type { ChartableSliceChartRangeSelectionChange } from '~/components/ChartableSliceChart/ChartableSliceChart.types';
import { GenericSystemError } from '~/components/GenericSystemError';
import { usePortfolioDetailsSliceQuery } from '~/graphql/hooks';
import type {
  PortfolioSlicePerformancePeriodEnum,
  SortDirectionEnum,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import type { PortfolioSliceSortKey } from '~/portfolio-slice';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

import { PortfolioDetailsHeader } from './components/PortfolioDetailsHeader';
import {
  PortfolioDetailsContext,
  type SortState,
} from './PortfolioDetailsContext';
import { PortfolioDetailsDesktopView } from './PortfolioDetailsDesktopView';
import { PortfolioDetailsMobileView } from './PortfolioDetailsMobileView';
import { MastHeader } from './PortfolioDetailsPage.styled';
import {
  createPortfolioSliceSorter,
  generateColorsForPie,
} from './PortfolioDetailsPage.utils';

type PortfolioDetailsPageProps = {
  portfolioSliceId: string | null;
};

export const PortfolioDetailsPage = ({
  portfolioSliceId,
}: PortfolioDetailsPageProps) => {
  const navigate = useNavigate();
  const [hasMarginCallDialogOnMount, setHasMarginCallDialogOnMount] =
    React.useState(false);
  const [chartPeriod, setChartPeriod] =
    React.useState<PortfolioSlicePerformancePeriodEnum>('MAX');
  const [chartRange, setChartRange] =
    React.useState<ChartableSliceChartRangeSelectionChange | null>(null);

  const [sort, setSort] = React.useState<SortState>([
    {
      sortDirection: 'DESC',
      sortKey: 'value.total',
    },
  ]);

  const hasUserDismissedMarginCallDialog = useSelector(
    (state) => state.borrowMargin.hasUserDismissedMarginCallDialog,
  );

  const isSetOrderFlowRunning = useSelector(
    (state) => state.newFlows.SET_ORDER._isFlowRunning,
  );

  const theme = useTheme();

  const accountId = useSelector((state) => state.global.activeAccountId);

  // TODO: switch this to a lazy query and use conditional checking for the account/portfolio ID.
  const { data, loading, variables } = usePortfolioDetailsSliceQuery({
    variables: {
      accountId: accountId as string,
      portfolioId: portfolioSliceId as string,
    },
    skip: !accountId,
    errorPolicy: 'all',
  });

  const viewer = data?.viewer;
  const account = data?.account?.__typename === 'Account' ? data.account : null;
  const portfolioSlice =
    data?.portfolioSlice && 'to' in data.portfolioSlice
      ? data.portfolioSlice
      : null;

  React.useEffect(() => {
    if (
      viewer?.borrow?.hasBorrowAccountWithOpenMaintenanceCall &&
      !hasUserDismissedMarginCallDialog
    ) {
      setHasMarginCallDialogOnMount(true);
    }
  }, [viewer, hasUserDismissedMarginCallDialog]);

  React.useEffect(() => {
    if (
      account &&
      portfolioSlice?.account &&
      account.id !== portfolioSlice.account.id &&
      !isSetOrderFlowRunning
    ) {
      navigate({ to: '/d/invest' });
    }
  }, [account, navigate, isSetOrderFlowRunning, portfolioSlice]);

  const onChangeSort = (sortKey: PortfolioSliceSortKey) => {
    let sortDirection = sort[0].sortDirection;
    if (sort[0].sortKey === sortKey) {
      sortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortDirection = sortKey === 'to.name' ? 'ASC' : 'DESC';
    }
    setSort([
      {
        sortDirection,
        sortKey,
      },
    ]);
  };

  const createSorter = memoize(
    createPortfolioSliceSorter,
    (key: PortfolioSliceSortKey, direction: SortDirectionEnum) => {
      return `${key}-${direction}`;
    },
  );

  const sorter = createSorter(sort[0].sortKey, sort[0].sortDirection);

  const announcement = viewer?.announcements?.forInvest;

  // Do not show a modal announcement if the user has a maintenance call,
  // which will also show a modal. Maintenance call takes priority.
  // If the user does not have a maintenance call, or if the announcement
  // is not a modal, then show the announcement.
  const shouldShowAnnouncement =
    !hasMarginCallDialogOnMount || announcement?.displayPreference !== 'MODAL';

  const memoizedGenerateColorsForPie = React.useMemo(() => {
    return generateColorsForPie(portfolioSlice as any, theme.pieSliceColors);
  }, [portfolioSlice, theme.pieSliceColors]);

  const isSmallDevice = useMediaQuery({
    query: '(max-width: 992px)',
  });

  const isCrypto = Boolean(account?.registration === 'CRYPTO');

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!account || !portfolioSlice || !viewer) {
    return <GenericSystemError />;
  }

  return (
    <>
      <PortfolioDetailsContext.Provider
        value={{
          account,
          portfolioSlice,
          sorter,
          viewer,
          isCrypto,
          memoizedGenerateColorsForPie,
          hasMarginCallDialogOnMount,
          hasUserDismissedMarginCallDialog,
          queryVariables: variables,
          onChangeSort,
          sort,
          onChangePeriod: setChartPeriod,
          onChangeChartRange: setChartRange,
          chartPeriod,
          chartRange,
        }}
      >
        {portfolioSlice.__typename === 'RootPortfolioSlice' && <MastHeader />}
        <PortfolioDetailsHeader />
        {announcement && shouldShowAnnouncement && (
          <Container marginTop={16} px={64}>
            <AnnouncementContext announcement={announcement} context="INVEST" />
          </Container>
        )}
        <Flex mt={32}>
          {isSmallDevice ? (
            <PortfolioDetailsMobileView />
          ) : (
            <PortfolioDetailsDesktopView />
          )}
        </Flex>
      </PortfolioDetailsContext.Provider>
    </>
  );
};
