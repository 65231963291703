import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { ConnectBankFlow } from '~/flows/components';
import { useLocation } from '~/hooks/useLocation';
import { type NavigatePropsObject, useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { useToast } from '~/toasts';

type HandleFinish = {
  achRelationshipId?: string | null | undefined;
  route?: NavigatePropsObject | null | undefined;
  locationParams?: Record<string, string>;
  shouldClearToast?: boolean;
};

export const ConnectBankPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearToast } = useToast();
  const [searchParams] = useSearchParams();

  const connectionType = searchParams.get('connectionType');
  const previousRouteName = searchParams.get('previousRouteName');
  const initialStep = searchParams.get('initialStep');
  const fundingSource = searchParams.get('fs');

  const handleFinish = (action?: HandleFinish): void => {
    let nextRoute: NavigatePropsObject = { to: '/d/invest/bank-connection' };

    if (action?.shouldClearToast) {
      clearToast();
    }

    if (action?.route) {
      nextRoute = action.route;
    } else if (previousRouteName) {
      nextRoute = { to: previousRouteName };
    }

    const query = action?.locationParams ?? {};

    navigate({ to: nextRoute.to, query });
  };

  const connectBankFlowProps = {
    initialStep: initialStep ? initialStep : null,
    onFinish: handleFinish,
    canNavigateBackFromMicrodeposits: false,
    plaidRedirect: location.state?.plaidPayload
      ? location.state.plaidPayload
      : null,
    plaidFailure: location.state?.plaidFailure
      ? location.state.plaidFailure
      : false,
    fundingSourceId: fundingSource ? fundingSource : null,
    redirectUrl: '/d/c/connect-bank',
    connectionType: connectionType,
  };

  return (
    <Box width={550} m="0 auto" p="64px 0 100px 0">
      <ConnectBankFlow {...connectBankFlowProps} />
    </Box>
  );
};
