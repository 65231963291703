import { css, styled } from '@m1/liquid-react';
import noop from 'lodash-es/noop';
import * as React from 'react';

import { type PillProps, Pill } from '~/toolbox/Pill';

export type TabProps = {
  as?: React.ElementType;
  isActive?: boolean;
  label: string;
  onClick?: React.MouseEventHandler;
  pill?: PillProps;
};

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type. | TS7006 - Parameter 'variable' implicitly has an 'any' type.
const useTheme = (props, variable) => props.theme.colors[variable];

const StyledTab = styled.div<React.PropsWithChildren<Omit<TabProps, 'label'>>>`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8px 16px;

  height: fit-content;
  align-self: center;

  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  ${(props) => {
    return props.isActive
      ? css`
          color: ${() => useTheme(props, 'foregroundNeutralMain')};
          background-color: ${() => useTheme(props, 'backgroundInfoSubtle')};
          border: 1px ${() => useTheme(props, 'borderInfo')} solid;
        `
      : css`
          color: ${() => useTheme(props, 'foregroundNeutralSecondary')};
          background-color: 'transparent';
          &:hover {
            color: ${() => useTheme(props, 'foregroundNeutralSecondary')};
            background-color: ${() =>
              useTheme(props, 'foregroundNeutralInverse')};
          }
        `;
  }};
`;

export const Tab = ({
  as = 'div',
  isActive = false,
  label,
  onClick = noop,
  pill,
}: TabProps) => {
  const handleClick = (event: React.MouseEvent): void => {
    if (isActive) {
      /* do nothing if tab is already active */
    } else {
      onClick(event);
    }
  };

  return (
    <StyledTab as={as} isActive={isActive} onClick={handleClick}>
      {label}
      {pill && <Pill {...pill} ml={4} />}
    </StyledTab>
  );
};
