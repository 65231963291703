import {
  ApolloClient,
  type NormalizedCache,
  type QueryOptions,
  type QueryResult,
  type RefetchQueriesInclude,
  type RefetchQueriesPromiseResults,
} from '@apollo/client';
import type { SagaIterator } from 'redux-saga';
import { call, getContext } from 'redux-saga/effects';

export function* apolloQuerySaga(
  queryOptions: QueryOptions,
): SagaIterator<QueryResult> {
  const apolloClient: ApolloClient<NormalizedCache> =
    yield getContext('apolloClient');

  if (!apolloClient) {
    throw new Error('apolloQuerySaga required an Apollo Client in context.');
  }

  return yield call(() => {
    return apolloClient.query({ fetchPolicy: 'network-only', ...queryOptions });
  });
}

export function* apolloRefetchSaga(
  queryOptions: RefetchQueriesInclude,
): SagaIterator<RefetchQueriesPromiseResults<QueryResult>> {
  const apolloClient: ApolloClient<NormalizedCache> =
    yield getContext('apolloClient');

  if (!apolloClient) {
    throw new Error('apolloRefetchSaga required an Apollo Client in context.');
  }

  return yield call(() => {
    return apolloClient.refetchQueries({
      include: queryOptions,
    });
  });
}
