import { Box } from '@m1/liquid-react';
import React from 'react';

import { useOnMount } from '~/hooks/useOnMount';
import { clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { CreateTransferProvider } from './CreateTransferProvider';
import { CreateTransferWizard } from './TransferWizard';

export const CreateTransferPage = () => {
  const dispatch = useDispatch();

  // Ensure that form fields are cleared whenever the cover-wizard is initially mounted
  useOnMount(() => {
    dispatch(clearSingleReactHookForm('createTransferDetails'));
  });
  return (
    <Box>
      <CreateTransferProvider>
        <CreateTransferWizard />
      </CreateTransferProvider>
    </Box>
  );
};
