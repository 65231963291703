import * as React from 'react';

import type { OpenInvestJointAccountWizardSteps } from '~/flows/components/OpenInvestJointAccountWizard/types';
import { useWizard, WizardProvider } from '~/flows/wizard';

import {
  AnnualIncome,
  Confirmation,
  ContactInfo,
  Disclosures,
  Employment,
  Identity,
  InvestmentExperience,
  LiquidityNeeds,
  LiquidNetWorth,
  NetWorth,
  RiskTolerance,
  SocialSecurityNumber,
  TimeHorizon,
  TrustedContact,
} from '../steps';

type StepsMap = Record<OpenInvestJointAccountWizardSteps, React.ReactElement>;

export const OpenInvestJointAccountWizard = () => {
  const steps: StepsMap = {
    CONTACT_INFO: <ContactInfo />,
    IDENTITY_INFO: <Identity />,
    SOCIAL_SECURITY_NUMBER: <SocialSecurityNumber />,
    DISCLOSURES: <Disclosures />,
    EMPLOYMENT: <Employment />,
    ANNUAL_INCOME: <AnnualIncome />,
    NET_WORTH: <NetWorth />,
    LIQUID_NET_WORTH: <LiquidNetWorth />,
    INVESTMENT_EXPERIENCE: <InvestmentExperience />,
    RISK_TOLERANCE: <RiskTolerance />,
    TIME_HORIZON: <TimeHorizon />,
    LIQUIDITY_NEEDS: <LiquidityNeeds />,
    TRUSTED_CONTACT: <TrustedContact />,
    CONFIRMATION: <Confirmation />,
  };

  const wizard = useWizard(steps);

  return <WizardProvider value={{ ...wizard }}>{wizard.step}</WizardProvider>;
};
