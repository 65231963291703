import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useGetSecurityQuoteQuery } from '~/graphql/hooks';
import type { SecurityDetailFragment } from '~/graphql/types';

import { SecurityCurrentQuoteDailyChange } from './SecurityCurrentQuoteDailyChange';
import { SecurityCurrentQuoteLastTradeTime } from './SecurityCurrentQuoteLastTradeTime';
import { SecurityCurrentQuotePrice } from './SecurityCurrentQuotePrice';

export const SecurityCurrentQuote = ({
  security,
}: {
  security: SecurityDetailFragment;
}) => {
  const {
    data: quoteData,
    startPolling,
    stopPolling,
  } = useGetSecurityQuoteQuery({
    variables: {
      id: security.id,
    },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    startPolling(4000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const latestQuote = (quoteData?.security as SecurityDetailFragment)
    ?.latestQuote;

  return (
    <div>
      <Flex alignItems="baseline" height={53} color="foregroundNeutralMain">
        <SecurityCurrentQuotePrice
          mr={8}
          lastPrice={latestQuote?.lastPrice}
          isCrypto={security.__typename === 'CryptoAsset'}
        />
        {typeof latestQuote?.percentChangeFromPreviousClosePrice === 'number' &&
          typeof latestQuote.changeFromPreviousClosePrice === 'number' && (
            <SecurityCurrentQuoteDailyChange
              latestQuote={{
                percentChangeFromPreviousClosePrice:
                  latestQuote?.percentChangeFromPreviousClosePrice,
                changeFromPreviousClosePrice:
                  latestQuote.changeFromPreviousClosePrice,
              }}
              size="small"
            />
          )}
      </Flex>
      <SecurityCurrentQuoteLastTradeTime latestQuote={latestQuote} />
    </div>
  );
};
