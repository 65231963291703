import * as React from 'react';

import { AppContext } from '~/AppContext';
import type { AnalyticsEventFragment } from '~/graphql/types';

export const useRecordDisplayAppAnalyticsEvent = (
  appAnalyticsEvent: AnalyticsEventFragment | null | undefined,
): void => {
  const context = React.useContext(AppContext);
  const [alreadyLogged, setAlreadyLogged] = React.useState(false);

  React.useEffect(() => {
    if (appAnalyticsEvent && context && !alreadyLogged) {
      context.analytics?.recordAppAnalyticsEvent(appAnalyticsEvent);
      setAlreadyLogged(true);
    }
  }, [appAnalyticsEvent, alreadyLogged, context]);
};
