import * as React from 'react';

import { useFeedbackFormQuery } from '~/graphql/hooks';
import type { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { NavLinks } from './NavLinks';

export const TopNavSettings = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  const { data } = useFeedbackFormQuery();
  const showForm = data?.viewer.feedbackForm?.showFeedbackForm;

  const items = [
    {
      id: 'profile',
      to: '/d/settings/profile',
      label: 'Profile',
    },
    {
      id: 'accounts',
      to: '/d/settings/accounts',
      label: 'Accounts',
    },
    {
      id: 'documents',
      to: '/d/settings/documents',
      label: 'Documents',
    },
    {
      id: 'billing',
      to: '/d/settings/billing',
      label: 'Platform fee',
    },
    {
      id: 'security',
      to: '/d/settings/security',
      label: 'Security',
    },
    {
      id: 'preferences',
      to: '/d/settings/preferences',
      label: 'Preferences',
    },
  ].concat(
    showForm === true
      ? [
          {
            id: 'feedback',
            to: '/d/settings/feedback',
            label: 'Feedback',
          },
        ]
      : [],
  );

  return (
    <NavLinks
      items={items}
      isActive={(item) => selectedNavigation.activeL3Nav === item.id}
    />
  );
};
