import { Button, type SemanticColorNames } from '@m1/liquid-react';
import { Icon, type MonochromaticIconName } from '@m1/liquid-react/icons';
import copy from 'copy-to-clipboard';
import * as React from 'react';

type CopyIconButtonProps = {
  color: SemanticColorNames;
  value: string;
  name: MonochromaticIconName<'16' | '20' | '24'>;
  size: 'small' | 'medium' | 'large';
  onCopyClickCallback?: (arg0: string) => void;
};

export const CopyIconButton = ({
  value,
  name,
  color,
  size,
  onCopyClickCallback,
}: CopyIconButtonProps) => {
  const handleCopyClick = (copyableString: string): void => {
    copy(copyableString);

    if (onCopyClickCallback) {
      onCopyClickCallback(copyableString);
    }
  };

  return (
    <Button
      size={size}
      kind="text"
      label={
        <Icon
          color={color}
          name={name}
          onClick={() => handleCopyClick(value)}
        />
      }
    />
  );
};
