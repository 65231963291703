/* eslint-disable no-param-reassign */

import { HM, PL, Box } from '@m1/liquid-react';
import * as React from 'react';

import { EmploymentInfoFormModern } from '~/forms';
import { submittedEmploymentInfoModern } from '~/redux/actions';
import type {
  EmploymentInfoForm,
  SubmittedEmploymentInfoModern,
} from '~/redux/actions/newFlows/identityFirst/identityFirstActions.types';
import { useDispatch, useSelector } from '~/redux/hooks';
import { pickByPaths } from '~/utils';

type Props = {
  body?: string;
  holder: 'primary' | 'secondary';
  title: string;
};

export const CollectEmploymentStep = (props: Props) => {
  const { body, holder, title } = props;
  const dispatch = useDispatch();

  const modernInitialValues = useSelector((state) =>
    prepareSavedValues(holder, state.newFlows.IDENTITY_FIRST.input),
  );

  return (
    <Box maxWidth={588} mx="auto">
      <Box py={32}>
        <HM content={title} />
        {body && <PL content={body} mt={32} />}
      </Box>
      <EmploymentInfoFormModern
        initialValues={modernInitialValues.initialValues}
        onSubmit={(formValues: EmploymentInfoForm) => {
          dispatch(
            submittedEmploymentInfoModern(
              prepareFormValues(holder, formValues),
            ),
          );
        }}
      />
    </Box>
  );
};

const prepareSavedValues = (
  holder: string,
  savedValues: Record<string, any>,
): Record<string, any> => {
  const initialValues = pickByPaths(savedValues, makePaths(holder));
  return {
    initialValues,
  };
};

const prepareFormValues = (
  holder: 'primary' | 'secondary',
  formValues: EmploymentInfoForm,
): SubmittedEmploymentInfoModern['payload'] => {
  const employment = formValues.employment;

  if (
    employment.status !== 'EMPLOYED' &&
    employment.status !== 'SELF_EMPLOYED'
  ) {
    employment['employer'] = null;
    employment['occupation'] = null;
  }

  const values =
    holder === 'primary'
      ? { primary: { employment } }
      : { secondary: { employment } };

  return {
    holder,
    values,
  };
};

function makePaths(holder: string): Array<[string, string]> {
  return [['employment', `${holder}.employment`]];
}
