import { Flex, HM, PM, PXL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { AspectRatioFrame } from '~/flows/components/personal-loans/application/components/AspectRatioFrame';
import { useIraAccountTypeQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { AccountSelectionCard } from '../components/AccountSelectionCard';
import { useIRAWizardContext } from '../hooks/useIRAWizardContext';
import type { RetirementAccountValues } from '../types';

export type SelectIRAAccountTypeStepProps = {
  onWizardComplete: (value: RetirementAccountValues) => void;
};

export const SelectIRAAccountTypeStep = ({
  onWizardComplete,
}: SelectIRAAccountTypeStepProps) => {
  const analytics = useAnalytics();
  const { isDesktop, isMedium } = useBreakpoints();
  const { data, loading } = useIraAccountTypeQuery();
  const { goTo } = useIRAWizardContext();

  const iraAccountTypeSelectionScreen =
    data?.viewer.onboarding?.onboardingInvest.iraOnboarding
      .iraAccountTypeSelectionScreen;

  const analyticsEvent =
    iraAccountTypeSelectionScreen?.screenViewedAnalyticsEvent;

  React.useEffect(() => {
    if (analyticsEvent) {
      analytics.recordAppAnalyticsEvent(analyticsEvent);
    }
  }, [analytics, analyticsEvent]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!iraAccountTypeSelectionScreen) {
    return <GenericSystemError />;
  }

  const iraAccountTypes = iraAccountTypeSelectionScreen?.accountTypes;

  return (
    <Flex
      flexDirection="column"
      maxWidth={1200}
      mx="auto"
      my={88}
      px={isMedium ? 48 : 0}
      width="100%"
    >
      <BackArrow
        alignSelf="flex-start"
        content="Back"
        my={48}
        onClick={() => {
          goTo('SELECT_IRA_FUNDING_TYPE');
        }}
      />
      <Flex width="100%">
        <Flex
          flexDirection="column"
          width={isDesktop ? '50%' : '100%'}
          mr={isDesktop ? 126 : 0}
        >
          <HM content={iraAccountTypeSelectionScreen.title} mb={48} />
          <Flex flexDirection="column" mb={24}>
            {iraAccountTypes.filter(isNotNil).map((card, index) => (
              <AccountSelectionCard
                key={index}
                card={card}
                onClick={onWizardComplete}
              />
            ))}
          </Flex>
          {iraAccountTypeSelectionScreen.learnMoreLink && (
            <Flex mb={24}>
              <PXL
                fontWeight={600}
                content={iraAccountTypeSelectionScreen.helpTitle}
                mr={4}
              />
              <PXL
                content={
                  <LinkableLink
                    linkable={iraAccountTypeSelectionScreen.learnMoreLink}
                  />
                }
              />
            </Flex>
          )}
          <Flex flexDirection="column">
            {iraAccountTypeSelectionScreen.disclosures.map(
              (disclosure, index) => (
                <PM
                  color="foregroundNeutralSecondary"
                  content={disclosure}
                  key={index}
                />
              ),
            )}
          </Flex>
        </Flex>
        {isDesktop && (
          <Flex flexDirection="column" p={24} width="50%">
            <AspectRatioFrame ratio="4:3">
              <Illustration name="directToIRA3Web" />
            </AspectRatioFrame>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
