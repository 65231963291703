import * as React from 'react';

import { Navigation } from '~/toolbox/navigation';

const navigationLinks: Array<{
  to: string;
  label: string;
}> = [
  {
    to: '/d/settings/profile/contact',
    label: 'Contact information',
  },
  {
    to: '/d/settings/profile/employment',
    label: 'Employment',
  },
  {
    to: '/d/settings/profile/investment',
    label: 'Investment profile',
  },
  {
    to: '/d/settings/profile/disclosures',
    label: 'Investor disclosures',
  },
  {
    to: '/d/settings/profile/trusted-contact',
    label: 'Trusted contact',
  },
  {
    to: '/d/settings/profile/beneficiaries',
    label: 'Beneficiaries',
  },
];

export const ProfileNavigation = () => {
  return (
    <Navigation
      orientation="vertical"
      items={navigationLinks.map(({ to, label }) => ({
        to,
        label,
      }))}
    />
  );
};
