import * as React from 'react';

import type { GetInitialFundingWizardPreloadQueryResult } from '~/graphql/hooks';

import { InitialFundingContext } from './InitialFundingContext';

export const InitialFundingProvider = ({
  children,
  data,
}: {
  children: React.ReactChild;
  data: GetInitialFundingWizardPreloadQueryResult['data'];
}) => {
  return (
    <InitialFundingContext.Provider value={data}>
      {children}
    </InitialFundingContext.Provider>
  );
};
