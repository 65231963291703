import type { PortfolioSliceRebalanceStatusFragment } from '~/graphql/types';
import { MODALS } from '~/static-constants/modals';

export const MODAL_ACTION_TYPES = {
  SHOW_MODAL: 'SHOW_MODAL' as const,
  HIDE_MODAL: 'HIDE_MODAL' as const,
};

type ShowModalAction<M extends ValueOf<typeof MODALS>, P> = {
  meta: {
    modal: M;
  };
  payload: P;
  type: typeof MODAL_ACTION_TYPES.SHOW_MODAL;
};

type HideModalAction<M> = {
  meta: {
    modal: M;
  };
  type: typeof MODAL_ACTION_TYPES.HIDE_MODAL;
};

export type ShowArchiveModalAction = ShowModalAction<'ARCHIVE_PIE', string>;
export type ShowCashControlModalAction = ShowModalAction<
  'CASH_CONTROL',
  Record<string, any>
>;
export type ShowInactivityWarningModalAction = ShowModalAction<
  'INACTIVITY_WARNING',
  void
>;

export type ShowLiquidationModalAction = ShowModalAction<'LIQUIDATION', string>;
export type ShowLoginOrRegisterModalAction = ShowModalAction<
  'LOGIN_OR_REGISTER',
  { activeTab?: 0 | 1; username?: string; password?: string } // two tabs on login/signup modal
>;
export type ShowOrderActionCancelModalAction = ShowModalAction<
  'ORDER_ACTION_CANCEL',
  string
>;
export type ShowOrderActionLockedModalAction = ShowModalAction<
  'ORDER_ACTION_LOCKED',
  string
>;
export type ShowPositionPortfolioLinksModalAction = ShowModalAction<
  'POSITION_PORTFOLIO_LINKS',
  Array<string>
>;
export type ShowReacceptLegalTermsModalAction = ShowModalAction<
  'REACCEPT_LEGAL_TERMS',
  void
>;
export type ShowRebalanceActionCancelModalAction = ShowModalAction<
  'REBALANCE_ACTION_CANCEL',
  { portfolioSliceId: string }
>;
export type ShowRealanceActionLockedModalAction = ShowModalAction<
  'REBALANCE_ACTION_LOCKED',
  {
    portfolioSliceId: string;
    rebalanceStatus: PortfolioSliceRebalanceStatusFragment | undefined;
  }
>;
export type ShowRebalanceActionSetModalAction = ShowModalAction<
  'REBALANCE_ACTION_SET',
  {
    portfolioSliceId: string;
    rebalanceStatus: PortfolioSliceRebalanceStatusFragment | undefined;
  }
>;
export type ShowRebalancePortfolioModalAction = ShowModalAction<
  'REBALANCE_PORTFOLIO',
  void
>;
export type ShowResearchAnnoucementModalAction = ShowModalAction<
  'RESEARCH_ANNOUNCEMENT',
  void
>;
export type ShowSecurityStatusModalAction = ShowModalAction<
  'SECURITY_STATUS',
  string
>;
export type ShowInvestAccontModalAction = ShowModalAction<
  'SELECT_INVEST_ACCOUNT',
  string
>;

export type ShowSharePieModalAction = ShowModalAction<'SHARE_PIE', string>;
export type ShowTransferScheduleAction = ShowModalAction<
  'TRANSFER_SCHEDULE',
  TransferScheduleModalPayload
>;

export type ShowTradeDisclosuresModalAction = ShowModalAction<
  'TRADE_DISCLOSURES',
  Record<string, any>
>;

export type ShowModalActions = {
  [MODALS.ARCHIVE_PIE]: ShowArchiveModalAction;
  [MODALS.CASH_CONTROL]: ShowCashControlModalAction;
  [MODALS.INACTIVITY_WARNING]: ShowInactivityWarningModalAction;
  [MODALS.LOGIN_OR_REGISTER]: ShowLoginOrRegisterModalAction;
  [MODALS.LIQUIDATION]: ShowLiquidationModalAction;
  [MODALS.ORDER_ACTION_CANCEL]: ShowOrderActionCancelModalAction;
  [MODALS.ORDER_ACTION_LOCKED]: ShowOrderActionLockedModalAction;
  [MODALS.POSITION_PORTFOLIO_LINKS]: ShowPositionPortfolioLinksModalAction;
  [MODALS.REACCEPT_LEGAL_TERMS]: ShowReacceptLegalTermsModalAction;
  [MODALS.REBALANCE_ACTION_CANCEL]: ShowRebalanceActionCancelModalAction;
  [MODALS.REBALANCE_ACTION_LOCKED]: ShowRealanceActionLockedModalAction;
  [MODALS.REBALANCE_ACTION_SET]: ShowRebalanceActionSetModalAction;
  [MODALS.REBALANCE_PORTFOLIO]: ShowRebalancePortfolioModalAction;
  [MODALS.RESEARCH_ANNOUNCEMENT]: ShowResearchAnnoucementModalAction;
  [MODALS.SECURITY_STATUS]: ShowSecurityStatusModalAction;
  [MODALS.SELECT_INVEST_ACCOUNT]: ShowInvestAccontModalAction;
  [MODALS.SHARE_PIE]: ShowSharePieModalAction;
  [MODALS.TRADE_DISCLOSURES]: ShowTradeDisclosuresModalAction;
  [MODALS.TRANSFER_SCHEDULE]: ShowTransferScheduleAction;
};

type TransferScheduleModalPayload = {
  amount: number;
  date: string;
  from: string;
  to: string;
};

export interface ShowModalActionCreator {
  (m: 'ARCHIVE_PIE', p: string): ShowArchiveModalAction;
  (m: 'CASH_CONTROL', p: Record<string, any>): ShowCashControlModalAction;
  (m: 'INACTIVITY_WARNING'): ShowInactivityWarningModalAction;
  (
    m: 'LOGIN_OR_REGISTER',
    p: { activeTab: 0 | 1 },
  ): ShowLoginOrRegisterModalAction;
  (m: 'LIQUIDATION'): ShowLiquidationModalAction;
  (m: 'ORDER_ACTION_CANCEL', p: string): ShowOrderActionCancelModalAction;
  (m: 'ORDER_ACTION_LOCKED', p: string): ShowOrderActionLockedModalAction;
  (
    m: 'POSITION_PORTFOLIO_LINKS',
    p: Array<string>,
  ): ShowPositionPortfolioLinksModalAction;
  (m: 'REACCEPT_LEGAL_TERMS'): ShowReacceptLegalTermsModalAction;
  (
    m: 'REBALANCE_ACTION_CANCEL',
    p: {
      portfolioSliceId: string;
    },
  ): ShowRebalanceActionCancelModalAction;
  (
    m: 'REBALANCE_ACTION_LOCKED',
    p: {
      portfolioSliceId: string;
      rebalanceStatus: PortfolioSliceRebalanceStatusFragment | undefined;
    },
  ): ShowRealanceActionLockedModalAction;
  (
    m: 'REBALANCE_ACTION_SET',
    p: {
      portfolioSliceId: string;
      rebalanceStatus: PortfolioSliceRebalanceStatusFragment | undefined;
    },
  ): ShowRebalanceActionSetModalAction;
  (m: 'REBALANCE_PORTFOLIO'): ShowRebalancePortfolioModalAction;
  (m: 'RESEARCH_ANNOUNCEMENT'): ShowResearchAnnoucementModalAction;
  (m: 'SECURITY_STATUS', p: string): ShowSecurityStatusModalAction;
  (m: 'SELECT_INVEST_ACCOUNT', p: string): ShowInvestAccontModalAction;
  (m: 'SHARE_PIE', p: string): ShowSharePieModalAction;
  (m: 'TRADE_DISCLOSURES'): ShowTradeDisclosuresModalAction;
  (
    m: 'TRANSFER_SCHEDULE',
    p: TransferScheduleModalPayload,
  ): ShowTransferScheduleAction;
}

export type HideArchiveModalAction = HideModalAction<'ARCHIVE_PIE'>;
export type HideCashControlModalAction = HideModalAction<'CASH_CONTROL'>;
export type HideInactivityWarningModalAction =
  HideModalAction<'INACTIVITY_WARNING'>;
export type HideLoginOrRegisterModalAction =
  HideModalAction<'LOGIN_OR_REGISTER'>;
export type HideLiquidationModalAction = HideModalAction<'LIQUIDATION'>;
export type HideOrderActionCancelModalAction =
  HideModalAction<'ORDER_ACTION_CANCEL'>;
export type HideOrderActionLockedModalAction =
  HideModalAction<'ORDER_ACTION_LOCKED'>;
export type HidePositionPortfolioLinksModalAction =
  HideModalAction<'POSITION_PORTFOLIO_LINKS'>;
export type HideReacceptLegalTermsModalAction =
  HideModalAction<'REACCEPT_LEGAL_TERMS'>;
export type HideRebalanceActionCancelModalAction =
  HideModalAction<'REBALANCE_ACTION_CANCEL'>;
export type HideRealanceActionLockedModalAction =
  HideModalAction<'REBALANCE_ACTION_LOCKED'>;
export type HideRebalanceActionSetModalAction =
  HideModalAction<'REBALANCE_ACTION_SET'>;
export type HideRebalancePortfolioModalAction =
  HideModalAction<'REBALANCE_PORTFOLIO'>;
export type HideResearchAnnoucementModalAction =
  HideModalAction<'RESEARCH_ANNOUNCEMENT'>;
export type HideSecurityStatusModalAction = HideModalAction<'SECURITY_STATUS'>;
export type HideSelectInvestAccountModalAction =
  HideModalAction<'SELECT_INVEST_ACCOUNT'>;
export type HideSharePieModalAction = HideModalAction<'SHARE_PIE'>;
export type HideTransferScheduleModalAction =
  HideModalAction<'TRANSFER_SCHEDULE'>;
export type HideTradeDisclosuresModalAction =
  HideModalAction<'TRADE_DISCLOSURES'>;

export type HideModalActions =
  | HideArchiveModalAction
  | HideCashControlModalAction
  | HideInactivityWarningModalAction
  | HideLiquidationModalAction
  | HideLoginOrRegisterModalAction
  | HideOrderActionCancelModalAction
  | HideOrderActionLockedModalAction
  | HidePositionPortfolioLinksModalAction
  | HideReacceptLegalTermsModalAction
  | HideRebalanceActionCancelModalAction
  | HideRealanceActionLockedModalAction
  | HideRebalanceActionSetModalAction
  | HideRebalancePortfolioModalAction
  | HideResearchAnnoucementModalAction
  | HideSecurityStatusModalAction
  | HideSelectInvestAccountModalAction
  | HideSharePieModalAction
  | HideTradeDisclosuresModalAction
  | HideTransferScheduleModalAction;

interface HideModalActionCreator {
  (m: 'ARCHIVE_PIE'): HideArchiveModalAction;
  (m: 'CASH_CONTROL'): HideCashControlModalAction;
  (m: 'INACTIVITY_WARNING'): HideInactivityWarningModalAction;
  (m: 'LOGIN_OR_REGISTER'): HideLoginOrRegisterModalAction;
  (m: 'LIQUIDATION'): HideLiquidationModalAction;
  (m: 'ORDER_ACTION_CANCEL'): HideOrderActionCancelModalAction;
  (m: 'ORDER_ACTION_LOCKED'): HideOrderActionLockedModalAction;
  (m: 'POSITION_PORTFOLIO_LINKS'): HidePositionPortfolioLinksModalAction;
  (m: 'REACCEPT_LEGAL_TERMS'): HideReacceptLegalTermsModalAction;
  (m: 'REBALANCE_ACTION_CANCEL'): HideRebalanceActionCancelModalAction;
  (m: 'REBALANCE_ACTION_LOCKED'): HideRealanceActionLockedModalAction;
  (m: 'REBALANCE_ACTION_SET'): HideRebalanceActionSetModalAction;
  (m: 'REBALANCE_PORTFOLIO'): HideRebalancePortfolioModalAction;
  (m: 'RESEARCH_ANNOUNCEMENT'): HideResearchAnnoucementModalAction;
  (m: 'SECURITY_STATUS'): HideSecurityStatusModalAction;
  (m: 'SELECT_INVEST_ACCOUNT'): HideSelectInvestAccountModalAction;
  (m: 'SHARE_PIE'): HideSharePieModalAction;
  (m: 'TRANSFER_SCHEDULE'): HideTransferScheduleModalAction;
  (m: 'TRADE_DISCLOSURES'): HideTradeDisclosuresModalAction;
}

export type ModalAction =
  | ShowModalActions[keyof ShowModalActions]
  | HideModalActions;

// @ts-expect-error - TS2322 - Type '<M extends ValueOf<{ ARCHIVE_PIE: "ARCHIVE_PIE"; CASH_CONTROL: "CASH_CONTROL"; INACTIVITY_WARNING: "INACTIVITY_WARNING"; LOGIN_OR_REGISTER: "LOGIN_OR_REGISTER"; LIQUIDATION: "LIQUIDATION"; ORDER_ACTION_CANCEL: "ORDER_ACTION_CANCEL"; ... 12 more ...; }>, P>(modal: M, pa...' is not assignable to type 'ShowModalActionCreator'.
export const showModal: ShowModalActionCreator = <
  M extends ValueOf<typeof MODALS>,
  P,
>(
  modal: M,
  payload: P,
): ShowModalAction<M, P> => ({
  type: MODAL_ACTION_TYPES.SHOW_MODAL,
  meta: {
    modal,
  },
  payload,
});

export const hideModal: HideModalActionCreator = <
  M extends ValueOf<typeof MODALS>,
>(
  modal: M,
): HideModalAction<M> => ({
  type: MODAL_ACTION_TYPES.HIDE_MODAL,
  meta: {
    modal,
  },
});
