import { Box } from '@m1/liquid-react';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import * as React from 'react';

import type { Notation } from '~/components/charts/secondary-historical-chart';
import type { HistoricalQuote } from '~/graphql/types';

import { Dividends } from './Dividends';
import { Splits } from './Splits';

const moment = extendMoment(Moment as any);

type HistoricalQuotePartial = {
  date: HistoricalQuote['date'];
  split?:
    | Pick<NonNullable<HistoricalQuote['split']>, 'toFactor' | 'forFactor'>
    | null
    | undefined;
  dividends: Pick<HistoricalQuote['dividends'][number], 'amount' | 'type'>[];
};

export function makeNotationFromQuote(quote: HistoricalQuotePartial): Notation {
  return {
    date: quote.date,
    label: makeNotationLabelFromQuote(quote),
    value: makeNotationContentFromQuote(quote),
  };
}

function makeNotationLabelFromQuote(quote: HistoricalQuotePartial): string {
  if (quote.dividends && quote.dividends.length > 0 && quote.split) {
    return 'D/S';
  } else if (quote.dividends && quote.dividends.length > 0) {
    return ' D';
  } else if (quote.split) {
    return ' S';
  }

  return 'D';
}

function makeNotationContentFromQuote(quote: HistoricalQuotePartial) {
  return (
    <Box>
      <Box>{moment(quote.date).format('l')}</Box>
      <Box>{quote.dividends && <Dividends dividends={quote.dividends} />}</Box>
      <Box>{quote.split && <Splits split={quote.split} />}</Box>
    </Box>
  );
}
