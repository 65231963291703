import * as launchDarkly from 'launchdarkly-js-client-sdk';

type LaunchDarklyConfig = {
  anonUserKey: string;
  clientSideId: string;
};

const anonContext = (
  anonymousUserId: string,
): launchDarkly.LDMultiKindContext => ({
  kind: 'multi',
  device: {
    kind: 'device',
    client: 'm1-web',
    version: __VERSION__,
    key: anonymousUserId,
    source: 'web',
    anonymous: true,
  },
  user: {
    kind: 'user',
    key: anonymousUserId,
    anonymous: true,
  },
});

const loggedInContext = (
  correlationId: string,
  anonymousUserId: string,
  email: string,
): launchDarkly.LDMultiKindContext => ({
  kind: 'multi',
  account: {
    kind: 'account',
    key: correlationId,
    email,
    source: 'web',
  },
  device: {
    kind: 'device',
    client: 'm1-web',
    version: __VERSION__,
    key: anonymousUserId,
    source: 'web',
  },
  user: {
    kind: 'user',
    key: correlationId,
    client: 'm1-web',
    version: __VERSION__,
    userId: correlationId,
    email,
  },
});

export class LaunchDarkly {
  _userIdentified: boolean = false;
  _launchDarklyReady: boolean = false;
  _client: launchDarkly.LDClient;
  _userIsAnon: boolean = true;
  _anonymousUserId: string;

  constructor(launchDarklyConfig: LaunchDarklyConfig, anonymousUserId: string) {
    this._anonymousUserId = anonymousUserId;
    this._client = launchDarkly.initialize(
      launchDarklyConfig.clientSideId,
      anonContext(anonymousUserId),
    );
    this._client.on(
      'initialized',
      () => {
        this._launchDarklyReady = true;
        this._userIdentified = true;
      },
      null,
    );
  }

  checkUserIdentified(): boolean {
    return this._userIdentified;
  }

  checkUserIsAnon(): boolean {
    return this._userIsAnon;
  }

  checkLaunchDarklyReady(): boolean {
    return this._launchDarklyReady;
  }

  logout(): void {
    // NOTE: This works because we log the user out when we call document.location.reload
    // If we change the logout logic in the future this may need to be revisited.
    this._client.close();
  }

  waitUntilReady(): Promise<void> {
    return this._client.waitUntilReady();
  }

  identifyUser(key: string, email: string): void {
    this._client.identify(
      loggedInContext(key, this._anonymousUserId, email),
      // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string | undefined'.
      null,
      (err) => {
        if (!err) {
          this._userIsAnon = false;
          this._userIdentified = true;
        }
      },
    );
  }

  evaluateFlag<T>(flagName: string, defaultValue: T): T {
    if (!this.checkLaunchDarklyReady()) {
      return defaultValue;
    }
    return this._client.variation(flagName, defaultValue);
  }
}
