import { Flex } from '@m1/liquid-react';
import React from 'react';

import {
  SliceableAddToPortfolioButton,
  SliceableWatchlistActionButton,
} from '~/components/pie';
import type { SliceableCellFragment, SliceableTypeEnum } from '~/graphql/types';

type AddToPortfolioButtonProps = {
  sliceable: SliceableCellFragment;
  securityId: string;
  type: SliceableTypeEnum;
  disableWatchlist?: boolean;
};

export const AddToPortfolioButton = ({
  securityId,
  type,
  disableWatchlist = false,
  sliceable,
}: AddToPortfolioButtonProps) => (
  <Flex mt={76} flexDirection="column" ml={130} minWidth={170} maxWidth={280}>
    <SliceableAddToPortfolioButton
      id={securityId}
      type={type}
      sliceable={sliceable}
    />
    {!disableWatchlist && (
      <SliceableWatchlistActionButton
        sliceableId={securityId}
        sliceableType={type}
      />
    )}
  </Flex>
);
