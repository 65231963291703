import { Button } from '@m1/liquid-react';
import React from 'react';

import type { SliceableTypeEnum } from '~/graphql/types';
import { addToWatchlist, removeFromWatchlist } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

type Props = {
  sliceableId: string;
  sliceableType: SliceableTypeEnum;
};

export const SliceableWatchlistActionButton = ({
  sliceableId,
  sliceableType,
}: Props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.watchlist);
  const isInWatchList = list.includes(sliceableId);
  const handleOnClick = () => {
    if (isInWatchList) {
      dispatch(
        removeFromWatchlist({
          securityId: sliceableId,
          securityType: sliceableType,
        }),
      );
    } else {
      dispatch(
        addToWatchlist({
          securityId: sliceableId,
          securityType: sliceableType,
        }),
      );
    }
  };
  return (
    <Button
      mt={10}
      kind="secondary"
      label={isInWatchList ? 'Watching' : 'Watch'}
      onClick={handleOnClick}
      size="medium"
      data-testid="watchlist-action-button"
    />
  );
};
