import type { SagaIterator } from 'redux-saga';

import type { BootstrapAuthedUserQuery } from '~/graphql/types';
import { selectedInitialAccounts, ACTION_TYPES } from '~/redux/actions';

import { put } from '../effects';

/*
  Select an "initial" Invest and Borrow account. The selected account will
  be the first a user sees when landing on Invest or Borrow.
*/

export function* selectInitialAccounts({
  viewer,
}: BootstrapAuthedUserQuery): SagaIterator<void> {
  let firstViewedInvestAccountId = null;
  let investAccountId = null;
  let cryptoAccountId = null;
  if (Array.isArray(viewer.accounts?.edges)) {
    for (const edge of viewer.accounts.edges) {
      if (edge?.node && edge.node.registration !== 'CRYPTO') {
        if (edge.node.isActive) {
          firstViewedInvestAccountId = edge.node.id;
          investAccountId = investAccountId || edge.node.id;
        }
        if (investAccountId) {
          break;
        }
      }
    }

    if (!investAccountId) {
      investAccountId = firstViewedInvestAccountId;
    }
    const cryptoAccount = viewer.accounts?.edges?.find(
      (edge) => edge?.node?.registration === 'CRYPTO',
    );
    if (cryptoAccount) {
      cryptoAccountId = cryptoAccount.node?.id;
    }
  }

  let borrowAccountId = null;
  if (Array.isArray(viewer.borrow?.borrowAccounts?.edges)) {
    for (const edge of viewer.borrow.borrowAccounts.edges) {
      // Prioritize borrow account that is active vs one that is eligible
      if (edge?.node && edge.node.hasBorrowedBefore) {
        borrowAccountId = edge.node.id;
        break; // Remove once new marketing screen is released
      } else if (edge?.node) {
        borrowAccountId = edge.node.id;
        break;
      }
    }
  }

  let creditAccountId = null;
  if (viewer.credit?.activeAccount) {
    if (viewer.credit.activeAccount.id) {
      creditAccountId = viewer.credit.activeAccount.id;
    }
  }

  let savingsAccountId = null;
  if (viewer.save?.savings?.hasSavingsAccounts) {
    const savingsEdges = viewer.save?.savings.savingsAccounts?.edges;
    if (savingsEdges) {
      savingsAccountId = savingsEdges[0]?.node?.id;
    }
  }

  let personalLoanAccountId = null;
  if (Array.isArray(viewer.borrow?.personalLoans?.loans?.edges)) {
    for (const edge of viewer.borrow.personalLoans.loans.edges) {
      // If a user has a withdrawn loan treat it as if they don't have a loan ID
      if (edge?.node && edge.node.status !== 'WITHDRAWN') {
        personalLoanAccountId = edge.node.id;
        break;
      }
    }
  }

  const netWorthNodeId = viewer.netWorth?.id ?? null;

  const localStorageActiveAccount = localStorage.getItem(
    ACTION_TYPES.SET_ACTIVE_INVEST_ACCOUNT,
  );

  if (localStorageActiveAccount) {
    const parsedLocalStorageActiveAccount = JSON.parse(
      localStorageActiveAccount,
    );

    const investAccountIdFromStorage: string | null | undefined =
      parsedLocalStorageActiveAccount?.global?.investAccountId;

    // Two checks before we use the ID from localStorage.
    // 1. Does it exist?
    // 2. Is the account it references an active account?
    if (
      investAccountIdFromStorage &&
      viewer.accounts?.edges?.some((edge) => {
        if (!edge?.node) {
          return false;
        }
        return (
          edge.node.id === investAccountIdFromStorage && edge.node.isActive
        );
      })
    ) {
      investAccountId = parsedLocalStorageActiveAccount.global.investAccountId;
    }
  }

  yield put(
    selectedInitialAccounts({
      investAccountId,
      borrowAccountId,
      creditAccountId,
      cryptoAccountId,
      savingsAccountId,
      personalLoanAccountId,
      netWorthNodeId,
    }),
  );
}
