import { PL, Flex, PXL, PM, styled, theme, Button } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { EmailVerificationCard } from '~/components/cards/EmailVerificationCard/EmailVerificationCard';
import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import {
  useLoanTermsAndAcceptPageQuery,
  usePersonalLoansDirectQuery,
  useAcceptPersonalLoanMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Link } from '~/toolbox/link';
import { Pill } from '~/toolbox/Pill';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from '../components/StyledHeader';
import { WithdrawApplicationModal } from '../components/WithdrawApplicationModal';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

import { SingleLineItem } from './components';

const ButtonContainer = styled(Flex)`
  margin-top: 24px;
  margin-bottom: 24px;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    justify-content: start;
    flex-direction: row;
    margin-top: 48px;
  }
`;

interface AdditionalDocument {
  url: string;
  title: string;
}

export const AcceptLoanOffer = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { addToast } = useToast();
  const { goTo } = usePersonalLoanDirectWizardContext();

  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [requiredDocumentError, setRequiredDocumentError] =
    React.useState<boolean>(false);
  const [viewedLoanAgreement, setViewedLoanAgreement] =
    React.useState<boolean>(false);
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const { data: questionsData, loading: questionsLoading } =
    usePersonalLoansDirectQuery();

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/loan-terms');
  }, [analytics]);

  const { data, loading } = useLoanTermsAndAcceptPageQuery({
    variables: {
      applicationId,
    },
    errorPolicy: 'all',
  });

  const [acceptPersonalLoan, { loading: loadingMutation }] =
    useAcceptPersonalLoanMutation();

  if (loading || questionsLoading || loadingMutation) {
    return <Spinner fullScreen centered />;
  }

  const loanId = data?.viewer.borrow?.personalLoans?.application?.loan?.id;
  const loanTerms =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.viewLoanTerms;
  const questions =
    questionsData?.viewer?.borrow?.personalLoans?.applicationFlowDirect
      ?.questions;
  const loanAcceptanceSignature = loanTerms?.additionalTerms?.signature;
  let props;

  if (questions) {
    props = getQuestionProps({
      question: 'ACCEPT_LOAN_OFFER',
      questions,
    });
  }

  const isPrimaryEmailVerified = data?.viewer?.user?.isPrimaryEmailVerified;
  const { title, subtitle, continueLabel, nextQuestionType } = props || {};

  if (
    !loanId ||
    !loanTerms ||
    !loanAcceptanceSignature ||
    !nextQuestionType ||
    !loanTerms.dynamicLoanAgreementPresignedUrl
  ) {
    return <GenericSystemError />;
  }

  const {
    loanDetails = [],
    paymentSchedule = [],
    viewLoanDocumentsHeader,
    notViewedErrorMessage,
    dynamicLoanAgreementPresignedUrl,
  } = loanTerms;

  const handleSubmit = () => {
    if (viewedLoanAgreement) {
      acceptPersonalLoan({
        variables: {
          input: {
            loanAcceptanceSignature,
            loanId,
          },
        },
        onCompleted: (data) => {
          if (
            data?.acceptPersonalLoan?.outcome?.loanDisbursement &&
            data.acceptPersonalLoan.didSucceed
          ) {
            goTo(nextQuestionType || 'RECEIPT');
          } else {
            addToast({
              content: 'Failed to accept loan offer. Please try again.',
              kind: 'alert',
              duration: 'short',
            });
          }
        },
        onError: () => {
          addToast({
            content: 'Failed to accept loan offer. Please try again.',
            kind: 'alert',
            duration: 'short',
          });
        },
      });
    } else {
      setRequiredDocumentError(true);
    }
  };

  return (
    <>
      {!isPrimaryEmailVerified && (
        <EmailVerificationCard
          margin="0"
          content="You can't accept a loan offer until your email is verified. Please check your inbox."
        />
      )}
      <StyledHeader
        content={title}
        mb={8}
        mt={isPrimaryEmailVerified ? 0 : 24}
      />
      <PL content={subtitle} />
      <Flex mt={24}>
        <Icon name="documents24" />
        <PXL content="Loan summary" fontWeight={600} ml={8} />
      </Flex>

      <ul>
        {loanDetails?.reduce((lineItems, loanSummaryItem, idx) => {
          if (!loanSummaryItem) {
            return lineItems;
          }

          lineItems.push(
            <SingleLineItem
              padding={idx === 0 ? '0px 16px 16px 16px' : '16px'}
              key={loanSummaryItem?.title}
              {...loanSummaryItem}
            />,
          );

          return lineItems;
        }, [] as React.ReactNode[])}
      </ul>

      <Flex mt={24}>
        <Icon name="calendar24" />
        <PXL content="Payment schedule" fontWeight={600} ml={8} />
      </Flex>

      <ul>
        {paymentSchedule?.reduce((lineItems, paymentScheduleItem, idx) => {
          if (!paymentScheduleItem) {
            return lineItems;
          }

          lineItems.push(
            <SingleLineItem
              padding={idx === 0 ? '0px 16px 16px 16px' : '16px'}
              key={paymentScheduleItem?.label}
              {...paymentScheduleItem}
            />,
          );

          return lineItems;
        }, [] as React.ReactNode[])}
      </ul>

      <PL mb={24} pt={24}>
        {`Offers are good for 30 days, or you can `}
        <Button
          kind="link"
          style={{
            textDecoration: 'underline',
          }}
          onClick={() => setModalIsOpen(true)}
          label="withdraw"
        />
        {` your application now.`}
      </PL>
      <Flex>
        <PL content={viewLoanDocumentsHeader} fontWeight={600} mb={16} />
      </Flex>
      <Flex>
        <LinkableLink
          linkable={dynamicLoanAgreementPresignedUrl}
          fontSize="16px"
          fontWeight={400}
          textDecoration="underline"
          onClick={() => {
            setRequiredDocumentError(false);
            setViewedLoanAgreement(true);
          }}
        />
        <Pill
          kind={viewedLoanAgreement ? 'success' : 'caution'}
          label={viewedLoanAgreement ? 'Viewed' : 'Not viewed'}
          ml={8}
        />
      </Flex>
      <Flex flexDirection="column">
        {loanTerms?.additionalTerms?.documents.map(
          (additionalDocument: AdditionalDocument) => (
            <Flex key={additionalDocument.url} mt={16}>
              <Link
                to={additionalDocument.url}
                target="_blank"
                fontSize="16px"
                fontWeight={400}
                textDecoration="underline"
              >
                {additionalDocument.title}
              </Link>
            </Flex>
          ),
        )}
      </Flex>

      {requiredDocumentError && (
        <Flex mt={16} alignItems="center">
          <Icon name="alert16" color="foregroundCritical" />
          <PM ml={4} color="critical" content={notViewedErrorMessage?.copy} />
        </Flex>
      )}

      <ButtonContainer>
        <ResponsiveButton
          label={continueLabel ?? 'Continue'}
          onClick={handleSubmit}
          size="large"
          mb={16}
        />
        <Flex justifyContent="center">
          <ResponsiveButton
            kind="text"
            size="large"
            label="Save and close"
            onClick={() => {
              navigate({ to: '/d/home' });
            }}
          />
        </Flex>
      </ButtonContainer>

      <WithdrawApplicationModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loanId={loanId}
        applicationId={applicationId}
      />
    </>
  );
};
