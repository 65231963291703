import { Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { Cell } from '~/toolbox/cell';

import type { AcatInstanceSteps, AcatInstanceStatus } from '../types';

import { AcatStepFragment } from './AcatStepFragment';
import { StepParticipant } from './StepParticipant';

type AcatStepCardProps = {
  steps: AcatInstanceSteps;
  status: AcatInstanceStatus | null | undefined;
  firstStep: AcatInstanceSteps[number];
};

export const AcatStepCard = ({
  steps,
  status,
  firstStep,
}: AcatStepCardProps) => {
  return (
    <Card p={16} mb={32}>
      <StepParticipant>
        <Cell
          title={firstStep?.title}
          subtitle={firstStep?.subtitle}
          icon={<Icon name="accountBankPrimary32" />}
        />
      </StepParticipant>
      {steps.map(
        (step) =>
          step && (
            <AcatStepFragment step={step} status={status} key={step.title} />
          ),
      )}
    </Card>
  );
};
