import {
  Button,
  Flex,
  HXS,
  PM,
  PXL,
  styled,
  Card,
  ModalContent,
  Modal,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useMoveSlicesConfirmationModalWrapperQuery } from '~/graphql/hooks';
import { preparePieTreeForUpdate } from '~/pie-trees';
import type { MoveSlicesInput } from '~/redux/actions/newFlows/portfolioEditor/portfolioEditorActions.types';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { DestinationPieChangesSection } from './components/DestinationPieChangesSection';
import { NextTradeWindowSection } from './components/NextTradeWindowSection';
import { SourcePieChangesSection } from './components/SourcePieChangesSection';

type MoveSlicesConfirmationModalWrapperProps = {
  active: boolean;
  onCancel: () => void;
  onConfirm: (arg0: MoveSlicesInput) => void;
};

const StyledOverflowFlex = styled(Flex)`
  overflow: scroll;
`;

/**
 * This effect handles disabling the CTA in order to prevent multiple user clicks and
 * re-enabling the CTA when the modal is closed to allow the user to click again.
 */
function useSetEnabledStateOfCta(
  isModalOpen: boolean,
): [boolean, (arg0: boolean) => void] {
  const [enableCta, setEnableCta] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!isModalOpen && !enableCta) {
      setEnableCta(true);
    }
  }, [enableCta, isModalOpen]);

  return [enableCta, setEnableCta];
}

export const MoveSlicesConfirmationModal = ({
  active,
  onCancel,
  onConfirm,
}: MoveSlicesConfirmationModalWrapperProps) => {
  const [enableCta, setEnableCta] = useSetEnabledStateOfCta(active);
  const accountId = useSelector((state) => state.global.activeAccountId);
  const {
    destinationPieSerialized,
    moveSliceIds,
    sourcePieBeforeUpdatesSerialized,
    sourcePieSerialized,
    sourcePiePortfolioSliceId,
    destinationPiePortfolioSliceId,
  } = useSelector<MoveSlicesInput>((state) => {
    const {
      destinationPie,
      moveSliceIds,
      sourcePie,
      sourcePiePortfolioSliceId,
      destinationPiePortfolioSliceId,
      sourcePieBeforeUpdates,
    } = state.newFlows.PORTFOLIO_EDITOR;
    if (!sourcePie || !destinationPie) {
      throw new Error(
        'Unable to find find source and destination pies in store',
      );
    }

    return {
      destinationPieSerialized: JSON.stringify(
        preparePieTreeForUpdate(destinationPie),
      ),
      sourcePieSerialized: JSON.stringify(preparePieTreeForUpdate(sourcePie)),
      sourcePieBeforeUpdatesSerialized: JSON.stringify(
        preparePieTreeForUpdate(sourcePieBeforeUpdates),
      ),
      moveSliceIds,
      sourcePiePortfolioSliceId: sourcePiePortfolioSliceId,
      destinationPiePortfolioSliceId: destinationPiePortfolioSliceId,
    };
  });

  const { data, loading } = useMoveSlicesConfirmationModalWrapperQuery({
    variables: {
      accountId: accountId as string,
      sourcePieSerialized,
      destinationPieSerialized,
      moveSliceIds,
    },
    skip: !active || !accountId,
  });

  if (loading || data?.node?.__typename !== 'Account') {
    return null;
  }

  const moveSlicesChangesConfirmation =
    data.node.moveSlices?.moveSlicesChangesConfirmation;

  if (!moveSlicesChangesConfirmation) {
    return null;
  }

  const {
    title,
    sourcePieChangesSection,
    sourcePieDetails,
    destinationPieChangesSection,
    nextAvailableTradeWindowSection,
    infoCard,
    cancelLabel,
    confirmLabel,
  } = moveSlicesChangesConfirmation;

  return (
    <Modal open={active} onClose={onCancel}>
      <ModalContent width="wide">
        <Flex flexDirection="column">
          <Flex alignItems="center" justifyContent="center" mt={16} mb={32}>
            <HXS>{title}</HXS>
          </Flex>
          {sourcePieDetails && (
            <Flex alignItems="center" justifyContent="center" mb={16}>
              <Icon name="rootPies24" />
              <PXL fontWeight={600} ml={8}>
                {sourcePieDetails.name}
              </PXL>
            </Flex>
          )}
          <StyledOverflowFlex
            flexDirection="column"
            maxHeight={500}
            width="100%"
          >
            {sourcePieChangesSection && (
              <SourcePieChangesSection
                sourceChanges={sourcePieChangesSection}
              />
            )}
            {destinationPieChangesSection && (
              <DestinationPieChangesSection
                destinationChanges={destinationPieChangesSection}
              />
            )}
            {nextAvailableTradeWindowSection && (
              <NextTradeWindowSection
                nextTradeWindow={nextAvailableTradeWindowSection}
              />
            )}
            {infoCard?.message && (
              <Card
                borderColor="transparent"
                backgroundColor="backgroundNeutralTertiary"
                py={12}
                px={16}
                mb={32}
              >
                <PM color="foregroundNeutralSecondary">{infoCard.message}</PM>
              </Card>
            )}
          </StyledOverflowFlex>
        </Flex>
        <ButtonGroup mt={32}>
          <Button
            kind="secondary"
            label={cancelLabel || 'Cancel'}
            size="large"
            onClick={onCancel}
          />
          <Button
            label={confirmLabel || 'Confirm'}
            disabled={!enableCta}
            size="large"
            onClick={() => {
              setEnableCta(false);

              onConfirm({
                destinationPieSerialized,
                moveSliceIds,
                sourcePieBeforeUpdatesSerialized,
                sourcePieSerialized,
                sourcePiePortfolioSliceId,
                destinationPiePortfolioSliceId,
              });
            }}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
