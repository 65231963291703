import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import {
  ResendEmailVerificationDocument,
  type ResendEmailVerificationMutationResult,
} from '~/graphql/hooks';
import type { ResendEmailVerificationInput } from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import {
  type ClickedResendVerificationEmailAction,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';
import type { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../apolloMutationSaga';

export function* clickedResendVerificationEmail(
  payload: ClickedResendVerificationEmailAction['payload'],
): SagaIterator<void> {
  yield put(showLoadingSpinner());
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  try {
    const result: ResendEmailVerificationMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: ResendEmailVerificationDocument,
        variables: {
          input: {} satisfies ResendEmailVerificationInput,
        },
      },
    );

    if (result.data && payload?.successNotificationLocation) {
      yield put({
        payload: {
          content: 'Email sent.',
          duration: 'long',
          kind: 'success',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    }
    if (payload?.pathToRedirectToOnSuccess) {
      yield call(navigate, {
        to: payload.pathToRedirectToOnSuccess,
      });
    }
  } catch (e: any) {
    if (payload?.errorNotificationLocation) {
      yield put({
        payload: {
          content: 'Failed to resend email.',
          duration: 'long',
          kind: 'alert',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    }
  } finally {
    yield put(hideLoadingSpinner());
  }
}
