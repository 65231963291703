import { Box, styled } from '@m1/liquid-react';
import React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useSubmitInvestAcatTransferMutation } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';
import { useToast } from '~/toasts';
import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

import type { AcatSteps, AcatWizardFields } from './AcatWizard.types';
import {
  toMutationInput,
  useAcatWizardFormContext,
} from './hooks/useAcatWizardFormContext';
import { useAcatWizardQueryContext } from './hooks/useAcatWizardQueryContext';
import { AcatWizardFormProvider } from './providers/AcatWizardFormProvider';
import { AcatWizardQueryProvider } from './providers/AcatWizardQueryProvider';

const Gradient = styled(Box)<{ shouldHaveGradient: boolean }>`
  background: ${(props) =>
    props.shouldHaveGradient
      ? props.theme.colors.gradientLinearMain
      : undefined};
  width: 100%;
`;

export const AcatWizard = () => {
  return (
    <PageErrorBoundary>
      <AcatWizardQueryProvider>
        <AcatWizardWithQuery />
      </AcatWizardQueryProvider>
    </PageErrorBoundary>
  );
};

export const AcatWizardWithQuery = () => {
  const data = useAcatWizardQueryContext();

  return (
    <AcatWizardFormProvider data={data}>
      <AcatWizardForm />
    </AcatWizardFormProvider>
  );
};

const defaultErrorMessage =
  'Something went wrong. Try again or contact us for further assistance.';

const AcatWizardForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let initialStep = null;
  const lastPath = location.pathname.split('/').pop();
  const data = useAcatWizardQueryContext();
  if (lastPath !== 'acat-wizard') {
    initialStep = lastPath as AcatSteps;
  }
  const { step, handleSubmit, stepKey, goTo } = useAcatWizardFormContext();
  const [hasRedirected, setHasRedirected] = React.useState(false);
  const { addToast } = useToast();

  const [submitAcat, { loading }] = useSubmitInvestAcatTransferMutation();
  usePortaledSpinner(loading);

  const onSubmit = async (wizardState: AcatWizardFields) => {
    await submitAcat({
      variables: {
        input: toMutationInput(wizardState),
      },
      onCompleted: (d) => {
        addToast(d.submitInvestAcatTransfer?.toast);
        navigate({ to: '/d/home' });
      },
      onError: () =>
        addToast({
          content: defaultErrorMessage,
          duration: 'short',
          kind: 'alert',
        }),
    });
  };

  React.useEffect(() => {
    if (initialStep && stepKey !== initialStep && !hasRedirected) {
      setHasRedirected(true);
      goTo(initialStep);
    }
  }, [initialStep, stepKey, goTo, hasRedirected, setHasRedirected]);

  if (initialStep && stepKey !== initialStep && !hasRedirected) {
    return <Spinner />;
  }

  return (
    <Gradient shouldHaveGradient={stepKey === 'rollover-overview'}>
      <Container marginTop={32}>
        <InformationBanner
          appBanner={data.viewer.invest?.acatWizard?.informationalBanner}
          contain={false}
        />
        <form
          style={{ height: '100%', width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {step}
        </form>
      </Container>
    </Gradient>
  );
};
