import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
  PS,
} from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { AddressFieldsSection } from '~/components/form/address/AddressFieldsSection';
import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';
import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { printableAsciiChars, date } from '~/forms/validators';

import type {
  BeneficiaryInfoFragment,
  MailingAddressCountryEnum,
  MailingAddressSubdivisionEnum,
} from '~/graphql/types';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { getEnumEntries } from '~/utils';
import { formatDateDashes } from '~/utils/formatting';

type AddBeneficiaryModalProps = {
  open: boolean;
  beneficiary: null | Partial<
    BeneficiaryInfoFragment & {
      address: Partial<BeneficiaryInfoFragment['address']>;
    }
  >;
  onClose: any;
  onSubmit: any;
};

/* Will output a date in MM/DD/YYYY format given a YYYY-MM-DD format*/
function transformInitialDate(value: string) {
  if (value.includes('-')) {
    const [year, month, day] = value.split('-');
    return `${month}/${day}/${year}`;
  }
  return value;
}

export const BeneficiaryModal = ({
  open,
  onClose,
  onSubmit,
  beneficiary,
}: AddBeneficiaryModalProps) => {
  const formMethods = useForm<Partial<BeneficiaryInfoFragment>>({
    defaultValues: {
      ...(beneficiary ?? {}),
      dateOfBirth: transformInitialDate(beneficiary?.dateOfBirth ?? ''),
      isPrimary: true,
      address: {
        ...(beneficiary?.address ?? {}),
        country: 'USA' as MailingAddressCountryEnum,
      },
    },
  });

  React.useEffect(() => {
    if (open) {
      // Have to pass in the whole object to reset values, or else they persist.
      formMethods.reset(
        beneficiary
          ? {
              ...beneficiary,
              dateOfBirth: transformInitialDate(beneficiary.dateOfBirth ?? ''),
            }
          : {
              firstName: undefined,
              lastName: undefined,
              dateOfBirth: undefined,
              relationship: undefined,
              sharePercentage: 100,
              isPrimary: true,
              address: {
                lineOne: undefined,
                lineTwo: undefined,
                city: undefined,
                stateOrProvince: undefined,
                postalCode: undefined,
                country: 'USA' as MailingAddressCountryEnum,
              },
            },
      );
    }
  }, [beneficiary, formMethods, open]);

  if (!open) {
    return null;
  }

  function mockFormValues() {
    formMethods.reset({
      // random string of letters a-z
      firstName: capitalize(
        Array.from({ length: 2 + Math.round(10 * Math.random()) })
          .map(() => String.fromCharCode(65 + Math.floor(Math.random() * 26)))
          .join(''),
      ),
      lastName: capitalize(
        Array.from({ length: 2 + Math.round(20 * Math.random()) })
          .map(() => String.fromCharCode(65 + Math.floor(Math.random() * 26)))
          .join(''),
      ),
      dateOfBirth: '01/01/1980',
      relationship: 'Beloved',
      sharePercentage: 100,
      isPrimary: true,
      address: {
        lineOne: '123 Appleburrough Ct.',
        city: 'Naughtogeuak',
        stateOrProvince: 'NY' as MailingAddressSubdivisionEnum,
        postalCode: '12345',
        country: 'USA' as MailingAddressCountryEnum,
      },
    });
  }

  const dateValidation = date({
    checkAge: false,
    rejectFutureDateOfBirth: true,
  });

  return (
    <FormProvider {...formMethods}>
      <Modal open={open} onClose={onClose}>
        <ModalContent width="wide">
          <ModalTitle>
            {isEmpty(beneficiary) ? 'Add' : 'Edit'} Beneficiary
          </ModalTitle>
          <PS color="foregroundNeutralMain">
            Please note that you will be asked for Social Security numbers for
            all new and previously added beneficiaries when you save.
          </PS>
          <Flex flexDirection="column">
            <form
              onSubmit={formMethods.handleSubmit((data) => {
                onSubmit({
                  ...data,
                  // BE expects dateOfBirth in YYYY-MM-DD format while form asks for MM/DD/YYYY so we must transform it before submitting
                  dateOfBirth: formatDateDashes(data.dateOfBirth ?? ''),
                });
                onClose();
              })}
            >
              <Flex>
                <Box width="100%">
                  <ControlledInput
                    name="firstName"
                    label="First name"
                    control={formMethods.control}
                    rules={{ required: 'Required' }}
                    maxLength={FIELD_MAX_LENGTHS.FIRST_NAME}
                  />
                </Box>
              </Flex>
              <Flex flexDirection="row">
                <Box width="66%" pr={20}>
                  <ControlledInput
                    name="lastName"
                    label="Last name"
                    control={formMethods.control}
                    rules={{ required: 'Required' }}
                    maxLength={FIELD_MAX_LENGTHS.LAST_NAME}
                  />
                </Box>
                <Box width="33%">
                  <ControlledDropdown
                    name="suffix"
                    label="Suffix"
                    isSearchable={false}
                    control={formMethods.control}
                    options={getEnumEntries('NameSuffixEnum').map(
                      ({ name, description }) => ({
                        label: description,
                        value: name,
                      }),
                    )}
                  />
                </Box>
              </Flex>
              <Box>
                <AddressFieldsSection kind="NORMAL" namespace="address" />
              </Box>

              <Flex flexDirection="row">
                <Box width="33%" pr={20}>
                  <ControlledInput
                    name="dateOfBirth"
                    label="Date of birth"
                    id="dateOfBirth"
                    placeholder="MM/DD/YYYY"
                    control={formMethods.control}
                    rules={{
                      required: 'Required',
                      validate: {
                        printableAsciiChars: (v) =>
                          typeof v === 'string' && printableAsciiChars(v),
                        dateValidation: (v) => dateValidation(v),
                      },
                    }}
                    maskType="date"
                  />
                </Box>
                <Box width="66%">
                  <ControlledInput
                    name="relationship"
                    label="Relationship"
                    control={formMethods.control}
                    rules={{ required: 'Required' }}
                  />
                </Box>
              </Flex>

              <Flex>
                <Box width="66%">
                  <ControlledCheckbox
                    name="isPrimary"
                    label="Is Primary?"
                    control={formMethods.control}
                  />
                </Box>
              </Flex>

              <Flex>
                <Box width="33%" mt="1ex">
                  <Button
                    label="Submit"
                    kind="primary"
                    size="large"
                    type="submit"
                  />
                </Box>
              </Flex>
            </form>
            {__NODE_ENV__ !== 'production' ? (
              <Button kind="link" onClick={mockFormValues} label="Mock Form" />
            ) : null}
          </Flex>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
