import { type Color, styled, Image, type ImageProps } from '@m1/liquid-react';
import * as React from 'react';

export type LogoProps = {
  backgroundColor?: Color;
  content: React.ReactNode;
  placeholder: string;
  starred: boolean;
  style?: Record<string, any>;
};

type LogoContentProps = {
  handleLogoError: ImageProps['onError'];
  imageError: boolean;
  logoProps: LogoProps;
};

const StyledLogoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  img {
    width: 100%;
  }
`;

const StyledLogoContent = styled.div<{
  backgroundColor?: Color;
  imageError?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  font-size: 10px;
  background: ${(props) => {
    if (props.imageError) {
      return props.theme.colors.secondary;
    } else if (props.backgroundColor) {
      return props.theme.colors[props.backgroundColor];
    }
    return 'inherit';
  }};
  color: ${(props) =>
    props.imageError
      ? props.theme.colors.foregroundNeutralOnDark
      : props.theme.colors.foregroundNeutralMain};
`;

const StyledLogoStar = styled.div<{
  starred?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  font-size: 1.6rem;
  color: var(--colors-foreground-neutral-main);
  pointer-events: none;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.starred ? '1' : '0')};
`;

const LogoContent = ({
  imageError,
  handleLogoError,
  logoProps,
}: LogoContentProps) => {
  if (imageError) {
    return <>{logoProps.placeholder}</>;
  } else if (typeof logoProps.content === 'string') {
    return <Image alt="" onError={handleLogoError} src={logoProps.content} />;
  } else if (logoProps.content) {
    return <>{logoProps.content}</>;
  }

  return <>{logoProps.placeholder}</>;
};

export const Logo = (logoProps: LogoProps) => {
  const [imageError, setImageError] = React.useState<boolean>(false);

  return (
    <StyledLogoContainer style={logoProps.style}>
      <StyledLogoContent
        backgroundColor={logoProps.backgroundColor}
        imageError={imageError}
      >
        <LogoContent
          handleLogoError={() => setImageError(true)}
          imageError={imageError}
          logoProps={logoProps}
        />
      </StyledLogoContent>
      <StyledLogoStar>★</StyledLogoStar>
    </StyledLogoContainer>
  );
};

Logo.defaultProps = {
  starred: false,
};
