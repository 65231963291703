import * as React from 'react';

import { AppContext } from '~/AppContext';
import { LineOneInput } from '~/components/form/address/LineOneInput';
import { mapPlaceToAddress } from '~/forms/fields/AddressAutofill.helpers';
import type { MailingAddress } from '~/graphql/types';

type Autocomplete = google.maps.places.Autocomplete;

// types
type PlaceResult = google.maps.places.PlaceResult;

// custom hooks
function useLoadGmapsScript(
  hasLoaded: boolean,
  setHasLoaded: (arg0: boolean) => void,
) {
  React.useEffect(() => {
    let script: HTMLScriptElement;

    if (!hasLoaded) {
      script = document.createElement('script');

      script.src = `https://maps.googleapis.com/maps/api/js?key=${window.config.googleMaps.apiKey}&libraries=places&callback=initMap`;
      script.async = true;

      window.initMap = () => {
        setHasLoaded(true);
      };

      document.body.appendChild(script);
    }

    return () => {
      if (script && window.initMap) {
        script.removeEventListener('load', window.initMap);
      }
    };
  }, [hasLoaded]);
}

function usePreventSubmissionOnEnter(
  htmlInputElement: HTMLInputElement | undefined,
) {
  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };

    if (htmlInputElement) {
      htmlInputElement.addEventListener('keypress', onKeyPress);
    }

    return () => {
      if (htmlInputElement) {
        htmlInputElement.removeEventListener('keypress', onKeyPress);
      }
    };
  }, [htmlInputElement]);
}

function useGetGoogleAutoCompleteInstance(
  htmlInputElement: HTMLInputElement | undefined,
  hasGoogleLoaded: boolean,
): Autocomplete | null {
  // It is important to memoize this so we don't instantiate multiple instances.
  // This effects our google handles session tokens and in turn, our billing.
  return React.useMemo(() => {
    if (!htmlInputElement || !hasGoogleLoaded) {
      return null;
    }

    return new google.maps.places.Autocomplete(htmlInputElement, {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'formatted_address'],
      types: ['geocode'],
    });
  }, [hasGoogleLoaded, htmlInputElement]);
}

type UseSetupGooglePlaceListenerCallbackInput = {
  hasGoogleLoaded: boolean;
  onPlaceSelect: (arg0: PlaceResult) => void;
  googleMapsAutoCompleteInstance: Autocomplete | null;
};

function useSetupGooglePlaceListenerCallback({
  hasGoogleLoaded,
  onPlaceSelect,
  googleMapsAutoCompleteInstance,
}: UseSetupGooglePlaceListenerCallbackInput) {
  React.useEffect(() => {
    if (!hasGoogleLoaded || !googleMapsAutoCompleteInstance) {
      return;
    }

    googleMapsAutoCompleteInstance.addListener('place_changed', () => {
      const place = googleMapsAutoCompleteInstance.getPlace();

      onPlaceSelect(place);
    });
  }, [googleMapsAutoCompleteInstance, hasGoogleLoaded, onPlaceSelect]);
}

/**
 * Custom RF validator designed to guard against a user selecting a place that isn't a complete address.
 * Examples include are 'Chicago, IL' or 'United States'.
 */
function hasSelectedValidPlace(mailingAddress: MailingAddress | null): boolean {
  if (
    !mailingAddress?.city ||
    !mailingAddress?.lineOne ||
    !mailingAddress?.postalCode ||
    !mailingAddress?.stateOrProvince
  ) {
    return false;
  }

  return true;
}

export const GooglePlaces = ({
  handleAutocompleteSelect,
}: {
  handleAutocompleteSelect: (mailingAddress: any) => void;
}) => {
  const { analytics } = React.useContext(AppContext);
  const [hasGoogleLoaded, setHasGoogleLoaded] = React.useState(false);

  const onPlaceSelect = (place: PlaceResult) => {
    if (!place || !place.formatted_address) {
      return;
    }
    const mailingAddress = mapPlaceToAddress(place);

    const goodAddress = hasSelectedValidPlace(mailingAddress);

    analytics.recordEvent('m1_address_autofill_suggestion_selected');

    if (goodAddress) {
      handleAutocompleteSelect(mailingAddress);
    }
  };

  useLoadGmapsScript(hasGoogleLoaded, setHasGoogleLoaded);

  // Ideally, this would be handled through a Ref if our input wasn't buried several levels deep.
  // Querying the DOM on a per-render basis suffices for now.
  const htmlInputElement = document.getElementById(
    'autocomplete',
  ) as HTMLInputElement;

  usePreventSubmissionOnEnter(htmlInputElement);

  const googleMapsAutoCompleteInstance = useGetGoogleAutoCompleteInstance(
    htmlInputElement,
    hasGoogleLoaded,
  );

  useSetupGooglePlaceListenerCallback({
    hasGoogleLoaded,
    onPlaceSelect,
    googleMapsAutoCompleteInstance,
  });

  React.useEffect(() => {
    //  manually setting display:none for any pac-containers if navigated away (only Chrome does this automatically)
    return () => {
      const pacContainerElements = document.querySelectorAll('.pac-container');
      pacContainerElements?.forEach((element) =>
        element.setAttribute('style', 'display: none'),
      );
    };
  }, []);

  return <LineOneInput id="autocomplete" label="Street address" />;
};
