import { HM, PL, Box } from '@m1/liquid-react';
import * as React from 'react';

import { HolderSsnForm } from '~/forms';
import type { AppState } from '~/redux';
import { collectedHolderSsnModern } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import type { OnboardingFlowState } from '~/redux/reducers/newFlows/reducers/onboardingReducer';

type Props = {
  fieldLabel?: string;
  holder: 'primary' | 'secondary';
  initialValues: Record<string, any>;
  title: string;
  subtitle: string;
  disclaimerTooltipContent: string;
};

export const CollectHolderSsnStep = (props: Props) => {
  const dispatch = useDispatch();
  const { holder, title, subtitle, disclaimerTooltipContent } = props;

  const handleSubmit = ({ ssn }: Record<string, any>): void => {
    dispatch(
      collectedHolderSsnModern({
        holder,
        ssn,
      }),
    );
  };

  const cachedSSN = useSelector<string | null>((state) => {
    if (props.holder === 'primary') {
      return state.newFlows.IDENTITY_FIRST.input.primarySsn;
    }
    return state.newFlows.IDENTITY_FIRST.input.secondarySsn;
  });

  const { product } = useSelector<OnboardingFlowState>(
    (state: AppState) => state.newFlows.onboarding,
  );

  const disclosureSSN =
    product.productIdentifier === 'BORROW_PERSONAL_LOANS'
      ? 'Annoying? You bet, but legally we have to ask and verify your answer. We won’t run a hard credit check unless you decide to accept one of your loan offers.'
      : 'Annoying? You bet, but legally we have to ask and verify your answer. We will not run a credit check.';

  return (
    <Box width={588} mx="auto">
      <HM
        content={title ?? 'We need to verify your Social Security number.'}
        py={32}
      />
      <PL content={subtitle ?? disclosureSSN} />
      <Box mt={48} mx="auto">
        <HolderSsnForm
          initialValues={{
            ssn: cachedSSN,
          }}
          disclaimerText={disclaimerTooltipContent}
          label={props.fieldLabel}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};
