import { Box, Button, Flex, HXS, PL, PXL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { SmartTransferTimingMessage } from '~/components/SmartTransferTimingMessage';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';
import { useConfirmSmartTransferQuery } from '~/graphql/hooks';
import type { SmartTransferBalanceTriggerInput } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

export type ConfirmSmartTransferStepProps = {
  onFinishStep: (e: React.SyntheticEvent<any>) => void;
};

export const ConfirmSmartTransferStep = ({
  onFinishStep,
}: ConfirmSmartTransferStepProps) => {
  const navigate = useNavigate();
  const {
    balanceTrigger,
    contraParticipantEntries,
    focusAccountId,
    focusOptionId,
  } = useSelector((state) => ({
    balanceTrigger: state.newFlows.CREATE_SMART_TRANSFER.input.balanceTrigger,
    contraParticipantEntries:
      state.newFlows.CREATE_SMART_TRANSFER.input.contraParticipantEntries,
    focusAccountId:
      state.newFlows.CREATE_SMART_TRANSFER.input.focusParticipantId,
    focusOptionId: state.newFlows.CREATE_SMART_TRANSFER.focusOptionId,
  }));

  const { data, loading } = useConfirmSmartTransferQuery({
    variables: {
      focusAccountId: focusAccountId as string,
      focusOptionId: focusOptionId as string,
    },
    skip: !focusAccountId || !focusOptionId,
  });

  if (loading) {
    return <Spinner />;
  }

  if (data?.node?.__typename !== 'NewSmartTransferFocusOption') {
    return <GenericSystemError />;
  }

  const editRequirements = data?.node?.editRequirements;
  const transferParticipantName =
    editRequirements?.focusParticipant?.transferParticipantName ??
    'Your account';

  return (
    <Box maxWidth={490} mx="auto" pt={100}>
      <BackArrow
        content="Create Smart Transfer"
        mb={16}
        params={{
          step: 'set-smart-transfer',
        }}
        to="/d/c/create-smart-transfer/:step"
      />

      <HXS content="Confirm Smart Transfer" mb={32} />
      <Card>
        <Box p={32}>
          <PL color="foregroundNeutralSecondary" content="When" />
          {isNotNil(balanceTrigger) && (
            <WhenDescriptionHeading
              balanceTrigger={balanceTrigger}
              participantName={transferParticipantName}
            />
          )}
          {typeof balanceTrigger?.underBalanceRefillTarget === 'number' && (
            <>
              <PL content="Refill balance to" color="foregroundNeutralMain" />
              <PXL
                fontWeight={600}
                content={formatCurrency(
                  balanceTrigger.underBalanceRefillTarget,
                  'SELECTIVE',
                )}
              />
            </>
          )}
          <Divider spacing="relaxed" />
          <Flex
            flexDirection={
              balanceTrigger?.balanceTriggerType === 'OVER_BALANCE'
                ? 'row'
                : 'row-reverse'
            }
            justifyContent="space-between"
          >
            <Flex flexDirection="column" width="40%">
              <PL
                color="foregroundNeutralSecondary"
                content={
                  balanceTrigger?.balanceTriggerType === 'OVER_BALANCE'
                    ? 'Transfer from'
                    : 'Transfer to'
                }
              />
              <PXL
                fontWeight={600}
                content={
                  focusAccountId && (
                    <TransferParticipantCellRenderer
                      includeDescription
                      id={focusAccountId}
                    />
                  )
                }
                mt={4}
              />
            </Flex>
            <Flex flexDirection="column" width="48%">
              <PL
                color="foregroundNeutralSecondary"
                content={
                  balanceTrigger?.balanceTriggerType === 'OVER_BALANCE'
                    ? 'Transfer to'
                    : 'Transfer from'
                }
              />
              <PXL
                fontWeight={600}
                content={
                  <>
                    {contraParticipantEntries.map((entry, index) => (
                      <Flex key={index} mt={index ? 32 : 0}>
                        {entry.contraParticipantId && (
                          <TransferParticipantCellRenderer
                            includeDescription
                            id={entry.contraParticipantId}
                          />
                        )}
                      </Flex>
                    ))}
                  </>
                }
                mt={4}
              />
            </Flex>
          </Flex>
        </Box>
        {balanceTrigger && editRequirements && (
          <SmartTransferTimingMessage
            balanceTrigger={balanceTrigger}
            editRequirementsId={editRequirements.id}
            mt={80}
            borderRadius="0 0 8px 8px"
          />
        )}
      </Card>
      <ButtonGroup mt={64}>
        <Button
          kind="secondary"
          label="Cancel"
          onClick={() => {
            navigate({
              to: '/d/c/create-smart-transfer/:step',
              params: { step: 'set-smart-transfer' },
            });
          }}
        />
        <Button label="Confirm" kind="primary" onClick={onFinishStep} />
      </ButtonGroup>
    </Box>
  );
};

const WhenDescriptionHeading = ({
  balanceTrigger,
  participantName,
}: {
  balanceTrigger: SmartTransferBalanceTriggerInput;
  participantName: string;
}) => (
  <PXL
    fontWeight={600}
    content={`${participantName} is ${
      balanceTrigger.balanceTriggerType === 'OVER_BALANCE' ? 'over' : 'under'
    } ${formatCurrency(balanceTrigger.balanceThreshold)}`}
  />
);
