import {
  ACCOUNT_ACTIONS,
  type InvestAccountSelectedAction,
  type SetActiveBorrowAccountAction,
  type SetActiveCheckingAccountAction,
  type SetActiveCreditAccountAction,
  type SetActiveInvestAccountAction,
  type SetActivePersonalLoanAccountAction,
  type SetActiveSavingsAccountAction,
  type SkippedTrustedContactAction,
} from './accountActions.types';

export const setActiveInvestAccount = (
  payload: SetActiveInvestAccountAction['payload'],
): SetActiveInvestAccountAction => ({
  payload,
  type: ACCOUNT_ACTIONS.SET_ACTIVE_INVEST_ACCOUNT,
});

export const setActiveBorrowAccount = (
  payload: SetActiveBorrowAccountAction['payload'],
): SetActiveBorrowAccountAction => ({
  payload,
  type: ACCOUNT_ACTIONS.SET_ACTIVE_BORROW_ACCOUNT,
});

export const setActivePersonalLoanAccount = (
  payload: SetActivePersonalLoanAccountAction['payload'],
): SetActivePersonalLoanAccountAction => ({
  payload,
  type: ACCOUNT_ACTIONS.SET_ACTIVE_PERSONAL_LOAN_ACCOUNT,
});

export const setActiveCheckingAccount = (
  payload: SetActiveCheckingAccountAction['payload'],
): SetActiveCheckingAccountAction => ({
  payload,
  type: ACCOUNT_ACTIONS.SET_ACTIVE_CHECKING_ACCOUNT,
});

export const setActiveCreditAccount = (
  payload: SetActiveCreditAccountAction['payload'],
): SetActiveCreditAccountAction => ({
  payload,
  type: ACCOUNT_ACTIONS.SET_ACTIVE_CREDIT_ACCOUNT,
});

export const setActiveSavingsAccount = (
  payload: SetActiveSavingsAccountAction['payload'],
): SetActiveSavingsAccountAction => ({
  payload,
  type: ACCOUNT_ACTIONS.SET_ACTIVE_SAVINGS_ACCOUNT,
});

export const skippedTrustedContact = (): SkippedTrustedContactAction => ({
  type: ACCOUNT_ACTIONS.SKIPPED_TRUSTED_CONTACT,
});

export const investAccountSelected = (
  payload: InvestAccountSelectedAction['payload'],
): InvestAccountSelectedAction => ({
  payload,
  type: ACCOUNT_ACTIONS.INVEST_ACCOUNT_SELECTED,
});
