import { Button, PM } from '@m1/liquid-react';
import * as React from 'react';
import { type Path, useFormContext } from 'react-hook-form';

import type { InvestActivityFormValues } from '../ActivityPage';

const BOOLEAN_FILTERS: Path<InvestActivityFormValues>[] = [
  'includeCategoryCash',
  'includeCategoryDividend',
  'includeCategoryPosition',
  'includeCategoryTrading',
  'includeCategoryTransfer',
];

export const ResetTypeButton = () => {
  const { watch, resetField } = useFormContext<InvestActivityFormValues>();
  const filters = watch(BOOLEAN_FILTERS);

  const isActive = filters.some((filter) => filter !== true);

  const onResetTypeFilter = () => {
    BOOLEAN_FILTERS.forEach((filter) =>
      resetField(filter, { keepDirty: true }),
    );
  };

  return (
    <PM
      as="div"
      content={
        <Button
          kind="link"
          disabled={!isActive}
          label="Reset"
          style={{
            fontSize: '16px',
            fontWeight: 600,
            // TODO(Wolf): Remove these once we support margins in link buttons.
            marginLeft: 4,
            marginTop: 8,
          }}
          onClick={onResetTypeFilter}
        />
      }
    />
  );
};
