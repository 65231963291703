import { useReactiveVar } from '@apollo/client';
import * as React from 'react';

import {
  selectedSlicesVar,
  clickedSliceCheckbox,
} from '~/graphql/local/reactiveVars/selectedSlices';
import type { SliceableCellFragment } from '~/graphql/types';
import { clickedSliceableSelector } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';

export type SliceableSelectProps = {
  className?: string;
  disabled?: boolean;
  sliceable: SliceableCellFragment;
};

export const SliceableSelect = ({
  disabled,
  sliceable,
}: SliceableSelectProps) => {
  const selectedSlices = useReactiveVar(selectedSlicesVar);
  const selected = selectedSlices.some((slice) => slice.id === sliceable.id);
  const dispatch = useDispatch();

  const handleOnChange = () => {
    dispatch(clickedSliceableSelector(sliceable.id));
    clickedSliceCheckbox(sliceable);
  };

  return (
    <Checkbox
      checked={selected}
      disabled={disabled}
      onChange={handleOnChange}
      size="large"
      data-testid={`sliceable-select-${sliceable.name}`}
    />
  );
};
