import { Text } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import type { TransferRuleFragment } from '~/graphql/types';
import { Pill } from '~/toolbox/Pill';

export type TransferRuleStatusProps = {
  transferRule: TransferRuleFragment;
};

export const TransferRuleStatus = ({
  transferRule,
}: TransferRuleStatusProps) => {
  if (transferRule.hasPendingInstance) {
    return <Pill kind="caution" label="Pending" />;
  } else if (!transferRule.isEnabled) {
    return <Pill kind="neutral" label="Paused" />;
  } else if (
    transferRule.__typename === 'ScheduledTransferRule' &&
    transferRule.latestInstance &&
    transferRule.latestInstance.startDate
  ) {
    const date = moment(transferRule.latestInstance.startDate).format('ll');
    return <Text content={`Last transfer ${date}`} />;
  }
  return null;
};
