import React from 'react';

import { HistoricalSparklinesChart } from '~/components/charts';
import { useSecurityPerformanceQuery } from '~/graphql/hooks';
import type {
  HistoricalQuotePeriodEnum,
  Security,
  TimeIntervalEnum,
} from '~/graphql/types';

import { PERIOD_TO_INTERVAL, PERIOD_TO_LENGTH } from './constants';

type SecurityMiniChartProps = {
  securityId: string;
  period: HistoricalQuotePeriodEnum;
  interval?: TimeIntervalEnum;
};

export const SecurityMiniChart = ({
  securityId,
  period,
  interval,
}: SecurityMiniChartProps) => {
  const rangeInput = {
    length: PERIOD_TO_LENGTH[period],
  };
  const intervalInput = interval ? interval : PERIOD_TO_INTERVAL[period];

  const { data } = useSecurityPerformanceQuery({
    variables: {
      id: securityId,
      range: rangeInput,
      interval: intervalInput,
    },
  });

  const security = data?.security as Security | null | undefined;

  if (!security?.historicalQuotes) {
    return null;
  }

  const historicalQuotes = security.historicalQuotes;
  return (
    <HistoricalSparklinesChart
      data={historicalQuotes}
      x="date"
      y="closePrice"
    />
  );
};
