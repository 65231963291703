import type { Theme } from '@m1/liquid-react';
import classNames from 'classnames';
import * as React from 'react';

import { pickSliceFillColor } from '~/utils/slices';

import {
  type D3PieDatum,
  type D3Arc,
  type PieColorScheme,
  type SliceData,
  PieColorSchemes,
} from './Pie.types';

import style from './pieSlice.module.scss';

type PieSliceProps = {
  arc: D3Arc<SliceData>;
  colors: NonNullable<Theme['pieSliceColors']>;
  colorScheme: PieColorScheme;
  highlightedSlice: string | null | undefined;
  highlightSlice: (id?: string | null | undefined) => void;
  inactiveColor: string;
  index: number;
  onClick?: (data: SliceData) => void;
  pieDatum: D3PieDatum<SliceData>;
};

const buildClasses = (
  id: string | null | undefined,
  highlightedSlice: string | null | undefined,
): string => {
  if (!id) {
    return classNames(style.slice);
  }

  return classNames(style.slice, {
    [style.sliceActive]: id === highlightedSlice,
    [style.sliceInactive]: highlightedSlice && id !== highlightedSlice,
  });
};

export const PieSlice = (props: PieSliceProps) => {
  const {
    arc,
    colors,
    colorScheme,
    highlightedSlice,
    highlightSlice,
    inactiveColor,
    index,
    onClick,
    pieDatum,
  } = props;
  const { id, isPlaceholder, percentage } = pieDatum.data;
  const classes = buildClasses(id, highlightedSlice);
  const styles = {
    cursor: onClick && !isPlaceholder ? 'pointer' : null,
    opacity: isPlaceholder && percentage !== 100 ? 0 : 1,
  };

  const isGrayscale = colorScheme !== PieColorSchemes.PRIMARY_COLORS;

  return (
    <g className={classes}>
      <path
        className="arc"
        // @ts-expect-error - TS2322 - Type '{ centroid: () => [number, number]; context: (...args: any[]) => any; cornerRadius: (arg: number | ((...args: any[]) => any) | null) => (...args: any[]) => any; endAngle: (arg: number | ((...args: any[]) => any) | null) => (...args: any[]) => any; ... 7 more ...; startAngle: (arg: number | ... 1 more ... | null) => ...' is not assignable to type 'string'.
        d={arc(pieDatum)}
        data-testid={isPlaceholder ? 'placeholder' : null}
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        fill={
          isPlaceholder && percentage === 100
            ? inactiveColor // whole pie is empty
            : pickSliceFillColor(colors, index, isGrayscale)
        }
        // @ts-expect-error - TS2322 - Type '{ cursor: string | null; opacity: number; }' is not assignable to type 'Properties<string | number, string & {}>'.
        style={styles}
        // @ts-expect-error - TS2322 - Type '(() => void) | null' is not assignable to type 'MouseEventHandler<SVGPathElement> | undefined'.
        onClick={onClick ? () => onClick(pieDatum.data) : null}
        onMouseEnter={() => highlightSlice(id)}
        onMouseLeave={() => highlightSlice()}
        onTouchStart={() => highlightSlice(id)}
        onTouchEnd={() => highlightSlice()}
      />
    </g>
  );
};
