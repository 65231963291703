import { Button, Box, HXS, HXXS, PL, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

import { useCancelOrderModalQuery } from '~/graphql/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Spinner } from '~/toolbox/spinner';
import { formatCurrency } from '~/utils/formatting';

type OrderActionCancelModalContentProps = {
  portfolioSliceId: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const OrderActionCancelModalContent = ({
  portfolioSliceId,
  onCancel,
  onConfirm,
}: OrderActionCancelModalContentProps) => {
  const { data, loading } = useCancelOrderModalQuery({
    variables: {
      portfolioSliceId,
    },
  });

  if (loading) {
    return <Spinner />;
  }

  const portfolioSlice =
    data?.node && 'orderStatus' in data.node ? data.node : null;

  const cashFlow = portfolioSlice?.orderStatus?.order?.cashFlow ?? null;
  if (cashFlow === null) {
    return null;
  }

  return (
    <ModalContent>
      <Box pb={24}>
        <HXS>Are you sure you want to cancel this order?</HXS>
      </Box>

      <Box pb={8}>
        <PL>{cashFlow >= 0 ? 'Buy' : 'Sell'}</PL>
      </Box>
      <Box pb={16}>
        <HXXS>{portfolioSlice?.to.name}</HXXS>
      </Box>

      <Box pb={8}>
        <PL>Amount</PL>
      </Box>
      <Box pb={32}>
        <HXXS>{formatCurrency(Math.abs(cashFlow))}</HXXS>
      </Box>

      <ButtonGroup>
        <Button
          kind="secondary"
          size="medium"
          label="Keep Order"
          onClick={onCancel}
        />
        <Button
          kind="primary"
          size="medium"
          label="Cancel Order"
          onClick={onConfirm}
        />
      </ButtonGroup>
    </ModalContent>
  );
};
