// To cut down on extra computation, we're using a map with the key equal to
// a potentially invalid route or path and a value equal to the corresponding
// valid route path:
import { generatePath } from 'react-router-dom';

/**
 * Converts the specified route name or path to a valid route _path_.
 *
 * Important note! This function will _not_ verify that the actual endpoint is
 * valid. You could pass `/this/path/goes/nowhere` into this function and it
 * will return that same value right back, even though there are no pages in the
 * app that correspond to that route.
 *
 * @todo Remove the description below and rename 'routeNameOrPath' to "routePath"
 *       once react-router upgrade is complete.
 * The idea with this function is that it can accept either a route path or
 * name and always return a valid path. So when we remove named routes, any
 * components/functions that need to do any kind of navigation in the app
 * will still operate as expected, since they need to go through this function
 * to get a valid route path. It ensures the returned value has a `/` prefix
 * (if a route path is specified), or is checked against a table of named routes
 * and returns the corresponding path.
 *
 * @param routeNameOrPath Route name *or* path to convert to a valid route path.
 * @param [params] Optional params that are used to swap out patterns with values.
 */
export function toValidRoutePath(
  routeNameOrPath: string,
  params?: Maybe<Record<string, any>>,
): string {
  // TypeScript should catch the `typeof` check, unless we're passing in a value
  // from an API response payload:
  if (typeof routeNameOrPath !== 'string' || routeNameOrPath === '') {
    /** This will cause a redirect to the {@link NotFoundPage}. */
    return '/404';
  }

  // prettier-ignore
  let validRoutePath = tryToGetRoutePathFromName(routeNameOrPath);

  if (validRoutePath === undefined) {
    // If the route path wasn't associated with a name, it's a path, so we ensure
    // it's formatted correctly:
    validRoutePath = tryToGetFormattedRoutePath(routeNameOrPath);
  }

  validRoutePath = generatePath(validRoutePath, params ?? {});

  return validRoutePath ? validRoutePath : '/404';
}

/**
 * Compares the specified route name (if it is a name) against all named routes.
 * If an entry is found that matches the named route, the corresponding route
 * path is returned, otherwise returns null.
 *
 * Note! Some of the name to path mappings are also present in the deep links
 * file. But rather than import those and jump between two files during
 * dev/debugging, I just dumped everything in here for quick reference. Considering
 * the deep links don't get updated very often (we're talking once every six months
 * here), this function is going to disappear before we need to update them
 * again. Once react-router is upgraded, we'll delete this function _and_
 * import the deep links for paths only.
 *
 * @todo Remove this once react-router upgrade is complete.
 */
function tryToGetRoutePathFromName(maybeRouteName: string): string | undefined {
  const namedRouteLookupTable: Record<string, string> = {
    'acat-wizard': `/d/w/acat-wizard`,
    'activate-credit-card': `/d/c/activate-credit-card`,
    'activate-debit-card': `/d/c/activate-debit-card/:step`,
    'activity': `/d/invest/activity`,
    'activity-details': `/d/c/activity/:activityId/activity-details`,
    'activity-excluded-buys': `/d/c/activity/:activityId/excluded-buys`,
    'add-crypto': `/d/c/add-slices/crypto`,
    'add-crypto-details': `/d/c/add-slices/crypto/details/:cryptoId`,
    'add-fund-details': `/d/c/add-slices/funds/details/:fundId`,
    'add-funds': `/d/c/add-slices/funds`,
    'add-model-portfolio-category-details': `/d/c/add-slices/model-portfolios/:categoryKey`,
    'add-model-portfolio-details': `/d/c/add-slices/model-portfolios/details/:modelPortfolioId`,
    'add-model-portfolios': `/d/c/add-slices/model-portfolios`,
    'add-my-pies': `/d/c/add-slices/my-pies`,
    'add-shared-pie-failure': `/share/failed`,
    'add-shared-pie-success': `/share/success`,
    'add-slices': `/d/c/add-slices`,
    'add-stock-details': `/d/c/add-slices/stocks/details/:equityId`,
    'add-stocks': `/d/c/add-slices/stocks`,
    'add-user-pie-details': `/d/c/add-slices/my-pies/details/:userPieId`,
    'add-watchlist': `/d/c/add-slices/watchlist`,
    'app': `/`,
    'application-received': `/direct-loan-application-error/application-received`,
    'application-rejected': `/direct-loan-application-error/application-rejected`,
    'available-funding-sources': `/d/invest/available-funding-sources`,
    'bank-connection': `/d/bank-connection`,
    'borrow': `/d/borrow`,
    'borrow-account': `/d/borrow/margin`,
    'borrow-margin-marketing': `/d/borrow/margin/marketing`,
    'borrow-marketing': `/d/borrow/marketing`,
    'borrow-splash': `/d/borrow`,
    'borrow-use-cases': `/d/c/borrow-use-cases/:useCase`,
    'confirm-identity': `/d/c/confirm-identity`,
    'connect-bank': `/d/c/connect-bank`,
    'contact-us': `/d/contact-us`,
    'create-send-check': `/d/c/create-send-check/:step/:scheduledTransferRuleId`,
    'create-smart-transfer': `/d/c/create-smart-transfer/:step`,
    'create-transfer': `/d/c/create-transfer/:step`,
    'credit-card-application': `/d/c/credit-card-application/:step`,
    'crypto': `/d/crypto`,
    'custodial-beneficiary': `/d/invest/custodial-beneficiary`,
    'dashboard': `/d`,
    'dashboard-covers': `/d/c`,
    'direct-loan-application': `/direct-loan-application`,
    'disbursement-error': `/direct-loan-application-error/disbursement-error`,
    'dividend-management': `/d/invest/automation/dividend-management`,
    'document-upload': `/d/document-upload/:associatedService/:businessPurpose`,
    'earned-dividends': `/d/c/earned-dividends/:portfolioSliceId`,
    'edit-contra-entry-via-edit': `/d/c/edit-smart-transfer/:smartTransferRuleId/edit-contra-entry/:contraParticipantEntryEditRequirementsId`,
    'edit-smart-transfer': `/d/c/edit-smart-transfer/:smartTransferRuleId`,
    'edit-transfer-schedule': `/d/c/edit-transfer-schedule/:scheduledTransferRuleId`,
    'financial-suitability-wizard': `/onboarding/financial-details/:step`,
    'forgot': `/forgot-password`,
    'forgot-password-confirm': `/forgot-password/confirm`,
    'fund-account': `/d/invest/fund-account`,
    'funding-history': `/d/invest/funding-history`,
    'holdings': `/d/invest/holdings`,
    'home': `/d/home`,
    'input-micro-deposits': `/d/c/verify-bank/input-micro-deposits`,
    'invest': `/d/invest`,
    'invest-bank-connection': `/d/invest/bank-connection`,
    'invest-marketing': `/d/invest/invest-marketing`,
    'ira-wizard': `/onboarding/setup-ira-account/:step`,
    'login': `/login`,
    'logout': `/logout`,
    'maintenance': `/maintenance`,
    'manage-personal-loan': `/d/borrow/personal/manage-loan`,
    'manual-review': `/direct-loan-application-error/manual-review`,
    'market-news': `/d/research/market-news`,
    'micro-deposits-expired': `/d/c/verify-bank/micro-deposits-expired`,
    'model-portfolio-details': `/d/research/model-portfolios/details/:modelPortfolioId`,
    'move-money': `/d/move-money/:step`,
    'new-contra-entry-via-edit': `/d/c/edit-smart-transfer/:smartTransferRuleId/new-contra-entry/:newContraParticipantEntryOptionId`,
    'not-eligible': `/direct-loan-application-error/not-eligible`,
    'on-demand-portfolio-trading-details': `/d/invest/portfolio/:portfolioSliceId/on-demand-trading`,
    'on-demand-portfolio-trading-details-root': `/d/invest/portfolio/on-demand-trading`,
    'on-demand-portfolio-trading-in-progress': `/d/invest/portfolio/on-demand-trading/:tradeWindowId`,
    'onboarding': `/onboarding`,
    'onboarding-initial-funding': `/onboarding/initial-funding/:initialFundingStep/:childStep`,
    'onboarding-setup-account': `/onboarding/setup-account/:accountSetupStep`,
    'onboarding-setup-invest-account': `/onboarding/setup-invest-account/:investAccountSetupStep`,
    'open-account': `/d/c/open-account/:accountSetupStep`,
    'open-invest-account': `/d/open-invest-account/:step`,
    'open-invest-joint-account': `/d/open-invest-joint-account`,
    'payment': `/d/c/payment/:step`,
    'pending-liquidation': `/d/invest/unmanaged-holdings/pending-liquidation/:id`,
    'personal-loan-application': `/d/borrow/personal/loan-application/:step`,
    'personal-loan-not-eligible': `/d/borrow/personal/not-eligible`,
    'personal-loan-transaction-details': `/d/c/transaction/:transactionId/personal-loan-transaction-details`,
    'personal-loan-transactions': `/d/borrow/personal/transactions`,
    'personal-loans': `/d/borrow/personal`,
    'personal-loans-onboarding': `/onboarding/personal-loans-onboarding/:step`,
    'phone-verification': `/onboarding/phone-verification/:phoneVerificationStep`,
    'plaid-redirect': `/d/plaid-redirect`,
    'plus': `/d/plus`,
    'plus-billing': `/d/c/plus-billing/:step`,
    'portfolio': `/d/invest/portfolio`,
    'portfolio-details': `/d/invest/portfolio/:portfolioSliceId`,
    'portfolio-editor': `/d/invest/portfolio-editor/:step`,
    'portfolio-excluded-buys': `/d/invest/portfolio/:portfolioSliceId/excluded-buys`,
    'portfolio-excluded-buys-root': `/d/invest/portfolio/excluded-buys`,
    'portfolio-organizer': `/d/invest/portfolio-organizer`,
    'portfolio-trading-details': `/d/invest/portfolio/:portfolioSliceId/trading`,
    'portfolio-trading-details-root': `/d/invest/portfolio/trading`,
    'position-details': `/d/invest/holdings/position/:positionId`,
    'promotion-signup': `/signup/promotion/:promoCode`,
    'reaccept-legal-terms': `/d/c/reaccept-legal-terms`,
    'rebalance': `/d/c/rebalance`,
    'referrals': `/d/referrals`,
    'referrals-signup': `/signup/referrals`,
    'report-transaction-issue': `/d/c/report-transaction-issue/:disputeDetailsId`,
    'request-debit-card': `/d/c/request-debit-card/:step`,
    'research': `/d/research`,
    'research-crypto': `/d/research/crypto`,
    'research-crypto-details': `/d/research/crypto/details/:cryptoId`,
    'research-expert-pies': `/d/research/model-portfolios`, // Redirect only
    'research-fund-details': `/d/research/funds/details/:fundId`,
    'research-funds': `/d/research/funds`,
    'research-market-news': `/d/research/market-news`,
    'research-model-portfolio-category-details': `/d/research/model-portfolios/:categoryKey`,
    'research-model-portfolios': `/d/research/model-portfolios`,
    'research-my-pies': `/d/research/my-pies`,
    'research-stock-details': `/d/research/stocks/details/:equityId`,
    'research-stocks': `/d/research/stocks`,
    'research-watchlist': `/d/research/watchlist`,
    'resend-verification-email': `/d/c/resend-verification-email`,
    'reset-debit-card-pin': `/d/c/reset-debit-card-pin/:step`,
    'reset-password': `/reset-password/:token`,
    'resolve-call': `/d/c/resolve-call`,
    'save': `/d/save`,
    'save-checking': `/d/save/checking`,
    'save-checking-debit-card': `/d/save/checking/debit-card`,
    'save-checking-direct-deposit': `/d/save/checking/direct-deposit`,
    'save-checking-transactions': `/d/save/checking/transactions`,
    'save-marketing': `/d/save/marketing`,
    'save-savings': `/d/save/savings`,
    'save-savings-account': `/d/save/savings/account`,
    'save-savings-transactions': `/d/save/savings/transactions`,
    'savings-onboarding': `/onboarding/savings-onboarding/:step`,
    'savings-onboarding-initial-funding': `/onboarding/savings-onboarding-initial-funding`,
    'select-parent-pie': `/d/invest/select-parent-pie`,
    'select-payment-account': `/d/c/select-payment-account`,
    'set-order': `/d/c/set-order/:portfolioSliceId/:step`,
    'settings': `/d/settings`,
    'settings-accounts': `/d/settings/accounts`,
    'settings-appearance': `/d/settings/appearance`,
    'settings-billing': `/d/settings/billing`,
    'settings-documents': `/d/settings/documents`,
    'settings-notifications': `/d/settings/notifications`,
    'settings-payments': `/d/settings/payments`,
    'settings-profile': `/d/settings/profile`,
    'settings-profile-beneficiaries': `/d/settings/profile/beneficiaries`,
    'settings-profile-cash-disclosures': `/d/settings/profile/cash-disclosures`,
    'settings-profile-contact': `/d/settings/profile/contact`,
    'settings-profile-credit-disclosures': `/d/settings/profile/credit-disclosures`,
    'settings-profile-crypto-disclosures': `/d/settings/profile/crypto-disclosures`,
    'settings-profile-promotions-disclosures': `/d/settings/profile/promotions-disclosures`,
    'settings-profile-disclosures': `/d/settings/profile/disclosures`,
    'settings-profile-employment': `/d/settings/profile/employment`,
    'settings-profile-investment': `/d/settings/profile/investment`,
    'settings-profile-savings-disclosures': `/d/settings/profile/savings-disclosures`,
    'settings-profile-trusted-contact': `/d/settings/profile/trusted-contact`,
    'settings-security': `/d/settings/security`,
    'share': `/share`,
    'shared-pie-details': `/share`,
    'signup': `/signup`,
    'smart-transfer-contra-participant-entry-transfer-instances': `/d/c/smart-transfer-instances/:smartTransferContraParticipantEntryId`,
    'smart-transfer-details': `/d/c/smart-transfer-details/:smartTransferRuleId`,
    'spend': `/d/spend`,
    'spend-account-detail': `/d/spend/direct-deposit`,
    'spend-activity-entry-details': `/d/c/spend-activity-entry-details/:spendActivityEntryDetailsId`,
    'spend-checking': `/d/spend/checking`,
    'spend-checking-debit-card': `/d/spend/checking/debit-card`,
    'spend-checking-direct-deposit': `/d/spend/checking/direct-deposit`,
    'spend-checking-transactions': `/d/spend/transactions`,
    'spend-credit': `/d/spend/credit`,
    'spend-credit-closed': `/d/spend/credit/closed`,
    'spend-credit-manage': `/d/spend/credit/manage`,
    'spend-credit-marketing': `/d/spend/credit/marketing`,
    'spend-credit-rewards': `/d/spend/credit/rewards`,
    'spend-credit-rewards-payout': `/d/c/rewards-payout/:step`,
    'spend-credit-rewards-programs': `/d/c/rewards/programs/:programType`,
    'spend-credit-rewards-singular-program': `/d/c/rewards/program/:programType/:programId`,
    'spend-credit-rewards-summary': `/d/spend/credit/rewards/summary`,
    'spend-credit-transactions': `/d/spend/credit/transactions`,
    'spend-debit-card': `/d/spend/debit-card`,
    'spend-savings': `/d/spend/savings`,
    'spend-savings-account': `/d/spend/savings/account`,
    'spend-savings-transactions': `/d/spend/savings/transactions`,
    'transaction': `/d/c/transaction/:transactionId`,
    'transfer': `/d/c/transfer/:step`,
    'transfer-acat-instance-details': `/d/c/acat-transfer-details/:transferInstanceId`,
    'transfer-instance-details': `/d/c/transfer-details/:transferInstanceId`,
    'transfer-rule-details': `/d/rule-details/:transferRuleId`,
    'transfers': `/d/transfers`,
    'transfers-activity': `/d/transfers/activity`,
    'transfers-rules': `/d/transfers/rules`,
    'turn-off-plus-billing-auto-renewal': `/d/c/turn-off-plus-billing-auto-renewal/:step`,
    'unmanaged-holdings': `/d/invest/unmanaged-holdings`,
    'user-feedback': `/d/settings/feedback`,
    'user-pie-details': `/d/research/my-pies/details/:userPieId`,
    'verify-bank': `/d/c/verify-bank`,
    'verify-email': `/verify-email/:verificationToken`,
    'verify-email-error': `/verify-email/:verificationToken/error`,
    'verify-email-success': `/verify-email/:verificationToken/success`,
    'verify-spend-bank': `/d/c/verify-your-bank/:spendExternalAccountId/:step`,
    'waitlist': `/d/waitlist`,
    'waitlist-signup': `/d/c/waitlist/:step`,
    'welcome-back-savings': `/savings/welcome-back`,
    'wire-instructions': `/d/c/wire-transfer/wire-instructions`,
    'wire-transfer': `/d/c/wire-transfer`,
    'wizards': `/d/w`,

    // TODO: Find out if the cached named routes above are correct or the commented out ones below.
    // 'portfolio-excluded-buys': `/d/invest/portfolio/:portfolioSliceId/excluded-buys`,
    // 'portfolio-excluded-buys-root': `/d/invest/portfolio/excluded-buys`,
  };

  const matchingPath = namedRouteLookupTable[maybeRouteName];

  // Adding this check here, so we can catch any route name stragglers during
  // development. We'll want to make sure all route names are gone before we
  // can officially upgrade to the latest RR:
  if (
    matchingPath !== undefined &&
    __ENV__ !== 'production' &&
    // @ts-ignore: "test" isn't a valid value, but I didn't want to add it if we have a "test" environment.
    __ENV__ !== 'test'
  ) {
    // non-production code, allow console log.
    // prettier-ignore
    // eslint-disable-next-line no-console
    console.error(`Route name ${maybeRouteName} is being used instead of route path ${matchingPath}. Update to use path instead.`);
  }

  return matchingPath;
}

/**
 * Ensures the specified route path is in a valid format and returns the
 * valid formatted route path. If the route path is invalid, we return a path
 * that will redirect to the 404 page.
 */
function tryToGetFormattedRoutePath(routePath: string): string {
  /*
   * Splitting up the path elements by forward slashes and filtering out
   * empty strings helps us out in a couple of ways:
   * 1. If the resulting length is 0, that indicates that a categorically
   *    invalid route path was passed in (e.g. `///`), so we return 404.
   * 2. If a route path with two or more consecutive forward slashes was passed
   *    in, we can get rid of the extra slashes.
   */
  const pathElements = routePath
    .split('/')
    .map((element) => element.trim())
    .filter(Boolean);
  if (pathElements.length === 0) {
    return '/404';
  }

  // Add a preceding forward slash to the path, since it was stripped out when
  // we did the split operation above:
  return `/${pathElements.join('/')}`;
}
