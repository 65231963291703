import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  OpenAccountDocument,
  type OpenAccountMutationResult,
  OpenCustodialAccountDocument,
  type OpenCustodialAccountMutationResult,
  RequestOfflineOpenAccountDocument,
  type UpdatePieTreeMutationResult,
} from '~/graphql/hooks';
import type {
  OnlineAccountRegistrationEnum,
  OpenAccountInput,
  OpenCustodialAccountInput,
  OpenCustodialAccountMutationVariables,
} from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import { serializePieTree } from '~/pie-trees';
import type { AppState } from '~/redux';
import {
  ACTION_TYPES,
  type ChangeOpenInvestStepAction,
  type CreateInvestQuickClickPieAndNavigateAction,
  displayNotification,
  hideLoadingSpinner,
  type RetirementAccountTypes,
  setRetirementAccountType,
  showLoadingSpinner,
} from '~/redux/actions';
import {
  getLoggers,
  hasInvestSuitability,
  setRootPieSaga,
  updatePieTreeSaga,
} from '~/redux/sagas/common';
import { changeStep, makeFlowFuncs } from '~/redux/sagas/flows/utils';
import {
  getSecondaryProfile,
  showFailureToast,
  showSuccessToast,
} from '~/redux/sagas/open-invest-account/helpers';
import {
  INVEST_ACCOUNT_TYPES,
  NOTIFICATION_LOCATIONS,
  NOTIFICATION_TYPES,
  OPEN_INVEST_ACCOUNT_STEPS as STEPS,
} from '~/static-constants';
import type { ToastProps } from '~/toolbox/toast';
import { delay } from '~/utils';
import { formatDateForAccountMutation } from '~/utils/formatting';

import { apolloMutationSaga } from '../apolloMutationSaga';

const { takeFlow, takeFlowStep } = makeFlowFuncs('OPEN_INVEST_ACCOUNT');

export function* openInvestAccountSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginOpenInvestAccountFlow);
}

type AccountTypes = ValueOf<typeof INVEST_ACCOUNT_TYPES>;

type FinishedSelectedAccountTypePayload = {
  payload: AccountTypes;
};

function* beginOpenInvestAccountFlow(): SagaIterator<void> {
  // Account setup
  const hasAnsweredSuitability = yield call(hasInvestSuitability);
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  // if a user somehow does not yet have financial suitability info, send them to invest onboarding to collect it.
  if (hasAnsweredSuitability === false || hasAnsweredSuitability === null) {
    yield call(navigate, { to: '/onboarding/setup-invest-account' });
    return;
  }

  yield takeEvery(
    'CHANGE_OPEN_INVEST_ACCOUNT_STEP',
    function* (action: ChangeOpenInvestStepAction): SagaIterator<void> {
      yield call(changeStep, action.payload);
    },
  );

  yield fork(
    takeFlowStep,
    STEPS.SELECT_ACCOUNT_TYPE,
    finishedSelectAccountType,
  );

  // Individual account opening
  yield fork(takeFlowStep, STEPS.INDIVIDUAL_REVIEW, finishedIndividualReview);

  // Custodial account opening
  yield fork(takeFlowStep, STEPS.CUSTODIAL, finishedCustodialStep);
  yield fork(takeFlowStep, STEPS.CUSTODIAL_REVIEW, finishedCustodialReviewStep);

  // Retirement account opening
  yield fork(
    takeFlowStep,
    STEPS.RETIREMENT_SELECT,
    finishedRetirementSelection,
  );

  yield fork(
    takeFlowStep,
    STEPS.RETIREMENT_ROLLOVER,
    finishedRetirementRollover,
  );

  yield fork(
    takeFlowStep,
    STEPS.RETIREMENT_REVIEW,
    finishedRetirementReviewSelection,
  );

  // Crypto account opening
  yield fork(takeFlowStep, STEPS.CRYPTO_CHOOSE_PIE, finishedChoosePie);
  yield fork(takeFlowStep, STEPS.CRYPTO_ACCOUNT_REVIEW, finishedCryptoReview);
  yield takeEvery(
    ACTION_TYPES.CREATE_INVEST_QUICK_CLICK_PIE_AND_NAVIGATE,
    handleCreateQuickClickPie,
  );
  // @ts-expect-error - TS2769 - No overload matches this call.
  yield takeEvery('SET_SELECTED_ROOT_PIE', handleSelectedRootPie);

  // Trust account opening
  yield fork(takeFlowStep, STEPS.TRUST_SELECT, handleSelectedTrustAccount);
  yield fork(takeFlowStep, STEPS.TRUST_RECEIPT, handleFinishedTrustReceipt);
}

function* finishedSelectAccountType({
  payload,
}: FinishedSelectedAccountTypePayload): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state: AppState) => state.routing.navigate,
  );

  if (payload === INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE) {
    yield call(changeStep, STEPS.INDIVIDUAL_REVIEW);
  }

  if (payload === INVEST_ACCOUNT_TYPES.CUSTODIAL) {
    yield call(changeStep, STEPS.CUSTODIAL);
  }

  if (payload === INVEST_ACCOUNT_TYPES.JOINT_TAXABLE) {
    const hasSecondaryProfile = yield call(getSecondaryProfile);

    if (hasSecondaryProfile === true) {
      // TODO: determine if this is okay from a UX perspective. need to understand if users can have a secondary profile without an open joint account.
      yield put({
        payload: {
          content:
            'At this time, M1 only supports one joint account for the people on the account.',
          kind: 'alert',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    } else {
      yield call(navigate, { to: '/d/open-invest-joint-account' });
    }
  }

  if (payload === INVEST_ACCOUNT_TYPES.CRYPTO) {
    yield call(changeStep, STEPS.CRYPTO_ACCOUNT_REVIEW);
  }

  if (payload === INVEST_ACCOUNT_TYPES.RETIREMENT) {
    yield call(changeStep, STEPS.RETIREMENT_SELECT);
  }

  if (payload === INVEST_ACCOUNT_TYPES.OTHER) {
    yield call(changeStep, STEPS.TRUST_SELECT);
  }
}

function* finishedIndividualReview({
  payload,
}: {
  payload: { signature: string; fplStatus?: boolean };
}): SagaIterator<void> {
  yield put(showLoadingSpinner());

  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const { analytics } = yield call(getLoggers);
  try {
    const result: OpenAccountMutationResult = yield call(apolloMutationSaga, {
      mutation: OpenAccountDocument,
      variables: {
        input: {
          registration: 'INDIVIDUAL',
          signature: payload.signature,
          fplStatus: payload.fplStatus,
        } satisfies OpenAccountInput,
      },
    });

    const accountId = result?.data?.openAccount?.outcome?.account?.id;

    if (!accountId) {
      yield call(
        showFailureToast,
        'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
      );
    } else {
      yield put({
        type: 'SET_ACTIVE_INVEST_ACCOUNT',
        payload: accountId,
      });

      // if brokerage account, fire investment account opened
      analytics.recordEvent('investmentAccountOpened');

      // @ts-ignore put expects an action creator
      yield call(navigate, { to: '/d/invest/portfolio' });

      yield call(
        showSuccessToast,
        'Success! Your account was successfully created.',
      );
    }
  } catch (e: any) {
    yield call(showFailureToast, e.message);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedRetirementSelection({
  payload,
}: {
  payload: {
    retirementChoiceType: RetirementAccountTypes;
  };
}): SagaIterator<void> {
  switch (payload.retirementChoiceType) {
    case 'ROLLOVER':
      yield call(changeStep, STEPS.RETIREMENT_ROLLOVER);
      break;
    case 'TRADITIONAL_IRA':
    case 'ROTH_IRA':
    case 'SEP_IRA':
    default:
      yield put(setRetirementAccountType(payload.retirementChoiceType));
      yield call(changeStep, STEPS.RETIREMENT_REVIEW);
  }
}

function* finishedRetirementRollover({
  payload,
}: {
  payload: RetirementAccountTypes;
}): SagaIterator<void> {
  yield put(setRetirementAccountType(payload));

  yield call(changeStep, STEPS.RETIREMENT_REVIEW);
}

function* finishedRetirementReviewSelection({
  payload,
}: {
  payload: {
    registration: RetirementAccountTypes;
    signature: string;
    fplStatus?: boolean;
  };
}): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield put(showLoadingSpinner());
  try {
    const result: OpenAccountMutationResult = yield call(apolloMutationSaga, {
      mutation: OpenAccountDocument,
      variables: {
        input: {
          registration: payload.registration as OnlineAccountRegistrationEnum,
          signature: payload.signature,
          fplStatus: payload.fplStatus,
        } satisfies OpenAccountInput,
      },
    });

    const accountId = result?.data?.openAccount?.outcome?.account?.id;

    if (!accountId) {
      yield call(
        showFailureToast,
        'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
      );
    } else {
      yield put({
        type: 'SET_ACTIVE_INVEST_ACCOUNT',
        payload: accountId,
      });

      yield call(navigate, { to: '/d/invest/portfolio' });

      yield call(
        showSuccessToast,
        'Success! Your account was successfully created.',
      );
    }
  } catch (e: any) {
    yield call(showFailureToast, e.message);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedCryptoReview({
  payload,
}: {
  payload: { signature: string };
}): SagaIterator<void> {
  yield put(showLoadingSpinner());

  const { analytics } = yield call(getLoggers);
  try {
    const result: OpenAccountMutationResult = yield call(apolloMutationSaga, {
      mutation: OpenAccountDocument,
      variables: {
        input: {
          registration: 'CRYPTO',
          signature: payload.signature,
        } satisfies OpenAccountInput,
      },
    });

    const accountId = result?.data?.openAccount?.outcome?.account?.id;

    if (!accountId) {
      analytics.recordEvent('m1_crypto_account_rejected');

      yield call(
        showFailureToast,
        'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
      );
    } else {
      analytics.recordEvent('m1_crypto_account_opened');

      yield put({
        type: 'ENDED_INVEST_MARKETING_SESSION',
      });

      yield put({
        type: 'SET_ACTIVE_CRYPTO_ACCOUNT',
        payload: accountId,
      });

      yield call(changeStep, STEPS.CRYPTO_CHOOSE_PIE);
    }
  } catch (e: any) {
    yield call(showFailureToast, e.message);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedCustodialStep(): SagaIterator<void> {
  yield call(changeStep, STEPS.CUSTODIAL_REVIEW);
}

function* finishedCustodialReviewStep({
  payload,
}: {
  payload: {
    signature: string;
  };
}): SagaIterator<void> {
  const input = yield select((state: AppState) => {
    return state.form['custodial-contact-info'].values;
  });

  const { ssn, legalResidence, dateOfBirth, ...rest } = input;

  const mutationInput: OpenCustodialAccountInput = {
    custodialAccountBeneficiary: {
      ...rest,
      dateOfBirth: formatDateForAccountMutation(dateOfBirth),
      legalResidence: {
        ...legalResidence,
        country: 'USA',
      },
    },
    custodialAccountBeneficiarySsn: ssn,
    signature: payload.signature,
  };

  yield put(showLoadingSpinner());

  try {
    const { data: result }: OpenCustodialAccountMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: OpenCustodialAccountDocument,
        variables: {
          input: mutationInput,
        } satisfies OpenCustodialAccountMutationVariables,
      },
    );

    const navigate: NavigateFunction = yield select(
      (state) => state.routing.navigate,
    );

    const accountId = result?.openCustodialAccount?.outcome?.account?.id;
    if (!accountId) {
      throw new Error('Unable to read custodial account ID ');
    }

    yield put({
      type: 'SET_ACTIVE_INVEST_ACCOUNT',
      payload: accountId,
    });

    yield call(
      showSuccessToast,
      `Your custodial account for ${input.firstName} ${input.lastName} has been opened.`,
    );

    yield call(navigate, { to: '/d/invest/portfolio' });
  } catch (e: any) {
    yield call(showFailureToast, e.message);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedChoosePie(action: { payload: string }): SagaIterator<void> {
  yield call(
    changeStep,
    STEPS.CRYPTO_PIE_DETAILS,
    false,
    {},
    {
      pieId: action.payload,
    },
  );
}

function* handleCreateQuickClickPie(
  action: CreateInvestQuickClickPieAndNavigateAction,
): SagaIterator<void> {
  const { pie: pieTree } = action.payload;

  yield put(showLoadingSpinner());

  try {
    const serializedTree = serializePieTree(pieTree);
    const updatePieTreeResult: UpdatePieTreeMutationResult = yield call(
      updatePieTreeSaga,
      serializedTree,
      true, // isCrypto
    );

    yield call(
      changeStep,
      STEPS.CRYPTO_PIE_DETAILS,
      false,
      {},
      {
        pieId: updatePieTreeResult.data?.updatePieTree?.pie?.id,
      },
    );
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* handleSelectedRootPie(action: {
  payload: string;
}): SagaIterator<void> {
  const accountId = yield select(
    (state: AppState) => state.newFlows.accountSetup.accountId,
  );

  yield call(setRootPieSaga, accountId, action.payload);
}

function* handleSelectedTrustAccount(): SagaIterator<void> {
  try {
    yield put(showLoadingSpinner());

    yield all([
      call(apolloMutationSaga, {
        mutation: RequestOfflineOpenAccountDocument,
        variables: {
          input: {
            registration: 'TRUST',
          },
        },
      }),

      call(delay, 1600),
    ]);

    yield call(changeStep, STEPS.TRUST_RECEIPT);
  } catch (e: any) {
    yield put(
      displayNotification({
        type: NOTIFICATION_TYPES.ERROR_ALT,
        location: NOTIFICATION_LOCATIONS.OTHER_ACCOUNT_TYPES,
        message: 'There was a problem. Please contact support.',
      }),
    );
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* handleFinishedTrustReceipt(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/invest/portfolio' });
}
