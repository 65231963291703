import { Box, Flex, PM, PS } from '@m1/liquid-react';
import { cloneDeep } from 'lodash-es';
import * as React from 'react';

import { Feedback } from '~/components/Feedback';
import { GenericSystemError } from '~/components/GenericSystemError';
import { usePieEditModelQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useLocation } from '~/hooks/useLocation';
import { useParams } from '~/hooks/useParams';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { StyledPageContent } from '../../navigation/Navigation.styled';

import type { PieEditorModelKind, TreeItems } from './PieEditor.types';
import {
  generateNewPieTreeItems,
  getRemoteEditModel,
  mapRemotePieToTreeItems,
  mapRemotePortfolioToTreeItems,
  NEW_CRYPTO_PIE_ROUTE,
  NEW_PIE_ROUTE,
} from './PieEditor.utils';
import { PieEditorHeader } from './PieEditorHeader';
import { SunburstPie } from './SortableTree/components/SunburstPie';
import { SortableTree } from './SortableTree/SortableTree';

export const PieEditor = () => {
  const { pieEditorRouteParam } = useParams();
  const location = useLocation();
  const { history, historyIndex } = useSelector((state) => ({
    history: state.pieEditor.history,
    historyIndex: state.pieEditor.historyIndex,
  }));
  const hasHandledAddedSlice = React.useRef(false);
  const isNewPie =
    pieEditorRouteParam === NEW_PIE_ROUTE ||
    pieEditorRouteParam === NEW_CRYPTO_PIE_ROUTE;
  const dispatch = useDispatch();
  const originalItems = React.useRef<TreeItems>([]);
  const { contentWidth } = useLayout();
  const [showPieVisual, setShowPieVisual] = React.useState(false);
  const { data, loading } = usePieEditModelQuery({
    variables: {
      pieEditorRouteParam: pieEditorRouteParam as string,
    },
    fetchPolicy: 'cache-and-network',
    skip: isNewPie,
  });

  React.useEffect(() => {
    // Add slice from location state if it exists
    if (
      !hasHandledAddedSlice.current &&
      history.length &&
      location.state?.addedSlice?.id
    ) {
      dispatch({
        type: 'PIE_EDITOR_ADD_SLICES_COMPLETE',
        payload: {
          addToSelfPie: true,
          slices: [location.state.addedSlice],
        },
      });
      hasHandledAddedSlice.current = true;
    }
  }, [dispatch, history.length, location.state?.addedSlice?.id]);

  if ((!data || loading) && !isNewPie) {
    return <Spinner />;
  }

  let treeItems: TreeItems | null = null;
  let pieKind: PieEditorModelKind = 'PIE';
  let pieIsCrypto = false;
  if (isNewPie) {
    treeItems = generateNewPieTreeItems();
    pieIsCrypto = pieEditorRouteParam === NEW_CRYPTO_PIE_ROUTE;
  } else if (data) {
    try {
      const { model, kind, isCrypto } = getRemoteEditModel(data.node);
      pieKind = kind;
      pieIsCrypto = isCrypto;
      if (kind === 'PIE') {
        treeItems = mapRemotePieToTreeItems(
          model,
          pieEditorRouteParam as string,
        );
      } else {
        const isAccount = data.node?.__typename === 'Account';
        // If it's an account ID, just use default logic for second arg (collapse all but root)
        treeItems = mapRemotePortfolioToTreeItems(
          model,
          isAccount ? undefined : pieEditorRouteParam,
        );
      }
    } catch (e) {
      return <GenericSystemError />;
    }
  }

  const rootId =
    treeItems && 'id' in treeItems[0] ? String(treeItems[0].id) : null;

  if (!history.length && treeItems) {
    dispatch({
      type: 'PIE_EDITOR_SET_INITIAL_PIE',
      payload: {
        treeItems,
        rootId,
        kind: pieKind,
        uncollapse: isNewPie,
        isCrypto: pieIsCrypto,
      },
    });
    if (!originalItems.current.length) {
      originalItems.current = cloneDeep(treeItems);
    }
  }

  const currentTreeItems = history[historyIndex]?.tree ?? [];
  const rootItem = currentTreeItems[0];

  return (
    <Box width="100%">
      <StyledPageContent contentWidth={contentWidth}>
        <PieEditorHeader
          id={rootId}
          items={currentTreeItems}
          name={rootItem?.meta.name}
          history={history}
          historyIndex={historyIndex}
          onTogglePieVisual={() => setShowPieVisual((prev) => !prev)}
        />
        <Flex>
          {showPieVisual && (
            <Box position="sticky" top={100} alignSelf="flex-start" width="33%">
              <SunburstPie items={currentTreeItems[0]?.children} />
            </Box>
          )}
          <Flex
            py={32}
            flexDirection="column"
            flex="1"
            maxWidth="100%"
            minWidth={0}
          >
            <Flex justifyContent="space-between" mb={8}>
              <PM fontWeight={600} color="foregroundNeutralSecondary">
                Slices
              </PM>
              <PM fontWeight={600} color="foregroundNeutralSecondary" mr={116}>
                Allocations
              </PM>
            </Flex>
            <SortableTree items={currentTreeItems} collapsible indicator />
            <PS
              color="foregroundNeutralSecondary"
              fontStyle="italic"
              textAlign="center"
              alignSelf="center"
              maxWidth={480}
              mt={32}
            >
              You're enjoying our new pie editor experience! If you have
              feedback, please submit it using the button below.
            </PS>
            <Feedback
              justifyContent="center"
              mt={16}
              content="Provide pie editor feedback"
              href="https://m1finance.slack.com/archives/C07BYDZMX2Q"
            />
          </Flex>
        </Flex>
      </StyledPageContent>
    </Box>
  );
};
