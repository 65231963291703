import * as React from 'react';

import { PhoneVerificationWizard } from '~/components/phoneVerification/PhoneVerificationWizard';
import { useNavigate } from '~/hooks/useNavigate';

export const PhoneVerificationPage = () => {
  const navigate = useNavigate();

  return (
    <PhoneVerificationWizard
      onWizardCompletion={() => {
        navigate({ to: '/onboarding/setup-account' });
      }}
    />
  );
};
