import { Box, PL, PXS, Card } from '@m1/liquid-react';
import React from 'react';

import { LinkableLink } from '~/lens-toolbox/LinkableLink';

import type { AcatInstanceTransferSpecificMessage } from '../types';

type AcatTransferSpecificMessageCardProps = {
  transferSpecificMessage: AcatInstanceTransferSpecificMessage;
};

export const AcatTransferSpecificMessageCard = ({
  transferSpecificMessage,
}: AcatTransferSpecificMessageCardProps) => {
  return (
    <Card p={16}>
      <PL fontWeight={600} content={transferSpecificMessage.title} mb={4} />
      <PXS content={transferSpecificMessage.description} mb={4} />
      {transferSpecificMessage.link && (
        <Box m="16px auto 32">
          <LinkableLink
            kind="primary"
            font="LS"
            linkable={transferSpecificMessage.link}
          />
        </Box>
      )}
    </Card>
  );
};
