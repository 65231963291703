import { Box, Button, Flex, HXS, PXL, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { SmartTransfersFocusAccountNavigationWarningModal } from '~/components/modals';
import { SmartTransferContraParticipantEntryOptions } from '~/components/SmartTransferContraParticipantEntryOptions';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';
import { useNavigate } from '~/hooks/useNavigate';
import {
  ACTION_TYPES as ACTIONS,
  addSmartTransferContraParticipantIdAction,
  reorderActiveContraParticipants,
} from '~/redux/actions';
import { useDispatch, useIsEqualSelector } from '~/redux/hooks';
import { Link } from '~/toolbox/link';

import {
  SmartTransferBubble,
  SmartTransferToggle,
} from '../SmartTransferHelpers';
import { SmartTransferTimingMessage } from '../SmartTransferTimingMessage';

import { SmartTransferBalanceTriggerInput } from './SmartTransferBalanceTriggerInput';
import { SmartTransferRefillBalanceInput } from './SmartTransferRefillBalanceInput';
import { SmartTransferWaterfall } from './SmartTransferWaterfall';

type SmartTransferCreateFormProps = {
  editRequirements: any;
  focusAccountId: string;
  onFinishStep: (
    arg0:
      | {
          newSmartTransferContraParticipantEntryOptionId: string;
        }
      | null
      | undefined,
  ) => void;
  refetch: any;
};

const StyledFlexFocusAccountSection = styled(Flex)`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.foregroundNeutralTertiary};
  padding: 8px 32px 16px 32px;
`;

export const SmartTransferCreateForm = ({
  editRequirements,
  focusAccountId,
  onFinishStep,
  refetch,
}: SmartTransferCreateFormProps) => {
  const navigate = useNavigate();
  const [balanceTrigger, contraParticipantEntries] = useIsEqualSelector(
    (state) => {
      return [
        state.newFlows.CREATE_SMART_TRANSFER.input.balanceTrigger,
        state.newFlows.CREATE_SMART_TRANSFER.input.contraParticipantEntries,
      ];
    },
  );

  const isContraEntriesGreaterThanOrEqualToOne =
    contraParticipantEntries.length >= 1;

  // for balance trigger threshold
  const [balanceThresholdAmount, setBalanceThresholdAmount] = React.useState<
    number | null | undefined
  >(
    typeof balanceTrigger?.balanceThreshold === 'number'
      ? balanceTrigger.balanceThreshold
      : null,
  );
  const [
    balanceThresholdAmountErrorMessage,
    setBalanceThresholdAmountErrorMessage,
  ] = React.useState('');

  // for under balance refill target
  const [underBalanceRefillTargetValue, setUnderBalanceRefillTargetValue] =
    React.useState<number | null | undefined>(
      typeof balanceTrigger?.underBalanceRefillTarget === 'number'
        ? balanceTrigger.underBalanceRefillTarget
        : null,
    );
  const [
    underBalanceRefillTargetErrorMessage,
    setUnderBalanceRefillTargetErrorMessage,
  ] = React.useState('');

  // for modal
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const isUnderBalance =
    editRequirements.balanceTriggerEditRequirements.balanceTriggerType ===
    'UNDER_BALANCE';
  // for helper bubbles
  const isOverBalance =
    editRequirements.balanceTriggerEditRequirements.balanceTriggerType ===
    'OVER_BALANCE';

  // for edit order mode
  const [isInEditOrderMode, setIsInEditOrderMode] = React.useState(false);
  const [contraParticipantIdOrder, setContraParticipantIdOrder] =
    React.useState([]);

  const isContinueButtonDisabled = Boolean(
    (underBalanceRefillTargetValue !== null &&
      underBalanceRefillTargetValue === 0) ||
      balanceThresholdAmount === null ||
      contraParticipantEntries.length === 0 ||
      Boolean(balanceThresholdAmountErrorMessage) ||
      Boolean(underBalanceRefillTargetErrorMessage) ||
      isInEditOrderMode,
  );
  // when balance trigger data changes, update the store
  const dispatch = useDispatch();
  React.useEffect(() => {
    const payload = isUnderBalance
      ? {
          amount: balanceThresholdAmount || 0,
          balanceTriggerType: 'UNDER_BALANCE',
          underBalanceRefillTarget: underBalanceRefillTargetValue || 0,
        }
      : {
          amount: balanceThresholdAmount || 0,
          balanceTriggerType: 'OVER_BALANCE',
        };
    dispatch({
      type: ACTIONS.SET_SMART_TRANSFER_BALANCE_TRIGGER_DATA,
      payload,
    });
  }, [
    balanceThresholdAmount,
    dispatch,
    isUnderBalance,
    underBalanceRefillTargetValue,
  ]);

  React.useEffect(() => {
    if (contraParticipantEntries) {
      refetch({
        contraParticipantEntries,
        smartTransferRuleEditRequirementsId: editRequirements.id,
        insertionContext: {
          contraParticipantEntries,
          newEntryInsertionIndex: contraParticipantEntries.length,
        },
      });
    }
  }, [contraParticipantEntries, editRequirements, refetch]);

  const linkNavigationProps = isContraEntriesGreaterThanOrEqualToOne
    ? {
        onClick: () => setIsWarningModalOpen(true),
      }
    : {
        to: '/d/c/create-smart-transfer/initialize-smart-transfer',
      };
  const [showEntryOptions, setShowEntryOptions] = React.useState(
    contraParticipantEntries.length === 0,
  );

  const handleEditOrder = () => {
    if (isInEditOrderMode && contraParticipantIdOrder.length > 1) {
      dispatch(reorderActiveContraParticipants(contraParticipantIdOrder));
    }

    setIsInEditOrderMode(!isInEditOrderMode);
  };

  const [activeStepNumber, setActiveStepNumber] = React.useState(
    isContraEntriesGreaterThanOrEqualToOne ? 3 : 1,
  );

  return (
    <>
      <Box>
        <BackArrow
          content="Select Smart Transfer Type"
          mb={16}
          {...linkNavigationProps}
        />
        <Flex justifyContent="space-between">
          <HXS content="Create Smart Transfer" />
          <SmartTransferToggle />
        </Flex>
        <Card mt={32} py={12}>
          <StyledFlexFocusAccountSection
            alignItems="center"
            justifyContent="space-between"
          >
            <TransferParticipantCellRenderer
              id={focusAccountId}
              includeDescription
            />
            {linkNavigationProps.to ? (
              <Link to={linkNavigationProps.to}>Change</Link>
            ) : (
              <Button label="Change" onClick={linkNavigationProps.onClick} />
            )}
          </StyledFlexFocusAccountSection>
          <PXL
            fontWeight={600}
            content={editRequirements.balanceThresholdPreamble || '--'}
            mt={32}
            mx={32}
          />
          <Box mx={32}>
            <SmartTransferBalanceTriggerInput
              balanceTriggerEditRequirements={
                editRequirements.balanceTriggerEditRequirements
              }
              onInputDataChange={({ amountValue, amountErrorMessage }) => {
                amountValue === ''
                  ? setBalanceThresholdAmount(null)
                  : setBalanceThresholdAmount(Number(amountValue));
                setBalanceThresholdAmountErrorMessage(amountErrorMessage);
              }}
              mt={8}
              preFilledAmount={
                typeof balanceThresholdAmount === 'number'
                  ? `${balanceThresholdAmount}`
                  : ''
              }
            />
          </Box>
          {isUnderBalance && (
            <Box mx={32}>
              <PXL fontWeight={600} content="Refill balance to" mt={16} />
              <SmartTransferRefillBalanceInput
                balanceTriggerEditRequirements={
                  editRequirements.balanceTriggerEditRequirements
                }
                balanceThresholdAmount={
                  typeof balanceThresholdAmount === 'number'
                    ? `${balanceThresholdAmount}`
                    : ''
                }
                onInputDataChange={({
                  underBalanceRefillTargetAmount,
                  underBalanceRefillTargetAmountErrorMessage,
                }) => {
                  setUnderBalanceRefillTargetValue(
                    Number(underBalanceRefillTargetAmount),
                  );
                  setUnderBalanceRefillTargetErrorMessage(
                    underBalanceRefillTargetAmountErrorMessage,
                  );
                }}
                preFilledAmount={
                  typeof underBalanceRefillTargetValue === 'number'
                    ? `${underBalanceRefillTargetValue}`
                    : ''
                }
                mt={8}
                mx={32}
              />
            </Box>
          )}
          <SmartTransferBubble
            activeStepNumber={activeStepNumber}
            isOverBalance={isOverBalance}
            isUnderBalance={isUnderBalance}
            stepNumber={1}
            totalSteps={2}
            handleGoForward={() => setActiveStepNumber(2)}
          />
          <Flex justifyContent="space-between" mt={32} mx={32}>
            <PXL
              fontWeight={600}
              content={editRequirements.contraParticipantListPreamble}
            />
            {editRequirements.shouldShowContraParticipantEntryReordering && (
              <Button
                kind="link"
                label={isInEditOrderMode ? 'Done' : 'Edit Order'}
                onClick={handleEditOrder}
              />
            )}
          </Flex>
          {isContraEntriesGreaterThanOrEqualToOne && (
            <Flex flexDirection="column">
              <SmartTransferWaterfall
                balanceTriggerType={
                  balanceTrigger?.balanceTriggerType || 'OVER_BALANCE'
                }
                contraParticipantEntries={contraParticipantEntries}
                editRequirements={editRequirements}
                focusAccountId={focusAccountId}
                isInEditOrderMode={isInEditOrderMode}
                isShowingEntryOptions={showEntryOptions}
                onAddMoreContraParticipantsClick={() =>
                  setShowEntryOptions(true)
                }
                onEditContraClick={(contraEditingId) => {
                  if (contraEditingId) {
                    dispatch(
                      addSmartTransferContraParticipantIdAction(
                        contraEditingId,
                      ),
                    );
                  }
                  dispatch({
                    type: ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE,
                    payload: 'EDIT',
                  });

                  navigate({
                    to: '/d/c/create-smart-transfer/:step',
                    params: { step: 'set-fulfillment-condition' },
                  });
                }}
                setContraParticipantIdOrder={setContraParticipantIdOrder as any}
              />
              {/* explicitly setting activeStepNumber for addDestination scenario */}
              <SmartTransferBubble
                addDestination
                activeStepNumber={1}
                isOverBalance={isOverBalance}
                isUnderBalance={isUnderBalance}
                stepNumber={1}
                totalSteps={1}
              />
            </Flex>
          )}
          {showEntryOptions &&
            editRequirements.newContraParticipantEntryOptionSet && (
              <Box mt={16} mb={32}>
                <SmartTransferContraParticipantEntryOptions
                  newContraParticipantEntryOptionSet={
                    editRequirements.newContraParticipantEntryOptionSet
                  }
                  onOptionSelect={(
                    newSmartTransferContraParticipantEntryOptionId,
                  ) => {
                    onFinishStep({
                      newSmartTransferContraParticipantEntryOptionId,
                    });
                  }}
                />
              </Box>
            )}
          <SmartTransferBubble
            activeStepNumber={activeStepNumber}
            isOverBalance={isOverBalance}
            isUnderBalance={isUnderBalance}
            handleGoBackward={() => setActiveStepNumber(1)}
            stepNumber={2}
            totalSteps={2}
          />
          <SmartTransferTimingMessage
            balanceTrigger={{
              balanceThreshold: balanceThresholdAmount || 0,
              balanceTriggerType: editRequirements
                .balanceTriggerEditRequirements.balanceTriggerType as any,
            }}
            editRequirementsId={editRequirements.id}
            mt={16}
            mx={32}
          />
          <Flex justifyContent="center">
            <Button
              disabled={isContinueButtonDisabled}
              kind="primary"
              label="Create Smart Transfer"
              my={32}
              // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
              onClick={() => onFinishStep()}
              size="large"
            />
          </Flex>
        </Card>
      </Box>
      <SmartTransfersFocusAccountNavigationWarningModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
      />
    </>
  );
};
