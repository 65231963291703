import { Box, Flex, HXXS, Skeleton, theme } from '@m1/liquid-react';
import React from 'react';

import { DisclosureLink } from '~/components/DisclosureLink/DisclosureLink';
import { DividendReportChart } from '~/components/invest/DividendReportChart/DividendReportChart';
import type {
  PortfolioDividendsChartQueryHookResult,
  PortfolioDividendsTablesQueryHookResult,
} from '~/graphql/hooks';
import type { DividendTrackerDataPeriod } from '~/graphql/types';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';
import { createRichTextParagraphs } from '~/utils/createRichTextParagraphs';

import { DividendHoldingsTable } from './DividendHoldingsTable/DividendHoldingsTable';
import { UpcomingDividendsTable } from './UpcomingDividendsTable';

export const DividendsView = ({
  dividendPeriod,
  chartData,
  isSkeletonLoading,
  isChartLoading,
  tableDividendData,
}: {
  dividendPeriod: DividendTrackerDataPeriod;
  chartData: PortfolioDividendsChartQueryHookResult['data'];
  isSkeletonLoading: boolean;
  isChartLoading: boolean;
  tableDividendData: PortfolioDividendsTablesQueryHookResult['data'];
}) => {
  const initialPerformanceData = [
    Array(12).fill(0),
    Array(12).fill(0),
    Array(12).fill(0),
    Array(12).fill(0),
  ];

  const [earnedDividends, upcomingDividends, pendingDividends, topEarners] = (
    chartData?.viewer.invest?.dividends?.dividendPerformance ?? []
  ).reduce(
    (
      [earnedDividends, upcomingDividends, pendingDividends, topEarners],
      month,
      i,
    ) => [
      [
        ...earnedDividends.slice(0, i),
        month.paid,
        ...earnedDividends.slice(i + 1),
      ],
      [
        ...upcomingDividends.slice(0, i),
        month.upcoming,
        ...upcomingDividends.slice(i + 1),
      ],
      [
        ...pendingDividends.slice(0, i),
        month.pending,
        ...pendingDividends.slice(i + 1),
      ],
      [...topEarners.slice(0, i), month.topEarners, ...topEarners.slice(i + 1)],
    ],
    initialPerformanceData,
  );

  // TODO: This will be false if the period has no dividends, which doesn't necessarily mean
  //       that the account has no dividend paying positions. Might be Lens update with flag.
  const hasDividendHistory = Boolean(
    earnedDividends.some((dividend) => dividend > 0) ||
      upcomingDividends.some((dividend) => dividend > 0) ||
      pendingDividends.some((dividend) => dividend > 0),
  );

  const {
    currentPositions,
    upcomingDividends: upcoming,
    disclosure,
  } = tableDividendData?.viewer.invest?.dividends ?? {};

  const holdings = currentPositions?.edges
    ?.map((edge) => edge?.node)
    .filter(isNotNil);

  const upcomingDividendDetails = upcoming?.edges
    ?.map((edge) => edge?.node)
    .filter(isNotNil);

  return (
    <>
      <Box
        style={{
          borderBottom: `1px solid ${theme.colors.foregroundNeutralTertiary}`,
        }}
        pb={32}
        mb={27}
      >
        {isChartLoading && !isSkeletonLoading ? (
          <Flex alignItems="center" height={528}>
            <Spinner />
          </Flex>
        ) : (
          <>
            <Flex my={32} alignItems="center" justifyContent="space-between">
              <Skeleton>
                <HXXS fontWeight={300}>Performance</HXXS>
              </Skeleton>
            </Flex>
            {hasDividendHistory && (
              <DividendReportChart
                dividendPeriod={dividendPeriod}
                upcomingDividends={upcomingDividends}
                earnedDividends={earnedDividends}
                pendingDividends={pendingDividends}
                topEarners={topEarners}
              />
            )}
          </>
        )}
        <Skeleton>
          <Link to="https://help.m1.com/en/articles/9332162-dividends-on-m1">
            Understanding dividend information
          </Link>
        </Skeleton>
      </Box>
      <Box mb={24}>
        <Skeleton mb={15}>
          <HXXS fontWeight={300}>Upcoming dividends</HXXS>
        </Skeleton>
        <UpcomingDividendsTable upcomingDividends={upcomingDividendDetails} />
      </Box>
      <Box>
        <Skeleton mb={15}>
          <HXXS fontWeight={300}>Holdings</HXXS>
        </Skeleton>
        <DividendHoldingsTable holdings={holdings} />
      </Box>
      {disclosure && (
        <Box mt={32} mb={64}>
          <Skeleton>
            <DisclosureLink
              linkText={disclosure.linkText}
              paragraphs={createRichTextParagraphs(disclosure.paragraphs)}
            />
          </Skeleton>
        </Box>
      )}
    </>
  );
};
