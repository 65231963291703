import type { Slice } from './types';

export const sortSlicesByPercentage = (
  slices: Array<Slice>,
  sortDirection: 'ASC' | 'DESC',
): Array<Slice> => {
  if (slices.length === 0) {
    return slices;
  }

  // Create a copy of the array because arrays are sorted in place:
  const slicesCopy = [...slices];

  if (sortDirection === 'ASC') {
    slicesCopy.sort((a, b) => a.percentage - b.percentage);
  } else {
    slicesCopy.sort((a, b) => b.percentage - a.percentage);
  }

  return slicesCopy;
};
