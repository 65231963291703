import { Flex, PL, PM, Text } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { Gain } from '~/components/gain';
import { PositionGainText } from '~/components/PositionGainText';
import type { InvestActivityNodeFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { isNotNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

import { getActivitySummary } from '../utils';

export const InvestActivityRow = ({
  activity,
}: {
  activity: InvestActivityNodeFragment;
}) => {
  return (
    <GridTable.NavigableRow
      key={activity.id}
      to="/d/c/activity/:activityId/activity-details"
      params={{
        activityId: activity.id,
      }}
    >
      <GridTable.Cell
        alignSelf="center"
        content={moment.parseZone(activity.date).format('ll')}
      />
      <GridTable.Cell
        alignSelf="center"
        content={<Text content={activity.title} fontWeight={600} />}
      />
      <GridTable.Cell
        alignSelf="center"
        content={
          <Text whiteSpace="pre-wrap" content={getActivitySummary(activity)} />
        }
      />
      <GridTable.Cell
        alignSelf="center"
        content={renderActivityValue(activity)}
        justifySelf="end"
        textAlign="right"
      />
    </GridTable.NavigableRow>
  );
};

const renderActivityValue = (activity: InvestActivityNodeFragment) => {
  switch (activity.__typename) {
    case 'InvestActivityTradeSummaryEntry': {
      return (
        <>
          {typeof activity.amountOfBuys === 'number' &&
            activity.amountOfBuys > 0 && (
              <>
                <Text content={formatCurrency(activity.amountOfBuys)} />
                <br />
              </>
            )}
          {typeof activity.amountOfSells === 'number' &&
            activity.amountOfSells > 0 && (
              <>
                <Text content={formatCurrency(activity.amountOfSells)} />
              </>
            )}
        </>
      );
    }
    case 'InvestActivityTradeEntry':
      return activity.amount ? formatCurrency(activity.amount) : '--';
    case 'InvestActivityCashEntry': {
      return isNotNil(activity.transferDetails?.totalAmount) ? (
        <Flex flexDirection="column">
          <PL content={<Gain value={activity.amount} />} />
          <PM
            fontWeight={600}
            content={`of $${activity.transferDetails.totalAmount}`}
          />
        </Flex>
      ) : (
        <PL content={<Gain value={activity.amount} />} />
      );
    }
    case 'InvestActivityPositionEntry':
      return (
        <PositionGainText
          render={({ shouldPluralize, value }) =>
            `${value} share${shouldPluralize ? 's' : ''}`
          }
          value={activity.quantity}
        />
      );
    default:
      return null;
  }
};
