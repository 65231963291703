import { HXXS, Flex, Skeleton, SkeletonProvider, Card } from '@m1/liquid-react';
import React from 'react';

import { Speedometer } from '~/components/Speedometer/Speedometer';
import { usePersonalLoanLandingPageGraphDetailsQuery } from '~/graphql/hooks';
import type { AppTooltipFragment } from '~/graphql/types';
import { StyledDetailsCardContainer } from '~/pages/dashboard/common/ProductLandingPageCard.styled';

export const PaymentProgressCard = ({ accountId }: { accountId: string }) => {
  const { data, loading } = usePersonalLoanLandingPageGraphDetailsQuery({
    variables: { loanId: accountId },
  });

  if (data?.node?.__typename !== 'PersonalLoan') {
    return null;
  }

  const graphDetails = data?.node?.personalLoanProductLandingPage?.graphDetails;

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      {graphDetails?.percentage ? (
        <Skeleton skeletonWidth="100%" mt={32}>
          <StyledDetailsCardContainer>
            <Card
              backgroundColor="backgroundNeutralSecondary"
              padding={24}
              minWidth={248}
            >
              <HXXS
                content={graphDetails?.title ?? 'Payoff progress'}
                fontWeight={300}
              />
              <Flex position="relative">
                <Speedometer
                  percentage={graphDetails?.percentage * 100}
                  content={graphDetails?.description ?? null}
                  tooltip={
                    (graphDetails?.tooltip as AppTooltipFragment) ?? null
                  }
                />
              </Flex>
            </Card>
          </StyledDetailsCardContainer>
        </Skeleton>
      ) : null}
    </SkeletonProvider>
  );
};
