import { Box, Button, Flex, HXXS, PL } from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import React from 'react';
import {
  type SubmitHandler,
  type UseFormReturn,
  useFormContext,
} from 'react-hook-form';

import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';
import { ControlledInput } from '~/components/form/ControlledInput';
import { Checkbox } from '~/toolbox/checkbox';

import type { ProfileDisclosuresInput } from './InvestorDisclosuresProfileForm.types';

type InvestorDisclosuresFormProps = {
  accountType: 'primary' | 'secondary';
  showLabel?: boolean;
  onSubmit: SubmitHandler<ProfileDisclosuresInput>;
  formMethods: UseFormReturn<ProfileDisclosuresInput>;
  disabled?: boolean;
  hideSubmit?: boolean | null | undefined;
};

export const InvestorDisclosuresForm = ({
  accountType,
  onSubmit,
  formMethods,
  showLabel = true,
}: InvestorDisclosuresFormProps) => {
  const { control, watch } = useFormContext<ProfileDisclosuresInput>();
  const [noDisclosures, setNoDisclosures] = React.useState(false);
  const values = watch();

  // If values have not been selected, they default to unchecked.
  const formValues = values[accountType];
  const isSubjectToBackupWithholding =
    formValues?.backupWithholding?.isSubjectToBackupWithholding ?? false;
  const isExchangeOrFinraAffiliated =
    formValues?.exchangeOrFinraAffiliationDisclosure?.isAffiliated ?? false;
  const isControlPerson =
    formValues?.controlPersonDisclosure?.isControlPerson ?? false;
  const isPoliticallyExposed =
    formValues?.politicalExposureDisclosure?.isPoliticallyExposed ?? false;

  React.useEffect(() => {
    setNoDisclosures(
      !(
        isExchangeOrFinraAffiliated ||
        isControlPerson ||
        isPoliticallyExposed ||
        isSubjectToBackupWithholding
      ),
    );
    // Clear out fields tied to any disclosures if it's unchecked
    if (!isExchangeOrFinraAffiliated) {
      formMethods.setValue(
        `${accountType}.exchangeOrFinraAffiliationDisclosure.firmName`,
        null,
      );
    }
    if (!isControlPerson) {
      formMethods.setValue(
        `${accountType}.controlPersonDisclosure.companySymbols`,
        null,
      );
    }
    if (!isPoliticallyExposed) {
      formMethods.setValue(
        `${accountType}.politicalExposureDisclosure.politicalOrganization`,
        null,
      );
      formMethods.setValue(
        `${accountType}.politicalExposureDisclosure.immediateFamilyMembers`,
        null,
      );
    }
    if (!isSubjectToBackupWithholding) {
      formMethods.setValue(
        `${accountType}.backupWithholding.isSubjectToBackupWithholding`,
        false,
      );
    }
  }, [
    isExchangeOrFinraAffiliated,
    isControlPerson,
    isPoliticallyExposed,
    isSubjectToBackupWithholding,
    formMethods,
    accountType,
  ]);

  React.useEffect(() => {
    // If `noDisclosures` is selected, clear the form and set all to false.
    if (noDisclosures) {
      formMethods.setValue(accountType, {
        backupWithholding: {
          isSubjectToBackupWithholding: false,
        },
        controlPersonDisclosure: {
          isControlPerson: false,
          companySymbols: null,
        },
        exchangeOrFinraAffiliationDisclosure: {
          isAffiliated: false,
          firmName: null,
        },
        politicalExposureDisclosure: {
          isPoliticallyExposed: false,
          politicalOrganization: null,
          immediateFamilyMembers: null,
        },
      });
    }
  }, [noDisclosures, formMethods, accountType]);

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Flex flexDirection="column" gap={24}>
        {showLabel && (
          <HXXS
            fontWeight={300}
          >{`${capitalize(accountType)} account holder`}</HXXS>
        )}
        <Box>
          <ControlledCheckbox
            name={`${accountType}.exchangeOrFinraAffiliationDisclosure.isAffiliated`}
            label="I am affiliated with or work for a broker/dealer"
            control={control}
          />
          {isExchangeOrFinraAffiliated && (
            <ControlledInput
              name={`${accountType}.exchangeOrFinraAffiliationDisclosure.firmName`}
              label="Firm Name"
              placeholder="M1 Finance, etc."
              control={control}
            />
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name={`${accountType}.controlPersonDisclosure.isControlPerson`}
            label="I am a 10% shareholder or director of a publicly traded company"
            control={control}
          />
          {isControlPerson && (
            <ControlledInput
              name={`${accountType}.controlPersonDisclosure.companySymbols`}
              label="Symbols"
              placeholder="GOOG, AMZN, META"
              control={control}
              valueIsArray
            />
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name={`${accountType}.politicalExposureDisclosure.isPoliticallyExposed`}
            label="I am or an immediate family member is a current or former Public Official"
            control={control}
          />
          {isPoliticallyExposed && (
            <>
              <Box mb={8}>
                <ControlledInput
                  name={`${accountType}.politicalExposureDisclosure.politicalOrganization`}
                  label="Affiliated public entities"
                  placeholder="e.g. Director of the Port Authority"
                  control={control}
                />
              </Box>
              <ControlledInput
                name={`${accountType}.politicalExposureDisclosure.immediateFamilyMembers`}
                label="Names of immediate family members"
                valueIsArray
                placeholder="e.g. George Washington, President of USA"
                control={control}
              />
            </>
          )}
        </Box>
        <ControlledCheckbox
          name={`${accountType}.backupWithholding.isSubjectToBackupWithholding`}
          label="I am subject to backup withholding by the IRS."
          control={control}
        />
        <Checkbox
          label={<PL fontWeight={600}>None of these describes me.</PL>}
          checked={noDisclosures}
          // Always set to true on click. If it was already checked,
          // do nothing. If not already checked, the other checkboxes
          // will be cleared in the effect above.
          onClick={() => setNoDisclosures(true)}
        />
        <Button type="submit" size="medium">
          Save
        </Button>
      </Flex>
    </form>
  );
};
