import { Button, PL, PXL, PM, Card } from '@m1/liquid-react';
import * as React from 'react';

import type { AppState } from '~/redux';
import { clickedResendVerificationEmail } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import type { OnboardingFlowState } from '~/redux/reducers/newFlows/reducers/onboardingReducer';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';

type EmailVerificationCardProps = {
  notificationLocation: keyof typeof NOTIFICATION_LOCATIONS;
};

export const EmailVerificationCard = ({
  notificationLocation,
}: EmailVerificationCardProps) => {
  const dispatch = useDispatch();

  const { product } = useSelector<OnboardingFlowState>(
    (state: AppState) => state.newFlows.onboarding,
  );

  const emailContent =
    product.productIdentifier === 'BORROW_PERSONAL_LOANS'
      ? "You can't accept a loan offer until your email is verified. Please check your inbox."
      : "Before you can fund any account, you'll need to confirm your email. Check your inbox to verify.";

  return (
    <Card
      backgroundColor="backgroundWarningSubtle"
      padding={16}
      mb={48}
      borderColor="borderWarning"
    >
      <PXL
        content="You also need to verify your email."
        mb={4}
        fontWeight={600}
      />
      <PL color="foregroundNeutralSecondary" content={emailContent} mb={4} />
      <PM>
        <Button
          label="Resend email"
          kind="destructive-text"
          style={{
            display: 'inline-flex',
            height: 'auto',
            minHeight: '0px',
            padding: 0,
          }}
          onClick={() =>
            dispatch(
              clickedResendVerificationEmail({
                errorNotificationLocation: notificationLocation,
                successNotificationLocation: notificationLocation,
              }),
            )
          }
        />
      </PM>
    </Card>
  );
};
