import { call } from 'redux-saga/effects';

import {
  AccountHasValueSagaDocument,
  type AccountHasValueSagaQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../../apolloQuerySaga';

import { getLoggers } from '../../common';

type DepositLogArgs = {
  accountId: string;
  achRelationshipId: string;
  amount: number;
  fundingType: string;
  isSchedule: boolean;
};

export function* logDepositFundingEvents({
  accountId,
  amount,
  isSchedule,
  fundingType,
}: DepositLogArgs): Generator<any, any, any> {
  const { analytics } = yield call(getLoggers);
  const { data }: AccountHasValueSagaQueryResult = yield call(apolloQuerySaga, {
    query: AccountHasValueSagaDocument,
    variables: {
      accountId,
    },
  });

  const account = data?.node?.__typename === 'Account' ? data.node : null;

  const hasValue =
    account?.balance.totalValue && account.balance.totalValue.hasValue;
  if (!isSchedule && !hasValue) {
    analytics.event(
      'funding',
      'm1_initial_funding_deposit_created',
      'user_action',
      {
        amount,
        funding_method: fundingType,
      },
    );
  }
}
