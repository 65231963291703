import { Box, Flex, Card } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import type { Location } from 'react-router-dom';

import { ConnectedPie } from '~/components/ConnectedPie';
import { ConfirmationDialogContainer } from '~/components/pie/ConfirmationDialog/ConfirmationDialogContainer';
import { useGetUserKeysQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import {
  type Pie as PieType,
  readPieTreeByPath,
  type Slice,
} from '~/pie-trees';
import {
  confirmedPieOrganizerExit,
  hideLoadingSpinner,
  savePieOrganizerAction,
  showLoadingSpinner,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { BackArrow } from '../BackArrow';

import { BulkActionsDialogMessage } from './BulkActionsDialogMessage';
import { DragAndDropDialogMessage } from './DragAndDropDialogMessage';
import { PortfolioNameAndDescriptionForm } from './Forms';
import { HelperDialog } from './HelperDialog/HelperDialog';
import { HelperDialogIntroModal } from './HelperDialog/HelperDialogIntroModal';
import { MultiHelperDialog } from './HelperDialog/MultiHelperDialog';
import { PieControls } from './PieControls';
import { PieOrganizerCreatePieFormModal } from './PieOrganizerCreatePieFormModal';
import { PieOrganizerHeader } from './PieOrganizerHeader';
import { InactiveSlicesBanner } from './PieOrganizerHeader/InactiveSlicesBanner';
import {
  noSlicesDialogMessage,
  SEQUENCED_DIALOGS_WITHOUT_SLICES,
  SEQUENCED_DIALOGS_WITH_SLICES,
  setTargetWeightDialogMessage,
  slicesDialogMessage,
} from './PortfolioOrganizer.constants';
import { PortfolioOrganizerPathBreadcrumbs } from './PortfolioOrganizerPathBreadcrumbs';
import { PortfolioOrganizerSliceEditButtons } from './PortfolioOrganizerSliceEditButtons';
import { SliceablesList } from './SliceablesList';
import { SliceListAllocationFeedbackCard } from './SliceListAllocationFeedbackCard';
import { SliceListNoSlicesCard } from './SliceListNoSlicesCard';

export type PortfolioOrganizerComponentProps = {
  isCrypto: boolean;
};

export const PortfolioOrganizer = ({
  isCrypto,
}: PortfolioOrganizerComponentProps) => {
  const navigate = useNavigate();
  const { data, loading } = useGetUserKeysQuery();

  const user = data?.viewer.user;

  const dispatch = useDispatch();

  const isLessThan1260: boolean = useMediaQuery({
    query: '(max-width: 1260px)',
  });

  const isLessThan1000: boolean = useMediaQuery({
    query: '(max-width: 1000px)',
  });

  // State
  const [activeDialogPage, setActiveDialogPage] = React.useState(1);
  const [showCreatePieForm, setShowCreatePieForm] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  // Selectors
  const {
    activeAccountId,
    showApplicableLocationsOnConfirm,
    showHelperDialogs,
  } = useSelector<{
    activeAccountId: string | null | undefined;
    showApplicableLocationsOnConfirm: boolean;
    showHelperDialogs: boolean;
  }>((state) => {
    const { confirmationDialog, session, showHelperDialogs } =
      state.portfolioOrganizer;

    return {
      activeAccountId: state.global.activeAccountId,
      showHelperDialogs: showHelperDialogs && session.mode === 'NEW_ROOT_PIE',
      showApplicableLocationsOnConfirm: Boolean(
        confirmationDialog.showApplicableLocations,
      ),
    };
  });

  const isLoading = useSelector((state) => {
    const sessionState = state.portfolioOrganizer.session.state;
    return Boolean(
      sessionState === 'INITIALIZING' ||
        sessionState === 'FETCHING' ||
        sessionState === 'SAVING',
    );
  });

  const pie = useSelector<PieType>((state) => {
    return readPieTreeByPath(
      state.portfolioOrganizer.pieTree,
      state.portfolioOrganizer.path,
    );
  });

  const pieHasSlices = Boolean(pie.slices && pie.slices.length);
  const hasPieSlices = Boolean(
    pie.slices?.find(
      (slice: Slice) =>
        slice.to.type === 'new_pie' || slice.to.type === 'old_pie',
    ),
  );

  // If we don't have slices don't show the drag and drop helper or the set targets helper
  const SEQUENCED_DIALOGS = pieHasSlices
    ? SEQUENCED_DIALOGS_WITH_SLICES
    : SEQUENCED_DIALOGS_WITHOUT_SLICES;

  const maxDialogPage = Object.keys(SEQUENCED_DIALOGS).filter(
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ PORTFOLIO_NAME_AND_DESCRIPTION: number; SLICES: number; CREATE_PIE: number; SEARCH_AND_ADD: number; BULK_ACTIONS: number; SET_TARGETS: null; DRAG_AND_DROP: null; } | { PORTFOLIO_NAME_AND_DESCRIPTION: number; ... 5 more ...; BULK_ACTIONS: number; }'.
    (key) => SEQUENCED_DIALOGS[key] !== null,
  ).length;

  // Callbacks
  const incrementDialogPage = React.useCallback(() => {
    if (activeDialogPage < maxDialogPage) {
      setActiveDialogPage(activeDialogPage + 1);
    }
  }, [activeDialogPage, maxDialogPage]);

  const decrementDialogPage = React.useCallback(() => {
    if (activeDialogPage > 1) {
      setActiveDialogPage(activeDialogPage - 1);
    }
  }, [activeDialogPage]);

  const resetDialogPageCallback = React.useCallback(() => {
    setActiveDialogPage(1);
  }, [setActiveDialogPage]);

  // <Pie /> requires data in a particular format.
  const pieData = React.useMemo(() => {
    if (pie.slices) {
      return pie.slices.map((slice) => ({
        id: slice.to.__id,
        percentage: slice.percentage,
      }));
    }
  }, [pie.slices]);

  // Effects
  React.useEffect(() => {
    if (isLoading) {
      dispatch(showLoadingSpinner());
    } else {
      dispatch(hideLoadingSpinner());
    }

    return () => {
      dispatch(hideLoadingSpinner());
    };
  }, [dispatch, isLoading]);

  const portfolioOrganizerIntroHelperDate = user?.data.find(
    (flag) => flag.key === 'web-portfolioOrganizerIntroHelperDate',
  );

  const isRootPie = useSelector<boolean>(
    (state) => state.portfolioOrganizer.path.length === 0,
  );
  const backButton = useSelector<
    Maybe<{
      label: string;
      state: Location['state'];
      to: string;
    }>
  >((state) => state.portfolioOrganizer.buttons?.backButton);

  const helperDialogFlag = Boolean(portfolioOrganizerIntroHelperDate?.value);

  const dialogSequenceConfig = {
    activeDialogPage,
    decrementDialogPage,
    incrementDialogPage,
    maxDialogPage,
    SEQUENCED_DIALOGS,
  };

  if (loading) {
    return (
      <Box p={20}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Box width="100%">
      <PieOrganizerHeader
        resetDialogPageCallback={resetDialogPageCallback}
        // @ts-expect-error - TS2322 - Type 'Dispatch<SetStateAction<boolean>>' is not assignable to type '() => void'.
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        isCrypto={isCrypto}
      />

      <Flex
        flexDirection="column"
        backgroundColor="backgroundNeutralMain"
        minHeight="100vh"
        p={isLessThan1000 ? 32 : 0}
        style={{
          overflow: 'hidden',
        }}
        width="100%"
      >
        <Flex maxWidth={1200} mx="auto" width="100%">
          <InactiveSlicesBanner />
          {backButton && (
            <Flex mt={16} width="100%">
              <BackArrow
                content={backButton.label}
                onClick={() => {
                  dispatch(confirmedPieOrganizerExit());
                }}
                state={backButton.state ?? undefined}
                to={backButton.to}
              />
            </Flex>
          )}
          {!isRootPie && !backButton && <PortfolioOrganizerPathBreadcrumbs />}
        </Flex>

        <Flex
          flexDirection={isLessThan1000 ? 'column' : 'row'}
          justifyContent="space-between"
          maxWidth={1200}
          mx="auto"
          pt={32}
          width="100%"
        >
          <Flex
            flex="0 0 380px"
            flexDirection="column"
            mr={isLessThan1000 ? 0 : 32}
            width="100%"
          >
            <PortfolioNameAndDescriptionForm
              dialogSequenceConfig={dialogSequenceConfig}
            />

            <Card backgroundColor="backgroundNeutralSecondary" mt={24}>
              {pieData && (
                <Flex justifyContent="center">
                  <ConnectedPie
                    data={pieData}
                    height={380}
                    innerRadius={100}
                    width={330}
                  />
                </Flex>
              )}
            </Card>
          </Flex>
          <Flex
            flex="1"
            flexDirection="column"
            ml={isLessThan1000 ? 0 : 32}
            pt={isLessThan1000 ? 32 : 0}
            width="100%"
          >
            {showCreatePieForm && (
              <PieOrganizerCreatePieFormModal
                onCancel={() => setShowCreatePieForm(false)}
                onConfirm={() => setShowCreatePieForm(false)}
                open={showCreatePieForm}
              />
            )}

            <Flex
              alignItems={
                isLessThan1260 && !isLessThan1000 ? 'flex-start' : 'center'
              }
              flexDirection={
                isLessThan1260 && !isLessThan1000 ? 'column' : 'row'
              }
              justifyContent="space-between"
              pb={24}
              width="100%"
            >
              <PieControls
                dialogSequenceConfig={dialogSequenceConfig}
                onCreatePieClick={() => setShowCreatePieForm(true)}
                onSearchAddClick={() =>
                  navigate({
                    to: isCrypto ? '/d/c/add-slices/crypto' : '/d/c/add-slices',
                  })
                }
                pieHasSlices={pieHasSlices}
              />

              <Flex
                flexDirection="column"
                height="100%"
                justifyContent="flex-end"
              >
                <HelperDialog
                  alignment="end"
                  content={<BulkActionsDialogMessage />}
                  direction="up"
                  height={240}
                  isVisible={
                    showHelperDialogs &&
                    SEQUENCED_DIALOGS.BULK_ACTIONS === activeDialogPage
                  }
                  kind="normal"
                  pageNumber={SEQUENCED_DIALOGS.BULK_ACTIONS}
                  sequence={dialogSequenceConfig}
                  title="Bulk Actions"
                >
                  <PortfolioOrganizerSliceEditButtons
                    setShowCreatePieFormCallback={setShowCreatePieForm}
                  />
                </HelperDialog>
              </Flex>
            </Flex>

            {pie.slices && pie.slices.length ? (
              <>
                <SliceListAllocationFeedbackCard mb={24} slices={pie.slices} />
                {SEQUENCED_DIALOGS.DRAG_AND_DROP === activeDialogPage ? (
                  <HelperDialog
                    alignment="start"
                    content={DragAndDropDialogMessage()}
                    direction="right-reverse"
                    height={205}
                    isVisible={
                      showHelperDialogs &&
                      SEQUENCED_DIALOGS.DRAG_AND_DROP === activeDialogPage
                    }
                    kind="normal"
                    margin="45px 25px 0 0"
                    pageNumber={SEQUENCED_DIALOGS.DRAG_AND_DROP}
                    sequence={dialogSequenceConfig}
                    title="Drag and Drop"
                  >
                    <SliceablesList pie={pie} />
                  </HelperDialog>
                ) : (
                  <MultiHelperDialog
                    dialogConfigs={[
                      {
                        alignment: 'center',
                        content: slicesDialogMessage,
                        direction: 'up-reverse',
                        height: 200,
                        isVisible:
                          showHelperDialogs &&
                          SEQUENCED_DIALOGS.SLICES === activeDialogPage,
                        kind: 'normal',
                        margin: '100px 0 0 0',
                        pageNumber: SEQUENCED_DIALOGS.SLICES,
                        sequence: dialogSequenceConfig,
                        title: 'Slices',
                      },
                      {
                        alignment: 'end',
                        content: setTargetWeightDialogMessage,
                        direction: 'up-reverse',
                        height: 200,
                        isVisible:
                          showHelperDialogs &&
                          SEQUENCED_DIALOGS.SET_TARGETS === activeDialogPage,
                        kind: 'normal',
                        margin: `100px ${hasPieSlices ? 88 : 60}px 0 0`,
                        pageNumber: SEQUENCED_DIALOGS.SET_TARGETS,
                        sequence: dialogSequenceConfig,
                        title: 'Set Target Weight',
                      },
                    ]}
                  >
                    <SliceablesList pie={pie} />
                  </MultiHelperDialog>
                )}
              </>
            ) : (
              <Flex alignItems="center">
                <HelperDialog
                  alignment="center"
                  content={noSlicesDialogMessage}
                  direction={isLessThan1260 ? 'down' : 'up'}
                  height={215}
                  isVisible={
                    showHelperDialogs &&
                    SEQUENCED_DIALOGS.SLICES === activeDialogPage
                  }
                  kind="full-width"
                  pageNumber={SEQUENCED_DIALOGS.SLICES}
                  sequence={dialogSequenceConfig}
                  title="Slices"
                >
                  <SliceListNoSlicesCard />
                </HelperDialog>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <HelperDialogIntroModal
        isVisible={showHelperDialogs && !helperDialogFlag}
      />
      {confirmationDialogOpen && (
        <ConfirmationDialogContainer
          active={confirmationDialogOpen}
          onCancel={() => setConfirmationDialogOpen(false)}
          onConfirm={() => {
            setConfirmationDialogOpen(false);
            dispatch(savePieOrganizerAction(activeAccountId));
          }}
          isCrypto={isCrypto}
          showApplicableLocations={showApplicableLocationsOnConfirm}
        />
      )}
    </Box>
  );
};
