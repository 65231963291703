import { PS } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

import type { SelectProps } from './Dropdown.types';

type GroupHeadingProps = {
  children?: React.ReactNode;
  className: string;
  selectProps: SelectProps;
};

export const GroupHeading = ({ children, ...rest }: GroupHeadingProps) => (
  <components.GroupHeading {...rest}>
    <PS content={children} />
  </components.GroupHeading>
);
