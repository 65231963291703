import type { ApolloQueryResult } from '@apollo/client';
import { Box, Flex, HXXS, ListItem, PM, PL, PXL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';
import {
  type PlatformBillingSettingsPageQuery,
  type PlatformBillingSettingsPageQueryVariables,
  type PlatformBillingHistorySectionFragment,
  type PlatformBillingEntryFragment,
} from '~/graphql/types';
import { useApolloPrevNext } from '~/hooks/useApolloPrevNext';
import { AppImage } from '~/lens-toolbox/AppImage';
import { RichText } from '~/lens-toolbox/RichText';

type SettingsPlatformBillingHistorySectionProps = {
  historySection: PlatformBillingHistorySectionFragment;
  refetch: (
    variables?: Partial<PlatformBillingSettingsPageQueryVariables>,
  ) => Promise<ApolloQueryResult<PlatformBillingSettingsPageQuery>>;
};

export const SettingsPlatformBillingHistorySection = (
  props: SettingsPlatformBillingHistorySectionProps,
) => {
  const pageInfo = props.historySection?.billingHistory?.pageInfo;
  const renderErrorCopy = props.historySection === null;

  const { handleNextPage, handlePreviousPage, hasPreviousPage } =
    useApolloPrevNext({ refetch: props.refetch });

  if (renderErrorCopy) {
    return (
      <Flex mt={40} flexDirection="column">
        <Flex>
          <Icon name="bills24" />
          <PXL fontWeight={500} content="Billing history" />
        </Flex>
        <PL mt={8}>
          Something went wrong and we couldn't load your billing history.
        </PL>
      </Flex>
    );
  }

  const filteredTransactions =
    props.historySection?.billingHistory?.edges?.reduce((acc, current) => {
      const node = current?.node as
        | PlatformBillingEntryFragment
        | null
        | undefined;
      if (node !== null && node !== undefined) {
        acc.push(node);
      }
      return acc;
    }, [] as Array<PlatformBillingEntryFragment>) ?? [];

  return (
    <>
      <Flex mt={40}>
        {props.historySection.titleIcon && (
          <AppImage appImage={props.historySection.titleIcon} mr={8} />
        )}
        <PXL content={props.historySection?.title} />
      </Flex>
      <Flex justifyContent="space-between" my={12}>
        <Flex alignItems="end">
          <HXXS fontWeight={300}>Recent transfers</HXXS>
        </Flex>

        {filteredTransactions.length > 0 && (
          <PaginationButtonGroup
            handleNextPage={() => handleNextPage(pageInfo?.endCursor ?? null)}
            handlePreviousPage={handlePreviousPage}
            hasNextPage={pageInfo?.hasNextPage ?? false}
            hasPreviousPage={hasPreviousPage}
          />
        )}
      </Flex>
      {filteredTransactions.length > 0 ? (
        filteredTransactions.map((transaction) => (
          <Box key={transaction.id}>
            <ListItem
              description={
                transaction.listItem?.descriptionV2
                  ? transaction.listItem.descriptionV2.map(
                      (description, index) => (
                        <RichText richText={description} key={index} />
                      ),
                    )
                  : '-'
              }
              header={transaction.listItem?.subheader || '-'}
              headerRight={transaction.listItem?.headerRight}
              size="MEDIUM"
              py={12}
            />
          </Box>
        ))
      ) : (
        <PM
          content={props.historySection?.billingHistoryFallbackCopy}
          mt={32}
        />
      )}
    </>
  );
};
