import { Box, Button, Flex, HM } from '@m1/liquid-react';
import * as React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledInput } from '~/components/form/ControlledInput';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export interface AnnualIncomeFormValues {
  annualIncome: string;
}

export const AnnualIncome = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountAnnualIncome) {
      return {
        annualIncome: undefined,
      };
    }
    return state.reactHookForm.openJointInvestAccountAnnualIncome;
  });

  const wiz = useOpenInvestJointAccountWizardContext();

  const { control, setValue, handleSubmit } = useForm<AnnualIncomeFormValues>({
    defaultValues: submittedFormValues,
  });

  const onSubmit: SubmitHandler<AnnualIncomeFormValues> = (data) => {
    dispatch(submitReactFormData({ openJointInvestAccountAnnualIncome: data }));

    wiz.next();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={48} mb={32}>
        How much do you make before taxes each year?
      </HM>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledInput
          name="annualIncome"
          rules={{ required: true }}
          control={control}
          label="Yearly income"
          maskType="money"
          backgroundColor="backgroundNeutralMain"
          maxLength={11}
        />
        <Button
          type="submit"
          kind="primary"
          size="large"
          label="Continue"
          mt={32}
        />
        <FormMockerReactHookForm
          fields={[{ name: 'annualIncome', value: '$150,000' }]}
          setValue={setValue}
        />
      </form>
    </Flex>
  );
};
