import * as React from 'react';

import { Pill, type PillKind, type PillProps } from '~/toolbox/Pill';

type ValidPillProps = Omit<PillProps, 'kind' | 'label' | 'size'>;

export type AnnouncementTagProps = ValidPillProps & {
  tag: 'ALERT' | 'NEW' | 'PLUS';
};

const TAG_COPY_MAPPING: Record<AnnouncementTagProps['tag'], string> = {
  ALERT: 'Important',
  NEW: "What's New",
  PLUS: 'M1 Plus',
};

const TAG_TO_PILL_KIND_MAPPING: Record<AnnouncementTagProps['tag'], PillKind> =
  {
    ALERT: 'important',
    NEW: 'new',
    PLUS: 'neutral',
  };

export const AnnouncementTag = ({ tag, ...rest }: AnnouncementTagProps) => (
  <Pill
    {...rest}
    kind={TAG_TO_PILL_KIND_MAPPING[tag]}
    label={TAG_COPY_MAPPING[tag]}
    size="announcement"
  />
);
