/* eslint-disable no-param-reassign */

import { PL, Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { IdentityInfoFormModern } from '~/forms';
import { submittedIdentityInfoFormModern } from '~/redux/actions';
import type {
  IdentityInfoForm as IdentityInfoFormType,
  SubmittedIdentityInfoForm,
} from '~/redux/actions/newFlows/accountSetup(legacy)/accountSetup(Legacy)Actions.types';
import { useSelector, useDispatch } from '~/redux/hooks';
import { pickByPaths } from '~/utils';

type CollectIdentityInfoStepProps = {
  body?: string;
  holder: 'primary' | 'secondary';
  title: string;
};

export const CollectIdentityInfoStep = (
  props: CollectIdentityInfoStepProps,
) => {
  const { body, holder, title } = props;
  const dispatch = useDispatch();

  const { initialValues } = useSelector((state) => {
    return prepareSavedValues(holder, state.newFlows.IDENTITY_FIRST.input);
  });

  return (
    <Box width={588} mx="auto">
      <Box mb={16}>
        <HM content={title} py={32} />
        {body && <PL content={body} mt={32} />}
      </Box>
      <IdentityInfoFormModern
        initialValues={initialValues}
        onSubmit={(formValues: IdentityInfoFormType) => {
          dispatch(
            submittedIdentityInfoFormModern(
              prepareFormValues(holder, formValues),
            ),
          );
        }}
      />
    </Box>
  );
};

const prepareSavedValues = (
  holder: 'primary' | 'secondary',
  savedValues: Record<string, any>,
): Record<string, any> => {
  const initialValues = pickByPaths(savedValues, makePaths(holder));
  return {
    initialValues,
  };
};

const prepareFormValues = (
  holder: 'primary' | 'secondary',
  formValues: IdentityInfoFormType,
): SubmittedIdentityInfoForm['payload'] => {
  const employment = formValues?.employment;
  const values = pickByPaths(
    formValues,
    makePaths(holder).map((pair) => pair.reverse() as [string, string]),
  );

  if (
    employment &&
    employment.status !== 'EMPLOYED' &&
    employment.status !== 'SELF_EMPLOYED'
  ) {
    values[holder]['employment']['employer'] = null;
    values[holder]['employment']['occupation'] = null;
  }

  return {
    holder,
    values,
  };
};

function makePaths(holder: 'primary' | 'secondary'): Array<[string, string]> {
  return [
    ['dateOfBirth', `${holder}.dateOfBirth`],
    ['countryOfCitizenship', `${holder}.countryOfCitizenship`],
    ['employment', `${holder}.employment`],
  ];
}
