import { Flex, LM, PM, PXL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { DottedArrow } from '~/components/DottedArrow';
import { SecurityLogo } from '~/components/SecurityLogo';
import type { DestinationPieChangesSectionFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';
import { RichText } from '~/lens-toolbox/RichText';

type DestinationPieChangesSectionProps = {
  destinationChanges: DestinationPieChangesSectionFragment;
};

export const DestinationPieChangesSection = ({
  destinationChanges,
}: DestinationPieChangesSectionProps) => {
  const { adjustedSlices, applicableLocationsMessage, label } =
    destinationChanges;

  if (!adjustedSlices?.length) {
    return null;
  }

  const slices = adjustedSlices.filter(Boolean);

  if (!slices.length) {
    return null;
  }

  return (
    <Flex flexDirection="column" mb={32}>
      <PXL fontWeight={600} color="foregroundNeutralSecondary" mb={8}>
        {label}
      </PXL>
      {applicableLocationsMessage && (
        <PM color="foregroundNeutralSecondary" mb={8}>
          {applicableLocationsMessage}
        </PM>
      )}
      <Flex
        borderColor="borderMain"
        borderStyle="solid"
        borderRadius={8}
        borderWidth={1}
        flexDirection="column"
      >
        {slices.map((slice, index) => {
          const isLastItem = index === adjustedSlices.length - 1;
          const {
            actionPill,
            logoUrl,
            name,
            percentage,
            oldPercentage,
            symbol,
          } = slice ?? {};
          const logo =
            // @ts-expect-error - TS2531 - Object is possibly 'null'.
            slice.type === 'USER_PIE' ? <Icon name="createPie32" /> : logoUrl;

          return (
            <Flex
              alignItems="center"
              borderColor="borderMain"
              borderStyle="solid"
              borderWidth={isLastItem ? '0' : '0 0 1px 0'}
              key={index}
              px={16}
              py={8}
            >
              <Flex width="70%">
                {logo && <SecurityLogo content={logo} />}
                <Flex flexDirection="column" justifyContent="center">
                  <PM fontWeight={600} color="foregroundNeutralSecondary">
                    {symbol}
                  </PM>
                  <LM>{name}</LM>
                </Flex>
              </Flex>
              <Flex width="30%" justifyContent="flex-end">
                <Flex alignItems="end" flexDirection="column">
                  {actionPill && <AppPill appPill={actionPill} my={4} />}
                  {typeof percentage === 'number' &&
                    typeof oldPercentage === 'number' && (
                      <Flex alignItems="center">
                        <PM mr={8}>{`${oldPercentage}%`}</PM>
                        <DottedArrow />
                        <PM ml={8}>{`${percentage}%`}</PM>
                      </Flex>
                    )}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      {destinationChanges.messages?.map((message, index) => {
        return (
          <RichText
            key={index}
            richText={message}
            textProps={{
              color: 'foregroundNeutralSecondary',
            }}
            linkProps={{
              font: 'PS',
              fontWeight: 600,
              display: 'inline !important',
            }}
            containerProps={{
              display: 'inline-block',
              paddingY: '4px',
            }}
          />
        );
      })}
    </Flex>
  );
};
