import * as React from 'react';
import {
  type ControllerProps,
  type FieldValues,
  type Path,
  useController,
} from 'react-hook-form';

import { Radio } from '~/toolbox/radio';
import type { RadioGroupProps } from '~/toolbox/radio/group';

export type RadioGroupFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<RadioGroupProps, 'value'>;

export const ControlledRadioGroup = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  defaultValue,
  name,
  rules,
  onChange,
  ...forwardedProps
}: RadioGroupFieldProps<TName, TFieldValues>) => {
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (value: any) => {
    field.onChange(value);
    onChange?.(value);
  };

  return <Radio.Group {...field} {...forwardedProps} onChange={handleChange} />;
};
