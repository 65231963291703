import { type TextProps, Text, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export type HelpTriggerKind = 'default' | 'inverse' | 'light';

export type HelpTriggerProps = {
  kind?: HelpTriggerKind;
  label: React.ReactNode;
  labelFont?: TextProps['font'];
};

export const HelpTrigger = ({
  kind,
  label,
  labelFont = 'PS',
  ...rest
}: HelpTriggerProps) => {
  const color =
    kind === 'inverse' || kind === 'light' ? 'primaryTint' : 'primary';
  const backgroundColor = 'inherit';
  return (
    <Flex alignItems="center" {...rest}>
      {typeof label === 'string' ? (
        <Text
          font={labelFont}
          content={label}
          color="foregroundNeutralSecondary"
        />
      ) : (
        label
      )}
      <Icon
        name="tooltip20"
        color={color}
        ml={8}
        style={{
          backgroundColor: `${backgroundColor}`,
        }}
      />
    </Flex>
  );
};
