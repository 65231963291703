import type { SagaIterator } from 'redux-saga';
import { all, call, select, takeLatest } from 'redux-saga/effects';

import type { NavigateFunction } from '~/hooks/useNavigate';
import {
  ACTION_TYPES as ACTIONS,
  type FinishedAddSharedPieAction,
} from '~/redux/actions';

import { hasUserOnboarded } from '../common';

export function* shareMonitor(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.FINISHED_ADD_SHARED_PIE,
    function* ({ payload }: FinishedAddSharedPieAction): SagaIterator<void> {
      const { pieId, didAttemptPieCreation } = payload;
      const [userHasOnboarded] = yield all([call(hasUserOnboarded)]);

      const navigate: NavigateFunction = yield select(
        (state) => state.routing.navigate,
      );

      if (userHasOnboarded) {
        if (didAttemptPieCreation) {
          yield call(navigate, { to: `/d/research/my-pies/details/${pieId}` });
        } else {
          yield call(navigate, { to: '/d/research/my-pies' });
        }
      } else {
        yield call(navigate, { to: '/onboarding/phone-verification' });
      }
    },
  );
}
