import * as React from 'react';

import { useSelector } from '~/redux/hooks';
import { type BubbleHelperProps, BubbleHelper } from '~/toolbox/helper';

type SmartTransferBubbleProps = BubbleHelperProps & {
  addDestination?: boolean;
  isOverBalance?: boolean;
  isUnderBalance?: boolean;
};

export const SmartTransferBubble = ({
  activeStepNumber,
  description,
  handleGoBackward,
  handleGoForward,
  isOverBalance = false,
  addDestination,
  isUnderBalance = false,
  maxWidth,
  stepNumber,
  title,
  totalSteps,
}: SmartTransferBubbleProps) => {
  const isHelperOn = useSelector<boolean>(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.isHelperOn,
  );
  const [show, setShow] = React.useState(false);
  const [bubbleDescription, setBubbleDescription] = React.useState(description);
  const [bubbleTitle, setBubbleTitle] = React.useState(title);

  React.useEffect(() => {
    if (isHelperOn) {
      setShow(true);
    }
    return () => setShow(false);
  }, [isHelperOn]);

  // Handles title and description for smart transfer scenarios
  React.useEffect(() => {
    switch (isOverBalance || isUnderBalance) {
      case isOverBalance && addDestination:
        setBubbleTitle('Add destination');
        setBubbleDescription(
          'You can add as many destinations as you have eligible M1 accounts. The order you place them in this list designates the order in which they are used. The next account in the list is used after the endpoint you set on the previous account is completed.',
        );
        break;
      case isOverBalance && activeStepNumber === 1:
        setBubbleTitle('Source and balance');
        setBubbleDescription(
          'The top displays your selected focus account and its current balance info. Here, set the balance that will trigger this Smart Transfer. Any funds exceeding this amount will transfer to another account.',
        );
        break;
      case isOverBalance && activeStepNumber === 2:
        setBubbleTitle('Transfer destination');
        setBubbleDescription(
          'Select a specific account where funds will be transferred into. After selecting your destination, you will customize by adding specific conditions. You can also add additional destinations.',
        );
        break;
      case isUnderBalance && addDestination:
        setBubbleTitle('Add source');
        setBubbleDescription(
          'You can add as many sources as you have eligible M1 accounts. The order you place them in this list designates the order in which they are used. The next account in the list is used after the endpoint you set on the previous account is completed.',
        );
        break;
      case isUnderBalance && activeStepNumber === 1:
        setBubbleTitle('Destination, balance, and refill limit');
        setBubbleDescription(
          'The top area displays your selected destination account and its current balance. Next, set a minimum balance for your destination account to trigger the Smart Transfer, and an amount you want to refill the balance to.',
        );
        break;
      case isUnderBalance && activeStepNumber === 2:
        setBubbleTitle('Transfer source');
        setBubbleDescription(
          'Select a specific account where funds will be transferred from. After selecting your source, you will customize by adding specific conditions. You can also add additional sources.',
        );
        break;
      default:
        break;
    }
  }, [isOverBalance, isUnderBalance, activeStepNumber, addDestination]);
  return (
    <BubbleHelper
      activeStepNumber={activeStepNumber}
      description={bubbleDescription}
      externalLinkDestination="FAQ_SMART_TRANSFERS"
      externalLinkLabel="View FAQs"
      handleGoForward={handleGoForward}
      handleGoBackward={handleGoBackward}
      isHelperOn={show}
      maxWidth={maxWidth}
      stepNumber={stepNumber}
      title={bubbleTitle}
      totalSteps={totalSteps}
    />
  );
};
