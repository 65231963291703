import {
  Box,
  HL,
  type SemanticColorNames,
  styled,
  theme,
  useSkeleton,
  useTheme,
} from '@m1/liquid-react';
import highchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import clamp from 'lodash-es/clamp';
import * as React from 'react';

highchartsMore(Highcharts);

const Value = styled(HL)<{ $color: string }>`
  position: absolute;
  z-index: 1;
  bottom: calc(65% - 64px);
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ $color }) => getColorOrThemeColor($color)};
`;

const SpeedometerContainer = styled(Box)`
  position: relative;
  margin-bottom: -50%;
  pointer-events: none;
`;

type SpeedometerProps = {
  axisColor?: SemanticColorNames;
  plotBands?: Array<
    Highcharts.YAxisPlotBandsOptions & { valueColor?: SemanticColorNames }
  >;
  value: Maybe<number>;
  valueColor?: SemanticColorNames;
  valueFormat?: string;
};

const getColorOrThemeColor = (color: string): string =>
  // @ts-ignore this is type safe
  color in theme.colors ? theme.colors[color] : color;

export const Speedometer = ({
  axisColor = 'foregroundNeutralMain',
  plotBands = [
    {
      from: 30,
      to: 100,
      color: theme.colors.foregroundSuccessOnFeature, // green
      thickness: 20,
    },
    {
      from: 15,
      to: 30,
      color: theme.colors.warning, // yellow
      thickness: 20,
    },
    {
      from: 0,
      to: 15,
      color: theme.colors.foregroundCriticalOnFeature, // red
      thickness: 20,
    },
  ],
  value,
  valueColor,
  valueFormat = '{y}%',
}: SpeedometerProps) => {
  const { isLoading } = useSkeleton();
  const theme = useTheme();
  const [options, setOptions] = React.useState<Highcharts.Options>({
    chart: {
      type: 'gauge',
      margin: [0, 0, 0, 0],
      backgroundColor: 'transparent',
      height: '100%',
    },
    title: {
      text: '',
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: undefined,
      size: '100%',
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      tickWidth: 0,
      minorTickInterval: undefined,
      labels: {
        enabled: false,
      },
      lineColor: theme.colors[axisColor],
      lineWidth: 2,
      offset: -32,
    },
  });

  React.useEffect(() => {
    if (!isLoading) {
      // Setting the series this way stops the value from re-animating as other props change
      // It's also how Highcharts recommends updating the series data
      const min = plotBands.reduce(
        (acc, band) => Math.min(acc, Number(band.from)),
        Infinity,
      );
      const max = plotBands.reduce(
        (acc, band) => Math.max(acc, Number(band.to)),
        -Infinity,
      );
      const colorizedPlotBands = plotBands.map(({ valueColor, ...band }) => ({
        ...band,
        color:
          typeof band.color === 'string'
            ? getColorOrThemeColor(band.color)
            : band.color,
      }));
      setOptions({
        yAxis: {
          min,
          max,
          plotBands: colorizedPlotBands,
        },
        series: [
          {
            data: [typeof value === 'number' ? clamp(value, min, max) : value],
            dataLabels: {
              enabled: false,
              format: valueFormat,
              borderWidth: 0,
              color: theme.colors.foregroundNeutralMain,
              style: {
                fontSize: '16px',
              },
            },
            // @ts-ignore this is correct for gauge
            dial: {
              radius: '100%',
              backgroundColor: theme.colors[axisColor],
              baseWidth: 2,
              baseLength: '100%',
              rearLength: -65,
            },
            pivot: {
              radius: 0,
            },
          },
        ],
      });
    }
  }, [value, plotBands, axisColor, theme.colors, valueFormat, isLoading]);

  const valueColorFromPlotBands = valueColor
    ? valueColor
    : plotBands.reduce((acc, band) => {
        if (
          typeof band.color === 'string' &&
          value &&
          band.from &&
          band.to &&
          value >= band.from &&
          value <= band.to
        ) {
          return band.color;
        }
        return acc;
      }, 'foregroundNeutralMain');

  return (
    <SpeedometerContainer>
      <Value fontWeight={300} $color={valueColorFromPlotBands}>
        {value}%
      </Value>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </SpeedometerContainer>
  );
};
