import * as React from 'react';

import type { TransferInstancesFragment } from '~/graphql/types';
import type { SegmentEventName } from '~/loggers/analytics-reporter/segment.types';
import { GridTable } from '~/toolbox/grid-table';
import { isNotNil } from '~/utils';

import type { DisplayFlags } from './TransferInstanceStatusPill';
import { TransfersCompletedTableRow } from './TransfersCompletedTableRow';

type TransferInstanceDetailsSource = '/d/transfers' | '/d/home';

export type TransfersCompletedTableProps = {
  emptyMessage: string | React.ReactNode;
  routeToNavigateToOnFinish?: TransferInstanceDetailsSource;
  rowAnalytics?: SegmentEventName;
  style?: Record<string, any>;
  transferInstanceConnection: TransferInstancesFragment | null | undefined;
  transferInstancePillDisplayFlags?: Partial<DisplayFlags>;
};

export const TransfersCompletedTable = ({
  emptyMessage,
  routeToNavigateToOnFinish,
  rowAnalytics,
  style,
  transferInstanceConnection,
  transferInstancePillDisplayFlags,
}: TransfersCompletedTableProps) => {
  const transferInstances =
    transferInstanceConnection?.edges
      ?.map((edge) => edge && edge.node)
      .filter(isNotNil) ?? [];

  return (
    <GridTable
      emptyMessage={emptyMessage}
      gridTemplateColumns="110px 325px auto auto"
      style={style}
      isNewStyle
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="Date" />
        <GridTable.HeaderCell label="From" />
        <GridTable.HeaderCell label="To" />
        <GridTable.HeaderCell justifySelf="end" label="Amount" />
      </GridTable.HeaderRow>
      {transferInstances.map((transferInstance) => (
        <TransfersCompletedTableRow
          key={`transfer-instance-${transferInstance.id}`}
          transferInstance={transferInstance}
          routeToNavigateToOnFinish={routeToNavigateToOnFinish}
          rowAnalytics={rowAnalytics}
          transferInstancePillDisplayFlags={transferInstancePillDisplayFlags}
        />
      ))}
    </GridTable>
  );
};
