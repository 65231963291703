import * as React from 'react';

import type { GetPaymentWizardPreloadQueryResult } from '~/graphql/hooks';
import type { TransferParticipantSideEnum } from '~/graphql/types';

import type { TransferModeEnum } from '../create-transfer/steps/TransferDetails.types';

export type CreatePaymentContextType = {
  completedPaymentNodeId: Maybe<string>;
  fromParticipantIdQueryParam: string | null;
  idempotencyKey: string | null | undefined;
  pivot: TransferParticipantSideEnum;
  previousRouteName: string;
  setCompletedPaymentNodeId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setMode: React.Dispatch<React.SetStateAction<TransferModeEnum>>;
  toParticipantIdQueryParam: string | null;
  mode: TransferModeEnum;
} & GetPaymentWizardPreloadQueryResult;

export const CreatePaymentContext =
  React.createContext<CreatePaymentContextType | null>(null);
