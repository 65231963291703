import { Box, Flex, HXXS, PL, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import type { CreditCardRewardsDetailsFragment } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Link } from '~/toolbox/link';

import { RewardProgramEmptyState } from './RewardsProgramEmptyState';
import { RewardsTableOverview } from './RewardsTableOverview';

export type RewardsDetailsOwnersComponentProps = {
  ownersRewards: NonNullable<
    CreditCardRewardsDetailsFragment['ownersRewardsOverview']
  >;
};

export const RewardsDetailsOwners = ({
  ownersRewards,
}: RewardsDetailsOwnersComponentProps) => {
  const { title, highlights, tooltip } = ownersRewards;

  return (
    <>
      <Flex alignItems="center">
        <HXXS mr={8} content={title} />
        {tooltip && (
          <Tooltip
            placement="bottom-start"
            icon="tooltip16"
            iconColor="primary"
            trigger="click"
            body={
              <Box width={400} p={16}>
                <PL content={tooltip.copy} />
                {tooltip.link && (
                  <LinkableLink kind="inverse" linkable={tooltip.link} />
                )}
              </Box>
            }
          />
        )}
      </Flex>

      {highlights?.map((rewardsProgram, i) => {
        if (!rewardsProgram?.programs?.length) {
          return (
            <RewardProgramEmptyState
              key={rewardsProgram?.title}
              rewardsTitle={rewardsProgram?.title}
            />
          );
        }
        const programType =
          rewardsProgram.link?.destination === 'OWNED_STOCKS'
            ? 'owned'
            : 'unowned';
        return (
          <React.Fragment key={`rewards-program-${i}`}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              m="16px 0 8px 0"
              id={`rewards-program-${i}`}
            >
              <PL content={rewardsProgram.title} fontWeight={600} />
              <Link
                params={{
                  programType,
                }}
                to="/d/c/rewards/programs/:programType"
              >
                {rewardsProgram.link?.title}
              </Link>
            </Flex>
            <RewardsTableOverview
              programType={programType}
              rewardsProgram={rewardsProgram}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
