import { Button } from '@m1/liquid-react';
import React from 'react';

import { LandingPageHeader } from '~/components/LandingPageHeader';
import { usePersonalLoanProductLandingPageHeaderQuery } from '~/graphql/hooks';
import type { PersonalLoanProductLandingPageHeaderQuery } from '~/graphql/types';

export const PersonalLoanLandingPageHeader = ({
  handleMakePayment,
  accountId,
  hasAnnouncement,
}: {
  handleMakePayment: () => void;
  accountId: string;
  hasAnnouncement: boolean;
}) => {
  const { data, loading } = usePersonalLoanProductLandingPageHeaderQuery({
    variables: {
      loanId: accountId as string,
    },
  });

  const node = data?.node as ExtractTypename<
    PersonalLoanProductLandingPageHeaderQuery['node'],
    'PersonalLoan'
  >;
  const title = node?.personalLoanProductLandingPage?.title || '';
  const accountDetails =
    node?.personalLoanProductLandingPage?.accountDetails || [];
  const header = node?.personalLoanProductLandingPage?.header;
  const isActiveLoan = node?.isActive;

  const ctaComponent = (
    <Button
      disabled={!isActiveLoan}
      marginLeft="auto"
      kind="primary"
      label="Make Payment"
      onClick={handleMakePayment}
      size="medium"
    />
  );

  return (
    <LandingPageHeader
      loading={loading}
      title={title}
      header={header}
      accountDetails={accountDetails}
      ctaComponent={ctaComponent}
      hasAnnouncement={hasAnnouncement}
    />
  );
};
