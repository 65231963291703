import { HXXS, PM } from '@m1/liquid-react';
import * as React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { useWizardContext } from '~/flows/wizard';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { requireNonNil } from '~/forms/validators';
import {
  useLoanInformationQuery,
  usePersonalLoansDirectQuery,
} from '~/graphql/hooks';
import type { LoanPurposeEnum, LoanTermOption } from '~/graphql/types';
import type { AppState } from '~/redux';
import { submitReactFormData } from '~/redux/actions';
import { useSelector } from '~/redux/hooks';

import { getQuestionProps } from '../utils/getQuestionprops';

import { StyledHeader } from './StyledHeader';

export interface LoanInfoFormValues {
  loanPurpose: LoanPurposeEnum;
  loanAmount: number;
  loanTerm: LoanTermOption;
}

export const LoanInfoForm = () => {
  const dispatch = useDispatch();
  const { goTo } = useWizardContext();

  const persistedFormValues = useSelector((state: AppState) => {
    return state.reactHookForm['loanInfo'];
  });

  const { control, handleSubmit, setValue } = useForm<LoanInfoFormValues>({
    defaultValues: persistedFormValues,
  });

  const { data, loading } = useLoanInformationQuery();
  const { data: questionsData, loading: questionsLoading } =
    usePersonalLoansDirectQuery();

  const questions =
    questionsData?.viewer?.borrow?.personalLoans?.applicationFlowDirect
      ?.questions;
  let props;
  if (questions) {
    props = getQuestionProps({
      question: 'LOAN_USE_AMOUNT_AND_DURATION',
      questions,
    });
  }

  const { continueLabel, title, footer, nextQuestionType } = props || {};
  const onSubmit: SubmitHandler<LoanInfoFormValues> = (data) => {
    // transform input will be passed in here, not transform value
    // do all formatting in transform input if that is what you want to pass to the onSubmit
    dispatch(
      submitReactFormData({
        loanInfo: { ...data, loanAmount: Number(data.loanAmount) },
      }),
    );
    goTo(nextQuestionType ?? 'HOME_ADDRESS');
  };

  const initialLoanTermOptions =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanTermOptions || [];

  const initialLoanPurposeOptions =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanPurposeOptions
      ?.edges || [];
  if (
    !loading &&
    !questionsLoading &&
    (initialLoanPurposeOptions?.length === 0 ||
      initialLoanTermOptions?.length === 0)
  ) {
    return <GenericSystemError />;
  }

  const loanPurposeOptions = initialLoanPurposeOptions.reduce<
    { label: string | null; value: LoanPurposeEnum }[]
  >((arr, loanPurposeOption) => {
    if (loanPurposeOption?.node?.title && loanPurposeOption?.node?.value) {
      arr.push({
        label: loanPurposeOption?.node?.title,
        value: loanPurposeOption?.node?.value,
      });
    }
    return arr;
  }, []);

  const loanTermOptions = initialLoanTermOptions.reduce<
    { label: string; value: number }[]
  >((arr, term) => {
    if (term?.value && term?.description) {
      arr.push({
        label: term.description,
        value: term.value,
      });
    }
    return arr;
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader
          content={title ?? 'Tell us about the money you’re looking to borrow.'}
        />
        <HXXS
          content="What will you be using the loan for?"
          fontWeight={600}
          mb={8}
          mt={24}
        />
        <ControlledDropdown
          control={control}
          name="loanPurpose"
          isSearchable={false}
          placeholder="Select"
          options={loanPurposeOptions}
          rules={{
            required: "Please select a reason you'll be using the loan.",
            validate: {
              requireNonNil: requireNonNil,
            },
          }}
        />
        <HXXS
          content="How much would you like to borrow?"
          fontWeight={600}
          mb={8}
          mt={24}
        />
        <ControlledInput
          name="loanAmount"
          label="Loan amount"
          control={control}
          maskType="money"
          rules={{
            required: 'Required',
            validate: {
              min: (v) => {
                if (!(Number(v) >= 2500)) {
                  return 'Must be at least $2,500.';
                }

                return true;
              },
              max: (v) => {
                if (!(Number(v) <= 50000)) {
                  return 'Must be at most $50,000.';
                }

                return true;
              },
            },
          }}
          maxLength={9}
        />
        <HXXS
          content="What’s your preferred loan term?"
          fontWeight={600}
          mb={8}
          mt={24}
        />

        <ControlledDropdown
          control={control}
          isSearchable={false}
          name="loanTerm"
          placeholder="Select"
          options={loanTermOptions}
          rules={{
            required: 'Please select a term for the loan.',
            validate: {
              requireNonNil: requireNonNil,
            },
          }}
        />
        <PM
          color="foregroundNeutralSecondary"
          content={
            footer ??
            'Please note, you can’t use these loans to pay for educational expenses.'
          }
          pt={8}
          pb={8}
        />
        <ResponsiveButton
          label={continueLabel ?? 'Continue'}
          mt={48}
          type="submit"
          size="large"
        />
      </form>
      <FormMockerReactHookForm
        fields={[
          {
            name: 'loanPurpose',
            value: 'SPECIAL_OCCASION',
          },
          {
            name: 'loanAmount',
            value: '5000',
          },
          {
            name: 'loanTerm',
            value: 36,
          },
        ]}
        setValue={setValue}
      />
    </>
  );
};
