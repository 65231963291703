import type { Theme } from '@m1/liquid-react';
import * as D3 from 'd3';
import * as React from 'react';

import { generateSliceFillColorsArray } from '~/utils/slices';

import {
  type D3PieDatum,
  type D3Arc,
  type PieColorScheme,
  type SliceData,
  PieColorSchemes,
} from './Pie.types';
import { PieSlice } from './PieSlice';

export type PieProps = {
  colorScheme?: PieColorScheme;
  data: Array<SliceData>;
  height: number;
  innerRadius?: number;
  onClick?: (dataItem: SliceData) => void;
  padding?: string | null | undefined;
  showEmpty?: boolean;
  width: number;
};

export type ProvidedPieProps = {
  highlightedSlice: string | null | undefined;
  highlightSlice: (id: string | null | undefined) => void;
  theme: Theme;
};

export type CombinedPieProps = PieProps & ProvidedPieProps;

export const Pie = (props: CombinedPieProps) => {
  const {
    colorScheme = PieColorSchemes.PRIMARY_COLORS,
    data,
    height,
    highlightedSlice,
    highlightSlice,
    innerRadius,
    onClick,
    padding,
    showEmpty,
    theme,
    width,
  } = props;
  const radius = Math.min(width, height) / 2;
  const total = data.reduce((total, curr) => total + curr.percentage, 0);
  const difference = 100 - total;

  // If the difference is 100, the entire pie is empty and it should
  // always show the placeholder with the inactive color.
  // If its not, then only show the placeholder if showEmpty is passed.
  if ((difference > 0 && showEmpty) || difference === 100) {
    data.push({
      id: null,
      percentage: difference,
      isPlaceholder: true,
    });
  }

  // Initialize a d3 pie
  const pie = D3.pie<SliceData>()
    .value((d) => d.percentage)
    .sort(null);

  // Create our arc
  // @ts-expect-error - TS2322 - Type 'Arc<any, SliceData>' is not assignable to type 'D3Arc<SliceData>'.
  const arc: D3Arc<SliceData> = D3.arc<SliceData>()
    .innerRadius(innerRadius || 100)
    .outerRadius(radius);

  const pieData: Array<D3PieDatum<SliceData>> = pie(data);
  const colors: NonNullable<Theme['pieSliceColors']> =
    generateSliceFillColorsArray(theme.pieSliceColors, data.length);

  return (
    <svg
      height={height}
      style={{
        // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'Padding<string | number> | undefined'.
        padding: padding ? padding : null,
      }}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
    >
      <g
        data-testid="pie-group"
        transform={`translate(${width / 2},${height / 2})`}
      >
        {pieData.map((slice: D3PieDatum<SliceData>, index: number) => (
          <PieSlice
            arc={arc}
            colors={colors}
            colorScheme={colorScheme}
            pieDatum={slice}
            index={index}
            inactiveColor={theme.colors.foregroundNeutralTertiary}
            key={index}
            onClick={onClick}
            highlightSlice={highlightSlice}
            highlightedSlice={highlightedSlice}
          />
        ))}
      </g>
    </svg>
  );
};
