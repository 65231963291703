import type { DocumentTypeFilterEnumType } from '~/graphql/types';

type DocumentRoute = {
  documentType: DocumentTypeFilterEnumType;
  label: string;
  path: string;
};

export const routes: DocumentRoute[] = [
  {
    documentType: 'STATEMENT',
    label: 'Statements',
    path: 'statements',
  },
  {
    documentType: 'TAX_FORM',
    label: 'Tax forms',
    path: 'tax-forms',
  },
  {
    documentType: 'TRADE_CONFIRMATION',
    label: 'Trade confirmations',
    path: 'trade-confirmations',
  },
  {
    documentType: 'ACCOUNT_DOCUMENT',
    label: 'Account documents',
    path: 'account-documents',
  },
];
