import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { TransferParticipantFragment } from '~/graphql/types';

export type TransferParticipantIconProps = {
  disabled?: boolean;
  transferParticipant: TransferParticipantFragment | undefined | null;
};

export const TransferParticipantIcon = ({
  disabled = false,
  transferParticipant,
}: TransferParticipantIconProps) => {
  const name = React.useMemo(() => {
    if (!transferParticipant) {
      return 'accountAccountsPrimary32';
    }
    switch (transferParticipant.transferParticipantType) {
      case 'INVEST':
        return disabled ? 'accountInvestDisabled32' : 'accountInvestPrimary32';
      case 'CRYPTO':
        return disabled ? 'accountCryptoDisabled32' : 'accountCryptoPrimary32';
      case 'SPEND':
        return 'accountSpendPrimary32';
      case 'SAVE':
        return disabled
          ? 'accountSavingsDisabled32'
          : 'accountSavingsPrimary32';
      case 'BORROW':
        return disabled ? 'accountBorrowDisabled32' : 'accountBorrowPrimary32';
      case 'EXTERNAL':
      case 'FUNDING_SOURCE':
        return disabled ? 'accountBankDisabled32' : 'accountBankPrimary32';
      case 'PHYSICAL_CHECK':
        return disabled
          ? 'accountCheckingDisabled32'
          : 'accountCheckingPrimary32';
      case 'CREDIT_CARD':
        return disabled
          ? 'accountCreditCardDisabled32'
          : 'accountCreditCardPrimary32';
      case 'REWARDS':
        return disabled
          ? 'accountCreditCardDisabled32'
          : 'accountCreditCardPrimary32';
      case 'LOAN':
        return disabled ? 'accountBorrowDisabled32' : 'accountBorrowPrimary32';
      default:
        return disabled
          ? 'accountAccountsDisabled32'
          : 'accountAccountsPrimary32';
    }
  }, [disabled, transferParticipant]);

  return <Icon data-testid="transfer-participant-icon" name={name} mr={12} />;
};
