import { PM } from '@m1/liquid-react';
import * as React from 'react';

import type { AppLink } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

export const LinkOrText = ({
  link,
  text,
  label,
}: {
  link: Maybe<AppLink>;
  text: Maybe<string>;
  label?: boolean;
}) => {
  if (link) {
    return <LinkableLink whiteSpace="nowrap" font="PM" linkable={link} />;
  } else if (text) {
    return (
      <PM
        whiteSpace="nowrap"
        font="PM"
        content={text}
        color={label ? 'foregroundNeutralSecondary' : undefined}
      />
    );
  }
  return null;
};
