import { TwoColumn3by9 } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { SettingsAccountsNavigation } from './SettingsAccountsNavigation';

export const SettingsAccountsLayout = () => {
  return (
    <TwoColumn3by9>
      <TwoColumn3by9.Column1>
        <SettingsAccountsNavigation />
      </TwoColumn3by9.Column1>
      <TwoColumn3by9.Column2>
        <Outlet />
      </TwoColumn3by9.Column2>
    </TwoColumn3by9>
  );
};
