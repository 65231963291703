import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  PersonalLoansApplicationSagaDocument,
  type PersonalLoansApplicationSagaQueryResult,
} from '~/graphql/hooks';
import { hideLoadingSpinner, showLoadingSpinner } from '~/redux/actions';

import { apolloQuerySaga } from '../../apolloQuerySaga';

export type ApplicationInformation = {
  applicationId?: string;
  hasActivePersonalLoan: boolean;
  hasFailedPersonalLoanDisbursement: boolean;
  hasPendingDisbursement: boolean;
  loanId?: string;
  loanWithdrawn: boolean;
  needsDisbursement: boolean;
  status: string;
  isEligibleToApplyForPersonalLoan: boolean;
  hasDisbursementOption: boolean;
};

export function* fetchPersonalLoansApplicationInfo(
  savedLoanIdFromApplication: string | null | undefined,
): SagaIterator<ApplicationInformation | null | undefined> {
  yield call(showLoadingSpinner);

  try {
    const { data }: PersonalLoansApplicationSagaQueryResult = yield call(
      apolloQuerySaga,
      {
        query: PersonalLoansApplicationSagaDocument,
        variables: { savedLoanIdFromApplication },
      },
    );

    const needsDisbursement =
      data?.viewer?.borrow?.personalLoans?.activeApplication?.loan?.status ===
      'LOAN_ACCEPTED';

    const hasDisbursementOption =
      data?.viewer?.borrow?.personalLoans?.disbursementFlow
        ?.hasDisbursementOption === true;

    const loanWithdrawn =
      data?.viewer?.borrow?.personalLoans?.activeApplication?.loan?.status ===
      'WITHDRAWN';

    const hasPendingDisbursement = Boolean(
      data?.viewer?.borrow?.personalLoans?.loans?.edges?.some(
        (loan) => loan?.node?.status === 'DISBURSEMENT_QUEUED',
      ),
    );

    return {
      applicationId: data?.viewer?.borrow?.personalLoans?.activeApplication?.id,
      hasActivePersonalLoan: Boolean(
        data?.viewer?.borrow?.hasActivePersonalLoan,
      ),
      hasFailedPersonalLoanDisbursement: Boolean(
        data?.viewer?.borrow?.hasFailedPersonalLoanDisbursement,
      ),
      isEligibleToApplyForPersonalLoan: Boolean(
        data?.viewer?.borrow?.isEligibleToApplyForPersonalLoan,
      ),
      loanId: data?.viewer?.borrow?.personalLoans?.activeApplication?.loan?.id,
      needsDisbursement,
      status: data?.viewer?.borrow?.personalLoans?.activeApplication?.status
        ?.status as any,
      loanWithdrawn,
      hasPendingDisbursement,
      hasDisbursementOption,
    };
  } catch (e: any) {
    throw new Error(`error fetchUserPersonalLoansApplicationData: ${e}`);
  } finally {
    yield call(hideLoadingSpinner);
  }
}
