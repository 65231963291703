import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';

import { hasInactiveSlices } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';

export const InactiveSlicesBanner = () => {
  const pieTree = useSelector((state) => state.portfolioOrganizer.pieTree);

  const showBanner = React.useMemo<boolean>(() => {
    return hasInactiveSlices(pieTree); // Note, even though the plugin says we don't need pieTree.slices
    // below in the dependency array, we do otherwise react won't recalculate
    // if a user adds or removes a slice
  }, [pieTree, pieTree.slices]);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner
      content="Remove inactive securities from your portfolio before editing or saving this Pie."
      kind="alert"
      mt={12}
    />
  );
};
