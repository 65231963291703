import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';

import { useSupportLink } from '~/hooks/useSupportLink';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

export const CustodialAccountMaturedBanner = () => {
  const supportLink = useSupportLink();
  return (
    <Container>
      <Banner
        action={<Link {...supportLink}>Contact Support</Link>}
        content="The custodial beneficiary has reached the age of maturity. Contact support to initiate the transfer of ownership."
        kind="warning"
      />
    </Container>
  );
};
