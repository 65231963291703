import type { Slice } from './types';

export const equalizeSliceListAllocations = (
  slices: Array<Slice>,
  toFull: boolean,
): Array<Slice> => {
  // Nothing to equalize in an empty array.
  if (slices.length === 0) {
    return slices;
  }

  const target = toFull ? 100 : slices.reduce((p, v) => p + v.percentage, 0);

  const equalizedSlices = slices.map((slice, _, array) => {
    const newAllocation = Math.floor(target / array.length);
    return {
      ...slice,
      percentage: newAllocation,
    };
  });

  // These next lines ensure that the allocation returned by this function
  // is valid. This will happen if slices.length does not divide evenly into 100.
  const allocation = sumSliceListPercentages(equalizedSlices);

  if (allocation !== target) {
    for (let x = 0; x < target - allocation; x++) {
      equalizedSlices[x].percentage += 1;
    }
  }

  return equalizedSlices;
};

const sumSliceListPercentages = (slices: Array<Slice>): number => {
  return slices.reduce((p, v) => p + v.percentage, 0);
};
