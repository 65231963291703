import { Box, css, Flex, PL, styled, Tooltip } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AnnouncementCard } from '~/components/announcement/AnnouncementCard';
import {
  HeaderAnnouncementRefetchDocument,
  useDismissAnnouncementsMutation,
} from '~/graphql/hooks';
import type { HeaderAnnouncementsFragment } from '~/graphql/types';

type DashboardAnnouncementsProps = {
  announcements: null | undefined | HeaderAnnouncementsFragment;
};

const StyledTrigger = styled(Flex)(
  ({ theme }) => css`
    position: relative;
    fill: ${theme.colors.foregroundNeutralOnDark};
    cursor: pointer;

    &:hover {
      fill: ${theme.colors.primaryTint};
    }

    svg {
      vertical-align: middle;
    }
  `,
);

const StyledDot = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;

  height: 8px;
  width: 8px;

  background-color: ${(props) => props.theme.colors.warning};
  border-radius: 50%;
`;

const StyledMenu = styled(Box)`
  padding: 24px 16px 0px;
  max-height: 550px;
  min-height: 440px;
  overflow-y: auto;
  width: 316px;
`;

export const DashboardAnnouncements = ({
  announcements,
}: DashboardAnnouncementsProps) => {
  const hasAnyAnnouncements = Boolean(announcements?.hasAny);

  const [dismissAnnouncements] = useDismissAnnouncementsMutation({
    variables: {
      input: {
        context: 'LIST',
      },
    },
    refetchQueries: [{ query: HeaderAnnouncementRefetchDocument }],
  });

  const announcementsArray = [];
  if (announcements?.list?.edges) {
    for (const edge of announcements.list.edges) {
      if (edge?.node) {
        announcementsArray.push(edge.node);
      }
    }
  }
  const handleOpen = () => {
    dismissAnnouncements();
  };

  return (
    <Flex alignItems="center">
      <Tooltip
        placement="bottom-end"
        trigger="click"
        onShow={handleOpen}
        body={
          <StyledMenu>
            {hasAnyAnnouncements ? (
              <>
                <Flex alignItems="center">
                  <PL
                    fontWeight={600}
                    content="Announcements"
                    color="foregroundNeutralMain"
                  />
                </Flex>
                {announcementsArray.map((announcement, index) => (
                  <AnnouncementCard
                    announcement={announcement}
                    layout="inline"
                    key={announcement.id}
                    marginTop={index ? 8 : 16}
                    marginBottom={
                      index === announcementsArray.length - 1 ? 16 : undefined
                    }
                  />
                ))}
              </>
            ) : (
              <Flex
                alignItems="center"
                mt={32}
                justifyContent="center"
                flexDirection="column"
              >
                <Illustration height={180} name="announcements" width={188} />
                <PL
                  fontWeight={600}
                  content="No announcements"
                  style={{
                    textAlign: 'center',
                  }}
                />
              </Flex>
            )}
          </StyledMenu>
        }
      >
        <StyledTrigger
          alignItems="center"
          justifyContent="center"
          height={32}
          width={32}
          aria-label="Open announcements"
        >
          {Boolean(announcements && announcements.hasNew) && <StyledDot />}
          <Icon name="announcement24" />
        </StyledTrigger>
      </Tooltip>
    </Flex>
  );
};
