import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { BeneficiariesPage } from './beneficiaries/BeneficiariesPage';
import { ContactInformationPage } from './contact-information/ContactInformationPage';
import { EmploymentPage } from './employment-information/EmploymentProfilePage';
import { InvestmentProfilePage } from './investment-profile/InvestmentProfilePage';
import { InvestorDisclosuresProfilePage } from './investor-disclosures/InvestorDisclosuresProfilePage';
import { ProfilePage } from './ProfilePage';
import { TrustedContactPage } from './trusted-contact/TrustedContactPage';

export const ProfileRoute = () => (
  <Route path="profile" element={<ProfilePage />}>
    <Route
      index
      element={<Navigate to="/d/settings/profile/contact" replace />}
    />
    <Route path="contact" element={<ContactInformationPage />} />
    <Route path="employment" element={<EmploymentPage />} />
    <Route path="investment" element={<InvestmentProfilePage />} />
    <Route path="disclosures" element={<InvestorDisclosuresProfilePage />} />
    <Route path="trusted-contact" element={<TrustedContactPage />} />
    <Route path="beneficiaries" element={<BeneficiariesPage />} />
  </Route>
);
