import type { LoanApplicationStatusEnum } from '~/graphql/types';

export const getApplicationStatusRoute = (
  status: LoanApplicationStatusEnum,
): {
  route: LoanApplicationStatusEnum | string;
  isWizardStep: boolean;
} => {
  switch (status) {
    case 'OFFERS_PROVIDED':
      return {
        route: 'PRE_APPROVED_OFFERS',
        isWizardStep: true,
      };
    case 'REJECTED_CREDIT_FROZEN':
      return {
        route: 'REJECTED_CREDIT_FROZEN',
        isWizardStep: true,
      };
    case 'INCOME_VERIFICATION_REQUIRED':
      return {
        route: 'INCOME_VERIFICATION_REQUIRED',
        isWizardStep: true,
      };
    case 'APPROVED':
      return {
        route: 'BANK_CONNECTION',
        isWizardStep: true,
      };
    case 'REJECTED':
      return {
        route: '/direct-loan-application-error/application-rejected',
        isWizardStep: false,
      };
    case 'MANUAL_REVIEW_REQUIRED':
      return {
        route: '/direct-loan-application-error/manual-review',
        isWizardStep: false,
      };
    default:
      return {
        route: '/direct-loan-application-error/application-received',
        isWizardStep: false,
      };
  }
};
