import * as React from 'react';

import { AnnouncementContext } from '~/components/announcement';
import { useDocumentAnnouncementsQuery } from '~/graphql/hooks';

export const AnnouncementsForDocuments = () => {
  const { data, loading, error } = useDocumentAnnouncementsQuery();

  if (loading || error) {
    return null;
  }

  const announcement = data?.viewer?.announcements?.forDocuments ?? null;
  if (announcement === null) {
    return null;
  }

  return (
    <AnnouncementContext
      announcement={announcement}
      context="DOCUMENTS"
      mb={16}
    />
  );
};
