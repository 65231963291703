import { Button, HXS, Modal, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useRebalancePortfolioSliceMutation } from '~/graphql/hooks';
import { useModal } from '~/hooks/useModal';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { EXTERNAL_LINKS } from '~/static-constants';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Link } from '~/toolbox/link';

import { CryptoDisclosure } from '../CryptoDisclosure';

export const RebalanceModal = () => {
  const { addToast } = useToast();

  const isCrypto = useSelector((state) => state.global.activeAccountIsCrypto);

  const { hideModal, modalState } = useModal('REBALANCE_ACTION_SET');
  const portfolioSliceId = modalState.payload?.portfolioSliceId;

  const [rebalancePortfolioSlice, { loading }] =
    useRebalancePortfolioSliceMutation({
      variables: {
        input: {
          isRebalance: true,
          portfolioSliceId,
        },
      },
    });

  usePortaledSpinner(loading);

  const handleCancel = (): void => {
    hideModal();
  };

  const handleConfirm = (): void => {
    rebalancePortfolioSlice({
      onCompleted: () => {
        addToast({
          content: 'Rebalance requested.',
          duration: 'long',
          kind: 'success',
        });

        hideModal();
      },
      onError: () => {
        addToast({
          content:
            'Something went wrong with your request. Please try again later or contact support.',
          duration: 'long',
          kind: 'alert',
        });

        hideModal();
      },
    });
  };

  return (
    <Modal open={modalState.isOpened} onClose={handleCancel}>
      <ModalContent>
        <HXS content="Rebalance" />
        <PL mt={8}>
          During the next trade window, M1 will buy underweight slices and sell
          overweight slices to bring your Pie back to its targets.
        </PL>
        <PL mt={16}>
          <Link to={EXTERNAL_LINKS.REBALANCING_FAQ} target="_blank">
            Learn more about rebalancing
          </Link>
        </PL>
        {isCrypto && <CryptoDisclosure />}
        <ButtonGroup mt={16}>
          <Button
            label="Cancel"
            kind="secondary"
            size="medium"
            onClick={handleCancel}
          />
          <Button
            label="Confirm"
            kind="primary"
            size="medium"
            onClick={handleConfirm}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
