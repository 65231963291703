import { Box, Button, Flex, HM, HXS, LL, PL, PS, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { LabelWithValue } from '~/components/label-with-value';
import { ConfirmModal } from '~/components/modals';
import { CancelTransferNotifications } from '~/components/notifications';
import { TextWithPhoneLinks } from '~/components/TextWithPhoneLinks';
import { useTransferInstanceDetailsPageWrapperQuery } from '~/graphql/hooks';
import type { TransferInstanceDetailsFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useParams } from '~/hooks/useParams';
import { useSearchParams } from '~/hooks/useSearchParams';
import { AppPill } from '~/lens-toolbox/AppPill';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import {
  clickedCancelTransferInstance,
  dismissNotifications,
  enteredPage,
  exitedPage,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { hasActiveErrorMessageAtLocation } from '~/redux/selectors';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { TransferInstanceBottomMessageCard } from './components/TransferInstanceBottomMessageCard';
import { TransferInstanceStepsCard } from './components/TransferInstanceStepsCard';

export const TransferInstanceDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { transferInstanceId } = useParams();

  const { data, loading } = useTransferInstanceDetailsPageWrapperQuery({
    variables: {
      transferInstanceId: transferInstanceId as string,
    },
    skip: !transferInstanceId,
    fetchPolicy: 'network-only',
  });

  const previousRouteName =
    searchParams.get('previousRouteName') || '/d/transfers';

  const hasCancelTransferError = useSelector((state) => {
    return hasActiveErrorMessageAtLocation(
      state,
      NOTIFICATION_LOCATIONS.CANCEL_TRANSFER,
    );
  });
  React.useEffect(() => {
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    dispatch(enteredPage('TRANSFER_INSTANCE_DETAILS'));

    return function cleanup() {
      // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
      dispatch(exitedPage('TRANSFER_INSTANCE_DETAILS'));

      if (hasCancelTransferError) {
        dispatch(dismissNotifications());
      }
    };
  }, [dispatch, hasCancelTransferError]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  const transferInstance = data?.node as
    | TransferInstanceDetailsFragment
    | null
    | undefined;

  if (!data || !transferInstance) {
    return <GenericSystemError />;
  }
  const { details } = transferInstance;

  const handleClickCancel = () => {
    dispatch(
      clickedCancelTransferInstance({
        transferInstance: transferInstance.id,
        source: previousRouteName,
      }),
    );
  };

  const totalCredited = details?.outstandingTransferCredits
    ? details.outstandingTransferCredits.reduce((totalCredited, current) => {
        let newTotalCredited: number = totalCredited;

        if (current) {
          newTotalCredited += current.creditedAmount;
        }

        return newTotalCredited;
      }, 0)
    : null;

  return (
    <Box m="0 auto" pt={64} pb={100} width={490}>
      <HXS content={details?.title} />
      <Card mt={32} p={32}>
        <LL color="foregroundNeutralMain" content={details?.amountLabel} />
        <Flex alignItems="center" mt={8}>
          {details?.amount && <HM content={details.amount} />}
          {details?.pill && <AppPill appPill={details.pill} ml={8} />}
        </Flex>
        <Divider spacing="relaxed" />
        {typeof totalCredited === 'number' && totalCredited !== 0 && (
          <Flex py={16} pr={16}>
            {`While your recent deposit is processing, you
              have $${totalCredited} in buying
              power that you can put to work right now.`}
          </Flex>
        )}
        {details?.additionalDetails?.filter(isNotNil).map((detail, i) => {
          const values: Array<string | null | undefined> = [
            detail.value1,
            detail.value2,
            detail.value3,
            detail.value4,
          ];
          return (
            <LabelWithValue
              key={`additional-details-${i}`}
              label={detail.label}
              value={values}
              style={{
                marginTop: 16,
              }}
            />
          );
        })}
        <PL
          color="foregroundNeutralMain"
          content={details?.steps?.label}
          mt={20}
        />
        {details?.steps && (
          <TransferInstanceStepsCard
            style={{
              marginTop: 8,
            }}
            transferInstanceSteps={details.steps}
          />
        )}
        {details?.transferSpecificMessage && (
          <TransferInstanceBottomMessageCard
            style={{
              marginTop: 16,
            }}
            transferInstanceDetailsMessage={details.transferSpecificMessage}
          />
        )}
        {details?.transferTimingMessage?.link && (
          <LinkableLink
            linkable={details.transferTimingMessage.link}
            font="PM"
            fontWeight={400}
            marginTop={40}
          />
        )}
      </Card>
      {details?.disclaimer && (
        <Box mt={16}>
          <PS>
            <TextWithPhoneLinks
              text={details.disclaimer.disclaimerCopy}
              phoneNumber={details.disclaimer.phoneNumber}
            />
          </PS>
          {details.disclaimer.link && (
            <LinkableLink
              kind="primary"
              font="LS"
              linkable={details.disclaimer.link}
            />
          )}
        </Box>
      )}
      <CancelTransferNotifications
        style={{
          marginTop: hasCancelTransferError ? 16 : 0,
        }}
      />
      <ButtonGroup mt={64}>
        {details?.ifCancelable ? (
          <ConfirmModal
            title={details.ifCancelable.cancelDialogCopy}
            cancelCtaCopy={details.ifCancelable.cancelDialogCancelCtaCopy}
            confirmCtaCopy={details.ifCancelable.cancelDialogConfirmCtaCopy}
            trigger={
              <Button
                kind="destructive"
                label={details.ifCancelable.cancelCtaCopy}
                size="large"
                onClick={() => dispatch(dismissNotifications())}
              />
            }
            onConfirm={handleClickCancel}
            hasError={hasCancelTransferError}
          />
        ) : null}
        <Button
          kind="primary"
          label="Done"
          size="large"
          onClick={() => {
            dispatch(dismissNotifications());
            navigate({ to: previousRouteName });
          }}
        />
      </ButtonGroup>
    </Box>
  );
};
