import React from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { useTransferWizardRequirementsLazyQuery } from '~/graphql/hooks';
import type {
  TransferScenarioTypeEnum,
  TransferWizardRequirementsQuery,
} from '~/graphql/types';
import { useDebounce } from '~/hooks/useDebounce';

import {
  type TransferDetailsFormValues,
  TransferFrequencyEnum,
} from '../steps/TransferDetails.types';

export type TransferRequirements = NonNullable<
  TransferWizardRequirementsQuery['viewer']['transfers']
>['requirements'];

export function useTransferRequirements({
  form,
  scenario,
}: {
  form: UseFormReturn<TransferDetailsFormValues>;
  scenario?: Maybe<TransferScenarioTypeEnum>;
}) {
  const [amount, sourceId, destinationId, frequency] = form.watch([
    'amount',
    'sourceId',
    'destinationId',
    'frequency',
  ]);

  const [loadTransferRequirements, { data, loading, previousData }] =
    useTransferWizardRequirementsLazyQuery({
      variables: {
        amount: amount ? parseFloat(amount) : undefined,
        destinationId: destinationId as string,
        sourceId: sourceId as string,
        scenario,
        transferType: (() => {
          switch (frequency) {
            case TransferFrequencyEnum.OneTime:
              return 'TRANSFER_INSTANCE';
            default:
              return 'SCHEDULED_TRANSFER_RULE';
          }
        })(),
      },
    });

  // We need to debounce here, due to cascading form changes:
  const fireDebounce = useDebounce(loadTransferRequirements, 100);

  React.useEffect(() => {
    fireDebounce();
  }, [amount, sourceId, destinationId, frequency]);

  const requirements = loading
    ? previousData?.viewer?.transfers?.requirements
    : data?.viewer.transfers?.requirements;

  React.useEffect(() => {
    if (
      !form.getValues('contributionYear') &&
      requirements?.isIraContributionYearRequired === false
    ) {
      form.setValue('contributionYear', 'CURRENT_YEAR');
    }
  }, [requirements]);

  return requirements;
}
