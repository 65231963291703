import { PL, Flex, styled, theme, PXL, PM } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import {
  useLoanRecapStepQuery,
  useLoanTermsAndAcceptPageQuery,
  usePersonalLoansDirectQuery,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from '../components/StyledHeader';
import { getQuestionProps } from '../utils/getQuestionprops';

import { SingleLineItem } from './components/SingleLineItem';

const IllustrationContainer = styled(Flex)`
  margin-bottom: 24px;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    margin-bottom: 40px;
    margin-top: 56px;
  }
`;

export const Receipt = () => {
  const { data: ploeData, loading: ploeLoading } =
    usePersonalLoansDirectQuery();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const { transferParticipantName } = useSelector(
    (state) => state.reactHookForm.directToPersonalLoansTransferParticipant,
  );

  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const { data: loanData, loading: loanLoading } =
    useLoanTermsAndAcceptPageQuery({
      variables: {
        applicationId,
      },
      errorPolicy: 'all',
    });
  const loanId = loanData?.viewer.borrow?.personalLoans?.application?.loan?.id;

  const { data, loading } = useLoanRecapStepQuery({
    variables: {
      loanId,
    },
  });

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/loan-recap');
  }, [analytics]);

  if (loading || ploeLoading || loanLoading) {
    return <Spinner fullScreen centered />;
  }

  const questions =
    ploeData?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  let props;

  if (questions) {
    props = getQuestionProps({
      question: 'DATE_OF_BIRTH_CITIZENSHIP',
      questions,
    });
  }

  const { title, continueLabel } = props || {};

  const specializedMessage = data?.viewer.borrow?.personalLoans
    ?.disbursementFlow?.reviewLoanScreen?.customizedMessage || [''];

  const loanDetails =
    data?.viewer.borrow?.personalLoans?.disbursementFlow?.reviewLoanScreen
      ?.details;

  const loanAgreementDocLink =
    data?.viewer.borrow?.personalLoans?.disbursementFlow?.reviewLoanScreen
      ?.loanAgreementDocLink;

  return (
    <>
      <IllustrationContainer flexDirection="column" alignItems="center">
        <Illustration name="allSetWide" />
      </IllustrationContainer>

      <StyledHeader content={title || "You're all set!"} mt={24} mb={24} />

      <PL>
        Your money should arrive in your{' '}
        <strong>{transferParticipantName}</strong> account in a few days. If you
        need, you can review your loan agreement in the disclosures section of
        the app.
      </PL>

      {specializedMessage.map((message, index) => (
        <PL content={message} mt={16} key={index} />
      ))}

      <PXL content="Your loan summary" mt={24} fontWeight={600} />

      {loanDetails?.map((loanDetail) =>
        loanDetail ? (
          <SingleLineItem
            key={loanDetail.label}
            label={loanDetail.label}
            value={loanDetail.value}
          />
        ) : null,
      )}

      <PM mt={40} fontWeight={400}>
        {loanAgreementDocLink && (
          <LinkableLink linkable={loanAgreementDocLink} />
        )}
      </PM>

      <ResponsiveButton
        label={continueLabel || 'Go to loan dashboard'}
        onClick={() => navigate({ to: '/d/borrow/personal/transactions' })}
        mt={48}
      />
    </>
  );
};
