import { Flex, spacingUnits } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { BiweeklyIsEvenWeeksDropdownField } from '~/components/form/Dropdowns/schedule/BiweeklyIsEvenWeeksDropdownField';
import { DayOfMonthDropdown } from '~/components/form/Dropdowns/schedule/DayOfMonthDropdown';
import { DayOfWeekDropdown } from '~/components/form/Dropdowns/schedule/DayOfWeekDropdown';
import { isNotNil } from '~/utils';

import {
  type TransferDetailsFormValues,
  TransferFrequencyEnum,
} from '../../steps/TransferDetails.types';
import { TransferContext } from '../../TransferContext';

import { isValidTransferFrequency } from './TransferScheduleOptions.utils';
import { TransferScheduleRadioGroup } from './TransferScheduleRadioGroup';

export const TransferScheduleOptions = ({
  isDisabled,
}: {
  isDisabled: boolean;
}) => {
  const transferContext = React.useContext(TransferContext);
  const isEvenWeek = Boolean(transferContext?.viewer.transfers?.isEvenWeek);

  const { setValue, watch, control, clearErrors } =
    useFormContext<TransferDetailsFormValues>();

  const frequency = watch('frequency');

  const onFrequencyChange = (freq: TransferFrequencyEnum) => {
    if (isValidTransferFrequency(freq)) {
      setValue('schedule', undefined);
      clearErrors('schedule');
    }
  };

  let options = Object.values(TransferFrequencyEnum);

  // If we are editing a transfer schedule, we omit the "one-time" option:
  if (isNotNil(transferContext?.transferRule)) {
    options = options.filter(
      (option) => option !== TransferFrequencyEnum.OneTime,
    );
  }

  return (
    <Flex flexDirection="column" mb={`${spacingUnits.l}px`}>
      <fieldset>
        <legend className="visually-hidden">Recurring Transfer Options</legend>
        <Flex justifyContent="space-between">
          <TransferScheduleRadioGroup
            {...{
              name: 'frequency',
              disabled: isDisabled,
              control,
              onFrequencyChange,
              options,
            }}
          />
        </Flex>
      </fieldset>
      {frequency === TransferFrequencyEnum.Weekly && (
        <DayOfWeekDropdown name="schedule.weekly.dayOfWeek" />
      )}
      {frequency === TransferFrequencyEnum.EveryTwoWeeks && (
        <Flex flexDirection="column">
          <DayOfWeekDropdown name="schedule.biweekly.dayOfWeek" />
          <BiweeklyIsEvenWeeksDropdownField
            isEvenWeek={isEvenWeek}
            label="Start"
          />
        </Flex>
      )}
      {frequency === TransferFrequencyEnum.Monthly && <DayOfMonthDropdown />}
    </Flex>
  );
};
