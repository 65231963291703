import type { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { ChartableSliceChartDocument } from '~/graphql/hooks';
import type { NavigateFunction } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import { ACTION_TYPES as ACTIONS, displayNotification } from '~/redux/actions';
import { readLandingUrl } from '~/redux/selectors';
import { NOTIFICATION_LOCATIONS, NOTIFICATION_TYPES } from '~/static-constants';
import { isNotNil } from '~/utils';

import { apolloQuerySaga } from '../apolloQuerySaga';
import {
  hasUserOnboarded,
  handleProductAutoSelectDeepLinksSaga,
} from '../common';

export function* loginMonitor(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.FINISHED_LOGIN_FLOW,
    function* (): SagaIterator<void> {
      try {
        const [landingUrl, userHasOnboarded, navigate]: [
          string,
          boolean,
          NavigateFunction,
        ] = yield all([
          select(readLandingUrl),
          call(hasUserOnboarded),
          select((state: AppState) => state.routing.navigate),
        ]);

        const deeplinkRedirects = yield call(
          handleProductAutoSelectDeepLinksSaga,
        );

        if (deeplinkRedirects) {
          const { route, previousRoute } = deeplinkRedirects;
          if (isNotNil(previousRoute)) {
            yield call(navigate, {
              to: previousRoute,
              options: { replace: true },
            });
          }

          yield call(navigate, { to: route });
        } else if (!userHasOnboarded) {
          yield call(navigate, { to: '/onboarding/phone-verification' });
        } else if (
          (landingUrl && landingUrl.startsWith('/d/')) ||
          landingUrl.includes('activate-your-card')
        ) {
          yield call(navigate, { to: landingUrl });
        } else {
          yield call(navigate, { to: '/d/home' });

          const netWorthNodeId = yield select(
            (state: AppState) => state.global.netWorthNodeId,
          );
          // Preload the net worth chart
          if (netWorthNodeId) {
            yield call(apolloQuerySaga, {
              query: ChartableSliceChartDocument,
              variables: {
                ids: [netWorthNodeId],
              },
            });
          }
        }
      } catch (e: any) {
        yield put(
          displayNotification({
            type: NOTIFICATION_TYPES.ERROR_ALT,
            location: NOTIFICATION_LOCATIONS.LOGIN,
            message: 'Something went wrong. Please try again.',
          }),
        );
      }
    },
  );
}
