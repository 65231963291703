import { type Theme, css, styled } from '@m1/liquid-react';

export const Baseline = styled.line(
  ({ theme }) => css`
    stroke: ${theme.colors.foregroundNeutralSecondary};
    stroke-dasharray: 5, 5;
    stroke-width: 1px;
  `,
);

export const Path = styled.path<{
  color: keyof Theme['colors'];
}>(
  ({ color, theme }) => css`
    fill: none;
    stroke-width: 1.5px;
    stroke: ${theme.colors[color]};
  `,
);
export const Circle = styled.circle<{
  color: keyof Theme['colors'];
}>(
  ({ color, theme }) => css`
    fill: ${theme.colors[color]};
  `,
);
export const Text = styled.text(
  ({ theme }) => css`
    font-size: 12;
    fill: ${theme.colors.foregroundNeutralSecondary};
  `,
);
