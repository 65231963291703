import type { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import type { AppState } from '~/redux';

import { setRootPieSaga } from '../../../../common/setRootPieSaga';

export function* handleSelectedRootPie(action: {
  payload: string;
}): SagaIterator<void> {
  const accountId = yield select(
    (state: AppState) => state.newFlows.accountSetup.accountId,
  );

  yield call(setRootPieSaga, accountId, action.payload);
}
