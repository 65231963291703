import * as React from 'react';

import type { TransferParticipantFragment } from '~/graphql/types';
import { Cell } from '~/toolbox/cell';

import { TransferParticipantIcon } from './TransferParticipantIcon';

type TransferParticipantCellProps = {
  transferParticipant: TransferParticipantFragment;
  includeDescription?: boolean;
  titleWeight?: 400 | 600;
};

export const TransferParticipantCell = (
  props: TransferParticipantCellProps,
) => (
  <Cell
    icon={
      <TransferParticipantIcon
        transferParticipant={props.transferParticipant}
      />
    }
    subtitle={
      props.includeDescription
        ? props.transferParticipant.transferParticipantDescription
        : null
    }
    title={props.transferParticipant.transferParticipantName}
    titleWeight={props.titleWeight ?? 600}
    overflowEllipsis
  />
);
