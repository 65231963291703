import {
  Box,
  Flex,
  HXS,
  LL,
  LS,
  PL,
  PS,
  styled,
  Card,
  Tooltip,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Gain } from '~/components/gain';
import { PercentGain } from '~/components/percent-gain';
import { SliceableNameCell } from '~/components/pie';
import type {
  PortfolioLinkFragment,
  PositionDetailsFragment,
  SortDirectionEnum,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { COST_BASIS_DISCLOSURE } from '~/static-constants/COST_BASIS_DISCLOSURE';
import { GridTable, type GridTableHeaderCellProps } from '~/toolbox/grid-table';
import { isNotNil, tableSort } from '~/utils';
import { formatPercentage } from '~/utils/formatting';
import { formatCurrency } from '~/utils/formatting/format-currency';

import { PortfolioSliceOrderLink } from './PortfolioSliceOrderLink';

export type PositionDetailsProps = {
  isNavigable?: boolean;
  onSellLinkClick?: (...args: Array<any>) => any;
  onRowClick?: (portfolioLinkId: string) => void;
  position: PositionDetailsFragment;
  previousRouteName?: string | null | undefined;
};

const StyledSecurityCard = styled(Flex)<{
  isNavigable: boolean;
}>`
  cursor: ${(props) => props.isNavigable && 'pointer'};
`;

const NAVIGATION_MAPPING = {
  Equity: { idKey: 'equityId', to: '/d/research/stocks/details/:equityId' },
  Fund: { idKey: 'fundId', to: '/d/research/funds/details/:fundId' },
  CryptoAsset: {
    idKey: 'cryptoId',
    to: '/d/research/crypto/details/:cryptoId',
  },
};

export const PositionDetailsContainer = ({
  isNavigable = true,
  onSellLinkClick,
  onRowClick,
  position,
  previousRouteName,
}: PositionDetailsProps) => {
  const navigate = useNavigate();

  const isCrypto = useSelector(
    (state: AppState) => state.global.activeAccountIsCrypto,
  );

  const [sortDirection, setSortDirection] =
    React.useState<SortDirectionEnum>('DESC');

  const [sortKey, setSortKey] = React.useState<string>('value.value');

  const portfolioLinks =
    position.positionSecurity.security?.portfolioLinks ?? [];
  const security = position.positionSecurity.security;

  const hasFullyPaidLending =
    typeof position.fullyPaidLending?.quantity === 'number';
  const hasBorrowAccount = isNotNil(position.investAccount?.borrowAccount);

  const hasOpenMarginCall =
    position.investAccount?.borrowAccount?.status?.hasOpenMaintenanceCall;

  const sortedPortfolioLinks = tableSort(
    portfolioLinks,
    sortKey,
    sortDirection,
  );

  const renderPositionDataPoint = (
    label: string | null | undefined,
    value: React.ReactNode,
    tooltip?: React.ReactNode,
  ) => (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        {typeof label === 'string' && (
          <LS color="foregroundNeutralMain" content={label} />
        )}
        {tooltip}
      </Flex>
      <LL content={value} />
    </Flex>
  );

  const renderTableHeader = (
    key: string,
    label: string,
    props: GridTableHeaderCellProps | null | undefined,
  ) => {
    return (
      <GridTable.HeaderCell
        {...props}
        label={label}
        // FIXME: Figure out what to set the sort key too. It wasn't being set in the HeaderCell.
        onClick={() => handleTableHeaderClick('')}
        sortDirection={key === sortKey ? sortDirection : null}
      />
    );
  };

  const handleTableHeaderClick = (newSortKey: string): void => {
    setSortKey(newSortKey);

    if (sortKey === newSortKey) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortDirection('ASC');
    }
  };

  const readMarginiabilityValue = () => {
    if (position.marginability) {
      return position.marginability.isMarginable ? 'Yes' : 'No';
    }

    return '--';
  };

  const handleSecurityCardClick = () => {
    if (!security) {
      return;
    }
    const mapping = NAVIGATION_MAPPING[security.__typename];
    const { idKey, to } = mapping;
    const params = { [idKey]: security.id };

    if (isNotNil(mapping)) {
      navigate({ to, params });
    }
  };

  return (
    <>
      {security && (
        <Card>
          <StyledSecurityCard
            data-testid="position-details-security-card"
            isNavigable={isNavigable}
            justifyContent="space-between"
            onClick={isNavigable ? handleSecurityCardClick : undefined}
            px={16}
            py={21}
          >
            <SliceableNameCell
              sliceable={security}
              size="medium"
              truncated={false}
            />
            {isNavigable && (
              <Flex alignItems="center">
                <Icon name="caretRight16" />
              </Flex>
            )}
          </StyledSecurityCard>
        </Card>
      )}
      <HXS content="Position" mt={16} />
      <Card mt={16}>
        <Flex flexDirection="column" mb={32} mt={16} mx={16}>
          <Flex mb={28}>
            <Box mr={46}>
              {renderPositionDataPoint(
                isCrypto ? 'Quantity' : 'Shares',
                position.quantity,
              )}
            </Box>
            <Box mr={46}>
              {!isCrypto &&
                hasFullyPaidLending &&
                renderPositionDataPoint(
                  'Shares on loan',
                  position.fullyPaidLending?.quantity,
                )}
            </Box>
          </Flex>
          <Flex mb={28}>
            <Flex mr={46}>
              <Box mr={position.cost === null ? 8 : 0}>
                {renderPositionDataPoint(
                  'Avg. price',
                  formatCurrency(position.cost?.averageSharePrice) ?? '$--',
                )}
              </Box>
              <Tooltip
                placement="bottom-start"
                icon="tooltip16"
                iconColor="primary"
                body={
                  <PS content="The exact cost for this holding is not currently known because of an action in the symbol. The most common reasons for cost to be unknown is from stock splits, company mergers, or account transfers. Your account will continue to trade as normal and your cost will be to date in the next few days." />
                }
              />
            </Flex>
            <Flex mr={46}>
              {renderPositionDataPoint(
                'Cost basis',
                formatCurrency(position.cost?.cost) ?? '$--',
              )}
              <Tooltip
                placement="bottom-start"
                icon="tooltip16"
                iconColor="primary"
                body={<PS content={COST_BASIS_DISCLOSURE} />}
              />
            </Flex>
            <Flex mr={46}>
              <Box mr={position.value === null ? 8 : 0}>
                {renderPositionDataPoint(
                  'Value',
                  formatCurrency(position.value?.value) ?? '$--',
                )}
              </Box>
              {position.value === null && !isCrypto && (
                <Tooltip
                  placement="bottom-start"
                  icon="tooltip16"
                  iconColor="primary"
                  body={
                    <PS content="We temporarily don't have pricing for this security. Your account will continue to trade as normal and your value will be up to date shortly." />
                  }
                />
              )}
            </Flex>
            <Box>
              {renderPositionDataPoint(
                'Unrealized gain',
                position.unrealizedGain ? (
                  <Box display="flex" whiteSpace="nowrap">
                    <Gain value={position.unrealizedGain.gain} />(
                    <PercentGain value={position.unrealizedGain.gainPercent} />)
                  </Box>
                ) : (
                  '$-- (--%)'
                ),
              )}
            </Box>
          </Flex>
          {hasBorrowAccount && (
            <Flex>
              <Box mr={55}>
                {renderPositionDataPoint(
                  'Margin eligible',
                  readMarginiabilityValue(),
                  <Tooltip
                    placement="top-start"
                    icon="tooltip16"
                    iconColor="primary"
                    body={
                      <PS content="Whether or not this security can be leveraged with M1 Borrow. If a symbol has a maintenance requirement of 100% it is not eligible for M1 Borrow." />
                    }
                  />,
                )}
              </Box>
              <Box mr={33}>
                {renderPositionDataPoint(
                  'Margin requirement',
                  position.marginability
                    ? formatPercentage(
                        position.marginability
                          .maintenanceEquityRequirementPercent / 100,
                        'INTEGER',
                      )
                    : '--%',
                  <Tooltip
                    placement="top-start"
                    icon="tooltip16"
                    iconColor="primary"
                    body={
                      <PS content="The minimum amount of equity that must be maintained when leveraged with M1 Borrow." />
                    }
                  />,
                )}
              </Box>
              <Box>
                {renderPositionDataPoint(
                  'Required equity',
                  formatCurrency(
                    position.marginability?.maintenanceEquityRequirement,
                  ) ?? '$--',
                )}
              </Box>
            </Flex>
          )}
        </Flex>
      </Card>
      {Boolean(portfolioLinks.length) && (
        <>
          <HXS content="Portfolio Location" mb={16} mt={24} />
          <GridTable
            gridTemplateColumns={
              hasOpenMarginCall === true
                ? '275px 25% 25% auto'
                : '275px 25% auto'
            }
          >
            <GridTable.HeaderRow>
              <GridTable.HeaderCell label="Name" />
              {renderTableHeader('value.total', 'Value', {
                align: 'right',
              })}
              {renderTableHeader('percentage', 'Percent allocation', {
                align: 'right',
              })}
              {hasOpenMarginCall === true && <GridTable.HeaderCell />}
            </GridTable.HeaderRow>
            {sortedPortfolioLinks.map((portfolioLink) => {
              if (portfolioLink.__typename === 'ChildPortfolioSlice') {
                return (
                  <GridTable.Row
                    key={portfolioLink.id}
                    onClick={() => onRowClick?.(portfolioLink.id)}
                  >
                    <GridTable.Cell
                      content={readPortfolioLinkName(portfolioLink.ancestors)}
                    />
                    <GridTable.Cell
                      content={
                        formatCurrency(portfolioLink.value?.total) ?? '$--'
                      }
                      textAlign="right"
                    />
                    <GridTable.Cell
                      content={
                        typeof portfolioLink.percentage === 'number'
                          ? formatPercentage(
                              portfolioLink.percentage / 100,
                              'INTEGER',
                            )
                          : '0%'
                      }
                      textAlign="right"
                    />
                    {hasOpenMarginCall === true && (
                      <GridTable.Cell
                        content={
                          <PortfolioSliceOrderLink
                            isFromHoldingsPositionDetail
                            isSellOrder
                            onClick={onSellLinkClick}
                            portfolioSlice={portfolioLink}
                            previousRouteName={previousRouteName}
                          />
                        }
                        textAlign="right"
                      />
                    )}
                  </GridTable.Row>
                );
              }
            })}
          </GridTable>
        </>
      )}
    </>
  );
};

function readPortfolioLinkName(ancestors: PortfolioLinkFragment['ancestors']) {
  if (!ancestors) {
    return null;
  }

  const numberOfAncestors = ancestors.length;
  if (numberOfAncestors === 1) {
    return <PL content={ancestors[0]?.to.name} />;
  } else if (numberOfAncestors === 2) {
    return (
      <>
        <PL content={ancestors[1]?.to.name} />
        <PS content={ancestors[0]?.to.name} />
      </>
    );
  } else if (numberOfAncestors === 3) {
    return (
      <>
        <PL content={ancestors[2]?.to.name} />
        <PS
          content={`${ancestors[0]?.to.name ?? ''}/${
            ancestors[1]?.to.name ?? ''
          }`}
        />
      </>
    );
  } else if (numberOfAncestors > 3) {
    return (
      <>
        <PS content={ancestors[numberOfAncestors - 1]?.to.name} />
        <PS
          content={`${ancestors[0]?.to.name ?? ''}/.../${
            ancestors[numberOfAncestors - 2]?.to.name ?? ''
          }`}
        />
      </>
    );
  }

  return '--';
}
