import { PL } from '@m1/liquid-react';
import * as React from 'react';

import { Gain } from '~/components/gain';
import { PercentGain } from '~/components/percent-gain';
import { SliceableNameCell } from '~/components/pie';
import { SliceAvatarIndicator } from '~/components/pie/SliceAvatarIndicator';
import type {
  ChildPortfolioSlice,
  SliceableCellFragment,
  SortDirectionEnum,
} from '~/graphql/types';
import type { PortfolioSliceSortKey } from '~/portfolio-slice';
import { highlightSlice } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { GridTable, type GridTableHeaderCellProps } from '~/toolbox/grid-table';
import { formatCurrency } from '~/utils/formatting';

import { PortfolioSliceTargetActual } from './PortfolioSliceTargetActual';

export type PortfolioSliceChildrenTableProps = {
  colors: Map<
    string,
    {
      activeColor: string;
      inactiveColor: string;
    }
  >;
  children: ChildPortfolioSlice[];
  onChangeSort: (k: PortfolioSliceSortKey) => void;
  sort: (...args: Array<any>) => any;
  sortDirection: SortDirectionEnum;
  sortKey: PortfolioSliceSortKey;
};

export const PortfolioSliceChildrenTable = ({
  colors,
  children,
  onChangeSort,
  sort,
  sortDirection,
  sortKey,
}: PortfolioSliceChildrenTableProps) => {
  const dispatch = useDispatch();
  const highlightedSliceId = useSelector<string | null | undefined>(
    (state) => state.interface.highlightedSliceId,
  );

  const collectHeaderCellSortProps = (
    key: PortfolioSliceSortKey,
  ): GridTableHeaderCellProps => ({
    sortDirection: key === sortKey ? sortDirection : null,
    onClick: () => onChangeSort(key),
  });

  const sortedChildren = children.filter(Boolean).sort(sort);

  return (
    <GridTable gridTemplateColumns="230px 12% 20% auto">
      <GridTable.HeaderRow>
        <GridTable.HeaderCell
          {...collectHeaderCellSortProps('to.name')}
          label="Name"
        />
        <GridTable.HeaderCell
          {...collectHeaderCellSortProps('value.total')}
          align="right"
          label="Value"
        />
        <GridTable.HeaderCell
          {...collectHeaderCellSortProps(
            'performance.moneyWeightedRateOfReturn',
          )}
          align="right"
          label="Gain / Return"
        />
        <GridTable.HeaderCell
          {...collectHeaderCellSortProps('percentage')}
          align="right"
          justifySelf="end"
          label="Actual / Target"
        />
      </GridTable.HeaderRow>
      {sortedChildren.map((child) => {
        if (!child) {
          return null;
        }

        const leftBorderColor = colors.get(child.id);

        return (
          <GridTable.NavigableRow
            isHighlighted={highlightedSliceId === child.id}
            key={child?.id}
            leftBorderColor={leftBorderColor && leftBorderColor['activeColor']}
            onMouseEnter={() => {
              dispatch(highlightSlice(child?.id));
            }}
            onMouseLeave={() => {
              dispatch(highlightSlice(null));
            }}
            params={{
              portfolioSliceId: child?.id,
            }}
            to="/d/invest/portfolio/:portfolioSliceId"
          >
            <GridTable.Cell>
              <SliceableNameCell
                indicators={<SliceAvatarIndicator portfolioSlice={child} />}
                sliceable={child.to as unknown as SliceableCellFragment}
              />
            </GridTable.Cell>
            <GridTable.Cell textAlign="right">
              <PL
                content={formatCurrency(child.value?.total) ?? '$--'}
                fontWeight={600}
              />
            </GridTable.Cell>
            <GridTable.Cell textAlign="right">
              <Gain value={child.performance && child.performance.totalGain} />
              <br />
              <PercentGain
                value={
                  child.performance &&
                  child.performance.moneyWeightedRateOfReturn
                }
              />
            </GridTable.Cell>
            <GridTable.Cell textAlign="right">
              <PortfolioSliceTargetActual portfolioSlice={child} />
            </GridTable.Cell>
          </GridTable.NavigableRow>
        );
      })}
    </GridTable>
  );
};
