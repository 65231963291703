import { Box, Button, HM, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useInvestActivityPageQuery } from '~/graphql/hooks';
import type { InvestActivityEntryFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useParams } from '~/hooks/useParams';
import { Spinner } from '~/toolbox/spinner';

import { InvestActivityTradeSummaryEntryDetails } from './components';
import { ActivityEntryCardDetails } from './components/ActivityEntryCardDetails';

export const InvestActivityPage = () => {
  const navigate = useNavigate();
  const { activityId } = useParams();

  const { data, loading } = useInvestActivityPageQuery({
    variables: {
      id: activityId as string,
    },
    skip: !activityId,
  });

  if (loading) {
    return <Spinner />;
  }

  if (!activityId) {
    return null;
  }

  if (!data?.node) {
    return <GenericSystemError />;
  }

  const activity = data.node as InvestActivityEntryFragment;

  return (
    <Box
      m="0 auto"
      p="64px 0 100px 0"
      width={
        activity.__typename === 'InvestActivityTradeSummaryEntry' ? 836 : 620
      }
    >
      <HM content={activity.title} mb={32} />
      <Card mb={32} p={32}>
        <ActivityEntryCardDetails activity={activity} />
      </Card>
      {activity.__typename === 'InvestActivityTradeSummaryEntry' ? (
        <InvestActivityTradeSummaryEntryDetails />
      ) : null}

      <Button
        kind="primary"
        label="Done"
        onClick={() => {
          navigate({ to: '/d/invest/activity' });
        }}
        size="large"
        mb={16}
        mt={64}
      />
    </Box>
  );
};
