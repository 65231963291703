import { useTheme, type Color } from '@m1/liquid-react';
import * as React from 'react';

import type { SystemPieCategoryDetails } from '~/graphql/types';
import { Logo } from '~/toolbox/logo';

type SystemPieLogoProps = {
  name: string;
  logoUrl: string | null | undefined;
  categorizationDetails: Pick<SystemPieCategoryDetails, 'logoUrl'>[];
};

export const SystemPieLogo = ({
  name,
  logoUrl,
  categorizationDetails,
}: SystemPieLogoProps) => {
  const { colors } = useTheme();
  const backgroundColor = colors.backgroundNeutralMain as Color;
  const placeholder = name.slice(0, 3);
  const content = logoUrl || categorizationDetails[0].logoUrl;

  return <Logo {...{ backgroundColor, placeholder, content }} />;
};
