import React from 'react';

import { useWizard, WizardProvider } from '~/flows/wizard';

import { AutoPayValidationStep } from './steps/AutoPayValidationStep';
import { PaymentDetailsStep } from './steps/PaymentDetailsStep';
import { PaymentReceiptStep } from './steps/PaymentReceiptStep';
import { PaymentValidationStep } from './steps/PaymentValidationStep';

export type CreatePaymentWizardStep =
  | 'PAYMENT_DETAILS'
  | 'PAYMENT_VALIDATION'
  | 'AUTOPAY_VALIDATION'
  | 'PAYMENT_RECEIPT';

export const CreatePaymentWizard = (pageContext: any) => {
  const steps = {
    PAYMENT_DETAILS: <PaymentDetailsStep />,
    PAYMENT_VALIDATION: <PaymentValidationStep />,
    AUTOPAY_VALIDATION: <AutoPayValidationStep />,
    PAYMENT_RECEIPT: <PaymentReceiptStep />,
  };

  const { step, ...wizardContext } = useWizard<CreatePaymentWizardStep>(steps);

  return (
    <WizardProvider value={{ step, ...wizardContext, ...pageContext }}>
      {step}
    </WizardProvider>
  );
};
