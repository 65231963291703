import { PL } from '@m1/liquid-react';
import startCase from 'lodash-es/startCase';
import React from 'react';

import type { PieRiskLevelEnum } from '~/graphql/types';
import { Rating } from '~/toolbox/rating';

type PieRiskLevelProps = {
  riskGrade: number;
  riskLevel: PieRiskLevelEnum;
  size: 'small' | 'medium' | 'large';
};

export const PieRiskLevel = ({
  riskGrade,
  riskLevel,
  size,
}: PieRiskLevelProps) => {
  if (!riskGrade || !riskLevel) {
    return <PL>--</PL>;
  }
  const ratingLabel = startCase(riskLevel).replace(/_/g, '-');

  return (
    <Rating
      maxRating={5}
      rating={riskGrade}
      ratingLabel={ratingLabel}
      size={size}
    />
  );
};
