import { Box, Button } from '@m1/liquid-react';
import React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const AutoPayButton = ({
  isSchedule,
  isPersonalLoanPayment,
  isPersonalLoansAutoPayEnabled,
  creditAutoPayInstanceId,
  personalLoanAutoPayInstanceId,
  onSwitchModes,
}: {
  isSchedule: boolean;
  isPersonalLoanPayment: boolean;
  isPersonalLoansAutoPayEnabled: boolean;
  creditAutoPayInstanceId: Maybe<string>;
  personalLoanAutoPayInstanceId: Maybe<string>;
  onSwitchModes: () => void;
}) => {
  const navigate = useNavigate();

  const transferRuleId =
    creditAutoPayInstanceId ?? personalLoanAutoPayInstanceId;

  const onClick = React.useCallback(() => {
    if (transferRuleId) {
      navigate({
        to: '/d/rule-details/:transferRuleId',
        params: { transferRuleId },
      });
    } else {
      onSwitchModes();
    }
  }, [transferRuleId, navigate, onSwitchModes]);

  if (isPersonalLoanPayment && !isPersonalLoansAutoPayEnabled) {
    return null;
  }

  if (isSchedule && !transferRuleId) {
    return null;
  }

  return (
    <Box mt={16} mb={32}>
      <Button kind="text" onClick={onClick} style={{ paddingLeft: 0 }}>
        {transferRuleId ? 'View AutoPay Details' : 'Enable AutoPay'}
      </Button>
    </Box>
  );
};
