import {
  type ThemeMode,
  Box,
  PM,
  themeModes,
  useThemeMode,
  HXXS,
} from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import { Radio } from '~/toolbox/radio';

export const SettingsAppearanceSection = () => {
  const { themeMode, setThemeMode } = useThemeMode();

  const handleThemeChange = (value: ThemeMode) => {
    setThemeMode(value);
  };

  return (
    <Box maxWidth={600} mb={64}>
      <HXXS fontWeight={300} content="Theme" mb={8} />
      <PM fontWeight={400}>
        By default, M1 uses your browser appearance as defined under Settings.
        However, you can choose either Light or Dark modes to match your
        preference.
      </PM>
      <Box pt={40} pb={16}>
        <Radio.Group value={themeMode} onChange={handleThemeChange}>
          {themeModes.map((mode) => (
            <Radio.Choice label={capitalize(mode)} value={mode} key={mode} />
          ))}
        </Radio.Group>
      </Box>
    </Box>
  );
};
