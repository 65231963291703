import React from 'react';

import {
  useResubmitLoanAfterCreditFreezeMutation,
  usePersonalLoansApplicationByIdSagaQuery,
} from '~/graphql/hooks';
import type { LoanApplicationStatusEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { usePollTimer } from '~/hooks/usePollTimer';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { Loader } from '../components/Loader';
import { RejectedCreditFrozenForm } from '../components/RejectedCreditFrozenForm';
import { GENERAL_ERROR_MESSAGE, POLL_INTERVAL, POLL_LIMIT } from '../constants';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import type { PersonalLoanDirectSteps } from '../types';
import { getApplicationStatusRoute } from '../utils/getApplicationStatusRoute';

export const RejectedCreditFrozen = () => {
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const navigate = useNavigate();
  const { addToast } = useToast();
  const analytics = useAnalytics();
  const { goTo } = usePersonalLoanDirectWizardContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [shouldStartApplicationPollTimer, setShouldStartApplicationPollTimer] =
    React.useState<boolean>(false);
  const { isPollLimitReached } = usePollTimer({
    shouldStartPollTimer: shouldStartApplicationPollTimer,
    pollInterval: POLL_INTERVAL,
    pollLimit: POLL_LIMIT,
  });

  const [resubmitLoanAfterCreditFreeze] =
    useResubmitLoanAfterCreditFreezeMutation();
  const {
    data: personalLoanApplicationData,
    startPolling: startPollingApplication,
    stopPolling: stopPollingApplication,
  } = usePersonalLoansApplicationByIdSagaQuery({
    variables: {
      applicationId,
    },
  });

  const loanId =
    personalLoanApplicationData?.viewer.borrow?.personalLoans?.application?.loan
      ?.id;

  const handleResubmitLoanAfterCreditFreeze = () => {
    setLoading(true);
    resubmitLoanAfterCreditFreeze({
      variables: {
        input: {},
      },
      onCompleted: () => {
        // Once resubmitLoanAfterCreditFreeze is complete, start polling for application status
        setShouldStartApplicationPollTimer(true);
        startPollingApplication(POLL_INTERVAL);
      },
      onError: () => {
        setLoading(false);
        addToast({
          content: GENERAL_ERROR_MESSAGE,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  React.useEffect(() => {
    const statusesIgnore: LoanApplicationStatusEnum[] = [
      'OFFER_SELECTION_QUEUED',
      'QUEUED',
      'REJECTED_CREDIT_FROZEN',
    ];
    const status =
      personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
        ?.status?.status;

    if (
      (status && !statusesIgnore?.includes(status)) ||
      (isPollLimitReached && status)
    ) {
      // If a status is returned and it's a status we can take action on (or if the timer limit is reached), stop polling the application status query and setLoading to false (so loader stops spinning).
      stopPollingApplication();
      setShouldStartApplicationPollTimer(false);
      setLoading(false);

      const { route, isWizardStep } = getApplicationStatusRoute(status);
      if (isWizardStep) {
        goTo(route as keyof PersonalLoanDirectSteps);
      } else {
        navigate({ to: route });
      }
    }
  }, [
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.status?.status,
    isPollLimitReached,
  ]);

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application-error/credit-frozen');
  }, [analytics]);

  return loading ? (
    <Loader
      heading="We are resubmitting your application."
      content="This may take up to a minute."
    />
  ) : (
    <RejectedCreditFrozenForm
      handleResubmitLoanAfterCreditFreeze={handleResubmitLoanAfterCreditFreeze}
      applicationId={applicationId}
      loanId={loanId}
    />
  );
};
