import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { IdentityFirstFlow } from '~/flows';
import { useSearchParams } from '~/hooks/useSearchParams';

import { Container } from '~/toolbox/container';

export const IdentityFirstWrapper = () => {
  const [queryParams] = useSearchParams();

  const basePath = 'onboarding/setup-account';
  const registration = queryParams.get('registration');

  return (
    <Container>
      <Box mt={32}>
        <IdentityFirstFlow basePath={basePath} registration={registration} />
      </Box>
    </Container>
  );
};
