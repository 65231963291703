import { PS } from '@m1/liquid-react';
import * as React from 'react';

import { submittedLiquidNetWorth } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { isNotNil } from '~/utils';

import {
  SingleSelectButtonInput,
  type SingleSelectButtonInputProps,
} from '../../SingleSelectButtonInput';

import {
  CollectProfileInputTableTooltip,
  type OnboardingTooltip,
} from './CollectProfileInputTableTooltip';

type BaseProps = SingleSelectButtonInputProps;

type Props = Pick<BaseProps, 'content' | 'field' | 'options' | 'question'> & {
  onClick?: BaseProps['onClick'];
  tooltip?: OnboardingTooltip;
};

export const CollectProfileInput = ({
  content,
  field,
  options,
  onClick,
  question,
  tooltip,
}: Props) => {
  const dispatch = useDispatch();

  const handleOptionClick = React.useCallback(
    (value: number | string) => {
      dispatch(
        submittedLiquidNetWorth({
          field,
          value,
        }),
      );
    },
    [field, dispatch],
  );

  return (
    <SingleSelectButtonInput
      field={field}
      question={question}
      tooltip={
        isNotNil(tooltip) ? (
          <>
            {tooltip.text?.map((text: string) => (
              <PS key={text} content={text} px={16} py={6} />
            ))}
            <CollectProfileInputTableTooltip table={tooltip.table} />
          </>
        ) : null
      }
      content={content}
      options={options}
      onClick={onClick ?? handleOptionClick}
    />
  );
};
