import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { SettingsAppearanceSection } from './appearance/SettingsAppearanceSection';
import { SettingsNotificationsSection } from './notifications/SettingsNotificationsSection';
import { SettingsPaymentsSection } from './payments/SettingsPaymentsSection';

export const SettingsPreferencesPage = () => {
  return (
    <Flex flexDirection="column">
      <Flex flexWrap="wrap" width="100%" columnGap={40}>
        <SettingsNotificationsSection />
        <SettingsPaymentsSection />
      </Flex>
      <Flex>
        <SettingsAppearanceSection />
      </Flex>
    </Flex>
  );
};
