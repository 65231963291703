import { Box, Text } from '@m1/liquid-react';
import * as React from 'react';

import { useInitiateResetPasswordMutation } from '~/graphql/hooks';
import type { InitiateResetPasswordInput } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';

import { useToast } from '~/toasts';
import { Link } from '~/toolbox/link';

import { InitiateResetPasswordForm } from './InitiateResetPasswordForm';

export const InitiateResetPasswordPage = () => {
  const { addToast } = useToast();
  const [resetPassword, { loading }] = useInitiateResetPasswordMutation();
  const navigate = useNavigate();

  usePortaledSpinner(loading);

  const onSubmit = (input: InitiateResetPasswordInput) => {
    resetPassword({
      variables: {
        input,
      },
      onCompleted: () => {
        navigate({ to: '/forgot-password/confirm' });
      },
      onError: (error) => {
        addToast({
          content: error.message,
          kind: 'alert',
        });
      },
    });
  };

  return (
    <Box>
      <InitiateResetPasswordForm onSubmit={onSubmit} />
      <Box pt={32}>
        <Text content={<Link to="/login">Back to Login</Link>} />
      </Box>
    </Box>
  );
};
