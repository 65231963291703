import { Box, type BoxProps } from '@m1/liquid-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import type { TransferParticipantRelationshipFragment } from '~/graphql/types';
import { PillGroup, Pill } from '~/toolbox/Pill';
import { isNil, isNotNil } from '~/utils';
import { parseCurrency } from '~/utils/parseCurrency';

import type { PaymentFormValues } from './CreatePaymentForm';

export const PaymentAmountField = ({
  amountPills,
  isRequired,
  isDisabled,
  onChange,
  selectedRelationship,
  ...forwardedProps
}: BoxProps & {
  amountPills: Maybe<Maybe<{ label: string }>[]>;
  isRequired?: Maybe<boolean>;
  isDisabled?: Maybe<boolean>;
  onChange?: (value: string | string[] | number | undefined | null) => void;
  selectedRelationship: Maybe<TransferParticipantRelationshipFragment>;
}) => {
  const { control, setValue } = useFormContext<PaymentFormValues>();

  const [maxRule, minRule] = React.useMemo(() => {
    if (isNotNil(selectedRelationship)) {
      return [
        typeof selectedRelationship.max === 'number'
          ? {
              message: selectedRelationship.maxErrorMessage,
              value: selectedRelationship.max,
            }
          : null,
        typeof selectedRelationship.min === 'number'
          ? {
              message: selectedRelationship.minErrorMessage,
              value: selectedRelationship.min,
            }
          : null,
      ];
    }
    return [null, null];
  }, [selectedRelationship]);

  return (
    <Box {...forwardedProps}>
      <Box>
        <ControlledInput
          name="otherAmount"
          maskType="money"
          mask={{
            allowNegative: false,
          }}
          disabled={isDisabled ?? false}
          autoComplete="off"
          autoFocus={false}
          control={control}
          onChange={onChange}
          rules={{
            required: isRequired === true ? 'Required' : false,
            maxLength: 12,
            max: maxRule ?? undefined,
            min: minRule ?? undefined,
          }}
        />
      </Box>
      <Box justifyContent="center" mt={4}>
        <PillGroup justifyContent="center">
          {amountPills?.map((pill) => {
            if (isNil(pill?.label)) {
              return null;
            }
            return (
              <Pill
                key={`pill-${pill.label}`}
                label={pill?.label}
                size="large"
                kind="interactive"
                onClick={() => {
                  setValue(
                    'otherAmount',
                    parseCurrency(pill?.label) ?? undefined,
                  );
                }}
              />
            );
          })}
        </PillGroup>
      </Box>
    </Box>
  );
};
