import { Text, styled, css, Skeleton, type BoxProps } from '@m1/liquid-react';
import * as React from 'react';

import { GridContext } from './context';

type SharedProps = { isNewStyle?: boolean; isCaretLeftCell?: boolean };

export type GridTableCellProps = React.PropsWithChildren<
  SharedProps &
    BoxProps & {
      content?: React.ReactNode;
      className?: string;
    }
>;

export const StyledGridTableCell = styled(Skeleton)<SharedProps>`
  margin: ${({ isNewStyle }) => (isNewStyle ? '8px' : '14px')} 0;
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  background: ${({ theme, isCaretLeftCell }) =>
    isCaretLeftCell && `${theme.colors.backgroundNeutralSecondary}`};

  ${({ isCaretLeftCell }) =>
    isCaretLeftCell &&
    css`
      height: 100%;
      align-content: center;
      color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
      animation-delay: 0.25s;
      animation: 0.75s expand;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};

      @keyframes expand {
        from {
          width: 0;
          padding-left: 16px;
        }
        to {
          width: 32px;
          padding-left: 0;
        }
      }
    `}
`;

export const GridTableCell = ({
  alignSelf,
  content,
  children,
  isCaretLeftCell,
  ...props
}: GridTableCellProps) => {
  const { defaultAlignSelf, isNewStyle } = React.useContext(GridContext);

  const renderedContent =
    typeof content === 'string' ? (
      <Text content={content} />
    ) : (
      (content ?? children ?? null)
    );

  return (
    <StyledGridTableCell
      data-testid="table-grid-cell"
      alignSelf={alignSelf ?? defaultAlignSelf}
      isNewStyle={isNewStyle}
      isCaretLeftCell={isCaretLeftCell}
      skeletonWidth="100%"
      skeletonHeight={`calc(100% - ${isNewStyle ? '8px' : '14px'})`}
      {...props}
    >
      {renderedContent}
    </StyledGridTableCell>
  );
};
