import * as React from 'react';

import {
  IdentityFirstConfirmationScreenSection,
  type SectionRowData,
} from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenSection';
import type {
  OnlineAccountRegistrationEnum,
  TrustedContact,
} from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { getEnumDescription } from '~/utils';

import {
  formatTrustedContactAsAnArray,
  accountTypeFromAccountRegistrationMapping,
} from './InvestConfirmationHelpers';

type InvestAccountSectionComponentProps = {
  onClickEdit: () => void;
};

type InvestAccountData = {
  investmentExperience: string;
  liquidityNeeds: string;
  riskTolerance: string;
  timeHorizon: string;
  subProduct: OnlineAccountRegistrationEnum | null | undefined;
  trustedContact: TrustedContact | null;
};

function getKeyValuePairsFromInput(
  input: InvestAccountData,
): Array<SectionRowData> {
  const accountType = input.subProduct
    ? accountTypeFromAccountRegistrationMapping[input.subProduct]
    : null;

  const firstItem = accountType
    ? [{ label: 'Account type', value: accountType }]
    : [];

  return [
    ...firstItem,
    {
      label: 'Investment experience',
      value: getEnumDescription(
        input.investmentExperience,
        'InvestmentExperienceEnum',
      ),
    },
    {
      label: 'Risk tolerance',
      value: getEnumDescription(input.riskTolerance, 'RiskToleranceEnum'),
    },
    {
      label: 'Time horizon',
      value: getEnumDescription(input.timeHorizon, 'TimeHorizonEnum'),
    },
    {
      label: 'Liquidity needs',
      value: getEnumDescription(input.liquidityNeeds, 'LiquidityNeedsEnum'),
    },
    ...(input.trustedContact
      ? [
          {
            label: 'Trusted contact',
            value: formatTrustedContactAsAnArray(input.trustedContact),
          },
        ]
      : []),
  ];
}

export const InvestAccountSection = ({
  onClickEdit,
}: InvestAccountSectionComponentProps) => {
  const investAccountData = useSelector<InvestAccountData>((state) => {
    return {
      investmentExperience:
        state.newFlows.INVEST_ONBOARDING.input.suitability.investmentExperience,
      riskTolerance:
        state.newFlows.INVEST_ONBOARDING.input.suitability.riskTolerance,
      timeHorizon:
        state.newFlows.INVEST_ONBOARDING.input.suitability.timeHorizon,
      liquidityNeeds:
        state.newFlows.INVEST_ONBOARDING.input.suitability.liquidityNeeds,
      subProduct: state.newFlows.onboarding.subProduct,
      trustedContact: state.newFlows.INVEST_ONBOARDING.input.trustedContact,
    };
  });

  return (
    <IdentityFirstConfirmationScreenSection
      headingLabel="Investment account details"
      editLabel="Edit"
      rows={getKeyValuePairsFromInput(investAccountData)}
      onEdit={onClickEdit}
    />
  );
};
