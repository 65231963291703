import { Box, Button } from '@m1/liquid-react';
import React from 'react';

import type { PageInfoFragment } from '~/graphql/types';

type LoadMoreButtonProps = {
  loading: boolean;
  handleLoadMore: () => void;
  pageInfo: PageInfoFragment | null | undefined;
};
export const LoadMoreButton = ({
  loading,
  handleLoadMore,
  pageInfo,
}: LoadMoreButtonProps) => {
  const hasNextPage = pageInfo?.hasNextPage ?? false;
  const disabled = loading || !hasNextPage;

  return (
    <Box pt={16} textAlign="center">
      <Button
        label="Load More"
        kind="primary"
        size="small"
        onClick={handleLoadMore}
        disabled={disabled}
      />
    </Box>
  );
};
