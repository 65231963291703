import {
  Flex,
  styled,
  type LayoutableProps,
  spacingUnits,
  Button,
} from '@m1/liquid-react';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledAmountInput } from '~/components/form/Inputs/ControlledAmountInput';
import { type TransferParticipantRelationshipFragment } from '~/graphql/types';

import { TransferContext } from '../TransferContext';

const StyledTransferAmountContainer = styled(Flex)`
  align-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${spacingUnits.l}px;
`;

export function TransferAmountInputField({
  selectedRelationship,
  ...rest
}: LayoutableProps & {
  selectedRelationship: Maybe<TransferParticipantRelationshipFragment>;
}) {
  const transferContext = useContext(TransferContext);

  const { control, setValue } = useFormContext();

  const [maxRule, minRule] = React.useMemo(() => {
    if (selectedRelationship) {
      return [
        typeof selectedRelationship.max === 'number'
          ? {
              message: selectedRelationship.maxErrorMessage,
              value: selectedRelationship.max,
            }
          : null,
        typeof selectedRelationship.min === 'number'
          ? {
              message: selectedRelationship.minErrorMessage,
              value: selectedRelationship.min,
            }
          : null,
      ];
    }
    return [null, null];
  }, [selectedRelationship]);

  const amountPills = (() => {
    // scenarios do not support amount pills
    if (
      transferContext?.scenarioQueryParam === 'IRA_CONVERSION' ||
      transferContext?.scenarioQueryParam === 'IRA_RECHARACTERIZATION'
    ) {
      return null;
    }
    if (!transferContext?.viewer.transfers?.defaultAmountPills) {
      return null;
    }

    const pills = transferContext?.viewer.transfers?.defaultAmountPills.flatMap(
      (pill) => pill ?? [],
    );

    return pills;
  })();

  return (
    <StyledTransferAmountContainer {...rest}>
      <ControlledAmountInput
        autoFocus
        control={control}
        name="amount"
        rules={{
          max: maxRule ?? undefined,
          min: minRule ?? undefined,
          required: true,
        }}
        style={{ paddingBlock: '0' }}
      />
      {amountPills && (
        <Flex gap="16px" justifyContent="center">
          {amountPills.map((pill, i) => (
            <Button
              kind="inverse-primary"
              size="small"
              key={`pill-${pill.label}-${i}`}
              onClick={() => {
                const amount = parseFloat(pill.label.replace(/[$,]/g, ''));
                setValue('amount', amount, { shouldValidate: true });
              }}
            >
              {pill.label}
            </Button>
          ))}
        </Flex>
      )}
    </StyledTransferAmountContainer>
  );
}
