import {
  Box,
  Flex,
  HXXS,
  PL,
  PM,
  Skeleton,
  SkeletonProvider,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { useEarnProductLandingPageDetailsQuery } from '~/graphql/hooks';
import type {
  DetailRow,
  EarnProductLandingPageDetailsQuery,
} from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import {
  StyledDetailsCard,
  StyledDetailsCardContainer,
  StyledDetailsCardDivider,
} from '~/pages/dashboard/common/ProductLandingPageCard.styled';

const getRowContent = ({
  row,
  accountId,
  unmaskedValues,
  setUnmaskedValues,
}: {
  row: DetailRow;
  accountId: string;
  unmaskedValues: string[];
  setUnmaskedValues: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <>
      <PM
        fontWeight={400}
        content={
          !row.valueMasked ||
          unmaskedValues.includes(`${row.label}-${accountId}`)
            ? row.value
            : row.valueMasked
        }
        py={8}
      />
      {row.icon && !unmaskedValues.includes(`${row.label}-${accountId}`) && (
        <AppImage
          css={{ cursor: 'pointer' }}
          appImage={row.icon}
          ml={4}
          onClick={() =>
            setUnmaskedValues((unmaskedValues) => [
              ...unmaskedValues,
              `${row.label}-${accountId}`,
            ])
          }
        />
      )}
      {row.iconClicked &&
        unmaskedValues.includes(`${row.label}-${accountId}`) && (
          <AppImage
            css={{ cursor: 'pointer' }}
            appImage={row.iconClicked}
            ml={4}
            onClick={() =>
              setUnmaskedValues((unmaskedValues) =>
                unmaskedValues.filter(
                  (value) => value !== `${row.label}-${accountId}`,
                ),
              )
            }
          />
        )}
    </>
  );
};

export const EarnDetailsCard = ({
  accountId,
  selectedTransactionId,
}: {
  accountId: string;
  selectedTransactionId: string | null;
}) => {
  const [unmaskedValues, setUnmaskedValues] = React.useState<string[]>([]);

  const { data, loading } = useEarnProductLandingPageDetailsQuery({
    variables: {
      id: accountId,
    },
  });

  React.useEffect(() => {
    // Reset unmasked values when data changes
    setUnmaskedValues([]);
  }, [data, selectedTransactionId]);

  const node = data?.node as ExtractTypename<
    EarnProductLandingPageDetailsQuery['node'],
    'SavingsAccount'
  >;

  const title = node?.earnProductLandingPage?.accountDetailsCard?.title || '';
  const details =
    node?.earnProductLandingPage?.accountDetailsCard?.details || [];
  const disclaimers = node?.earnProductLandingPage?.disclaimers;

  return (
    <SkeletonProvider isLoading={loading}>
      <StyledDetailsCardContainer>
        <Skeleton skeletonWidth="100%" skeletonHeight="400px">
          <Card
            minHeight={190}
            p={24}
            mb={16}
            backgroundColor="backgroundNeutralSecondary"
          >
            <HXXS fontWeight={300} content={title} mb={16} />
            {details?.map((detail, i) => {
              return (
                <Box key={`earn-detail-group-${i}`}>
                  <PL fontWeight={400} content={detail.header} py={16} />
                  {detail.rows?.map((row, j) => {
                    return (
                      <Box key={`earn-detail-row-${j}`}>
                        <StyledDetailsCard
                          py={8}
                          minHeight={48}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <PM
                            fontWeight={400}
                            content={row.label}
                            py={8}
                            color="foregroundNeutralSecondary"
                          />
                          <Flex alignItems="center">
                            {getRowContent({
                              row,
                              accountId,
                              unmaskedValues,
                              setUnmaskedValues,
                            })}
                          </Flex>
                        </StyledDetailsCard>
                        {j !== (detail.rows?.length ?? 0) - 1 && (
                          <StyledDetailsCardDivider />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Card>
          <>
            {disclaimers?.map((disclaimer, idx) => (
              <PM
                key={idx}
                fontWeight={400}
                content={disclaimer}
                color="foregroundNeutralSecondary"
              />
            ))}
          </>
        </Skeleton>
      </StyledDetailsCardContainer>
    </SkeletonProvider>
  );
};
