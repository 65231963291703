import { Flex, LM, PM, styled, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import type { SecurityDetail_Fund_Fragment } from '~/graphql/types';
import { highlightSlice } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Grid } from '~/toolbox/grid';
import { Pie } from '~/toolbox/pie';
import { generateSliceFillColorsArray } from '~/utils/slices';

type FundAllocationsProps = {
  fund: SecurityDetail_Fund_Fragment;
};

export const FundAllocations = ({ fund }: FundAllocationsProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const highlightedSlice = useSelector(
    (state) => state.interface.highlightedSliceId,
  );
  const allocations =
    fund.fundFundamentals?.assetAllocations?.filter(
      ({ name, percentage }) => name && percentage,
    ) || [];

  if (allocations.length === 0) {
    return null;
  }

  const sorted = allocations
    .map((alloc) => ({
      ...alloc,
      percentage: alloc.percentage || 0,
    }))
    .sort((a, b) => {
      const aPercentage = a.percentage;
      const bPercentage = b.percentage;

      return aPercentage < bPercentage ? 1 : -1;
    });

  const themeSliceColors = theme.pieSliceColors;
  const sliceFillColorsArray = generateSliceFillColorsArray(
    themeSliceColors,
    allocations.length,
  );

  const StyledFlexContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
      flex-wrap: wrap;
    }
  `;

  const StyledFlex = styled(Flex)`
    flex-direction: column;
    width: 60%;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
      width: 100%;
    }
  `;

  return (
    <StyledFlexContainer>
      <Flex>
        <Pie
          data={sorted.map((fi) => ({
            id: null,
            percentage: fi.percentage,
          }))}
          height={250}
          highlightSlice={(id: string | null | undefined) =>
            dispatch(highlightSlice(id))
          }
          highlightedSlice={highlightedSlice}
          innerRadius={80}
          padding="20px"
          theme={theme}
          width={250}
        />
      </Flex>
      <StyledFlex>
        {sorted.map(({ name, percentage }, index) => {
          return (
            <Grid.Row
              key={name}
              style={{
                paddingBottom: 4,
              }}
            >
              <Grid.Col xs={8}>
                <span
                  style={{
                    display: 'inline-block',
                    width: 10,
                    height: 10,
                    marginRight: 8,
                    backgroundColor:
                      sliceFillColorsArray[index % sliceFillColorsArray.length][
                        'active'
                      ],
                  }}
                />
                <LM color="foregroundNeutralMain" content={name} />
              </Grid.Col>
              <Grid.Col xs={4} xsTextRight>
                <PM fontWeight={600} content={`${percentage || 0}%`} />
              </Grid.Col>
            </Grid.Row>
          );
        })}
      </StyledFlex>
    </StyledFlexContainer>
  );
};
