import { Text } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

import type { SelectProps } from './Dropdown.types';

type SingleValueProps = {
  children?: React.ReactNode;
  className: string;
  data: Record<string, any>;
  selectProps: SelectProps;
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectSingleValue = components.Option as any;

export const SingleValue = ({
  data,
  selectProps,
  ...rest
}: SingleValueProps) => {
  const { displayOptionValue, isDisabled } = selectProps;

  return (
    <ReactSelectSingleValue {...rest}>
      <Text
        content={displayOptionValue ? data.value : data.label}
        color={
          isDisabled ? 'foregroundNeutralTertiary' : 'foregroundNeutralMain'
        }
      />
    </ReactSelectSingleValue>
  );
};
