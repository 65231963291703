import type {
  GetSecurityHistoricalQuotesQueryVariables,
  HistoricalQuotePeriodEnum,
  IntradayQuotePeriodEnum,
} from '~/graphql/types';

export const INITIAL_VARIABLES: Omit<
  GetSecurityHistoricalQuotesQueryVariables,
  'id'
> = {
  securityPeriod: 'THREE_MONTHS',
  intradayPeriod: 'LATEST_DAY',
  intradayInterval: 'ONE_MINUTE',
  useSecurityPeriod: false,
  useIntradayPeriod: true,
  isCrypto: false,
};

export const PERIODS: {
  label: string;
  value: HistoricalQuotePeriodEnum | IntradayQuotePeriodEnum;
}[] = [
  {
    label: '1D',
    value: 'LATEST_DAY',
  },
  {
    label: '1W',
    value: 'LATEST_WEEK',
  },
  {
    label: '1M',
    value: 'ONE_MONTH',
  },
  {
    label: '3M',
    value: 'THREE_MONTHS',
  },
  {
    label: '6M',
    value: 'SIX_MONTHS',
  },
  {
    label: 'YTD',
    value: 'YEAR_TO_DATE',
  },
  {
    label: '1Y',
    value: 'ONE_YEAR',
  },
  {
    label: '2Y',
    value: 'TWO_YEARS',
  },
  {
    label: '5Y',
    value: 'FIVE_YEARS',
  },
];
