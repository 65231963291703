import { Box, css, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import classNames from 'classnames';
import React, { forwardRef, type HTMLAttributes } from 'react';

import type { FlattenedItem } from '../../../PieEditor.types';
import { Action, Handle } from '../../components';

import { SliceableContent } from '../SliceableContent/SliceableContent';

import styles from './TreeItem.module.scss';

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  draggable?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  item: FlattenedItem;
  isDragging?: boolean;
  onCollapse?(all: boolean): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
}

const StyledTreeItem = styled(Box)<{ $depth: number; $isFirstRow: boolean }>`
  ${({ $depth, theme, $isFirstRow }) => {
    const borderColor =
      $depth <= 1 || ($depth === 2 && $isFirstRow)
        ? theme.colors.borderMain
        : theme.colors.backgroundNeutralTertiary;
    return css`
      border-bottom: 1px solid ${borderColor};
      border-top: 1px solid ${borderColor};
    `;
  }}
`;

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      draggable = true,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      isDragging,
      collapsed,
      onCollapse,
      onRemove,
      style,
      item,
      wrapperRef,
      ...props
    },
    ref,
  ) => {
    const isSecurity = !item.allowsChildren && item.meta.type !== 'add';
    const isPie = item.allowsChildren && item.meta.type !== 'add';
    const isAdd = item.meta.type === 'add';
    const isDraggable = draggable && !item.hasOrder;
    const isRoot = depth === 0;
    return (
      <li
        className={classNames(
          styles.Wrapper,
          clone && styles.clone,
          ghost && styles.ghost,
          indicator && styles.indicator,
          disableSelection && styles.disableSelection,
          disableInteraction && styles.disableInteraction,
        )}
        ref={wrapperRef}
        style={
          {
            '--spacing': `${indentationWidth * depth}px`,
            scrollMarginTop: '96px',
          } as React.CSSProperties
        }
        {...props}
        id={String(item.id)}
      >
        <StyledTreeItem
          className={styles.TreeItem}
          ref={ref}
          style={style}
          $depth={depth}
          $isFirstRow={item.meta.type === 'add'}
        >
          {/* Complicated logic below so the Logos are generally horizontally aligned. Basically if it's a security that can't be moved, we want the Handle to take up space but be hidden so the Logo is still indented
              However, if the slice is a pie that can't be moved, we need to not render the Handle at all so the collapse icon is taking up this space and the Logos still line up.
          */}
          {(isSecurity ||
            (isPie && !isAdd && !isRoot) ||
            (!isPie && !isAdd)) && (
            <Handle hidden={!isDraggable} {...handleProps} />
          )}
          {onCollapse && (
            <Action
              title={
                item.collapsed
                  ? `Hold ${navigator.userAgent.includes('Apple') ? '⌥ (Option)' : 'Alt'} while clicking to open all sub-pies!`
                  : undefined
              }
              onClick={(e) => onCollapse(e.altKey)}
              className={classNames(
                styles.Collapse,
                collapsed && styles.collapsed,
              )}
            >
              <Icon name="arrowDropDown20" color="foregroundNeutralMain" />
            </Action>
          )}
          <span className={styles.Text}>
            <SliceableContent item={item} isDragging={isDragging} />
          </span>
          {clone && childCount && childCount > 1 ? (
            <span className={styles.Count}>{childCount}</span>
          ) : null}
        </StyledTreeItem>
      </li>
    );
  },
);
