import { Button, Flex, Skeleton, spacingUnits } from '@m1/liquid-react';
import * as React from 'react';

type NotificationPreferencesFooterProps = {
  isSaveEnabled: boolean;
  handleSaveClick: () => Promise<void>;
};

export const NotificationPreferencesFooter = ({
  isSaveEnabled,
  handleSaveClick,
}: NotificationPreferencesFooterProps) => (
  <Flex
    mt={spacingUnits.s}
    mb={spacingUnits.xxl}
    height={42}
    alignItems="center"
  >
    <Skeleton display="flex">
      <Button disabled={!isSaveEnabled} onClick={handleSaveClick} size="medium">
        Save
      </Button>
    </Skeleton>
  </Flex>
);
