import type { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import { ACTION_TYPES } from '~/redux/actions';

import { updateUserData } from './common';

export function* updateUserDataSaga(): SagaIterator<void> {
  yield takeEvery(
    ACTION_TYPES.UPDATE_USER_DATA,
    function* (action: any): SagaIterator<void> {
      yield call(updateUserData, action.payload);
    },
  );
}
