import type { Theme } from '@m1/liquid-react';

import type { PillKind, PillSize } from './Pill.types';

export const collectKindStyles = (
  kind: PillKind,
  theme: Theme,
): {
  background?: string;
  backgroundColor?: string;
  color?: string;
} => {
  const pillTheme = theme.colors;
  switch (kind) {
    case 'bronze':
      return {
        background: 'linear-gradient(90deg, #E6CEC2 0%, #C8947A 100%)',
        color: pillTheme.foregroundNeutralOnLight,
      };
    case 'caution':
      return {
        backgroundColor: pillTheme.warning,
        color: pillTheme.foregroundNeutralOnLight,
      };
    case 'danger':
      return {
        backgroundColor: pillTheme.critical,
        color: pillTheme.foregroundNeutralOnDark,
      };
    case 'gold':
      return {
        background: 'linear-gradient(90deg, #EBD2AD 0%, #D6A55C 100%)',
        color: pillTheme.foregroundNeutralOnLight,
      };
    case 'important':
      return {
        backgroundColor: pillTheme.secondary,
        color: pillTheme.foregroundNeutralInverse,
      };
    case 'obsidian':
      return {
        background: `linear-gradient(90deg, #636363 0%, #212121 100%)`,
        color: pillTheme.m1Plus,
      };
    case 'platinum':
      return {
        background: 'linear-gradient(270deg, #9A9EA1 0%, #E2E9F2 92.16%)',
        color: pillTheme.foregroundNeutralOnLight,
      };
    case 'success':
      return {
        backgroundColor: pillTheme.success,
        color: pillTheme.foregroundNeutralOnDark,
      };
    case 'new':
      return {
        backgroundColor: pillTheme.success,
        color: pillTheme.foregroundNeutralOnDark,
      };
    case 'interactive':
      return {
        backgroundColor: pillTheme.backgroundPrimarySubtle,
        color: pillTheme.foregroundNeutralMain,
      };
    default:
      return {
        backgroundColor: pillTheme.backgroundNeutralTertiary,
        color: pillTheme.foregroundNeutralMain,
      };
  }
};

export const collectSizeStyles = (
  size: PillSize,
): {
  padding: string;
  fontSize: number;
  lineHeight?: number;
} => {
  switch (size) {
    case 'medium':
      return {
        padding: '5px 10px',
        fontSize: 14,
        lineHeight: 1,
      };
    case 'large':
      return {
        padding: '7px 14px',
        fontSize: 16,
        lineHeight: 1,
      };
    case 'announcement':
      return {
        padding: '4px 8px',
        fontSize: 12,
      };
    default:
      return {
        padding: '0 8px',
        fontSize: 12,
        lineHeight: 1.5,
      };
  }
};
