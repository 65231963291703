import { Card, type LayoutableProps, PM } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { TransferRequirements } from '../utils/useTransferRequirements';

export function TransferTimingMessage({
  transferRequirements,
  ...rest
}: LayoutableProps & {
  transferRequirements: TransferRequirements;
}) {
  if (!transferRequirements?.timingDescription) {
    return null;
  }
  return (
    <Card
      alignItems="center"
      backgroundColor="backgroundNeutralMain"
      borderColor="transparent"
      borderRadius={8}
      gap={8}
      p={8}
      mb={32}
      display="flex"
      {...rest}
    >
      <Icon name="hold20" />
      <PM content={transferRequirements.timingDescription} />
    </Card>
  );
}
