import React from 'react';

import { Limit, type LimitChange } from '~/components/limit';

import { useStockScreenerResult } from './useStockScreenerResult';

export const StockLimits = () => {
  const { handleLimitChange, limitState } = useStockScreenerResult();

  const onLimitChange = handleLimitChange as LimitChange;
  return (
    <>
      <Limit
        limit={limitState.MARKET_CAP}
        format="currency"
        onLimitChange={onLimitChange}
      />
      <Limit limit={limitState.PE_RATIO} onLimitChange={onLimitChange} />
      <Limit
        limit={limitState.DIVIDEND_YIELD}
        label="Dividend yield (%)"
        onLimitChange={onLimitChange}
      />
    </>
  );
};
