import {
  ACCOUNT_SETUP_ACTIONS,
  type SubmittedContactInfoForm,
  type AccountSetupFlowCanceledAction,
  type SubmittedIdentityInfoForm,
  type CollectedHolderSsn,
  type SubmittedProfileInputAction,
  type ExplainedProfileCollection,
  type ChangeAccountSetupFlowStepAction,
  type BeginAccountSetupFlowAction,
  type ClickedCancelAccountSetupAction,
  type AccountSetupFlowFinishedAction,
  type SelectedAccountTypeAction,
  type SelectedRetirementAccountTypeAction,
  type SelectedIndividualRetirementAccountTypeAction,
  type SelectedRolloverAccountTypeAction,
  type SubmittedCustodialContactInfoFormAction,
  type SelectedOtherAccountTypeAction,
  type SubmittedIntroductionSourceAction,
  type FinishedAccountSetupReviewAction,
  type FinishedReadingRolloverReceiptAction,
  type FinishedReadingOtherAccountReceiptAction,
} from './accountSetup(Legacy)Actions.types';

export const beginAccountSetupFlow = (
  payload: BeginAccountSetupFlowAction['payload'],
): BeginAccountSetupFlowAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW,
});

export const changeAccountSetupFlowStep = (
  payload: ChangeAccountSetupFlowStepAction['payload'],
): ChangeAccountSetupFlowStepAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.CHANGE_ACCOUNT_SETUP_FLOW_STEP,
});

export const clickedCancelAccountSetup = (
  payload: ClickedCancelAccountSetupAction['payload'],
): ClickedCancelAccountSetupAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.CLICKED_CANCEL_ACCOUNT_SETUP,
});

export const accountSetupFlowCanceled = (): AccountSetupFlowCanceledAction => ({
  type: ACCOUNT_SETUP_ACTIONS.ACCOUNT_SETUP_FLOW_CANCELED,
});

export const accountSetupFlowFinished = (
  payload: AccountSetupFlowFinishedAction['payload'],
): AccountSetupFlowFinishedAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED,
});

export const selectedAccountType = (
  payload: SelectedAccountTypeAction['payload'],
): SelectedAccountTypeAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SELECTED_ACCOUNT_TYPE,
});

export const selectedRetirementAccountType = (
  payload: SelectedRetirementAccountTypeAction['payload'],
): SelectedRetirementAccountTypeAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE,
});

export const selectedIndividualRetirementAccountType = (
  payload: SelectedIndividualRetirementAccountTypeAction['payload'],
): SelectedIndividualRetirementAccountTypeAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE,
});

export const selectedRolloverAccountType = (
  payload: SelectedRolloverAccountTypeAction['payload'],
): SelectedRolloverAccountTypeAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE,
});

export const selectedOtherAccountType = (
  payload: SelectedOtherAccountTypeAction['payload'],
): SelectedOtherAccountTypeAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE,
});

export const submittedCustodialContactInfoForm = (
  payload: SubmittedCustodialContactInfoFormAction['payload'],
): SubmittedCustodialContactInfoFormAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM,
});

export const submittedIntroductionSource = (
  payload: SubmittedIntroductionSourceAction['payload'],
): SubmittedIntroductionSourceAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.SUBMITTED_INTRODUCTION_SOURCE,
});

export const finishedAccountSetupReview = (
  payload: FinishedAccountSetupReviewAction['payload'],
): FinishedAccountSetupReviewAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW,
});

export const finishedReadingRolloverReceipt = (
  payload: FinishedReadingRolloverReceiptAction['payload'],
): FinishedReadingRolloverReceiptAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.FINISHED_READING_ROLLOVER_RECEIPT,
});

export const finishedReadingOtherAccountReceipt = (
  payload: FinishedReadingOtherAccountReceiptAction['payload'],
): FinishedReadingOtherAccountReceiptAction => ({
  payload,
  type: ACCOUNT_SETUP_ACTIONS.FINISHED_READING_OTHER_ACCOUNT_RECEIPT,
});

export const submittedContactInfoForm = (
  payload: SubmittedContactInfoForm['payload'],
): SubmittedContactInfoForm => ({
  type: ACCOUNT_SETUP_ACTIONS.SUBMITTED_CONTACT_INFO_FORM,
  payload,
});

export const submittedIdentityInfoForm = (
  payload: SubmittedIdentityInfoForm['payload'],
): SubmittedIdentityInfoForm => ({
  type: ACCOUNT_SETUP_ACTIONS.SUBMITTED_IDENTITY_INFO_FORM,
  payload,
});

export const collectedHolderSsn = (
  payload: CollectedHolderSsn['payload'],
): CollectedHolderSsn => ({
  type: ACCOUNT_SETUP_ACTIONS.COLLECTED_HOLDER_SSN,
  payload,
});

export const submittedProfileInput = (
  payload: SubmittedProfileInputAction['payload'],
): SubmittedProfileInputAction => ({
  type: ACCOUNT_SETUP_ACTIONS.SUBMITTED_PROFILE_INPUT,
  payload: payload,
});

export const explainedProfileCollection = (
  payload: ExplainedProfileCollection['payload'],
): ExplainedProfileCollection => ({
  type: ACCOUNT_SETUP_ACTIONS.EXPLAINED_PROFILE_COLLECTION,
  payload: payload,
});
