import { useCallback } from 'react';

import type { AnnouncementFragment } from '~/graphql/types';

import { useAnalytics } from './useAnalytics';
import { useInViewAction } from './useInViewAction';

export const useAnnouncementInViewRef = (
  displayAnalyticsEvent: AnnouncementFragment['displayAnalyticsEvent'],
) => {
  const analytics = useAnalytics();
  const handleAnnouncementInView = useCallback(() => {
    if (displayAnalyticsEvent) {
      analytics.recordAppAnalyticsEvent(displayAnalyticsEvent);
    }
  }, [displayAnalyticsEvent, analytics]);
  const ref = useInViewAction(handleAnnouncementInView);

  return ref;
};
