import { Box } from '@m1/liquid-react';
import React from 'react';

import type { HistoricalQuote } from '~/graphql/types';

type HistoricalQuoteSplit = Pick<
  NonNullable<HistoricalQuote['split']>,
  'toFactor' | 'forFactor'
>;

export const Splits = ({ split }: { split: HistoricalQuoteSplit }) => (
  <Box>
    <Box>
      <b>Split</b>
    </Box>
    <Box>
      {split.toFactor}:{split.forFactor}
    </Box>
  </Box>
);
