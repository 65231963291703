import tail from 'lodash-es/tail';

import type { LocationChangeAction } from '~/redux/actions';

import type { RoutingState } from './routingReducer.types';

// Keep ordered history of every route visited
export const buildAppHistory = (
  state: RoutingState,
  payload: LocationChangeAction['payload'],
): string[] => {
  const pathNameAndQueryParams = `${payload.location.pathname}${payload.location.search}`;
  if (state.appHistory.length === 0) {
    return [pathNameAndQueryParams];
  }

  const lastLocationKey = state.locationBeforeTransitions?.location.key ?? null;

  // Don't add the route to app history, the navigate action was dispatched
  // multiple times with the same location key:
  if (lastLocationKey !== null) {
    if (lastLocationKey === payload.location.key) {
      return [...state.appHistory];
    }
  }

  switch (payload.action) {
    case 'REPLACE':
      return [pathNameAndQueryParams, ...tail(state.appHistory)];
    default:
      return [pathNameAndQueryParams, ...state.appHistory];
  }
};
