import { createStepFade } from '../step-fade-transition';
import { makeFlowComponent } from '../utils';

import { SMART_TRANSFER_STEPS } from './constants';
import { ConfirmSmartTransferStep } from './steps/ConfirmSmartTransfer';
import { SmartTransferRemoteError } from './steps/errors/SmartTransferRemoteError';
import { InitializeSmartTransfer } from './steps/InitializeSmartTransfer';
import { IntroduceSmartTransfer } from './steps/IntroduceSmartTransfer';
import { LoadingState } from './steps/LoadingState';
import { SelectFocusAccount } from './steps/SelectFocusAccount';
import { SetFulfillmentCondition } from './steps/SetFulfillmentCondition';
import { SetSmartTransfer } from './steps/SetSmartTransfer';
import { SmartTransferReceipt } from './steps/SmartTransferReceipt';

export const CreateSmartTransferFlow = makeFlowComponent({
  name: 'CREATE_SMART_TRANSFER',
  Component: createStepFade({
    [SMART_TRANSFER_STEPS.INTRODUCE_SMART_TRANSFER]: IntroduceSmartTransfer,
    [SMART_TRANSFER_STEPS.INITIALIZE_SMART_TRANSFER]: InitializeSmartTransfer,
    [SMART_TRANSFER_STEPS.LOADING]: LoadingState,
    [SMART_TRANSFER_STEPS.SELECT_FOCUS_ACCOUNT]: SelectFocusAccount,
    [SMART_TRANSFER_STEPS.SET_SMART_TRANSFER]: SetSmartTransfer,
    [SMART_TRANSFER_STEPS.SET_FULFILLMENT_CONDITION]: SetFulfillmentCondition,
    [SMART_TRANSFER_STEPS.CONFIRM_SMART_TRANSFER]: ConfirmSmartTransferStep,
    [SMART_TRANSFER_STEPS.SMART_TRANSFER_RECEIPT]: SmartTransferReceipt,

    // ERROR STATES
    [SMART_TRANSFER_STEPS.REMOTE_ERROR]: SmartTransferRemoteError,
  }),
});
