import type { HistoricalQuote, IntradayQuotePointType } from '~/graphql/types';

export type DataPoint = {
  date: string;
  percentChange: number | string;
  previousQuoteClosePrice: number | null | undefined;
  shareVolume: number;
  value: number;
  valueChange: number;
};

type IntradayQuoteDatum = {
  time: IntradayQuotePointType['time'];
  data: Pick<
    IntradayQuotePointType['data'],
    | 'closePrice'
    | 'changeFromPreviousClosePrice'
    | 'shareVolume'
    | 'percentChangeFromPreviousClosePrice'
    | 'previousClosePrice'
    | 'previousQuoteClosePrice'
  >;
};

const shouldIncludeIntradayDataPoint = (
  val: IntradayQuoteDatum,
  isCrypto: boolean,
) => {
  const hasPercentChangeFromPreviousClose =
    typeof val.data.percentChangeFromPreviousClosePrice === 'number';
  const hasShareVolume = typeof val.data.shareVolume === 'number';

  if (isCrypto) {
    return Boolean(
      hasPercentChangeFromPreviousClose &&
        val.data.changeFromPreviousClosePrice,
    );
  }

  return Boolean(
    hasShareVolume &&
      hasPercentChangeFromPreviousClose &&
      val.data.changeFromPreviousClosePrice,
  );
};

export const makeIntradayQuoteData = (
  arr: IntradayQuoteDatum[],
  isCrypto: boolean,
): DataPoint[] => {
  return arr.reduce((acc, val) => {
    const shouldIncludeData = shouldIncludeIntradayDataPoint(val, isCrypto);
    if (shouldIncludeData) {
      acc.push({
        date: val.time,
        value: val.data.closePrice,
        valueChange: val.data.changeFromPreviousClosePrice,
        percentChange: val.data.percentChangeFromPreviousClosePrice,
        previousQuoteClosePrice: val.data.previousQuoteClosePrice,
        shareVolume: val.data.shareVolume,
      } as DataPoint);
    }
    return acc;
  }, [] as DataPoint[]);
};

type HistoricalQuoteDatum = Pick<
  HistoricalQuote,
  | 'date'
  | 'closePrice'
  | 'previousQuoteClosePrice'
  | 'changeInPriceFromPeriodOpen'
  | 'percentChangeFromBeginningOfPeriod'
  | 'shareVolume'
>;

const shouldIncludeHistoricalDataPoint = (
  val: HistoricalQuoteDatum,
  isCrypto: boolean,
) => {
  const hasPercentChangeFromBeginningOfPeriod =
    typeof val.percentChangeFromBeginningOfPeriod === 'number';

  if (isCrypto) {
    return Boolean(
      hasPercentChangeFromBeginningOfPeriod && val.changeInPriceFromPeriodOpen,
    );
  }

  return Boolean(
    val.shareVolume &&
      hasPercentChangeFromBeginningOfPeriod &&
      val.changeInPriceFromPeriodOpen,
  );
};

export const makeHistoricalQuoteData = (
  arr: HistoricalQuoteDatum[],
  isCrypto: boolean,
): DataPoint[] => {
  return arr.reduce((acc, val) => {
    const shouldIncludeData = shouldIncludeHistoricalDataPoint(val, isCrypto);
    if (shouldIncludeData) {
      acc.push({
        date: val.date,
        value: val.closePrice,
        previousQuoteClosePrice: val.previousQuoteClosePrice,
        valueChange: val.changeInPriceFromPeriodOpen,
        percentChange: val.percentChangeFromBeginningOfPeriod,
        shareVolume: val.shareVolume,
      } as DataPoint);
    }

    return acc;
  }, [] as DataPoint[]);
};
