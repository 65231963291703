import { Flex, Image } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink/ExternalLink';
import type { RichTextFragment } from '~/graphql/types';
import appStoreStars from '~/images/app-store-stars.svg';
import appStoreBadge from '~/images/app-store.png';
import playStoreStars from '~/images/play-store-stars.svg';
import playStoreBadge from '~/images/play-store.png';

import { RichText } from '../lens-toolbox/RichText/RichText';

type MarketingAndDisclaimerRowsPartialProps = {
  hideAppStoreBadges: boolean;
  referralsDisclosureContent: RichTextFragment[];
};

export const MarketingAndDisclaimerRows = ({
  hideAppStoreBadges,
  referralsDisclosureContent,
}: MarketingAndDisclaimerRowsPartialProps) => {
  return (
    <>
      {!hideAppStoreBadges && (
        <Flex maxWidth={396} mx="auto" justifyContent="space-between">
          <Flex flexDirection="column">
            <Image alt="" src={playStoreStars} mb={16} width={155} />
            <ExternalLink destination="GOOGLE_PLAY_STORE">
              <Image alt="" src={playStoreBadge} width={155} />
            </ExternalLink>
          </Flex>
          <Flex flexDirection="column">
            <Image alt="" src={appStoreStars} mb={16} width={155} />
            <ExternalLink destination="APPLE_APP_STORE">
              <Image alt="" src={appStoreBadge} width={155} />
            </ExternalLink>
          </Flex>
        </Flex>
      )}
      <Flex flexDirection="column" gap={24} mx={32} py={24}>
        {referralsDisclosureContent?.map((content, idx) => {
          return (
            <RichText
              key={idx}
              richText={content}
              textProps={{
                font: 'PL',
                fontWeight: 400,
                color: 'foregroundNeutralMain',
                paddingRight: 4,
              }}
            />
          );
        })}
      </Flex>
    </>
  );
};
