import { Flex, PL, PS } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { CreditCardRewardsPill } from '~/components/credit-card/CreditCardRewardsAppPill';
import { SpendActivityEntryAmountText } from '~/components/SpendActivityEntryAmountText';
import type { RelatedTransactionFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export type RewardsSingularProgramTableCellComponentProps = {
  index: number;
  transaction: RelatedTransactionFragment;
};

export const RewardsSingularProgramTableCell = ({
  index,
  transaction,
}: RewardsSingularProgramTableCellComponentProps) => {
  const rowContent = (
    <>
      <GridTable.Cell
        content={
          <>
            <PL content={transaction.merchant} />
            <PS
              color="foregroundNeutralMain"
              content={moment(transaction.date).format('ll')}
            />
          </>
        }
      />
      <GridTable.Cell justifySelf="end">
        <Flex flexDirection="column" alignItems="center">
          {transaction.amount && (
            <PL fontWeight={600}>
              <SpendActivityEntryAmountText
                amount={transaction.amount}
                type="credit"
              />
            </PL>
          )}

          {transaction.details?.colorizedTransactionRewardTier && (
            <CreditCardRewardsPill
              mt={4}
              appPill={transaction.details.colorizedTransactionRewardTier}
            />
          )}
        </Flex>
      </GridTable.Cell>
    </>
  );
  if (!transaction.details?.id) {
    return <GridTable.Row key={index}>{rowContent}</GridTable.Row>;
  }
  return (
    <GridTable.NavigableRow
      key={transaction.details.id}
      params={{
        spendActivityEntryDetailsId: transaction.details.id,
      }}
      to="/d/c/spend-activity-entry-details/:spendActivityEntryDetailsId"
    >
      {rowContent}
    </GridTable.NavigableRow>
  );
};
