import type { NewPie, OldPie, Pie, Sliceable } from './types';

export const isNewPie = (sliceable: Sliceable): sliceable is NewPie => {
  return sliceable.type === 'new_pie';
};

export const isOldPie = (sliceable: Sliceable): sliceable is OldPie => {
  return sliceable.type === 'old_pie';
};

export const isPie = (sliceable: Sliceable): sliceable is Pie => {
  return isNewPie(sliceable) || isOldPie(sliceable);
};
