import type { AppPillKind } from '~/graphql/types';
import type { PillKind } from '~/toolbox/Pill';

import type {
  ConfirmationChange,
  AddedOrUpdated,
  Removed,
  PieDetails,
  PortfolioLink,
} from './ConfirmationDialog.types';

const mapToPillKind = (
  kind: AppPillKind | null | undefined,
): PillKind | null | undefined => {
  switch (kind) {
    case 'CAUTION':
      return 'caution';
    case 'DANGER':
      return 'danger';
    case 'DANGER_INVERSE':
      return 'danger';
    case 'IMPORTANT':
      return 'important';
    case 'IMPORTANT_INVERSE':
      return 'important';
    case 'NEUTRAL':
      return 'neutral';
    case 'SUCCESS':
      return 'success';
    case 'WHATS_NEW':
      return 'new';
    case 'WHATS_NEW_INVERSE':
      return 'new';
    default:
      return null;
  }
};

const readAncestors = (portfolioLink: PortfolioLink): Array<string> => {
  // @ts-expect-error - TS7034 - Variable 'ancestorNames' implicitly has type 'any[]' in some locations where its type cannot be determined.
  const ancestorNames = [];

  if (
    portfolioLink.__typename === 'ChildPortfolioSlice' &&
    portfolioLink.ancestors?.length
  ) {
    portfolioLink.ancestors.forEach((ancestor) => {
      if (ancestor) {
        ancestorNames.push(ancestor.to.name);
      }
    });
  }

  // @ts-expect-error - TS7005 - Variable 'ancestorNames' implicitly has an 'any[]' type.
  return ancestorNames;
};

export const mapApplicableLocations = (
  applicableLocations: Array<PortfolioLink>,
  pieName: string,
): Array<ConfirmationChange> => {
  const changes: Array<ConfirmationChange> = [];

  if (applicableLocations.length) {
    applicableLocations.forEach((location) => {
      const description = [...readAncestors(location), pieName]
        .filter(Boolean)
        .join(' > ');

      changes.push({
        left: {
          description,
          label: location.account ? location.account.name : 'My Account',
        },
      });
    });
  }

  return changes;
};

export const mapName = (
  pieDetails: PieDetails | null | undefined,
): Array<ConfirmationChange> => {
  const changes: Array<ConfirmationChange> = [];

  if (pieDetails?.name) {
    changes.push({
      left: {
        label: pieDetails.name.value,
      },
      right: {
        actionPill: {
          kind: mapToPillKind(pieDetails.name.actionPill?.kind),
          label: pieDetails.name?.actionPill?.label || '',
        },
        isPercent: false,
      },
    });
  }

  return changes;
};

export const mapDescription = (
  pieDetails: PieDetails | null | undefined,
): Array<ConfirmationChange> => {
  const changes: Array<ConfirmationChange> = [];

  if (pieDetails?.description) {
    changes.push({
      left: {
        label: pieDetails.description.value,
      },
      right: {
        actionPill: {
          kind: mapToPillKind(pieDetails.description.actionPill?.kind),
          label: pieDetails.description?.actionPill?.label || '',
        },
        isPercent: false,
      },
    });
  }

  return changes;
};

export const mapAddedOrUpdated = (
  addedOrUpdated: AddedOrUpdated | null | undefined,
): Array<ConfirmationChange> => {
  const changes: Array<ConfirmationChange> = [];
  const slices = addedOrUpdated?.slices;

  if (slices) {
    slices.forEach((slice) => {
      if (slice) {
        changes.push({
          left: {
            label: slice.symbol || '',
            description: slice.name,
          },
          right: {
            actionPill: {
              kind: mapToPillKind(slice.actionPill?.kind),
              label: slice.actionPill?.label || '',
            },
            value: slice.percentage,
            isPercent: true,
          },
        });
      }
    });
  }

  return changes;
};

export const mapRemoved = (
  removed: Removed | null | undefined,
): Array<ConfirmationChange> => {
  const changes: Array<ConfirmationChange> = [];
  const slices = removed?.slices;

  if (slices) {
    slices.forEach((slice) => {
      if (slice) {
        changes.push({
          left: {
            label: slice.symbol || '',
            description: slice.name,
          },
        });
      }
    });
  }

  return changes;
};
