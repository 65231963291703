import { Box, Button, Flex, HXS, HXXS, LM, PL } from '@m1/liquid-react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { GenericSystemError } from '~/components/GenericSystemError';
import { InvestFullyPaidLendingDisclosure } from '~/flows/components/onboarding/invest/InvestFullyPaidLendingDisclosure/InvestFullyPaidLendingDisclosure';
import { useInvestAccountReviewStepRequiredDocumentsQuery } from '~/graphql/hooks';
import type { AppState } from '~/redux';
import { useDispatch } from '~/redux/hooks';
import { OPEN_INVEST_ACCOUNT_STEPS } from '~/static-constants';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { DocumentsColumns } from '../DocumentsColumns';

type OnFinishPayload = {
  signature: string;
  fplStatus?: Maybe<boolean>;
};

type IndividualReviewStepComponentProps = {
  onFinishStep: (arg0: OnFinishPayload) => void;
};

export const IndividualReviewStep = ({
  onFinishStep,
}: IndividualReviewStepComponentProps) => {
  const dispatch = useDispatch();
  const { fullyPaidLendingStatus } = useSelector(
    (state: AppState) => state.newFlows.INVEST_ONBOARDING,
  );
  const { data, loading, error } =
    useInvestAccountReviewStepRequiredDocumentsQuery({
      variables: {
        accountRegistration: 'INDIVIDUAL',
      },
    });

  if (loading) {
    return <Spinner />;
  }
  if (error || !data?.viewer.invest) {
    return <GenericSystemError />;
  }

  const { requiredDocuments } = data.viewer.invest;

  return (
    <Box maxWidth={588} mx="auto">
      <HXS content="Does everything look correct?" pb={16} />
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <HXXS content="Account Type" />
          <LM content="Individual" pt={8} />
        </Flex>
        <Button
          kind="link"
          label="Edit"
          style={{
            alignItems: 'flex-start',
            fontWeight: '600',
            fontSize: '16px',
          }}
          onClick={() => {
            dispatch({
              // @ts-ignore rando type for now
              type: 'CHANGE_OPEN_INVEST_ACCOUNT_STEP',
              payload: OPEN_INVEST_ACCOUNT_STEPS.SELECT_ACCOUNT_TYPE,
            });
          }}
        />
      </Flex>
      <Divider spacing="relaxed" />
      <Box py={16}>
        <PL
          content={requiredDocuments.preamble}
          mb={16}
          color="foregroundNeutralMain"
        />
        <DocumentsColumns>
          {requiredDocuments.documents.map((d) => (
            <Box key={d.url} pb={8}>
              <Link to={d.url} target="_blank">
                {d.title}
              </Link>
            </Box>
          ))}
        </DocumentsColumns>
        <PL content={requiredDocuments.postamble} />
      </Box>
      <Divider spacing="relaxed" />
      <InvestFullyPaidLendingDisclosure accountRegistration="INDIVIDUAL" />
      <Button
        size="large"
        label="Continue"
        onClick={() => {
          onFinishStep({
            signature: requiredDocuments.signature,
            fplStatus: fullyPaidLendingStatus,
          });
        }}
        mb={64}
      />
    </Box>
  );
};
