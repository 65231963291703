import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext, type SubmitHandler } from 'react-hook-form';

import type {
  LiquidNetWorthEnum,
  OnboardingIdentityFirstQuestionType,
} from '~/graphql/types';
import { getEnumEntries } from '~/utils';

import { StyledOption } from '../consts';
import type { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';

type AvailableCashFormProps = {
  nextQuestion?: OnboardingIdentityFirstQuestionType | null;
  onSubmit: SubmitHandler<FinancialSuitabilityFormFields>;
  handleNext: () => void;
};

export const AvailableCashForm = ({
  nextQuestion,
  onSubmit,
  handleNext,
}: AvailableCashFormProps) => {
  const { setValue, handleSubmit } =
    useFormContext<FinancialSuitabilityFormFields>();
  const options = getEnumEntries('LiquidNetWorthEnum');

  const handleOptionClick = (name: LiquidNetWorthEnum) => {
    setValue('suitability.liquidNetWorth', name);
    nextQuestion ? handleNext() : handleSubmit(onSubmit)();
  };

  return (
    <Box>
      {options.map(({ name, description }) => (
        <StyledOption
          tabIndex={0}
          key={name}
          onClick={() => handleOptionClick(name as LiquidNetWorthEnum)}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === 'Enter' || event.code === 'Space') {
              handleOptionClick(name as LiquidNetWorthEnum);
            }
          }}
        >
          {description}
        </StyledOption>
      ))}
    </Box>
  );
};
