import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { OpenAccountInput } from '~/graphql/types';
import type { AppState } from '~/redux';
import {
  accountSetupFlowFinished,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';
import {
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
  INVEST_ACCOUNT_TYPES,
} from '~/static-constants';
import type { ToastProps } from '~/toolbox/toast';

import { getLoggers } from '../../../../common';
import { openAccount } from '../../../../common/openAccountSaga';
import { showFailureToast } from '../../../../showFailureToastSaga';
import { changeStep } from '../../../utils';

export function* handleFinishedReview(): SagaIterator {
  const { analytics } = yield call(getLoggers);

  try {
    yield put(showLoadingSpinner());

    const {
      input,
      phone,
    }: {
      input: OpenAccountInput;
      phone: string | null | undefined;
    } = yield select((state: AppState) => ({
      input: state.newFlows.IDENTITY_FIRST.input,
      phone: state.newFlows.IDENTITY_FIRST.phone,
    }));

    const { registration, transferInfoOptIn } = input;
    const accountRegistration =
      registration || INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE;

    const result = yield call(openAccount, input, phone);

    if (!result.didSucceed) {
      yield call(showFailureToast);
    } else {
      const isCrypto = accountRegistration === 'CRYPTO';

      const accountId = readAccountId(result);

      if (!accountId) {
        if (isCrypto) {
          analytics.recordEvent('m1_crypto_account_rejected');
        }
        throw new Error('Something went wrong.');
      }

      if (isCrypto) {
        analytics.recordEvent('m1_crypto_account_opened');
      } else if (
        Object.values(INVEST_ACCOUNT_TYPES).includes(accountRegistration)
      ) {
        // if brokerage account, fire investment account opened
        analytics.recordEvent('investmentAccountOpened');
      }

      yield put({
        type: 'ENDED_INVEST_MARKETING_SESSION',
      });
      yield put({
        type: isCrypto
          ? 'SET_ACTIVE_CRYPTO_ACCOUNT'
          : 'SET_ACTIVE_INVEST_ACCOUNT',
        payload: accountId,
      });

      // Could be used for non-crypto flow in the future too! Step will need updates, details are in the step component
      if (isCrypto) {
        yield call(changeStep, STEPS.CHOOSE_PIE);
      } else if (transferInfoOptIn) {
        yield call(changeStep, STEPS.SHOW_ROLLOVER_RECEIPT);
      } else {
        yield put(accountSetupFlowFinished(accountId));
      }
    }
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function readAccountId(result: Record<string, any>): string {
  return result?.outcome?.account?.id;
}
