import { Button } from '@m1/liquid-react';
import React from 'react';

import { PieOrganizerNavigationButton } from '~/components/PieOrganizerNavigationButton/PieOrganizerNavigationButton';
import type { SliceableCellFragment } from '~/graphql/types';
import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { PieEditorEntryButton } from '~/pages/dashboard/wizards/pie-editor/PieEditorEntryButton';
import { useDispatch } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { usePieDetailsContext } from '../PieDetailsPage/hooks/usePieDetailsContext';
import { SliceableAddToPortfolioButton } from '../SliceableAddToPortfolioButton';

export const PieActionButtonGroup = () => {
  const { isAccountSetup, pie, disableAdding, disableEditing, isCrypto } =
    usePieDetailsContext();
  const { showNewPieEditor } = useNewPieEditor();
  const dispatch = useDispatch();

  if (!pie) {
    return null;
  }

  const { id, isOwnPie, __typename } = pie;

  return (
    <ButtonGroup behavior="fill-space">
      {!disableAdding ? (
        <SliceableAddToPortfolioButton
          id={id}
          sliceable={pie as SliceableCellFragment}
        />
      ) : null}
      {isOwnPie && !disableEditing ? (
        <>
          {!showNewPieEditor ? (
            <PieOrganizerNavigationButton
              event={{
                type: 'INITIALIZE_ORGANIZER',
                initConfig: {
                  confirmationDialog: {
                    showApplicableLocations: true,
                  },
                  mode: 'EDIT_PIE',
                  pieId: id,
                  returnTo: `/d/research/my-pies/details/${id}`,
                  isCrypto: isCrypto ?? false,
                },
              }}
              kind="secondary"
              label="Edit"
              size="medium"
            />
          ) : (
            <PieEditorEntryButton
              label="Edit"
              kind="secondary"
              pieEditorRouteParam={id}
              size="medium"
            />
          )}
        </>
      ) : null}
      {isAccountSetup && (
        <Button
          label="Fund this pie"
          onClick={() => {
            dispatch({
              type: 'SET_SELECTED_ROOT_PIE',
              payload: id,
            });
          }}
        />
      )}
    </ButtonGroup>
  );
};
