import { Box, Button, Flex, HM, useTheme } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  IdentityFirstConfirmationScreenProfileSection,
  IdentityFirstConfirmationScreenRequiredDocumentsInvest,
} from '~/components/Onboarding';
import { FinancialInformationConfirm } from '~/components/Onboarding/FinancialInformationConfirm';
import { useInvestConfirmationStepQuery } from '~/graphql/hooks';
import type { OnlineAccountRegistrationEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSentry } from '~/hooks/useSentry';
import type { AppState } from '~/redux';
import { changeInvestAccountSetupFlowStep } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import type { INVEST_ONBOARDING_FLOW_STEP_VALUES } from '~/static-constants';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

import { shouldIncludeFPLStatus } from '../../utils';

import { InvestAccountSection } from './InvestAccountSection';
import {
  type ConfirmationSection,
  getStepFromSectionClicked,
} from './InvestConfirmationHelpers';

export const InvestConfirmationStep = () => {
  const subProduct = useSelector(
    (state: AppState) => state.newFlows.onboarding.subProduct,
  );
  const { fullyPaidLendingStatus } = useSelector(
    (state: AppState) => state.newFlows.INVEST_ONBOARDING,
  );
  const accountRegistration = subProduct ?? 'INDIVIDUAL';
  const { data, loading } = useInvestConfirmationStepQuery({
    variables: {
      accountRegistration: accountRegistration as OnlineAccountRegistrationEnum,
    },
  });

  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const sentry = useSentry();

  const handleSubmit = (
    signature: string,
    isCustodialAccount: boolean,
  ): void => {
    dispatch({
      type: 'FINISHED_INVEST_ACCOUNT_SETUP_REVIEW',
      payload: {
        signature,
        isCustodialAccount,
        fplStatus: shouldIncludeFPLStatus(accountRegistration)
          ? fullyPaidLendingStatus
          : null,
      },
    });
  };

  const { addToast } = useToast();

  const theme = useTheme();
  const isSmallViewport = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.SMALL})`,
  });
  // update with fragments from SavingsCreateAccountReview - onboarding / profile
  const onClickEdit = React.useCallback(
    (section_clicked: ConfirmationSection) => {
      const step = getStepFromSectionClicked(section_clicked);

      analytics.recordEvent(
        'm1_onboarding_confirmation_screen_edit_clicked',
        null,
        { section_clicked: section_clicked },
      );
      if (section_clicked === 'invest') {
        dispatch(
          changeInvestAccountSetupFlowStep(
            step as INVEST_ONBOARDING_FLOW_STEP_VALUES,
          ),
        );
      }
    },
    [analytics, dispatch],
  );

  if (loading) {
    return <Spinner />;
  }

  if (
    !data?.viewer.invest ||
    !data.viewer.onboarding ||
    !data.viewer.profile ||
    !data.viewer.user
  ) {
    return <GenericSystemError />;
  }

  const { invest, onboarding, profile, user } = data.viewer;
  const showModuleOneSection = user?.hasProduct;
  return (
    <Box maxWidth={1200} mx="auto" px={16}>
      <HM
        content="Does everything look correct?"
        pt={16}
        pb={32}
        textAlign={isSmallViewport ? 'center' : 'left'}
      />
      {isSmallViewport ? (
        <Flex flexDirection="column">
          {showModuleOneSection && (
            <IdentityFirstConfirmationScreenProfileSection
              profile={profile}
              user={user}
            />
          )}
          <FinancialInformationConfirm
            analyticsEvent="m1_onboarding_confirmation_screen_edit_clicked"
            onboarding={onboarding}
            profile={profile}
          />
          <InvestAccountSection onClickEdit={() => onClickEdit('invest')} />
        </Flex>
      ) : (
        <Flex>
          <Flex flexDirection="column" width="50%" pr={24}>
            {showModuleOneSection ? (
              <IdentityFirstConfirmationScreenProfileSection
                profile={profile}
                user={user}
              />
            ) : (
              <FinancialInformationConfirm
                analyticsEvent="m1_onboarding_confirmation_screen_edit_clicked"
                onboarding={onboarding}
                profile={profile}
              />
            )}
          </Flex>
          <Flex flexDirection="column" width="50%" pl={24}>
            {showModuleOneSection && (
              <FinancialInformationConfirm
                analyticsEvent="m1_onboarding_confirmation_screen_edit_clicked"
                onboarding={onboarding}
                profile={profile}
              />
            )}
            <InvestAccountSection onClickEdit={() => onClickEdit('invest')} />
          </Flex>
        </Flex>
      )}
      <Divider spacing="relaxed" />
      <IdentityFirstConfirmationScreenRequiredDocumentsInvest
        requiredDocuments={invest.requiredDocuments}
        accountRegistration={subProduct ?? 'INDIVIDUAL'}
      />
      <Divider spacing="relaxed" />
      <Box pb={32}>
        <Button
          disabled={Boolean(!invest.requiredDocuments.signature)}
          type="submit"
          kind="primary"
          size="large"
          label="Continue"
          onClick={() => {
            if (!invest?.requiredDocuments.signature) {
              sentry.message('No signature found for document bundle', {
                level: 'warning',
              });

              addToast({
                content:
                  'An error occurred while attempting to open your account, please try again or contact support.',
                kind: 'alert',
                duration: 'long',
              });

              return;
            }

            handleSubmit(invest.requiredDocuments.signature, false);
          }}
        />
      </Box>
    </Box>
  );
};
