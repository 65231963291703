import type { ResponseSliceable, Sliceable } from './types';

/**
 * Our main mapping utility for mapping incoming data from our queries for sliceable data
 * to our local types used in Redux and the rest of the Pie Organizer. It takes a sliceable
 * and maps it to the correct local type, recursing over the slices array if the sliceable is a pie.
 * @param {ResponseSliceable} sliceable - The sliceable to map and if necessary recurse over
 * @returns - Returns our mapped sliceable
 */
export const mapRemoteSliceableByType = (
  sliceable: ResponseSliceable,
): Sliceable => {
  switch (sliceable.type) {
    case 'CRYPTO':
    case 'EQUITY_SECURITY':
    case 'FUND_SECURITY':
      return {
        __checked: false,
        __typename: sliceable.__typename,
        __highlighted__: Boolean(sliceable.highlight),
        __merged__: false,
        __id: sliceable.id,
        __legacyId: sliceable.legacyId,
        id: sliceable.legacyId || sliceable.id,
        isActive: sliceable.isActive,
        type: 'security',
        name: sliceable.name,
        portfolioLinks: sliceable.portfolioLinks,
        profile: sliceable.profile
          ? {
              logoUrl: sliceable.profile.logoUrl,
            }
          : null,
        symbol: sliceable.symbol,
      };
    case 'CRYPTO_SYSTEM_PIE':
    case 'SYSTEM_PIE':
      return {
        __checked: false,
        __highlighted__: false,
        __shouldEqualizeSlicePercentagesOnAdd: false,
        __id: sliceable.id,
        __merged__: false,
        id: sliceable.id,
        name: sliceable.name,
        portfolioLinks: sliceable.portfolioLinks,
        type: 'old_pie',
        __typename:
          sliceable.__typename === 'CRYPTO_SYSTEM_PIE'
            ? 'CRYPTO_SYSTEM_PIE'
            : 'SYSTEM_PIE',
        slices: sliceable.slices
          ? sliceable.slices.map((slice) => ({
              ...slice,
              to: mapRemoteSliceableByType(slice.to),
            }))
          : null,
      };
    case 'USER_PIE':
      return {
        __checked: false,
        __highlighted__: false,
        __shouldEqualizeSlicePercentagesOnAdd: false,
        __id: sliceable.id,
        __merged__: false,
        description: sliceable.description,
        id: sliceable.id,
        name: sliceable.name,
        portfolioLinks: sliceable.portfolioLinks,
        type: 'old_pie',
        __typename: 'USER_PIE',
        slices: sliceable.slices
          ? sliceable.slices.map((slice) => ({
              ...slice,
              to: mapRemoteSliceableByType(slice.to),
            }))
          : null,
      };
    default: {
      throw new Error('Unhandled remote sliceable type.');
    }
  }
};
