import type {
  SecurityStatusEnum,
  SliceableCellFragment,
  SliceableStatusEnum,
  SystemPieStatusEnum,
} from '~/graphql/types';

export const getSliceableStatus = (
  sliceable: Maybe<SliceableCellFragment>,
): Maybe<SliceableStatusEnum | SecurityStatusEnum | SystemPieStatusEnum> => {
  switch (sliceable?.__typename) {
    case 'Equity':
    case 'Fund':
      return sliceable.status;
    case 'SystemPie':
      return sliceable.systemPieStatus;
    default:
      return null;
  }
};
