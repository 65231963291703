import { Box, Flex, PS, styled, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import { ChartableSliceChart } from '~/components/ChartableSliceChart/ChartableSliceChart';
import { PercentGain } from '~/components/percent-gain';
import { usePortfolioChartPerformanceQuery } from '~/graphql/hooks';
import type { PortfolioChartPerformanceQuery } from '~/graphql/types';
import { EXTERNAL_LINKS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { isNotNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

import {
  PortfolioDetailsContext,
  type PortfolioDetailsContextType,
} from '../PortfolioDetailsContext';

export type PortfolioSliceValueHistoryChartProps = {
  height?: number;
  portfolioSlice: PortfolioDetailsContextType['portfolioSlice'];
};

const StatisticsTable = styled(Flex)`
  justify-content: space-between;
  gap: 24px;
`;

const StatisticContainer = styled(Flex)`
  flex-direction: column;
  flex: 1 0 0;
`;

const StatisticDivider = styled(Box)`
  margin: 12px 0;
  border: 1px solid ${({ theme }) => theme.colors.borderMain};
`;

const ChartAndStatistics = styled(Box)`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 32px;
`;

const LabeledStatistic = ({
  label,
  value,
}: {
  label: React.ReactNode | string;
  value: React.ReactNode | string;
}) => (
  <Box>
    <PS fontWeight={400} mb={4}>
      {label}
    </PS>
    <Box font="PM" fontWeight={600} css={{ display: 'flex' }}>
      {value}
    </Box>
  </Box>
);

export const PortfolioSliceValueHistoryChart = ({
  height = 280,
  portfolioSlice,
}: PortfolioSliceValueHistoryChartProps) => {
  const sliceContext = React.useContext(PortfolioDetailsContext);
  const { data } = usePortfolioChartPerformanceQuery({
    variables: {
      portfolioId: portfolioSlice.id,
      period: sliceContext.chartPeriod,
    },
  });
  const chartContainerRef = React.useRef(null);
  const theme = useTheme();

  const startDate = sliceContext.chartRange?.chartStartDate.format('ll');
  const startAmount = sliceContext.chartRange?.start.y;
  const endDate = sliceContext.chartRange?.chartEndDate.format('ll');
  const endAmount = sliceContext.chartRange?.end.y;

  const performance = (
    data?.node as ExtractNodeTypename<
      PortfolioChartPerformanceQuery,
      'RootPortfolioSlice' | 'ChildPortfolioSlice'
    >
  )?.performance;
  const MWRR = performance?.moneyWeightedRateOfReturn;
  const netCashFlow = performance?.netCashFlow;
  const dividendsEarned = performance?.earnedDividends;

  // We can get market gain from the performance endpoint too, but
  // that value will not match the start and end values from the chart.
  // Calculate it manually for now, can use the BE value once we sync the
  // chart data from Market Value Snapshots with the Performance endpoint.
  // Do not show market gain if any of the inputs are not available.
  const marketGain =
    typeof startAmount === 'number' &&
    typeof endAmount === 'number' &&
    typeof netCashFlow === 'number'
      ? endAmount - startAmount - netCashFlow
      : null;

  return (
    <Flex flexDirection="column" width="100%">
      <ChartAndStatistics ref={chartContainerRef} minHeight={height}>
        <ChartableSliceChart
          chartableSliceIds={[portfolioSlice.id]}
          chartName="invest_portfolio"
          // Hide all features, including dividends and cash flows.
          // Data is not reliable for production use.
          features={{}}
          periods={['1d', '1w', '1M', '3M', 'ytd', '1y', 'all']}
          onChangePeriod={sliceContext.onChangePeriod}
          onRangeSelectionChange={sliceContext.onChangeChartRange}
        />
        <StatisticsTable>
          <StatisticContainer>
            <LabeledStatistic
              label={`Starting value${isNotNil(startDate) ? `: ${startDate}` : ''}`}
              value={
                typeof startAmount === 'number'
                  ? formatCurrency(startAmount)
                  : '$--'
              }
            />
            <StatisticDivider />
            <LabeledStatistic
              label="Dividends earned"
              value={
                typeof dividendsEarned === 'number'
                  ? formatCurrency(dividendsEarned)
                  : '$--'
              }
            />
          </StatisticContainer>
          <StatisticContainer>
            <LabeledStatistic
              label={`Ending value${isNotNil(endDate) ? `: ${endDate}` : ''}`}
              value={
                typeof endAmount === 'number'
                  ? formatCurrency(endAmount)
                  : '$--'
              }
            />
            <StatisticDivider />
            <LabeledStatistic
              label="Market gain"
              value={
                typeof marketGain === 'number'
                  ? formatCurrency(marketGain)
                  : '$--'
              }
            />
          </StatisticContainer>
          <StatisticContainer>
            <LabeledStatistic
              label={
                <Link to="/d/invest/activity" font="PS">
                  Net cash flow
                </Link>
              }
              value={
                typeof netCashFlow === 'number'
                  ? formatCurrency(netCashFlow)
                  : '$--'
              }
            />
            <StatisticDivider />
            <LabeledStatistic
              label="Money weighted rate of return"
              value={<PercentGain value={MWRR} />}
            />
          </StatisticContainer>
        </StatisticsTable>
        <Link
          mt={16}
          to={EXTERNAL_LINKS.FAQ_BACKTESTING}
          target="_blank"
          title="Understanding performance information"
        >
          Understanding performance information
        </Link>
      </ChartAndStatistics>
      <hr style={{ borderBottom: `1px solid ${theme.colors.borderMain}` }} />
    </Flex>
  );
};
