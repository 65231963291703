import moment from 'moment';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useGenerateIdempotencyKeyMutation,
  useGetEditTransferSchedulePreloadQuery,
} from '~/graphql/hooks';
import type { EditScheduleTransferRuleFragment } from '~/graphql/types';
import { useParams } from '~/hooks/useParams';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil, isNil } from '~/utils';

import { TransferModeEnum } from '../create-transfer/steps/TransferDetails.types';
import { TransferContext } from '../create-transfer/TransferContext';

export const EditTransferScheduleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentDate = React.useMemo(() => moment().toISOString(), []);
  const { transferScheduleRuleId } = useParams();

  const { data, loading, error } = useGetEditTransferSchedulePreloadQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      date: currentDate,
      transferRuleId: transferScheduleRuleId as string,
    },
    skip: isNil(transferScheduleRuleId),
  });

  const viewer = data?.viewer;
  const transferRule = data?.node as EditScheduleTransferRuleFragment;

  const [
    generateIdempotencyKey,
    { data: idempotencyKeyData, loading: idempotencyKeyLoading },
  ] = useGenerateIdempotencyKeyMutation({});

  React.useEffect(() => {
    generateIdempotencyKey({
      variables: {
        input: {},
      },
    });
  }, [generateIdempotencyKey]);

  if (loading || idempotencyKeyLoading) {
    return <Spinner fullScreen />;
  }

  if (isNotNil(error) || isNil(viewer) || isNil(transferRule)) {
    return <GenericSystemError />;
  }

  const idempotencyKey =
    idempotencyKeyData?.generateIdempotencyKey?.outcome?.idempotencyKey;

  return (
    <TransferContext.Provider
      value={{
        fromParticipantIdQueryParam: transferRule?.from?.id ?? null,
        idempotencyKey,
        mode: TransferModeEnum.Schedule,
        pivot: 'FROM',
        scenarioQueryParam: null,
        toParticipantIdQueryParam: transferRule?.to?.id ?? null,
        transferRule,
        viewer,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};
