import { HotButton } from '@m1/liquid-react';
import * as React from 'react';

import type {
  PortfolioSliceButtonFragment,
  PortfolioSliceRebalanceButtonFragment,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useModal } from '~/hooks/useModal';
import { useNavigate } from '~/hooks/useNavigate';

export type PortfolioSliceRebalanceButtonProps = {
  account: PortfolioSliceButtonFragment;
  portfolioSlice: PortfolioSliceRebalanceButtonFragment;
};

export const PortfolioSliceRebalanceButton = ({
  account,
  portfolioSlice,
}: PortfolioSliceRebalanceButtonProps) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const { showModal: showRebalanceLockedModal } = useModal(
    'REBALANCE_ACTION_LOCKED',
  );

  const { showModal: showRebalanceModal } = useModal('REBALANCE_ACTION_SET');

  const isValidRebalanceTarget =
    portfolioSlice?.to.type === 'SYSTEM_PIE' ||
    portfolioSlice?.to.type === 'USER_PIE';

  const rebalanceStatus = portfolioSlice?.rebalanceStatus;

  if (!isValidRebalanceTarget || !rebalanceStatus) {
    return null;
  }

  // TODO: figure out where this comes from and update.
  const showLegacyModal = false;
  const disabled = !account.isFunded;

  const handleClickRebalance = () => {
    analytics.recordEvent('m1_invest_managepie_element_clicked', null, {
      'click_id': 'rebalance',
    });

    if (rebalanceStatus.isLocked) {
      showRebalanceLockedModal({
        portfolioSliceId: portfolioSlice.id,
        rebalanceStatus,
      });
    } else if (showLegacyModal) {
      showRebalanceModal({
        portfolioSliceId: portfolioSlice.id,
        rebalanceStatus,
      });
    } else {
      navigate({
        to: `/d/c/rebalance`,
        query: {
          accountId: account.id,
          portfolioName: portfolioSlice.to.name,
          portfolioId: portfolioSlice.id,
        },
      });
    }
  };

  return (
    <HotButton
      data-testid="portfolio-slice-rebalance-button"
      disabled={rebalanceStatus.isSetOnSelf || disabled}
      label="Rebalance"
      onClick={handleClickRebalance}
      size="small"
      icon="rebalance"
    />
  );
};
