import { Button, PL } from '@m1/liquid-react';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { useHardPullConsentPageQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { BackButton } from '../BackButton';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

import { HardPullConsentDetails } from './HardPullConsentDetails';
import { StyledHeader } from './StyledHeader';
import { WithdrawApplicationModal } from './WithdrawApplicationModal';

type HardPullConsentPageProps = {
  onSubmit: () => void;
};

export const HardPullConsentPage = ({ onSubmit }: HardPullConsentPageProps) => {
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const { back } = usePersonalLoanDirectWizardContext();
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

  const { data, loading } = useHardPullConsentPageQuery({
    variables: {
      applicationId,
    },
  });

  const loanTermOptions =
    data?.viewer?.borrow?.personalLoans?.applicationFlow?.loanTermOptions;
  const loanId = data?.viewer.borrow?.personalLoans?.application?.loan?.id;

  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;
  let props;
  if (questions) {
    props = getQuestionProps({
      question: 'HARD_PULL_CONSENT',
      questions,
    });
  }

  const { continueLabel, footer, title } = props || {};

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  if (!loanTermOptions) {
    return <GenericSystemError />;
  }

  return (
    <>
      <WithdrawApplicationModal
        loanId={loanId}
        applicationId={applicationId}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      />
      <BackButton onBackClick={() => back()} />
      <StyledHeader
        content={title ?? 'Take a look at these loan details before you apply.'}
      />
      <HardPullConsentDetails loanTermOptions={loanTermOptions} />
      <PL mb={24} pt={24}>
        {`Offers are good for 30 days, or you can `}
        <Button
          kind="link"
          style={{
            textDecoration: 'underline',
          }}
          onClick={() => setModalIsOpen(true)}
          label="withdraw"
        />
        {` your application now.`}
      </PL>
      <PL
        content={
          footer ??
          'By clicking on the “Apply now” button, we will request your full credit report, which is considered a hard pull, from one or more consumer reporting agencies. A hard pull credit inquiry may affect your credit score.'
        }
        pt={8}
      />
      <ResponsiveButton
        onClick={() => onSubmit()}
        mt={48}
        mb={16}
        label={continueLabel ?? 'Apply now'}
        size="large"
      />
    </>
  );
};
