/* eslint-disable react/sort-comp */

import type { Theme } from '@m1/liquid-react';
import classNames from 'classnames';
import * as d3 from 'd3';
import clamp from 'lodash-es/clamp';
import clone from 'lodash-es/clone';
import head from 'lodash-es/head';
import last from 'lodash-es/last';
import uniqueId from 'lodash-es/uniqueId';
import moment from 'moment-timezone';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { PeriodSelector } from '~/components/period-selector';
import { type Enhancer, withTheme } from '~/hocs';
import { Spinner } from '~/toolbox/spinner';
import { closestIndex } from '~/utils';

import { PercentGain } from '../../percent-gain';

import {
  HistoricalChartContainer,
  TooltipValue,
  Loader,
  PeriodReturn,
  ControlsContainer,
  ChartContainer,
  NoDataMessage,
} from './elements';
import style from './style.module.scss';

type TooltipKind = 'dark' | 'light';
type D3Selection = Record<string, any>;

export type DataPoint = {
  date: string;
  value: number;
};

export type Notation = {
  date: string;
  label: string;
  value: React.ReactElement<any>;
};

type Props = {
  chartMargin: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  data: Array<DataPoint>;
  height: number | string;
  loading: boolean;
  noDataMessage: string;
  notations: Array<Notation>;
  onPeriodChange: (...args: Array<any>) => any;
  period: string;
  periodChange: number | null | undefined;
  periodOptions:
    | Array<{
        label: string;
        value: string;
      }>
    | null
    | undefined;
  startLineValue?: number | null | undefined;
  tooltipProps: (d: DataPoint) => {
    label: string;
    value: string;
  };
  width: number | string;
  withAreaFill: boolean;
  withStartLine: boolean;
  withTooltip: boolean;
  xDomain: any;
  yAxisFormat: string;
};

type ProvidedProps = {
  theme: Theme;
};

type ComponentProps = Props & ProvidedProps;

const TOOLTIP_ARROW_PADDING = 14;
const LINE_ANIMATION_DURATION = 600;
const WIDGET_SIZE = 20;

const parseDate = (
  format: string = 'MMM D',
): ((...args: Array<any>) => any) => {
  return (date: string): string => {
    return moment(date).tz('America/New_York').format(format);
  };
};

const makeTooltipClasses = (
  kind: TooltipKind | null | undefined,
  flags: Record<string, any> = {},
): string => {
  return classNames(style.tooltip, kind && style[kind], {
    [style.forNearLeft]: flags.isOverLeft,
    [style.forNearRight]: flags.isOverRight,
  });
};

const makeWidgetTooltipClasses = (flags: Record<string, any> = {}): string => {
  return classNames(style.tooltip, style.dark, {
    [style.widgetForNearLeft]: flags.isOverLeft,
    [style.widgetForNearRight]: flags.isOverRight,
  });
};

const t = d3
  .transition()
  .ease(d3.easeCircleOut)
  .duration(LINE_ANIMATION_DURATION);

const Tooltip = ({ label, value }: { label: string; value: string }) => (
  <div>
    <div className={style.tooltipLabel}>{label}</div>
    <TooltipValue>{value}</TooltipValue>
  </div>
);

const PeriodChange = ({
  periodChange,
}: {
  periodChange: number | null | undefined;
}) => {
  if (typeof periodChange !== 'number') {
    return null;
  }
  return (
    <PeriodReturn>
      <PercentGain value={periodChange} />
    </PeriodReturn>
  );
};

class HistoricalChartComponent extends React.Component<ComponentProps> {
  static defaultProps = {
    width: 700,
    height: 280,
    chartMargin: {
      top: 0,
      right: 0,
      bottom: 30,
      left: 0,
    },
    tooltipProps: (d: DataPoint) => ({
      label: moment.utc(d.date).format('l'),
      value: `$${d.value.toFixed(2)}`,
    }),
    notations: [],
    noDataMessage: 'No data available',
    loading: false,
    withAreaFill: false,
    withStartLine: true,
    withTooltip: true,
    yAxisFormat: 'f',
  };

  d3: Record<string, any> = {};
  tooltipId: string = uniqueId('historical-chart-tooltip');

  // @ts-expect-error - TS2564 - Property 'xAxisSelection' has no initializer and is not definitely assigned in the constructor.
  xAxisSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'yAxisSelection' has no initializer and is not definitely assigned in the constructor.
  yAxisSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'valueAreaSelection' has no initializer and is not definitely assigned in the constructor.
  valueAreaSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'valueLineSelection' has no initializer and is not definitely assigned in the constructor.
  valueLineSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'startLineSelection' has no initializer and is not definitely assigned in the constructor.
  startLineSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'notationsContainerSelection' has no initializer and is not definitely assigned in the constructor.
  notationsContainerSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'tooltipSelection' has no initializer and is not definitely assigned in the constructor.
  tooltipSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'tooltipTargetSelection' has no initializer and is not definitely assigned in the constructor.
  tooltipTargetSelection: D3Selection;

  componentDidMount() {
    // @ts-expect-error - TS2339 - Property 'getBoundingClientRect' does not exist on type 'ReactInstance'.
    const { height, width } = this.refs.root.getBoundingClientRect();

    this.setupChart(width, height);
    this.updateChart(true);
  }

  componentDidUpdate() {
    this.updateChart();
  }

  render() {
    const {
      loading,
      periodOptions,
      periodChange,
      period,
      onPeriodChange,
      height,
      width,
      noDataMessage,
    } = this.props;
    return (
      <HistoricalChartContainer ref="root">
        {loading && (
          <Loader>
            <Spinner />
          </Loader>
        )}
        {Array.isArray(periodOptions) && (
          <ControlsContainer>
            <PeriodChange periodChange={periodChange} />
            {periodOptions && (
              <PeriodSelector
                periods={periodOptions}
                period={period}
                onChangePeriod={onPeriodChange}
              />
            )}
          </ControlsContainer>
        )}
        <ChartContainer ref="chartWrapper">
          <svg ref="chart" height={height} width={width} />
          {!this.hasData() && !loading && (
            <NoDataMessage>{noDataMessage}</NoDataMessage>
          )}
        </ChartContainer>
      </HistoricalChartContainer>
    );
  }

  hasData(): boolean {
    return Array.isArray(this.props.data) && this.props.data.length >= 1;
  }

  setupChart(width: number, height: number) {
    const { chartMargin } = this.props;

    const xScale = d3
      .scalePoint()
      .range([0, width - chartMargin.left - chartMargin.right]);

    const yScale = d3.scaleLinear().rangeRound([height, 0]);

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat(parseDate())
      .tickSizeInner(-height)
      .tickSizeOuter(0)
      .tickPadding(8);

    const yAxis = d3
      .axisRight(yScale)
      .ticks(clamp(height / 80, 4, 8), this.props.yAxisFormat)
      .tickSizeInner(width)
      .tickSizeOuter(0)
      .tickPadding(-8);

    const valueLine = d3
      .line()
      // @ts-expect-error - TS2339 - Property 'date' does not exist on type '[number, number]'.
      .x((d) => xScale(d.date))
      // @ts-expect-error - TS2551 - Property 'value' does not exist on type '[number, number]'. Did you mean 'values'?
      .y((d) => yScale(d.value));

    const valueArea = d3
      .area()
      // @ts-expect-error - TS2339 - Property 'date' does not exist on type '[number, number]'.
      .x((d) => xScale(d.date))
      .y0(yScale(0))
      // @ts-expect-error - TS2551 - Property 'value' does not exist on type '[number, number]'. Did you mean 'values'?
      .y1((d) => yScale(d.value));

    const svg = d3
      // @ts-expect-error - TS2769 - No overload matches this call.
      .select(this.refs.chart)
      .attr('width', width + chartMargin.left + chartMargin.right)
      .attr('height', height + chartMargin.top + chartMargin.bottom)
      .append('g')
      .attr('transform', `translate(${chartMargin.left},${chartMargin.top})`);

    this.xAxisSelection = svg
      .append('g')
      .attr('class', style.xAxis)
      .attr('transform', `translate(0,${height})`);

    this.yAxisSelection = svg.append('g').attr('class', style.yAxis);

    this.valueAreaSelection = svg.append('path');

    this.valueLineSelection = svg
      .append('path')
      .attr('class', style.line)
      .attr('shape-rendering', 'geometricPrecision')
      .attr('style', `stroke: ${this.props.theme.colors.primaryTint};`);

    this.startLineSelection = svg
      .append('path')
      .attr('class', style.startLine)
      .attr('shape-rendering', 'crispEdges');

    this.tooltipSelection = d3
      // @ts-expect-error - TS2769 - No overload matches this call.
      .select(this.refs.chartWrapper)
      .append('div')
      // @ts-expect-error - TS2554 - Expected 1-2 arguments, but got 0.
      .attr('class', makeTooltipClasses())
      .attr('id', this.tooltipId);

    this.tooltipTargetSelection = svg
      .append('rect')
      .attr('width', width)
      .attr('height', height - WIDGET_SIZE)
      .attr('fill-opacity', 0);

    this.notationsContainerSelection = svg.append('g').attr('class', 'widgets');

    this.d3 = {
      valueArea,
      valueLine,
      xScale,
      yScale,
      xAxis,
      yAxis,
    };
  }

  updateChart(initialRender: boolean = false): void {
    const hasData = this.hasData();

    this.updateScales(hasData);
    this.updateAxes(hasData);
    this.updateValueLine(initialRender);

    if (this.props.withStartLine) {
      this.updateStartLine(hasData);
    }

    this.updateNotations();

    if (this.props.withTooltip) {
      this.updateTooltipTarget();
    }

    if (this.props.withAreaFill) {
      this.updateAreaFill();
    }
  }

  updateScales(chartHasData: boolean): HistoricalChartComponent {
    const { data, startLineValue } = this.props;
    const { xScale, yScale } = this.d3;

    let xDomain;
    if (this.props.xDomain) {
      xDomain = this.props.xDomain;
    } else if (chartHasData) {
      xDomain = data.map((d) => d.date);
    } else {
      const xInterpolator = d3.interpolateDate(
        moment().subtract(1, 'month').toDate(),
        moment().toDate(),
      );

      // @ts-expect-error - TS2345 - Argument of type '(a: any, b: any) => Date' is not assignable to parameter of type '(t: number) => Date'. | TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
      xDomain = d3.quantize((a, b) => {
        // @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
        return clone(xInterpolator(a, b));
      }, 8);
    }

    let yDomain;
    if (chartHasData) {
      let [min, max] = d3.extent(data, (d) => d.value);
      if (startLineValue !== undefined && startLineValue !== null) {
        // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
        min = Math.min(min, startLineValue);
        // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
        max = Math.max(max, startLineValue);
      }
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
      const scalePadding = (max - min) * 0.15;
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
      yDomain = [min - scalePadding, max + scalePadding];
    } else {
      yDomain = [0, 100];
    }

    this.d3.xDomain = xDomain;
    this.d3.yDomain = yDomain;

    xScale.domain(xDomain);
    yScale.domain(yDomain);

    return this;
  }

  updateAxes(chartHasData: boolean): HistoricalChartComponent {
    const { data } = this.props;
    const { xAxis, xDomain, yAxis } = this.d3;

    let ticks;
    let tickValues;

    if (chartHasData) {
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      const firstDate = moment(head(data).date);
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      const lastDate = moment(last(data).date);
      if (!firstDate.diff(lastDate, 'days')) {
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        const m = moment(head(data).date).tz('America/New_York');
        tickValues = [
          m.hours(9.5).toISOString(),
          m.hours(10.5).toISOString(),
          m.hours(11.5).toISOString(),
          m.hours(12.5).toISOString(),
          m.hours(13.5).toISOString(),
          m.hours(14.5).toISOString(),
          m.hours(15.5).toISOString(),
        ];
        ticks = tickValues.length;
      } else if (!firstDate.diff(lastDate, 'weeks')) {
        // Run through data and add a tick value for each day in set.
        const days = new Map();
        for (const datum of data) {
          const d = moment(datum.date);
          if (!days.has(d.day())) {
            days.set(d.day(), d);
          }
        }
        tickValues = Array.from(days.values()).map((day) => day.toISOString());
        ticks = tickValues.length;
      } else {
        const lengthInterpolator = d3.interpolate(
          0,
          Math.max(data.length - 1, 0),
        );

        ticks = Math.max(Math.min(data.length, 6), 2);
        tickValues = d3
          .quantize(lengthInterpolator, ticks)
          .map((d) => Math.round(d))
          .map((d) => data[d].date);
      }
    } else {
      ticks = 6;
      tickValues = xDomain;
    }

    const tickFormat = (date: string): string => {
      const lastTick = moment(last(xDomain));
      const firstTick = moment(head(xDomain));
      if (firstTick.isSame(lastTick, 'day')) {
        return parseDate('LT')(date);
      } else if (lastTick.year() !== firstTick.year()) {
        return parseDate(`MMM D 'YY`)(date);
      }

      return parseDate()(date);
    };

    xAxis.ticks(ticks).tickValues(tickValues).tickFormat(tickFormat);

    this.xAxisSelection.call(xAxis);
    this.yAxisSelection.style('opacity', Number(chartHasData)).call(yAxis);

    return this;
  }

  updateValueLine(isInitialRender: boolean): HistoricalChartComponent {
    const { height, data } = this.props;
    const { valueLine, xScale } = this.d3;

    this.valueLineSelection.datum(data);
    if (isInitialRender) {
      this.valueLineSelection
        .attr(
          'd',
          d3
            .line()
            // @ts-expect-error - TS2339 - Property 'date' does not exist on type '[number, number]'.
            .x((d) => xScale(d.date))
            // @ts-expect-error - TS2769 - No overload matches this call.
            .y(height),
        )
        .transition(t);
    }
    this.valueLineSelection.attr('d', valueLine);

    return this;
  }

  updateStartLine(chartHasData: boolean): HistoricalChartComponent {
    const { width, data, startLineValue } = this.props;
    const { yScale } = this.d3;

    let value;
    if (chartHasData) {
      value =
        startLineValue !== undefined && startLineValue !== null
          ? yScale(startLineValue)
          : // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            yScale(head(data).value);
    } else {
      value = 0;
    }
    this.startLineSelection.attr('d', `M 0, ${value} L ${width}, ${value}`);

    return this;
  }

  updateNotations(): HistoricalChartComponent {
    const { height, notations } = this.props;
    const { xScale } = this.d3;

    function calculateXValue(date: string): number {
      return Math.min(xScale(date), window.innerWidth - WIDGET_SIZE);
    }

    const notationGroups = this.notationsContainerSelection
      .selectAll('g')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .data(notations, (d) => d.date);

    const enteringNotations = notationGroups.enter().append('g');
    enteringNotations
      .attr('class', `${style.point} ${style.filled}`)
      .insert('rect')
      .attr('width', WIDGET_SIZE)
      .attr('height', WIDGET_SIZE)
      .attr('transform', `translate(${-WIDGET_SIZE / 2},${-WIDGET_SIZE / 2})`)
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .on('mouseover', (d) => {
        const xValue = calculateXValue(d.date);
        const viewportFlags = {
          isOverLeft: d3.event.pageX < 55,
          isOverRight: window.innerWidth - xValue < 55,
        };

        this.tooltipSelection
          .attr('class', makeWidgetTooltipClasses(viewportFlags))
          .style('left', `${xValue}px`)
          // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
          .style('top', `${height - TOOLTIP_ARROW_PADDING - WIDGET_SIZE}px`);

        const tooltipElem = document.querySelector(`#${this.tooltipId}`);
        if (tooltipElem) {
          ReactDOM.render(d.value, tooltipElem, this.showTooltip);
        }
      })
      .on('mouseout', this.hideTooltip);

    enteringNotations
      .append('text')
      .attr('x', '0')
      .attr('y', '3')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .html((d) => d.label);

    // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
    enteringNotations.merge(notationGroups).attr('transform', (d) => {
      return `translate(${calculateXValue(d.date)}, ${
        // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
        height - WIDGET_SIZE / 2
      })`;
    });

    notationGroups.exit().remove();

    return this;
  }

  updateTooltipTarget(): HistoricalChartComponent {
    const { chartMargin, data } = this.props;
    const { xScale, yScale } = this.d3;

    const v = data.map((datum) => xScale(datum.date));
    this.tooltipTargetSelection
      .on('mouseover', this.showTooltip)
      .on('mousemove touchstart touchmove touchend', () => {
        const [x] = d3.mouse(d3.event.currentTarget);
        const index = closestIndex(v, x);
        if (index === undefined || index === null) {
          return;
        }

        const xValue = v[index];
        const d = data[index];

        const viewportFlags = {
          isOverLeft: d3.event.pageX < 55,
          isOverRight: window.innerWidth - xValue < 55,
        };

        this.tooltipSelection
          .attr('class', makeTooltipClasses('light', viewportFlags))
          .style('left', `${xScale(d.date) + chartMargin.left}px`)
          .style('top', `${yScale(d.value) - TOOLTIP_ARROW_PADDING}px`);

        const domElement = document.querySelector(`#${this.tooltipId}`);
        if (domElement) {
          ReactDOM.render(
            <Tooltip {...this.props.tooltipProps(d)} />,
            domElement,
          );
        }
      })
      .on('mouseout', this.hideTooltip);

    return this;
  }

  updateAreaFill(): HistoricalChartComponent {
    const { data } = this.props;
    const { valueArea } = this.d3;

    this.valueAreaSelection.datum(data);
    this.valueAreaSelection.attr('d', valueArea);
    this.valueAreaSelection.style(
      'fill',
      this.props.theme.colors.backgroundPrimarySubtle,
    );

    return this;
  }

  showTooltip = (): void => {
    this.tooltipSelection.style('opacity', 1);
  };

  hideTooltip = (): void => {
    this.tooltipSelection.style('opacity', 0);
  };
}

// @ts-expect-error - TS2322 - Type 'WithThemeFnInterface<DefaultTheme>' is not assignable to type 'Enhancer<ComponentProps, Props>'.
const enhancer: Enhancer<ComponentProps, Props> = withTheme;

export const HistoricalChart = enhancer(HistoricalChartComponent) as any;
