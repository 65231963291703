import * as React from 'react';

import { CopyableCard } from '~/components/CopyableCard';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import type { PortfolioSlicePieFragment } from '~/graphql/types';
import { useEnvironment } from '~/hooks/useEnvironment';

import { PortfolioDetailsContext } from '../PortfolioDetailsContext';

import { PieCardUpperContent } from './PieCardUpperContent';
import { PortfolioSlicePendingOrderBanner } from './PortfolioSlicePendingOrderBanner';
import { PortfolioSlicePendingRebalanceBanner } from './PortfolioSlicePendingRebalanceBanner';
import { PortfolioSlicePie } from './PortfolioSlicePie';

export const PieCardContent = () => {
  const { portfolioSlice, sorter, memoizedGenerateColorsForPie } =
    React.useContext(PortfolioDetailsContext);
  const [environment] = useEnvironment();

  // Open Search requires authentication when running lens locally. Catch this error and explain
  // how to make it work. This is only applicable in the local lens environment.
  const errorContent =
    environment.label === 'local' ? (
      <span>
        There was a problem loading the slices. Please switch to another
        environment or authenticate with Open Search by running this command in
        your terminal:
        <CopyableCard value="aws sso login" mt={8}>
          <code>aws sso login</code>
        </CopyableCard>
      </span>
    ) : undefined;

  return (
    <>
      <PortfolioSlicePendingOrderBanner
        mb={16}
        portfolioSlice={portfolioSlice}
      />
      <PortfolioSlicePendingRebalanceBanner
        mb={16}
        portfolioSlice={portfolioSlice}
      />
      <PieCardUpperContent />
      <PageErrorBoundary content={errorContent}>
        <PortfolioSlicePie
          colors={memoizedGenerateColorsForPie}
          portfolioSlice={portfolioSlice as PortfolioSlicePieFragment}
          highlightedSlice={
            portfolioSlice.to.__typename &&
            ['Equity', 'Fund'].includes(portfolioSlice.to.__typename)
              ? portfolioSlice.id
              : null
          }
          sort={sorter}
        />
      </PageErrorBoundary>
    </>
  );
};
