import { Box, Flex, PL, PM, PXL, styled, Card } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { HelpPopup } from '~/components/HelpPopup';
import type { MoveSlicesDestinationMenuFragment } from '~/graphql/types';
import type { PortfolioSlice } from '~/pie-trees';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';

import { MoveSlicesDestinationRow } from './MoveSlicesDestinationRow';

const StyledLabel = styled(PM)`
  cursor: pointer;
  font-weight: 600;
`;

const StyledScrollableList = styled(Flex)`
  overflow-y: scroll;
`;

const EmptyState = () => (
  <Card
    display="flex"
    alignItems="center"
    borderRadius={12}
    flexDirection="column"
    p={24}
    width={422}
  >
    <Illustration
      // @ts-expect-error - TS2322 - Type '{ alt: string; height: number; name: "investActivityEmptyState"; width: number; mb: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
      alt=""
      height={78}
      name="investActivityEmptyState"
      width={78}
      mb={16}
    />
    <PXL fontWeight={600} mb={16}>
      Create a Pie to move Slices
    </PXL>
    <PL color="foregroundNeutralSecondary" px={8} textAlign="center">
      You need to create another Pie to move Slices around this account. You
      can’t move Slices between accounts.
    </PL>
    {/* TODO add the create button here once we go to implement that. */}
  </Card>
);

export type MoveSlicesDestinationMenuProps = {
  isRefetchingDestinations?: boolean;
  moveToPieContent: MoveSlicesDestinationMenuFragment | null | undefined;
  portfolioSlicesOfSlicesMarkedForDeletion: Array<PortfolioSlice>;
  setShowMoveSlicesTutorial: (showTutorial: boolean) => void;
  slicesToMove: Array<PortfolioSlice>;
};

export const MoveSlicesDestinationMenu = ({
  isRefetchingDestinations,
  moveToPieContent,
  portfolioSlicesOfSlicesMarkedForDeletion,
  setShowMoveSlicesTutorial,
  slicesToMove,
}: MoveSlicesDestinationMenuProps) => {
  const isDesktop = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.LARGE,
  });

  if (!moveToPieContent) {
    return null;
  }

  const {
    primaryLabel,
    secondaryLabel,
    tutorialLabel,
    tooltip,
    validAugmentedPortfolioSliceDestinations,
  } = moveToPieContent;

  if (!validAugmentedPortfolioSliceDestinations) {
    return <EmptyState />;
  }

  return (
    <Box width={335}>
      <Flex
        borderColor="borderMain"
        borderStyle="solid"
        borderWidth="0 0 1px 0"
        flexDirection="column"
        padding={16}
      >
        <Flex mb={8}>
          <PL fontWeight={600} color="foregroundNeutralSecondary">
            {primaryLabel}
          </PL>
          {tooltip?.text && (
            <HelpPopup kind="default" placement="bottom-end">
              <PM color="foregroundNeutralMain">{tooltip.text}</PM>
            </HelpPopup>
          )}
        </Flex>
        <PM mb={8}>{secondaryLabel}</PM>
        {tutorialLabel && (
          <StyledLabel
            color="primary"
            onClick={() => {
              setShowMoveSlicesTutorial(true);
            }}
          >
            {tutorialLabel}
          </StyledLabel>
        )}
      </Flex>
      <Flex flexDirection="column">
        {isRefetchingDestinations ? (
          <Box py={32}>
            <Spinner />
          </Box>
        ) : (
          <StyledScrollableList
            flexDirection="column"
            maxHeight={isDesktop ? 250 : 180}
          >
            {validAugmentedPortfolioSliceDestinations.map(
              (destination, index) => {
                const destinationPendingDeletion =
                  portfolioSlicesOfSlicesMarkedForDeletion.find(
                    (slice) =>
                      destination?.portfolioSlice?.id &&
                      destination.portfolioSlice.id === slice.id,
                  );

                if (
                  destinationPendingDeletion ||
                  !destination?.portfolioSlice
                ) {
                  return null;
                }

                if (destination.portfolioSlice) {
                  return (
                    <MoveSlicesDestinationRow
                      portfolioSlice={destination.portfolioSlice}
                      validity={destination.validity}
                      key={index}
                      slicesToMove={slicesToMove}
                    />
                  );
                }
              },
            )}
          </StyledScrollableList>
        )}
      </Flex>
    </Box>
  );
};
