import { Input } from '@m1/liquid-react';
import * as React from 'react';

import { formatNumber } from '~/utils/formatting';

type Props = {
  format: 'numeric' | 'currency';
  label?: string;
  name: string;
  onChange: (...args: Array<any>) => any;
  placeholder?: string;
  value: number | null | undefined;
};

type State = {
  input: string;
  isValid: boolean;
};

const LimitFormatter = Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 4,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export class LimitInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      input: formatLimitValue(props.value),
      isValid: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value === null) {
      this.setState({
        input: '',
        isValid: true,
      });
    }
  }

  render() {
    return (
      <Input
        name={this.props.name}
        error={!this.state.isValid}
        onChange={this.handleChange}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.state.input}
        size="small"
      />
    );
  }

  handleChange(e: React.SyntheticEvent): void {
    // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
    const input = e.target.value;
    const value = parseLimitInput(input);
    const isValid = value !== false;

    this.setState({
      input,
      isValid,
    });
    if (isValid) {
      this.props.onChange(value);
    }
  }
}

/*
 * Utility constants and functions
 */

const SCREENER_LIMIT_WHITELIST = [',', 'k', 'm', 'b', 't'];
const validLimit = new RegExp(/^(\d|,)*(\.\d*)*(t|b|m|k)?$/, 'gi');
const whitelist = new RegExp(`(${SCREENER_LIMIT_WHITELIST.join('|')})`, 'gi');
const MULTIPLIER_MAP = {
  t: 1000000000000,
  b: 1000000000,
  m: 1000000,
  k: 1000,
};

function formatLimitValue(value: number | null | undefined): string {
  if (value === undefined || value === null) {
    return '';
  }

  return value >= 1000000
    ? LimitFormatter.format(value)
    : (formatNumber(value) ?? '');
}

// TODO: Account for LimitInput format.
export function parseLimitInput(input: string): number | false | null {
  if (input === '') {
    return null;
  } else if (input.search(validLimit) === -1) {
    return false;
  }

  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ t: number; b: number; m: number; k: number; }'.
  const multipler = MULTIPLIER_MAP[input.slice(-1).toLowerCase()] || 1;
  const num = Number(input.replace(whitelist, ''));

  if (Number.isNaN(num)) {
    return false;
  }

  return num * multipler;
}
