import * as React from 'react';
import { useFormContext, type Path } from 'react-hook-form';

import { printableAsciiChars } from '~/forms/validators';

import { ControlledInput } from '../ControlledInput';

import type { AddressSectionProps, AddressFormFields } from './types';

const FIELD_NAME = 'lineOne';

export const LineOneInput = <NS extends string = ''>({
  backgroundColor,
  disabled,
  namespace,
  label,
  ...props
}: AddressSectionProps<NS>) => {
  const { control } = useFormContext<AddressFormFields<NS>>();
  const name = (
    namespace ? `${namespace as NS}.${FIELD_NAME}` : FIELD_NAME
  ) as Path<AddressFormFields<NS>>;

  return (
    <ControlledInput
      control={control}
      name={name}
      rules={{
        required: 'Required',
        maxLength: 30,
        validate: {
          printableAsciiChars: (value) =>
            typeof value === 'string' && printableAsciiChars(value),
        },
      }}
      backgroundColor={backgroundColor}
      label={label ?? 'Address'}
      placeholder=""
      maxLength={30}
      disabled={disabled}
      {...props}
    />
  );
};
