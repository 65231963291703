import { Flex, styled, PM, PS, type BoxProps } from '@m1/liquid-react';
import { Icon, type AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import type { AppImage as AppImageType } from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { AppImage } from '~/lens-toolbox/AppImage';
import type { SegmentEventName } from '~/loggers/analytics-reporter/segment.types';
import { SIDENAV_TRANSITION_DURATION } from '~/pages/dashboard/navigation/components/SideNav';
import { Link } from '~/toolbox/link/Link';

const StyledSubText = styled(PS)`
  white-space: break-spaces;
`;

const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  margin-left: 16px;
`;

const StyledNavLink = styled(Flex)<{
  showActive: boolean;
  hasLeftIcon: boolean;
  hasTopLeftIcon: boolean;
  selected: boolean;
}>`
  display: inline-flex;
  align-items: ${({ hasTopLeftIcon }) => !hasTopLeftIcon && 'center'};
  cursor: pointer;
  user-select: none;

  padding: ${({ hasLeftIcon, hasTopLeftIcon }) =>
    hasLeftIcon || hasTopLeftIcon ? `8px 19px 8px 24px` : `4px 19px 8px 49px`};
  margin: 0 1px 0px 0;
  width: 100%;
  border-right: ${({ selected, theme }) =>
    selected && `4px solid ${theme.colors.blue04}`};
  background-color: ${({ selected, theme, showActive }) => {
    if (selected) {
      return theme.colors.blue01;
    }
    if (showActive) {
      return theme.colors.grey01;
    }
    return theme.colors.backgroundNeutralSecondary;
  }};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.blue04 : theme.colors.foregroundNeutralMain};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey02};
  }
  &:focus {
    background-color: ${({ theme, selected }) => {
      if (selected) {
        return theme.colors.blue01;
      }
      return theme.colors.grey02;
    }};
    border-right: ${({ selected, theme }) =>
      selected && `4px solid ${theme.colors.blue04}`};
  }
`;

export type SideNavLinkProps = {
  children?: any;
  icon?: {
    position: 'right' | 'left' | 'topLeft';
    default: AppImageType | AllIconName;
    active: AppImageType | AllIconName;
  };
  label: string | JSX.Element;
  subText?: string | JSX.Element;
  linkTo?: string;
  onClick?: () => void;
  openInNewWindow?: string;
  selected?: boolean;
  analyticsProps?: AnalyticsProps;
  id?: string;
};

type AnalyticsProps = {
  name: SegmentEventName;
  parameters?: Record<string, string>;
};

// modify the link properties as needed
const getLinkProps = ({
  linkTo,
  hasChildren,
  openInNewWindow,
  expanded,
  setExpanded,
  onClick,
}: {
  linkTo: string;
  onClick?: () => void;
  hasChildren: boolean;
  openInNewWindow: string;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
}) => {
  if (onClick !== undefined) {
    return {
      onClick,
      onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          onClick();
        }
      },
    };
  }
  if (linkTo !== '' && !hasChildren) {
    return { as: Link, to: linkTo };
  }

  if (openInNewWindow !== '') {
    return { as: StyledExternalLink, href: openInNewWindow, target: '_blank' };
  }

  if (hasChildren === true) {
    return {
      onClick: () => {
        setExpanded(!expanded);
      },
      onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          setExpanded(!expanded);
        }
      },
    };
  }
  return {};
};

const StyledLabelContainer = styled(Flex)<{ $isOpen: boolean }>`
  white-space: nowrap;
  ${({ $isOpen }) => {
    if ($isOpen) {
      return `
        transition: opacity ${SIDENAV_TRANSITION_DURATION} ${SIDENAV_TRANSITION_DURATION};
        opacity: 1;
      `;
    }

    return `
      transition: opacity ${SIDENAV_TRANSITION_DURATION};
      opacity: 0;
    `;
  }}
`;

type NavIconProps = Omit<BoxProps, 'height' | 'width'> & {
  icon: AppImageType | AllIconName;
};

const NavIcon = ({ icon, ...props }: NavIconProps) => {
  if (typeof icon === 'string') {
    return <Icon name={icon} {...props} />;
  }
  return <AppImage appImage={icon} {...props} />;
};

export const SideNavLink = ({
  children,
  icon,
  label,
  subText = '',
  linkTo = '',
  onClick = undefined,
  openInNewWindow = '',
  selected = false,
  analyticsProps,
  id,
}: React.PropsWithChildren<SideNavLinkProps>) => {
  const { analytics } = React.useContext(AppContext);

  const logEvent = () => {
    if (analyticsProps) {
      analytics.recordEvent(analyticsProps.name, null, {
        ...(analyticsProps?.parameters ? analyticsProps.parameters : {}),
      });
    }
  };
  const { isOpen } = useLayout();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [autoExpanded, setAutoExpanded] = React.useState<boolean>(false);
  const hasChildren = children !== undefined;
  const hasAction = linkTo !== '' || typeof onClick === 'function';
  const linkProps = getLinkProps({
    linkTo,
    expanded,
    hasChildren,
    openInNewWindow,
    setExpanded,
    onClick,
  });

  const showSelected = hasAction && selected && children === undefined;
  const showActive = showSelected || (selected && children !== undefined);

  React.useEffect(() => {
    // Auto-expand the section if it is newly selected
    // and has not been auto-expanded already.
    // Once auto-expanded once, it can be closed manually.
    if (selected && !expanded && !autoExpanded) {
      setExpanded(true);
      setAutoExpanded(true);
    }
    // Reset auto-expanded state when navigating away from this section.
    if (!selected) {
      setAutoExpanded(false);
    }
  }, [selected, expanded, setExpanded, autoExpanded, setAutoExpanded]);

  const linkId =
    id ||
    (typeof label === 'string'
      ? label.toLocaleLowerCase().replaceAll(' ', '')
      : undefined);

  return (
    <>
      {/* TODO: fix this type error */}
      <StyledNavLink
        data-testid={linkId}
        id={linkId}
        showActive={showActive}
        hasLeftIcon={icon?.position === 'left'}
        hasTopLeftIcon={icon?.position === 'topLeft'}
        selected={showSelected}
        tabIndex={0}
        {...linkProps}
        onClick={() => {
          linkProps?.onClick && linkProps.onClick();
          logEvent();
        }}
      >
        {icon?.position === 'left' || icon?.position === 'topLeft' ? (
          <NavIcon icon={showActive ? icon.active : icon.default} />
        ) : null}
        <StyledLabelContainer
          $isOpen={isOpen}
          marginLeft="16px"
          width="100%"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Flex width="100%" flexDirection="row" alignItems="center">
            <PM
              fontWeight={600}
              color="foregroundNeutralMain"
              textOverflow="ellipsis"
              width="100%"
              overflow="hidden"
            >
              {label}
            </PM>
            {!hasChildren && isOpen && icon?.position === 'right' ? (
              <NavIcon
                marginLeft="auto"
                color="foregroundNeutralMain"
                icon={showActive ? icon.active : icon.default}
              />
            ) : null}
          </Flex>
          {subText !== '' && isOpen ? (
            <StyledSubText color="foregroundNeutralSecondary">
              {subText}
            </StyledSubText>
          ) : null}
        </StyledLabelContainer>
        {hasChildren && isOpen ? (
          <Icon
            marginLeft="auto"
            color="foregroundNeutralMain"
            name={expanded ? 'caretUp16' : 'caretDown16'}
          />
        ) : null}
      </StyledNavLink>
      {expanded && isOpen && hasChildren ? <>{children}</> : null}
    </>
  );
};
