import { Box, Text, type TextProps } from '@m1/liquid-react';
import * as React from 'react';

type TransferValidationMessagesProps = TextProps & {
  messages: Record<string, any>[];
  messageGap?: number;
  isScheduledTransfer?: boolean;
};

export const TransferValidationMessages = ({
  messages,
  messageGap,
  isScheduledTransfer,
  ...rest
}: TransferValidationMessagesProps) => {
  const flatList = messages.flatMap((message) => message.text);
  return (
    <Box mt={isScheduledTransfer ? 4 : 16}>
      {flatList.map((message, index) => {
        const isFirst = index === 0;
        const marginTop = messageGap ?? 0;
        return (
          <Box key={`message-${index}`} mt={isFirst ? 0 : marginTop}>
            <Text
              {...{
                color: 'foregroundNeutralMain',
                font: rest.font ?? 'PL',
                ...rest,
              }}
            >
              {message}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};
