import {
  type URLSearchParamsInit,
  // eslint-disable-next-line no-restricted-imports
  useSearchParams as useRouterSearchParams,
} from 'react-router-dom';

/** Params must be defined */
export const useSearchParams = (
  defaultInit?: URLSearchParamsInit,
): ReturnType<typeof useRouterSearchParams> => {
  const [searchParams, setSearchParams] = useRouterSearchParams(defaultInit);

  return [searchParams, setSearchParams];
};
