import { LM, Box, styled, css, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useSliceByIdQuery } from '~/graphql/hooks';
import type { SliceableFragment } from '~/graphql/types';
import type { AppState } from '~/redux';
import { highlightSlice } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { FakeNameCell, SliceableNameCell } from './SliceableNameCell';

type Props = {
  onClick?: (sliceableId: string) => void;
  percentage: number;
  to: {
    id: string;
    [k: string]: any;
  };
  isClickable?: boolean;
};

const Slice = styled(Box)<{
  isHighlighted: boolean;
  onClick: (...args: Array<any>) => any;
  isClickable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  flex: 1;
  overflow-x: hidden;

  background-color: ${({ isHighlighted, theme }) =>
    isHighlighted ? theme.colors.backgroundNeutralTertiary : 'transparent'};

  ${({ onClick, isClickable }) =>
    Boolean(onClick) && isClickable !== false
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: default;
        `}
`;

export const SliceById = ({
  to,
  onClick,
  percentage,
  isClickable = true,
}: Props) => {
  const dispatch = useDispatch();
  const highlightedSlice = useSelector(
    (state: AppState) => state.interface.highlightedSliceId,
  );

  const { data, loading } = useSliceByIdQuery({
    variables: {
      id: to.id,
    },
  });

  const handleClick = (): void => {
    isClickable && onClick?.(to.id);
  };
  const handleEnter = (): void => dispatch(highlightSlice(to.id));
  const handleExit = (): void => dispatch(highlightSlice(null));

  const sliceable = data?.node;

  if (loading) {
    return null;
  }

  return (
    <Slice
      isHighlighted={Boolean(highlightedSlice && highlightedSlice === to.id)}
      onClick={handleClick}
      onTouchStart={handleEnter}
      onTouchEnd={handleExit}
      onMouseEnter={handleEnter}
      onMouseLeave={handleExit}
      isClickable={isClickable}
    >
      <Flex minWidth={0}>
        {sliceable ? (
          <SliceableNameCell sliceable={sliceable as SliceableFragment} />
        ) : (
          <FakeNameCell sliceable={to as SliceableFragment} />
        )}
      </Flex>
      <Flex justifyContent="flex-end" pl={16}>
        <LM content={`${percentage}%`} />
      </Flex>
    </Slice>
  );
};
