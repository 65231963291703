import React from 'react';

import type {
  ModelPortfolioScreenerQuery,
  PieBacktestPeriodEnum,
} from '~/graphql/types';
import { Table } from '~/toolbox/table';

import { ModelPortfolioTableRow } from './ModelPortfolioTableRow';

type ModelPortfolioTableBodyProps = {
  pies: ModelPortfolioScreenerQuery['viewer']['screenSystemPies']['edges'];
  period: PieBacktestPeriodEnum;
};

export const ModelPortfolioTableBody = ({
  pies,
  period,
}: ModelPortfolioTableBodyProps) => (
  <Table.Body emptyMessage="No pies to display!">
    {pies?.map((pie) => {
      if (!pie?.node) {
        return null;
      }

      return (
        <ModelPortfolioTableRow
          pie={pie.node}
          period={period}
          key={pie.node.id}
        />
      );
    })}
  </Table.Body>
);
