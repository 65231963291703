import React from 'react';
import { Outlet } from 'react-router-dom';

import { useWireTransferAccountsQuery } from '~/graphql/hooks';

import { WireTransferContext } from './useWireTransferContext';

export const WireTransferProvider = () => {
  const [selectedAccount, setSelectedAccount] = React.useState<null | string>(
    null,
  );

  const { data } = useWireTransferAccountsQuery();

  const accounts =
    data?.viewer.transfers?.moveMoneyV2?.wireTransferParticipants?.list;

  return (
    <WireTransferContext.Provider
      value={{
        accounts,
        selectedAccount,
        setSelectedAccount,
      }}
    >
      <Outlet />
    </WireTransferContext.Provider>
  );
};
