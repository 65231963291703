import { ApolloError } from '@apollo/client';
import { Flex } from '@m1/liquid-react';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SavingsFundingComplete } from '~/flows/components/onboarding/savings/steps';
import {
  useInitialFundingQuery,
  useCreateTransferInstanceMutation,
  useSetScheduledTransferRuleMutation,
} from '~/graphql/hooks';
import type { RecurrenceScheduleInput } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { SavingsInitialFundingForm } from './components';

type DataFromStore = {
  fundAccountAmount: number;
  schedule: Maybe<RecurrenceScheduleInput>;
};
export const SavingsOnboardingInitialFundingPage = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const [showSkipState, setShowSkipState] = React.useState<boolean>(false);
  const [showReceipt, setShowReceipt] = React.useState<boolean>(false);

  const [createTransferInstance, createTransferInstanceResult] =
    useCreateTransferInstanceMutation();

  const [setScheduledTransferRule, setScheduledTransferRuleResult] =
    useSetScheduledTransferRuleMutation();

  const { schedule, fundAccountAmount } = useSelector<DataFromStore>(
    (state) => {
      return {
        fundAccountAmount:
          state.form['fund-account']?.values?.fundAccountAmount ?? 0,
        schedule: state.form['fund-account']?.values?.schedule ?? null,
      };
    },
  );

  const savingsAccountId = useSelector(
    (state) => state.global.savingsAccountId,
  );

  const date = React.useMemo(() => new Date().toISOString(), []);
  const initialFundingQueryResult = useInitialFundingQuery({
    skip: !savingsAccountId,
    variables: {
      savingsAccountId: savingsAccountId as string,
      date,
    },
  });

  const showSavingsFundingComplete = showSkipState || showReceipt;

  const { addToast } = useToast();
  if (
    initialFundingQueryResult.loading ||
    setScheduledTransferRuleResult.loading ||
    createTransferInstanceResult.loading
  ) {
    return <Spinner fullScreen />;
  }

  const savingsAccount = initialFundingQueryResult.data?.savingsnode;
  const isSavings = savingsAccount?.__typename === 'SavingsAccount';
  const isEvenWeek =
    initialFundingQueryResult.data?.viewer.transfers?.isEvenWeek;

  if (!isSavings || typeof isEvenWeek !== 'boolean') {
    return <GenericSystemError />;
  }

  const {
    minimumInitialDeposit,
    initialTransferParticipant,
    maximumInitialDeposit,
  } = savingsAccount;

  const handleConfirmDeposit = () => {
    const handleOnComplete = () => {
      addToast({
        duration: 'short',
        kind: 'success',
        content: `Success! Your ${
          schedule ? 'rule' : 'transfer'
        } was successfully created.`,
      });

      setShowReceipt(true);
    };

    const handleOnError = (e: ApolloError) => {
      addToast({
        duration: 'short',
        kind: 'alert',
        content:
          e?.message ||
          'Something went wrong. Please try again or contact support.',
      });
    };

    if (schedule) {
      setScheduledTransferRule({
        onCompleted: handleOnComplete,
        onError: handleOnError,
        variables: {
          input: {
            amount: fundAccountAmount,
            fromParticipantId: initialTransferParticipant?.id as string,
            toParticipantId: savingsAccountId as string,
            schedule,
          },
        },
      });
    } else {
      createTransferInstance({
        onCompleted: handleOnComplete,
        onError: handleOnError,
        variables: {
          input: {
            amount: fundAccountAmount,
            fromParticipantId: initialTransferParticipant?.id as string,
            toParticipantId: savingsAccountId as string,
          },
        },
      });
    }
  };

  const onSkip = () => {
    analytics.recordEvent('m1_onboarding_initial_deposit_skipped');

    setShowSkipState(true);
  };

  const onFinishSavingsFundingComplete = () => {
    navigate({ to: '/d/spend/savings' });
  };

  if (showSavingsFundingComplete) {
    return (
      <Flex width="100vw" alignItems="center" justifyContent="center">
        <SavingsFundingComplete onFinishStep={onFinishSavingsFundingComplete} />
      </Flex>
    );
  }

  return (
    // @ts-ignore flow doesn't like that this is an HoC
    <SavingsInitialFundingForm
      isEvenWeek={isEvenWeek}
      transferParticipantDescription={
        initialTransferParticipant?.transferParticipantDescription
      }
      minimumInitialDeposit={minimumInitialDeposit}
      maximumInitialDeposit={maximumInitialDeposit}
      handleConfirmDeposit={handleConfirmDeposit}
      onSkip={onSkip}
    />
  );
};
