import { Flex, HXS, type LayoutableProps, Card } from '@m1/liquid-react';
import React from 'react';

const CardHeader = ({ text }: { text: React.ReactNode | string | null }) => {
  if (!text) {
    return null;
  }

  if (typeof text === 'string') {
    return <HXS mb={24} fontWeight={300} content={text} />;
  }

  return text;
};

export function AcatWizardStepCard({
  children,
  header,
  ...rest
}: React.PropsWithChildren<
  LayoutableProps & {
    header?: React.ReactNode | string | null;
    onKeyPress?: (e: React.KeyboardEvent) => void;
  }
>) {
  return (
    <Card {...rest} as={Flex} flexDirection="column" p={40}>
      <CardHeader text={header} />
      {children}
    </Card>
  );
}
