import { type LayoutableProps, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { PillKind, PillIconsEnum, PillSize } from './Pill.types';
import { collectKindStyles, collectSizeStyles } from './Pill.utils';
import { PillGroup } from './PillGroup';
import { PillIcon } from './PillIcon';

export type RootBoxProps = {
  $kind: PillKind;
  $size: PillSize;
};

const StyledRootBox = styled(Box)<RootBoxProps>(({ $kind, $size, theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: 10,
  cursor: $kind === 'interactive' ? 'pointer' : 'auto',
  ...collectKindStyles($kind, theme),
  ...collectSizeStyles($size),
}));

export type PillProps = LayoutableProps &
  (
    | {
        icon?: PillIconsEnum | null | undefined;
        kind?: PillKind;
        label: string;
        size?: PillSize;
      }
    | {
        icon?: PillIconsEnum | null | undefined;
        kind: 'interactive';
        onClick: (event: React.MouseEvent) => void;
        label: string;
        size?: PillSize;
      }
  );

export const Pill = ({
  icon,
  kind = 'neutral',
  label,
  size = 'small',
  ...rest
}: PillProps) => (
  <StyledRootBox $kind={kind} $size={size} {...rest}>
    {icon && <PillIcon icon={icon} kind={kind} size={size} />}
    {label}
  </StyledRootBox>
);

Pill.Group = PillGroup;
