import { PL, Flex } from '@m1/liquid-react';
import * as React from 'react';

import {
  AgreementBundleLinkList,
  type AgreementBundleDocuments,
  type RequiredDocumentsBundleDocuments,
} from '~/components/AgreementBundleLinkList';

export type DisclosuresListProps = {
  emptyMessage: string;
  latestAgreements?:
    | AgreementBundleDocuments
    | RequiredDocumentsBundleDocuments;
};

export const DisclosuresList = ({
  emptyMessage,
  latestAgreements,
}: DisclosuresListProps) => {
  if (!latestAgreements?.documents || latestAgreements.documents.length === 0) {
    return <PL content={emptyMessage} />;
  }

  return (
    <Flex flexDirection="column">
      <AgreementBundleLinkList agreementBundle={latestAgreements} />
    </Flex>
  );
};
