import { isNil } from '../typeAssertions';

import { formatCurrency } from './format-currency';

/**
 * If the value is less than 1, it will use the CryptoFormatter,
 * otherwise it will use the DecimalCashFormatter.
 */
export const formatCryptoOrCurrency = (
  value: number | null | undefined,
  isCrypto = true,
) => {
  if (isNil(value) || typeof value !== 'number') {
    return null;
  }

  if (isCrypto && value < 1) {
    return CryptoFormatter.format(value);
  }

  return formatCurrency(value);
};

/**
 * Do not use this formatter directly for currency.
 * Use formatCurrency instead.
 */
const CryptoFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 8,
});
