import { Button, Text } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { ResetPasswordNotifications } from '~/components/notifications';
import { connectForm } from '~/hocs';

import { TextField } from './fields';
import { required, length } from './validators';

class AccountChallengeFormComponent extends React.Component<
  InjectedFormProps<any>
> {
  static ssnLastFourValidations = [required, length(4)];

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div
          style={{
            padding: `16px 0`,
          }}
        >
          <Text
            content="For your security, please input the last four digits of your social
            security number."
          />
        </div>
        <TextField
          name="ssnLastFour"
          label="Last 4 Digits of SSN"
          validate={AccountChallengeFormComponent.ssnLastFourValidations}
          autoComplete="off"
          maxLength={4}
          autoFocus
        />
        <div
          style={{
            paddingTop: 8,
          }}
        >
          <ResetPasswordNotifications />
        </div>
        <div
          style={{
            paddingTop: 24,
          }}
        >
          <Button
            type="submit"
            label="Submit"
            kind="primary"
            size="large"
            fullWidth
          />
        </div>
      </form>
    );
  }
}

export const AccountChallengeForm = connectForm({
  form: 'account-challenge',
})(AccountChallengeFormComponent);
