import { Flex, HS, HXS, PM } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Gain } from '~/components/gain';
import { HelpPopup } from '~/components/HelpPopup';
import { PercentGain } from '~/components/percent-gain';
import type { UnmanagedHoldingSummaryCardFragment } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { AccountTradingWindowInProgress } from './AccountTradingWindowInProgress';

export type UnmanagedHoldingsSummaryCardProps = {
  isTradingWindowInProgress: boolean;
  unmanagedHoldingsScreen: UnmanagedHoldingSummaryCardFragment;
};

export const UnmanagedHoldingsSummaryCard = ({
  isTradingWindowInProgress,
  unmanagedHoldingsScreen,
}: UnmanagedHoldingsSummaryCardProps) => {
  const isMobile: boolean = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  const { learnMore, title, unmanagedHoldingsScreenCard } =
    unmanagedHoldingsScreen;

  const quantitySection = unmanagedHoldingsScreenCard?.quantitySection;
  const totalValueSection = unmanagedHoldingsScreenCard?.totalValueSection;
  const unrealizedGainSection =
    unmanagedHoldingsScreenCard?.unrealizedGainSection;

  const amountIsPositive =
    typeof unrealizedGainSection?.amount === 'number' &&
    unrealizedGainSection.amount > 0;

  return (
    <Flex
      flexDirection={isMobile ? 'column' : 'row'}
      mt={32}
      mb={32}
      width="100%"
    >
      <Flex flexDirection="column" width={isMobile ? '100%' : '50%'}>
        <Flex flexDirection="column" width="100%">
          <HS content={title} mr="auto" />

          {learnMore && <LinkableLink linkable={learnMore} mr="auto" mt={8} />}
        </Flex>
        <Flex mt={32}>
          {quantitySection && (
            <Flex inline flexDirection="column" mr={16}>
              <PM fontWeight={600} color="foregroundNeutralSecondary" mb={8}>
                {quantitySection.label}
              </PM>
              <HXS color="foregroundNeutralMain">{quantitySection.value}</HXS>
            </Flex>
          )}
          {totalValueSection && (
            <Flex inline flexDirection="column" mx={16}>
              <PM fontWeight={600} color="foregroundNeutralSecondary" mb={8}>
                {totalValueSection.label}
              </PM>
              <HXS color="foregroundNeutralMain">{totalValueSection.value}</HXS>
            </Flex>
          )}
          {unrealizedGainSection && (
            <Flex inline flexDirection="column" ml={8}>
              <Flex inline mb={6}>
                <PM fontWeight={600} color="foregroundNeutralSecondary">
                  {unrealizedGainSection.label}
                </PM>
                {unrealizedGainSection.tooltip?.text && (
                  <HelpPopup
                    placement="bottom-start"
                    body={
                      <>
                        {unrealizedGainSection.tooltip.text.map(
                          (text, index) => (
                            <PM content={text} key={index} py={6} />
                          ),
                        )}
                      </>
                    }
                  />
                )}
              </Flex>
              {unrealizedGainSection.amount && (
                <HXS color={amountIsPositive ? 'success' : 'critical'}>
                  <Gain value={unrealizedGainSection.amount} />
                  <Flex as="span" inline ml={8}>
                    <PercentGain
                      includeParenthesis
                      value={unrealizedGainSection.percent}
                    />
                  </Flex>
                </HXS>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {isTradingWindowInProgress && (
        <Flex
          ml={32}
          pl={32}
          mt={isMobile ? 16 : 0}
          width={isMobile ? '100%' : '50%'}
        >
          <AccountTradingWindowInProgress />
        </Flex>
      )}
    </Flex>
  );
};
