import {
  APP_ACTIONS,
  type AppInitFailureAction,
  type AppInitializedAction,
  type DeterminedCampaignAttributionAction,
  type EnteredPageAction,
  type ExitedPageAction,
  type InitializeAppAction,
  type LoadFromStorageAction,
  type Page,
} from './appActions.types';

export const initializeApp = (): InitializeAppAction => ({
  type: APP_ACTIONS.INITIALIZE_APP,
});

export const appInitFailure = (
  payload: AppInitFailureAction['payload'],
): AppInitFailureAction => ({
  payload,
  type: APP_ACTIONS.APP_INIT_FAILURE,
});

export const appInitialized = (): AppInitializedAction => ({
  type: APP_ACTIONS.APP_INITIALIZED,
});

export const loadFromStorage = (
  payload: LoadFromStorageAction['payload'],
): LoadFromStorageAction => ({
  payload,
  type: APP_ACTIONS.LOAD_FROM_STORAGE,
});

export const determinedCampaignAttribution = (
  payload: DeterminedCampaignAttributionAction['payload'],
): DeterminedCampaignAttributionAction => ({
  payload,
  type: APP_ACTIONS.DETERMINED_CAMPAIGN_ATTRIBUTION,
});

// TODO: Add type for the payload on these next four actions.
export const loadSystemStatus = (payload: unknown) => ({
  payload,
  type: APP_ACTIONS.LOAD_SYSTEM_STATUS,
});

export const cacheAppConfig = (payload: unknown) => ({
  payload,
  type: APP_ACTIONS.CACHE_APP_CONFIG,
});

export const determinedReferral = (payload: unknown) => ({
  payload,
  type: APP_ACTIONS.DETERMINED_REFERRAL,
});

export const enteredPage = (page: Page, payload: any): EnteredPageAction => ({
  type: 'ENTERED_PAGE',
  meta: {
    page,
  },
  payload: payload,
});

export const exitedPage = (page: Page, payload: any): ExitedPageAction => ({
  type: 'EXITED_PAGE',
  meta: {
    page,
  },
  payload: payload,
});
