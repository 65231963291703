import { Modal, ModalContent, ModalTitle, Text } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { compose, connectModal } from '~/hocs';
import type { ModalProp } from '~/redux/reducers/modals';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';

import { Gain } from '../../gain';
import { LabelWithValue } from '../../label-with-value';

type Props = {
  modal: ModalProp<any>;
};

class TransferScheduleModalComponent extends React.Component<Props> {
  render() {
    const {
      modal: { isOpened, payload },
    } = this.props;

    return (
      <Modal onClose={this.handleClose} open={isOpened}>
        {payload && this.renderContent(payload)}
      </Modal>
    );
  }

  renderContent(payload: Record<string, any>) {
    const isLiquidating = payload.direction === 'Liquidation';

    let directionAmount;
    if (isLiquidating) {
      directionAmount = 'Pending';
    } else if (payload.direction === 'Deposit') {
      directionAmount = <Gain value={Number(payload.amount)} />;
    } else {
      directionAmount = <Gain value={-Number(payload.amount)} />;
    }
    return (
      <ModalContent width="narrow">
        <ModalTitle>Scheduled {payload.direction}</ModalTitle>

        {isLiquidating && (
          <div
            style={{
              paddingBottom: 16,
            }}
          >
            <Text color="foregroundNeutralMain">
              Liquidation can take up to 5 days.
            </Text>
          </div>
        )}

        <div
          style={{
            paddingBottom: 16,
          }}
        >
          <LabelWithValue label="Amount" value={directionAmount} />
        </div>

        {isLiquidating && (
          <div
            style={{
              paddingBottom: 16,
            }}
          >
            <Text color="foregroundNeutralMain">
              Amount will be displayed after completion due to market
              fluctuations.
            </Text>
          </div>
        )}

        <div
          style={{
            paddingBottom: 16,
          }}
        >
          <LabelWithValue label="Transfer from" value={payload.from} />
        </div>

        <div
          style={{
            paddingBottom: 16,
          }}
        >
          <LabelWithValue label="Transfer to" value={payload.to} />
        </div>

        <Divider />

        <Grid.Row
          style={{
            paddingBottom: 16,
          }}
        >
          <Grid.Col xs={6}>
            <LabelWithValue
              label="Date"
              value={moment(payload.start).format('ll')}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <LabelWithValue label="Frequency" value={payload.frequency} />
          </Grid.Col>
        </Grid.Row>
      </ModalContent>
    );
  }

  handleClose = (): void => this.props.modal.hide();
}

const enhancer = compose<any, any>(connectModal('TRANSFER_SCHEDULE'));

export const TransferScheduleModal = enhancer(
  TransferScheduleModalComponent,
) as any;
