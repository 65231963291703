import * as React from 'react';

import type {
  AppLinkFragment,
  AppNavigationItemFragment,
  LinkableFragment,
  Linkable_TransferSuggestionLink_Fragment,
} from '~/graphql/types';
import { mapInternalPathToRouteName } from '~/utils';

import { useAnalytics } from './useAnalytics';
import { useIntercomConversation } from './useIntercomConversation';

export type OnClickLinkableEvent = React.KeyboardEvent | React.MouseEvent;

export type OnClickLinkable = (
  event: OnClickLinkableEvent,
  suggestion?: Linkable_TransferSuggestionLink_Fragment['suggestion'],
) => void | React.MouseEventHandler;

type UseLinkableReturn = {
  onClickLinkable: OnClickLinkable;
  routeName: string | null;
  queryParams: Record<string, string> | undefined;
};

export type LinkableFragments =
  | AppLinkFragment
  | LinkableFragment
  | AppNavigationItemFragment;

// Linkable fragment types that implement the AppLink interface.
const isAppLink = (
  linkable: LinkableFragments,
): linkable is AppLinkFragment | AppNavigationItemFragment =>
  linkable?.__typename === 'AppLink' ||
  linkable?.__typename === 'AppNavigationItem';

export const useLinkable = (
  link: LinkableFragments,
  onClick?: OnClickLinkable,
): UseLinkableReturn => {
  const analytics = useAnalytics();
  const handleConversation = useIntercomConversation();

  const onClickLinkable = React.useCallback(
    (event: OnClickLinkableEvent) => {
      if (isAppLink(link)) {
        if (
          link.internalPath === '/d/contact-us?mode=instantchat' &&
          Boolean(window.Intercom)
        ) {
          // If we have an instant chat link, prevent the default link behavior and open the intercom chat window.
          event.preventDefault();
          window.Intercom('show');
        }
        const url = new URL(link.url);
        if (url.hostname === 'help.m1.com') {
          // If we have a help center link, check for parameters and trigger the appropriate action.
          const action = url.searchParams.get('action');
          switch (action) {
            case 'new_conversation':
              handleConversation(url, event);
              break;
            case 'show_article':
              // Continue with regular link handling to open articles in a new tab.
              break;
            default:
              // If we don't recognize the action, continue with regular link handling.
              break;
          }
        }
      }

      if (link.analyticsEvent) {
        analytics.recordAppAnalyticsEvent(link.analyticsEvent);
      }
      const suggestion =
        link.__typename === 'TransferSuggestionLink' ? link.suggestion : null;

      if (onClick) {
        suggestion ? onClick(event, suggestion) : onClick(event);
      }
    },
    [analytics, handleConversation, link, onClick],
  );

  let routeName = null;
  let queryParams;
  if (isAppLink(link) && link.internalPath) {
    ({ routeName, queryParams } = mapInternalPathToRouteName(
      link.internalPath,
    ));
  }

  return {
    onClickLinkable,
    routeName,
    queryParams,
  };
};
