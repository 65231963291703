import type { SemanticColorNames } from '@m1/liquid-react';
import React from 'react';

import { required, minLength, maxLength, securePassword } from '../validators';

import { TextField } from './text-field';

const MAX_LENGTH = 255;

type PasswordFieldProps = {
  color?: string;
  label?: string;
  name?: string;
  securePasswordEnabled?: boolean;
  backgroundColor?: SemanticColorNames;
};

type PasswordInputType = 'password' | 'text';

export const PasswordField = ({
  securePasswordEnabled,
  ...rest
}: PasswordFieldProps) => {
  const [validations] = React.useState<Array<any>>([
    required,
    minLength(4),
    maxLength(MAX_LENGTH),
    ...(securePasswordEnabled ? [securePassword] : []),
  ]);
  const [type, setType] = React.useState<PasswordInputType>('password');

  return (
    <TextField
      iconRight={type === 'text' ? 'hide20' : 'show20'}
      label="Password"
      maxLength={MAX_LENGTH}
      name="password"
      type={type}
      onIconRightClick={() => setType(type === 'text' ? 'password' : 'text')}
      validate={validations}
      {...rest}
    />
  );
};
