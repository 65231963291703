import pick from 'lodash-es/pick';

import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { MARGIN_CALL_INFORMATION_STEPS as STEPS } from '~/static-constants';

import type { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type MarginCallInformationFlowState = FlowState<
  typeof STEPS,
  {
    basePath: string;
    borrowAccountId: string | null | undefined;
    isDisableAutoInvestMutationSubmitting: boolean;
    positionId: string | null | undefined;
    previousRouteName: string | null | undefined;
  }
>;

export const marginCallInformationInitialState: MarginCallInformationFlowState =
  {
    basePath: '',
    borrowAccountId: null,
    isDisableAutoInvestMutationSubmitting: false,
    positionId: null,
    previousRouteName: null,
    step: STEPS.INFO_SCREEN,
    stepTitle: '',
  };

const stepReducer = createStepReducer(marginCallInformationInitialState);

const reducer = (
  state: MarginCallInformationFlowState = marginCallInformationInitialState,
  action: any,
): MarginCallInformationFlowState => {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...state,
        ...pick(action.payload, Object.keys(state)),
        borrowAccountId:
          action.payload.borrowAccountId || state.borrowAccountId,
        step:
          action.payload.initialStep || marginCallInformationInitialState.step,
        previousRouteName: action.payload.previousRouteName,
      };
    case ACTIONS.END_FLOW:
      if (action.payload.preserveStateOnUnmount) {
        return state;
      }

      return marginCallInformationInitialState;
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    case ACTIONS.SUBMIT_DISABLE_AUTO_INVEST_MUTATION:
      return {
        ...state,
        isDisableAutoInvestMutationSubmitting: true,
      };
    case ACTIONS.COMPLETE_DISABLE_AUTO_INVEST_MUTATION:
      return {
        ...state,
        isDisableAutoInvestMutationSubmitting: false,
      };
    case ACTIONS.UPDATE_ACTIVE_POSITION_ID:
      return {
        ...state,
        positionId: action.payload,
      };
    case ACTIONS.SET_MARGIN_CALL_INFORMATION_PREVIOUS_ROUTE:
      return {
        ...state,
        previousRouteName: action.payload,
      };
    case ACTIONS.RESET_MARGIN_CALL_INFORMATION_STATE:
      return marginCallInformationInitialState;
    default:
      return state;
  }
};

export const marginCallInformation = createFlowReducer(
  'MARGIN_CALL_INFORMATION',
  reducer,
);
