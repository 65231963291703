import { Box, Button, PM, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { type InjectedFormProps, reduxForm } from 'redux-form';

import { FormMocker, generateRandomSSN } from '~/forms/FormMockers';

import { SsnField } from './fields';
import { makeFormConfig } from './utils';

type Props = InjectedFormProps<any> & {
  label: string;
  onSubmit: (...args: Array<any>) => any;
  submitButtonLabel: string;
  disclaimerTooltipContent: string;
};

const HolderSsnFormComponent = ({
  handleSubmit,
  label,
  submitButtonLabel,
  disclaimerTooltipContent,
}: Props) => {
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);

  return (
    <form
      onSubmit={(values) => {
        setFormSubmitted(true);
        handleSubmit(values);
        setFormSubmitted(false);
      }}
    >
      <SsnField name="ssn" label={label || 'Social Security'} autoFocus />
      <Flex alignItems="center" mt={8}>
        <Icon
          name="lock32"
          style={{
            ariaHidden: true,
          }}
        />
        <PM
          ml={8}
          style={{ whiteSpace: 'nowrap' }}
          content={
            // Currently using this tooltip field for the disclaimer text
            disclaimerTooltipContent ||
            'We protect your personal information using security and encryption best practices.'
          }
        />
      </Flex>
      <Box>
        <Button
          type="submit"
          kind="primary"
          size="large"
          label={submitButtonLabel || 'Continue'}
          mt={48}
          disabled={isFormSubmitted}
        />
        <FormMocker
          formName="holder-ssn"
          fields={[{ name: 'ssn', value: generateRandomSSN() }]}
        />
      </Box>
    </form>
  );
};

const enhancer = reduxForm(
  makeFormConfig({
    form: 'holder-ssn',
  }),
);

// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<InjectedFormProps<{}, {}, string>>'.
export const HolderSsnForm = enhancer(HolderSsnFormComponent) as any;
