import moment from 'moment';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';

import {
  useSubmitPersonalLoanApplicationMutation,
  useUserIdentityStatusLazyQuery,
  usePersonalLoansApplicationByIdSagaLazyQuery,
  useCreateProfileMutation,
} from '~/graphql/hooks';
import type { LoanApplicationStatusEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { usePollTimer } from '~/hooks/usePollTimer';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { normalizeCurrency } from '../../../utils/normalizers';
import { Loader } from '../components/Loader';
import {
  SocialSecurityNumberForm,
  type DirectToPersonalLoansSSNFormValues,
} from '../components/SocialSecurityNumberForm';
import { POLL_INTERVAL, POLL_LIMIT } from '../constants';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import type { PersonalLoanDirectSteps } from '../types';
import { getApplicationStatusRoute } from '../utils/getApplicationStatusRoute';

export const SocialSecurityNumber = () => {
  const {
    directToPersonalLoansSignUpInfo,
    loanInfo,
    directToPersonalLoansPhoneNumber,
    annualIncomeMonthlyHousingCost,
    homeAddress,
    dateOfBirthAndCitizenshipInfo,
  } = useSelector((state) => state.reactHookForm);
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { goTo } = usePersonalLoanDirectWizardContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [shouldStartApplicationPollTimer, setShouldStartApplicationPollTimer] =
    React.useState<boolean>(false);
  const { isPollLimitReached } = usePollTimer({
    shouldStartPollTimer: shouldStartApplicationPollTimer,
    pollInterval: POLL_INTERVAL,
    pollLimit: POLL_LIMIT,
  });

  const [createProfileMutation] = useCreateProfileMutation();
  const [
    getUserIdentityStatus,
    {
      data: getUserIdentityStatusData,
      startPolling: startPollingUser,
      stopPolling: stopPollingUser,
    },
  ] = useUserIdentityStatusLazyQuery();
  const [submitPersonalLoanApplicationMutation] =
    useSubmitPersonalLoanApplicationMutation();
  const [
    getPersonalLoanApplicationById,
    {
      data: personalLoanApplicationData,
      startPolling: startPollingApplication,
      stopPolling: stopPollingApplication,
    },
  ] = usePersonalLoansApplicationByIdSagaLazyQuery();

  const onSubmit: SubmitHandler<DirectToPersonalLoansSSNFormValues> = ({
    socialSecurityNumber,
  }) => {
    setLoading(true);
    createProfileMutation({
      variables: {
        input: {
          phoneNumber: directToPersonalLoansPhoneNumber?.phoneNumber,
          name: {
            firstName: directToPersonalLoansSignUpInfo?.firstName,
            middleInitial: directToPersonalLoansSignUpInfo?.middleInitial,
            lastName: directToPersonalLoansSignUpInfo?.lastName,
            suffix: directToPersonalLoansSignUpInfo?.suffix,
          },
          countryOfCitizenship:
            dateOfBirthAndCitizenshipInfo?.countryOfCitizenship,
          address: {
            lineOne: homeAddress?.lineOne,
            lineTwo: homeAddress?.lineTwo,
            city: homeAddress?.city,
            stateOrProvince: homeAddress?.stateOrProvince,
            postalCode: homeAddress?.postalCode,
            country: 'USA',
          },
          dateOfBirth: moment(
            dateOfBirthAndCitizenshipInfo?.dateOfBirth,
          ).format('YYYY-MM-DD'),
          ssn: socialSecurityNumber?.replaceAll('-', ''),
          suitability: {
            annualIncomeAmount: annualIncomeMonthlyHousingCost.annualIncome,
          },
        },
      },
      onCompleted: () => {
        getUserIdentityStatus();
        // Once createProfile is complete
        // Dispatch SET_USER_HAS_ONBOARDED in case user hits any error states and want to be routed home
        // Start polling for identityStatus === "APPROVED" on user
        dispatch({
          type: 'SET_USER_HAS_ONBOARDED',
          payload: true,
        });
        startPollingUser(POLL_INTERVAL);
      },
      onError: (error) => {
        setLoading(false);
        addToast({
          content: error.message,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  React.useEffect(() => {
    if (
      getUserIdentityStatusData?.viewer?.profile?.identityStatus === 'Approved'
    ) {
      // If identityStatus === "APPROVED", stop polling the user query and call submitPersonalLoanApplicationMutation
      stopPollingUser();
      submitPersonalLoanApplicationMutation({
        variables: {
          input: {
            amount:
              loanInfo?.loanAmount &&
              Number(normalizeCurrency(loanInfo?.loanAmount)),
            requestedTerm: loanInfo?.loanTerm,
            purpose: loanInfo?.loanPurpose,
            annualIncome: annualIncomeMonthlyHousingCost.annualIncome,
            yearlyHousingCost:
              annualIncomeMonthlyHousingCost.monthlyHousingCosts,
          },
        },
        onCompleted: (data) => {
          const applicationId =
            data?.submitPersonalLoanApplication?.outcome?.applicationId;
          if (applicationId) {
            // Store applicationId so we can access it on any potential error screens
            dispatch({
              type: ACTIONS.PERSONAL_LOANS_STORE_APPLICATION_ID,
              payload: {
                applicationId,
              },
            });
            getPersonalLoanApplicationById({
              variables: {
                applicationId,
              },
            });
            // Once submitPersonalLoanApplicationMutation is complete, start polling for application status
            setShouldStartApplicationPollTimer(true);
            startPollingApplication(POLL_INTERVAL);
          }
        },
        onError: () => {
          // If user is ineligible (they don't live in a US state), lens will return an error
          // Navigate user to ineligible error screen
          navigate({ to: '/direct-loan-application-error/not-eligible' });
          setLoading(false);
        },
      });
    }
  }, [getUserIdentityStatusData?.viewer?.profile?.identityStatus]);

  React.useEffect(() => {
    const statusesIgnore: LoanApplicationStatusEnum[] = ['SUBMITTED', 'QUEUED'];
    const status =
      personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
        ?.status?.status;

    if (
      (status && !statusesIgnore?.includes(status)) ||
      (isPollLimitReached && status)
    ) {
      // If a status is returned and it's a status we can take action on (or if the timer limit is reached), stop polling the application status query and setLoading to false (so loader stops spinning).
      stopPollingApplication();
      setShouldStartApplicationPollTimer(false);
      setLoading(false);

      const { route, isWizardStep } = getApplicationStatusRoute(status);
      if (isWizardStep) {
        goTo(route as keyof PersonalLoanDirectSteps);
      } else {
        navigate({ to: route });
      }
    }
  }, [
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.status?.status,
    isPollLimitReached,
  ]);

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/ssn');
  }, [analytics]);

  return loading ? (
    <Loader
      heading="We are getting your offers."
      content="This may take up to a minute."
    />
  ) : (
    <SocialSecurityNumberForm onSubmit={onSubmit} />
  );
};
