import { Button } from '@m1/liquid-react';
import React from 'react';

import { LandingPageHeader } from '~/components/LandingPageHeader';
import { MoveMoneyMenu } from '~/components/MoveMoneyMenu/MoveMoneyMenu';
import { useEarnProductLandingPageHeaderQuery } from '~/graphql/hooks';
import type { EarnProductLandingPageHeaderQuery } from '~/graphql/types';

export const EarnLandingPageHeader = ({
  accountId,
  hasAnnouncement,
}: {
  accountId: string;
  hasAnnouncement: boolean;
}) => {
  const { data, loading } = useEarnProductLandingPageHeaderQuery({
    variables: { id: accountId },
  });

  const node = data?.node as ExtractTypename<
    EarnProductLandingPageHeaderQuery['node'],
    'SavingsAccount'
  >;
  const title = node?.earnProductLandingPage?.title || '';
  const accountDetails = node?.earnProductLandingPage?.accountDetails || [];
  const header = node?.earnProductLandingPage?.header;
  const moveMoneyButtonLabel = node?.fundAccountLink?.title;

  const ctaComponent = (
    <MoveMoneyMenu>
      <Button label={moveMoneyButtonLabel ?? 'Move money'} size="medium" />
    </MoveMoneyMenu>
  );

  return (
    <LandingPageHeader
      title={title}
      accountDetails={accountDetails}
      header={header}
      loading={loading}
      ctaComponent={ctaComponent}
      hasAnnouncement={hasAnnouncement}
    />
  );
};
