import { Box, Flex, Image, PL, PS, spacingUnits } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';

import * as React from 'react';

import type { TransferParticipantFragment } from '~/graphql/types';
import { getParticipantIconName } from '~/pages/dashboard/covers/create-transfer/utils/lensMappers';
import { isNotNil } from '~/utils';

interface TransferParticipantDropdownOptionProps {
  participant: TransferParticipantFragment;
}

export const TransferParticipantDropdownOption: React.FC<
  TransferParticipantDropdownOptionProps
> = ({ participant }) => {
  return (
    <Flex alignItems="center" gap={spacingUnits.s}>
      <Box>
        {'logoUrl' in participant && isNotNil(participant.logoUrl) ? (
          <Image
            src={participant.logoUrl as string}
            borderRadius={4}
            width={32}
            height={32}
            alt=""
          />
        ) : (
          <Icon
            name={getParticipantIconName(participant.transferParticipantType)}
            fallback="accountCheckingPrimary32"
          />
        )}
      </Box>
      {/* Not great setting a max width. But need to force following Ps to overflow/wrap. */}
      <Box maxWidth="330px">
        <PL overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {participant.transferParticipantName}
        </PL>
        <PS color="foregroundNeutralSecondary" whiteSpace="normal">
          {participant.transferParticipantDescription}
        </PS>
      </Box>
    </Flex>
  );
};
