import { Box, Skeleton, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { useEarnLandingPageBannersQuery } from '~/graphql/hooks';
import type { EarnLandingPageBannersQuery } from '~/graphql/types';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';

export const EarnLandingPageBanners = ({
  accountId,
}: {
  accountId: string;
}) => {
  const { data, loading } = useEarnLandingPageBannersQuery({
    variables: {
      accountId: accountId,
    },
  });

  const node = data?.node as ExtractTypename<
    EarnLandingPageBannersQuery['node'],
    'SavingsAccount'
  >;

  const { banners } = node?.earnProductLandingPage || {};

  if (!banners?.length) {
    return null;
  }

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <Skeleton skeletonHeight="100%" skeletonWidth="100%">
        <Box mb={-32}>
          {banners.map((banner, index) => (
            <InformationBanner
              appBanner={banner}
              my={16}
              position="relative"
              key={index}
              contain={false}
            />
          ))}
        </Box>
      </Skeleton>
    </SkeletonProvider>
  );
};
