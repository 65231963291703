import { Box, Flex, HL, PS, styled, Text, Tooltip } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AnnouncementContext } from '~/components/announcement';
import { ExternalLinkButton } from '~/components/ExternalLinkButton';
import type {
  BorrowMarketingHeaderAccountFragment,
  BorrowMarketingHeaderFragment,
} from '~/graphql/types';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Container } from '~/toolbox/container';
import { formatCurrency, formatPercentage } from '~/utils/formatting';

import { BorrowTransferButton } from './BorrowTransferButton';

export type BorrowMarketingHeaderProps = {
  viewer: BorrowMarketingHeaderFragment['viewer'];
  borrowAccount?: BorrowMarketingHeaderAccountFragment;
};

const StyledHeader = styled(Box)<{
  opacity: number;
}>`
  background: ${(props) => props.theme.colors.gradientLinearFeature};
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const StyledAnnouncementContainer = styled(Flex)`
  width: 376px;
`;

export const BorrowMarketingHeader = ({
  viewer,
  borrowAccount,
}: BorrowMarketingHeaderProps) => {
  const [backgroundImageWidth, setBackgroundImageWidth] = React.useState<
    number | null | undefined
  >(null);
  const [_, setHasAnnouncementOnMount] = React.useState<boolean>(false);

  const [eligibleCreditAvailable, eligibleRatePercent] = React.useMemo(() => {
    return [
      formatCurrency(borrowAccount?.creditAvailable, 'SELECTIVE') ?? '$--',
      borrowAccount?.rate && typeof borrowAccount.rate.ratePercent === 'number'
        ? formatPercentage(borrowAccount.rate.ratePercent / 100)
        : '--%',
    ];
  }, [borrowAccount]);

  const [ineligibleRatePercentPunctuation, ineligibleRatePercentContent] =
    React.useMemo(() => {
      if (!viewer?.borrow) {
        return ['!', ''];
      }

      return typeof viewer.borrow.baseRate === 'number'
        ? [':', formatPercentage(viewer.borrow.baseRate / 100)]
        : ['!', ''];
    }, [viewer]);

  const [inEligibleMaxBorrowableAccountValuePercent, minRequiredAccountValue] =
    React.useMemo(() => {
      if (!viewer?.borrow) {
        return ['a percentage', 'money'];
      }

      return [
        typeof viewer.borrow.maxBorrowableAccountValuePercent === 'number'
          ? `up to ${formatPercentage(
              viewer.borrow.maxBorrowableAccountValuePercent / 100,
            )}`
          : 'a percentage',
        typeof viewer.borrow.minRequiredAccountValue === 'number'
          ? formatCurrency(viewer.borrow.minRequiredAccountValue, 'SELECTIVE')
          : '$',
      ];
    }, [viewer]);

  const resetBackground = (): void => {
    setBackgroundImageWidth(1012 + (window.innerWidth - 1200) / 2);
  };

  React.useEffect(() => {
    const announcement = viewer?.announcements?.forBorrow || null;
    if (announcement) {
      setHasAnnouncementOnMount(true);
    }

    resetBackground();
    window.addEventListener('resize', resetBackground);

    return () => {
      window.removeEventListener('resize', resetBackground);
    };
  }, [viewer]);

  const isEligibleForBorrow = Boolean(borrowAccount?.hasCreditAvailable);

  const announcement = viewer?.announcements?.forBorrow;

  return (
    <StyledHeader
      opacity={typeof backgroundImageWidth === 'number' ? 1 : 0}
      width="100%"
    >
      <Container>
        <Flex flexDirection="column" pt={88}>
          <Box>
            {isEligibleForBorrow ? (
              <HL color="foregroundNeutralOnDark">
                You’re eligible to borrow up to
                <br />
                <Text
                  as="span"
                  color="primaryTint"
                  content={`${eligibleCreditAvailable} at `}
                />
                <Text
                  as="span"
                  color="primaryTint"
                  content={`${eligibleRatePercent} with a M1 Margin Loan!`}
                />
              </HL>
            ) : (
              <HL color="foregroundNeutralOnDark">
                {`Borrow at one of the lowest rates${ineligibleRatePercentPunctuation} `}
                <Text
                  as="span"
                  color="primaryTint"
                  content={ineligibleRatePercentContent}
                />
              </HL>
            )}
          </Box>
          <Flex justifyContent="space-between" mt={16} pb={64}>
            <Flex flexDirection="column">
              <Flex flexDirection="column">
                <Box width="max-content" color="foregroundNeutralOnDark">
                  {isEligibleForBorrow
                    ? 'Unlock your portfolio line of credit with a click of a button.'
                    : `Borrow ${inEligibleMaxBorrowableAccountValuePercent} of your portfolio's value when you have ${minRequiredAccountValue} invested.`}
                  <Tooltip
                    placement="bottom-end"
                    triggerBoxProps={{
                      style: { verticalAlign: 'middle' },
                      ml: 8,
                      mb: -4,
                    }}
                    icon="tooltip24"
                    body={
                      <PS
                        content={`Customers with ${minRequiredAccountValue}+ marginable securities are eligible for M1 Borrow. Not available for retirement and custodial accounts. Newly eligible accounts may need to wait up to one full business day before being able to sign up`}
                      />
                    }
                  />
                </Box>
                <Flex alignSelf="flex-start">
                  <PS
                    content="Borrowing is an additional risk and you could lose more than invested."
                    color="foregroundNeutralOnDark"
                    my={10}
                  />
                </Flex>
              </Flex>
              {announcement && (
                <StyledAnnouncementContainer mt={64}>
                  <AnnouncementContext
                    announcement={announcement}
                    context="BORROW"
                  />
                </StyledAnnouncementContainer>
              )}
              <Flex mt={32}>
                <ButtonGroup>
                  {isEligibleForBorrow && borrowAccount && (
                    <BorrowTransferButton
                      borrowAccount={borrowAccount}
                      direction="Borrow"
                      kind="inverse-primary"
                    />
                  )}
                  <ExternalLinkButton
                    destination="SUPPORT_WHAT_IS_BORROW"
                    kind={
                      borrowAccount ? 'inverse-secondary' : 'inverse-primary'
                    }
                    label="Learn more about margin"
                    style={{ height: '44px' }}
                  />
                </ButtonGroup>
              </Flex>
            </Flex>
            <Box alignSelf="flex-end" mb={-64}>
              {/* @ts-expect-error - TS2322 - Type '{ alt: string; height: number; name: "marginLoan"; width: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
              <Illustration alt="" height={347} name="marginLoan" width={583} />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </StyledHeader>
  );
};
