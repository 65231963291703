import * as React from 'react';

import { STEPS } from './constants';
import {
  AcceptOffer,
  AccountConfirmation,
  ApplicationPending,
  ApplicationRejected,
  FinalSummary,
  FinancialInformation,
  InitialErrorPage,
  PreFilledSummary,
  ProcessingApplication,
  ProcessingOpenAccount,
  RemoveCreditFreeze,
  StartApplication,
  UploadDocuments,
} from './steps';

export const CreditCardApplicationContainer = ({ step, ...rest }: any) => {
  const steps = {
    // Start application, disclosures
    [STEPS.START_APPLICATION]: StartApplication,
    // Step One, Application Summary
    [STEPS.PREFILLED_SUMMARY]: PreFilledSummary,
    // Step Two, User Input, Financial Information
    [STEPS.FINANCIAL_INFORMATION]: FinancialInformation,
    // Edit Financial Information
    [STEPS.EDIT_FINANCIAL_INFORMATION]: FinancialInformation,
    // Step Three, Review Application Summary
    [STEPS.SUBMIT_APPLICATION]: FinalSummary,
    // Step Four, Application Results
    [STEPS.APPLICATION_APPROVED]: AcceptOffer,
    [STEPS.APPLICATION_REJECTED]: ApplicationRejected,
    [STEPS.APPLICATION_SUBMITTED]: ApplicationPending,
    [STEPS.REMOVE_CREDIT_FREEZE]: RemoveCreditFreeze,
    [STEPS.UPLOAD_DOCUMENTS]: UploadDocuments,
    [STEPS.OFFER_ACCEPTED]: AccountConfirmation,
    // Processing application / Opening account
    [STEPS.PROCESSING_APPLICATION]: ProcessingApplication,
    [STEPS.PROCESSING_OPEN_ACCOUNT]: ProcessingOpenAccount,
    // Error Loading Initial Data
    [STEPS.INITIAL_ERROR_PAGE]: InitialErrorPage,
  };

  const Step = steps[step];
  return Step ? <Step {...rest} /> : null;
};
