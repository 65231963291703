import { useWizardContext } from '~/flows/wizard';

import type { OpenInvestJointAccountWizardSteps } from '../types';

export const useOpenInvestJointAccountWizardContext = () => {
  const context = useWizardContext<OpenInvestJointAccountWizardSteps>();

  if (!context) {
    throw new Error(
      'useOpenInvestJointAccountWizardContext must be used within a Wizard provider!',
    );
  }

  return context;
};
