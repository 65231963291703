import type {
  TransferParticipantSideEnum,
  WizardTransferParticipantFragment,
} from '~/graphql/types';
import type { UseParticipantSummaryResult } from '~/pages/dashboard/covers/create-transfer/utils/useParticipantSummary';

export const summaryToParticipantsList = ({
  participantSummary,
  side,
}: {
  participantSummary: UseParticipantSummaryResult;
  side: TransferParticipantSideEnum;
}): WizardTransferParticipantFragment[] => {
  const {
    pivot,
    allSourceDetailsList,
    allDestinationDetailsList,
    sourceDetails,
    destinationDetails,
  } = participantSummary;

  switch (pivot) {
    case 'TO': {
      // Return all destination participants
      if (side === 'TO') {
        return (
          allDestinationDetailsList
            .map((destination) => destination.account)
            .filter((acct) => acct !== null) ?? []
        );
      }
      // Return source participants by examining relationships on selected destination:
      return (
        destinationDetails?.relationships
          ?.map((relationship) => relationship.source)
          .filter((acct) => acct !== null) ?? []
      );
    }
    case 'FROM':
    default: {
      // Return all source participants
      if (side === 'FROM') {
        return allSourceDetailsList
          .map((source) => source.account)
          .filter((acct) => acct !== null);
      }
      // Return destination participants by examining relationships on selected source:
      return (
        sourceDetails?.relationships
          ?.map((relationship) => relationship.destination)
          .filter((acct) => acct !== null) ?? []
      );
    }
  }
};
