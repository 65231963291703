import type { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import {
  ChatContextDocument,
  type ChatContextQueryResult,
} from '~/graphql/hooks';
import { ACTION_TYPES } from '~/redux/actions';

import { apolloQuerySaga } from './apolloQuerySaga';

export function* startIntercom(): SagaIterator {
  yield takeEvery('APP_INITIALIZED', function* (): SagaIterator {
    if (window.Intercom !== undefined) {
      yield window.Intercom('boot', { app_id: window.config.intercom.appId });
    }
  });
}

export function* updateIntercom(): SagaIterator {
  yield takeEvery('AUTH_SESSION_UPDATED', function* (): SagaIterator {
    if (window.Intercom !== undefined) {
      const { data }: ChatContextQueryResult = yield call(apolloQuerySaga, {
        query: ChatContextDocument,
      });

      if (data?.viewer.user?.id) {
        const { user, profile } = data.viewer;
        const { userIdPrefix } = window.config.intercom;

        const updateParams = {
          user_id: `${userIdPrefix}${user.correlationKey}`, // requested by CS for tracking users by correlation id
          user_hash: user.intercomIdentityHash,
          name: profile?.primary?.fullName,
          firstName: profile?.primary?.firstName,
          phone:
            // Intercom expects E.164 format for phone numbers. User Profile stores phone numbers in 10 digit format.
            // We need to add +1 to the phone number to convert it to E.164 format.
            typeof profile?.primary?.phoneNumber === 'string'
              ? `+1${profile.primary.phoneNumber}`
              : undefined,
          email: user.username,
          email_verified: user.isPrimaryEmailVerified,
        };

        if (window.Intercom !== undefined) {
          window.Intercom('update', updateParams);
        }
      }
    }
  });
}

export function* shutdownIntercom(): SagaIterator {
  yield takeEvery(ACTION_TYPES.LOGOUT, function* (): SagaIterator {
    if (window.Intercom !== undefined) {
      yield window.Intercom('shutdown');
    }
  });
}
