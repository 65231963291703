import { makeFlowComponent } from '../../utils';

import { CreditCardApplicationContainer } from './container';

export { STEPS } from './constants';
export { CreditCardApplicationContainer } from './container';

export const CreditCardApplicationFlow = makeFlowComponent({
  name: 'CREDIT_CARD_APPLICATION',
  Component: CreditCardApplicationContainer,
});
