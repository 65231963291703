import {
  Box,
  Button,
  Card,
  Flex,
  HL,
  HXS,
  PL,
  PS,
  styled,
  type TextProps,
} from '@m1/liquid-react';
import { Banner } from '@m1/liquid-react/Banner';
import {
  Illustration,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AccountOrdersTable } from '~/components/account';
import { AccountRebalancesTable } from '~/components/account/account-rebalances-table/AccountRebalancesTable';
import { CryptoDisclosure } from '~/components/CryptoDisclosure';
import { GenericSystemError } from '~/components/GenericSystemError';
import { EstimatedBuysTable } from '~/components/invest/EstimatedBuysTable';
import { EstimatedSellsTable } from '~/components/invest/EstimatedSellsTable';
import { usePortfolioTradingDetailsQuery } from '~/graphql/hooks';
import type { Account } from '~/graphql/types';
import { useParams } from '~/hooks/useParams';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { formatCurrency } from '~/utils/formatting';

import { AccountTradingWindowStatusCard } from '../PortfolioDetailsPage/components/AccountTradingStatus/AccountTradingWindowStatusCard';

import { PortfolioSliceNavigableButtonRenderer } from './portfolioBanner/PortfolioSliceNavigableButton';

export type PortfolioTradingDetailsPageProps = {
  onExecuteTrades?: () => void;
};

type DirectionMetrics = [number, number];

const StyledHeaderContainer = styled(Container)<{
  hasBanner: boolean;
}>`
  height: 100%;
  padding-top: ${(props) => (props.hasBanner ? '32px' : '0px')};
  padding-bottom: 32px;
`;

const StyledTwoColumnGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 50%));
  column-gap: 30px;
`;

const StyledTradesHeader = styled(Flex)`
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const PortfolioTradingDetails = ({
  onExecuteTrades,
}: PortfolioTradingDetailsPageProps) => {
  const { portfolioSliceId } = useParams();
  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading } = usePortfolioTradingDetailsQuery({
    variables: {
      id: activeAccountId as string,
    },
    fetchPolicy: 'network-only',
    skip: !activeAccountId,
    errorPolicy: 'all',
  });

  const account = data?.node?.__typename === 'Account' ? data.node : null;

  const [buyMetrics, sellMetrics] = React.useMemo(() => {
    if (account?.estimatedTrading) {
      const { estimatedTrading } = account;
      return [
        [estimatedTrading.buys.total, estimatedTrading.totalBuyAmount],
        [estimatedTrading.sells.total, estimatedTrading.totalSellAmount],
      ];
    }
    return [
      [0, 0],
      [0, 0],
    ];
  }, [account]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!account) {
    return <GenericSystemError />;
  }

  const isOnDemand = Boolean(onExecuteTrades);
  const hasRebalances = Boolean(
    Array.isArray(account.portfolioSlicesWithRebalances) &&
      account.portfolioSlicesWithRebalances.length > 0,
  );

  const renderTradingWindows = () => {
    const { preferredTradeWindow, trading } = account;

    if (!trading) {
      return null;
    }

    if (isOnDemand) {
      return (
        <AccountTradingWindowStatusCard
          trading={trading}
          forWindow="ON_DEMAND"
          kind="large"
        />
      );
    }

    if (!preferredTradeWindow || preferredTradeWindow === 'DEFAULT') {
      return (
        <>
          <AccountTradingWindowStatusCard
            trading={trading}
            forWindow="MORNING"
            kind="large"
          />
          <AccountTradingWindowStatusCard
            trading={trading}
            forWindow="AFTERNOON"
            kind="large"
          />
        </>
      );
    }

    return (
      <AccountTradingWindowStatusCard
        trading={trading}
        forWindow={preferredTradeWindow}
        kind="default"
      />
    );
  };

  const renderBuyOrdersAndTrades = () => {
    const hasBuyOrders = Boolean(
      Array.isArray(account.portfolioSlicesWithBuyOrders) &&
        account.portfolioSlicesWithBuyOrders.length > 0,
    );
    const hasEstimatedBuys = Boolean(
      account.estimatedTrading && account.estimatedTrading.buys.total > 0,
    );

    if (hasBuyOrders || hasEstimatedBuys) {
      return (
        <>
          {hasBuyOrders && (
            <AccountOrdersTable
              account={account}
              direction="buy"
              key="orders"
            />
          )}
          {hasEstimatedBuys && (
            <EstimatedBuysTable
              accountId={account.id}
              key="estimated-trades"
              style={
                hasBuyOrders
                  ? {
                      marginTop: 32,
                    }
                  : {}
              }
            />
          )}
        </>
      );
    }

    return renderNoTradesContent('cryptoNoPendingBuys', 'No pending buys');
  };

  const renderSellOrdersAndTrades = () => {
    const hasSellOrders = Boolean(
      Array.isArray(account.portfolioSlicesWithSellOrders) &&
        account.portfolioSlicesWithSellOrders.some(
          (slice) => slice?.orderStatus?.order !== null,
        ),
    );
    const hasEstimatedSells = Boolean(
      account.estimatedTrading && account.estimatedTrading.sells.total > 0,
    );

    if (hasSellOrders || hasEstimatedSells) {
      return (
        <>
          {hasSellOrders && (
            <AccountOrdersTable
              account={account}
              direction="sell"
              key="orders"
            />
          )}
          {hasEstimatedSells && (
            <EstimatedSellsTable
              accountId={account.id}
              key="estimated-trades"
              style={
                hasSellOrders
                  ? {
                      marginTop: 32,
                    }
                  : {}
              }
            />
          )}
        </>
      );
    }

    return renderNoTradesContent('cryptoNoPendingBuys', 'No pending sells');
  };

  const renderNoTradesContent = (
    picture: IllustrationsType,
    content: TextProps['content'],
  ) => {
    return (
      <Box alignSelf="stretch" textAlign="center" p={32}>
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; height: number; name: "mobile" | "timeout" | "holdings" | "mobileDocumentsEmptyState" | "accountTransfer" | "announcements" | "autopayOrcEnabled" | "balancedPie" | ... 99 more ... | "weddingSmall"; width: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="" height={216} name={picture} width={216} />
        <HXS
          content={content}
          style={{
            marginTop: 32,
          }}
        />
      </Box>
    );
  };

  const renderBuysHeader = (account: Account, buyMetrics: DirectionMetrics) => {
    if (
      account?.estimatedTrading?.excludedBuys &&
      account?.estimatedTrading?.excludedBuys?.total > 0
    ) {
      const totalExcludedBuys = account.estimatedTrading.excludedBuys.total;
      const linkParams = portfolioSliceId
        ? ({
            children: `${totalExcludedBuys} Buys`,
            to: '/d/invest/portfolio/:portfolioSliceId/excluded-buys',
            params: {
              portfolioSliceId: portfolioSliceId,
            },
          } as const)
        : ({
            children: `${totalExcludedBuys} Buys`,
            to: '/d/invest/portfolio/excluded-buys',
          } as const);

      return (
        <>
          Buys: {buyMetrics[0]}
          &nbsp;(
          <Link {...linkParams} />)
        </>
      );
    }
    return `Buys: ${buyMetrics[0]}`;
  };

  return (
    <>
      <Box>
        <StyledHeaderContainer
          hasBanner={Boolean(account.estimatedTrading?.message)}
        >
          {account.estimatedTrading?.message && (
            <Banner
              kind="alert"
              content={account.estimatedTrading.message.content}
              action={
                account.estimatedTrading.message.link && (
                  <LinkableLink
                    linkable={account.estimatedTrading.message.link}
                  />
                )
              }
              mb={24}
            />
          )}
          {portfolioSliceId && (
            <PortfolioSliceNavigableButtonRenderer id={portfolioSliceId} />
          )}
          <Flex justifyContent="space-between">
            <HL
              content={isOnDemand ? 'On-demand trades' : 'Upcoming trades'}
              style={{
                fontWeight: 600,
              }}
            />
          </Flex>
          {account.trading && (
            <>
              <Card backgroundColor="backgroundNeutralMain" mt={16}>
                <PL
                  fontWeight={600}
                  color="foregroundNeutralMain"
                  content="Next trade window"
                  style={{
                    margin: '16px 32px 0 32px',
                  }}
                />
                <StyledTwoColumnGrid px={32} pt={12} pb={24}>
                  {renderTradingWindows()}
                </StyledTwoColumnGrid>
              </Card>
            </>
          )}
          {isOnDemand && (
            <Box mt={32}>
              {account.trading?.onDemandTradingRestrictionReason && (
                <Card p={16} width={400} mb={16}>
                  <PS color="foregroundNeutralSecondary">
                    {account.trading.onDemandTradingRestrictionReason}
                  </PS>
                </Card>
              )}
              <Button
                label="Execute Trades"
                size="large"
                onClick={onExecuteTrades}
                disabled={Boolean(
                  account.trading?.onDemandTradingRestrictionReason,
                )}
              />
            </Box>
          )}
        </StyledHeaderContainer>
      </Box>
      <Container padded="wide">
        <PS content="Actual trades may vary slightly as prices fluctuate. Estimated trades are approximated based on auto-invest, transfers, orders, rebalances, and more." />
        {hasRebalances && (
          <>
            <HXS
              content="Pending rebalances"
              style={{
                marginTop: 32,
              }}
            />
            <AccountRebalancesTable
              account={account}
              style={{
                marginTop: 16,
              }}
            />
          </>
        )}
        <Flex justifyContent="space-between" mt={32} flexWrap="wrap" gap={30}>
          <Flex flexDirection="column" flexGrow={1}>
            <StyledTradesHeader>
              {/* @ts-expect-error - TS2345 - Argument of type 'number[]' is not assignable to parameter of type 'DirectionMetrics'. */}
              <HXS content={renderBuysHeader(account, buyMetrics)} />
              <PL color="foregroundNeutralTertiary" fontWeight={600}>
                Total {formatCurrency(buyMetrics[1])}
              </PL>
            </StyledTradesHeader>
            {renderBuyOrdersAndTrades()}
          </Flex>
          <Flex flexDirection="column" flexGrow={1}>
            <StyledTradesHeader>
              <HXS content={`Sells: ${sellMetrics[0]}`} />
              <PL color="foregroundNeutralTertiary" fontWeight={600}>
                Total {formatCurrency(sellMetrics[1])}
              </PL>
            </StyledTradesHeader>
            {renderSellOrdersAndTrades()}
          </Flex>
        </Flex>
        {account.isCryptoAccount && <CryptoDisclosure mt={16} />}
      </Container>
    </>
  );
};
