import * as React from 'react';

import { AppContext } from '~/AppContext';
import { NewsList } from '~/components/news-list';
import { useGetSecurityNewsQuery } from '~/graphql/hooks';
import type { SecurityNewsFragment } from '~/graphql/types';

export type SecurityNewsProps = {
  hasBorder?: boolean;
  loadMoreLabel?: string;
  loadMoreQuantity?: number;
  securityId: string;
};

export const SecurityNews = ({
  hasBorder,
  loadMoreLabel,
  loadMoreQuantity = 5,
  securityId,
}: SecurityNewsProps) => {
  const { data, fetchMore } = useGetSecurityNewsQuery({
    variables: {
      first: 5,
      securityId,
    },
  });
  const { analytics } = React.useContext(AppContext);

  const security = data?.security as SecurityNewsFragment | null | undefined;

  const securityNews = security?.securityNews;
  const pageInfo = securityNews?.pageInfo;

  const articles = React.useMemo(() => {
    const marketNews = securityNews?.edges;
    const articles = [];
    if (marketNews) {
      for (const articleSet of marketNews) {
        if (articleSet?.node) {
          articles.push(articleSet.node);
        }
      }
    }
    return articles;
  }, [securityNews]);

  const handleClickLoadMore = React.useCallback(() => {
    analytics.recordAppAnalyticsEvent({
      name: 'm1_security_details_data_discovered',
      customParameters: [
        {
          name: 'data_id',
          value: 'additional_news',
        },
      ],
      customBoolParameters: [],
      customNumberParameters: [],
      valueParameter: null,
    });
    fetchMore({
      variables: {
        after: securityNews?.pageInfo.endCursor,
        first: loadMoreQuantity,
      },
    });
  }, [securityNews, fetchMore, analytics, loadMoreQuantity]);

  return (
    <NewsList
      articles={articles}
      canFetchMore
      hasBorder={hasBorder}
      hasNextPage={pageInfo?.hasNextPage || false}
      loadMoreLabel={loadMoreLabel}
      onLoadMoreClick={handleClickLoadMore}
      size="small"
    />
  );
};
