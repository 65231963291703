import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  HasInvestAccountSagaDocument,
  type HasInvestAccountSagaQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* hasInvestAccountSaga(): SagaIterator<boolean> {
  const { data }: HasInvestAccountSagaQueryResult = yield call(
    apolloQuerySaga,
    {
      query: HasInvestAccountSagaDocument,
    },
  );

  if (!data?.viewer || !data.viewer.invest) {
    throw new Error(
      'Unable to determine whether the user has an invest account.',
    );
  }

  return Boolean(data.viewer.invest.hasInvestAccount);
}
