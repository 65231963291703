import { Box, Button, HM, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { GenericSystemError } from '~/components/GenericSystemError';
import { SliceableNameCell } from '~/components/pie';
import { useGetExcludedBuysPageQuery } from '~/graphql/hooks';
import type {
  ExcludedBuyEntryFragment,
  ExcludedBuysTableFragment,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useParams } from '~/hooks/useParams';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';
import { formatCurrency } from '~/utils/formatting';

const StyledPage = styled(Box)`
  margin: 0 auto;
  padding: 120px 0 100px 0;
  width: 583px;
`;
const StyledHeaderText = styled(HM)`
  display: block;
  margin-bottom: 16px;
`;

const StyledDescriptionText = styled(PL)`
  display: block;
  margin-bottom: 16px;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-bottom: 16px;
`;

const StyledButtonContainer = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

export const InvestActivityExcludedBuysPage = () => {
  const navigate = useNavigate();
  const { activityId } = useParams();

  const initialVariables = {
    first: 10,
    after: null,
    id: activityId as string,
  };
  const { data, loading, refetch, variables } = useGetExcludedBuysPageQuery({
    skip: !activityId,
    variables: initialVariables,
  });

  if (loading) {
    return <Spinner />;
  }

  if (data?.node?.__typename !== 'InvestActivityTradeSummaryEntry') {
    return <GenericSystemError />;
  }

  const minimumBuyTradeAmount = data.node.account?.minimumBuyTradeAmount;
  const readTradesFromConnection = (
    connection: ExcludedBuysTableFragment['excludedBuys'] | null | undefined,
  ): ExcludedBuyEntryFragment[] => {
    const trades = [];
    if (connection && connection.edges) {
      for (const edge of connection.edges) {
        if (edge && edge.node) {
          trades.push(edge.node);
        }
      }
    }
    return trades;
  };

  const trades = readTradesFromConnection(
    data.node.excludedBuys as ExcludedBuysTableFragment['excludedBuys'],
  );

  const hasNextPage = data.node.excludedBuys?.pageInfo.hasNextPage;

  const handleLoadMore = () => {
    refetch({
      first: (variables?.first ?? initialVariables.first) + 20,
    });
  };
  return (
    <StyledPage>
      <StyledHeaderText content="Excluded Trades" />
      {typeof minimumBuyTradeAmount === 'number' && (
        <StyledDescriptionText
          content={`Any estimated buys that are less than the ${formatCurrency(
            minimumBuyTradeAmount,
          )} minimum will be excluded from trading.`}
        />
      )}
      <StyledExternalLink
        label="Learn More"
        destination="SUPPORT_EXCLUDED_BUYS"
      />
      {data.node.excludedBuys && (
        <>
          <GridTable gridTemplateColumns="70% auto">
            <GridTable.HeaderRow>
              <GridTable.HeaderCell label="Name" />
              <GridTable.HeaderCell align="right" label="Value" />
            </GridTable.HeaderRow>
            {trades.map((trade, index) => (
              <GridTable.Row key={`${index}${trade.amount}`}>
                <GridTable.Cell>
                  {trade.tradeSecurity.security ? (
                    <SliceableNameCell
                      sliceable={trade.tradeSecurity.security}
                    />
                  ) : (
                    trade.tradeSecurity.descriptor
                  )}
                </GridTable.Cell>
                <GridTable.Cell
                  content={formatCurrency(trade.amount)}
                  justifySelf="end"
                />
              </GridTable.Row>
            ))}
          </GridTable>

          {hasNextPage === true && (
            <StyledButtonContainer>
              <Button kind="primary" size="small" onClick={handleLoadMore}>
                Load More
              </Button>
            </StyledButtonContainer>
          )}
        </>
      )}
      <Button
        label="Done"
        mb={16}
        mt={64}
        onClick={() => {
          navigate({
            to: `/d/c/activity/:activityId/activity-details`,
            params: { activityId },
          });
        }}
        size="large"
      />
    </StyledPage>
  );
};
