import { Box } from '@m1/liquid-react';
import React from 'react';

import { useOnMount } from '~/hooks/useOnMount';
import { clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { PaymentContainer } from './components/CreatePaymentWizard.styled';
import { CreatePaymentProvider } from './CreatePaymentProvider';
import { CreatePaymentWizard } from './CreatePaymentWizard';

export const CreatePaymentPage = () => {
  const dispatch = useDispatch();

  // Ensure that form fields are cleared whenever the cover-wizard is initially mounted
  useOnMount(() => {
    dispatch(clearSingleReactHookForm('createPayment'));
  });

  return (
    <Box>
      <CreatePaymentProvider>
        <PaymentContainer>
          <CreatePaymentWizard />
        </PaymentContainer>
      </CreatePaymentProvider>
    </Box>
  );
};
