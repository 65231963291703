import { isNil } from '../typeAssertions';

type PercentageFormatEnum = 'INTEGER' | 'DECIMAL';

export function formatPercentage(
  value: number,
  format?: Maybe<PercentageFormatEnum>,
): string;
/* eslint-disable-next-line no-redeclare */
export function formatPercentage(
  value: Maybe<number>,
  format?: Maybe<PercentageFormatEnum>,
): string | null;
/* eslint-disable-next-line no-redeclare */
export function formatPercentage(
  value: Maybe<string>,
  format?: Maybe<PercentageFormatEnum>,
): string | null;
/* eslint-disable-next-line no-redeclare */
export function formatPercentage(
  value: Maybe<number> | Maybe<string>,
  format?: Maybe<PercentageFormatEnum>,
): string | null {
  const percentageFormat = format ?? 'DECIMAL';
  const numericValue = typeof value === 'number' ? value : Number(value);

  if (isNil(value) || isNaN(numericValue)) {
    return null;
  }

  switch (percentageFormat) {
    case 'INTEGER':
      return IntegerPercentageFormatter.format(numericValue);
    case 'DECIMAL':
    default:
      return DecimalPercentageFormatter.format(numericValue);
  }
}

/**
 * Do not use this formatter directly for percentages.
 * Use formatPercentage instead.
 */
const DecimalPercentageFormatter = Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Do not use this formatter directly for percentages.
 * Use formatPercentage instead.
 */
const IntegerPercentageFormatter = Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});
