import { LM, Text } from '@m1/liquid-react';
import last from 'lodash-es/last';
import truncate from 'lodash-es/truncate';
import React from 'react';

import type { FundSubCategory, SecuritySubCategory } from '~/graphql/types';
import { Radio } from '~/toolbox/radio';

import {
  StyledRadioButton,
  StyledFilterLabelContainer,
} from './ScreenerFilter.styled';

type FilterProps = {
  label?: string | null | React.ReactNode;
  onChangeFilter: (filter: string) => void;
  categories: SecuritySubCategory[] | FundSubCategory[];
  selected: string | null | undefined;
};

export const Filter = ({
  categories,
  onChangeFilter,
  selected,
  label,
}: FilterProps) => {
  return (
    <div>
      {label && (
        <StyledFilterLabelContainer>
          <LM content={label} />
        </StyledFilterLabelContainer>
      )}
      <Radio.Group onChange={onChangeFilter} value={selected} size="small">
        {categories.map(({ category, count }, index) => {
          const name = last(category);
          return (
            <StyledRadioButton
              label={
                <Text
                  as="small"
                  content={`${truncate(name, {
                    length: 28,
                  })} (${count})`}
                />
              }
              value={name}
              key={index}
            />
          );
        })}
      </Radio.Group>
    </div>
  );
};
