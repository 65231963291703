import { Button, type ButtonProps, HotButton } from '@m1/liquid-react';
import * as React from 'react';

import { useNewPieEditorEntry, type IdArg } from './useNewPieEditorEntry';

export const PieEditorEntryButton = ({
  pieEditorRouteParam,
  isCrypto,
  ...props
}: OmitUnion<ButtonProps, 'onClick'> & IdArg) => {
  const handleClick = useNewPieEditorEntry();

  return (
    <Button
      {...props}
      onClick={() => handleClick({ pieEditorRouteParam, isCrypto })}
    />
  );
};

export const PieEditorEntryHotButton = ({
  pieEditorRouteParam,
  isCrypto,
  ...props
}: OmitUnion<React.ComponentProps<typeof HotButton>, 'onClick'> & IdArg) => {
  const handleClick = useNewPieEditorEntry();

  return (
    <HotButton
      {...props}
      onClick={() => handleClick({ pieEditorRouteParam, isCrypto })}
    />
  );
};
