import { Box, Flex, HS, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { ChartableSliceChart } from '~/components/ChartableSliceChart/ChartableSliceChart';
import type { ChartableSliceChartRangeSelectionChange } from '~/components/ChartableSliceChart/ChartableSliceChart.types';
import { ChartableSliceChartProvider } from '~/components/ChartableSliceChart/ChartableSliceChartProvider';
import { formatCurrency } from '~/utils/formatting';

import { BACKTESTING_INITIAL_VALUE } from './SharePieDetailsSection/SharePieDetailsSection.utils';

const RangeSelectorHider = styled(Box)`
  .highcharts-range-selector-buttons {
    display: none;
  }

  margin: 0 6px;
`;

export type SharePiePerformanceChartSectionProps = {
  pieId: string;
  sinceDate: string;
};

export const SharePiePerformanceChartSection = ({
  pieId,
  sinceDate,
}: SharePiePerformanceChartSectionProps) => {
  const [_, setChartRange] =
    React.useState<ChartableSliceChartRangeSelectionChange | null>(null);

  return (
    <Flex
      flexDirection="column"
      margin="80px auto 0"
      maxWidth={1200}
      width="100%"
    >
      <HS fontWeight={300} color="blue05" px={16}>
        Market gain over time
      </HS>
      <PL
        color="foregroundNeutralSecondary"
        content={`If you had deposited ${formatCurrency(BACKTESTING_INITIAL_VALUE, 'SELECTIVE')} into this Pie on ${sinceDate}¹`}
        fontWeight={400}
        px={16}
      />
      <RangeSelectorHider>
        <ChartableSliceChartProvider>
          <ChartableSliceChart
            chartableSliceIds={[pieId]}
            chartName="research_pies"
            features={{
              dateRangeInputs: false,
              navigator: false,
              print: false,
            }}
            initialValueForBacktesting={BACKTESTING_INITIAL_VALUE}
            periods={['all']}
            defaultPeriod="all"
            onRangeSelectionChange={setChartRange}
          />
        </ChartableSliceChartProvider>
      </RangeSelectorHider>
    </Flex>
  );
};
