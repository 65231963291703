import { PL, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

const StyledIcon = styled(Icon)`
  user-select: none;
`;

export const DragAndDropDialogMessage = () => (
  <PL>
    When a new Pie has been created, a drag and drop icon will appear here. You
    can move slices by clicking and holding a row that has the drag icon.
    <StyledIcon name="drag24" ml={5} top={3} draggable={false} /> .
  </PL>
);
