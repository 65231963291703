import { Box, Button, Flex, HXS, HXXS, LM, PL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { buildFullName } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenProfileSection';
import { useInvestAccountReviewStepRequiredDocumentsQuery } from '~/graphql/hooks';
import { useDispatch, useSelector } from '~/redux/hooks';
import { OPEN_INVEST_ACCOUNT_STEPS } from '~/static-constants';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { buildAddress, maskSsn } from '~/utils';

import { DocumentsColumns } from '../DocumentsColumns';

type OnFinishPayload = {
  signature: string;
};

type CustodialReviewStepComponentProps = {
  onFinishStep: (arg0: OnFinishPayload) => void;
};

export const CustodialReviewStep = ({
  onFinishStep,
}: CustodialReviewStepComponentProps) => {
  const dispatch = useDispatch();

  const custodialAccountForm = useSelector((state) => {
    return state.form['custodial-contact-info']?.values;
  });

  const { data, loading, error } =
    useInvestAccountReviewStepRequiredDocumentsQuery({
      variables: {
        accountRegistration: 'CUSTODIAL',
      },
    });
  if (loading) {
    return <Spinner />;
  }
  if (error || !data?.viewer.invest) {
    return <GenericSystemError />;
  }

  const { requiredDocuments } = data.viewer.invest;

  return (
    <Box maxWidth={588} mx="auto">
      <HXS content="Does everything look correct?" pb={16} />
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" mb={24}>
          <Flex flexDirection="column">
            <HXXS content="Account type" />
          </Flex>
          <Button
            kind="link"
            label="Edit"
            style={{ fontSize: '16px' }}
            onClick={() => {
              dispatch({
                type: 'CHANGE_OPEN_INVEST_ACCOUNT_STEP',
                payload: OPEN_INVEST_ACCOUNT_STEPS.SELECT_ACCOUNT_TYPE,
              });
            }}
          />
        </Flex>

        <LM content="Custodial" color="foregroundNeutralSecondary" />
      </Flex>

      <Divider spacing="relaxed" />

      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <HXXS content="Custodial information" />
        </Flex>
        <Button
          kind="link"
          label="Edit"
          style={{ fontSize: '16px' }}
          onClick={() => {
            dispatch({
              type: 'CHANGE_OPEN_INVEST_ACCOUNT_STEP',
              payload: OPEN_INVEST_ACCOUNT_STEPS.CUSTODIAL,
            });
          }}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={16}>
        <LM content="Full Name" color="foregroundNeutralSecondary" />
        <LM
          content={buildFullName({
            firstName: custodialAccountForm.firstName,
            middleInitial: custodialAccountForm.middleInitial,
            lastName: custodialAccountForm.lastName,
            suffix: custodialAccountForm.suffix,
          })}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <LM content="Date of Birth" color="foregroundNeutralSecondary" />
        <LM content={custodialAccountForm.dateOfBirth} />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <LM
          content="Country of Citizenship"
          color="foregroundNeutralSecondary"
        />
        <LM content={custodialAccountForm.countryOfCitizenship} />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <LM
          content="Social Security Number"
          color="foregroundNeutralSecondary"
        />
        <LM content={maskSsn(custodialAccountForm.ssn)} />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <LM content="Legal Residence" color="foregroundNeutralSecondary" />
        <LM content={buildAddress(custodialAccountForm.legalResidence)} />
      </Flex>
      <Divider spacing="relaxed" />
      <Box py={16}>
        <PL
          content={requiredDocuments.preamble}
          mb={16}
          color="foregroundNeutralMain"
        />
        <DocumentsColumns>
          {requiredDocuments.documents.map((d) => (
            <Box key={d.url} pb={8}>
              <Link to={d.url} target="_blank">
                {d.title}
              </Link>
            </Box>
          ))}
        </DocumentsColumns>
        <PL content={requiredDocuments.postamble} />
      </Box>
      <Divider spacing="relaxed" />
      <Button
        size="large"
        label="Continue"
        onClick={() => {
          onFinishStep({
            signature: requiredDocuments.signature,
          });
        }}
        mb={64}
      />
    </Box>
  );
};
