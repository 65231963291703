import { useReactiveVar } from '@apollo/client';
import { Button, type ButtonProps } from '@m1/liquid-react';
import * as React from 'react';

import {
  clickedSliceCheckbox,
  selectedSlicesVar,
} from '~/graphql/local/reactiveVars/selectedSlices';
import type { SliceableCellFragment } from '~/graphql/types';
import {
  clickedAddToPortfolio,
  clickedSliceableSelector,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';

type SliceableAddToPortfolioButtonProps = {
  id: string;
  sliceable: SliceableCellFragment;
  [key: string]: any;
};

export const SliceableAddToPortfolioButton = ({
  id,
  sliceable,
  ...rest
}: SliceableAddToPortfolioButtonProps) => {
  const dispatch = useDispatch();
  const isInAddMode = useSelector((state) => state.mode === APP_MODES.ADD);

  const isSelected = useReactiveVar(selectedSlicesVar).some(
    (slice) => slice.id === id,
  );

  const readLabel = () => {
    if (isInAddMode) {
      return isSelected ? 'Remove from Basket' : 'Add to Basket';
    }
    return 'Add to Portfolio';
  };

  const handleClick = () => {
    if (isInAddMode) {
      dispatch(clickedSliceableSelector(id));
      clickedSliceCheckbox(sliceable);
    } else {
      dispatch(clickedAddToPortfolio(id));
    }
  };

  const buttonProps: ButtonProps = {
    label: rest.label ?? readLabel(),
    kind: rest.kind ?? (isSelected ? 'secondary' : 'primary'),
    size: 'medium',
    onClick: handleClick,
    ...rest,
  };

  return <Button {...buttonProps} />;
};
