import { Box, Button, Flex, PM, PS, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import type { PortfolioSliceOrderLinkFragment } from '~/graphql/types';
import { clickedSetOrder } from '~/redux/actions';
import type { ClickedSetOrderAction } from '~/redux/actions/events/eventsActions.types';
import { useDispatch } from '~/redux/hooks';

export type PortfolioSliceOrderLinkProps = {
  isFromHoldingsPositionDetail?: boolean;
  isSellOrder?: boolean;
  onClick?: (opts: ClickedSetOrderAction['payload']) => void;
  portfolioSlice: PortfolioSliceOrderLinkFragment;
  previousRouteName: string | null | undefined;
};

export const PortfolioSliceOrderLink = ({
  isFromHoldingsPositionDetail,
  isSellOrder,
  onClick,
  portfolioSlice,
  previousRouteName,
}: PortfolioSliceOrderLinkProps) => {
  const dispatch = useDispatch();

  const handleClickLink = React.useCallback(() => {
    const payload = {
      isFromHoldingsPositionDetail: isFromHoldingsPositionDetail,
      portfolioSliceId: portfolioSlice.id,
      previousRouteName: previousRouteName,
      setAsSellOrder: isSellOrder,
    };
    if (typeof onClick === 'function') {
      onClick(payload);
    } else {
      dispatch(clickedSetOrder(payload));
    }
  }, [
    dispatch,
    isFromHoldingsPositionDetail,
    isSellOrder,
    onClick,
    portfolioSlice,
    previousRouteName,
  ]);

  const orderUnavailableDescription = React.useMemo(() => {
    if (portfolioSlice.orderStatus?.isSetOnSelf) {
      return 'Placing an order is unavailable while there is a pending order on this security. To place an order, you must cancel your pending order or wait for it to process.';
    } else if (portfolioSlice.orderStatus?.isLocked) {
      const { lockReason } = portfolioSlice.orderStatus;
      if (lockReason === 'LOCKED_BY_REBALANCE') {
        return 'Placing an order is unavailable while there are pending rebalance requests. To place an order, you must first cancel all rebalance requests.';
      } else if (lockReason === 'LOCKED_BY_TRADING') {
        return 'Trading for your account is currently in progress. Please try again after the trade window.';
      } else if (lockReason === 'LOCKED_BY_LIQUIDATION') {
        return 'Placing an order is unavailable while your account is liquidating.';
      }
    }
    return 'Placing an order is currently unavailable. Please try again later.';
  }, [portfolioSlice]);

  const isDisabled = Boolean(
    portfolioSlice.orderStatus?.isSetOnSelf ||
      portfolioSlice.orderStatus?.isLocked,
  );

  return (
    <Flex alignItems="center">
      <Button
        kind="link"
        label={isSellOrder ? 'Sell' : 'Buy/Sell'}
        style={{ fontSize: '16px' }}
        disabled={isDisabled}
        onClick={handleClickLink}
      />
      {isDisabled && (
        <Box ml={8}>
          <Tooltip
            placement="bottom-start"
            icon="tooltip16"
            iconColor="primary"
            body={
              <Flex flexDirection="column">
                <PM content="Sell unavailable" textAlign="left" />
                <PS content={orderUnavailableDescription} textAlign="left" />
              </Flex>
            }
          />
        </Box>
      )}
    </Flex>
  );
};
