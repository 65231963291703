import { Button, HM, HXXS, PXL, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { WithdrawModal } from '~/components/WithdrawModal';
import { useRemoveCreditFreezePageQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';
import { Container } from '~/toolbox/container';
import { List } from '~/toolbox/list';
import { Spinner } from '~/toolbox/spinner';

import {
  StyledApplicationBottomCardContainer,
  StyledApplicationLeftCardContainer,
  StyledApplicationRightCardContainer,
  StyledApplicationTopCardContainer,
} from '../components/ApplicationCard';
import { StyledApplicationContainer } from '../components/ApplicationContainer';

type RemoveCreditFreezePageProps = {
  onFinishStep: (arg0: {
    applicationId?: string;
    loanId?: string;
    withdrawingApplication?: boolean;
  }) => void;
};

const StyledContainer = styled(Container)`
  min-height: 600px;
`;

export const RemoveCreditFreezePage = ({
  onFinishStep,
}: RemoveCreditFreezePageProps) => {
  const analytics = useAnalytics();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const { applicationId, loanId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const { data, loading, error } = useRemoveCreditFreezePageQuery();
  if (loading) {
    return <Spinner fullScreen centered />;
  }
  const loanDeclinedErrorScreen =
    data?.viewer.borrow?.personalLoans?.applicationFlow
      ?.loanDeclinedErrorScreen;
  if (error || !loanDeclinedErrorScreen) {
    return <GenericSystemError />;
  }

  const handleFinishStep = () => {
    onFinishStep({
      applicationId,
      loanId,
      withdrawingApplication: false,
    });
  };

  return (
    <StyledContainer pt={8}>
      <WithdrawModal
        open={modalIsOpen}
        onClick={() => {
          analytics.recordEvent(
            'm1_personal_loan_speedbump_withdraw_confirmed',
          );
          setModalIsOpen(false);
          return onFinishStep({
            applicationId,
            loanId,
            withdrawingApplication: true,
          });
        }}
        onClose={() => {
          analytics.recordEvent('m1_personal_loan_speedbump_resume_clicked');
          return setModalIsOpen(false);
        }}
      />
      <StyledApplicationContainer>
        <StyledApplicationTopCardContainer>
          <StyledApplicationLeftCardContainer>
            <HM content={loanDeclinedErrorScreen.title} />
            <PXL mt={32}>{loanDeclinedErrorScreen.subtitle}</PXL>
            {loanDeclinedErrorScreen.bureaus && (
              <>
                <HXXS
                  mt={25}
                  fontWeight={500}
                  content="Credit bureau(s) to unfreeze:"
                />
                <List>
                  {loanDeclinedErrorScreen.bureaus.map((bureau) => {
                    return (
                      <List.Item
                        key={bureau}
                        mt={10}
                        ml={30}
                        content={bureau}
                      />
                    );
                  })}
                </List>
              </>
            )}
            <Checkbox
              ml={-4}
              mt={40}
              label={loanDeclinedErrorScreen.checkBoxText}
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
          </StyledApplicationLeftCardContainer>
          <StyledApplicationRightCardContainer>
            <Illustration
              // @ts-expect-error - TS2322 - Type '{ alt: string; name: "safeguardAccount"; width: number; height: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
              alt=""
              name="safeguardAccount"
              width={232}
              height={400}
            />
          </StyledApplicationRightCardContainer>
        </StyledApplicationTopCardContainer>
      </StyledApplicationContainer>
      <StyledApplicationBottomCardContainer>
        {loanDeclinedErrorScreen.ctaLink?.title && (
          <Button
            kind="primary"
            label={loanDeclinedErrorScreen.ctaLink.title}
            disabled={!isChecked}
            ml={16}
            onClick={() => {
              analytics.recordEvent(
                'm1_personal_loan_credit_freeze_resubmit_clicked',
              );
              handleFinishStep();
            }}
            size="large"
          />
        )}
        <Button
          kind="text"
          label="Withdraw application"
          size="large"
          onClick={() => {
            if (loanDeclinedErrorScreen.analyticsEvent) {
              analytics.recordAppAnalyticsEvent(
                loanDeclinedErrorScreen.analyticsEvent,
              );
            }
            return setModalIsOpen(true);
          }}
        />
      </StyledApplicationBottomCardContainer>
    </StyledContainer>
  );
};
