import castArray from 'lodash-es/castArray';
import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  GetUserKeysByKeyDocument,
  type GetUserKeysByKeyQueryResult,
} from '~/graphql/hooks';
import type { GetUserKeysByKeyQueryVariables } from '~/graphql/types';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* readUserData(
  k: string | ReadonlyArray<string>,
): SagaIterator<any> {
  const keys = castArray(k);
  try {
    const { data }: GetUserKeysByKeyQueryResult = yield call(apolloQuerySaga, {
      query: GetUserKeysByKeyDocument,
      variables: {
        keys,
      } as GetUserKeysByKeyQueryVariables,
    });

    const viewer = data?.viewer;
    if (viewer && viewer.user) {
      return Array.isArray(k)
        ? viewer.user.data.map((e) => e.value)
        : viewer.user.data[0].value;
    }
  } catch (e: any) {
    // Do nothing
  }

  return null;
}
