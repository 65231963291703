import { Flex, PL, styled, theme } from '@m1/liquid-react';
import React from 'react';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { usePersonalLoansDirectQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from '../components/StyledHeader';
import { getQuestionProps } from '../utils/getQuestionprops';

const StyledAppImage = styled(AppImage)`
  width: 264px;
  height: 264px;
  margin-bottom: 40px;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    width: 364px;
    height: 364px;
  }
`;

const SupportButton = styled(LinkableButton)`
  color: ${theme.colors.foregroundNeutralMain};
  margin-top: 16px;
  margin-bottom: 16px;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    margin-top: 0;
  }
`;

const StyledButtonGroup = styled(Flex)`
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    flex-direction: row;
    align-items: center;
    width: auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Linkable Buttons have yet to be moved over to LiRe so need to override link color
  a {
    color: ${theme.colors.foregroundPrimary};
  }
`;

export const BankConnection = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const { data, loading } = usePersonalLoansDirectQuery();

  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  const props =
    questions &&
    getQuestionProps({
      question: 'BANK_CONNECTION',
      questions,
    });

  const { continueLabel, title, subtitle, link, image } = props || {};

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/prompt-for-bank');
  }, [analytics]);

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      {image && <StyledAppImage appImage={image} />}
      <StyledHeader
        content={title || 'Congrats, you have been approved!'}
        mb={8}
      />
      <PL
        content={
          subtitle ||
          `Now, let’s connect your bank so we can deliver your money quickly.`
        }
      />
      <StyledButtonGroup mt={48}>
        <ResponsiveButton
          label={continueLabel || 'Instantly connect Your bank'}
          onClick={() => {
            navigate({
              to: '/d/c/connect-bank',
              query: { connectionType: 'personal_loans_direct' },
            });
          }}
        />
        {link && <SupportButton kind="text" linkable={link} />}
      </StyledButtonGroup>
    </Flex>
  );
};
