import { Box, Flex, LL, Text, Card } from '@m1/liquid-react';
import * as React from 'react';
import { change } from 'redux-form';

import { AppContext } from '~/AppContext';
import { IRSContributionLimitsDescription } from '~/components/IRSContributionLimitsDescription';
import { connectForm } from '~/hocs';
import { IRAContributionLimitModal } from '~/pages/setup-ira-account/components/IRAContributionLimitModal';
import type { AppState } from '~/redux';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Link } from '~/toolbox/link';

import {
  CheckboxField,
  RetirementContributionYearDropdownField,
} from '../fields';
import { required } from '../validators';

import { AccountHeader } from './AccountHeader';
import { DepositActionButtons } from './DepositActionButtons';
import { DepositAmountInput } from './DepositAmountInput';
import { DepositPillSection } from './DepositPillSection';
import { RecurringDepositSection } from './RecurringDepositSection';
import { TransferParticipantSection } from './TransferParticipantSection';
import type { FundAccountFormProps } from './types';

function readDefaultPillAmounts(
  transfers: FundAccountFormProps['transfers'],
): Array<number> | null {
  if (!transfers) {
    return null;
  }

  return transfers.defaultAmountPills.map((pill) =>
    parseFloat(`${pill?.label?.replace(/[^0-9]/g, '') ?? 0}`),
  );
}

const FundAccountFormComponent = ({
  account,
  fromParticipantId,
  handleSubmit,
  hideAccountNameAndNumber = false,
  isCryptoAccount,
  isScheduleSwitchOn,
  invalidExternalParticipants,
  setIsScheduleSwitchOn,
  onNextStepClick,
  onSkip,
  showForm,
  transfers,
  useOnboardingLayout,
  showSkipCTA = true,
}: FundAccountFormProps & {
  showSkipCTA: boolean;
  hideAccountNameAndNumber: boolean;
}) => {
  const dispatch = useDispatch();
  const { analytics } = React.useContext(AppContext);

  const transferParticipants = React.useMemo(() => {
    if (!transfers?.participants?.list) {
      return [];
    }

    return transfers.participants.list
      .filter(
        (participant) =>
          !invalidExternalParticipants?.includes(
            participant.transferParticipantType,
          ),
      )
      .map((participant) => {
        return {
          label: participant.transferParticipantName,
          description: participant.transferParticipantDescription,
          value: participant.id,
          type: participant.transferParticipantType,
        };
      });
  }, [transfers]);

  const initialConnectedBankAccount = transferParticipants?.[0];

  React.useEffect(() => {
    if (isScheduleSwitchOn) {
      dispatch(change('fund-account', 'isIraRollover', null));
    }
  }, [isScheduleSwitchOn, dispatch]);

  React.useEffect(() => {
    const externalAccount = transferParticipants.find((account) =>
      isCryptoAccount
        ? account.type === 'CRYPTO_EXTERNAL'
        : account.type === 'EXTERNAL' || account.type === 'FUNDING_SOURCE',
    );

    if (fromParticipantId) {
      dispatch(change('fund-account', 'fromParticipantId', fromParticipantId));
    } else if (externalAccount) {
      dispatch(
        change('fund-account', 'fromParticipantId', externalAccount.value),
      );
    }
  }, [transferParticipants, dispatch, fromParticipantId, isCryptoAccount]);

  const isFormValid = useSelector<boolean>((state: AppState) =>
    Boolean(!state.form['fund-account']?.syncErrors),
  );

  const { isIraRollover } = useSelector((state) => {
    return {
      fundAccountAmount:
        state.form['fund-account']?.values?.fundAccountAmount ?? 0,
      isIraRollover: state.form['fund-account']?.values?.isIraRollover ?? false,
    };
  });

  const showSchedule = !(account?.isRetirement && useOnboardingLayout);

  const onNextClick = () => {
    // force a synchronous redux form validation cycle
    // @ts-expect-error - TS2554 - Expected 1-5 arguments, but got 0.
    handleSubmit();

    // if valid, move on to next state
    if (isFormValid) {
      onNextStepClick();
    }
  };

  React.useEffect(() => {
    if (useOnboardingLayout && account?.isRetirement) {
      setIsScheduleSwitchOn(false);
    }
  }, [account?.isRetirement, useOnboardingLayout, setIsScheduleSwitchOn]);

  const defaultPillAmounts = readDefaultPillAmounts(transfers);

  const maximumInitialDeposit = account?.maximumInitialDeposit ?? 0;
  const maxIraContribution =
    account?.isRetirement && !isIraRollover
      ? (account.iraContributionLimits?.currentYearLimit ?? 0)
      : maximumInitialDeposit;

  const maxDeposit = account?.isRetirement
    ? maxIraContribution
    : maximumInitialDeposit;

  return (
    // simply display 'none' to persist the form values between different views
    <Box width={550} display={showForm ? 'initial' : 'none'}>
      {!useOnboardingLayout && account?.name && (
        <AccountHeader accountName={account?.name} />
      )}
      <Card p={44} mb={32}>
        <form>
          <Flex justifyContent="center" flexDirection="column" mb={48}>
            {useOnboardingLayout && !hideAccountNameAndNumber && (
              <Text
                content={initialConnectedBankAccount?.label?.toUpperCase()}
                color="foregroundNeutralSecondary"
                alignSelf="center"
                fontWeight={600}
              />
            )}
            <DepositAmountInput account={account} />
            {defaultPillAmounts && (
              <DepositPillSection
                transferAmounts={defaultPillAmounts}
                onClick={(amount) => {
                  analytics.recordEvent(
                    'm1_initial_funding_amount_pill_clicked',
                    null,
                    {
                      deposit_amount: amount?.toString() ?? '',
                    },
                  );
                  dispatch(change('fund-account', 'fundAccountAmount', amount));
                }}
              />
            )}
          </Flex>
          {!useOnboardingLayout && (
            <>
              <TransferParticipantSection
                transferParticipants={transferParticipants}
              />
              <Link justifyContent="end" mt={16} to="/d/invest/bank-connection">
                Edit bank
              </Link>
            </>
          )}

          {/* NOTE - For recurring transfers, we default to current year contributions, and hide this dropdown. */}
          {transfers?.requirements?.isIraContributionYearRequired &&
            !isScheduleSwitchOn && (
              <RetirementContributionYearDropdownField
                name="iraContributionYear"
                label="Contribution Year"
                validate={[required]}
                style={{
                  marginTop: 16,
                }}
              />
            )}

          {transfers?.requirements?.isIraContributionLimitRequired && (
            <Card
              borderColor="transparent"
              backgroundColor="backgroundNeutralTertiary"
              p={16}
              mt={16}
            >
              <IRSContributionLimitsDescription />
            </Card>
          )}

          {!isScheduleSwitchOn && account?.isRetirement && (
            <Flex mt={32} ml={-4}>
              <CheckboxField
                name="isIraRollover"
                label={
                  <LL content="This deposit was withdrawn from another retirement account within the past 60 days." />
                }
              />
            </Flex>
          )}
          {account?.isRetirement && (
            <Box>
              <IRAContributionLimitModal />
            </Box>
          )}
          {showSchedule && (
            <RecurringDepositSection
              onChange={() => {
                analytics.recordEvent('m1_toggle_click_frequency', null, {
                  toggle: !isScheduleSwitchOn ? 'on' : 'off',
                });
                setIsScheduleSwitchOn(!isScheduleSwitchOn);
              }}
              isEvenWeek={transfers?.isEvenWeek ?? false}
              isScheduleSwitchOn={isScheduleSwitchOn}
            />
          )}
          {useOnboardingLayout && (
            <Link
              target="_blank"
              to="https://m1.com/invest_ach_payment_auth_terms.pdf"
              fontWeight={400}
              marginTop={32}
              textDecoration="underline"
            >
              ACH Payment Terms
            </Link>
          )}
          <DepositActionButtons
            maximumDepositAmount={maxDeposit}
            minimumDepositAmount={account?.minimumInitialDeposit}
            onNextClick={onNextClick}
            onSkip={onSkip}
            useOnboardingLayout={useOnboardingLayout}
            showSkipCTA={showSkipCTA}
          />
        </form>
      </Card>
    </Box>
  );
};

export const FundAccountForm = connectForm({
  form: 'fund-account',
})(FundAccountFormComponent);
