import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import type {
  BiweeklyScheduleInput,
  MonthlyScheduleInput,
  RecurrenceScheduleInput,
  WeeklyScheduleInput,
  WeekOfMonthScheduleInput,
} from '~/graphql/types';
import { isNil, isNotNil } from '~/utils';

const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

/**
 * Extracts only the numbers from the specified string value and returns the
 * result. If no numbers are found, throws an error.
 * @param value Value containing a number
 * @example
 *   console.log(extractNumber('DAY_24'));
 *   // Expected output: 24
 */
const extractNumber = (value: string): number => {
  const matches = value.match(/(\d+)/);
  const numericString = matches?.[0] ?? null;

  if (numericString === null) {
    throw new Error(`Could not extract number from ${value}`);
  }

  return parseInt(numericString, 10);
};

/**
 * Returns the ordinal representation of the specified value containing a
 * number.
 * @param value Value containing a number to convert to ordinal
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
 * @example
 *   console.log(formatOrdinal('DAY_1'));
 *   // Expected output: '1st'
 */
const formatOrdinal = (value: string): string => {
  const numericValue = extractNumber(value);

  const rule = pluralRules.select(numericValue);
  const suffix = suffixes.get(rule);

  return `${numericValue}${suffix}`;
};

const MonthlyScheduleCopyText = ({
  schedule,
}: {
  schedule: MonthlyScheduleInput;
}) => {
  // This check is required since our schema type for `dayOfMonth` is nullable.
  // Ideally, this check should never happen since a user should be restricted
  // from selecting a monthly schedule without also selecting a day of the month.
  if (isNil(schedule.dayOfMonth)) {
    throw new Error('No day associated with monthly chosen recurring deposit');
  }

  const { dayOfMonth } = schedule;

  let dayDisplay;

  if (dayOfMonth === 'LAST') {
    dayDisplay = 'last day of month';
  } else {
    dayDisplay = formatOrdinal(dayOfMonth);
  }

  return <>Today and every week on the {dayDisplay}</>;
};

const WeeklyScheduleCopyText = ({
  schedule,
}: {
  schedule: WeeklyScheduleInput;
}) => <>Today and every week on {capitalize(schedule.dayOfWeek)}</>;

const BiweeklyScheduleCopyText = ({
  schedule,
}: {
  schedule: BiweeklyScheduleInput;
}) => <>Today and every other week on {capitalize(schedule.dayOfWeek)}</>;

const WeekOfMonthScheduleCopyText = ({
  schedule,
}: {
  schedule: WeekOfMonthScheduleInput;
}) => {
  const dayDisplay = capitalize(schedule.dayOfWeek);
  const weekDisplay = formatOrdinal(schedule.weekOfMonth);

  return (
    <>
      Today and every {weekDisplay} week of the month on {dayDisplay}
    </>
  );
};

export const RecurringScheduleCopyText = ({
  schedule,
}: {
  schedule: RecurrenceScheduleInput | null | undefined;
}) => {
  if (isNil(schedule)) {
    throw new Error('No schedule found with associated recurring deposit');
  }

  if (isNotNil(schedule.monthly)) {
    return <MonthlyScheduleCopyText schedule={schedule.monthly} />;
  }

  if (isNotNil(schedule.weekly)) {
    return <WeeklyScheduleCopyText schedule={schedule.weekly} />;
  }

  if (isNotNil(schedule.biweekly)) {
    return <BiweeklyScheduleCopyText schedule={schedule.biweekly} />;
  }

  if (isNotNil(schedule.weekOfMonth)) {
    return <WeekOfMonthScheduleCopyText schedule={schedule.weekOfMonth} />;
  }

  throw new Error('No valid schedule passed');
};
