import * as React from 'react';

import { useScreenerState } from '../../useScreenerState';

import {
  defaultSecurityLimits,
  INITIAL_EQUITY_SCREENER_VARIABLES,
} from './constants';
import { SecurityScreenerQueryResultContext } from './useStockScreenerResult';

type StockScreenerProviderProps = {
  children: React.ReactNode;
};

export const StockScreenerProvider = ({
  children,
}: StockScreenerProviderProps) => {
  const screenerState = useScreenerState({
    screenerType: 'EQUITY',
    defaultVariables: INITIAL_EQUITY_SCREENER_VARIABLES,
    defaultLimits: defaultSecurityLimits,
  });

  return (
    <SecurityScreenerQueryResultContext.Provider value={screenerState}>
      {children}
    </SecurityScreenerQueryResultContext.Provider>
  );
};
