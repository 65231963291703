import partial from 'lodash-es/partial';
import * as React from 'react';
import type { ActionCreator } from 'redux';

import { UNSAFE_connectRedux } from '~/hocs';
import type { AppState } from '~/redux';
import { beginFlow, endFlow, finishedFlowStep } from '~/redux/actions';
import type { FLOWS } from '~/redux/reducers/newFlows';

// TODO(Wolf): Rename this file to `makeFlowComponent`.

// TODO: How to type check props of the factory-created component?

type MakeFlowComponentConfig = {
  begin?: ActionCreator<any>;
  changeStep?: ActionCreator<any>;
  Component?: React.ComponentType<any>;
  name: ValueOf<typeof FLOWS> | string;
  stepElements?: Record<string, React.ReactElement<any>>;
};

type Props = {
  begin: (...args: Array<any>) => any;
  beginFlow: (...args: Array<any>) => any;
  children?: React.ReactNode;
  end: (...args: Array<any>) => any;
  flowState: any;
  onChangeStep?: (step: string) => void;
  onFinish: (...args: Array<any>) => any;
  onFinishStep: (...args: Array<any>) => any;
  overrideStepComponents: Record<string, any>;
  preserveStateOnUnmount?: boolean;
  step: any;
  // TODO: Properly type this
  stepElement?: React.ReactElement<any>;
};

export function makeFlowComponent(config: MakeFlowComponentConfig) {
  class FlowComponent extends React.Component<Props> {
    static defaultProps = {
      onFinish: () => {},
    };

    componentDidMount() {
      const { begin, beginFlow, flowState, ...rest } = this.props; // eslint-disable-line

      if (begin) {
        begin(rest);
      }
      if (beginFlow) {
        beginFlow(rest);
      }

      if (this.props.onChangeStep) {
        this.props.onChangeStep(this.props.step);
      }
    }

    componentDidUpdate(prevProps: Props) {
      if (prevProps.step !== this.props.step && this.props.onChangeStep) {
        this.props.onChangeStep(this.props.step);
      }
    }

    componentWillUnmount() {
      const { end, flowState, preserveStateOnUnmount } = this.props;

      end({
        ...flowState,
        preserveStateOnUnmount,
      });
    }

    render() {
      const {
        step,
        begin,
        flowState,
        end,
        stepElement,
        onFinish,
        overrideStepComponents,
        ...rest
      } = this.props;

      const element =
        (overrideStepComponents && overrideStepComponents[step]) ||
        stepElement ||
        (config.stepElements && config.stepElements[step]);

      const onFinishStep = partial(this.props.onFinishStep, config.name, step);

      const props = {
        ...rest,
        step,
        stepElement: element,
        onFinishStep,
      };

      if (typeof this.props.children === 'function') {
        // @ts-expect-error TS2349: This expression is not callable.
        return this.props.children(props);
      } else if (config.Component) {
        return <config.Component {...props} />;
      } else if (element) {
        return React.cloneElement(element, props);
      }

      return null;
    }
  }
  const enhancer = UNSAFE_connectRedux(
    (state: AppState): Partial<Props> => ({
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string | ValueOf<{ ACAT_WIZARD: "ACAT_WIZARD"; ACTIVATE_CREDIT_CARD: "ACTIVATE_CREDIT_CARD"; ACTIVATE_DEBIT_CARD: "ACTIVATE_DEBIT_CARD"; CREATE_SEND_CHECK: "CREATE_SEND_CHECK"; CREATE_SMART_TRANSFER: "CREATE_SMART_TRANSFER"; ... 21 more ...; SAVINGS_ONBOARDING: "SAVINGS_ONBOARDING"; }>' can't be used to index type 'NewFlowsState'. | TS2538 - Type 'ValueOf<{ ACAT_WIZARD: "ACAT_WIZARD"; ACTIVATE_CREDIT_CARD: "ACTIVATE_CREDIT_CARD"; ACTIVATE_DEBIT_CARD: "ACTIVATE_DEBIT_CARD"; CREATE_SEND_CHECK: "CREATE_SEND_CHECK"; CREATE_SMART_TRANSFER: "CREATE_SMART_TRANSFER"; ... 21 more ...; SAVINGS_ONBOARDING: "SAVINGS_ONBOARDING"; }>' cannot be used as an index type.
      flowState: state.newFlows[config.name],
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string | ValueOf<{ ACAT_WIZARD: "ACAT_WIZARD"; ACTIVATE_CREDIT_CARD: "ACTIVATE_CREDIT_CARD"; ACTIVATE_DEBIT_CARD: "ACTIVATE_DEBIT_CARD"; CREATE_SEND_CHECK: "CREATE_SEND_CHECK"; CREATE_SMART_TRANSFER: "CREATE_SMART_TRANSFER"; ... 21 more ...; SAVINGS_ONBOARDING: "SAVINGS_ONBOARDING"; }>' can't be used to index type 'NewFlowsState'. | TS2538 - Type 'ValueOf<{ ACAT_WIZARD: "ACAT_WIZARD"; ACTIVATE_CREDIT_CARD: "ACTIVATE_CREDIT_CARD"; ACTIVATE_DEBIT_CARD: "ACTIVATE_DEBIT_CARD"; CREATE_SEND_CHECK: "CREATE_SEND_CHECK"; CREATE_SMART_TRANSFER: "CREATE_SMART_TRANSFER"; ... 21 more ...; SAVINGS_ONBOARDING: "SAVINGS_ONBOARDING"; }>' cannot be used as an index type.
      step: state.newFlows[config.name].step,
    }),
    {
      begin: config.begin,
      beginFlow: partial(beginFlow, config.name),
      onFinishStep: finishedFlowStep,

      end: partial(endFlow, config.name),
      changeStep: config.changeStep,
    },
  );

  // @ts-expect-error - TS2345 - Argument of type 'typeof FlowComponent' is not assignable to parameter of type 'JSXElementConstructor<never>'.
  return enhancer(FlowComponent) as any;
}
