import { Flex, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  SliceableNameCell,
  SliceableAddToPortfolioButton,
} from '~/components/pie';
import { SecurityMiniChartAndReturn } from '~/components/security/SecurityMiniChartAndReturn';
import type { ScreenerCryptoFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';
import type { AppState } from '~/redux';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Table } from '~/toolbox/table';
import { isNil, isNotNil } from '~/utils';
import { formatCryptoOrCurrency, formatNumber } from '~/utils/formatting';

import { useCryptoScreenerResult } from './useCryptoScreenerResult';

const PerformanceCellContainer = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    padding-left: 8px;
  }
`;

export const CryptoResultsRow = ({
  hasCryptoAccount,
  crypto,
}: {
  crypto: ScreenerCryptoFragment['node'] | null | undefined;
  hasCryptoAccount: boolean | undefined;
}) => {
  const dispatch = useDispatch();
  const addMode = useSelector((state: AppState) => state.mode === 'ADD');
  const {
    queryResult: { variables },
  } = useCryptoScreenerResult();

  if (!crypto) {
    return null;
  }

  const handleRowClick = (id: string, e: React.SyntheticEvent) => {
    const ele = e.target;
    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'EventTarget'.
    if (ele.type !== 'checkbox') {
      dispatch(clickedOnSliceable(id));
    }
  };

  return (
    <Table.Row key={crypto.symbol}>
      <Table.Cell
        content={
          <Flex>
            <SliceableNameCell
              selectable
              disabled={Boolean(crypto.restrictions)}
              sliceable={crypto}
            />
            {crypto.restrictionPill && (
              <Box flex="1 0 auto" alignSelf="center">
                <AppPill appPill={crypto.restrictionPill} />
              </Box>
            )}
          </Flex>
        }
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={formatCryptoOrCurrency(crypto.quote?.lastPrice) ?? '$--'}
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={formatNumber(crypto.marketCap, 'COMPACT') ?? '--'}
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={formatNumber(crypto.volume, 'COMPACT') ?? '--'}
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={
          isNotNil(crypto.circulatingSupply)
            ? formatNumber(crypto.circulatingSupply, 'COMPACT')
            : '--'
        }
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell align="right" onClick={(e) => handleRowClick(crypto.id, e)}>
        <PerformanceCellContainer>
          <SecurityMiniChartAndReturn
            percentChange={crypto.priceChange?.percentChange}
            securityId={crypto.id}
            period={variables?.period || 'ONE_YEAR'}
          />
        </PerformanceCellContainer>
      </Table.Cell>
      <Table.Cell align="center">
        {/* TODO - make click targets work so surrounding cell still triggers
         clickedOnSliceable but button only triggers adding slices to portfolios
         via modal without navigation */}
        {hasCryptoAccount === true &&
          isNil(addMode) &&
          (crypto.restrictions?.length ?? 0) > 0 && (
            <SliceableAddToPortfolioButton
              sliceable={crypto}
              id={crypto.id}
              type={crypto.type}
              label="Add"
              kind="secondary"
              left={10}
            />
          )}
      </Table.Cell>
    </Table.Row>
  );
};
