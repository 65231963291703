import { Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { DisclosuresForm } from '~/forms';
import type {
  CollectPrimaryDisclosuresStepQuery,
  CollectSecondaryDisclosuresStepQuery,
  ProfileInput,
} from '~/graphql/types';
import { submittedDisclosuresFormModern } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { pickByPaths } from '~/utils';

type NonNullableSecondaryProfile = NonNullable<
  NonNullable<
    CollectSecondaryDisclosuresStepQuery['viewer']['profile']
  >['secondary']
>;

type NonNullablePrimaryProfile = NonNullable<
  NonNullable<
    CollectPrimaryDisclosuresStepQuery['viewer']['profile']
  >['primary']
>;

type CollectDisclosuresStepComponentProps = {
  body: React.ReactElement<any> | string;
  holder: string;
  profile:
    | (NonNullableSecondaryProfile | null | undefined)
    | NonNullablePrimaryProfile;
  title: string;
  onSubmit?: () => void;
};

export const CollectDisclosuresChild = ({
  holder,
  profile,
  title,
  onSubmit,
}: CollectDisclosuresStepComponentProps) => {
  const dispatch = useDispatch();
  const storedHolderInput = useSelector(
    (state) => state.newFlows.accountSetup.input,
  );

  const handleSubmit = React.useCallback(
    (values: Partial<ProfileInput>) => {
      dispatch(
        submittedDisclosuresFormModern({
          holder,
          values: {
            [holder]: values,
          },
        }),
      );

      if (onSubmit) {
        onSubmit();
      }
    },
    [holder, dispatch, onSubmit],
  );

  const initialProfileDisclosures = React.useMemo(() => {
    /* This is will populate the disclosures form with most recent information
    - either what is on user's profile, or what is stored in redux. */
    let profileDisclosures = null;
    if (storedHolderInput[holder]) {
      const storedProfileValues = pickByPaths(storedHolderInput[holder], [
        'exchangeOrFinraAffiliationDisclosure',
        'controlPersonDisclosure',
        'politicalDisclosure',
        'backupWithholding',
      ]);
      profileDisclosures = storedProfileValues;
    } else if (profile) {
      const {
        controlPersonDisclosure,
        politicalDisclosure,
        exchangeOrFinraAffiliationDisclosure,
        backupWithholding,
      } = profile;
      // Convert readOnlyArray values to strings pre-populate in the input fields.
      const disclosures = exchangeOrFinraAffiliationDisclosure || {
        isAffiliated: false,
        firmName: null,
      };
      const serializedPrimaryProfile = {
        ...profile,
        controlPersonDisclosure: {
          ...controlPersonDisclosure,
          companySymbols: controlPersonDisclosure?.companySymbols?.join(','),
        },
        exchangeOrFinraAffiliationDisclosure: disclosures,
        politicalExposureDisclosure: {
          ...politicalDisclosure,
          immediateFamilyMembers:
            politicalDisclosure?.immediateFamilyMembers?.join(','),
        },
        backupWithholding: backupWithholding
          ? {
              isSubjectToBackupWithholding:
                backupWithholding.isSubjectToBackupWithholding,
            }
          : { isSubjectToBackupWithholding: false },
      };

      const primaryValues = pickByPaths(serializedPrimaryProfile, [
        'exchangeOrFinraAffiliationDisclosure',
        'controlPersonDisclosure',
        'politicalExposureDisclosure',
        'backupWithholding',
      ]);
      profileDisclosures = primaryValues;
    }
    return profileDisclosures;
  }, [profile, holder, storedHolderInput]);

  return (
    <Box maxWidth={588} mx="auto">
      <fieldset>
        <Box mb={32}>
          <legend>
            <HM content={title} pt={32} pb={16} />
          </legend>
        </Box>
        <DisclosuresForm
          initialValues={initialProfileDisclosures ?? undefined}
          onSubmit={handleSubmit}
        />
      </fieldset>
    </Box>
  );
};
