import { Button, Flex } from '@m1/liquid-react';
import * as React from 'react';
import { type InjectedFormProps, FormSection } from 'redux-form';

import { connectForm } from '~/hocs';

import { TrustedContactFields } from './fields';

type TrustedContactFormProps = InjectedFormProps<any> & {
  backgroundColor: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
};

const TrustedContactFormComponent = (props: TrustedContactFormProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      {/* @ts-expect-error - TS2769 - No overload matches this call. | TS2786 - 'FormSection' cannot be used as a JSX component. */}
      <FormSection>
        <TrustedContactFields backgroundColor={props.backgroundColor} />
      </FormSection>
      <Flex justifyContent="end" mt={24}>
        <Button kind="primary" type="submit" size="medium" label="Save" />
      </Flex>
    </form>
  );
};

export const TrustedContactForm = connectForm({
  form: 'trusted-contact',
})(TrustedContactFormComponent);
