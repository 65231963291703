import React from 'react';

import type {
  TransferParticipantDetailsFragment,
  TransferParticipantGroupsFragment,
  TransferParticipantRelationshipFragment,
  TransferParticipantSideEnum,
} from '~/graphql/types';

import { isNotNil } from '~/utils';
import { assertAllCasesHandled } from '~/utils/switchStatements';

export type UseParticipantSummaryResult = {
  isSchedule: boolean;
  pivot: TransferParticipantSideEnum;
  sourceDetails: TransferParticipantDetailsFragment | null;
  destinationDetails: TransferParticipantDetailsFragment | null;
  allSourceDetailsList: TransferParticipantDetailsFragment[];
  allDestinationDetailsList: TransferParticipantDetailsFragment[];
  isSelectedRelationshipEligible: boolean;
  selectedRelationship: Maybe<TransferParticipantRelationshipFragment>;
};
export function useParticipantSummary({
  sourceId,
  destinationId,
  isSchedule,
  participantGroups,
  pivot = 'FROM',
}: {
  sourceId: string | null | undefined;
  destinationId: string | null | undefined;
  isSchedule: boolean;
  participantGroups: TransferParticipantGroupsFragment | null | undefined;
  pivot: TransferParticipantSideEnum | undefined;
}): UseParticipantSummaryResult {
  const result = React.useMemo(() => {
    const participantGroupKey = isSchedule ? 'scheduled' : 'oneTime';

    const allSourceDetailsList =
      participantGroups?.[participantGroupKey]?.sourceParticipants ?? [];

    const sourceDetails =
      allSourceDetailsList?.find(
        (sourceDetails) => sourceDetails?.account?.id === sourceId,
      ) ?? null;

    const allDestinationDetailsList =
      participantGroups?.[participantGroupKey]?.destinationParticipants ?? [];

    const destinationDetails =
      allDestinationDetailsList?.find(
        (destinationDetails) =>
          destinationDetails?.account?.id === destinationId,
      ) ?? null;

    /*
     * For most transfers, the anchored side will be the source.
     * This means that all source participants will be eligible, and only
     * destinations that are related to the selected source will be eligible.
     *
     * When a user is making a payment, the anchored side will be the destination.
     */
    let selectedRelationship: Maybe<TransferParticipantRelationshipFragment> =
      null;
    switch (pivot) {
      case 'TO': {
        selectedRelationship = destinationDetails?.relationships?.find(
          (relationship) => relationship?.source?.id === sourceId,
        );
        break;
      }
      case 'FROM': {
        selectedRelationship = sourceDetails?.relationships?.find(
          (relationship) => relationship.destination?.id === destinationId,
        );
        break;
      }
      default: {
        /*
         * If we have a new anchored side, we need to update this switch statement
         * The below will throw an error if a new enum value is not accounted for above.
         */
        return assertAllCasesHandled();
      }
    }

    return {
      allDestinationDetailsList,
      allSourceDetailsList,
      destinationDetails,
      isSchedule,
      isSelectedRelationshipEligible: isNotNil(selectedRelationship),
      pivot,
      selectedRelationship,
      sourceDetails,
    };
  }, [pivot, sourceId, destinationId, isSchedule, participantGroups]);

  return result;
}
