import {
  styled,
  Tooltip,
  type TooltipProps,
  type TextProps,
} from '@m1/liquid-react';
import * as React from 'react';

import {
  HelpTrigger,
  type HelpTriggerKind,
  type HelpTriggerProps,
} from './HelpTrigger';

export type HelpPopupProps = Partial<TooltipProps> & {
  trigger?: React.ReactElement<HelpTriggerProps>;
  kind?: HelpTriggerKind;
  label?: React.ReactNode;
  labelFont?: TextProps['font'];
};

const StyledHelpContent = styled.div`
  padding: 1.4rem 1.7rem;
  font-size: 1.3rem;
  color: var(--colors-foreground-neutral-main);

  text-align: left;
  text-transform: none; // Temporary until Portal
  letter-spacing: normal; // Temporary until Portal

  p {
    margin-bottom: 0.8em;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
  }

  a {
    color: var(--colors-primary);
    text-decoration: underline;
  }
`;

export const HelpPopup = (props: React.PropsWithChildren<HelpPopupProps>) => {
  const { kind, trigger, label, labelFont, ...rest } = props;
  return (
    <Tooltip
      placement="bottom-end"
      delay={550}
      body={<StyledHelpContent>{props.children}</StyledHelpContent>}
      {...rest}
    >
      {trigger || (
        <HelpTrigger labelFont={labelFont} kind={kind} label={label} />
      )}
    </Tooltip>
  );
};
