import * as React from 'react';

import type { GetTransferWizardPreloadQueryResult } from '~/graphql/hooks';
import type {
  EditScheduleTransferRuleFragment,
  TransferParticipantSideEnum,
  TransferScenarioTypeEnum,
} from '~/graphql/types';

import type { TransferModeEnum } from './steps/TransferDetails.types';

export type TransferContextType = {
  mode: TransferModeEnum;
  pivot: TransferParticipantSideEnum;
  scenarioQueryParam: TransferScenarioTypeEnum | null;
  fromParticipantIdQueryParam: string | null;
  toParticipantIdQueryParam: string | null;
  idempotencyKey: string | null | undefined;
  transferRule: EditScheduleTransferRuleFragment | null;
} & GetTransferWizardPreloadQueryResult['data'];

// Note that this context is shared by both the CreateTransferProvider
// and EditTransferScheduleProvider:
export const TransferContext = React.createContext<TransferContextType | null>(
  null,
);
