import { Button } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { BankRoutingNotifications } from '~/components/notifications';
import { connectForm } from '~/hocs';

import { MicroDepositField } from './fields';

class VerifyMicroDepositsFormV2Component extends React.Component<
  InjectedFormProps<any>
> {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <MicroDepositField name="amount1" label="Deposit 1" />
        <MicroDepositField name="amount2" label="Deposit 2" />
        <BankRoutingNotifications />
        <Button
          kind="primary"
          label="Verify"
          mt={64}
          size="large"
          type="submit"
        />
      </form>
    );
  }
}

const enhancer = connectForm({
  form: 'verify-micro-deposits-v2',
});

export const VerifyMicroDepositsFormV2 = enhancer(
  VerifyMicroDepositsFormV2Component,
) as any;
