import { isNil } from '../typeAssertions';

/**
 * @deprecated Use an Input component with `maskType="money"` instead.
 * You can update the defaults for a maskType by using the `mask` property.
 */
export function formatCurrencyInput(value: string | number): string {
  if (value === '-' || value === '') {
    return value;
  }

  if (isNil(value)) {
    return '';
  }

  /*
   * By default we show as many decimal places as are passed in.
   * Example: 1000.5 -> 1,000.5 and not 1,000.50
   */
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
