import type {
  PieBacktestPeriodEnum,
  SortDirectionEnum,
  SystemPieSortTypeEnum,
} from '~/graphql/types';

export const performancePeriods = [
  {
    label: '1Y',
    value: 'ONE_YEAR',
  },
  {
    label: '3Y',
    value: 'THREE_YEARS',
  },
  {
    label: '5Y',
    value: 'FIVE_YEARS',
  },
];

export const PERIOD_TO_SORT: Partial<
  Record<PieBacktestPeriodEnum, SystemPieSortTypeEnum>
> = {
  ONE_YEAR: 'PERFORMANCE_1Y',
  THREE_YEARS: 'PERFORMANCE_3Y',
  FIVE_YEARS: 'PERFORMANCE_5Y',
};

export type SystemPieSort = {
  type: SystemPieSortTypeEnum;
  direction: SortDirectionEnum;
};

export const DEFAULT_SORT: SystemPieSort = {
  type: 'NAME',
  direction: 'ASC',
};

export const INITIAL_GENERAL_INVESTING_SORT: SystemPieSort = {
  type: 'RISK_GRADE',
  direction: 'ASC',
};
