import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  InvestAccountOpeningQuestionsDocument,
  type InvestAccountOpeningQuestionsQueryResult,
} from '~/graphql/hooks';
import type { InvestAccountOpeningQuestion } from '~/graphql/types';
import { apolloQuerySaga } from '~/redux/sagas/apolloQuerySaga';

export function* queryInvestAccountQuestions(): SagaIterator<
  Array<InvestAccountOpeningQuestion>
> {
  const { data }: InvestAccountOpeningQuestionsQueryResult = yield call(
    apolloQuerySaga,
    {
      query: InvestAccountOpeningQuestionsDocument,
    },
  );

  return (data?.viewer?.invest?.accountOpening?.questions || []).reduce(
    (questions, question) => {
      if (question) {
        questions.push(question);
      }
      return questions;
    },
    [] as Array<InvestAccountOpeningQuestion>,
  );
}

export function* queryHasActiveInvestAccount(): SagaIterator<boolean> {
  const { data }: InvestAccountOpeningQuestionsQueryResult = yield call(
    apolloQuerySaga,
    {
      query: InvestAccountOpeningQuestionsDocument,
    },
  );

  return Boolean(data?.viewer?.invest?.hasActiveInvestAccount);
}
