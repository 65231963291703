import { Flex, PL, styled } from '@m1/liquid-react';
import React from 'react';

const StyledDisclosuresContainer = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
  flex-direction: column;
  margin: 40px 0;
  padding-bottom: 40px;
`;

export type DisclosuresSectionProps = React.PropsWithChildren<{
  header: string;
}>;

export const DisclosuresSection = ({
  header,
  children,
}: DisclosuresSectionProps) => {
  return (
    <StyledDisclosuresContainer>
      <PL content={header} mb={32} />
      {children}
    </StyledDisclosuresContainer>
  );
};
