import { Flex } from '@m1/liquid-react';
import React from 'react';

import { AppContext } from '~/AppContext';
import type { AppImageFragment, LinkableFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

type CryptoWhitePaperLinkProps = {
  whitepaperIcon: AppImageFragment | null | undefined;
  whitepaperLink: LinkableFragment;
};

export const CryptoWhitePaperLink = ({
  whitepaperIcon,
  whitepaperLink,
}: CryptoWhitePaperLinkProps) => {
  const { analytics } = React.useContext(AppContext);
  return (
    <Flex>
      {whitepaperIcon && <AppImage appImage={whitepaperIcon} height={24} />}
      <LinkableLink
        linkable={whitepaperLink}
        marginLeft={4}
        onClick={() =>
          analytics.recordAppAnalyticsEvent({
            name: 'm1_security_details_data_discovered',
            customParameters: [
              {
                name: 'data_id',
                value: 'whitepaper_link',
              },
            ],
            customBoolParameters: [],
            customNumberParameters: [],
            valueParameter: null,
          })
        }
      />
    </Flex>
  );
};
