import * as React from 'react';
import type { PlaidLinkError, PlaidLinkOnExitMetadata } from 'react-plaid-link';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SelectPlaidInnerLink } from '~/components/SelectPlaidLink/SelectPlaidInnerLink';
import {
  useCompletePlaidIncomeVerificationMutation,
  useGeneratePlaidIncomeLinkTokenMutation,
  usePersonalLoansApplicationByIdSagaLazyQuery,
} from '~/graphql/hooks';
import type {
  CompletePlaidIncomeVerificationInput,
  LoanApplicationStatusEnum,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { usePollTimer } from '~/hooks/usePollTimer';
import { useSentry } from '~/hooks/useSentry';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { Loader } from '../components/Loader';
import { POLL_INTERVAL, POLL_LIMIT } from '../constants';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import type { PersonalLoanDirectSteps } from '../types';
import { getApplicationStatusRoute } from '../utils/getApplicationStatusRoute';

export const PlaidIncomeVerification = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const analytics = useAnalytics();
  const sentry = useSentry();
  const navigate = useNavigate();
  const { goTo } = usePersonalLoanDirectWizardContext();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [shouldStartApplicationPollTimer, setShouldStartApplicationPollTimer] =
    React.useState<boolean>(false);
  const { isPollLimitReached } = usePollTimer({
    shouldStartPollTimer: shouldStartApplicationPollTimer,
    pollInterval: POLL_INTERVAL,
    pollLimit: POLL_LIMIT,
  });

  const [completePlaidIncomeVerificationMutation] =
    useCompletePlaidIncomeVerificationMutation();
  const [
    getPersonalLoanApplicationById,
    {
      data: personalLoanApplicationData,
      startPolling: startPollingApplication,
      stopPolling: stopPollingApplication,
    },
  ] = usePersonalLoansApplicationByIdSagaLazyQuery();

  const applicationStatus =
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.status?.status;
  const loanStatus =
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.loan?.status;
  const loanId =
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.loan?.id;
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const [generatePlaidIncomeLinkToken, { data, loading: tokenLoading }] =
    useGeneratePlaidIncomeLinkTokenMutation();

  const generateToken = () => {
    generatePlaidIncomeLinkToken({
      variables: {
        input: {
          incomeEnableMultipleItems: true,
        },
      },
      onCompleted: (data) => {
        if (!data.generatePlaidIncomeLinkToken?.didSucceed) {
          sentry.message(
            'PlaidLink: An error occurred while attempting to generate link token',
            {
              level: 'error',
              extra: {
                error: data.generatePlaidIncomeLinkToken?.outcome,
              },
            },
          );
        }
      },
    });
  };

  const onExit = React.useCallback(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      if (error) {
        sentry.message('PlaidLink exited with error', {
          level: 'warning',
          extra: {
            error,
            metadata,
          },
        });
        addToast({
          content:
            'There was an issue verifying your income through Plaid. You can try again or contact Client Support for help.',
          kind: 'alert',
          duration: 'long',
        });
      } else {
        sentry.message('Plaid Income verification exited without error', {
          extra: {
            metadata,
          },
        });
      }
      analytics.recordEvent('m1_personal_loan_income_exited');

      // If they exit, we navigate them back to income verification to try again if interested
      goTo('INCOME_VERIFICATION_REQUIRED');
    },
    [sentry],
  );

  const onSuccess = React.useCallback(() => {
    setLoading(true);
    analytics.recordEvent('m1_personal_loan_income_result_received');
    completePlaidIncomeVerificationMutation({
      variables: {
        input: {} satisfies CompletePlaidIncomeVerificationInput,
      },
      onCompleted: () => {
        getPersonalLoanApplicationById({
          variables: {
            applicationId,
          },
        });
        setShouldStartApplicationPollTimer(true);
        startPollingApplication(POLL_INTERVAL);
      },
      onError: (error) => {
        setLoading(false);
        addToast({
          content: error.message,
          kind: 'alert',
          duration: 'short',
        });
        navigate({ to: '/direct-loan-application-error/application-received' });
      },
    });
  }, [
    completePlaidIncomeVerificationMutation,
    startPollingApplication,
    setShouldStartApplicationPollTimer,
    getPersonalLoanApplicationById,
  ]);

  React.useEffect(() => {
    generateToken();
  }, []);

  // Watching for error states
  React.useEffect(() => {
    const statusesIgnore: LoanApplicationStatusEnum[] = [
      'SUBMITTED',
      'QUEUED',
      'APPROVED',
      'OFFERS_PROVIDED',
      'INCOME_VERIFICATION_REQUIRED',
      'OFFER_SELECTION_QUEUED',
    ];

    if (
      (applicationStatus && !statusesIgnore?.includes(applicationStatus)) ||
      (isPollLimitReached && applicationStatus)
    ) {
      // If a status is returned and it's a status we can take action on (or if the timer limit is reached), stop polling the application status query and setLoading to false (so loader stops spinning).
      stopPollingApplication();
      setShouldStartApplicationPollTimer(false);
      setLoading(false);

      const { route, isWizardStep } =
        getApplicationStatusRoute(applicationStatus);
      if (isWizardStep) {
        goTo(route as keyof PersonalLoanDirectSteps);
      } else {
        navigate({ to: route });
      }
    }
  }, [applicationStatus, isPollLimitReached]);

  // Watching for happy path states
  React.useEffect(() => {
    if (
      (applicationStatus === 'APPROVED' && loanStatus === 'CREATED') ||
      isPollLimitReached
    ) {
      // If the application status is APPROVED and the loan status is CREATED (or if the timer limit is reached), stop polling the application status query and setLoading to false (so loader stops spinning).
      stopPollingApplication();
      setShouldStartApplicationPollTimer(false);
      setLoading(false);

      if (loanStatus === 'CREATED' && loanId) {
        dispatch({
          type: ACTIONS.PERSONAL_LOANS_STORE_LOAN_ID,
          payload: {
            loanId,
          },
        });
        goTo('BANK_CONNECTION');
      } else {
        navigate({ to: '/direct-loan-application-error/application-received' });
      }
    }
  }, [applicationStatus, loanStatus, isPollLimitReached]);

  const linkToken =
    data?.generatePlaidIncomeLinkToken?.outcome?.plaidIncomeLinkToken;

  if (!linkToken && !tokenLoading) {
    // if we don't have a linkToken and it's not loading, return an error
    return <GenericSystemError />;
  }

  if (!linkToken) {
    // This is to ensure we do not pass in an empty linkToken to the SelectPlaidInnerLink component
    return null;
  }

  return loading ? (
    <Loader
      heading="We are submitting your application."
      content="This may take up to a minute."
    />
  ) : (
    <SelectPlaidInnerLink
      onSuccess={onSuccess}
      onExit={onExit}
      linkToken={linkToken}
    />
  );
};
