import { makeLimit } from '~/components/research/Screener/utils';
import type {
  HistoricalQuotePeriodEnum,
  FundScreenerQueryVariables,
  FundSortTypeEnum,
} from '~/graphql/types';

export const defaultLimits = {
  FUND_TOTAL_ASSETS: makeLimit('FUND_TOTAL_ASSETS'),
  FUND_NET_EXPENSE_RATIO: makeLimit('FUND_NET_EXPENSE_RATIO'),
  FUND_DIVIDEND_YIELD: makeLimit('FUND_DIVIDEND_YIELD'),
};

export const INITIAL_FUND_SCREENER_VARIABLES: FundScreenerQueryVariables = {
  first: 20,
  period: 'ONE_YEAR',
  after: null,
  sort: {
    direction: 'DESC',
    type: 'FUND_TOTAL_ASSETS',
  },
  filterCategory: [],
  limit: [],
};

export const PERIOD_TO_SORT: Partial<
  Record<HistoricalQuotePeriodEnum, FundSortTypeEnum>
> = {
  ONE_YEAR: 'PRICE_CHANGE_1Y',
  TWO_YEARS: 'PRICE_CHANGE_2Y',
  FIVE_YEARS: 'PRICE_CHANGE_5Y',
};
