import { Box } from '@m1/liquid-react';
import * as React from 'react';

import type { CreditCardRewardsDetailsFragment } from '~/graphql/types';

import { RewardsDetailsOwners } from './RewardsDetailsOwners';
import { RewardsDetailsStandard } from './RewardsDetailsStandard';

export type RewardsDetailsComponentProps = {
  rewardsDetails: CreditCardRewardsDetailsFragment;
};

export const RewardsDetails = ({
  rewardsDetails,
}: RewardsDetailsComponentProps) => {
  const standardRewards = rewardsDetails.standardRewardsOverview;
  const ownersRewards = rewardsDetails.ownersRewardsOverview;

  return (
    <Box minWidth={250}>
      {standardRewards && (
        <RewardsDetailsStandard standardRewards={standardRewards} />
      )}
      {ownersRewards && <RewardsDetailsOwners ownersRewards={ownersRewards} />}
    </Box>
  );
};
