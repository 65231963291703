import { Box, PS } from '@m1/liquid-react';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';
import type { OnDemandTradeFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';
import { GridTable } from '~/toolbox/grid-table';
import { formatCryptoOrCurrency } from '~/utils/formatting';

export const OnDemandAllocatedTrade = ({
  trade,
}: {
  trade: OnDemandTradeFragment;
}) => {
  const {
    allocatedQuantity,
    allocatedPrice,
    allocatedAmount,
    of,
    note,
    statusPill,
  } = trade;
  const symbol = of?.symbol ?? '';
  return (
    <>
      <GridTable.Cell>
        <SliceableNameCell sliceable={of} />
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <Box>{statusPill && <AppPill appPill={statusPill} />}</Box>
        {note && <PS mt={4}>{note}</PS>}
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        {formatCryptoOrCurrency(allocatedPrice) ?? '$--'}
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        {typeof allocatedQuantity === 'number'
          ? `${allocatedQuantity} ${symbol}`
          : '--'}
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        {formatCryptoOrCurrency(allocatedAmount) ?? '$--'}
      </GridTable.Cell>
    </>
  );
};
