import * as React from 'react';

import { GridTable } from '~/toolbox/grid-table';

export type OnboardingTable = {
  content: Array<Array<string>>;
  header: Array<string>;
};

export type OnboardingTooltip = {
  table?: OnboardingTable;
  text?: Array<string>;
};

export const CollectProfileInputTableTooltip = ({
  table,
}: {
  table: OnboardingTable | null | undefined;
}) => {
  if (!table) {
    return null;
  }

  return (
    <GridTable gridTemplateColumns="80px 80px 80px auto">
      <GridTable.HeaderRow
        style={{
          height: '88px',
          alignContent: 'center',
          whiteSpace: 'normal',
        }}
      >
        {table.header.map((text) => (
          <GridTable.HeaderCell label={text} key={text} />
        ))}
      </GridTable.HeaderRow>
      {table.content.map((row, index) => (
        <GridTable.Row key={index}>
          {row.map((text, idx) => (
            <GridTable.Cell
              textAlign="left"
              content={text}
              key={`${text}:${idx}`}
            />
          ))}
        </GridTable.Row>
      ))}
    </GridTable>
  );
};
