import { Box, Button, Flex, HM, Text } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';

import { useSetOrderCryptoConfirmationQueryQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Link } from '~/toolbox/link';
import { formatCurrency } from '~/utils/formatting';

type ConfirmCryptoOrderProps = {
  onFinishStep: (...args: Array<any>) => any;
};

export const ConfirmCryptoOrder = (props: ConfirmCryptoOrderProps) => {
  const { portfolioSliceId, cashFlow, direction, tradeWindow } = useSelector(
    (state) => ({
      portfolioSliceId: state.newFlows.SET_ORDER.portfolioSliceId,
      cashFlow: state.newFlows.SET_ORDER.input?.cashFlow,
      tradeWindow: state.newFlows.SET_ORDER.input?.tradeWindow,
      direction: state.newFlows.SET_ORDER.direction,
    }),
  );
  const { data } = useSetOrderCryptoConfirmationQueryQuery({
    variables: { id: portfolioSliceId as string },
    skip: !portfolioSliceId,
  });

  const accountName = React.useMemo(() => {
    let name = null;

    if (
      data?.account?.__typename === 'ChildPortfolioSlice' ||
      data?.account?.__typename === 'RootPortfolioSlice'
    ) {
      name =
        data.account.currencies.length === 1
          ? data.account.currencies.map((c) => c?.to.name).join(', ')
          : data.account.to.name;
    }

    return name;
  }, [data]);

  function handleConfirm() {
    props.onFinishStep({ confirmed: true, cashFlow, direction, tradeWindow });
  }

  function handleBack() {
    props.onFinishStep('BACK');
  }

  if (accountName === null) {
    return null;
  }
  const amount = formatCurrency(Math.abs(cashFlow ?? 0));
  const messageContent =
    direction === 'buy'
      ? `You authorize Apex Clearing to transfer ${amount} from your brokerage account held at Apex Clearing to Bakkt Crypto Solutions, LLC ("Bakkt") to pay for your purchase of ${accountName}. The actual quantity of coins purchased may change due to volatility in the price of ${accountName}, but your order will be executed based on the best price available to Bakkt. Cryptocurrency transactions are not FDIC or SIPC insured.`
      : `You authorize Apex Clearing to accept the transfer of ${amount} from Bakkt Crypto Solutions, LLC ("Bakkt") to your account held at Apex Clearing to complete your sale of ${accountName}. The actual quantity of coins sold may change due to volatility in the price of ${accountName}, but your order will be executed based on the best price available to Bakkt. Cryptocurrency transactions are not FDIC or SIPC insured.`;

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      margin="0 auto"
      maxWidth={490}
    >
      <HM>Confirm {direction}</HM>
      <Flex
        backgroundColor="backgroundNeutralMain"
        border="1px solid"
        borderRadius={8}
        borderColor="borderMain"
        flexDirection="column"
        mt={40}
        p={32}
      >
        <Box width={120}>
          <Illustration name="cryptoDisclosure" />
        </Box>
        <Box mt={8}>
          <Text>{messageContent}</Text>
        </Box>
        <Box mt={32}>
          <Link
            css={{ fontWeight: 400, textDecoration: 'underline' }}
            to="https://bakkt.com/funds-transfer-auth"
            title="Baakt Transfer Authorization"
            target="_blank"
          >
            Funds Transfer Authorization
          </Link>
        </Box>
        <Box mt={32}>
          <Link
            css={{ fontWeight: 400, textDecoration: 'underline' }}
            to="https://bakkt.com/disclosures"
            title="Baakt Disclosures"
            target="_blank"
          >
            Baakt Disclosures
          </Link>
        </Box>
        <Flex flexDirection="row" justifyContent="space-between" mt={42}>
          <Button
            kind="secondary"
            label="Back"
            size="large"
            css={{ flexGrow: 1, marginRight: 10 }}
            onClick={handleBack}
          />
          <Button
            kind="primary"
            label={`Confirm ${direction}`}
            size="large"
            css={{ flexGrow: 1, marginLeft: 10 }}
            onClick={handleConfirm}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
