import * as React from 'react';

import { PieShareLink } from '~/components/pie';
import type {
  PortfolioDetailsSliceQuery,
  PortfolioSliceButtonFragment,
} from '~/graphql/types';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { PortfolioDetailsContext } from '../PortfolioDetailsContext';

import { PortfolioSliceEditButton } from './PortfolioSliceEditButton';
import { PortfolioSliceOrderButton } from './PortfolioSliceOrderButton';
import { PortfolioSliceRebalanceButton } from './PortfolioSliceRebalanceButton';

export type PortfolioSliceActionsButtonGroupProps = {
  account: PortfolioSliceButtonFragment;
  portfolioSlice: Extract<
    PortfolioDetailsSliceQuery['portfolioSlice'],
    { id: string }
  >;
};

export const PortfolioSliceActionsButtonGroup = (
  props: PortfolioSliceActionsButtonGroupProps,
) => {
  const { portfolioSlice, account } = props;
  const { isCrypto } = React.useContext(PortfolioDetailsContext);

  return (
    <ButtonGroup behavior="fill-space">
      <PortfolioSliceOrderButton
        type="BUY"
        account={account}
        portfolioSlice={portfolioSlice}
      />
      <PortfolioSliceOrderButton
        type="SELL"
        account={account}
        portfolioSlice={portfolioSlice}
      />
      <PortfolioSliceRebalanceButton
        account={account}
        portfolioSlice={portfolioSlice}
      />
      <PortfolioSliceEditButton portfolioSlice={portfolioSlice} />
      {!isCrypto && portfolioSlice.to.__typename === 'UserPie' && (
        <PieShareLink
          type="button"
          label="Share"
          userPieId={portfolioSlice.to.id}
        />
      )}
    </ButtonGroup>
  );
};
