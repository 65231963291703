import {
  Button,
  Flex,
  styled,
  type ButtonProps,
  type FlexProps,
} from '@m1/liquid-react';
import throttle from 'lodash-es/throttle';
import * as React from 'react';

import {
  useTrackRemoteSubmitState,
  useHandleAutoSubmit,
} from './CodeInputDeprecated.hooks';

type FormRef = HTMLFormElement | null;
type InputRef = HTMLInputElement | null;

type SubmitEvent =
  | React.SyntheticEvent<HTMLInputElement | HTMLFormElement | HTMLButtonElement>
  | null
  | undefined;

type Behavior =
  | 'auto-submit-always'
  | 'auto-submit-just-once'
  | 'never-auto-submit';

type ValidationKind = 'numeric' | 'alpha-numeric' | 'none';

export type CodeInputProps = {
  behavior: Behavior;
  blockInternalSubmit: boolean;
  buttonProps?: ButtonProps;
  hasError: boolean;
  loading: boolean;
  message: (string | null | undefined) | (React.ReactNode | null | undefined);
  onChange?: (
    code: string,
    e: React.SyntheticEvent<HTMLFormElement> | ClipboardEvent,
  ) => void;
  onSubmit: (code: string, e: SubmitEvent) => void;
  validationType: ValidationKind;
  flexProps?: FlexProps;
};

function getValidationRegEx(validationType: ValidationKind): RegExp {
  if (validationType === 'numeric') {
    return new RegExp(/[^0-9]/gi);
  } else if (validationType === 'alpha-numeric') {
    return new RegExp(/[^a-z0-9]/gi);
  }
  return new RegExp(/''/gi);
}

const StyledForm = styled.form<{ $hasError: boolean }>`
  input {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.foregroundNeutralMain} !important;
    color: ${({ theme, $hasError }) => {
      return $hasError
        ? theme.colors.critical
        : theme.colors.foregroundNeutralMain;
    }};
    font-size: 28px;
    line-height: 1;
    margin-left: 4px;
    margin-right: 4px;
    padding: 4px;
    text-align: center;
    width: 100%;
    background-color: inherit;
  }
`;

export const CodeInputDeprecated = ({
  behavior,
  buttonProps = {
    label: 'Submit code',
    fullWidth: true,
    mt: 32,
    size: 'large',
  },
  blockInternalSubmit,
  message,
  loading,
  onSubmit,
  onChange,
  hasError,
  validationType,
  flexProps = {},
}: CodeInputProps) => {
  // refs
  const formRef = React.useRef<FormRef>(null);
  const inputRefs = [
    React.useRef<InputRef>(null),
    React.useRef<InputRef>(null),
    React.useRef<InputRef>(null),
    React.useRef<InputRef>(null),
    React.useRef<InputRef>(null),
    React.useRef<InputRef>(null),
  ];

  React.useEffect(() => {
    // auto focus the first input
    if (inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
  }, []);

  const {
    submitAttemptsCount,
    setSubmitAttemptsCount,
    hasFinishedSubmittingOnce,
  } = useTrackRemoteSubmitState({
    loading,
  });

  const [codeValue, setCodeValue] = React.useState<string>('');

  const showSubmitButton = React.useMemo<boolean>(() => {
    if (blockInternalSubmit) {
      return false;
    }

    switch (behavior) {
      case 'auto-submit-always':
        return false;
      case 'auto-submit-just-once':
        return hasFinishedSubmittingOnce;
      case 'never-auto-submit':
        return true;
      default:
        return true;
    }
  }, [blockInternalSubmit, behavior, hasFinishedSubmittingOnce]);

  const handleSubmitThrottled = (e: SubmitEvent, value: string) => {
    return throttle(() => {
      if (blockInternalSubmit || loading) {
        return;
      }

      onSubmit(value, e);

      setSubmitAttemptsCount(submitAttemptsCount + 1);
    }, 500)();
  };

  useHandleAutoSubmit({
    behavior,
    codeValue,
    handleSubmitThrottled,
    hasFinishedSubmittingOnce,
  });

  const handleFormInput = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const input = e.target;

    // resolves odd flow error. see, https://github.com/facebook/flow/issues/218#issuecomment-74119319
    if (!(input instanceof window.HTMLInputElement)) {
      return;
    }

    const form = e.currentTarget;
    const allInputs = form.querySelectorAll('input');

    // perform validation
    input.value = input.value.replace(getValidationRegEx(validationType), '');

    // sync state value
    let value = '';
    if (e.currentTarget) {
      for (let i = 0; i < allInputs.length; i++) {
        const input = allInputs[i];
        value += input.value;
      }
      setCodeValue(value);
    }

    // focus next input
    if (input.nextSibling && input.value) {
      // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'ChildNode'.
      input.nextSibling.focus();
    }

    if (onChange) {
      onChange(value, e);
    }
  };

  const handleFormKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target;

    if (!(input instanceof window.HTMLInputElement)) {
      return;
    }

    // delete input
    if (e.key === 'Backspace' && input.previousSibling) {
      // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'ChildNode'.
      input.previousSibling.focus();
      // @ts-expect-error - TS2339 - Property 'select' does not exist on type 'ChildNode'.
      input.previousSibling.select();
    }

    // submit on 'Enter'
    if (
      behavior !== 'auto-submit-always' &&
      e.key === 'Enter' &&
      codeValue.length === 6
    ) {
      handleSubmitThrottled(e, codeValue);
    }
  };

  const handlePaste = (
    e: ClipboardEvent,
    inputs: Array<{
      current: InputRef;
    }>,
  ) => {
    if (!e.clipboardData) {
      return;
    }

    const pastedValue = e.clipboardData.getData('text');

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i].current;
      if (input) {
        input.value = pastedValue.charAt(i) || '';
      }
    }

    const lastInputIndex = pastedValue.length < 6 ? pastedValue.length : 5;
    const lastInput = inputs[lastInputIndex];

    if (pastedValue.length >= 6) {
      const substr = pastedValue.substring(0, 6);
      setCodeValue(substr);
    }

    if (lastInput.current) {
      lastInput.current.focus();
    }

    if (onChange) {
      onChange(pastedValue, e);
    }
  };

  return (
    <Flex {...flexProps}>
      <StyledForm
        $hasError={hasError}
        onInput={handleFormInput}
        // @ts-expect-error - TS2769 - No overload matches this call.
        onKeyUp={handleFormKeyUp}
        ref={formRef}
      >
        <Flex flexDirection="row" padding={4} width={280}>
          {[
            'code-input-1',
            'code-input-2',
            'code-input-3',
            'code-input-4',
            'code-input-5',
            'code-input-6',
          ].map((key, i) => {
            return (
              <input
                className={i === 5 ? 'last' : undefined}
                key={key}
                id={key}
                maxLength={1}
                // @ts-expect-error - TS2322 - Type '(e: ClipboardEvent) => void' is not assignable to type 'ClipboardEventHandler<HTMLInputElement>'.
                onPaste={(e: ClipboardEvent) => {
                  e.preventDefault();

                  if (i === 0) {
                    handlePaste(e, inputRefs);
                  }
                }}
                ref={inputRefs[i]}
              />
            );
          })}
        </Flex>
      </StyledForm>
      {message}
      <>
        {showSubmitButton && (
          <Button
            disabled={codeValue.length !== 6 || hasError || loading}
            onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
              handleSubmitThrottled(e, codeValue);
            }}
            {...buttonProps}
          />
        )}
      </>
    </Flex>
  );
};
