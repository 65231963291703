import { Box, Flex, PM, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { InvestFullyPaidLendingDisclosure } from '~/flows/components/onboarding/invest/InvestFullyPaidLendingDisclosure/InvestFullyPaidLendingDisclosure';
import type {
  IdentityFirstConfirmationScreenRequiredDocumentsFragment,
  OnlineAccountRegistrationEnum,
} from '~/graphql/types';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { Link } from '~/toolbox/link';

type IdentityFirstConfirmationScreenDisclaimersComponentProps = {
  requiredDocuments: IdentityFirstConfirmationScreenRequiredDocumentsFragment;
  accountRegistration: OnlineAccountRegistrationEnum;
};

export const IdentityFirstConfirmationScreenRequiredDocumentsInvest = ({
  requiredDocuments,
  accountRegistration,
}: IdentityFirstConfirmationScreenDisclaimersComponentProps) => {
  const { isPhone } = useBreakpoints();

  const midIndex = requiredDocuments.documents.length / 2;
  const colOneDocuments = requiredDocuments.documents.slice(0, midIndex);
  const colTwoDocuments = requiredDocuments.documents.slice(
    midIndex,
    requiredDocuments.documents.length,
  );

  return (
    <Box>
      <PXL
        content="Agreements and Disclosures"
        fontWeight={600}
        py={16}
        textAlign={isPhone ? 'center' : 'left'}
      />
      <Flex width="100%" flexDirection={isPhone ? 'column' : 'row'}>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexWrap="nowrap"
          width="50%"
        >
          {colOneDocuments.map((document) => (
            <Box key={document.url} pb={8}>
              <Link
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  textDecoration: 'underline',
                }}
                to={document.url}
                target="_blank"
              >
                {document.title}
              </Link>
            </Box>
          ))}
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexWrap="nowrap"
          width="50%"
        >
          {colTwoDocuments.map((document) => (
            <Box key={document.url} pb={8}>
              <Link
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  textDecoration: 'underline',
                }}
                to={document.url}
                target="_blank"
              >
                {document.title}
              </Link>
            </Box>
          ))}
        </Flex>
      </Flex>
      <Flex mt={32}>
        <InvestFullyPaidLendingDisclosure
          accountRegistration={accountRegistration}
        />
      </Flex>
      <PM
        content={requiredDocuments.postamble}
        color="foregroundNeutralSecondary"
        pt={24}
      />
    </Box>
  );
};
