import { PL, PM, Flex, Box } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';

import type { SetNotificationPreferencesInput } from '~/graphql/types';
import { Checkbox } from '~/toolbox/checkbox';
import { Divider } from '~/toolbox/divider';

// Defining this custom type because `clientMutationId` doesn't have a
// `sendViaPush` and `sendViaEmail` property on it. So the only way to effectively
// type the `inputValue` prop to this component and use generics is to omit
// that field:
export type NotificationsPreferencesInputs = Omit<
  SetNotificationPreferencesInput,
  'clientMutationId'
>;

export type NotificationPreferenceName = keyof NotificationsPreferencesInputs;

export type NotificationPreferenceProps<T extends NotificationPreferenceName> =
  {
    title: string;
    content: string;
    preferenceName: NotificationPreferenceName;
    inputValues: NotificationsPreferencesInputs[T];
    areCheckboxesDisabled: boolean;
    onChange: (
      preferenceName: NotificationPreferenceName,
      event: React.SyntheticEvent<HTMLInputElement>,
    ) => void;
  };

export const NotificationPreferenceRow = <
  T extends NotificationPreferenceName,
>({
  title,
  content,
  preferenceName,
  inputValues,
  areCheckboxesDisabled,
  onChange,
}: NotificationPreferenceProps<T>) => {
  if (isNil(inputValues)) {
    return null;
  }

  return (
    <Box mb={16}>
      <Flex as="fieldset">
        <Box>
          <legend>
            <PL content={title} />
          </legend>
          <PM mt={8} content={content} />
        </Box>

        <Flex
          alignItems="center"
          ml="auto"
          width={124}
          justifyContent="space-around"
        >
          <Box minWidth={18}>
            <Checkbox
              disabled={areCheckboxesDisabled}
              name="sendViaPush"
              size="medium"
              m={0}
              value={inputValues.sendViaPush ?? false}
              onChange={(event) => onChange(preferenceName, event)}
            />
          </Box>

          <Box minWidth={18}>
            <Checkbox
              disabled={areCheckboxesDisabled}
              name="sendViaEmail"
              size="medium"
              m={0}
              value={inputValues.sendViaEmail ?? false}
              onChange={(event) => onChange(preferenceName, event)}
            />
          </Box>
        </Flex>
      </Flex>
      <Divider spacing="relaxed" />
    </Box>
  );
};
