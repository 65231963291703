import { Box, Button, PL, PM } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

import { RadioChoice } from '~/components/form/Radio';
import { useWizardContext } from '~/flows/wizard';
import { PhoneNumberField } from '~/forms/fields/PhoneNumberField';
import { useInitiatePhoneVerificationMutation } from '~/graphql/hooks';
import type { InitiatePhoneVerificationInput } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { normalizePhoneNumber } from '~/utils';

type CollectPhoneVerificationFormProps = {
  onCompleted?: (data: any) => void;
  style?: Record<string, any>;
};

const isProduction = __NODE_ENV__ === 'production' && __ENV__ === 'production';

export const CollectPhoneVerificationForm = ({
  onCompleted,
  style,
}: CollectPhoneVerificationFormProps) => {
  const formMethods = useFormContext<InitiatePhoneVerificationInput>();

  const { addToast } = useToast();

  const { goTo } = useWizardContext();

  const [initiatePhoneVerification, { loading }] =
    useInitiatePhoneVerificationMutation();

  usePortaledSpinner(loading);

  const onSubmit = (input: InitiatePhoneVerificationInput) => {
    initiatePhoneVerification({
      variables: {
        input: {
          channel: input.channel,
          phone: normalizePhoneNumber(input.phone),
        },
      },
      onCompleted: (data) => {
        onCompleted?.(data);
        goTo('VERIFY_RECEIVED_CODE');
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form style={style} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <PhoneNumberField
          autoFocus
          backgroundColor="backgroundNeutralSecondary"
        />
        <PL fontWeight={600} content="Deliver code by:" mt={24} mb={4} />

        <Box mt={8}>
          <RadioChoice name="channel" label="Phone call" value="CALL" />
          <RadioChoice name="channel" label="Text" value="SMS" />
        </Box>
        <Box mt={64}>
          <Button
            kind="primary"
            label="Continue"
            size="large"
            type="submit"
            disabled={!formMethods.formState.isValid}
          />
          {!isProduction && (
            <PM
              color="primary"
              cursor="pointer"
              content="Mock this value!"
              onClick={() => {
                formMethods.setValue('phone', '6301234567', {
                  shouldValidate: true,
                });
              }}
              pt={8}
            />
          )}
        </Box>
      </form>
    </FormProvider>
  );
};
