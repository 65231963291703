import * as React from 'react';
import { type Path, useFormContext } from 'react-hook-form';

import { ControlledInput } from '../ControlledInput';

import type { AddressFormFields, AddressSectionProps } from './types';

const FIELD_NAME = 'country';

export const CountryInput = <NS extends string = ''>({
  backgroundColor,
  disabled,
  namespace,
}: AddressSectionProps<NS>) => {
  const { control } = useFormContext<AddressFormFields<NS>>();
  const name = (
    namespace ? `${namespace as NS}.${FIELD_NAME}` : FIELD_NAME
  ) as Path<AddressFormFields<NS>>;

  return (
    <ControlledInput
      {...{
        control,
        name,
        backgroundColor,
        disabled,
        type: 'hidden',
      }}
    />
  );
};
