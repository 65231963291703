import { TwoColumn4by8, Box } from '@m1/liquid-react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { AnnouncementContext } from '~/components/announcement';
import { CommonTransactionDetailsCard } from '~/components/CommonTransactionDetailsCard/CommonTransactionDetailsCard';
import { Footer } from '~/components/Footer';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useEarnProductLandingPageQuery } from '~/graphql/hooks';
import type { EarnProductLandingPageQuery } from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { useTransactionDetailsModal } from '~/hooks/useTransactionDetailsModal';
import {
  ShrinkContainer,
  StyledLandingPageContent,
} from '~/pages/dashboard/common/ProductLandingPage.styled';
import { MastHeader } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';
import type { AppState } from '~/redux';

import { EarnPaginatedTransactions } from '../common-transactions/EarnPaginatedTransactions';

import { EarnDetailsCard } from './EarnDetailsCard';
import { EarnLandingPageBanners } from './EarnLandingPageBanners';
import { EarnLandingPageHeader } from './EarnLandingPageHeader';
import { EarnReferralPromoCards } from './EarnReferralPromoCards';
import { EarnValuePropositionCard } from './EarnValuePropositionCard';

export const EarnLandingPage = () => {
  const { contentWidth } = useLayout();
  const accountId = useSelector(
    (state: AppState) => state.global.savingsAccountId,
  );
  const { data } = useEarnProductLandingPageQuery({
    variables: { accountId: accountId as string },
    skip: !accountId,
  });

  const { selectedTransactionId, onModalOpen, onModalClose, showModal } =
    useTransactionDetailsModal();

  if (!accountId) {
    return <GenericSystemError />;
  }

  const node = data?.node as ExtractTypename<
    EarnProductLandingPageQuery['node'],
    'SavingsAccount'
  >;

  const announcement = data?.viewer.announcements?.forSave;
  const disclosures = node?.earnProductLandingPage?.disclosures;

  return (
    <Box width="100%">
      <MastHeader />
      <StyledLandingPageContent contentWidth={contentWidth}>
        {announcement && (
          <AnnouncementContext
            announcement={announcement}
            context="SAVE"
            mt={32}
          />
        )}

        <EarnLandingPageHeader
          accountId={accountId}
          hasAnnouncement={Boolean(announcement)}
        />
        <EarnLandingPageBanners accountId={accountId} />
        <TwoColumn4by8>
          <TwoColumn4by8.Column1>
            <CommonTransactionDetailsCard
              onClose={onModalClose}
              transactionId={selectedTransactionId}
              showModal={showModal}
            />

            <ShrinkContainer shouldShrink={showModal}>
              <EarnValuePropositionCard accountId={accountId} />
              <EarnReferralPromoCards />
              <EarnDetailsCard
                accountId={accountId}
                selectedTransactionId={selectedTransactionId}
              />
            </ShrinkContainer>
          </TwoColumn4by8.Column1>
          <TwoColumn4by8.Column2>
            <EarnPaginatedTransactions
              accountId={accountId}
              handleDetailsModalOpen={onModalOpen}
              selectedTransactionId={
                showModal && selectedTransactionId
                  ? selectedTransactionId
                  : null
              }
            />
          </TwoColumn4by8.Column2>
        </TwoColumn4by8>
      </StyledLandingPageContent>
      <Footer footerDisclosures={disclosures} />
    </Box>
  );
};
