import clamp from 'lodash-es/clamp';
import * as React from 'react';

import { SingleSelectButtonInput } from '~/components/SingleSelectButtonInput';
import type {
  SavingsDueDiligenceQuestion,
  SavingsCustomerDueDiligenceAnswer,
} from '~/graphql/types';
import { useSearchParams } from '~/hooks/useSearchParams';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { isNotNil } from '~/utils';

import { readQuestionQueryParam } from '../readQuestionQueryParam';

type SavingsDueDiligenceProps = {
  onFinishStep: (answer: {
    questionId: string | null | undefined;
    selectedAnswers: SavingsCustomerDueDiligenceAnswer[];
  }) => void;
};

type NonNullAnswer = PickNonNullable<
  SavingsCustomerDueDiligenceAnswer,
  'answerId' | 'answerLabel'
>;

const isNonNullAnswer = (
  answer: SavingsCustomerDueDiligenceAnswer | null | undefined,
): answer is NonNullAnswer => {
  return (
    isNotNil(answer) &&
    isNotNil(answer.answerId) &&
    isNotNil(answer.answerLabel)
  );
};

export const SavingsDueDiligence = ({
  onFinishStep,
}: SavingsDueDiligenceProps) => {
  // Selectors
  const questions = useSelector<
    ReadonlyArray<SavingsDueDiligenceQuestion> | null | undefined
  >(
    (state: AppState) =>
      state.newFlows.SAVINGS_ONBOARDING.dueDiligenceQuestions,
  );
  const [searchParams] = useSearchParams();
  const currentDDQuestion = readQuestionQueryParam({
    searchParams,
  });

  const question: SavingsDueDiligenceQuestion | null | undefined =
    questions?.[clamp(currentDDQuestion ?? 0, 0, questions.length)];

  // handler
  const handleClick = React.useCallback(
    (answer: NonNullAnswer) => {
      onFinishStep({
        questionId: question?.questionId,
        selectedAnswers: [answer],
      });
    },
    [onFinishStep, question],
  );

  if (
    !questions ||
    !question ||
    !question.questionId ||
    !question.questionLabel ||
    !question.answers
  ) {
    return null;
  }

  return (
    <SingleSelectButtonInput
      showBackButton
      field={question.questionId}
      getOptionKey={(answer) => answer.answerId}
      question={question.questionLabel}
      options={question.answers.filter(isNonNullAnswer).map((answer) => ({
        description: answer.answerLabel,
        name: answer,
      }))}
      onClick={handleClick}
    />
  );
};
