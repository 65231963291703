import { Box, useTheme, Flex } from '@m1/liquid-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BackArrow } from '~/components/BackArrow';
import { Sticky } from '~/components/sticky';
import { useSliceableContentQuery } from '~/graphql/hooks';
import type { SliceableContentQuery } from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { useDispatch } from '~/redux/hooks';

import {
  HeaderScrollBackground,
  SliceName,
  StaticHeader,
} from '../../invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';

import { CondensableButton } from './PieEditor.styled';
import type { TreeHistory, TreeItems } from './PieEditor.types';
import { PieEditorOptions } from './PieEditorOptions';
import { PieEditorSubmit } from './PieEditorSubmit';
import { PieEditorUndoHistory } from './PieEditorUndoHistory';

export const PieEditorHeader = ({
  id,
  name,
  history,
  historyIndex,
  onTogglePieVisual,
}: {
  id: Maybe<string>;
  items: TreeItems;
  name: Maybe<string>;
  history: TreeHistory['trees'];
  historyIndex: number;
  onTogglePieVisual: () => void;
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const historyLength = history.length;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data } = useSliceableContentQuery({
    variables: {
      sliceableId: id as string,
    },
    skip: Boolean(name),
  });
  const pieRoot = data?.node as Maybe<
    ExtractTypenameByKey<SliceableContentQuery['node'], 'name'>
  >;
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.XXSMALL})`,
  });
  const { scrollTop, topBarHeight } = useLayout();

  const onIndexChange = (change: number, isReset?: boolean) => {
    dispatch({
      type: 'PIE_EDITOR_UPDATE_HISTORY_INDEX',
      payload: {
        index: historyIndex + change,
        isReset,
      },
    });
  };

  return (
    <>
      <Sticky zIndex={2}>
        <Box>
          <StaticHeader
            forwardedAs="header"
            flexWrap={isMobile ? 'wrap' : undefined}
            $responsive={false}
            margin="unset !important"
          >
            <HeaderScrollBackground
              $percentTopBarScrolled={Math.min(
                1,
                Math.pow(scrollTop / topBarHeight, 2),
              )}
            />
            <SliceName
              fontWeight={300}
              color="foregroundSecondary"
              flexBasis={isMobile ? '100%' : undefined}
              mb={isMobile ? 16 : undefined}
            >
              {name ?? pieRoot?.name}
            </SliceName>
            <PieEditorSubmit />
            <HeaderScrollBackground
              $percentTopBarScrolled={Math.min(
                1,
                Math.pow(scrollTop / topBarHeight, 2),
              )}
            />
          </StaticHeader>
        </Box>
      </Sticky>
      {/* Subheader, not sticky */}
      <Flex width="100%" mt={32}>
        <Box flex="1">
          <BackArrow
            content="Back"
            onClick={() =>
              navigate({
                to:
                  searchParams.get('previousRouteName') ??
                  '/d/research/my-pies',
              })
            }
          />
        </Box>
        <Flex gap={8}>
          <PieEditorUndoHistory onIndexChange={onIndexChange} />
          <CondensableButton
            // @ts-ignore title works but not typed
            title="Undo"
            label=""
            leftIcon="undo20"
            size="medium"
            kind="text"
            disabled={historyIndex === 0}
            onClick={() => onIndexChange(-1)}
            condensed
          />
          <CondensableButton
            // @ts-ignore title works but not typed
            title="Redo"
            label=""
            leftIcon="redo20"
            size="medium"
            kind="text"
            disabled={historyIndex === historyLength - 1}
            onClick={() => onIndexChange(1)}
            condensed
          />
          <CondensableButton
            // @ts-ignore title works but not typed
            title="Reset"
            label=""
            leftIcon="reset20"
            size="medium"
            kind="text"
            disabled={historyLength <= 1}
            onClick={() => onIndexChange(0, true)}
            condensed
          />
          <PieEditorOptions onTogglePieVisual={onTogglePieVisual} />
        </Flex>
      </Flex>
    </>
  );
};
