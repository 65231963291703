import castArray from 'lodash-es/castArray';
import isNil from 'lodash-es/isNil';
import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { UpdateUserDataDocument } from '~/graphql/hooks';
import type { UpdateUserDataInput } from '~/graphql/types';

import { apolloMutationSaga } from '../apolloMutationSaga';

const GLOBAL_KEYS = [
  'watchlist',
  'm1_cc_app_started',
  'approvedCreditCardApplicationIds',
  'MOVE_VALUE_LATEST_CLICK',
  'HAS_MOVED_VALUE',
];

type UserDataEntry = {
  key: string;
  value: any;
};
export function* updateUserData(
  payload: UserDataEntry | Array<UserDataEntry>,
): SagaIterator {
  try {
    const arrayOfEntries = castArray(payload);
    const entries = arrayOfEntries.map((entry) => {
      const key = GLOBAL_KEYS.includes(entry.key)
        ? entry.key
        : `web-${entry.key}`;
      const value = !isNil(entry.value) ? entry.value : null;

      return {
        key,
        value,
      };
    });
    yield call(apolloMutationSaga, {
      mutation: UpdateUserDataDocument,
      variables: {
        input: {
          entries,
        } satisfies UpdateUserDataInput,
      },
    });
  } catch (e: any) {
    return null;
  }
}
