import { PL, Flex, HXXS, PM } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { usePaymentsPageQuery } from '~/graphql/hooks';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

export const SettingsPaymentsSection = () => {
  const { loading, data, error } = usePaymentsPageQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const viewer = data?.viewer;

  if ((error && !data) || !viewer) {
    return <GenericSystemError />;
  }

  const { user } = viewer;

  return (
    <Flex flexDirection="column" width="100%" maxWidth={600}>
      <HXXS content="Invest referral preferences" fontWeight={300} mb={8} />
      <PM fontWeight={400}>
        If you receive a referral payout, your funds will go here.
      </PM>
      <Flex
        alignItems="center"
        borderColor="borderMain"
        borderRadius={8}
        borderStyle="solid"
        borderWidth={1}
        justifyContent="space-between"
        mt={40}
        mb={56}
        py={16}
        px={20}
      >
        <PL>
          {user?.billing?.selectedPaymentsAccount
            ? user.billing.selectedPaymentsAccount.account.name
            : 'No default account selected'}
        </PL>
        <Link to="/d/c/select-payment-account">Change</Link>
      </Flex>
    </Flex>
  );
};
