import type {
  ControlPersonDisclosure,
  ExchangeOrFinraAffiliationDisclosure,
  PoliticalExposureDisclosure,
} from '~/graphql/types';

export type FinancialDisclosuresFormValues = {
  isSubjectToBackupWithholding: boolean | null | undefined;
  exchangeOrFinraAffiliationDisclosure: ExchangeOrFinraAffiliationDisclosure;
  controlPersonDisclosure: ControlPersonDisclosure | null | undefined;
  politicalExposureDisclosure: PoliticalExposureDisclosure | null | undefined;
  noDisclosuresValue: boolean | null | undefined;
};

export const financialDisclosuresFormDefaultValues: FinancialDisclosuresFormValues =
  {
    noDisclosuresValue: true,
    controlPersonDisclosure: {
      isControlPerson: false,
      companySymbols: null,
    },
    exchangeOrFinraAffiliationDisclosure: {
      isAffiliated: false,
      firmName: null,
    },
    isSubjectToBackupWithholding: false,
    politicalExposureDisclosure: {
      isPoliticallyExposed: false,
      politicalOrganization: null,
      immediateFamilyMembers: null,
    },
  };
