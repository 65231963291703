import * as React from 'react';
import { type FieldValues, useForm, type UseFormProps } from 'react-hook-form';

export const useMultiStepForm = <T extends FieldValues, K extends string>(
  steps: Record<K, React.ReactElement>,

  formProps: UseFormProps<T>,
) => {
  const formMethods = useForm<T>(formProps);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [previousStepIndex, setPreviousStepIndex] = React.useState(0);

  const stepKeys = Object.keys(steps) as K[];
  const stepKey = stepKeys[currentStepIndex];
  const step = steps[stepKey];
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === stepKeys.length - 1;

  const handleNext = () => {
    setPreviousStepIndex(currentStepIndex);
    setCurrentStepIndex((prevIndex) => prevIndex + 1);
  };

  const handleBack = () => {
    setCurrentStepIndex((currStepIndex) =>
      previousStepIndex >= currStepIndex
        ? currStepIndex - 1
        : previousStepIndex,
    );
  };

  const handleGoTo = (stepKey: K) => {
    const stepIndex = stepKeys.indexOf(stepKey);
    setCurrentStepIndex(stepIndex);
  };

  return {
    back: handleBack,
    next: handleNext,
    step,
    stepKeys,
    stepKey,
    currentStepIndex,
    goTo: handleGoTo,
    isFirstStep,
    isLastStep,
    steps,
    ...formMethods,
  };
};
