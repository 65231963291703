import { TwoColumn3by9 } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { Navigation } from '~/toolbox/navigation';

import { AnnouncementsForDocuments } from './components/AnnouncementsForDocuments';
import { routes } from './routes';

const navItems = routes.map(({ label, path }) => ({
  to: `/d/settings/documents/${path}`,
  label,
}));

export const DocumentsContainer = () => {
  return (
    <TwoColumn3by9 pb={64}>
      <TwoColumn3by9.Column1>
        <Navigation orientation="vertical" items={navItems} />
      </TwoColumn3by9.Column1>
      <TwoColumn3by9.Column2>
        <AnnouncementsForDocuments />
        <Outlet />
      </TwoColumn3by9.Column2>
    </TwoColumn3by9>
  );
};
