import { Card, Flex, PM, PS } from '@m1/liquid-react';
import React from 'react';

import { isNotNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

export const PaymentPendingCard = ({
  amount,
  timingDescription,
}: {
  amount: number | null;
  timingDescription: string | null;
}) => {
  return (
    <Card mt={32} p={12}>
      <Flex justifyContent="space-between" alignItems="center">
        <PM fontWeight={600} content="Pending Payment" />
        {isNotNil(amount) && <PM content={formatCurrency(amount)} />}
      </Flex>
      {isNotNil(timingDescription) && (
        <PS color="foregroundNeutralMain" content={timingDescription} mt={8} />
      )}
    </Card>
  );
};
