import type { PortfolioEditorSagaFetchSliceablesQuery } from '~/graphql/types';
import { mapRemoteSliceableByType, type Slice } from '~/pie-trees';

/**
 * Maps the response data from FetchSliceablesQuery to an array of Slices
 * @param {*} response - The response to map
 * @returns - The mapped array of slices
 */
export const mapFetchSliceablesResponseToSlices = (
  response: PortfolioEditorSagaFetchSliceablesQuery | null | undefined,
): Array<Slice> => {
  const nodes = [];

  if (response?.nodes) {
    for (const node of response.nodes) {
      // handle sliceables
      if (
        node?.__typename &&
        'id' in node &&
        'name' in node &&
        'type' in node
      ) {
        nodes.push({
          ...node,
          __typename: node.__typename,
          id: node.id,
          name: node.name,
          type: node.type,
          slices: 'slices' in node ? node.slices : null,
        });
      }
    }
  }

  return nodes.reduce<any>((slices, sliceable) => {
    if (sliceable) {
      slices.push({
        percentage: 1,
        to: mapRemoteSliceableByType(sliceable as any),
      });
    }

    return slices;
  }, []);
};
