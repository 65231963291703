import type { ComponentProps } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as RouterLink } from 'react-router-dom';

import { buildPath } from '~/router/utils/buildPath';

import type { LinkProps } from './Link.types';

export const hasRouterLinkProps = (props: ComponentProps<any>): boolean => {
  return Object.keys(props).some((prop) => ['to'].includes(prop));
};

/**
 * Tries to create a URL instance from the specified url string. If the url
 * string is malformed, returns false.
 *
 * @param url URL string to pass to URL constructor.
 * @returns A boolean indicating if the url string is valid.
 */
const isValidUrl = (url: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

/**
 * Runs increasingly elaborate checks on the specified `to` path to determine if
 * it's an internal URL that should go through our app routing or an external
 * URL that should be handled via an anchor element. Since trying to navigate
 * to an invalid internal URL will throw a runtime error, and trying to navigate
 * to an invalid external URL will just 404, we'd rather accidentally identify
 * an internal URL as an invalid external URL than vice versa.
 *
 * @param to URL to check if external or internal.
 * @param [params] Optional params present in the URL.
 * @param [query] Optional search query to append to the path.
 * @returns An internal path if the specified path is internal, otherwise null.
 */
export const getInternalUrl = ({
  to,
  params,
  query,
}: Pick<LinkProps, 'params' | 'query'> & { to: string | undefined }):
  | string
  | null => {
  if (!to) {
    return null;
  }

  // If the URL starts with any of the common URL protocols, we know right away
  // it must be external, so we return null:
  if (/^(ftp|http|mailto|sms|tel)/i.test(to)) {
    return null;
  }

  /*
   * If the protocol is a typo (e.g. htps, maillto, etc.), we try to create
   * a URL instance from the string. If the URL is completely invalid, trying
   * to create a URL with throw an error, so we continue onto the next check.
   * But calling `new URL('htps://stuff.com')` _will_ return a valid URL instance,
   * since it's just parsing the value, not checking against a protocol. If the
   * URL can be created, it's _not_ an internal URL, so we return null:
   */
  if (isValidUrl(to)) {
    return null;
  }

  // At this point, we're pretty sure the path is internal, but to avoid a runtime
  // error, we try to build the path. If the path can't get built using the specified
  // URL, params, and query, we want it to 404:
  try {
    return buildPath(to, query, params);
  } catch {
    return null;
  }
};

export const formatInternalLinkProps = ({
  internalUrl,
  state,
  query,
  target,
}: {
  internalUrl: string;
  state: LinkProps['state'];
  query: LinkProps['query'];
  target: LinkProps['target'];
}) => {
  const [pathname, predefinedQuery] = internalUrl.split('?');
  const search = query
    ? new URLSearchParams(query as Record<string, string>).toString()
    : predefinedQuery;

  return {
    forwardedAs: RouterLink,
    to: {
      pathname,
      search,
    },
    state,
    target,
  };
};
