import { styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { VideoTutorialsViewAllFragment } from '~/graphql/types';

const StyledAnchor = styled.a`
  display: inline-flex;
  align-items: center;
  padding-bottom: 32px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
`;

type ViewAllSectionProps = {
  viewAll: VideoTutorialsViewAllFragment;
};

export const ViewAllSection = ({ viewAll }: ViewAllSectionProps) => {
  return (
    <>
      {viewAll.destination && viewAll.label && (
        <StyledAnchor href={viewAll.destination} target="_blank">
          <Icon name="arrowLeft24" color="primary" />
          {viewAll.label}
        </StyledAnchor>
      )}
    </>
  );
};
