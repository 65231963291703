import { Flex } from '@m1/liquid-react';
import React from 'react';

import {
  GetTransferRuleDocument,
  useSetEnabledOnTransferRuleMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useToast } from '~/toasts';
import { Switch } from '~/toolbox/switch';

import { SwitchLabel } from './SwitchLabel';

export const ScheduleSwitch = ({
  isRuleEnabled,
  transferRuleId,
  isAutoPay,
  isPersonalLoanAutoPay,
}: {
  isAutoPay: boolean;
  isRuleEnabled: boolean;
  transferRuleId: string;
  isPersonalLoanAutoPay: boolean;
}) => {
  const analytics = useAnalytics();
  const { addToast } = useToast();

  const [setEnabledOnTransferRule, { loading }] =
    useSetEnabledOnTransferRuleMutation();

  const handleSwitchChange = React.useCallback(
    (isSwitchOn: boolean) => {
      setEnabledOnTransferRule({
        variables: {
          input: {
            /*
             * If the rule is an AutoPay rule, we want to enable the rule when the switch is on
             * If the rule is a recurring transfer schedule, we pause the rule when the switch is on
             */
            isEnabled: isAutoPay ? isSwitchOn : !isSwitchOn,
            transferRuleId,
          },
        },
        refetchQueries: [
          {
            query: GetTransferRuleDocument,
            variables: {
              id: transferRuleId,
            },
          },
        ],
        onCompleted: (data) => {
          if (isPersonalLoanAutoPay) {
            const isSwitchOn =
              data.setEnabledOnTransferRule.outcome?.rule?.isEnabled === true;
            const analyticsEvent = isSwitchOn
              ? 'm1_personal_loan_autopay_toggled_on'
              : 'm1_personal_loan_autopay_toggled_off';
            analytics.recordEvent(analyticsEvent);
          }
        },
        onError: () => {
          addToast({
            content: 'An error occurred. Please try again.',
            kind: 'alert',
            duration: 'short',
          });
        },
      });
    },
    [
      addToast,
      analytics,
      isPersonalLoanAutoPay,
      setEnabledOnTransferRule,
      transferRuleId,
      isAutoPay,
    ],
  );

  /*
   * If viewing an AutoPay schedule, an enabled switch means AutoPay is ON.
   * If viewing a recurring transfer schedule, an enabled switch means the schedule is paused.
   */
  const isSwitchOn = isAutoPay ? isRuleEnabled : !isRuleEnabled;

  return (
    <Flex alignItems="center" justifyContent="flex-start" mt={64}>
      <Switch
        checked={isSwitchOn}
        disabled={loading}
        label={<SwitchLabel isSwitchOn={isSwitchOn} isAutoPay={isAutoPay} />}
        onChange={handleSwitchChange}
      />
    </Flex>
  );
};
