import type { NavigationQueryResult } from '~/graphql/hooks';
import { isNotNil } from '~/utils';

export type AccountEdges = NonNullable<
  NonNullable<
    NonNullable<NavigationQueryResult['data']>['viewer']['accounts']
  >['edges']
>;

export type InvestAccount = NonNullable<
  NonNullable<AccountEdges[number]>['node']
>;

/**
 * Maps the accounts we get back on the IANavigationQuery to a local type thats easier to deal with.
 * @param {*} accountEdges - the account edges we get back.
 * @returns -
 */
export const getInvestAccounts = (
  accountEdges: AccountEdges | null | undefined,
): Array<InvestAccount> => {
  return (accountEdges ?? []).reduce<InvestAccount[]>((acc, account) => {
    const node = account?.node;
    if (isNotNil(node)) {
      acc.push(node);
    }
    return acc;
  }, []);
};
