import React from 'react';

import { Table } from '~/toolbox/table';
import type { TableHeaderCellProps } from '~/toolbox/table/header-cell';

type WatchlistTableHeaderProps = {
  headerKey: string;
  label: string;
  handleTableHeaderClick: (key: string) => void;
  shouldSort: TableHeaderCellProps['sort'];
  [key: string]: any;
};

export const WatchlistTableHeader = ({
  handleTableHeaderClick,
  label,
  headerKey,
  shouldSort,
  ...props
}: WatchlistTableHeaderProps) => (
  <Table.HeaderCell
    sort={shouldSort}
    onClick={() => handleTableHeaderClick(headerKey)}
    {...props}
  >
    {label}
  </Table.HeaderCell>
);
