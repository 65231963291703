import * as React from 'react';

import { CommonTransactionAmountCell } from '~/components/CommonTransactionsTable/CommonTransactionAmountCell';
import { CommonTransactionDescriptionCell } from '~/components/CommonTransactionsTable/CommonTransactionDescriptionCell';
import { CommonTransactionValueCell } from '~/components/CommonTransactionsTable/CommonTransactionValueCell';
import type { CommonTransaction } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export const EarnCommonTransactionRow = ({
  transaction,
  handleClick,
  isHighlighted,
}: {
  transaction: CommonTransaction;
  handleClick: (transactionId: string) => void;
  isHighlighted: boolean;
}) => {
  return (
    <GridTable.Row
      isHighlighted={isHighlighted}
      usesCaretLeftIndicator
      onClick={() => handleClick(transaction?.id)}
      className="common-transaction-table-row"
    >
      <GridTable.Cell
        content={
          <CommonTransactionDescriptionCell
            isHighlighted={isHighlighted}
            logoUrl={transaction?.logoUrl}
            icon={transaction?.icon}
            content={transaction?.title}
            logoStyles={{ width: '24px', height: '24px' }}
          />
        }
      />
      <GridTable.Cell
        content={<CommonTransactionValueCell content={transaction?.subtitle} />}
        textAlign="left"
      />
      <GridTable.Cell
        className="stickyColumn"
        content={
          <CommonTransactionAmountCell
            pill={transaction?.pill}
            amount={transaction?.amount}
            strikeThrough={
              transaction?.status === 'CANCELED' ||
              transaction?.status === 'FAILED'
            }
          />
        }
      />
    </GridTable.Row>
  );
};
