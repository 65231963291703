import find from 'lodash-es/find';
import get from 'lodash-es/get';

import type { AppState } from '~/redux';

export const hasActiveErrorMessageAtLocation = (
  state: AppState,
  errorLocation: string,
): boolean =>
  Boolean(find(get(state, 'notifications', []), ['location', errorLocation]));
