import { Box, Flex, HL, PL, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { ExcludedBuysTable } from '~/components/invest/ExcludedBuysTables';
import { useExcludedBuysPageQuery } from '~/graphql/hooks';
import { useParams } from '~/hooks/useParams';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { formatCurrency } from '~/utils/formatting';

const StyledHeaderContainer = styled(Container)`
  height: 100%;
  padding-top: 32px;
  padding-bottom: 16px;
`;

const StyledDescriptionText = styled(PL)`
  display: block;
  margin: 16px 0;
`;
export const ExcludedBuysPage = () => {
  const { portfolioSliceId } = useParams();
  const accountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading } = useExcludedBuysPageQuery({
    variables: {
      accountId: accountId as string,
    },
    skip: !accountId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!accountId) {
    return null;
  }

  const linkParams = portfolioSliceId
    ? ({
        to: '/d/invest/portfolio/:portfolioSliceId/trading',
        params: {
          portfolioSliceId,
        },
      } as const)
    : ({
        to: '/d/invest/portfolio/trading',
      } as const);

  const minimumBuyTradeAmount =
    data?.account?.__typename === 'Account'
      ? data.account.minimumBuyTradeAmount
      : null;

  return (
    <Box maxHeight={300}>
      <StyledHeaderContainer>
        <Link {...linkParams}>
          <Flex>
            <Icon name="arrowLeft24" />
            <PL
              fontWeight={600}
              content="Upcoming trades"
              color="primary"
              style={{
                marginLeft: 8,
              }}
            />
          </Flex>
        </Link>
        <HL content="Excluded trades" />
        {typeof minimumBuyTradeAmount === 'number' && (
          <StyledDescriptionText
            content={`Any estimated buys that are less than the ${formatCurrency(
              minimumBuyTradeAmount,
            )} minimum will be excluded from trading.`}
          />
        )}
        <ExternalLink destination="SUPPORT_EXCLUDED_BUYS" label="Learn more" />
      </StyledHeaderContainer>
      <Container>
        <ExcludedBuysTable accountId={accountId} />
      </Container>
    </Box>
  );
};
