import { Box, Button, Flex, HL, HM, PL, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { useNavigate } from '~/hooks/useNavigate';
import type { LinkProps } from '~/toolbox/link';
import { List } from '~/toolbox/list';

const StyledListContainer = styled(List)`
  list-style: none;
  list-style-position: inside;
  margin-top: 16px;
`;

const StyledListItem = styled(List.Item)`
  display: flex;
  margin-left: 0;
  margin-top: 16px;
`;

type CryptoContentProps = {
  onLearnMoreClick: LinkProps['onClick'];
  isEligible: boolean;
};

export const CryptoContent = ({
  onLearnMoreClick,
  isEligible,
}: CryptoContentProps) => {
  const navigate = useNavigate();

  if (isEligible) {
    return (
      <>
        <HL>Unlock crypto on M1 today.</HL>
        <HM mt={32}>You’ll first need to fund your Brokerage Account.</HM>
        <Flex justifyContent="flex-start">
          <StyledListContainer>
            {[
              'Deposit money into your existing Brokerage Account.',
              'Open a Crypto Account.',
              'Start investing with custom crypto Pies.',
            ].map((item) => (
              <StyledListItem key={item}>
                <Icon name="check24" />
                <PL ml={4} content={item} />
              </StyledListItem>
            ))}
          </StyledListContainer>
        </Flex>
        <Button
          kind="link"
          style={{
            marginTop: '32px',
            justifyContent: 'flex-start',
          }}
          label="Learn more about M1 Crypto"
          onClick={onLearnMoreClick}
        />
        <Box width={343}>
          <Button
            mt={32}
            size="large"
            label="Fund your existing account"
            onClick={() => {
              navigate({ to: '/d/invest/portfolio' });
            }}
            fullWidth
          />
        </Box>
      </>
    );
  }
  return (
    <>
      <HL>Congrats.</HL>
      <HL my={16}>You now have access to crypto on M1.</HL>
      <Flex justifyContent="flex-start">
        <PL content="Start investing by opening a Crypto Account. Choose from popular coins, and try out additional trading options." />
      </Flex>
      <Button
        kind="link"
        style={{
          marginTop: '32px',
          justifyContent: 'flex-start',
        }}
        label="Learn more about M1 Crypto"
        onClick={onLearnMoreClick}
      />
      <Box width={343}>
        <Button
          mt={32}
          size="large"
          label="Open a Crypto Account"
          onClick={() => {
            navigate({
              to: '/d/open-invest-account',
              query: { defaultAccountType: 'CRYPTO' },
            });
          }}
          fullWidth
        />
      </Box>
    </>
  );
};
