import { Box, css, LS, styled, type Theme } from '@m1/liquid-react';
import React from 'react';
import { components } from 'react-select';

import type { SelectProps } from '~/toolbox/Dropdown';
import { isNotNil } from '~/utils';

export const StyledLabel = styled(LS)<
  Pick<SelectProps, 'error'> & {
    appTheme: Theme;
    backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
    isLabelForDestination?: boolean;
    isDisabled?: boolean;
    isFocused?: boolean;
  }
>`
  ${({ isLabelForDestination }) =>
    isLabelForDestination === true
      ? css`
          left: 1px;
          padding-left: 16px;
          min-width: 64px;
        `
      : css`
          left: 12px;
          padding: 0 4px;
        `}
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.backgroundNeutralSecondary};
  line-height: 1.5rem;
  position: absolute;
  top: 1px;
  z-index: 1;
  font-weight: ${({ error }) => isNotNil(error) && 600};
  color: ${({ error, isDisabled, isFocused, theme }) => {
    if (isDisabled === true) {
      return theme.colors.foregroundNeutralTertiary;
    }
    if (isNotNil(error)) {
      return theme.colors.critical;
    }
    if (isFocused === true) {
      return theme.colors.primary;
    }
    return theme.colors.foregroundNeutralSecondary;
  }};
`;

export const TransferParticipantSelectContainer = (props: any) => {
  return (
    <components.SelectContainer {...props}>
      <Box position="relative" pt={8}>
        {isNotNil(props.selectProps.label) && (
          <StyledLabel
            htmlFor={props.selectProps.name}
            appTheme={props.selectProps.appTheme}
            backgroundColor={props.selectProps.backgroundColor}
            error={props.selectProps.error}
            isLabelForDestination={props.selectProps.name === 'destinationId'}
            isDisabled={props.selectProps.isDisabled}
            isFocused={props.selectProps.isFocused}
          >
            {props.selectProps.label}
          </StyledLabel>
        )}
        {props.children}
      </Box>
    </components.SelectContainer>
  );
};
