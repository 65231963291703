import * as React from 'react';

import { CollectProfileInput } from '~/components/form/Inputs/CollectProfileInput';
import { getEnumEntries } from '~/utils';

export const CollectTotalNetWorthStep = () => {
  return (
    <CollectProfileInput
      field="suitability.totalNetWorth"
      question="What’s your net worth?"
      tooltip={{
        text: [
          'This is the total value of everything you own (cash, investments, property, cars, etc.), minus everything you owe (mortgages, credit cards, student loans, etc.).',
        ],
      }}
      options={getEnumEntries('TotalNetWorthEnum')}
    />
  );
};
