import * as React from 'react';

import type { SavingsAccountSettingsFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

import { RenameableAccountCell } from './RenameableAccountCell';
import type { RenameableRowProps } from './types';

type SavingsAccountsRowsProps = {
  savingsAccount:
    | SavingsAccountSettingsFragment['savingsAccounts']
    | null
    | undefined;
} & RenameableRowProps;

export const SavingsAccountsRows = ({
  savingsAccount,
  activeRow,
  hoverRow,
  setActiveRow,
  setHoverRow,
}: SavingsAccountsRowsProps) => (
  <>
    {(savingsAccount?.edges ?? []).map((edge) =>
      edge?.node ? (
        <GridTable.Row
          onMouseEnter={() => setHoverRow(edge.node?.id)}
          onMouseLeave={() => setHoverRow(null)}
          key={edge.node?.id}
        >
          <RenameableAccountCell
            showEdit={hoverRow === edge.node.id}
            accountId={edge.node.id}
            initialAccountName={edge.node.name}
            isActive={activeRow === edge.node.id}
            onEditClick={(accountId: string) => setActiveRow(accountId)}
          />
          <GridTable.Cell content={edge.node.accountNumber ?? '--'} />
          <GridTable.Cell
            content={
              edge.node.accountType === 'CASH' ? 'Cash Account' : 'Savings'
            }
          />
          <GridTable.Cell content={edge.node.status?.description} />
        </GridTable.Row>
      ) : null,
    )}
  </>
);
