import { Flex, HXXS, PM } from '@m1/liquid-react';
import { OpenInNew20 } from '@m1/liquid-react/icons';
import React from 'react';

import { useDisclosuresAndAgreementsPageQuery } from '~/graphql/hooks';
import { Link } from '~/toolbox/link/Link';
import { Spinner } from '~/toolbox/spinner';

import { DisclosuresList } from './DisclosuresList';
import { DisclosuresSection } from './DisclosuresSection';

export const DisclosuresAndAgreementsPage = () => {
  const { loading, data } = useDisclosuresAndAgreementsPageQuery({});

  if (loading) {
    return <Spinner />;
  }

  const viewer = data?.viewer;
  const hysaAgreements =
    viewer?.settings?.disclosuresAndAgreements?.hysaAgreements;
  const cashAccountAgreements =
    viewer?.settings?.disclosuresAndAgreements?.cashAccountAgreements;
  const promotionalAgreements =
    viewer?.settings?.disclosuresAndAgreements?.promotionalAgreements;
  const cryptoAgreements =
    viewer?.settings?.disclosuresAndAgreements?.cryptoAgreements;

  const latestCreditCardAgreements =
    viewer?.credit?.userAcceptedCreditCardAgreements
      ?.onboardingCreditCardAgreements;

  return (
    <Flex flexDirection="column">
      <HXXS content="Disclosures and Agreements" fontWeight={300} />
      <DisclosuresSection header="M1 Invest Disclosures">
        <Link to="https://m1.com/legal/disclosures/">
          <PM content="View Disclosures" fontWeight={600} mr={4} />
          <OpenInNew20 />
        </Link>
      </DisclosuresSection>
      <DisclosuresSection header="M1 Invest Agreements">
        <Link to="https://m1.com/legal/agreements/">
          <PM content="View Agreements" fontWeight={600} mr={4} />
          <OpenInNew20 />
        </Link>
      </DisclosuresSection>
      {hysaAgreements && (
        <DisclosuresSection header="M1 High Yield Savings Account">
          <DisclosuresList
            emptyMessage="Savings disclosures are currently unavailable. Please try again later."
            latestAgreements={hysaAgreements}
          />
        </DisclosuresSection>
      )}
      {cashAccountAgreements && (
        <DisclosuresSection header="M1 Cash Account">
          <DisclosuresList
            emptyMessage="Cash disclosures are currently unavailable. Please try again later."
            latestAgreements={cashAccountAgreements}
          />
        </DisclosuresSection>
      )}
      {cryptoAgreements && (
        <DisclosuresSection header="Crypto Account">
          <DisclosuresList
            emptyMessage="Crypto disclosures are currently unavailable. Please try again later."
            latestAgreements={cryptoAgreements}
          />
        </DisclosuresSection>
      )}
      {latestCreditCardAgreements && (
        <DisclosuresSection header="M1 Credit Card">
          <DisclosuresList
            emptyMessage="Spend Credit Card disclosures are currently unavailable. Please try again later."
            latestAgreements={latestCreditCardAgreements}
          />
        </DisclosuresSection>
      )}
      {promotionalAgreements &&
        promotionalAgreements.documents.length !== 0 && (
          <DisclosuresSection header="Promotions">
            <DisclosuresList
              emptyMessage="Promotions disclosures are currently unavailable. Please try again later."
              latestAgreements={promotionalAgreements}
            />
          </DisclosuresSection>
        )}
    </Flex>
  );
};
