import * as React from 'react';

import type { WizardTransferParticipantFragment } from '~/graphql/types';

import { TransferParticipantDropdownOption } from './TransferParticipantDropdownOption';

export const getParticipantGroupLabel = (
  participantType: WizardTransferParticipantFragment['transferParticipantType'],
) =>
  participantType !== 'EXTERNAL' &&
  participantType !== 'FUNDING_SOURCE' &&
  participantType !== 'CRYPTO_EXTERNAL'
    ? 'M1 accounts'
    : 'External accounts';

export const participantToDropdownOption = (
  participant: WizardTransferParticipantFragment,
) => ({
  name: participant.id,
  description: <TransferParticipantDropdownOption participant={participant} />,
});

export type DropdownOption = {
  name: string;
  description: React.ReactNode | string;
};

export type DropdownOptionGroup = {
  label: string;
  options: DropdownOption[];
};

type DropdownOptionGroups = Record<string, DropdownOptionGroup>;

export const participantsToDropdownOptions = (
  participants: WizardTransferParticipantFragment[],
) =>
  participants.reduce((groups, participant) => {
    const groupName = getParticipantGroupLabel(
      participant.transferParticipantType,
    );
    return {
      ...groups,
      [groupName]: {
        label: groupName,
        options: [
          ...(groups[groupName]?.options ?? []),
          participantToDropdownOption(participant),
        ],
      },
    };
  }, {} as DropdownOptionGroups);

// Need to sort values so that "External Accounts" are first. This is required because there
// is no guaranteed order to Object.values resolves the order from keys.
// (see https://stackoverflow.com/a/5525820 for more info)
export const sortDropdownOptions = (options: DropdownOptionGroups) =>
  Object.values(options).sort(
    (a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0),
  );
