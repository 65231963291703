import type {
  MailingAddress,
  MailingAddressInput,
  MailingAddressBeneficiaryInput,
  SendCheckRecipientInput,
  Scalars,
} from '~/graphql/types';
import { SUFFIX_ABBREVIATIONS } from '~/static-constants';

import { getOnlyNumbers } from '../normalizers';

export function formatPhoneNumber(value: string | null | undefined): string {
  if (!value) {
    return '';
  }
  const onlyNums = getOnlyNumbers(value);
  const onlyNumsLength = onlyNums.length;
  const splitValue = value?.split(/[^0-9]/);
  if (
    onlyNumsLength < 10 &&
    onlyNumsLength > 7 &&
    splitValue.length - 1 === 4 &&
    splitValue[1].length <= 3 &&
    splitValue[3].length <= 3
  ) {
    return value;
  }

  if (onlyNumsLength <= 3) {
    return onlyNums;
  } else if (onlyNumsLength <= 7) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    '(' +
    onlyNums.slice(0, 3) +
    ') ' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
}

export const formatSuffix = (suffix: string | null | undefined) => {
  if (
    suffix &&
    (suffix === SUFFIX_ABBREVIATIONS.JR || suffix === SUFFIX_ABBREVIATIONS.SR)
  ) {
    return suffix[0] + suffix.substr(1).toLowerCase();
  }
  return suffix;
};

export const buildAddress = (
  address:
    | MailingAddress
    | MailingAddressInput
    | MailingAddressBeneficiaryInput
    | SendCheckRecipientInput,
): string => {
  const { lineOne, lineTwo, city, stateOrProvince, postalCode } = address;
  const formattedAddress = [];

  if (lineTwo) {
    formattedAddress.push(`${lineOne},`, `${lineTwo},`);
  } else {
    formattedAddress.push(`${lineOne},`);
  }

  formattedAddress.push(`${city},`, stateOrProvince, postalCode);

  return formattedAddress.join(' ');
};

export const maskSsn = (
  ssn: Scalars['SocialSecurityNumber']['input'],
): string => {
  const matches = ssn.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (Array.isArray(matches) && matches[3] && matches[3].length > 0) {
    return '●●● - ●● - ' + matches[3];
  }

  return '-';
};
