import { ApolloClient, createHttpLink, from, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

import { AuthManager } from '~/auth';
import { getEnvironment } from '~/hooks/useEnvironment';
import { AnalyticsReporter } from '~/loggers';
import { logout } from '~/redux/actions';
import type { Dispatch } from '~/redux/hooks';

import { cache } from './cache';
import {
  DEFAULT_ERROR_MESSAGE,
  mutationErrorApolloLink,
} from './mutationErrorMapping';
import { mutationSuccessLink } from './mutationSuccessLink';
import { reauthenticate } from './reauthenticate';

const removeTypenameLink = removeTypenameFromVariables();

const readClientTimezoneName = (): string | null | undefined => {
  let name = null;

  // Try/Catch to guard against browsers lacking the Intl API.
  try {
    name = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    // Do nothing.
  }
  return name;
};

const createClient = (
  dispatch: Dispatch,
  anonymousUserId: string | null,
  auth: AuthManager,
  analytics: AnalyticsReporter,
) => {
  const httpLink = createHttpLink({
    fetch: (_, options) => {
      const env = getEnvironment();
      return fetch(env.value, options);
    },
  });

  const authLink = setContext(async ({ operationName }) => {
    // change to let when silent refresh is reimplemented
    const accessToken = auth.get('accessToken');

    const clientTimezoneName = readClientTimezoneName();
    const headers = {
      'X-Client-Id': `m1-web/${__VERSION__}`,
      'X-Client-Sentinel': Date.now(),
      'X-Segment-Id': anonymousUserId,
      ...(typeof accessToken === 'string' && {
        Authorization: `Bearer ${accessToken}`,
      }),
      ...(typeof clientTimezoneName === 'string' && {
        ['X-Client-Timezone']: clientTimezoneName,
      }),
      'X-Apollo-Operation-Name': operationName,
    };

    return {
      headers,
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (networkError) {
        return new Observable((observer) => {
          return observer.error(new Error(DEFAULT_ERROR_MESSAGE));
        });
      }

      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          if (
            'isAuthError' in error &&
            error.isAuthError === true &&
            operation.operationName !== 'Reauthenticate'
          ) {
            // this allows us to use async/await in onError link
            return new Observable((observer) => {
              (async () => {
                try {
                  const refreshToken = auth.get('refreshToken');
                  const accessToken = auth.get('accessToken');

                  // User is querying on page where they aren't logged in,
                  // but the query requires them to be logged in
                  if (
                    typeof refreshToken !== 'string' &&
                    typeof accessToken !== 'string'
                  ) {
                    return observer.error(
                      new Error('No refresh token available, logging out'),
                    );
                  }

                  // User does not have the proper refreshToken -> logout
                  if (typeof refreshToken !== 'string') {
                    return dispatch(logout());
                  }

                  const endpoint = getEnvironment().value;

                  const reauthenticateResult = await reauthenticate(
                    refreshToken,
                    endpoint,
                  );

                  if (
                    typeof reauthenticateResult?.outcome?.accessToken !==
                    'string'
                  ) {
                    // Logout if no accessToken returned
                    return dispatch(logout());
                  }

                  auth.update(reauthenticateResult.outcome);

                  // Modify the operation context with a new token
                  const oldHeaders = operation.getContext().headers;

                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${reauthenticateResult.outcome.accessToken}`,
                    },
                  });

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  // Retry last failed request
                  return forward(operation).subscribe(subscriber);
                } catch (error) {
                  return observer.error(error);
                }
              })();
            });
          }
        }
      }
    },
  );

  return new ApolloClient({
    link: from([
      authLink,
      removeTypenameLink,
      errorLink,
      mutationErrorApolloLink,
      mutationSuccessLink(analytics),
      httpLink,
    ]),
    connectToDevTools: true,
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });
};

export { createClient };
