import { Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useIdentityFirstFinancialSuitabilityQuestionsQuery,
  useIdentityInfoFormQuery,
} from '~/graphql/hooks';
import { useChangeStep } from '~/hooks/useChangeStep';
import { useLocation } from '~/hooks/useLocation';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FINANCIAL_SUITABILITY_QUESTION_MAP } from '../consts';
import { PrimaryEmploymentForm } from '../forms/PrimaryEmploymentForm';
import { useUpdateProfileHook } from '../hooks/updateProfileHook';
import { useFinancialSuitabilityWizardContext } from '../hooks/useFinancialSuitabilityWizardContext';
import type { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';
import type { FinancialSuitabilityWizardSteps } from '../types';

export const CollectPrimaryEmploymentWizardStep = () => {
  const product = useSelector(
    (state) => state.navigation.financialSuitabilityProduct,
  );
  const { goTo } = useFinancialSuitabilityWizardContext();
  const location = useLocation();
  const handleChangeRouteStep = useChangeStep({
    basePath: 'onboarding/financial-details',
  });

  const { data: questionsData, loading: questionsLoading } =
    useIdentityFirstFinancialSuitabilityQuestionsQuery();
  const questions =
    questionsData?.viewer?.user?.onboardingIdentityFirst
      ?.financialSuitabilitySection?.questions;
  const employmentQuestion = questions?.find(
    (q) => q?.questionType === 'EMPLOYMENT_STATUS_AND_WHERE_DO_YOU_WORK',
  );
  const nextQuestion = employmentQuestion?.nextQuestionType;

  const { updateProfileMutation, updateProfileLoading } =
    useUpdateProfileHook();
  usePortaledSpinner(updateProfileLoading);

  const { data: identityData, loading: identityLoading } =
    useIdentityInfoFormQuery();
  const occupationOptions =
    identityData?.viewer?.onboarding?.dueDiligence?.occupationDropdownOptions;

  React.useEffect(() => {
    if (location?.pathname?.includes('disclosures')) {
      handleChangeRouteStep({
        step: 'disclosures' as FinancialSuitabilityWizardSteps,
      });
      goTo('disclosures');
    }
    if (location?.pathname?.includes('net-worth')) {
      handleChangeRouteStep({
        step: 'net-worth' as FinancialSuitabilityWizardSteps,
      });
      goTo('net-worth');
    }
    if (location?.pathname?.includes('available-cash')) {
      handleChangeRouteStep({
        step: 'available-cash' as FinancialSuitabilityWizardSteps,
      });
      goTo('available-cash');
    }
  }, [location?.pathname, handleChangeRouteStep, goTo]);

  const onSubmit = (data: FinancialSuitabilityFormFields) => {
    updateProfileMutation(data, product);
  };

  const handleNext = () => {
    const wizardStep = FINANCIAL_SUITABILITY_QUESTION_MAP[nextQuestion!];
    handleChangeRouteStep({
      step: wizardStep as FinancialSuitabilityWizardSteps,
    });
    goTo(wizardStep);
  };

  if (questionsLoading || identityLoading) {
    return <Spinner fullScreen />;
  }

  if (!occupationOptions) {
    return <GenericSystemError />;
  }

  return (
    <Box width={588}>
      <Box mb={32}>
        <HM
          content={employmentQuestion?.title ?? 'Tell us about your work.'}
          pt={64}
        />
      </Box>
      <PrimaryEmploymentForm
        occupationOptions={occupationOptions}
        continueLabel={employmentQuestion?.continueLabel ?? 'Continue'}
        nextQuestion={nextQuestion}
        onSubmit={onSubmit}
        handleNext={handleNext}
      />
    </Box>
  );
};
