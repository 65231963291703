import { Button, Flex, PL, PM, PXL } from '@m1/liquid-react';
import * as React from 'react';

import type { SmartTransferFulfillmentConditionOptionSetFragment } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Radio } from '~/toolbox/radio';

import {
  CashBalanceCap,
  CreditBorrowedCap,
  CreditUtilization,
  FundingPerPeriod,
  Indefinite,
  RetirementFundingCap,
} from './fulfillment-condition-inputs-by-type';

export type FulfillmentConditionOptionsProps = {
  fulfillmentConditionOptionSet: SmartTransferFulfillmentConditionOptionSetFragment;
  isEditMode: boolean;
  onFinishStep: (arg0: {
    contraEditType:
      | typeof ACTIONS.ADD_ACTIVE_CONTRA_PARTICIPANT
      | typeof ACTIONS.DELETE_ACTIVE_CONTRA_PARTICIPANT
      | typeof ACTIONS.REPLACE_ACTIVE_CONTRA_PARTICIPANT;
  }) => void;
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onInitialOptionSelect?: () => void;
  preamble: string | null | undefined;
  preSelectedFulfillmentConditionDataOfActiveContra?:
    | SmartTransferFulfillmentConditionInput
    | null
    | undefined;
};

export const FulfillmentConditionInputs = ({
  fulfillmentConditionOptionSet,
  isEditMode,
  onFinishStep,
  onFulfillmentDataChange,
  onInitialOptionSelect,
  preSelectedFulfillmentConditionDataOfActiveContra,
  preamble,
}: FulfillmentConditionOptionsProps) => {
  const options = fulfillmentConditionOptionSet.options.filter(Boolean);
  const [isContinueAllowed, setIsContinueAllowed] = React.useState(false);
  const [initialConditionRadioValue, setRadioValue] = React.useState(
    preSelectedFulfillmentConditionDataOfActiveContra?.fulfillmentConditionType,
  );
  // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
  const onRadioGroupChange = (value) => {
    if (initialConditionRadioValue === undefined && onInitialOptionSelect) {
      onInitialOptionSelect();
    }
    setRadioValue(value);
  };
  return (
    <Flex
      flexDirection="column"
      mb={64}
      mt={230}
      ml={64}
      mr="auto"
      maxWidth={550}
      minHeight={550}
    >
      <PXL fontWeight={600} content={preamble || ''} mb={16} />
      <Radio.Group
        style={{
          marginBottom: 32,
        }}
        onChange={onRadioGroupChange}
        value={initialConditionRadioValue}
      >
        {options.map((option, selectedInitialFulfillmentConditionIndex) => {
          return (
            <Radio.Choice
              key={selectedInitialFulfillmentConditionIndex}
              label={
                <>
                  {/* @ts-expect-error - TS2531 - Object is possibly 'null'. */}
                  <PL content={option.title} />
                  {/* @ts-expect-error - TS2531 - Object is possibly 'null'. */}
                  <PM content={option.description} />
                </>
              }
              // @ts-expect-error - TS2769 - No overload matches this call.
              style={{
                margin: '12px 16px',
              }}
              value={
                // @ts-expect-error - TS2531 - Object is possibly 'null'.
                option.fulfillmentConditionRequirements
                  ?.fulfillmentConditionType
              }
            />
          );
        })}
      </Radio.Group>
      <FulfillmentConditionAdditionalInputs
        preSelectedFulfillmentConditionDataOfActiveContra={
          preSelectedFulfillmentConditionDataOfActiveContra
        }
        fulfillmentConditionOptions={options}
        selectedFulfillmentConditionType={initialConditionRadioValue}
        onFulfillmentDataChange={onFulfillmentDataChange}
        onIsContinueAllowedChange={(isContinueAllowed) => {
          setIsContinueAllowed(isContinueAllowed);
        }}
      />
      <Flex alignItems="center" justifyContent="flex-end" mt={32}>
        {isEditMode ? (
          <ButtonGroup>
            <Button
              disabled={!isContinueAllowed}
              kind="destructive"
              label="Delete"
              onClick={() =>
                onFinishStep({
                  contraEditType: ACTIONS.DELETE_ACTIVE_CONTRA_PARTICIPANT,
                })
              }
              size="large"
            />
            <Button
              disabled={!isContinueAllowed}
              kind="primary"
              label="Save"
              onClick={() =>
                onFinishStep({
                  contraEditType: ACTIONS.REPLACE_ACTIVE_CONTRA_PARTICIPANT,
                })
              }
              size="large"
            />
          </ButtonGroup>
        ) : (
          <Button
            disabled={!isContinueAllowed}
            kind="primary"
            label="Continue"
            onClick={() =>
              onFinishStep({
                contraEditType: ACTIONS.ADD_ACTIVE_CONTRA_PARTICIPANT,
              })
            }
            size="large"
          />
        )}
      </Flex>
    </Flex>
  );
};

type FulfillmentConditionAdditionalInputProps = {
  fulfillmentConditionOptions: SmartTransferFulfillmentConditionOptionSetFragment['options'];
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onIsContinueAllowedChange: (arg0: boolean) => void;
  preSelectedFulfillmentConditionDataOfActiveContra:
    | SmartTransferFulfillmentConditionInput
    | null
    | undefined;
  selectedFulfillmentConditionType: string | null | undefined;
};

const FulfillmentConditionAdditionalInputs = ({
  fulfillmentConditionOptions,
  onFulfillmentDataChange,
  onIsContinueAllowedChange,
  preSelectedFulfillmentConditionDataOfActiveContra,
  selectedFulfillmentConditionType,
}: FulfillmentConditionAdditionalInputProps) => {
  if (typeof selectedFulfillmentConditionType !== 'string') {
    return null;
  }

  const selectedOption = fulfillmentConditionOptions
    .filter(Boolean)
    .find(
      (option) =>
        option?.fulfillmentConditionRequirements?.fulfillmentConditionType ===
        selectedFulfillmentConditionType,
    );
  if (!selectedOption) {
    return null;
  }

  switch (selectedFulfillmentConditionType) {
    case 'INDEFINITE':
      return (
        <Indefinite
          onFulfillmentDataChange={onFulfillmentDataChange}
          onIsContinueAllowedChange={onIsContinueAllowedChange}
        />
      );
    case 'FUNDING_PER_PERIOD':
      return (
        <FundingPerPeriod
          fulfillmentConditionRequirements={
            selectedOption.fulfillmentConditionRequirements
          }
          onFulfillmentDataChange={onFulfillmentDataChange}
          onIsContinueAllowedChange={onIsContinueAllowedChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            preSelectedFulfillmentConditionDataOfActiveContra
          }
        />
      );
    case 'CASH_BALANCE_CAP':
      return (
        <CashBalanceCap
          fulfillmentConditionRequirements={
            selectedOption.fulfillmentConditionRequirements
          }
          onFulfillmentDataChange={onFulfillmentDataChange}
          onIsContinueAllowedChange={onIsContinueAllowedChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            preSelectedFulfillmentConditionDataOfActiveContra
          }
        />
      );
    case 'CREDIT_BORROWED_CAP':
      return (
        <CreditBorrowedCap
          fulfillmentConditionRequirements={
            selectedOption.fulfillmentConditionRequirements
          }
          onFulfillmentDataChange={onFulfillmentDataChange}
          onIsContinueAllowedChange={onIsContinueAllowedChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            preSelectedFulfillmentConditionDataOfActiveContra
          }
        />
      );
    case 'CREDIT_UTILIZATION_CAP':
      return (
        <CreditUtilization
          onFulfillmentDataChange={onFulfillmentDataChange}
          onIsContinueAllowedChange={onIsContinueAllowedChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            preSelectedFulfillmentConditionDataOfActiveContra
          }
        />
      );
    case 'IRA_WEEKLY_FUNDING_PER_PERIOD':
    case 'IRA_MONTHLY_FUNDING_PER_PERIOD':
    case 'IRA_QUARTERLY_FUNDING_PER_PERIOD':
    case 'IRA_YEARLY_FUNDING':
      return (
        <RetirementFundingCap
          fulfillmentConditionRequirements={
            selectedOption.fulfillmentConditionRequirements
          }
          onFulfillmentDataChange={onFulfillmentDataChange}
          onIsContinueAllowedChange={onIsContinueAllowedChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            preSelectedFulfillmentConditionDataOfActiveContra
          }
        />
      );
    default:
      return null;
  }
};
