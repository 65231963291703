import React from 'react';

import type { RichTextFragment } from '~/graphql/types';
import { RichText } from '~/lens-toolbox/RichText/RichText';

export const createRichTextParagraphs = (
  paragraphs: (RichTextFragment | null)[] | null,
) => {
  return (
    paragraphs?.map((paragraph, index) => {
      if (paragraph?.content) {
        return (
          <RichText
            key={index}
            {...{
              richText: paragraph,
              containerProps: {
                style: {
                  flexDirection: 'column',
                  alignItems: 'baseline',
                },
              },
              linkProps: {
                style: {
                  display: 'initial',
                  paddingRight: '5px',
                  fontWeight: 600,
                },
              },
            }}
          />
        );
      }
      return null;
    }) || []
  );
};
