import { PS, type Theme } from '@m1/liquid-react';
import * as d3 from 'd3';
import last from 'lodash-es/last';
import moment from 'moment-timezone';
import * as React from 'react';

import type { IntradayQuoteTimeSeriesFragment } from '~/graphql/types';
import { isNotNil } from '~/utils';

import { Baseline, Path, Circle, Text } from './elements';

type IntradayQuoteTimeSeriesFragmentPoint =
  IntradayQuoteTimeSeriesFragment['series'][number];

type Props = {
  emptyMessage?: string;
  height: number;
  includeLastUpdatedTime: boolean;
  margin: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  intradayQuotes: IntradayQuoteTimeSeriesFragment | null | undefined;
  theme?: Theme;
  width: number;
};

export class SecurityMiniChartIntraday extends React.Component<Props> {
  static defaultProps = {
    height: 31,
    width: 85,
    margin: {
      top: 2,
      right: 0,
      bottom: 2,
      left: 0,
    },
    emptyMessage: 'No data to display',
    includeLastUpdatedTime: false,
  };

  static onlyValidQuotes = (point: IntradayQuoteTimeSeriesFragmentPoint) =>
    isNotNil(point.data.percentChangeFromPreviousClosePrice);

  render() {
    const { intradayQuotes, width, height, margin } = this.props;

    if (!intradayQuotes || !intradayQuotes.timeSpan) {
      return this.renderEmpty();
    }

    const { firstOpenTime, lastCloseTime } = intradayQuotes.timeSpan;
    const endOfMarket = moment.utc(firstOpenTime).toDate();
    const startOfMarket = moment.utc(lastCloseTime).toDate();

    const data = intradayQuotes.series
      .filter((quote) => SecurityMiniChartIntraday.onlyValidQuotes(quote))
      .map((quote) => ({
        value: quote.data.percentChangeFromPreviousClosePrice,
        date: moment.utc(quote.time).toDate(),
      }));

    if (data.length === 0) {
      return this.renderEmpty();
    }

    const lastDataPoint = last(data);

    const xScale = d3
      .scaleTime()
      .range([0 + margin.left, width - margin.right])
      .domain([endOfMarket, startOfMarket]);

    const yScale = d3
      .scaleLinear()
      .range([0 + margin.top, height - margin.bottom])
      // @ts-expect-error - TS2345 - Argument of type '(number | undefined)[]' is not assignable to parameter of type 'Iterable<NumberValue>'.
      .domain([
        // @ts-expect-error - TS2345 - Argument of type 'Maybe<number> | undefined' is not assignable to parameter of type 'number'.
        d3.max(data, (d) => Math.abs(d.value)),
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2345 - Argument of type 'Maybe<number> | undefined' is not assignable to parameter of type 'number'.
        d3.max(data, (d) => Math.abs(d.value)) * -1,
      ]);

    const line = d3
      .line()
      // @ts-expect-error - TS2339 - Property 'date' does not exist on type '[number, number]'.
      .x((d) => xScale(d.date))
      // @ts-expect-error - TS2551 - Property 'value' does not exist on type '[number, number]'. Did you mean 'values'?
      .y((d) => yScale(d.value));

    const halfHeight = Math.floor(
      (height + margin.top / 2 - margin.bottom / 2) / 2,
    );
    // @ts-expect-error - TS2769 - No overload matches this call.
    const calcLine = line(data);
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    const calcCircleX = xScale(lastDataPoint.date);
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2345 - Argument of type 'Maybe<number> | undefined' is not assignable to parameter of type 'NumberValue'.
    const calcCircleY = yScale(lastDataPoint.value);

    return (
      <svg height={height} width={width}>
        <defs>
          <clipPath id="clipPathPos">
            <rect x="0" y="0" width={width} height={halfHeight} />
          </clipPath>
          <clipPath id="clipPathNeg">
            <rect x="0" y={halfHeight} width={width} height={halfHeight} />
          </clipPath>
        </defs>

        <Baseline
          x1="0"
          y1={halfHeight}
          x2={width - margin.right}
          y2={halfHeight}
        />
        <g>
          <Path color="successTint" clipPath="url(#clipPathPos)" d={calcLine} />
          <Path color="critical" clipPath="url(#clipPathNeg)" d={calcLine} />
          <Circle
            color="successTint"
            cx={calcCircleX}
            cy={calcCircleY}
            r="2"
            clipPath="url(#clipPathPos)"
          />
          <Circle
            color="critical"
            cx={calcCircleX}
            cy={calcCircleY}
            r="2"
            clipPath="url(#clipPathNeg)"
          />
        </g>

        {this.props.includeLastUpdatedTime &&
          intradayQuotes.lastUpdatedTime && (
            <Text x={width - margin.right} y={height} textAnchor="end">
              {moment(intradayQuotes.lastUpdatedTime)
                .tz('America/New_York')
                .format('lll z')}
            </Text>
          )}
      </svg>
    );
  }

  renderEmpty() {
    return (
      <PS color="foregroundNeutralMain" content={this.props.emptyMessage} />
    );
  }
}
