import type { SectionRowData } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenSection';
import type {
  FinancialInformationProfileFragment,
  ExchangeOrFinraAffiliationDisclosure,
  ControlPersonDisclosure,
  PoliticalExposureDisclosure,
} from '~/graphql/types';
import { getEnumDescription } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

type NonNullableFinancialSectionProfile =
  NonNullable<FinancialInformationProfileFragment>;

export type FinancialSuitabilityData = {
  exchangeOrFinraAffiliationDisclosure?:
    | NonNullableFinancialSectionProfile['primary']['exchangeOrFinraAffiliationDisclosure']
    | null
    | undefined;
  controlPersonDisclosure?:
    | NonNullableFinancialSectionProfile['primary']['controlPersonDisclosure']
    | null
    | undefined;
  politicalDisclosure?:
    | NonNullableFinancialSectionProfile['primary']['politicalDisclosure']
    | null
    | undefined;
  isSubjectToBackupWithholding?:
    | NonNullable<
        NonNullableFinancialSectionProfile['primary']['backupWithholding']
      >['isSubjectToBackupWithholding']
    | null
    | undefined;
  annualIncomeAmount:
    | NonNullableFinancialSectionProfile['suitability']['annualIncomeAmount']
    | null;
  employer:
    | NonNullable<
        NonNullableFinancialSectionProfile['primary']['employmentInfo']
      >['employer']
    | null
    | undefined;
  employmentStatus:
    | NonNullable<
        NonNullableFinancialSectionProfile['primary']['employmentInfo']
      >['status']
    | undefined
    | null;
  occupation:
    | NonNullable<
        NonNullableFinancialSectionProfile['primary']['employmentInfo']
      >['occupation']
    | undefined
    | null;
  totalNetWorth:
    | NonNullableFinancialSectionProfile['suitability']['totalNetWorth']
    | null;
  liquidNetWorth:
    | NonNullableFinancialSectionProfile['suitability']['liquidNetWorth']
    | null;
};

export const getKeyValuePairsFromInput = (
  input: FinancialSuitabilityData,
): Array<SectionRowData> => {
  const allRows = [];
  const exchangeOrFinraAffiliationDisclosure =
    input.exchangeOrFinraAffiliationDisclosure;
  const controlPersonDisclosure = input.controlPersonDisclosure;
  const politicalDisclosure = input.politicalDisclosure;
  const isSubjectToBackupWithholding = input.isSubjectToBackupWithholding;
  const hasDisclosures =
    exchangeOrFinraAffiliationDisclosure ||
    controlPersonDisclosure ||
    politicalDisclosure ||
    isSubjectToBackupWithholding;

  if (hasDisclosures) {
    allRows.push({
      label: 'Disclosure',
      value: getDisclosuresCopy({
        exchangeOrFinraAffiliationDisclosure,
        controlPersonDisclosure,
        politicalDisclosure,
        isSubjectToBackupWithholding,
      }),
    });
  }

  allRows.push({
    label: 'Employment status',
    value: getEnumDescription(input.employmentStatus, 'EmploymentStatusEnum'),
  });

  if (input.employer) {
    allRows.push({
      label: 'Employer',
      value: input.employer,
    });
  }

  if (input.occupation) {
    allRows.push({
      label: 'Industry',
      value: input.occupation,
    });
  }

  if (typeof input.annualIncomeAmount === 'number') {
    allRows.push({
      label: 'Annual income',
      value: formatCurrency(input.annualIncomeAmount, 'COMPACT') ?? '$--',
    });
  }

  if (input.totalNetWorth) {
    allRows.push({
      label: 'Net worth',
      value: getEnumDescription(
        String(input.totalNetWorth),
        'TotalNetWorthEnum',
      ),
    });
  }

  if (input.liquidNetWorth) {
    allRows.push({
      label: 'Liquid net worth',
      value: getEnumDescription(input.liquidNetWorth, 'LiquidNetWorthEnum'),
    });
  }

  return allRows;
};

export const mapResponseToFinancialSuitabilityData = (
  profile: FinancialInformationProfileFragment,
): FinancialSuitabilityData => {
  const { suitability, primary } = profile;

  return {
    exchangeOrFinraAffiliationDisclosure:
      primary?.exchangeOrFinraAffiliationDisclosure,
    controlPersonDisclosure: primary?.controlPersonDisclosure,
    politicalDisclosure: primary?.politicalDisclosure,
    isSubjectToBackupWithholding:
      primary?.backupWithholding?.isSubjectToBackupWithholding,
    annualIncomeAmount: suitability?.annualIncomeAmount,
    employer: primary?.employmentInfo?.employer,
    employmentStatus: primary?.employmentInfo?.status,
    occupation: primary?.employmentInfo?.occupation,
    totalNetWorth: suitability?.totalNetWorth,
    liquidNetWorth: suitability?.liquidNetWorth,
  };
};

export const getDisclosuresCopy = ({
  exchangeOrFinraAffiliationDisclosure,
  controlPersonDisclosure,
  politicalDisclosure,
  isSubjectToBackupWithholding,
}: {
  exchangeOrFinraAffiliationDisclosure?: ExchangeOrFinraAffiliationDisclosure | null;
  controlPersonDisclosure?: ControlPersonDisclosure | null;
  politicalDisclosure?: PoliticalExposureDisclosure | null;
  isSubjectToBackupWithholding?: boolean | null;
}) => {
  const disclosuresCopy = [];
  const exchangeOrFinraAffiliationDisclosureCopy =
    'I am affiliated with or work for a broker/dealer.';
  const controlPersonDisclosureCopy =
    'I am a 10% shareholder or director of a publicly traded company.';
  const politicalDisclosureCopy =
    'I am or an immediate family member is a current or former Public Official.';
  const backupWithholdingDisclosureCopy = `I confirm I ${
    isSubjectToBackupWithholding ? 'am' : 'am not'
  } subject to backup withholding from previously underreporting income to the IRS.`;

  if (exchangeOrFinraAffiliationDisclosure?.isAffiliated) {
    disclosuresCopy.push(exchangeOrFinraAffiliationDisclosureCopy);
  }

  if (controlPersonDisclosure?.isControlPerson) {
    disclosuresCopy.push(controlPersonDisclosureCopy);
  }

  if (politicalDisclosure?.isPoliticallyExposed) {
    disclosuresCopy.push(politicalDisclosureCopy);
  }

  disclosuresCopy.push(backupWithholdingDisclosureCopy);

  return disclosuresCopy;
};
