import * as React from 'react';
import { type FieldValues, FormProvider } from 'react-hook-form';

import { MultiStepFormContext } from './MultiStepFormContext';
import type { MultiStepFormContextValue } from './types';

export const MultiStepFormProvider = <T extends FieldValues, K extends string>({
  children,
  value,
}: {
  children: React.ReactNode;
  value: MultiStepFormContextValue<T, K>;
}) => {
  return (
    <FormProvider {...value}>
      <MultiStepFormContext.Provider value={value}>
        {children}
      </MultiStepFormContext.Provider>
    </FormProvider>
  );
};
