import { type Color, Flex, PL, PM, PS, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import type { AccountTileFragment } from '~/graphql/types';
import type { OnClickLinkableEvent } from '~/hooks/useLinkable';
import { useNavigate } from '~/hooks/useNavigate';
import { useRecordDisplayAppAnalyticsEvent } from '~/hooks/useRecordDisplayAppAnalyticsEvent';
import { AppImage } from '~/lens-toolbox/AppImage';
import { Link } from '~/toolbox/link';

type HomeTileProps = {
  tile: AccountTileFragment;
};

const StyledAppImage = styled(AppImage)`
  width: 80px;
  height: 80px;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundNeutralMain};
  }
`;

export const HomeTile = ({ tile }: HomeTileProps) => {
  const { analytics } = React.useContext(AppContext);
  const navigate = useNavigate();

  const handleTileClick = () => {
    tile.primaryAction?.analyticsEvent &&
      analytics.recordAppAnalyticsEvent(tile.primaryAction.analyticsEvent);
    if (tile.primaryAction?.internalPath) {
      navigate({ to: tile.primaryAction.internalPath });
    }
  };
  const handleTileKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleTileClick();
    }
  };
  const handleSecondaryActionClick = (
    e: OnClickLinkableEvent,
    internalPath: string | null | undefined,
  ) => {
    e.stopPropagation();
    navigate({ to: internalPath ?? '/d/home' });
  };
  useRecordDisplayAppAnalyticsEvent(tile.analyticsEvent);
  return (
    <StyledCard
      p="0 16px !important"
      height={88}
      onClick={handleTileClick}
      onKeyPress={handleTileKeyDown}
      data-testid={`home-tile-${tile.title}`}
    >
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={72}
        height="100%"
      >
        <Flex flexDirection="row" py={16} alignItems="center">
          {tile.icon && <AppImage appImage={tile.icon} mr={12} />}
          <Flex flexDirection="column">
            <PL content={tile.title} fontWeight={600} />
            {tile.subtitle && (
              <Flex flexDirection="row" alignItems="center">
                {tile.subtitle.icon && (
                  <AppImage appImage={tile.subtitle.icon} mr={4} />
                )}
                {tile.subtitle.text && (
                  <PM
                    content={tile.subtitle.text}
                    fontWeight={400}
                    color="foregroundNeutralSecondary"
                  />
                )}
              </Flex>
            )}
            {tile.secondaryAction?.internalPath && (
              <Link
                to={tile.secondaryAction.internalPath}
                onClick={(e) =>
                  handleSecondaryActionClick(
                    e,
                    tile.secondaryAction?.internalPath,
                  )
                }
              >
                <Flex flexDirection="row">
                  <PM
                    fontWeight={600}
                    color="foregroundPrimary"
                    content={tile.secondaryAction.title}
                  />
                  {tile.secondaryAction.icon && (
                    <AppImage appImage={tile.secondaryAction.icon} />
                  )}
                </Flex>
              </Link>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column" alignItems="end" alignContent="center">
          {tile.illustration && !tile.value && (
            <StyledAppImage appImage={tile.illustration} />
          )}
          <PM content={tile.value} fontWeight={600} />
          {tile.subValue && (
            <PS
              mt={6}
              content={tile.subValue.text}
              color={
                (tile.subValue.color as Color) || 'foregroundNeutralPrimary'
              }
            />
          )}
        </Flex>
      </Flex>
    </StyledCard>
  );
};
