import * as React from 'react';

import { HelpPopup, type HelpPopupProps } from '../HelpPopup';

export const SystemPiePerformancePopup = (
  props: Omit<HelpPopupProps, 'label'>,
) => {
  return (
    <HelpPopup
      {...props}
      label="Performance"
      body="Total gain of this pie, including dividends"
    />
  );
};
