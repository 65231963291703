export interface FlowDefinition<
  S extends { [key in string | number | symbol]?: string } = any,
> {
  name: ValueOf<typeof FLOWS>;
  steps: S;
}

export type FlagVariations = 'control' | 'a' | 'b';

export type FlowStep<D extends FlowDefinition> = keyof D['steps'];

// TODO: See if I can remove the ts-expect errors here
export const FLOWS = {
  ACAT_WIZARD: 'ACAT_WIZARD' as const,
  ACTIVATE_CREDIT_CARD: 'ACTIVATE_CREDIT_CARD' as const,
  ACTIVATE_DEBIT_CARD: 'ACTIVATE_DEBIT_CARD' as const,
  CREATE_SEND_CHECK: 'CREATE_SEND_CHECK' as const,
  CREATE_SMART_TRANSFER: 'CREATE_SMART_TRANSFER' as const,
  CREATE_PAYMENT: 'CREATE_PAYMENT' as const,
  CREDIT_CARD_APPLICATION: 'CREDIT_CARD_APPLICATION' as const,
  ENROLL_IN_SPEND: 'ENROLL_IN_SPEND' as const,
  IDENTITY_PROFILE: 'IDENTITY_PROFILE' as const,
  KNOW_YOUR_CUSTOMER: 'KNOW_YOUR_CUSTOMER' as const,
  MARGIN_CALL_INFORMATION:
    // @ts-expect-error - TS2695 - Left side of comma operator is unused and has no side effects.
    ('MARGIN_CALL_INFORMATION', 'MARGIN_CALL_INFORMATION'),
  MOVE_MONEY: 'MOVE_MONEY' as const,
  // @ts-expect-error - TS2695 - Left side of comma operator is unused and has no side effects.
  IDENTITY_FIRST: ('IDENTITY_FIRST', 'IDENTITY_FIRST'),
  INVEST_ONBOARDING: 'INVEST_ONBOARDING' as const,
  PERSONAL_LOANS_APPLICATION: 'PERSONAL_LOANS_APPLICATION' as const,
  PORTFOLIO_EDITOR: 'PORTFOLIO_EDITOR' as const,
  PHONE_VERIFICATION: 'PHONE_VERIFICATION' as const,
  REQUEST_DEBIT_CARD: 'REQUEST_DEBIT_CARD' as const,
  RESET_DEBIT_CARD_PIN: 'RESET_DEBIT_CARD_PIN' as const,
  RESET_PASSWORD: 'RESET_PASSWORD' as const,
  // @ts-expect-error - TS2695 - Left side of comma operator is unused and has no side effects.
  RESOLVE_CALL: ('RESOLVE_CALL', 'RESOLVE_CALL'),
  OPEN_INVEST_ACCOUNT: 'OPEN_INVEST_ACCOUNT' as const,
  SET_ORDER: 'SET_ORDER' as const,
  REWARDS_PAYOUT_LOCATION:
    // @ts-expect-error - TS2695 - Left side of comma operator is unused and has no side effects.
    ('REWARDS_PAYOUT_LOCATION', 'REWARDS_PAYOUT_LOCATION'),
  VERIFY_SPEND_BANK: 'VERIFY_SPEND_BANK' as const,
  WAITLIST: 'WAITLIST' as const,
  SAVINGS_ONBOARDING: 'SAVINGS_ONBOARDING' as const,
};

export const SET_ORDER_FLOW_STEPS = {
  LOAD_STATUS: 'loading',
  SETUP_ORDER: 'setup',
  ORDER_LOCKED: 'locked',
  ORDER_BLOCKED: 'blocked',
  CONFIRM_CRYPTO_ORDER: 'confirm_crypto',
  BUYING_POWER_OVERVIEW: 'buying-power',
};

export const SetOrderDefinition: FlowDefinition<typeof SET_ORDER_FLOW_STEPS> = {
  name: FLOWS.SET_ORDER,
  steps: SET_ORDER_FLOW_STEPS,
};

const verifySpendBankSteps = {
  CONFIRM_DEPOSIT_AMOUNTS: 'confirm-deposits',
  SHOW_UNKNOWN_ERROR: 'uh-oh',
};
export const VerifySpendBankDefinition: FlowDefinition<
  typeof verifySpendBankSteps
> = {
  name: FLOWS.VERIFY_SPEND_BANK,
  steps: verifySpendBankSteps,
};
