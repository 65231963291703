import type { SetPortfolioSliceOrderInput } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

import {
  FLOWS,
  type FlowDefinition,
  SET_ORDER_FLOW_STEPS,
} from '../newFlowsReducer.definition';
import { type FlowReducerState, createAugmentedFlowReducer } from '../utils';

const setOrderSteps = SET_ORDER_FLOW_STEPS;

export const SetOrderDefinition: FlowDefinition<typeof setOrderSteps> = {
  name: FLOWS.SET_ORDER,
  steps: setOrderSteps,
};

export type OrderDirection = 'buy' | 'sell';

export type SetOrderFlowState = FlowReducerState<typeof SetOrderDefinition> & {
  direction: OrderDirection;
  input:
    | (SetPortfolioSliceOrderInput & { tradeWindow: 'MORNING' | 'AFTERNOON' })
    | null
    | undefined;
  isFromHoldingsPositionDetail?: boolean;
  portfolioSliceId: string | null | undefined;
};

const initialState: Partial<SetOrderFlowState> = {
  direction: 'buy',
  input: null,
  isFromHoldingsPositionDetail: false,
  portfolioSliceId: null,
  step: 'LOAD_STATUS',
  stepTitle: '',
};

export const setOrder = createAugmentedFlowReducer(
  SetOrderDefinition,
  // @ts-expect-error - TS2345 - Argument of type 'Partial<SetOrderFlowState>' is not assignable to parameter of type 'Partial<SetOrderFlowState> & RequiredState<"LOAD_STATUS" | "SETUP_ORDER" | "ORDER_LOCKED" | "CONFIRM_ORDER" | "SHOW_SUCCESS">'.
  initialState,
  (state, action) => {
    switch (action.type) {
      case ACTIONS.CLICKED_ORDER_DIRECTION_TOGGLE:
        return {
          ...state,
          direction: action.payload,
        };
      case ACTIONS.FINISHED_FLOW_STEP:
        if (action.meta.step === 'SETUP_ORDER') {
          return {
            ...state,
            input: action.payload,
          };
        }
        return state;
      default:
        return state;
    }
  },
);
