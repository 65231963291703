import defaultsDeep from 'lodash-es/defaultsDeep';
import unset from 'lodash-es/unset';
import moment from 'moment';

import type {
  Profile,
  ProfileInput,
  UpdateAccountHolderInput,
} from '~/graphql/types';
import { deserializeDisclosureString } from '~/utils';

export function mergeProfiles(
  remote: Profile,
  local: Record<string, any>,
  { isUpdateProfile }: { isUpdateProfile?: boolean },
): ProfileInput {
  const { beneficiaries, allowsBeneficiaries, primary, ...rest } = remote;

  // if isUpdateProfile
  // remove controlPersonDisclosure.companySymbols and politicalExposureDisclosure.immediateFamilyMembers from remote profile
  // so any old array items do not override any local profile updates
  const remoteProfile = {
    ...rest,
    primary: {
      ...primary,
      controlPersonDisclosure: {
        isControlPerson: primary?.controlPersonDisclosure?.isControlPerson,
      },
      politicalDisclosure: {
        isPoliticallyExposed:
          primary?.politicalDisclosure?.isPoliticallyExposed,
        politicalOrganization:
          primary?.politicalDisclosure?.politicalOrganization,
      },
    },
  };

  const profile = defaultsDeep(
    local,
    isUpdateProfile ? remoteProfile : { ...rest, primary },
    {
      beneficiaries: null,
    },
  ) as ProfileInput;

  if (!allowsBeneficiaries) {
    unset(profile, 'beneficiaries');
  }

  return {
    ...profile,
    primary: transformProfilePoliticalDisclosure(profile?.primary),
  };
}

// @ts-ignore 'autofilledAddress' comes from RF form, not easy to type here
function removeExtraneousFieldsFromHomeAddress({ autofilledAddress, ...rest }) {
  return {
    ...rest,
  };
}

/**
 * TODO: This function isn't really typesafe due to it keeping anything thats passed in the holderInput
 * record. We should do one of the following:
 * 1) Type the holderInput to be a more concrete type
 * 2) use pick or pickBy to only pick out the properties we want before merging with the defaults.
 */
export const transformHolderInput = (
  holderInput: Record<string, any> | null | undefined,
  phone: string | null | undefined,
): (UpdateAccountHolderInput & { dateOfBirth: string }) | null => {
  if (!holderInput) {
    return null;
  }

  const defaults = {
    homeAddress: {
      country: 'USA',
    },
    additionalEmail: null,
    exchangeOrFinraAffiliationDisclosure: {
      isAffiliated: false,
      firmName: null,
    },
    controlPersonDisclosure: {
      isControlPerson: false,
      companySymbols: null,
    },
    politicalExposureDisclosure: {
      politicalOrganization: null,
      isPoliticallyExposed: false,
      immediateFamilyMembers: null,
    },
  };

  // Create a new object and assign defaults to anything that isn't in the holderInput
  const profileInput = defaultsDeep({}, holderInput, defaults);

  const companySymbols =
    profileInput['controlPersonDisclosure']['companySymbols'];
  const dateOfBirth = profileInput['dateOfBirth'];
  const immediateFamilyMembers =
    profileInput['politicalExposureDisclosure']['immediateFamilyMembers'];

  if (phone) {
    profileInput['phoneNumber'] = phone;
  }

  // Format our DOB and disclosure properties
  profileInput['dateOfBirth'] =
    dateOfBirth && moment(dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');
  profileInput['controlPersonDisclosure']['companySymbols'] =
    companySymbols && deserializeDisclosureString(companySymbols);

  profileInput['politicalExposureDisclosure']['immediateFamilyMembers'] =
    immediateFamilyMembers &&
    deserializeDisclosureString(immediateFamilyMembers);

  // ensure no extraneous fields exist on homeAddress
  profileInput['homeAddress'] = removeExtraneousFieldsFromHomeAddress(
    profileInput['homeAddress'],
  );

  // replace the new 'employmentInfo' with 'employment'. this has been updated read-side but not write-side
  if (profileInput['employmentInfo']) {
    profileInput['employment'] = profileInput['employmentInfo'];
    unset(profileInput, 'employmentInfo');
  }

  return profileInput;
};

/**
 * A helper util that takes in the input field from the identity first flow state in redux ((state) => state.newFlows.IDENTITY_FIRST.input)
 * and builds the final input object required for the UpdateProfileMutation.
 * @param inputData - The input object from redux.
 * @returns - Returns the ProfileInput object or null.
 */
export const createUpdateProfileInput = (
  inputData: Record<string, any>,
): ProfileInput | null => {
  const { primary, suitability, trustedContact } = inputData;

  const accountHolderInput = transformHolderInput(
    profileWithoutUnwantedFields(primary),
    null,
  );

  if (!accountHolderInput || !suitability) {
    return null;
  }

  const profileInput = trustedContact
    ? {
        primary: accountHolderInput,
        suitability,
        trustedContact,
      }
    : {
        primary: accountHolderInput,
        suitability,
      };

  return profileInput;
};

export const profileWithoutUnwantedFields = ({
  countryOfCitizenship,
  dateOfBirth,
  employmentInfo,
  ...rest
}: Record<string, any>) => {
  return { ...rest };
};

export const transformProfilePoliticalDisclosure = ({
  politicalDisclosure,
  politicalExposureDisclosure,
  ...rest
}: Record<string, any>) => {
  return politicalDisclosure && !politicalExposureDisclosure
    ? {
        ...rest,
        politicalExposureDisclosure: politicalDisclosure,
      }
    : { ...rest, politicalExposureDisclosure };
};
