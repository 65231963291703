import { Button, Flex } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { TransferInstanceAmountText } from '~/components/transfers/TransferInstanceAmountText';
import { TransferInstanceStatusPill } from '~/components/transfers/TransferInstanceStatusPill';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { useSmartTransferContraParticipantEntryInstancesPageQuery } from '~/graphql/hooks';
import type {
  SmartTransferContraParticipantEntryInstanceNodeFragment,
  TransferInstanceNodeFragment,
} from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';
import { toArray } from '~/utils';

type SmartTransferContraParticipantEntryTransferInstancesTableProps = {
  contraParticipantEntryId: string;
};

type ParticipantEntryInstanceNode =
  SmartTransferContraParticipantEntryInstanceNodeFragment;

export const SmartTransferContraParticipantEntryTransferInstancesTable = ({
  contraParticipantEntryId,
}: SmartTransferContraParticipantEntryTransferInstancesTableProps) => {
  const { data, loading, fetchMore } =
    useSmartTransferContraParticipantEntryInstancesPageQuery({
      variables: {
        smartTransferContraParticipantEntryId: contraParticipantEntryId,
        first: 15,
      },
    });

  if (loading) {
    return <Spinner />;
  }

  const participantEntry =
    data?.node?.__typename === 'SmartTransferContraParticipantEntry'
      ? data?.node
      : null;
  if (!participantEntry || !participantEntry.instances) {
    return null;
  }

  const pageInfo = participantEntry.instances.pageInfo;

  const instanceNodes = toArray(participantEntry.instances.edges)
    .map((edge) => edge?.node)
    .filter(Boolean) as ParticipantEntryInstanceNode[];

  const handleClickLoadMore = (): void => {
    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  };

  return (
    <>
      <GridTable
        emptyMessage="No transfers found."
        gridTemplateColumns="140px auto 140px"
      >
        <GridTable.HeaderRow sticky={false}>
          <GridTable.HeaderCell label="Date" />
          <GridTable.HeaderCell label="To" />
          <GridTable.HeaderCell justifySelf="end" label="Amount" />
        </GridTable.HeaderRow>

        {instanceNodes.map((instance) => (
          <GridTable.NavigableRow
            key={instance.id}
            params={{ transferInstanceId: instance.id }}
            to="/d/c/transfer-details/:transferInstanceId"
          >
            <GridTable.Cell>
              {moment(instance.startDate).format('ll')}
            </GridTable.Cell>
            <GridTable.Cell>
              {instance.to ? (
                <TransferParticipantCell transferParticipant={instance.to} />
              ) : (
                '--'
              )}
            </GridTable.Cell>
            <GridTable.Cell textAlign="right">
              <Flex>
                <TransferInstanceStatusPill
                  transferInstance={instance as TransferInstanceNodeFragment}
                />
                <TransferInstanceAmountText
                  emphasizeFailureState
                  ml={8}
                  transferInstance={instance as TransferInstanceNodeFragment}
                />
              </Flex>
            </GridTable.Cell>
          </GridTable.NavigableRow>
        ))}
      </GridTable>

      {pageInfo.hasNextPage && (
        <Flex justifyContent="center" mt={16}>
          <Button
            label={loading ? 'Loading...' : 'Load more'}
            disabled={loading}
            kind="secondary"
            mb={32}
            onClick={handleClickLoadMore}
            size="small"
          />
        </Flex>
      )}
    </>
  );
};
