import {
  TwoColumn4by8,
  Skeleton,
  PXL,
  Flex,
  type SemanticColorNames,
  Box,
  styled,
  Card,
  Modal,
} from '@m1/liquid-react';
import * as React from 'react';

import type { MarginHealthFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { AppSkeletonButton } from '~/lens-toolbox/AppButton';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';
import { LinkableSkeletonLink } from '~/lens-toolbox/LinkableLink';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Speedometer } from '~/toolbox/Speedometer/Speedometer';

import { MarginHealthDetailsModalContent } from './MarginHealthDetailsModal';
import { MarginListItem } from './MarginListItem';
import { TransactionsHistoryTabs } from './TransactionsHistoryTabs';

const ParagraphContainer = styled(Box)`
  height: 100%;
  margin-left: 24px;
  align-self: center;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    margin-left: 16px;
    margin-top: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    margin-left: 0px;
  }
`;

export const MarginLandingPageRightColumn = ({
  health,
  investAccountId,
}: {
  health: Maybe<MarginHealthFragment>;
  investAccountId: Maybe<string>;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const borrowAccountId = useSelector((state) => state.global.borrowAccountId);
  const [isCreditStatusDetailsModalOpen, setIsCreditStatusDetailsModalOpen] =
    React.useState(false);
  return (
    <TwoColumn4by8.Column2>
      <Card p={16} mb={32}>
        <Skeleton skeletonWidth="25%" mb={16} width="fit-content">
          <PXL fontWeight={300}>{health?.title}</PXL>
        </Skeleton>
        <InformationBanner
          appBanner={health?.banner}
          mb={16}
          position="relative"
          contain={false}
          onClick={(action) => {
            if (action === 'MARGIN_CALL') {
              navigate({
                to: '/d/c/resolve-call',
                query: {
                  borrowAccountId: borrowAccountId as string,
                  previousRouteName: '/d/borrow/margin',
                },
              });
            } else if (action === 'MARGIN_VIEW_DETAILS') {
              setIsCreditStatusDetailsModalOpen(true);
            }
          }}
        />
        <Flex flexWrap="wrap" justifyContent="center">
          <Skeleton
            minWidth={200}
            width={360}
            maxWidth="50%"
            ml={32}
            mr={24}
            height="fit-content"
            skeletonWidth={360}
            overflow="hidden"
          >
            <Speedometer
              value={
                typeof health?.currentScore?.numericValue === 'number'
                  ? Math.round(health.currentScore.numericValue * 100)
                  : undefined
              }
              valueColor={
                health?.currentScore?.foregroundColor as SemanticColorNames
              }
              plotBands={health?.thresholds?.map((threshold) => ({
                from: threshold.thresholdMin * 100,
                to: threshold.thresholdMax * 100,
                color: threshold.color,
                thickness: 20,
              }))}
            />
          </Skeleton>
          <ParagraphContainer>
            <Skeleton skeletonWidth="100%" skeletonHeight={72}>
              <PXL fontWeight={400} color="foregroundNeutralSecondary">
                {health?.breakdown?.map((breakdown, i) => (
                  <RichText
                    richText={breakdown}
                    key={i}
                    containerProps={{ display: 'inline' }}
                  />
                ))}
              </PXL>
            </Skeleton>
          </ParagraphContainer>
        </Flex>
        <Box mt={24}>
          <MarginListItem
            label={
              <AppSkeletonButton
                appButton={health?.viewHoldingsButton}
                kind="link"
                onClick={() => {
                  if (investAccountId) {
                    dispatch({
                      type: 'SET_ACTIVE_INVEST_ACCOUNT',
                      payload: investAccountId,
                    });
                  }
                  navigate({ to: '/d/invest/holdings' });
                }}
              />
            }
          />
          <MarginListItem
            label={
              <LinkableSkeletonLink
                font="PM"
                linkable={health?.learnMoreLink}
              />
            }
          />
        </Box>
      </Card>
      <TransactionsHistoryTabs />
      <Modal
        open={isCreditStatusDetailsModalOpen}
        onClose={() => setIsCreditStatusDetailsModalOpen(false)}
      >
        <MarginHealthDetailsModalContent />
      </Modal>
    </TwoColumn4by8.Column2>
  );
};
