import { Box, PS, Tooltip, type TooltipProps } from '@m1/liquid-react';
import * as React from 'react';

type SecurityMarginPopupProps = {
  icon: TooltipProps['icon'];
  iconColor: TooltipProps['iconColor'];
  text: string | null | undefined;
};
export const SecurityMarginPopup = ({
  icon,
  text,
}: SecurityMarginPopupProps) => {
  return (
    <Tooltip
      placement="bottom-end"
      icon={icon}
      body={
        <Box px={16} py={12}>
          <PS content={text} />
        </Box>
      }
    />
  );
};
