import { Button, Box, Flex, PM, PXL } from '@m1/liquid-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import type { DocumentRequirementsQuery } from '~/graphql/types';
import { useToast } from '~/toasts';

import type { DocumentGenericForm } from '../types';

import { DocumentPreview } from './DocumentPreview';

type DocumentRequirements = NonNullable<
  NonNullable<
    DocumentRequirementsQuery['viewer']['documentUploadRequestsCenter']
  >['documentRequirements']
>;

export const DocumentUploadGenericForm = ({
  documentRequirements,
}: {
  documentRequirements: DocumentRequirements;
}) => {
  const {
    maximumFileSizeDescription,
    maximumFileSizeInBytes,
    acceptableFileTypes,
    acceptableMimeTypes,
  } = documentRequirements;
  const { watch, setValue, register } = useFormContext<DocumentGenericForm>();
  register('selectedFile', { required: true });

  const { addToast, clearToast } = useToast();
  const fileInputEl = React.useRef<HTMLInputElement | null>(null);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const selectedFile = watch('selectedFile');

  const handleSelectClick = (): void => {
    fileInputEl.current?.click();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.currentTarget?.files ?? [];

    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.size > maximumFileSizeInBytes) {
      addToast({
        content: `The file you uploaded exceeds ${maximumFileSizeDescription}. Please try again.`,
        kind: 'alert',
      });
    } else if (!acceptableMimeTypes.includes(file.type)) {
      addToast({
        content: 'The file type is not supported. Please try again.',
        kind: 'alert',
      });
    } else {
      clearToast();
      setValue('selectedFile', file, { shouldValidate: true });
    }
  };

  const handleRemoveImage = (): void => {
    setValue('selectedFile', null, { shouldValidate: true });
  };
  const formattedFileTypes = acceptableFileTypes
    .map((file) => file.toUpperCase())
    .join(', ');

  return (
    <Box
      backgroundColor="backgroundNeutralMain"
      mt={40}
      py={32}
      padding="32px 48px"
      alignSelf="center"
      borderRadius={8}
    >
      <PXL fontWeight={600} content="Please select a file to upload." />
      <Flex flexDirection="row" mt={32}>
        <Flex flexDirection="column">
          {selectedFile && (
            <Box pt={16}>
              <DocumentPreview
                imagePreview={URL.createObjectURL(selectedFile)}
                selectedFile={selectedFile}
                handleClickedRemove={() => setIsModalOpen(true)}
                handleRemoveImage={handleRemoveImage}
                closeModal={() => setIsModalOpen(false)}
                isModalOpen={isModalOpen}
              />
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex>
        {/* input is hidden so that we can use a styled button to trigger the file selection instead of generic file upload button */}
        <input
          type="file"
          id="selectedFile"
          name="selectedFile"
          data-testid="file-input"
          style={{
            display: 'none',
          }}
          ref={fileInputEl}
          onChange={handleInputChange}
          accept={(acceptableMimeTypes ?? []).join(',')}
        />
        <Button
          label="Select File"
          onClick={handleSelectClick}
          mt={32}
          mx="auto"
          kind="secondary"
          mb={12}
        />
      </Flex>
      <Flex justifyContent="center" pt={16}>
        <PM fontWeight={600} content="Valid file types:" mr={4} />
        <PM content={` ${formattedFileTypes}`} />
      </Flex>

      <PM
        fontWeight={600}
        content={`Max file size: ${maximumFileSizeDescription}`}
        textAlign="center"
      />
    </Box>
  );
};
