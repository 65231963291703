import React from 'react';
import { useFormContext } from 'react-hook-form';

import type { RecurrenceScheduleInput } from '~/graphql/types';
import { WeeklyScheduleDayEnumDropdownOptions } from '~/static-constants/dropdown-options';

import { ControlledDropdown } from '../../ControlledDropdown';

const WeeklyScheduleWithoutWeekendsEnum =
  WeeklyScheduleDayEnumDropdownOptions.slice(1, 6);

type ValidNames =
  | 'schedule.biweekly.dayOfWeek'
  | 'schedule.weekly.dayOfWeek'
  | 'schedule.weekOfMonth.dayOfWeek';

type DayOfWeekDropdownProps = {
  name: ValidNames;
};

export const DayOfWeekDropdown = ({ name }: DayOfWeekDropdownProps) => {
  const { control } = useFormContext<{
    schedule: RecurrenceScheduleInput;
  }>();

  return (
    <ControlledDropdown
      control={control}
      rules={{
        required: 'Required',
      }}
      name={name}
      label="Day of week"
      placeholder="Select"
      source={WeeklyScheduleWithoutWeekendsEnum}
      style={{
        marginTop: 16,
      }}
    />
  );
};
