import { Button, HM, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import type { AppInformationalMessageCardFragment } from '~/graphql/types';
import { AppInformationalMessageCard } from '~/lens-toolbox/AppInformationalMessageCard';
import type { DropdownOption } from '~/toolbox/Dropdown';

import { AspectRatioFrame } from '../../components/AspectRatioFrame';
import { Cell, Divider, Grid, Stack } from '../../components/Layout';

import {
  AutoPayEnrollmentForm,
  type AutoPayEnrollmentFormSubmitHandler,
} from './AutoPayEnrollmentForm';

export interface AutoPayEnrollmentScreenProps {
  title: string;
  subtitle: string;
  accounts: DropdownOption[];
  initialAccount?: string;
  autoPayInformationalCard: AppInformationalMessageCardFragment;
  onSubmit: AutoPayEnrollmentFormSubmitHandler;
  onSkip: () => void;
}

export const AutoPayEnrollmentScreen: React.FC<
  AutoPayEnrollmentScreenProps
> = ({
  title,
  subtitle,
  accounts,
  initialAccount,
  autoPayInformationalCard,
  onSubmit,
  onSkip,
}) => (
  <Grid>
    <AspectRatioFrame
      ratio="4:3"
      css="grid-column: 6 / span 4; margin-block: auto;"
    >
      <Illustration name="autoPayToPersonalLoanWide" />
    </AspectRatioFrame>
    <Stack column="5 / span 6">
      <Cell vertical css="gap: 8px;">
        <HM>{title}</HM>
        <PL>{subtitle}</PL>
        <AutoPayEnrollmentForm
          accounts={accounts}
          initialValues={
            initialAccount
              ? { transferParticipantId: initialAccount }
              : undefined
          }
          onSubmit={onSubmit}
        />
        <AppInformationalMessageCard
          appInformationalMessageCard={autoPayInformationalCard}
          backgroundColor="backgroundNeutralMain"
        />
      </Cell>
    </Stack>
    <Stack column="5 / span 6" css="gap: 24px;">
      <Divider />
      <Cell css="gap: 24px;">
        <Button
          label="Turn on AutoPay"
          size="large"
          type="submit"
          form="autoPayEnrollmentForm"
        />
        <Button label="Skip" size="large" kind="text" onClick={onSkip} />
      </Cell>
    </Stack>
  </Grid>
);
