import { Box } from '@m1/liquid-react';
import range from 'lodash-es/range';
import React from 'react';

import { StyledFilters, Filter } from '~/components/research/Screener';
import { useFundCategoriesQuery } from '~/graphql/hooks';

import { useFundScreenerResult } from './useFundScreenerResult';

const FUND_FILTER_LABELS = ['Categories'];

export const FundScreenerCategories = () => {
  const {
    updateScreenerQuery,
    queryResult: { variables },
  } = useFundScreenerResult();

  const categoriesArr = React.useMemo(() => {
    if (!variables?.filterCategory) {
      return [];
    }
    if (Array.isArray(variables.filterCategory)) {
      return variables.filterCategory;
    }

    return [variables.filterCategory];
  }, [variables]);

  const handleFilterSelection = (position: number, filter: string) => {
    const filters = [...categoriesArr.slice(0, position), filter];

    updateScreenerQuery({
      filterCategory: filters,
    });
  };

  return (
    <Box>
      <StyledFilters>
        {range(categoriesArr?.length + 1).map((n) => (
          <FundFilter
            key={n}
            categories={categoriesArr?.slice(0, n)}
            onChangeFilter={(filter: string) =>
              handleFilterSelection(n, filter)
            }
            selected={categoriesArr[n]}
            {...(FUND_FILTER_LABELS[n] && { label: FUND_FILTER_LABELS[n] })}
          />
        ))}
      </StyledFilters>
    </Box>
  );
};

type FundFilterProps = {
  categories: string[];
  onChangeFilter: (filter: string) => void;
  selected: string;
  label?: string;
};

const FundFilter = ({
  categories,
  onChangeFilter,
  selected,
  ...rest
}: FundFilterProps) => {
  const { data } = useFundCategoriesQuery({
    variables: {
      filterCategory: categories,
    },
  });

  const subCategories = data?.viewer.securities.subCategories;
  if (!subCategories?.length) {
    return null;
  }

  return (
    <Filter
      categories={subCategories}
      onChangeFilter={onChangeFilter}
      selected={selected}
      {...rest}
    />
  );
};
