import {
  Box,
  Flex,
  type LayoutableProps,
  PL,
  PS,
  PXL,
  styled,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { SmartTransferRuleActivityFragment } from '~/graphql/types';
import { formatCurrency } from '~/utils/formatting';

import { NavigableCard } from '../cards/navigable-card';

type SmartTransferRuleActivityProps = LayoutableProps & {
  smartTransferRule: SmartTransferRuleActivityFragment;
};

const StyledCardsBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.borderMain};
  border-radius: 10px;
  overflow: hidden;
`;

const StyledNavigableCard = styled(NavigableCard)`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.borderMain};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundPrimarySubtle};
  }
`;

export const SmartTransferRuleActivity = ({
  smartTransferRule,
  ...rest
}: SmartTransferRuleActivityProps) => {
  const entries = smartTransferRule.contraParticipantEntries?.filter(Boolean);
  if (!entries || entries.length === 0) {
    // Empty state?
    return null;
  }
  return (
    <Box {...rest}>
      <PL color="foregroundNeutralSecondary" content="Transfer activity" />
      <StyledCardsBox mt={4}>
        {entries.map(
          (entry) =>
            entry && (
              <StyledNavigableCard
                borderColor="transparent"
                borderRadius={0}
                key={entry.id}
                query={{
                  smartTransferRuleId: smartTransferRule.id,
                }}
                to="/d/c/smart-transfer-instances/:smartTransferContraParticipantEntryId"
                params={{
                  smartTransferContraParticipantEntryId: entry.id,
                }}
              >
                <Box>
                  <PXL
                    fontWeight={600}
                    color="foregroundNeutralMain"
                    content={entry.title}
                    mb={4}
                  />

                  {entry.transferCountDescription && (
                    <PS
                      color="foregroundNeutralSecondary"
                      content={entry.transferCountDescription}
                    />
                  )}
                </Box>
                <Flex alignItems="baseline">
                  {typeof entry.totalTransferAmount === 'number' && (
                    <PXL
                      fontWeight={600}
                      color="foregroundNeutralMain"
                      content={formatCurrency(
                        entry.totalTransferAmount,
                        'SELECTIVE',
                      )}
                      mr={8}
                    />
                  )}
                  <Icon
                    name="caretRight16"
                    color="foregroundNeutralSecondary"
                  />
                </Flex>
              </StyledNavigableCard>
            ),
        )}
      </StyledCardsBox>
    </Box>
  );
};
