import {
  type AddSlicesToPortfolioEditorAction,
  type ClickedMoveSlicesDestinationAction,
  type CloseEditorConfirmationDialogAction,
  type FetchPieDataForPortfolioEditorAction,
  type FetchedPieDataForPortfolioEditorAction,
  type FetchedPieSliceablesForPortfolioEditorAction,
  type MoveSlicesAction,
  type OpenEditorConfirmationDialogAction,
  PORTFOLIO_EDITOR_ACTIONS,
  type SavePortfolioEditorAction,
  type UpdatePieDescriptionForPortfolioEditorAction,
  type UpdatePieNameForPortfolioEditorAction,
} from './portfolioEditorActions.types';

export const openEditorConfirmationDialog = (
  payload: OpenEditorConfirmationDialogAction['payload'],
): OpenEditorConfirmationDialogAction => ({
  payload,
  type: PORTFOLIO_EDITOR_ACTIONS.OPEN_EDITOR_CONFIRMATION_DIALOG,
});

export const closeEditorConfirmationDialog = (
  payload: CloseEditorConfirmationDialogAction['payload'],
): CloseEditorConfirmationDialogAction => ({
  payload,
  type: PORTFOLIO_EDITOR_ACTIONS.CLOSE_EDITOR_CONFIRMATION_DIALOG,
});

export const addSlicesToPortfolioEditorAction = (
  payload: AddSlicesToPortfolioEditorAction['payload'],
): AddSlicesToPortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.ADD_SLICES_TO_PORTFOLIO_EDITOR,
  payload,
});

export const clickedMoveSlicesDestination = (
  payload: ClickedMoveSlicesDestinationAction['payload'],
): ClickedMoveSlicesDestinationAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.CLICKED_MOVE_SLICES_DESTINATION,
  payload,
});

export const fetchPieDataForPortfolioEditor = (
  payload: FetchPieDataForPortfolioEditorAction['payload'],
): FetchPieDataForPortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.FETCH_PIE_DATA_FOR_PORTFOLIO_EDITOR,
  payload,
});

export const fetchedPieDataForPortfolioEditor = (
  payload: FetchedPieDataForPortfolioEditorAction['payload'],
): FetchedPieDataForPortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.FETCHED_PIE_DATA_FOR_PORTFOLIO_EDITOR,
  payload,
});

export const fetchedPieSliceablesForPortfolioEditor = (
  payload: FetchedPieSliceablesForPortfolioEditorAction['payload'],
): FetchedPieSliceablesForPortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.FETCHED_PIE_SLICEABLES_FOR_PORTFOLIO_EDITOR,
  payload,
});

export const savePortfolioEditor = (
  payload: SavePortfolioEditorAction['payload'],
): SavePortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.SAVE_PORTFOLIO_EDITOR,
  payload,
});

export const updatePieNameForPortfolioEditor = (
  payload: UpdatePieNameForPortfolioEditorAction['payload'],
): UpdatePieNameForPortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.UPDATE_PIE_NAME_FOR_PORTFOLIO_EDITOR,
  payload,
});

export const updatePieDescriptionForPortfolioEditor = (
  payload: UpdatePieDescriptionForPortfolioEditorAction['payload'],
): UpdatePieDescriptionForPortfolioEditorAction => ({
  type: PORTFOLIO_EDITOR_ACTIONS.UPDATE_PIE_DESCRIPTION_FOR_PORTFOLIO_EDITOR,
  payload,
});

export const moveSlices = (payload: MoveSlicesAction['payload']) => ({
  payload,
  type: PORTFOLIO_EDITOR_ACTIONS.MOVE_SLICES,
});
