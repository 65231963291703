import { ApolloLink } from '@apollo/client';
import { GraphQLError } from 'graphql';

import type { ErrorEnumUnion } from './types';

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong! If the problem persists, please contact support.';

type MutationPayload =
  | {
      didSucceed: boolean;
      __typename: keyof typeof mutationErrorMapping;
    }
  | {
      didSucceed: boolean;
      error: any;
      __typename: keyof typeof mutationErrorMapping;
    }
  | {
      result: {
        didSucceed: boolean;
        inputError: string;
        __typename: keyof typeof mutationErrorMapping;
      };
    }
  | {
      didSucceed: boolean;
      errorMessage: string;
      __typename: keyof typeof mutationErrorMapping;
    };

const getMutationError = (obj: MutationPayload) => {
  if (!('error' in obj) && !('result' in obj) && !('errorMessage' in obj)) {
    // If didSucceed: false but no error in the query, use default message.
    return { errorMessage: DEFAULT_ERROR_MESSAGE };
  }
  const errorResult =
    'error' in obj || 'errorMessage' in obj ? obj : obj.result;

  let errorCode = '';
  if ('error' in obj) {
    errorCode = obj.error;
  } else if ('errorMessage' in obj) {
    errorCode = obj.errorMessage;
  } else if ('result' in obj) {
    errorCode = obj.result.inputError;
  }

  // If the mutation response includes an errorMessage, use that.
  if (
    'errorMessage' in errorResult &&
    typeof errorResult.errorMessage === 'string'
  ) {
    return { errorCode, errorMessage: errorResult.errorMessage };
  }

  // Look up the error code in the mutation error mapping.
  // Fall back to the default error message if the error code is not mapped.
  const errorResultType = errorResult.__typename;
  let errorMapping = mutationErrorMapping[errorResultType];
  // SomeMutationNamePayload and SomeMutationNameResult should have identical error mappings.
  // If we can't find the mapping for one, try the other.
  // TODO: wire this up to our query types so we have mappings for the queries we actually use.
  if (!errorMapping && typeof errorResultType === 'string') {
    // TODO: figure out why this is not typed correctly.
    // We can cast it here because we just checked the type above.
    const errorResultTypeAsString: string = errorResultType;
    if (errorResultTypeAsString.endsWith('Result')) {
      errorMapping =
        mutationErrorMapping[
          errorResultTypeAsString.replace(
            /Result$/,
            'Payload',
          ) as keyof typeof mutationErrorMapping
        ];
    } else if (errorResultTypeAsString.endsWith('Payload')) {
      errorMapping =
        mutationErrorMapping[
          errorResultTypeAsString.replace(
            /Payload$/,
            'Result',
          ) as keyof typeof mutationErrorMapping
        ];
    }
  }

  const errorMessage = errorMapping?.[errorCode] ?? DEFAULT_ERROR_MESSAGE;

  return { errorMessage, errorCode };
};

const hasAuthError = (errors: readonly GraphQLError[] | undefined): boolean => {
  if (!errors) {
    return false;
  }
  return errors.some(
    (error) => 'isAuthError' in error && error.isAuthError === true,
  );
};

/**

  * This link throws a GraphQL error when a mutation does not succeed.

  * This error can then be used within the onError option of mutation hooks
  and can be referenced from the error result.  E.g.:

  @example
  const [doMutation, { error }] = useSomeMutation({
    variables: {
      input
    },
    onError: (e) => console.log('Error response here!')
  })

  console.log(error) // <- Error mapping here as well.

*/
export const mutationErrorApolloLink = new ApolloLink((operation, forward) => {
  if (
    operation.query.definitions.some(
      (op) => op.kind === 'OperationDefinition' && op.operation === 'mutation',
    )
  ) {
    return forward(operation).map((data) => {
      // Mutation Error
      if (data.errors || !data.data) {
        // For auth errors, forward along the original response.
        // We can try to reauthenticate and retry the mutation.
        if (hasAuthError(data.errors)) {
          return data;
        }

        // For other mutation errors, default a generic error message.
        // We don't want to show raw errors to users.
        return {
          ...data,
          errors: [new GraphQLError(DEFAULT_ERROR_MESSAGE)],
        };
      }

      const mutationPayload: MutationPayload = Object.values(data.data)[0];

      const didSucceed =
        'didSucceed' in mutationPayload
          ? mutationPayload.didSucceed
          : mutationPayload.result.didSucceed;

      if (didSucceed) {
        return data;
      }

      // Mutation Failure

      // For mutation failures, map the error code to an error message.
      const { errorMessage, errorCode } = getMutationError(mutationPayload);

      return {
        ...data,
        errors: [
          // https://www.apollographql.com/docs/apollo-server/data/errors/#custom-errors
          // Create a custom error to be used in the UI based on Apollo's recommended custom format.
          new GraphQLError(errorMessage, {
            extensions: {
              code: errorCode,
            },
          }),
        ],
      };
    });
  }
  return forward(operation);
});

const retirementError = (accountName: string) =>
  `M1 supports one ${accountName} per user. To open an account for another individual, have them create a new M1 login with their personal information.`;

/**
 * This mapping represents the enum values returned from an error enum on a mutation payload/result.
 * If you are not able to add a new payload, ensure you run yarn generate-schema to retrieve the latest schema.
 */
export const mutationErrorMapping: ErrorEnumUnion = {
  AutoPayApplicationSetUpPayload: {
    GENERAL_SETUP_AUTOPAY_ERROR:
      'There was an error setting up AutoPay. Please try again once the funds reach your account.',
  },
  AcceptCreditCardOfferPayload: {
    USER_NOT_ELIGIBLE: DEFAULT_ERROR_MESSAGE,
    CREDIT_CARD_NOT_ENABLED_FOR_USER: DEFAULT_ERROR_MESSAGE,
    NO_APPLICATIONS_FOR_USER: DEFAULT_ERROR_MESSAGE,
    NO_ACTIVE_CREDIT_CARD_APPLICATION_FOR_USER: DEFAULT_ERROR_MESSAGE,
    NO_ACTIVE_CREDIT_CARD_OFFER_FOR_USER: DEFAULT_ERROR_MESSAGE,
    GENERAL_ACCEPT_OFFER_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  AcceptPersonalLoanPayload: {
    GENERIC_ACCEPT_LOAN_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  AcceptPersonalLoanOfferPayload: {
    GENERIC_ACCEPT_OFFER_ERROR: DEFAULT_ERROR_MESSAGE,
    REJECTED: DEFAULT_ERROR_MESSAGE,
    REJECTED_CREDIT_FROZEN: DEFAULT_ERROR_MESSAGE,
    COULD_NOT_FIND_LOAN_APPLICATION: DEFAULT_ERROR_MESSAGE,
    COULD_NOT_FIND_LOAN_ID: DEFAULT_ERROR_MESSAGE,
  },
  ActivateCreditCardPayload: {
    LAST_FOUR_MISMATCH:
      'The last four digits you entered does not match the last four digits on the card.',
    CARD_ALREADY_ACTIVATED:
      'Your card is unable to be verified at this time. Please contact our support team if you have questions.',
  },
  ActivatePlusTrialPayload: {
    ALREADY_PLUS_MEMBER:
      'You are currently enrolled in an active M1 Plus membership.',
    INELIGIBLE_FOR_TRIAL: 'You are not eligible for this offer at this time.',
  },
  ArchivePieResult: {
    HAS_UNARCHIVED_ANCESTORS:
      'This pie cannot be deleted, as it is currently in use by another pie. You will need to remove it from the referencing pie or delete the referencing pie to continue.',
  },
  ArchiveSpendExternalAccountPayload: {
    UNABLE_TO_DEACTIVATE_EXTERNAL_ACCOUNT_PENDING_ACTION:
      'Your bank account cannot be removed while there are pending transfers.',
  },
  ArchiveTransferRulePayload: {
    NOT_ARCHIVABLE: 'This schedule cannot be archived right now.',
  },
  AuthenticateResult: {
    INCORRECT_CREDENTIALS: 'Incorrect email or password. Please try again.',
    INCORRECT_CODE: 'The code provided is not correct. Please try again.',
    USER_ACCOUNT_LOCKED:
      'Your account is currently locked. Please contact support.',
  },
  CancelAchRelationshipResult: {
    HAS_PENDING_TRANSFERS:
      'Your bank account cannot be removed while there are pending transfers.',
  },
  CancelTransferInstancePayload: {
    NOT_CANCELABLE: 'This transfer cannot be canceled right now.',
  },
  CompletePhoneVerificationPayload: {
    NO_PENDING_VERIFICATION:
      'Your phone number is not pending verification. Please try again.',
    INCORRECT_CODE: 'The confirmation code is incorrect.',
    PHONE_ALREADY_USED:
      'Your phone number is already in use and cannot be verified a second time.',
    TOO_MANY_ATTEMPTS:
      'Too many verification attempts. Please try again later.',
  },
  CompleteSpendEnrollmentPayload: {
    BLOCKED_BY_PENDING_TRANSFER:
      'Pending transfers must complete before enrollment. Please cancel any pending transfers or wait for them to complete, then try again.',
  },
  ConfirmAchRelationshipViaDepositsResult: {
    INCORRECT_DEPOSIT_AMOUNTS:
      'The deposit amounts provided are incorrect. Please try again.',
    INVESTMENT_ACCOUNT_NOT_OPEN:
      'Please finish opening an account to confirm your micro-deposits.',
  },
  ContinueCreditCardApplicationAfterUserCreditIsUnfrozenPayload: {
    USER_NOT_ELIGIBLE: DEFAULT_ERROR_MESSAGE,
    CREDIT_CARD_NOT_ENABLED_FOR_USER: DEFAULT_ERROR_MESSAGE,
    NO_CREDIT_CARD_APPLICATIONS_FOR_USER: DEFAULT_ERROR_MESSAGE,
    APPLICATION_NOT_IN_UNFREEZE_CREDIT_BUREAU_STATUS: DEFAULT_ERROR_MESSAGE,
    GENERAL_UNFREEZE_CREDIT_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  CreateAccountDocumentLinkPayload: {
    // Typo in enum in Lens
    DOCUMENT_NOT_AVAILBLE:
      'This document is not available right now. Please try again later.',
  },
  CreateAchRelationshipViaDepositsResult: {
    UNALLOWED_BANK: 'The bank you selected is not supported.',
  },
  CreateAchRelationshipViaFundingSourcePayload: {
    UNALLOWED_BANK: 'The bank you selected is not supported.',
  },
  CreateAchRelationshipViaLinkResult: {
    UNALLOWED_BANK: 'The bank you selected is not supported.',
  },
  CreateFundingSourceRelationshipPayload: {
    INVALID_TRANSFER_PARTICIPANT:
      'We couldn’t add your new bank. Try again or contact Client Support.',
    INVALID_FUNDING_SOURCE:
      'We couldn’t add your new bank. Try again or contact Client Support.',
    ACCOUNT_SWITCH_LIMIT_EXCEEDED:
      'We can’t change your bank right now, please contact Client Support.',
    BLOCKED_BY_PENDING_TRANSFER:
      'Pending transfers must complete before you can change your bank, then you can try again.',
    BLOCKED_ROUTING_NUMBER:
      'We couldn’t add your new bank. Try again or contact Client Support.',
  },
  CreateIraTransferResult: {
    PARTICIPANTS_INVALID: 'The selected accounts are not a valid pair.',
    PARTICIPANTS_FROZEN: 'One or more of the selected accounts are frozen.',
    PARTICIPANTS_DAILY_LIMIT_EXCEEDED:
      'You have exceeded the daily transfer limit.',
    AMOUNT_EXCEEDS_IRA_CONTRIBUTION_LIMIT:
      'Amount would place this IRA over its yearly contribution limit.',
    AMOUNT_LESS_THAN_TRANSFER_MINIMUM: 'Amount is too low.',
    AMOUNT_GREATER_THAN_TRANSFER_MAXIMUM: 'Amount is too high.',
    AMOUNT_GREATER_THAN_AVAILABLE_BALANCE:
      'Amount is greater than your available balance.',
    AMOUNT_LESS_THAN_INVEST_INITIAL_DEPOSIT:
      'Amount is below the minimum initial deposit.',
    AMOUNT_REQUIRES_MARGIN_PAY_BACK:
      'You must first pay back borrowed funds before withdrawing this amount.',
    AMOUNT_REQUIRES_INVEST_LIQUIDATION:
      'The amount you requested requires us to liquidate the account.',
    INVALID_IRA_DISTRIBUTION_REASON:
      'Please select a valid distribution reason.',
    INSUFFICIENT_MARGIN_BALANCE:
      'You need to have enough money in this account to cover any money you may have borrowed from this account, related interest, as well as any pending withdrawals. The amount you’re trying to withdraw right now exceeds that amount, so reduce it to continue.',
    IRA_CONTRIBUTION_YEAR_REQUIRED: 'Please select a contribution year',
    IRA_CONTRIBUTION_DISALLOWED_BY_AGE:
      "IRA contributions not allowed due to the owner's age.",
    IRA_DISTRIBUTION_REASON_REQUIRED: 'Please select a distribution reason',
    IRA_DISTRIBUTION_REASON_MUST_BE_PREMATURE:
      'Your IRA distribution reason must be "Premature"',
    TOO_MANY_TRANSFERS:
      'You already have a pending transfer for this Invest account. Please wait until it completes before creating another.',
    DUPLICATE_AUTOMATED_TRANSFER:
      'Your transfer has already been submitted. Thanks!',
  },
  CreatePieShareUrlResult: {
    INELIGIBLE_FOR_SHARING: 'The pie is currently too complex to be shared.',
    REMOVE_BEFORE_SHARING:
      'This pie contains inactive or delisted securities and cannot be shared. Remove/delete these securities from your pie before sharing.',
  },
  CreateProfilePayload: {
    DUPLICATE_PROFILE:
      'It looks like you already have an account with us. Log out and try logging in with another email. If that doesn’t work, contact Client Success.',
  },
  CreateSpendAccountDocumentLinkPayload: {
    DOCUMENT_NOT_AVAILBLE:
      'This document is not available right now. Please try again later.',
  },
  CreateTransferInstancePayload: {
    PARTICIPANTS_INVALID: 'The selected accounts are not a valid pair.',
    PARTICIPANTS_FROZEN: 'One or more of the selected accounts are frozen.',
    PARTICIPANTS_DAILY_LIMIT_EXCEEDED:
      'You have exceeded the daily transfer limit.',
    AMOUNT_LESS_THAN_TRANSFER_MINIMUM: 'Amount is too low.',
    AMOUNT_GREATER_THAN_TRANSFER_MAXIMUM: 'Amount is too high.',
    AMOUNT_GREATER_THAN_AVAILABLE_BALANCE:
      'Amount is greater than your available balance.',
    AMOUNT_LESS_THAN_INVEST_INITIAL_DEPOSIT:
      'Amount is below the minimum initial deposit.',
    AMOUNT_REQUIRES_MARGIN_PAY_BACK:
      'You must first pay back borrowed funds before withdrawing this amount.',
    AMOUNT_REQUIRES_INVEST_LIQUIDATION:
      'The amount you requested requires us to liquidate the account.',
    INVALID_IRA_DISTRIBUTION_REASON:
      'Please select a valid distribution reason.',
    INSUFFICIENT_MARGIN_BALANCE:
      'You need to have enough money in this account to cover any money you may have borrowed from this account, related interest, as well as any pending withdrawals. The amount you’re trying to withdraw right now exceeds that amount, so reduce it to continue.',
    IRA_CONTRIBUTION_YEAR_REQUIRED: 'Please select a contribution year',
    IRA_CONTRIBUTION_DISALLOWED_BY_AGE:
      "IRA contributions not allowed due to the owner's age.",
    IRA_DISTRIBUTION_REASON_REQUIRED: 'Please select a distribution reason',
    IRA_DISTRIBUTION_REASON_MUST_BE_PREMATURE:
      'Your IRA distribution reason must be "Premature"',
    TOO_MANY_TRANSFERS:
      'You already have a pending transfer for this Invest account. Please wait until it completes before creating another.',
    AMOUNT_EXCEEDS_IRA_CONTRIBUTION_LIMIT:
      'Amount would place this IRA over its yearly contribution limit.',
    DUPLICATE_AUTOMATED_TRANSFER:
      'Your transfer has already been submitted. Thanks!',
  },
  EnableTwoFactorAuthPayload: {
    INCORRECT_CODE: 'The code provided is not correct. Please try again.',
  },
  EnableTwoFactorAuthResult: {
    INCORRECT_CODE: 'The code provided is not correct. Please try again.',
  },
  GeneratePresignedUrlPayload: {
    ERROR_GENERATING_PRESIGNED_URL: 'The upload has failed. Please try again.',
  },
  InitiatePhoneVerificationPayload: {
    INVALID_PHONE:
      'Your phone number is invalid. Check your input and try again.',
    DISALLOWED_PHONE: 'Your phone number is not eligible for verification.',
    PHONE_ALREADY_USED:
      'Your phone number is already in use and cannot be verified a second time.',
    PHONE_DOES_NOT_SUPPORT_SMS:
      'Your phone number does not support SMS. Please select the "call" option.',
    TOO_MANY_ATTEMPTS:
      'Too many verification attempts. Please try again later.',
  },
  InitiateResetPasswordPayload: {
    INVALID_EMAIL_ADDRESS: 'Invalid email address',
  },
  OpenAccountPayload: {
    EXCEEDS_NEW_ACCOUNT_RATE_LIMIT:
      'Sorry, you cannot request any more new accounts right now. Please try again after 24 hours.',
    EXCEEDS_MAX_ACCOUNTS:
      'Sorry, you have reached the maximum number of accounts.',
    HOME_ADDRESS_CANNOT_BE_PO_BOX:
      'Sorry, your home address cannot be a PO box. Please update your address and try again.',
    INVALID_HOME_ADDRESS: `We don't recognize your home address. Please verify and update this information to continue.`,
    NO_ADDITIONAL_ACCOUNTS_WHILE_SOME_REJECTED:
      'Please contact support to open another account.',
    NO_ADDITIONAL_ACCOUNTS_WHILE_SOME_NEW:
      'One of your new accounts is still pending. Please wait until it opens before creating another account.',
    PHONE_MUST_MATCH_VERIFICATION:
      'Please provide the same phone number that you used for verification and try again.',
    PRIMARY_HOLDER_NAME_TOO_LONG:
      "The primary account holder's name is too long. Please try again.",
    SSNS_MUST_NOT_MATCH:
      'The social security numbers of the primary and secondary account holders must not match.',
    DUPLICATE_IRA_ACCOUNT: retirementError('IRA'),
    DUPLICATE_ROTH_IRA_ACCOUNT: retirementError('Roth IRA'),
    DUPLICATE_SEP_IRA_ACCOUNT: retirementError('SEP IRA'),
    DUPLICATE_TRADITIONAL_IRA_ACCOUNT: retirementError('Traditional IRA'),
    DUPLICATE_INDIVIDUAL_ACCOUNT:
      'At this time, M1 only supports one individual account per person.',
    DUPLICATE_JOINT_ACCOUNT:
      'At this time, M1 only supports one joint account for the people on the account.',
  },
  OpenCustodialAccountPayload: {
    USER_DOES_NOT_HAVE_INDIVIDUAL_ACCOUNT:
      'Users cannot open a custodial account without having an individual, joint, trust, or retirement account first.',
    CUSTODIAL_BENEFICIARY_ALREADY_ASSOCIATED_TO_CUSTODIAL_ACCOUNT:
      'The beneficiary information entered is already associated to a Custodial Account.',
    COULD_NOT_FETCH_PRIMARY_PROFILE:
      "There was an issue fetching the custodian's user profile.",
    CUSTODIAL_BENEFICIARY_NAME_TOO_LONG:
      'The full legal name (first, plus middle if present, plus last, with separating spaces) of the custodial account beneficiary may not be longer than 30 characters.',
    INVALID_CUSTODIAL_BENEFICIARY_DOB:
      'The date of birth of the primary account holder is invalid. Account holders must be younger than 25 years old (but varies by state).',
    NO_ADDITIONAL_ACCOUNTS_WHILE_SOME_NEW:
      'No additional accounts may be opened while some existing accounts are in new status.',
    NO_ADDITIONAL_ACCOUNTS_WHILE_SOME_REJECTED:
      'No additional accounts may be opened while some existing accounts are in rejected status.',
    HOME_ADDRESS_CANNOT_BE_PO_BOX:
      "The custodial account beneficiary's legal residence may not be a P.O. box.",
    INVALID_LEGAL_RESIDENCE:
      'The provided legal residence for the custodial account beneficiary is invalid.',
    GENERAL_REJECTION:
      'The new account request is rejected for an unspecified reason.',
  },
  OpenHighYieldSavingsAccountMutationPayload: {
    BLOCKED_BY_PENDING_TRANSFER:
      'Pending transfers must complete before enrollment. Please cancel any pending transfers or wait for them to complete, then try again.',
    USER_NOT_ELIGIBLE:
      'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
    UNVERIFIED_EMAIL:
      'To continue, you need to confirm your email. Please check your inbox to verify.',
    USER_REJECTED:
      'We couldn’t confirm your identity. You can update any incorrect information below to try again, or contact Client Support.',
    TOO_MANY_INVITES:
      'Unfortunately your invitee already has a pending Joint Account invitation. Users can only have one Joint Account.',
  },
  RequestReferralsParticipationResult: {
    INELIGIBLE_FOR_PARTICIPATION:
      'You are not eligible for participation in our referrals program.',
  },
  ResubmitLoanAfterCreditFreezePayload: {
    GENERIC_RESUBMIT_AFTER_CREDIT_FREEZE_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  SendCheckPayload: {
    PARTICIPANTS_FROZEN: 'One or more of the selected accounts are frozen.',
    PARTICIPANTS_DAILY_LIMIT_EXCEEDED:
      'You have exceeded the daily transfer limit.',
    AMOUNT_LESS_THAN_TRANSFER_MINIMUM: 'Amount is too low.',
    AMOUNT_GREATER_THAN_TRANSFER_MAXIMUM: 'Amount is too high.',
    AMOUNT_GREATER_THAN_AVAILABLE_BALANCE:
      'Amount is greater than your available balance.',
    CONFLICTS_WITH_EXISTING_RULE:
      'A schedule already exists for the selected accounts. Please edit your existing schedule.',
    PHYSICAL_CHECK_MEMO_TOO_LONG: 'Memo is too long.',
    PHYSICAL_CHECK_ADDRESS_TOO_LONG: 'Address is too long.',
    PHYSICAL_CHECK_ADDRESS_COUNTRY_INVALID: 'Country must be USA.',
    PHYSICAL_CHECK_RECIPIENT_NAME_TOO_LONG: 'Check recipient name too long',
  },
  SetCreditCardWaitlistStatusPayload: {
    USER_NOT_ELIGIBLE:
      'User is not eligible to be added into the Credit Card waitlist.',
    GENERAL_SET_CREDIT_CARD_WAITLIST_STATUS_ERROR:
      'Something went wrong with the request. Please try again later or contact support.',
  },
  SetEnabledOnTransferRulePayload: {
    ENABLE_STATE_CHANGE_DISALLOWED: 'Unable to change schedule right now.',
  },
  SetLockOnCreditCardPayload: {
    CREDIT_CARD_NOT_ENABLED_FOR_USER: DEFAULT_ERROR_MESSAGE,
    USER_NOT_ELIGIBLE: DEFAULT_ERROR_MESSAGE,
    GENERAL_LOCK_CREDIT_CARD_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  SetMaxCashThresholdResult: {
    PENDING_SELL_ORDERS:
      'You have a pending sell order. It must be completed or canceled before you can change auto-invest settings.',
  },
  SetPortfolioSliceOrderPayload: {
    LOCKED_BY_REBALANCE:
      'There is a pending rebalance request for your portfolio. Please cancel all rebalance requests and try again.',
    LOCKED_BY_TRADING:
      'Trading is currently in progress. Please try again after the trade window.',
    LOCKED_BY_LIQUIDATION:
      'Your account is currently liquidating. Please cancel the liquidation and try again.',
    LESS_THAN_MINIMUM_BUY:
      'The buy order is less than the required minimum value. Please increase the amount and try again.',
    GREATER_THAN_MAXIMUM_SELL:
      'The sell order is greater than the current value. Please update the amount and try again.',
    CANNOT_SET_ORDER_FOR_INACTIVE_SLICEABLE:
      'This order includes an inactive security and cannot be traded. Please remove the inactive security from the Pie to create an order.',
    CANNOT_SET_BUY_ORDER_FOR_DELISTED_SLICEABLE:
      'This buy order includes a delisted or unsupported security and cannot be traded. Please remove the security from the Pie to create an order.',
  },
  SetScheduledTransferRulePayload: {
    AMOUNT_LESS_THAN_TRANSFER_MINIMUM: 'Amount is too low.',
    AMOUNT_GREATER_THAN_TRANSFER_MAXIMUM: 'Amount is too high.',
    AMOUNT_REQUIRES_INVEST_INITIAL_DEPOSIT:
      'Please create a transfer meeting the Invest account minimum first.',
    INVALID_IRA_DISTRIBUTION_REASON:
      'Please select a valid distribution reason.',
    IRA_CONTRIBUTION_YEAR_REQUIRED: 'Please select a contribution year.',
    IRA_CONTRIBUTION_DISALLOWED_BY_AGE:
      "IRA contributions not allowed due to the owner's age.",
    IRA_DISTRIBUTION_REASON_REQUIRED: 'Please select a distribution reason.',
    IRA_DISTRIBUTION_REASON_MUST_BE_PREMATURE:
      'Your IRA distribution reason must be "Premature"',
    CONFLICTS_WITH_EXISTING_RULE:
      'A schedule already exists for the selected accounts. Please edit your existing schedule.',
    TOO_MANY_SCHEDULED_TRANSFER_RULES:
      'You have reached the maximum number of schedules allowed.',
  },
  SetSmartTransferRulePayload: {
    NOT_ELIGIBLE: 'You are currently not eligible to create a Smart Transfer.',
    PARTICIPANTS_INVALID: 'One or more of the selected accounts is invalid.',
    CONFLICTS_WITH_EXISTING_RULE:
      'This Smart Transfer conflicts with an existing one.',
    BALANCE_TRIGGER_THRESHOLD_INVALID:
      'The selected balance to trigger the Smart Transfer is invalid.',
  },
  SubmitCreditCardApplicationPayload: {
    USER_NOT_ELIGIBLE: DEFAULT_ERROR_MESSAGE,
    REJECTED_APPLICATION_HOLD_PERIOD_NOT_COMPLETE: DEFAULT_ERROR_MESSAGE,
    CREDIT_CARD_NOT_ENABLED_FOR_USER: DEFAULT_ERROR_MESSAGE,
  },
  UpdateCreditCardRewardPayoutDestinationPayload: {
    CREDIT_CARD_NOT_ENABLED_FOR_USER: DEFAULT_ERROR_MESSAGE,
    USER_NOT_ELIGIBLE: DEFAULT_ERROR_MESSAGE,
    GENERAL_UPDATE_CREDIT_CARD_REWARD_PAYOUT_DESTINATION_ERROR:
      DEFAULT_ERROR_MESSAGE,
  },
  UpdateCustodialAccountBeneficiaryLegalAddressPayload: {
    NO_UPDATE_UNTIL_ACCEPTED:
      'Profile updates may not be made while the current profile information is unaccepted.',
    HOME_ADDRESS_CANNOT_BE_PO_BOX: 'The home address may not be a P.O. box.',
    INVALID_HOME_ADDRESS: 'The provided home address is invalid.',
    TEMPORARY_NEW_ACCOUNT_FREEZE:
      'Profile updates are temporarily frozen for a short period after a new account is opened. Try again later.',
  },
  UpdatePieTreeResult: {
    INVALID_TOTAL_PERCENTAGE:
      'Total slice percentage of all pies must equal 100%.',
    NO_SLICE_SELF_REFERENCE:
      'A Pie slice cannot point to the same Pie it belongs to, or any of its parent Pies.',
    SLICEABLE_NOT_ACTIVE:
      'Please remove inactive slices from your Pie to continue.',
    EXCEEDS_PIE_TREE_MAX_SLICES:
      'Your portfolio has reached the maximum number of securities allowed. Reduce the number of Slices and please try again.',
  },
  UpdateProfileResult: {
    INVALID_HOME_ADDRESS: `We don't recognize your home address. Please verify and update this information to continue.`,
    NO_UPDATE_UNTIL_ACCEPTED: `Please wait for pending updates to be approved before making changes to your profile.`,
    TEMPORARY_NEW_ACCOUNT_FREEZE: `Sorry, profile updates are temporarily disabled after opening a new account. Please try again later.`,
    BENEFICIARY_SSNS_MUST_BE_UNIQUE: `The social security numbers for each beneficiary must be unique.`,
    HOME_ADDRESS_CANNOT_BE_PO_BOX: 'The home address may not be a P.O. box.',
    INVALID_CONTINGENT_BENEFICIARY_SHARE:
      'The contingent beneficiary share must add up to 100%.',
    INVALID_PRIMARY_BENEFICIARY_SHARE:
      'The primary beneficiary share must add up to 100%.',
  },
  VerifySpendExternalAccountPayload: {
    EXTERNAL_ACCOUNT_IS_LOCKED:
      'Your bank is locked. Please contact support for assistance.',
    EXTERNAL_ACCOUNT_IS_DENIED:
      'Your bank was denied. Please contact support for assistance.',
    EXTERNAL_ACCOUNT_IS_EXPIRED:
      'Verification expired. Please contact support for assistance.',
    TOO_MANY_VERIFICATION_FAILURES:
      'Too many verification attempts. Please contact support for assistance.',
    DEPOSITS_INCORRECT:
      'The amounts provided were incorrect. Please try again.',
  },
  WithdrawPersonalLoanPayload: {
    GENERIC_PERSONAL_LOAN_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  WithdrawPersonalLoanApplicationPayload: {
    GENERIC_PERSONAL_LOAN_ERROR: DEFAULT_ERROR_MESSAGE,
  },
  ConfirmPlusMembershipPayload: {
    GENERIC_PLUS_CONFIRMATION_ERROR:
      'Something went wrong and we couldn’t update your preferences. Please try again or contact Client Success.',
    TRANSACTION_DECLINED:
      'The transaction was declined. Try again with different payment method information.',
  },
  SetPreferredPlatformPaymentMethodPayload: {
    'PREFERRED_ACCOUNT_UPDATE_FAILED':
      "Something went wrong and we couldn't upate your default billing method. Try again or contact Client Success for assistance.",
  },
};
