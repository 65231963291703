import { Box, PL } from '@m1/liquid-react';
import React from 'react';

import { Gain } from '~/components/gain';
import { PercentGain } from '~/components/percent-gain';
import type { WatchlistItemLatestQuoteFragment } from '~/graphql/types';

type WatchlistLatestQuoteChangeProps = {
  latestQuote: WatchlistItemLatestQuoteFragment;
};

export const WatchlistLatestQuoteChange = ({
  latestQuote,
}: WatchlistLatestQuoteChangeProps) => {
  const { changeFromPreviousClosePrice, percentChangeFromPreviousClosePrice } =
    latestQuote;

  return (
    <PL fontWeight={600}>
      {typeof changeFromPreviousClosePrice === 'number' ? (
        <>
          <Gain value={changeFromPreviousClosePrice} />
          <Box display="inline-block" width={8} />
          <PercentGain value={percentChangeFromPreviousClosePrice} />
        </>
      ) : (
        '--'
      )}
    </PL>
  );
};
