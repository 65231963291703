import { LM, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { PercentGain } from '~/components/percent-gain';
import type { HistoricalQuotePeriodEnum } from '~/graphql/types';

import { SecurityMiniChart } from '../SecurityMiniChart';

const Container = styled(Flex)`
  > * {
    padding: 0 8px;
    width: 100%;
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      min-width: 80px;
    }
  }
`;

type Props = {
  period: HistoricalQuotePeriodEnum;
  securityId: string;
  percentChange: number | null | undefined;
};

export const SecurityMiniChartAndReturn = ({
  securityId,
  period,
  percentChange,
}: Props) => {
  return (
    <Container alignItems="center">
      <SecurityMiniChart securityId={securityId} period={period} />
      <LM minWidth="80" content={<PercentGain value={percentChange} />} />
    </Container>
  );
};
