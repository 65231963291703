import { Box, Flex, PL, PS, spacingUnits } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { BillingMethodSectionFragment } from '~/graphql/types';

import { getParticipantIconName } from '../../covers/create-transfer/utils/lensMappers';

export const participantToDropdownOption = (
  participants: BillingMethodSectionFragment['billingMethods'],
) => {
  if (!participants?.list) {
    return [];
  }
  return participants?.list.map((participant) => ({
    name: participant.id,
    description: (
      <Flex alignItems="center" gap={spacingUnits.s}>
        <Box>
          <Icon
            name={getParticipantIconName(participant.transferParticipantType)}
            fallback="accountCheckingPrimary32"
          />
        </Box>
        {/* Not great setting a max width. But need to force following Ps to overflow/wrap. */}
        <Box maxWidth="330px">
          <PL overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {participant.transferParticipantName}
          </PL>
          <PS color="foregroundNeutralSecondary" whiteSpace="normal">
            {participant.transferParticipantDescription}
          </PS>
        </Box>
      </Flex>
    ),
  }));
};
