import React from 'react';

import type { HistoricalQuotePeriodEnum } from '~/graphql/types';

import { PERFORMANCE_PERIOD_OPTIONS } from '../constants';
import { PeriodSelectorTabs } from '../PeriodSelector/PeriodSelectorTabs';

import { StyledPerformanceHeaderCell } from './ScreenerTable.styled';

type ScreenerPriceHistoryProps = {
  handlePeriodChange: (period: HistoricalQuotePeriodEnum) => void;
};

export const ScreenerPriceHistory = ({
  handlePeriodChange,
}: ScreenerPriceHistoryProps) => {
  const [currentPeriod, setCurrentPeriod] =
    React.useState<HistoricalQuotePeriodEnum>('ONE_YEAR');

  const onPeriodClick = (period: HistoricalQuotePeriodEnum) => {
    setCurrentPeriod(period);
    handlePeriodChange(period);
  };

  return (
    <StyledPerformanceHeaderCell>
      <span>Price History</span>
      <PeriodSelectorTabs
        onClick={onPeriodClick}
        periods={PERFORMANCE_PERIOD_OPTIONS}
        size="tiny"
        value={currentPeriod}
      />
    </StyledPerformanceHeaderCell>
  );
};
