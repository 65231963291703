import { Flex, spacingUnits, styled } from '@m1/liquid-react';
import * as React from 'react';

import { AppButtons } from '~/components/AppButtons';
import type { FundingActionsFragment } from '~/graphql/types';
import { RichText } from '~/lens-toolbox/RichText';

const StyledFlexContainer = styled(Flex)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const AccountFundingIraActions = ({
  fundingActions,
}: FundingActionsFragment) => {
  return (
    <Flex flexDirection="column" mb={spacingUnits.xl}>
      <RichText richText={fundingActions?.title} />
      <StyledFlexContainer flexDirection="row" gap={160} mt={spacingUnits.m}>
        {fundingActions?.cards.map((card, i) => {
          return (
            <Flex flexDirection="column" gap={8} maxWidth={550} key={i}>
              <RichText richText={card?.title} />
              {card?.paragraphs?.map((paragraph, i) => {
                return <RichText key={i} richText={paragraph} />;
              })}
              <Flex maxWidth="240px">
                <AppButtons
                  button={card?.primaryCta}
                  linkButtonProps={{
                    kind: 'secondary',
                    mt: spacingUnits.xs,
                  }}
                />
              </Flex>
            </Flex>
          );
        })}
      </StyledFlexContainer>
    </Flex>
  );
};
