import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { type InjectedFormProps, FormSection } from 'redux-form';

import { connectForm } from '~/hocs';
import { Grid } from '~/toolbox/grid';

import { EmploymentFields } from '../fields';

type EmploymentFormProps = InjectedFormProps<any> & {
  dropdownOptions: ReadonlyArray<{
    label: string;
  }>;
  fullWidth?: boolean | null | undefined;
  hideSubmit?: boolean | null | undefined;
};

const EmploymentInfoFormComponent = ({
  handleSubmit,
  fullWidth,
  dropdownOptions,
  hideSubmit,
}: EmploymentFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col xs={fullWidth ? 12 : 6}>
          <FormSection name="employment">
            <EmploymentFields
              dropdownOptions={dropdownOptions}
              hideSlideAnimation
            />
          </FormSection>
        </Grid.Col>
      </Grid.Row>
      {!hideSubmit && (
        <Grid.Row xsTextRight>
          <Grid.Col xs={12}>
            <Button kind="primary" type="submit" size="medium" label="Save" />
          </Grid.Col>
        </Grid.Row>
      )}
    </form>
  );
};

export const EmploymentInfoForm = connectForm({
  form: 'employment-profile',
})(EmploymentInfoFormComponent);
