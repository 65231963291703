import React from 'react';

import { Grid } from '~/toolbox/grid';

import { CityInput } from './CityInput';
import { CountryInput } from './CountryInput';
import { LineOneInput } from './LineOneInput';
import { LineTwoInput } from './LineTwoInput';
import { PostalCodeInput } from './PostalCodeInput';
import { StateOrProvinceDropdown } from './StateOrProvinceDropdown';
import type { AddressFieldsProps } from './types';

export const AddressFieldsSection = <NS extends string = ''>({
  disabled,
  kind,
  backgroundColor,
  condensed,
  modernLayout,
  namespace,
}: AddressFieldsProps<NS>) => {
  if (modernLayout) {
    return (
      <Grid.Row>
        <Grid.Col xs={12}>
          <Grid.Row>
            <Grid.Col sm={condensed ? 12 : 8} xs={12}>
              <LineOneInput<NS>
                backgroundColor={backgroundColor}
                disabled={disabled}
                namespace={namespace}
              />
            </Grid.Col>
            <Grid.Col sm={condensed ? 12 : 4} xs={12}>
              <LineTwoInput<NS>
                backgroundColor={backgroundColor}
                disabled={disabled}
                namespace={namespace}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={condensed ? 12 : 8} xs={12}>
              <CityInput<NS>
                backgroundColor={backgroundColor}
                disabled={disabled}
                namespace={namespace}
              />
            </Grid.Col>
            <Grid.Col sm={condensed ? 12 : 4} xs={12}>
              <StateOrProvinceDropdown<NS>
                backgroundColor={backgroundColor}
                disabled={disabled}
                kind={kind}
                namespace={namespace}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={condensed ? 12 : 8} xs={12}>
              <PostalCodeInput<NS>
                backgroundColor={backgroundColor}
                disabled={disabled}
                namespace={namespace}
              />
            </Grid.Col>
            <CountryInput
              backgroundColor={backgroundColor}
              disabled={disabled}
              namespace={namespace}
            />
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    );
  }
  return (
    <Grid.Row>
      <Grid.Col xs={12}>
        <Grid.Row>
          <Grid.Col sm={condensed ? 12 : 8} xs={12}>
            <LineOneInput<NS>
              backgroundColor={backgroundColor}
              disabled={disabled}
              namespace={namespace}
            />
          </Grid.Col>
          <Grid.Col sm={condensed ? 12 : 4} xs={12}>
            <LineTwoInput<NS>
              backgroundColor={backgroundColor}
              disabled={disabled}
              namespace={namespace}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm={condensed ? 12 : 5} xs={12}>
            <CityInput<NS>
              backgroundColor={backgroundColor}
              disabled={disabled}
              namespace={namespace}
            />
          </Grid.Col>
          <Grid.Col sm={condensed ? 6 : 3} xs={12}>
            <StateOrProvinceDropdown<NS>
              backgroundColor={backgroundColor}
              disabled={disabled}
              kind={kind}
              namespace={namespace}
            />
          </Grid.Col>
          <Grid.Col sm={condensed ? 6 : 4} xs={12}>
            <Grid.Row>
              <Grid.Col xs={12}>
                <PostalCodeInput<NS>
                  backgroundColor={backgroundColor}
                  disabled={disabled}
                  namespace={namespace}
                />
              </Grid.Col>
              <CountryInput<NS>
                backgroundColor={backgroundColor}
                disabled={disabled}
                namespace={namespace}
              />
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};
