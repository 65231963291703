import { Box, HM, PM, PS, Skeleton, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { useTransferParticipantCellsQuery } from '~/graphql/hooks';
import type { TransferParticipantFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { GridTable } from '~/toolbox/grid-table';

import { Link } from '~/toolbox/link';

export const InvestDirectDespositAccounts = ({
  accountIds,
  onSelect,
}: {
  accountIds: string[];
  onSelect: (id: string) => void;
}) => {
  const navigate = useNavigate();
  const { data, loading } = useTransferParticipantCellsQuery({
    variables: {
      ids: accountIds,
    },
  });
  const nodes = data?.nodes as Maybe<Array<TransferParticipantFragment>>;

  return (
    <SkeletonProvider isLoading={loading}>
      <Box my={32}>
        <BackArrow
          content="Back"
          onClick={() => navigate({ to: '/d/move-money' })}
        />
      </Box>
      <HM mb={32}>
        Where would you like to deposit some or all of your paycheck?
      </HM>
      <PM mb={16}>
        Direct deposits are an easy way to efficiently build long-term wealth.
        You can enroll one individual Brokerage Account.
      </PM>
      <Link
        to="https://help.m1.com/en/articles/9332029-create-transfers-in-my-m1-account#h_01HXSFJTCF3CEZBD1V1Q8F8TJT"
        target="_blank"
        mb={32}
      >
        Learn more about direct deposits
      </Link>
      <PS color="foregroundNeutralSecondary">Invest</PS>
      <Skeleton skeletonWidth="100%" skeletonHeight={200}>
        {/* @ts-ignore it works */}
        <GridTable gridTemplateColumns="auto" pt={12} isNewStyle>
          {nodes?.map((node, id) => {
            return (
              <GridTable.NavigableRow
                key={id}
                onClick={() => onSelect(node.id)}
              >
                <GridTable.Cell>
                  <TransferParticipantCell
                    titleWeight={400}
                    includeDescription
                    transferParticipant={node}
                  />
                </GridTable.Cell>
              </GridTable.NavigableRow>
            );
          })}
        </GridTable>
      </Skeleton>
    </SkeletonProvider>
  );
};
