import { Box, Flex } from '@m1/liquid-react';
import * as React from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { useFilterBySymbolQuery } from '~/graphql/hooks';
import type {
  FilterBySymbolQueryVariables,
  SliceableTypeEnum,
} from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { FilterOrTogglePopup } from '~/toolbox/filter-or-toggle-popup';
import { SearchInput } from '~/toolbox/search-input';

import type { InvestActivityFormValues } from '../ActivityPage';

import { FilterBySymbolResult } from './FilterBySymbolResult';
import { SymbolTag } from './SymbolTag';

export const FilterBySymbol = () => {
  const {
    watch: filterWatch,
    setValue: setFilterValue,
    getValues,
  } = useFormContext<InvestActivityFormValues>();

  const isCrypto = useSelector<boolean>(
    (state) => state.global.activeAccountIsCrypto,
  );

  const filterTypes: SliceableTypeEnum[] = isCrypto
    ? ['CRYPTO']
    : ['EQUITY_SECURITY', 'FUND_SECURITY'];

  const { watch, setValue } = useForm<FilterBySymbolQueryVariables>({
    defaultValues: {
      query: '',
    },
  });

  const query = watch('query');

  const { data } = useFilterBySymbolQuery({
    variables: {
      filterTypes,
      first: 5,
      query,
    },
    skip: !query,
  });

  const handleClickResult = React.useCallback(
    (symbol: string) => {
      setValue('query', '');

      const symbols = getValues('symbols') ?? [];
      const newArr = [...symbols];

      const index = newArr.findIndex((currSymbol) => currSymbol === symbol);
      if (index > -1) {
        newArr.splice(index, 1);
      } else {
        newArr.push(symbol);
      }
      setFilterValue('symbols', newArr, { shouldDirty: true });
    },
    [setValue, setFilterValue, getValues],
  );

  const results =
    data?.viewer.searchSliceables.edges?.map((edge) => edge?.node) ?? [];

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('query', event.currentTarget.value);
  };

  const symbols = filterWatch('symbols') ?? [];
  const label =
    symbols.length > 0
      ? `${symbols[0].toUpperCase()}${
          symbols.length > 1 ? ` + ${symbols.length - 1}` : ''
        }`
      : 'Symbol';

  return (
    <FilterOrTogglePopup placement="bottom-end" label={label}>
      <Box minWidth={324} maxWidth={356}>
        <Box py={16} px={14}>
          <SearchInput
            placeholder={
              isCrypto ? 'Search by symbol' : 'Search by symbol or company name'
            }
            onChange={onFilterChange}
            value={query}
          />
          {symbols.length > 0 && (
            <Flex p="8px 0 0" flexWrap="wrap" justifyContent="flex-end">
              {symbols.map((s) => (
                <SymbolTag key={s} label={s} onClick={handleClickResult} />
              ))}
            </Flex>
          )}
        </Box>
        {results.map(
          (result) =>
            result &&
            'symbol' in result && (
              <FilterBySymbolResult
                key={result.id}
                onClick={handleClickResult}
                security={result}
              />
            ),
        )}
      </Box>
    </FilterOrTogglePopup>
  );
};
