import {
  Box,
  Button,
  Flex,
  PS,
  PM,
  Skeleton,
  SkeletonProvider,
  theme,
  Input,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { DiscoverSearch } from '~/components/research';
import { useAcatWizardSelectAssetsStepQuery } from '~/graphql/hooks';
import type {
  Sliceable_Equity_Fragment,
  Sliceable_Fund_Fragment,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { useToast } from '~/toasts';
import { Link } from '~/toolbox/link';
import { Logo } from '~/toolbox/logo';
import { normalizeCurrency } from '~/utils';

import { AcatWizardBackButton } from '../components/AcatWizardBackButton';
import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';
import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';

const SecuritiesHelper = (
  <PS color="foregroundNeutralSecondary">
    Don’t see your investment? First transfer those you can, then contact us.
    Learn more about{' '}
    <Link
      to="https://help.m1.com/en/articles/9332016-what-securities-can-i-transfer"
      target="_blank"
    >
      <PS fontWeight={600}>investments we support</PS>
    </Link>
    .
  </PS>
);

export function AcatWizardSelectAssetsStep() {
  const analytics = useAnalytics();
  const form = useAcatWizardFormContext();
  const { addToast } = useToast();

  React.useEffect(() => {
    analytics.pageView('m1_acat_wizard_partials_add_assets_screen_view');
  }, [analytics]);

  const { data, loading } = useAcatWizardSelectAssetsStepQuery({
    variables: {
      brokerageId: form.getValues('externalBrokerageId') as string,
    },
    skip: !form.getValues('externalBrokerageId'),
  });

  const content = data?.viewer.invest?.acatWizard?.assetSelectionContent;

  const assets = useFieldArray({
    control: form.control,
    name: 'assets',
  });

  const assetsValue = form.watch('assets');
  const canAdvanceToNextStep =
    (assetsValue.reduce(
      (totalShares, asset) => totalShares + asset.quantity,
      0,
    ) > 0 ||
      Boolean(form.getValues('cashAmount'))) &&
    form.formState.isValid;

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <AcatWizardStepContainer>
        <AcatWizardBackButton />
        <AcatWizardStepCard
          header={
            <Skeleton mb={40}>
              <RichText richText={content?.header} />
            </Skeleton>
          }
        >
          <Skeleton mb={8}>
            <RichText richText={content?.cash?.header} />
          </Skeleton>
          <Skeleton mb={8}>
            <RichText richText={content?.cash?.description} />
          </Skeleton>
          <Skeleton skeletonWidth="100%">
            <Controller
              control={form.control}
              defaultValue="0"
              name="cashAmount"
              render={({ field }) => (
                <Input
                  maskType="money"
                  label={content?.cash?.input?.label}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(normalizeCurrency(e.target.value));
                  }}
                  placeholder={content?.cash?.input?.placeholder ?? '$0'}
                  value={field.value ?? ''}
                  maxLength={11}
                />
              )}
            />
          </Skeleton>
          <Box
            border={`0.5px solid ${theme.colors.borderMain}`}
            height={0}
            margin="32px 0"
          />
          <Skeleton>
            <RichText
              richText={content?.assets?.header}
              containerProps={{ mb: 8 }}
            />
          </Skeleton>
          <Skeleton>
            <RichText
              richText={content?.assets?.description}
              containerProps={{ mb: 8 }}
            />
          </Skeleton>
          <Skeleton skeletonWidth="100%">
            <DiscoverSearch
              filterStatuses={['ACTIVE', 'DELISTED']}
              filterTypes={['EQUITY_SECURITY', 'FUND_SECURITY']}
              placeholder={content?.assets?.searchInput?.placeholder as string}
              staticResultCell={SecuritiesHelper}
              onResultClick={(selected) => {
                const { symbol, name, profile } = selected as
                  | Sliceable_Equity_Fragment
                  | Sliceable_Fund_Fragment;

                if (!assets.fields.some((v) => v.symbol === symbol)) {
                  assets.append({
                    name,
                    logoUrl: profile?.logoUrl as string,
                    quantity: 0,
                    symbol,
                    type: 'STOCK_OR_ETF',
                  });
                } else {
                  addToast({
                    content:
                      "You've already added this security and can adjust shares of that entry.",
                    kind: 'warning',
                  });
                }
              }}
            />
          </Skeleton>
          <Box mt={40} position="relative">
            {assets.fields.length > 0 && (
              <Flex pb={2} justifyContent="space-between">
                <PM fontWeight={600} ml="calc(100% - 136px)">
                  Shares
                </PM>
                <div />
              </Flex>
            )}
            {assets.fields.map((asset, index) => (
              <Flex
                alignItems="center"
                css={{
                  borderBottom: `1px solid ${theme.colors.borderMain}`,
                  '> input > *': {
                    paddingTop: 0,
                  },
                }}
                cursor="pointer"
                font="PL"
                gap={8}
                key={asset.id}
                justifyContent="stretch"
                pt={8}
                pb={16}
              >
                <Flex flex="1 1 auto" flexDirection="row" minWidth={0}>
                  <Box height={40} width={40}>
                    <Logo content={asset.logoUrl} placeholder={asset.symbol} />
                  </Box>
                  <Flex flexDirection="column" pl={8} width="calc(100% - 40px)">
                    <PM color="foregroundNeutralSecondary">{asset.symbol}</PM>
                    <PM
                      color="foregroundNeutralMain"
                      fontWeight={600}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {asset.name}
                    </PM>
                  </Flex>
                </Flex>
                <Box width={160} flex="0 0 auto">
                  <ControlledInput
                    containerProps={{ mt: 8 }}
                    control={form.control}
                    name={`assets.${index}.quantity`}
                    rules={{
                      required: 'Required',
                      onChange: () => form.trigger(`assets.${index}.quantity`),
                      validate: (value) => {
                        if (value.toString().includes('.')) {
                          return 'We only accept full shares.';
                        }
                        if (typeof value === 'number' && value > 0) {
                          return true;
                        }
                      },
                    }}
                    maxLength={11}
                  />
                </Box>
                <Icon
                  name="removeBubble20"
                  onClick={() => assets.remove(index)}
                />
              </Flex>
            ))}
          </Box>
          <Box pt={48}>
            <Skeleton>
              <Button
                label={content?.ctaLabel as string}
                onClick={() => {
                  // Removing any assets with a zero quantity when advancing to the next step.
                  assets.remove(
                    assetsValue
                      .map((asset, i) => (asset.quantity === 0 ? i : -1))
                      .filter((idx) => idx >= 0),
                  );
                  form.next();
                }}
                size="large"
                disabled={!canAdvanceToNextStep}
              />
            </Skeleton>
          </Box>
        </AcatWizardStepCard>
      </AcatWizardStepContainer>
    </SkeletonProvider>
  );
}
