import type { Pie } from './types';

/**
 * A DFS implementation for traversing our pie tree and plopping each pie found into a result set.
 * This function is broken up into two parts for readability.
 */
export function getAllPiesFromPieTree(pie: Pie): Pie[] {
  const result: Pie[] = [];
  traverseAndCollectAllPies(pie, result);
  return result;
}

function traverseAndCollectAllPies(pie: Pie, result: Pie[]): void {
  result.push(pie);

  if (pie.slices) {
    for (const slice of pie.slices) {
      if (slice.to.type === 'new_pie' || slice.to.type === 'old_pie') {
        traverseAndCollectAllPies(slice.to, result);
      }
    }
  }
}
