import { Box, styled } from '@m1/liquid-react';

export const FINANCIAL_SUITABILITY_QUESTION_MAP = {
  POLITICALLY_EXPOSED_PERSON: 'disclosures',
  EMPLOYMENT_STATUS_AND_WHERE_DO_YOU_WORK: 'employment',
  NET_WORTH: 'net-worth',
  LIQUID_NET_WORTH: 'available-cash',
} as any;

export const StyledOption = styled(Box)`
  border: 2px solid;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem;
  text-align: center;
  outline: none;
  padding: 1rem;
  user-select: none;
  ${(props) => `
    color: ${props.theme.colors.primary};
    border-color: ${props.theme.colors.primary};
  `}
  &:focus, &:hover {
    ${(props) => `
      color: ${props.theme.colors.primaryShade};
      border-color: ${props.theme.colors.primaryShade};
    `}
`;
