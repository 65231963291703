import { css, styled } from '@m1/liquid-react';
import * as React from 'react';

type Spacing = 'none' | 'compact' | 'normal' | 'relaxed' | 'large';

export type DividerProps = {
  hidden?: boolean;
  spacing?: Spacing;
  width?: string;
  vertical?: boolean;
};

function getSpacingAmount(spacing: Spacing): number {
  const defaultSpacing = 16;
  switch (spacing) {
    case 'none':
      return 0;
    case 'compact':
      return defaultSpacing * 0.5;
    case 'relaxed':
      return defaultSpacing * 1.5;
    case 'large':
      return 40;
    default:
      return defaultSpacing;
  }
}

type StyledDividerProps = {
  $hidden: boolean;
  $spacing: Spacing;
  $width: string;
  $vertical: boolean;
};
const StyledDivider = styled.div<StyledDividerProps>`
  ${({ $vertical, $width, $spacing }) =>
    $vertical
      ? css`
          width: 1px;
          height: ${$width};
          margin: 0 ${getSpacingAmount($spacing)}px;
        `
      : css`
          height: 1px;
          width: ${$width};
          margin: ${getSpacingAmount($spacing)}px auto;
        `}
  background-color: ${(props) => {
    if (props.$hidden) {
      return 'transparent';
    }
    return props.theme.colors.borderMain;
  }};
`;

export const Divider = ({
  hidden = false,
  spacing = 'normal',
  width = '100%',
  vertical = false,
}: DividerProps) => {
  return (
    <StyledDivider
      $hidden={hidden}
      $spacing={spacing}
      $width={width}
      $vertical={vertical}
    />
  );
};
