import { Box, TwoColumn4by8 } from '@m1/liquid-react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { AnnouncementContext } from '~/components/announcement';
import { CommonTransactionDetailsCard } from '~/components/CommonTransactionDetailsCard/CommonTransactionDetailsCard';
import { Footer } from '~/components/Footer';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useCreditCardProductLandingPageQuery } from '~/graphql/hooks';
import type { CreditCardProductLandingPageQuery } from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { useTransactionDetailsModal } from '~/hooks/useTransactionDetailsModal';
import {
  StyledLandingPageContent,
  ShrinkContainer,
} from '~/pages/dashboard/common/ProductLandingPage.styled';
import { MastHeader } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';
import type { AppState } from '~/redux';

import { CreditCardPaginatedTransactions } from '../components/common-transactions/CreditCardPaginatedTransactions';

import { CreditCardDetailsCard } from './CreditCardDetailsCard';
import { CreditCardLandingPageBanners } from './CreditCardLandingPageBanners';
import { CreditCardLandingPageHeader } from './CreditCardLandingPageHeader';
import { CreditCardValuePropositionCard } from './CreditCardLandingPageValuePropositionCard';
import { CreditLimitCard } from './CreditLimitCard';

export const CreditCardLandingPage = () => {
  const { contentWidth } = useLayout();
  const accountId = useSelector(
    (state: AppState) => state.global.creditAccountId,
  );
  const { data } = useCreditCardProductLandingPageQuery({
    variables: { accountId: accountId as string },
    skip: !accountId,
  });

  const { selectedTransactionId, onModalOpen, onModalClose, showModal } =
    useTransactionDetailsModal();

  if (!accountId) {
    return <GenericSystemError />;
  }

  const node = data?.node as ExtractTypename<
    CreditCardProductLandingPageQuery['node'],
    'CreditCardAccount'
  >;

  const announcement = data?.viewer.announcements?.forSpend;
  const disclosures = node?.creditCardProductLandingPage?.disclosures;

  return (
    <Box width="100%">
      <MastHeader />
      <StyledLandingPageContent contentWidth={contentWidth}>
        {announcement && (
          <AnnouncementContext
            announcement={announcement}
            context="SPEND"
            mt={32}
          />
        )}
        <CreditCardLandingPageHeader
          accountId={accountId}
          hasAnnouncement={Boolean(announcement)}
        />
        <CreditCardLandingPageBanners accountId={accountId} />
        <TwoColumn4by8>
          <TwoColumn4by8.Column1>
            <CommonTransactionDetailsCard
              onClose={onModalClose}
              transactionId={selectedTransactionId}
              showModal={showModal}
            />

            <ShrinkContainer shouldShrink={showModal}>
              <CreditLimitCard accountId={accountId} />
              <CreditCardValuePropositionCard accountId={accountId} />
              <CreditCardDetailsCard accountId={accountId} />
            </ShrinkContainer>
          </TwoColumn4by8.Column1>
          <TwoColumn4by8.Column2>
            <CreditCardPaginatedTransactions
              accountId={accountId}
              handleDetailsModalOpen={onModalOpen}
              selectedTransactionId={
                showModal && selectedTransactionId
                  ? selectedTransactionId
                  : null
              }
            />
          </TwoColumn4by8.Column2>
        </TwoColumn4by8>
      </StyledLandingPageContent>
      <Footer footerDisclosures={disclosures} />
    </Box>
  );
};
