import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { useUnmarkUnmanagedHoldingsForLiquidationMutation } from '~/graphql/hooks';
import type { PendingLiquidationUnmanagedHoldingFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { isTrue } from '~/utils';

export const UnmarkUnmanagedHoldingButton = ({
  unmanagedHolding,
  primaryButtonLabel,
}: {
  unmanagedHolding: PendingLiquidationUnmanagedHoldingFragment;
  primaryButtonLabel: string;
}) => {
  const navigate = useNavigate();

  const { addToast } = useToast();

  const [unmarkUnmanagedHoldingsForLiquidation, { loading }] =
    useUnmarkUnmanagedHoldingsForLiquidationMutation();

  usePortaledSpinner(loading);

  const handleClick = () => {
    unmarkUnmanagedHoldingsForLiquidation({
      variables: {
        input: { unmanagedHoldingIds: [unmanagedHolding.id] },
      },
      onCompleted: (data) => {
        const result = data.unmarkUnmanagedHoldingsForLiquidation;

        addToast({
          content: result?.successMessage ?? '',
          duration: 'short',
          kind: 'success',
        });
      },
      onError: (err) => {
        addToast({
          content: err.message,
          duration: 'short',
          kind: 'alert',
        });
      },
    });

    navigate({ to: '/d/invest/unmanaged-holdings' });
  };

  const isUnmarking = loading || isTrue(unmanagedHolding.trading?.isInProgress);

  return (
    <Button
      disabled={isUnmarking}
      label={primaryButtonLabel}
      onClick={handleClick}
      size="large"
    />
  );
};
