import { useSkeleton } from '@m1/liquid-react';
import startCase from 'lodash-es/startCase';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie/SliceableNameCell';
import {
  aPosition,
  aPositionSecurityReference,
  aSecurity,
} from '~/graphql/fixtures';
import type { DividendTablePositionFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

const makeMockPosition = (symbol: string) =>
  aPosition({
    positionSecurity: aPositionSecurityReference({
      security: aSecurity({ symbol }),
    }),
  });

const mockedHoldings = [
  makeMockPosition('A'),
  makeMockPosition('B'),
  makeMockPosition('C'),
  makeMockPosition('D'),
  makeMockPosition('E'),
  makeMockPosition('F'),
  makeMockPosition('G'),
  makeMockPosition('H'),
];

export const DividendHoldingsTableRows = ({
  positions,
}: {
  positions: DividendTablePositionFragment[];
}) => {
  const { isLoading } = useSkeleton();
  const holdings = React.useMemo(
    () => (isLoading ? mockedHoldings : positions),
    [isLoading, positions],
  );

  return (
    <>
      {holdings.map((holding) => {
        const rowKey = isLoading
          ? `mocked-dividend-holding-${holding.positionSecurity?.security?.symbol}`
          : `dividend-holding-${holding.positionSecurity?.security?.symbol}`;

        return (
          <GridTable.NavigableRow
            key={rowKey}
            to={`/d/invest/holdings/position/${holding.id}`}
            usesCaretLeftIndicator={false}
          >
            <GridTable.Cell>
              <SliceableNameCell
                // TODO -- fix type error
                // @ts-expect-error -- typename not being recognized
                sliceable={holding.positionSecurity?.security}
                truncated
              />
            </GridTable.Cell>
            <GridTable.Cell justifySelf="right">
              {holding.dividends?.lastTwelveMonthsPaid ?? '--'}
            </GridTable.Cell>
            <GridTable.Cell justifySelf="right">
              {holding.dividends?.dividendYield
                ? `${holding.dividends.dividendYield.toFixed(2)}%`
                : '--'}
            </GridTable.Cell>
            <GridTable.Cell justifySelf="right">
              {holding.dividends?.yieldOnCost
                ? `${holding.dividends.yieldOnCost.toFixed(2)}%`
                : '--'}
            </GridTable.Cell>
            <GridTable.Cell justifySelf="right">
              {holding.dividends?.averageCost ?? '--'}
            </GridTable.Cell>
            <GridTable.Cell justifySelf="right">
              {holding.dividends?.dividendFrequency
                ? startCase(holding.dividends?.dividendFrequency?.toLowerCase())
                : '--'}
            </GridTable.Cell>
          </GridTable.NavigableRow>
        );
      })}
    </>
  );
};
