import { Flex, Box, PM, PL, Skeleton } from '@m1/liquid-react';
import * as React from 'react';

import { formatCurrency } from '~/utils/formatting';

type NetWorthChartProps = {
  assetRatio: Maybe<number>;
  assets: Maybe<number>;
  liabilities: Maybe<number>;
};

export const NetWorthChart = ({
  assetRatio,
  assets,
  liabilities,
}: NetWorthChartProps) => {
  const [assetPercent, setAssetPercent] = React.useState('');
  const [liabilityPercent, setLiabilityPercent] = React.useState('');

  React.useEffect(() => {
    if ((liabilities || assets) && typeof assetRatio === 'number') {
      setAssetPercent(`${assetRatio * 100}%`);
      setLiabilityPercent(`${(1 - assetRatio) * 100}%`);
    }
  }, [assetRatio, assets, liabilities]);

  return (
    <Box color="foregroundNeutralOnDark">
      <Skeleton display="flex" height="10px" skeletonWidth="100%" mb={8}>
        <Box
          backgroundColor="successTint"
          minWidth={assetPercent}
          borderRadius="5px 0 0 5px"
        />
        <Box width=".4%">&nbsp;</Box>
        <Box
          backgroundColor="critical"
          minWidth={liabilityPercent}
          borderRadius="0 5px 5px 0"
        />
      </Skeleton>
      <Flex justifyContent="space-between">
        <Box>
          <Skeleton width="fit-content" mb={2}>
            <PM>Assets</PM>
          </Skeleton>
          <Skeleton skeletonWidth={120}>
            <PL fontWeight={600} id="net-worth-assets">
              {assets ? formatCurrency(assets) : '$--'}
            </PL>
          </Skeleton>
        </Box>
        <Box>
          <Skeleton width="fit-content" mb={2}>
            <PM>Liabilities</PM>
          </Skeleton>
          <Flex justifyContent="end">
            <Skeleton skeletonWidth={120}>
              <PL fontWeight={600} id="net-worth-liabilities">
                {liabilities ? formatCurrency(liabilities) : '$--'}
              </PL>
            </Skeleton>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
