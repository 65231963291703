import { Box, Flex, type LayoutableProps, PS } from '@m1/liquid-react';
import * as React from 'react';

import {
  SmartTransferDetailsDocument,
  useSetEnabledOnTransferRuleMutation,
} from '~/graphql/hooks';
import type { SmartTransferEnableSwitchFragment } from '~/graphql/types';
import { Spinner } from '~/toolbox/spinner';
import { Switch } from '~/toolbox/switch';

import cautionIconSvg from './CautionSmall.svg';

export type SmartTransferEnableSwitchProps = LayoutableProps & {
  smartTransferRule: SmartTransferEnableSwitchFragment;
};

export const SmartTransferEnableSwitch = ({
  smartTransferRule,
  ...rest
}: SmartTransferEnableSwitchProps) => {
  const [setEnabledOnTransferRule, { loading }] =
    useSetEnabledOnTransferRuleMutation();
  const onSwitchChange = React.useCallback(() => {
    setEnabledOnTransferRule({
      variables: {
        input: {
          isEnabled: !smartTransferRule.isEnabled,
          transferRuleId: smartTransferRule.id,
        },
      },
      refetchQueries: [
        {
          query: SmartTransferDetailsDocument,
          variables: {
            smartTransferRuleId: smartTransferRule.id,
          },
        },
      ],
    });
  }, [smartTransferRule, setEnabledOnTransferRule]);

  return (
    <Box {...rest}>
      <Flex alignItems="center" justifyContent="space-between">
        <Switch
          checked={smartTransferRule.isEnabled}
          disabled={smartTransferRule.isPauseToggleDisabled || loading}
          label="Enable Smart Transfer"
          onChange={onSwitchChange}
        />
        {loading && (
          <div>
            <Spinner radius={28} thickness={3} />
          </div>
        )}
      </Flex>
      {smartTransferRule.cautionStatusMessage && (
        <Flex alignItems="center" mt={8}>
          <img height={16} src={cautionIconSvg} width={16} alt="Caution sign" />
          <PS
            color="foregroundNeutralSecondary"
            content={smartTransferRule.cautionStatusMessage}
            ml={8}
          />
        </Flex>
      )}
    </Box>
  );
};
