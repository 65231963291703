import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';

import {
  CryptoChoosePieStep,
  CryptoReviewStep,
  SelectAccountTypeStep,
  IndividualReviewStep,
  CustodialStep,
  CustodialReviewStep,
  CryptoPieDetailsStep,
  RetirementTypeSelectStep,
  RetirementRolloverTypeStep,
  RetirementReviewStep,
  TrustAccountSelectedStep,
  TrustAccountReceiptStep,
} from '~/flows/components/open-invest-account/steps';

import { OPEN_INVEST_ACCOUNT_STEPS as STEPS } from '~/static-constants';
import { Container } from '~/toolbox/container';

type OpenInvestAccountContainerProps = {
  onFinishStep: () => void;
  step: ValueOf<typeof STEPS>;
};

const shouldShowBackArrow = (step: ValueOf<typeof STEPS>) => {
  return (
    // First screen, no back arrow.
    step !== STEPS.SELECT_ACCOUNT_TYPE &&
    // Crypto post-account open screen.
    // Don't want to go back to Review after account is open.
    step !== STEPS.CRYPTO_CHOOSE_PIE
  );
};

export const OpenInvestAccountContainer = (
  props: OpenInvestAccountContainerProps,
) => {
  const steps = {
    // Flow entry point
    [STEPS.SELECT_ACCOUNT_TYPE]: SelectAccountTypeStep,

    // Individual
    [STEPS.INDIVIDUAL_REVIEW]: IndividualReviewStep,

    // Custodial
    [STEPS.CUSTODIAL]: CustodialStep,
    [STEPS.CUSTODIAL_REVIEW]: CustodialReviewStep,

    // Crypto
    [STEPS.CRYPTO_CHOOSE_PIE]: CryptoChoosePieStep,
    [STEPS.CRYPTO_PIE_DETAILS]: CryptoPieDetailsStep,
    [STEPS.CRYPTO_ACCOUNT_REVIEW]: CryptoReviewStep,

    // Retirement
    [STEPS.RETIREMENT_SELECT]: RetirementTypeSelectStep,
    [STEPS.RETIREMENT_ROLLOVER]: RetirementRolloverTypeStep,
    [STEPS.RETIREMENT_REVIEW]: RetirementReviewStep,

    // Trust
    [STEPS.TRUST_SELECT]: TrustAccountSelectedStep,
    [STEPS.TRUST_RECEIPT]: TrustAccountReceiptStep,
  };

  const { step, ...rest } = props;

  const Step = steps[props.step];

  if (!Step) {
    return null;
  }

  return (
    <Box
      width="100%"
      backgroundColor="backgroundNeutralSecondary"
      height="100vh"
    >
      <Container padding="32px 80px !important" width="100%">
        {shouldShowBackArrow(props.step) ? (
          <BackArrow
            content="Back"
            onClick={() => {
              window.history.back();
            }}
          />
        ) : null}
        {/* @ts-ignore step components aren't type friendly yet */}
        <Step {...rest} />
      </Container>
    </Box>
  );
};
