import {
  type BeginResolveCallFlowAction,
  RESOLVE_MARGIN_CALL_ACTIONS,
  type ReturnToResolveMarginCallInformationAction,
  type SelectedCreateTransferResolutionOptionAction,
  type SelectedSellPositionResolutionOptionAction,
} from './resolveMarginCallActions.types';

export const beginResolveCallFlow = (
  payload: BeginResolveCallFlowAction['payload'],
): BeginResolveCallFlowAction => ({
  payload,
  type: RESOLVE_MARGIN_CALL_ACTIONS.BEGIN_RESOLVE_CALL_FLOW,
});

export const returnToResolveMarginCallInformation = (
  payload: ReturnToResolveMarginCallInformationAction['payload'],
): ReturnToResolveMarginCallInformationAction => ({
  payload,
  type: RESOLVE_MARGIN_CALL_ACTIONS.RETURN_TO_RESOLVE_MARGIN_CALL_INFORMATION,
});

export const selectedCreateTransferResolutionOption = (
  payload: SelectedCreateTransferResolutionOptionAction['payload'],
): SelectedCreateTransferResolutionOptionAction => ({
  payload,
  type: RESOLVE_MARGIN_CALL_ACTIONS.SELECTED_CREATE_TRANSFER_RESOLUTION_OPTION,
});

export const selectedSellPositionResolutionOption = (
  payload: SelectedSellPositionResolutionOptionAction['payload'],
): SelectedSellPositionResolutionOptionAction => ({
  payload,
  type: RESOLVE_MARGIN_CALL_ACTIONS.SELECTED_SELL_POSITION_RESOLUTION_OPTION,
});
