import * as React from 'react';

import { ChartableSliceChartContext } from './ChartableSliceChartContext';

export const ChartableSliceChartProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isChartDataNull, setIsChartDataNull] = React.useState<boolean>(false);

  return (
    <ChartableSliceChartContext.Provider
      value={{ isChartDataNull, setIsChartDataNull }}
    >
      {children}
    </ChartableSliceChartContext.Provider>
  );
};
