import {
  css,
  Flex,
  HXS,
  PL,
  styled,
  Card,
  type CardProps,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type {
  PreferredTradeWindowEnum,
  TradingStatusFragment,
  TradingWindowDescription,
  TradingWindowTypeEnum,
} from '~/graphql/types';
import { formatTradingTimeByTimeStamp } from '~/utils/formatting';

import { AccountTradingStatusAfternoonWindowEligibilityPopup } from './AccountTradingStatusAfternoonWindowEligibilityPopup';

type Kind = 'default' | 'large';

export type AccountTradingWindowStatusCardProps = CardProps & {
  trading: TradingStatusFragment | null | undefined;
  forWindow: TradingWindowTypeEnum | PreferredTradeWindowEnum;
  kind?: Kind;
};

type AccountTradingWindowDetails = {
  tradeWindow?: Maybe<TradingWindowDescription>;
  prefix?: string;
  type?: TradingWindowTypeEnum;
};

export const AccountTradingWindowStatusCard = ({
  kind = 'default',
  forWindow,
  trading,
}: AccountTradingWindowStatusCardProps) => {
  const { tradeWindow, prefix } =
    React.useMemo<AccountTradingWindowDetails>(() => {
      switch (forWindow) {
        case 'MORNING':
          return {
            tradeWindow: trading?.nextMorningWindow,
            prefix: 'Morning',
            type: 'MORNING',
          };
        case 'AFTERNOON':
          return {
            tradeWindow: trading?.nextAfternoonWindow,
            prefix: 'Afternoon',
            type: 'AFTERNOON',
          };
        case 'ON_DEMAND':
          return {
            tradeWindow: trading?.nextOnDemandWindow,
            prefix: 'Now!',
            type: 'ON_DEMAND',
          };
        default:
          return {};
      }
    }, [forWindow, trading]);

  if (!tradeWindow || !trading) {
    return null;
  }

  const windowDate =
    trading.inProgressWindowForAccount === tradeWindow.type
      ? 'In progress'
      : formatTradingTimeByTimeStamp(tradeWindow.startTime);

  let windowToHighlight = trading.inProgressWindowForAccount;
  if (windowToHighlight === null) {
    windowToHighlight = trading.nextWindowForAccount;
  }

  if (forWindow === 'ON_DEMAND') {
    windowToHighlight = forWindow;
  }

  const highlighted = windowToHighlight === tradeWindow.type;

  return (
    <StyledCard
      data-testid="account-trading-window-status-card"
      $kind={kind}
      $highlighted={highlighted}
      $ineligible={
        tradeWindow.type === 'AFTERNOON' &&
        !trading.hasAccessToAndCanTradeInNextAfternoonWindow
      }
    >
      <Icon
        name={kind === 'default' ? 'bell24' : 'bell32'}
        borderRadius="50%"
        color="primary"
        backgroundColor="backgroundNeutralSecondary"
      />
      <StyledContent ml={kind === 'default' ? 8 : 16}>
        <StyledText>
          {prefix}
          {kind === 'default' ? (
            <b>{windowDate}</b>
          ) : (
            <HXS content={windowDate} />
          )}
        </StyledText>
        {tradeWindow.type === 'AFTERNOON' && (
          <AccountTradingStatusAfternoonWindowEligibilityPopup
            trading={trading}
          />
        )}
      </StyledContent>
    </StyledCard>
  );
};

const StyledContent = styled(Flex)`
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled(PL)`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

const StyledCard = styled(Card)<{
  $highlighted: boolean;
  $kind: Kind;
  $ineligible: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $highlighted, theme: { colors } }) => {
    if ($highlighted) {
      return css`
        border-color: ${colors.borderSuccess};
      `;
    }
    return css`
      elevation: unset;
      border-color: transparent;
    `;
  }};
  ${({ $ineligible }) => {
    if ($ineligible) {
      return css`
        & > svg {
          opacity: 0.5;
        }
        & > div {
          opacity: 0.5;
        }
      `;
    }
  }}
  padding: ${({ $kind }) => ($kind === 'default' ? '8px 12px' : '16px 32px')};
`;
