import { type LayoutableProps, type BannerKind } from '@m1/liquid-react';
import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';

import type { Slice } from '~/pie-trees';

export const SliceListAllocationFeedbackBanner = ({
  slices,
  ...rest
}: LayoutableProps & {
  slices: ReadonlyArray<Slice>;
}) => {
  const { content, kind } = React.useMemo<{
    content: string;
    kind: BannerKind;
  }>(() => {
    const total = slices.reduce((p, v) => p + v.percentage, 0);

    if (total < 100) {
      return {
        content: `Add ${100 - total}% to total 100%`,
        kind: 'warning',
      };
    } else if (total > 100) {
      return {
        content: `Remove ${total - 100}% to total 100%`,
        kind: 'warning',
      };
    }

    return {
      content: `Total 100% of 100%`,
      kind: 'success',
    };
  }, [slices]);

  return slices.length ? (
    <Banner {...rest} content={content} kind={kind} />
  ) : null;
};
