import * as React from 'react';

import type { SecurityScreenerQueryResultContextValue } from './types';

export const SecurityScreenerQueryResultContext = React.createContext(
  {} as SecurityScreenerQueryResultContextValue,
);

export const useStockScreenerResult = () => {
  const queryResult = React.useContext(SecurityScreenerQueryResultContext);
  if (!queryResult) {
    throw new Error(
      'useStockScreenerResult must be used within a SecurityScreenerProvider',
    );
  }
  return queryResult;
};
