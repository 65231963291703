import {
  Flex,
  type LayoutableProps,
  OneColumnConstrained,
} from '@m1/liquid-react';
import React from 'react';

export function AcatWizardStepContainer({
  children,
  ...rest
}: React.PropsWithChildren<LayoutableProps>) {
  return (
    <OneColumnConstrained>
      <Flex {...rest} flexDirection="column" gap={32} mx="auto" pb={64}>
        {children}
      </Flex>
    </OneColumnConstrained>
  );
}
