import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useChartableSliceChartLazyQuery,
  usePortfolioPageQuery,
} from '~/graphql/hooks';
import type { PortfolioPageAccountNodeFragment } from '~/graphql/types';
import { useParams } from '~/hooks/useParams';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { NewPortfolioPage } from './components/NewPortfolioPage';
import { PortfolioDetailsPage } from './PortfolioDetailsPage';
import { PortfolioPageContainer } from './PortfolioDetailsPage/PortfolioDetailsPage.styled';

export type PortfolioPageProps = {
  children?: React.ReactElement<any>;
};

export const PortfolioPage = ({ children }: PortfolioPageProps) => {
  const accountId = useSelector((state) => state.global.activeAccountId);
  const { portfolioSliceId: portfolioSliceIdParam } = useParams();

  const { data, loading, refetch } = usePortfolioPageQuery({
    variables: {
      accountId: accountId as string,
    },
    skip: !accountId,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const account = data?.node as ExtractTypename<
    PortfolioPageAccountNodeFragment,
    'Account'
  >;
  const portfolioSliceId =
    portfolioSliceIdParam || account?.rootPortfolioSlice?.id;

  const [fetchChart] = useChartableSliceChartLazyQuery();

  React.useEffect(() => {
    if (portfolioSliceId) {
      // Preload the invest chart
      fetchChart({
        variables: {
          ids: [portfolioSliceId],
        },
      });
    }
  }, [portfolioSliceId]);

  if (loading) {
    return (
      <Box m={20}>
        <Spinner fullScreen />
      </Box>
    );
  }

  if (!data?.node || !data.viewer) {
    return <GenericSystemError onClickRetry={refetch} />;
  }

  if (!account?.hasPortfolio) {
    return (
      <Box minHeight={600} pt={16}>
        <NewPortfolioPage account={account} viewer={data.viewer} />
      </Box>
    );
  } else if (children) {
    return (
      <Box minHeight={600} pt={16}>
        {children}
      </Box>
    );
  }

  if (!portfolioSliceId) {
    return <GenericSystemError />;
  }

  return (
    <PortfolioPageContainer>
      <PortfolioDetailsPage portfolioSliceId={portfolioSliceId || null} />
    </PortfolioPageContainer>
  );
};
