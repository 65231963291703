import { Text, styled, type Theme } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { components } from 'react-select';

import type { SelectProps, DropdownOption } from './Dropdown.types';

export type OptionProps = {
  children?: React.ReactNode;
  className: string;
  content?: React.ReactNode;
  data: DropdownOption;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  label: string;
  selectProps: SelectProps & {
    appTheme: Theme;
  };
};

type StyledOptionElementProps = Pick<OptionProps, 'isFocused' | 'selectProps'>;

const StyledText = styled(Text)<StyledOptionElementProps>`
  color: ${(props) => {
    if (props.isFocused) {
      return props.selectProps.appTheme.colors.foregroundNeutralMain;
    }
    return props.selectProps.appTheme.colors.foregroundNeutralMain;
  }};
`;

const StyledContent = styled.div<StyledOptionElementProps>`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background-color: ${(props) =>
    props.isFocused
      ? props.selectProps.appTheme.colors.backgroundNeutralMain
      : undefined};

  &:hover {
    cursor: pointer;
  }
`;

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectOption = components.Option as any;

export const Option = (props: OptionProps) => {
  const { children, ...rest } = props;

  return (
    <ReactSelectOption {...rest}>
      <StyledContent
        isFocused={props.isFocused}
        selectProps={props.selectProps}
      >
        {props.selectProps.optionContent ? (
          props.selectProps.optionContent(props as any)
        ) : (
          <StyledText
            content={children}
            isFocused={props.isFocused}
            selectProps={props.selectProps}
          />
        )}
        {props.isSelected && (
          <Icon name="check24" color="foregroundNeutralMain" ml={8} />
        )}
      </StyledContent>
    </ReactSelectOption>
  );
};
