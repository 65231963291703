import { Button, LS, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import classNames from 'classnames';
import * as React from 'react';

import { mapProps } from '~/hocs';

import { ActionCard, type ActionCardProps } from '../action-card';

import style from './style.module.scss';

type Props = ActionCardProps & {
  icon?: React.ReactNode;
  isSelected: boolean;
  subtitle?: string | null | undefined;
  title: string;
};

const enhancer = mapProps(
  ({ icon, isSelected, subtitle, title, ...rest }: Props) => ({
    ...rest,
    children: (
      <>
        {icon && icon}
        <div className={style.contentContainer}>
          <PL content={title} fontWeight={600} color="foregroundNeutralMain" />
          {typeof subtitle === 'string' && subtitle ? (
            <LS as="h4" color="foregroundNeutralSecondary" content={subtitle} />
          ) : null}
        </div>
        <div className={style.rightContainer}>
          {isSelected ? (
            <Icon name="check24" color="primary" />
          ) : (
            <Button kind="link" label="Select" />
          )}
        </div>
      </>
    ),
    className: classNames(style.root, {
      [style.selected]: isSelected,
    }),
    backgroundColor: isSelected ? 'backgroundInfoSubtle' : undefined,
    borderColor: isSelected ? 'secondaryTint' : undefined,
  }),
);

export const SelectableCard = enhancer(ActionCard) as any;
