import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { TopNavInvest } from './components/TopNavInvest';
import { TopNavResearch } from './components/TopNavResearch';
import { TopNavSettings } from './components/TopNavSettings';
import { TopNavSpend } from './components/TopNavSpend';
import { TopNavTransfers } from './components/TopNavTransfers';

const EmptyNav = styled(Flex)`
  height: 60px;
  width: 100%;
`;

export const TopNavContents = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  switch (selectedNavigation.activeL1Nav) {
    case 'invest':
      return <TopNavInvest selectedNavigation={selectedNavigation} />;
    case 'research':
      return <TopNavResearch />;
    case 'settings':
      return <TopNavSettings selectedNavigation={selectedNavigation} />;
    case 'spend':
      return <TopNavSpend selectedNavigation={selectedNavigation} />;
    case 'transfers':
      return <TopNavTransfers selectedNavigation={selectedNavigation} />;
    default:
      return <EmptyNav />;
  }
};
