import * as React from 'react';

import { useWizard, WizardProvider } from '~/flows/wizard';
import { useSearchParams } from '~/hooks/useSearchParams';
import { setFinancialSuitabilityProduct } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { CollectAvailableCashWizardStep } from './steps/CollectAvailableCashWizardStep';
import { CollectPrimaryDisclosuresWizardStep } from './steps/CollectPrimaryDisclosuresWizardStep';
import { CollectPrimaryEmploymentWizardStep } from './steps/CollectPrimaryEmploymentWizardStep';
import { CollectTotalNetWorthWizardStep } from './steps/CollectTotalNetWorthWizardStep';
import type { FinancialSuitabilityWizardSteps } from './types';

type FinancialSuitabilityWizardFormSteps = Record<
  FinancialSuitabilityWizardSteps,
  React.ReactElement
>;

export const FinancialSuitabilityWizard = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const productQueryParam = searchParams.get('product');
  const previousRouteQueryParam = searchParams.get('previousRouteName');

  React.useMemo(() => {
    if (typeof productQueryParam === 'string') {
      dispatch(setFinancialSuitabilityProduct(productQueryParam));
    }
  }, []);

  const previousRouteName = React.useMemo(() => {
    if (typeof previousRouteQueryParam === 'string') {
      return previousRouteQueryParam;
    }
  }, []);

  const steps: FinancialSuitabilityWizardFormSteps = {
    'disclosures': (
      <CollectPrimaryDisclosuresWizardStep
        previousRouteName={previousRouteName}
      />
    ),
    'employment': <CollectPrimaryEmploymentWizardStep />,
    'net-worth': <CollectTotalNetWorthWizardStep />,
    'available-cash': <CollectAvailableCashWizardStep />,
  };

  const wizard = useWizard(steps);

  return <WizardProvider value={wizard}>{wizard.step}</WizardProvider>;
};
