import {
  Box,
  Flex,
  HXS,
  TabularDataSet,
  styled,
  Skeleton,
  PM,
} from '@m1/liquid-react';
import { Icon, type AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Sticky } from '~/components/sticky';
import type {
  AppNavigationItemFragment,
  InformationBannerFragment,
  LandingPageHeaderFragment,
  LinkableFragment,
  TabularDataItemFragment,
} from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';
import { LinkableSkeletonButton } from '~/lens-toolbox/LinkableButton';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { HeaderScrollBackground } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';

import { BillStatementDetailsQueriedModal } from './BillStatementDetailsModal';

export const StaticHeader = styled(Flex)`
  min-height: 84px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderFeature};
  padding: 20px 0;
`;

const DataSetContainer = styled(Box)<{ opacity: any }>`
  opacity: ${({ opacity }) => opacity};
`;

const DynamicHeight = styled(Box)`
  width: fit-content;
  overflow-y: hidden;
`;

const MarginDataSet = ({
  opacity,
  compact = false,
  accountDetails,
  header,
}: {
  opacity: any;
  compact?: boolean;
  accountDetails: Maybe<TabularDataItemFragment>;
  header: Maybe<LandingPageHeaderFragment>;
}) => {
  return (
    <DataSetContainer opacity={opacity}>
      <TabularDataSet
        tabularData={[
          {
            label: (
              <Skeleton skeletonWidth="75%">
                <PM color="foregroundNeutralSecondary">{header?.title}</PM>
              </Skeleton>
            ),
            data: (
              <Skeleton
                font={compact ? 'PXL' : 'HL'}
                fontWeight={compact ? 500 : 300}
                skeletonWidth={100}
              >
                {header?.value ?? ' '}
              </Skeleton>
            ),
          },
          ...(compact
            ? []
            : [
                {
                  label: (
                    <Skeleton skeletonWidth="75%" whiteSpace="nowrap">
                      <PM color="foregroundNeutralSecondary">
                        {accountDetails?.title}
                      </PM>
                    </Skeleton>
                  ),
                  data: (
                    <Skeleton skeletonWidth={120}>
                      <Flex alignItems="center">
                        <RichText
                          textProps={{
                            font: 'HM',
                            fontWeight: 300,
                            mr: 8,
                          }}
                          richText={accountDetails?.richTextValue}
                        />
                        {accountDetails?.valueIcon?.names && (
                          <Icon
                            name={
                              accountDetails?.valueIcon.names[0] as AllIconName
                            }
                          />
                        )}
                      </Flex>
                    </Skeleton>
                  ),
                },
              ]),
        ]}
      />
    </DataSetContainer>
  );
};

export const MarginLandingPageHeader = ({
  title,
  accountDetails,
  header,
  primaryCta,
  secondaryCta,
  banners,
}: {
  title: Maybe<string>;
  accountDetails: Maybe<TabularDataItemFragment>;
  header: Maybe<LandingPageHeaderFragment>;
  primaryCta: Maybe<AppNavigationItemFragment>;
  secondaryCta: Maybe<LinkableFragment>;
  banners: Maybe<Array<Maybe<InformationBannerFragment>>>;
}) => {
  const [isBillStatementDetailsModalOpen, setIsBillStatementDetailsModalOpen] =
    React.useState(false);
  const { scrollTop, topBarHeight } = useLayout();
  const percentTopBarScrolled = Math.min(
    1,
    Math.pow(scrollTop / topBarHeight, 2),
  );

  return (
    <Sticky zIndex={3}>
      <StaticHeader forwardedAs="header">
        <Box width="100%">
          {banners?.map((banner, i) => (
            <InformationBanner
              appBanner={banner}
              mb={16}
              position="relative"
              key={i}
              contain={false}
              onClick={(action) => {
                if (action === 'VIEW_BILL') {
                  setIsBillStatementDetailsModalOpen(true);
                }
              }}
            />
          ))}
          <Flex
            mb={
              scrollTop < topBarHeight
                ? 24
                : Math.max(0, 1 - 0.01 * (scrollTop - topBarHeight)) * 24 // margin animates between 0 and default 24px so vertical space disappears as you scroll down
            }
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton skeletonWidth="25%">
              <HXS color="foregroundSecondary" fontWeight={300}>
                {title}
              </HXS>
            </Skeleton>
            <Box>
              <MarginDataSet
                accountDetails={accountDetails}
                header={header}
                opacity={Math.max(0, 0.015 * (scrollTop - topBarHeight))} // Fades in as you scroll down
                compact
              />
            </Box>
            <Flex flexWrap="wrap" gap={8}>
              <Skeleton skeletonHeight="fit-content" skeletonWidth={160}>
                <LinkableSkeletonButton
                  linkable={secondaryCta}
                  kind="secondary"
                  size="large"
                  borderRadius={1000}
                />
              </Skeleton>
              <Skeleton skeletonHeight="fit-content" skeletonWidth={160}>
                <LinkableSkeletonButton
                  linkable={primaryCta}
                  size="large"
                  borderRadius={1000}
                />
              </Skeleton>
            </Flex>
          </Flex>
          <DynamicHeight
            // 72 is the expected height, and this doesn't really work without hardcoding heights.
            // If the largest font in the MarginDataSet changes, this value will need to change too.
            // No good way to reliably do this programmatically except for line height, but that is a string
            // and it is 48px for HL, not 72px.
            height={
              scrollTop < topBarHeight
                ? 72
                : Math.max(0, 1 - 0.01 * (scrollTop - topBarHeight)) * 72
            }
          >
            <MarginDataSet
              accountDetails={accountDetails}
              header={header}
              opacity={Math.max(0, 1 - 0.025 * (scrollTop - topBarHeight))} // Fades out as you scroll down
            />
          </DynamicHeight>
        </Box>
        <HeaderScrollBackground
          $percentTopBarScrolled={percentTopBarScrolled}
        />
        <BillStatementDetailsQueriedModal
          open={isBillStatementDetailsModalOpen}
          onClose={() => setIsBillStatementDetailsModalOpen(false)}
        />
      </StaticHeader>
    </Sticky>
  );
};
