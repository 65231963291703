import * as React from 'react';
import { Route } from 'react-router-dom';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

import { CreatePaymentPage } from '../create-payment/CreatePaymentPage';

import { CreateTransferPage } from './CreateTransferPage';

export const CreateTransferRoute = () => {
  return (
    <>
      <Route
        path="transfer/:step?"
        element={<CreateTransferPage />}
        handle={{
          fallbackRoute: {
            to: '/d/transfers',
          },
        }}
      />
      {/* Payments have not been migrated to new flow, still using legacy components */}
      <Route
        path="payment/:step?"
        element={<RedirectToMoveMoney />}
        handle={{
          fallbackRoute: {
            to: '/d/transfers',
          },
        }}
      />
      {/* --------------------------------
       * The create-transfer route is
       * deprecated as of 12.28.23
       * ----------------------------------*/}
      <Route
        path="create-transfer/:step?"
        element={<CreatePaymentPage />}
        handle={{
          fallbackRoute: {
            to: '/d/transfers',
          },
        }}
      />
    </>
  );
};

const RedirectToMoveMoney = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const query = Object.fromEntries(new URLSearchParams(location.search));

    navigate({
      to: '/d/move-money',
      query,
    });
  }, [location, navigate]);

  return null;
};
