import { Button, styled } from '@m1/liquid-react';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';
import type { AccountRebalancesTableFragment } from '~/graphql/types';
import { useModal } from '~/hooks/useModal';
import { GridTable } from '~/toolbox/grid-table';

export type AccountRebalancesTableProps = {
  account: AccountRebalancesTableFragment;
  style?: Record<string, any>;
};

const StyledGridTable = styled(GridTable)`
  border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const AccountRebalancesTable = ({
  account,
  ...rest
}: AccountRebalancesTableProps) => {
  const { portfolioSlicesWithRebalances } = account;
  const { showModal } = useModal('REBALANCE_ACTION_CANCEL');

  return (
    <StyledGridTable
      {...rest}
      emptyMessage="You have no pending rebalances"
      gridTemplateColumns="70% auto"
      hasHeaderRow={false}
    >
      {portfolioSlicesWithRebalances &&
        portfolioSlicesWithRebalances.map((portfolioSlice) => {
          if (!portfolioSlice) {
            return null;
          }
          return (
            <GridTable.Row key={portfolioSlice.id}>
              <GridTable.Cell
                content={<SliceableNameCell sliceable={portfolioSlice.to} />}
              />
              <GridTable.Cell
                content={
                  <Button
                    kind="link"
                    label="Cancel"
                    style={{ fontSize: '16px' }}
                    onClick={() =>
                      showModal({ portfolioSliceId: portfolioSlice.id })
                    }
                  />
                }
                textAlign="right"
              />
            </GridTable.Row>
          );
        })}
    </StyledGridTable>
  );
};
