import Basil from 'basil.js';
import type { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { cacheAppConfig } from '~/redux/actions';
import type { ConfigState } from '~/redux/reducers/configReducer';
import { parseQuery } from '~/utils';

export function* cacheLocationInput(
  location: Location,
): SagaIterator<Partial<ConfigState>> {
  const config = parseLocation({
    href: location.href,
    origin: location.origin,
    pathname: location.pathname,
    search: parseQuery(location.search),
  });

  if (config.promoCode) {
    const domain =
      window.location.host.indexOf('localhost') > -1 ? 'localhost' : 'm1.com';
    Basil.cookie.set('promo-code', config.promoCode, {
      expireDays: 7,
      domain,
    });
  }

  yield put(cacheAppConfig(config));
  return config;
}

// @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
function parseLocation(result): Partial<ConfigState> {
  return {
    email: result['search']['email'],
    landingUrl: readLandingUrl(result),
    pieKey: result['search']['pieKey'],
    promoCode: result['search']['p'] || Basil.cookie.get('promo-code'),
    tokens: {
      resetPassword: result['search']['resetPasswordToken'],
      verifyEmail: result['search']['verifyEmailToken'],
    },
  };
}

// @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
function readLandingUrl(result): ConfigState['landingUrl'] {
  if (!result['href']) {
    return null;
  }

  return result['href'].replace(result['origin'], '');
}
