import merge from 'lodash-es/merge';
import set from 'lodash-es/set';

import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import type { IdentityFirstOnboardingQuestion } from '~/redux/actions/newFlows/identityFirst/identityFirstActions.types';
import {
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
  INVEST_ACCOUNT_TYPES,
  RETIREMENT_ACCOUNT_TYPES,
} from '~/static-constants';

import type { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type IdentityFirstFlowState = FlowState<
  string,
  {
    accountId: string | null | undefined;
    basePath: string;
    input: Record<string, any>;
    onboardingQuestions: Record<string, IdentityFirstOnboardingQuestion>;
    phone: string | null | undefined;
  }
>;

export const identityFirstInitialFlowState: IdentityFirstFlowState = {
  accountId: null,
  basePath: '',
  input: {},
  onboardingQuestions: {},
  phone: null,
  step: STEPS.LOAD_STATUS,
  stepTitle: '',
};

const stepReducer = createStepReducer(identityFirstInitialFlowState);

export function IdentityFirst(
  state: IdentityFirstFlowState = identityFirstInitialFlowState,
  action: any,
): IdentityFirstFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW_MODERN: {
      const { basePath, phone, registration } = action.payload;
      const input = registration
        ? {
            registration,
          }
        : {};

      return {
        ...identityFirstInitialFlowState,
        basePath,
        phone,
        input,
      };
    }
    case 'SET_ACTIVE_CRYPTO_ACCOUNT':
    case 'SET_ACTIVE_INVEST_ACCOUNT':
      return {
        ...state,
        accountId: action.payload,
      };
    case ACTIONS.SET_IDENTITY_FIRST_ONBOARDING_QUESTIONS:
      return {
        ...state,
        onboardingQuestions: action.payload
          .filter((question: IdentityFirstOnboardingQuestion) => question)
          .reduce(
            (
              questions: Record<string, IdentityFirstOnboardingQuestion>,
              current: IdentityFirstOnboardingQuestion,
            ) => {
              if (current.questionType) {
                questions[current.questionType] = current;
              }
              return questions;
            },
            {},
          ),
      };
    case ACTIONS.SET_INITIAL_USER_PROFILE_INPUT:
      return {
        ...state,
        input: action.payload,
      };
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return {
        ...state,
        input: inputReducer(state.input, action),
      };
  }
}

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type.
function inputReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SELECTED_ACCOUNT_TYPE_MODERN:
      switch (action.payload) {
        case INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE:
        case INVEST_ACCOUNT_TYPES.JOINT_TAXABLE:
        case INVEST_ACCOUNT_TYPES.CUSTODIAL:
        case INVEST_ACCOUNT_TYPES.CRYPTO:
          return Object.assign(
            {
              ...state,
            },
            {
              registration: action.payload,
            },
          );
        default:
          return state;
      }
    case ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE_MODERN:
      return action.payload === RETIREMENT_ACCOUNT_TYPES.ROLLOVER
        ? Object.assign(
            {
              ...state,
            },
            {
              transferInfoOptIn: true,
            },
          )
        : Object.assign(
            {
              ...state,
            },
            {
              registration: action.payload,
              transferInfoOptIn: false,
            },
          );
    case ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE_MODERN:
    case ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE_MODERN:
      return Object.assign(
        {
          ...state,
        },
        {
          registration: action.payload,
        },
      );
    case ACTIONS.SUBMITTED_CONTACT_INFO_FORM_MODERN:
    case ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN:
    case ACTIONS.SUBMITTED_IDENTITY_INFO_FORM_MODERN:
    case ACTIONS.SUBMITTED_DISCLOSURES_FORM_MODERN:
    case ACTIONS.SUBMITTED_EMPLOYMENT_INFO_MODERN:
    case 'EXPLAINED_PROFILE_COLLECTION':
      return merge({}, state, action.payload.values);
    case ACTIONS.COLLECTED_HOLDER_SSN_MODERN: {
      const { holder, ssn } = action.payload;
      return merge({}, state, {
        [`${holder}Ssn`]: ssn,
        holder,
      });
    }
    case 'COLLECTED_TRUSTED_CONTACT': {
      return merge({}, state, action.payload);
    }
    case 'SKIPPED_TRUSTED_CONTACT': {
      return merge({}, state, {
        trustedContact: null,
      });
    }
    case ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW_MODERN:
      return merge({}, state, action.payload);

    case ACTIONS.SUBMITTED_ANNUAL_INCOME_FORM:
    case ACTIONS.SUBMITTED_LIQUID_NET_WORTH_FORM:
    case ACTIONS.SUBMITTED_PROFILE_INPUT_MODERN: {
      const { field, value } = action.payload;
      return merge(
        {
          ...state,
        },
        set({}, field, value),
      );
    }
    case ACTIONS.SUBMITTED_INTRODUCTION_SOURCE_MODERN:
      return merge({}, state, {
        introductionSource: action.payload,
      });
    default:
      return state;
  }
}
