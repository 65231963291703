import { PL, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useDepositInfoPageQuery,
  usePersonalLoansDirectQuery,
  useQueueLoanDisbursementMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { submitReactFormData } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import type { PossibleOptions } from '~/toolbox/Dropdown';
import { Spinner } from '~/toolbox/spinner';

import { BankDepositTransferParticipantForm } from '../components/BankDepositTransferParticipantForm';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export const BankDeposit = () => {
  const { loanId, transferParticipantId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goTo } = usePersonalLoanDirectWizardContext();

  React.useEffect(() => {
    // remove query step from the URL since doesn't make sense to persist it
    window.history.replaceState(null, '', window.location.pathname);
  }, []);

  const { data: questionsData, loading: questionsLoading } =
    usePersonalLoansDirectQuery();
  const { data, loading } = useDepositInfoPageQuery({
    variables: {
      loanId,
    },
  });
  const [queueLoanDisbursementMutation] = useQueueLoanDisbursementMutation();

  const questions =
    questionsData?.viewer?.borrow?.personalLoans?.applicationFlowDirect
      ?.questions;
  let props;

  if (questions) {
    props = getQuestionProps({
      question: 'BANK_DEPOSIT',
      questions,
    });
  }
  const { nextQuestionType } = props || {};

  const disbursementProps =
    data?.viewer.borrow?.personalLoans?.disbursementFlow?.loanDisbursement;

  const title = disbursementProps?.title;
  const subtitle = disbursementProps?.subtitle;
  const destinationList = disbursementProps?.destinationList;

  const initialValue = {
    transferParticipantId:
      Array.isArray(destinationList) && destinationList.length === 1
        ? destinationList?.[0]?.id
        : transferParticipantId,
  };

  const list = destinationList ?? [];

  const accountOptions = list.map((participant) => ({
    label: (
      <Flex alignItems="center">
        <Icon name="accountAccountsPrimary32" style={{ marginRight: 12 }} />
        <PL content={participant?.transferParticipantName} />
      </Flex>
    ),
    value: participant?.id,
  })) as PossibleOptions;

  const handleSubmit = (data: any) => {
    queueLoanDisbursementMutation({
      variables: {
        input: {
          loanId,
          transferParticipantId: data?.transferParticipantId,
        },
      },
      onCompleted: () => {
        dispatch(
          submitReactFormData({
            directToPersonalLoansTransferParticipant: {
              transferParticipantId: data?.transferParticipantId,
              transferParticipantName: destinationList?.find(
                (destination) =>
                  destination?.id === data?.transferParticipantId,
              )?.transferParticipantName,
            },
          }),
        );
        goTo(nextQuestionType ?? 'AUTOPAY');
      },
      onError: () => {
        navigate({ to: '/direct-loan-application-error/disbursement-error' });
      },
    });
  };

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/deposit-info');
  }, [analytics]);

  if (loading || questionsLoading) {
    return <Spinner fullScreen centered />;
  }

  if (!disbursementProps || !title || !subtitle || !destinationList) {
    return <GenericSystemError />;
  }

  return (
    <div>
      <StyledHeader
        mt={24}
        content={title ?? 'Which account at your bank should we deposit into?'}
      />
      <PL
        content={
          subtitle ?? 'Funds will appear in your account in 3-5 business days.'
        }
        mt={8}
        mb={16}
      />
      <BankDepositTransferParticipantForm
        onSubmit={handleSubmit}
        options={accountOptions}
        initialValue={initialValue}
      />
    </div>
  );
};
