import moment from 'moment';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import {
  useGenerateIdempotencyKeyMutation,
  useGetPaymentWizardPreloadQuery,
} from '~/graphql/hooks';
import { useSearchParams } from '~/hooks/useSearchParams';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { TransferModeEnum } from '../create-transfer/steps/TransferDetails.types';

import { CreatePaymentContext } from './CreatePaymentContext';

export const CreatePaymentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const defaultDate = React.useMemo(() => moment().toISOString(), []);

  const [completedPaymentNodeId, setCompletedPaymentNodeId] = React.useState<
    string | null
  >(null);
  const [params] = useSearchParams();

  const fromParticipantIdQueryParam = params.get('fromParticipantId') ?? null;
  const toParticipantIdQueryParam = params.get('toParticipantId') ?? null;
  const transferModeQueryParam =
    (params.get('mode') as TransferModeEnum) ?? null;
  const previousRouteName = params.get('previousRouteName') ?? '/d/transfers';

  const [mode, setMode] = React.useState<TransferModeEnum>(
    transferModeQueryParam ?? TransferModeEnum.OneTime,
  );

  const data = useGetPaymentWizardPreloadQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      date: defaultDate,
      fromParticipantId: fromParticipantIdQueryParam,
      toParticipantId: toParticipantIdQueryParam,
      transferType:
        mode === TransferModeEnum.OneTime
          ? 'TRANSFER_INSTANCE'
          : 'SCHEDULED_TRANSFER_RULE',
    },
  });

  const [
    generateIdempotencyKey,
    { data: idempotencyKeyData, loading: idempotencyKeyLoading },
  ] = useGenerateIdempotencyKeyMutation({});

  React.useEffect(() => {
    generateIdempotencyKey({
      variables: {
        input: {},
      },
    });
  }, [generateIdempotencyKey]);

  const { loading, error } = data;

  if (loading === true || idempotencyKeyLoading) {
    return <Spinner fullScreen />;
  }

  if (isNotNil(error)) {
    return <GenericSystemError />;
  }

  const idempotencyKey =
    idempotencyKeyData?.generateIdempotencyKey?.outcome?.idempotencyKey;

  /*
   * All payments should pivot on the "To" / destination field.
   * This means that all eligible "From" / source fields will be
   * filtered by the selected To field.
   */
  const pivot = 'TO';

  return (
    <CreatePaymentContext.Provider
      value={{
        completedPaymentNodeId,
        fromParticipantIdQueryParam: fromParticipantIdQueryParam ?? null,
        idempotencyKey,
        pivot,
        previousRouteName,
        setCompletedPaymentNodeId,
        setMode,
        toParticipantIdQueryParam: toParticipantIdQueryParam ?? null,
        mode,
        ...data,
      }}
    >
      {children}
    </CreatePaymentContext.Provider>
  );
};
