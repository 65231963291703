import { PXL, LS, Flex } from '@m1/liquid-react';
import moment from 'moment-timezone';
import * as React from 'react';

import type {
  IntradayQuotePeriodEnum,
  HistoricalQuotePeriodEnum,
  SecurityDetailFragment,
} from '~/graphql/types';
import type { SetSecurityDateRange } from '~/redux/actions';
import { useSelector } from '~/redux/hooks';
import { isNotNil } from '~/utils';
import { formatNumber } from '~/utils/formatting';

export type SecurityCurrentQuoteLastTradeTimeProps = {
  latestQuote: SecurityDetailFragment['latestQuote'] | null;
};

export const SecurityCurrentQuoteLastTradeTime = ({
  latestQuote,
}: SecurityCurrentQuoteLastTradeTimeProps) => {
  const { date, shareVolume } = useSelector(
    (state) => state.securityDetails.snapshot,
  );
  const { dateRange, period } = useSelector((state) => state.securityDetails);

  const shareVolumeFormatted = formatNumber(shareVolume);

  const volume = shareVolumeFormatted ?? latestQuote?.tradeVolume;
  return (
    <Flex flexDirection="column">
      {isNotNil(volume) && (
        <PXL height={24} fontWeight={600} color="foregroundNeutralSecondary">
          Volume: {volume}
        </PXL>
      )}
      <LS color="foregroundNeutralSecondary" mt={4}>
        {adjustedDateString(period, dateRange, latestQuote, date as any)}
      </LS>
    </Flex>
  );
};

const adjustedDateString = (
  period: IntradayQuotePeriodEnum | HistoricalQuotePeriodEnum,
  dateRange: SetSecurityDateRange['payload'] | null | undefined,
  latestQuote: SecurityDetailFragment['latestQuote'],
  date?: string | null | undefined,
): string | null | undefined => {
  const localTimeZone = moment.tz.guess();
  const isHistoricalPeriod =
    period !== 'LATEST_DAY' && period !== 'LATEST_WEEK';
  const isOneWeekPeriod = period === 'LATEST_WEEK';

  if (date && isHistoricalPeriod) {
    // scrubber engaged historical
    return moment.utc(date.toLocaleString()).format('MMM Do, YYYY');
  } else if (date && isOneWeekPeriod) {
    // scrubber engaged 1 week
    return moment.tz(date, localTimeZone).format('lll z');
  } else if (dateRange) {
    // default non-intraday display
    return adjustedDateRangeString(dateRange);
  } else if (date) {
    // scrubber engaged intraday
    return moment.tz(date, localTimeZone).format('lll z');
  }
  return latestQuote?.localLastTradeTime || latestQuote?.localAsOfTime;
};

const adjustedDateRangeString = (date: Record<string, any>): string => {
  return (
    date &&
    `${moment
      .utc(date.first.toLocaleString())
      .format('MMM Do, YYYY')} - ${moment
      .utc(date.last.toLocaleString())
      .format('MMM Do, YYYY')}`
  );
};
