import { styled, Box, Input, PM } from '@m1/liquid-react';
import React from 'react'; // default to integers

const DEFAULT_MAX_VALUE = '9999999999';
const DEFAULT_MAX_LENGTH = DEFAULT_MAX_VALUE.length;
const DEFAULT_SCALE = 0;

export type AmountInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onFocus' | 'size' | 'type' | 'value'
> & {
  allowNegative?: boolean;
  error?: string | boolean;
  label?: string;
  maxLength?: number;
  name?: string | undefined;
  onChange: (value: number | string | null | undefined) => void;
  scale?: number;
  validate?: Function[];
  value?: any;
};

const AmountInputContainer = styled(Box)`
  > div > div {
    background-color: transparent;
    border: none;
    box-shadow: none;
    transition: color 0.3s ease-in-out;
    padding-block: 0;
  }

  > div > p {
    display: none;
  }

  & input {
    background-color: transparent;
    text-align: center;
  }
  & input::placeholder {
    transition: color 0.3s ease-in-out;
  }
`;

/*
 * We need to use forwardRef to avoid a console error caused by react-hook-form
 * forwarding the ref to the component. In this case, we ignore the forwarded ref,
 * because we _always_ need a ref and the forwarded ref may be undefined.
 */
export const AmountInput = React.forwardRef(
  (
    {
      allowNegative = false,
      maxLength = DEFAULT_MAX_LENGTH,
      scale = DEFAULT_SCALE,
      value,
      onChange,
      error,
      ...rest
    }: AmountInputProps,
    _ignoredRef,
  ) => {
    const color = error ? 'critical' : 'foregroundNeutralMain';
    return (
      <Box>
        <AmountInputContainer
          font="HXL"
          fontWeight={400}
          color={color}
          css={{
            '& input::placeholder': {
              color,
            },
          }}
        >
          <Input
            {...rest}
            maskType="money"
            maxLength={maxLength}
            mask={{
              allowNegative,
              decimalScale: scale,
            }}
            value={value}
            onValueChange={({ expectedValue }) => {
              // will set value to 0 in cases where amount input field is cleared (no empty input field). In the future we may want to support an empty amount input.
              if (typeof expectedValue === 'undefined') {
                onChange?.(0);
              } else {
                onChange?.(expectedValue);
              }
            }}
          />
        </AmountInputContainer>
        <Box textAlign="center">
          {error && <PM color="critical" content={error} />}
        </Box>
      </Box>
    );
  },
);

export const StyledInput = styled.input<any>`
  width: 100%;
  text-align: center;
`;
