import * as React from 'react';

import { formatCurrency } from '~/utils/formatting';
import { normalizeCurrencyString, onlyNumbers } from '~/utils/normalizers';

export const useAmountRangeValidation = (
  amountValue: string,
  setAmountValue: (arg0: string) => void,
  minAmount: number | null | undefined,
  maxAmount: number | null | undefined,
  hasDecimal: boolean | null | undefined,
  maxErrorMessageCopy?: string | null | undefined,
  minErrorMessageCopy?: string | null | undefined,
): {
  amountErrorMessage: string;
  amountValue: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} => {
  const [amountErrorMessage, setAmountErrorMessage] = React.useState('');
  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // format to number
      const formattedValue = hasDecimal
        ? normalizeCurrencyString(event.target.value)
        : onlyNumbers(event.target.value);
      // input validation
      let hasInvalidError = false;
      let hasMinError = false;
      let hasMaxError = false;

      // check validity
      if (formattedValue === '') {
        hasInvalidError = true;
        setAmountErrorMessage(`Amount must be entered to continue`);
      } else {
        // check min requirement
        if (typeof minAmount === 'number') {
          hasMinError = Number(formattedValue) < minAmount;
          if (hasMinError) {
            if (minErrorMessageCopy) {
              setAmountErrorMessage(minErrorMessageCopy);
            } else {
              setAmountErrorMessage(
                `The minimum amount is ${formatCurrency(minAmount)}`,
              );
            }
          }
        }

        // check max requirement
        if (typeof maxAmount === 'number') {
          hasMaxError = Number(formattedValue) > maxAmount;
          if (hasMaxError) {
            if (maxErrorMessageCopy) {
              setAmountErrorMessage(maxErrorMessageCopy);
            } else {
              setAmountErrorMessage(
                `The maximum amount is ${formatCurrency(maxAmount)}`,
              );
            }
          }
        }
      }

      // if no errors were detected, clear our error message
      if (
        !(hasMinError || hasMaxError || hasInvalidError) &&
        amountErrorMessage !== ''
      ) {
        setAmountErrorMessage('');
      }

      // finally, update input value
      if (formattedValue !== amountValue) {
        setAmountValue(formattedValue);
      }
    },
    [amountErrorMessage, amountValue, setAmountValue, minAmount, maxAmount],
  );
  return {
    amountValue,
    amountErrorMessage,
    onInputChange,
  };
};
