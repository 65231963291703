import isNil from 'lodash-es/isNil';

export type CurrencyFormatEnum =
  | 'DECIMAL' // Always show 2 decimal places
  | 'SELECTIVE' // Show 2 decimal places only if value is not an integer
  | 'COMPACT'; // Show large numbers with a letter up to 4 significant digits

export function formatCurrency(
  value: number,
  format?: Maybe<CurrencyFormatEnum>,
): string;
/* eslint-disable-next-line no-redeclare */
export function formatCurrency(
  value: Maybe<number>,
  format?: Maybe<CurrencyFormatEnum>,
): string | null;
/* eslint-disable-next-line no-redeclare */
export function formatCurrency(
  value: Maybe<string>,
  format?: Maybe<CurrencyFormatEnum>,
): string | null;
/* eslint-disable-next-line no-redeclare */
export function formatCurrency(
  value: Maybe<number> | Maybe<string>,
  format?: Maybe<CurrencyFormatEnum>,
): string | null {
  const currencyFormat = format ?? 'DECIMAL';
  const numericValue = typeof value === 'number' ? value : Number(value);

  if (isNil(value) || isNaN(numericValue)) {
    return null;
  }

  switch (currencyFormat) {
    case 'SELECTIVE':
      return numericValue % 1 === 0
        ? IntegerCashFormatter.format(numericValue)
        : DecimalCashFormatter.format(numericValue);
    case 'COMPACT':
      return CompactCashFormatter.format(numericValue);
    case 'DECIMAL':
    default:
      return DecimalCashFormatter.format(numericValue);
  }
}

/**
 * Do not use this formatter directly for currency.
 * Use formatCurrency instead.
 * @example
 *  formatCurrency.format(100)      // '$100'
 *  formatCurrency.format(1000.5)   // '$1,001'
 */
const IntegerCashFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * Do not use this formatter directly for currency.
 * Use formatCurrency instead.
 *
 * @example
 *  formatCurrency(100)     // '$100.00'
 *  formatCurrency(100.1)   // '$100.10'
 *  formatCurrency(100.123) // '$100.12'
 */
const DecimalCashFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Do not use this formatter directly for currency.
 * Use formatCurrency instead.
 * @example
 *  CompactCashFormatter.format(1000000) // '$1M'
 */
const CompactCashFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 4,
});
