import * as React from 'react';

import type { SecurityDetailsQueryHookResult } from '~/graphql/hooks';

type TSecurityDetailsContext = {
  queryResult: SecurityDetailsQueryHookResult;
  disableActions?: boolean;
  disableBreadcrumbs?: boolean;
  disableWatchlist?: boolean;
  isCrypto: boolean;
} | null;

export const SecurityDetailsContext =
  React.createContext<TSecurityDetailsContext>(null);

export const useSecurityDetailsContext = () => {
  const securityDetails = React.useContext(SecurityDetailsContext);
  if (!securityDetails) {
    throw new Error(
      'useSecurityDetailsContext must be used within a SecurityDetailsProvider',
    );
  }
  return securityDetails;
};
