import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { RewardsTableCell } from '~/components/credit-card/RewardsTableCell';
import type { CreditCardRewardsProgramFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export type RewardsTableViewAllComponentProps = {
  programType: string;
  rewardsProgram:
    | (
        | { node?: CreditCardRewardsProgramFragment | null | undefined }
        | null
        | undefined
      )[]
    | null
    | undefined;
};

export const RewardsTableViewAllTable = ({
  rewardsProgram,
  programType,
}: RewardsTableViewAllComponentProps) => {
  return (
    <>
      <Box mb={32}>
        <GridTable
          emptyMessage="No rewards available"
          gridTemplateColumns="auto 110px"
        >
          <GridTable.HeaderRow>
            <GridTable.HeaderCell label="Security" />
            <GridTable.HeaderCell label="Cash back rate" />
          </GridTable.HeaderRow>
          {rewardsProgram?.map((rewardDetails, index) => {
            return (
              <RewardsTableCell
                key={index}
                programType={programType}
                rewardDetails={rewardDetails?.node}
                index={index}
              />
            );
          })}
        </GridTable>
      </Box>
    </>
  );
};
