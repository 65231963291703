import { useTheme, useThemeMode, ThemeProvider } from '@m1/liquid-react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';
import * as React from 'react';
import { renderToString } from 'react-dom/server';

import { StockChart } from '~/components/charts';
import { useChartableSliceChartQuery } from '~/graphql/hooks';
import type { ChartableSliceChartQuery } from '~/graphql/types';
import { formatCurrency } from '~/utils/formatting';
import { roundToDecimal } from '~/utils/round';

import { CHART_DATUM_KEY } from '../charts/StockChart/StockChart';
import type {
  SeriesTypes,
  StockChartPeriodDuration,
} from '../charts/StockChart/StockChart.types';
import { gradientFillColor } from '../charts/StockChart/StockChart.utils';

import type {
  ChartableSliceDatumPoint,
  ChartableSliceChartProps,
  ChartableSliceChartPeriodDuration,
  ChartableSliceSeries,
  ChartKeys,
} from './ChartableSliceChart.types';
import {
  periodDurationToPeriodEnum,
  sumDividends,
  sumTrades,
} from './ChartableSliceChart.utils';
import { useChartableSliceChartContext } from './ChartableSliceChartContext';
import { ChartableSliceChartFlagTooltip } from './ChartableSliceChartFlagTooltip';
import { ChartableSliceChartTooltip } from './ChartableSliceChartTooltip';

type ChartableSliceNode = ExtractTypenameByKey<
  NonNullable<ChartableSliceChartQuery['nodes']>[number],
  'chartData'
>;

/**
 * A chart for displaying time series data for securities, pies, and portfolios.
 * This is a reusable component for all ChartableSlices which will eventually replace every single one of our charts of security/pie/portfolio time series data.
 *
 * This component takes in an array of chartableSlice IDs, fetches the data for each chartableSlice, and charts them on top of each other.
 *
 * For example, adding the key 'candleSticks' to the features prop will display a button to toggle candlesticks on and off. Without adding that key, the button will not show.
 *
 * - `chartableSliceIds` - An array of IDs which this component fetches the data for each chartableSlice by node ID, and charts them on top of each other. Useful for comparisons or benchmarking.
 * - `defaultPeriod` - The period for the initial query of data. Usually you want to keep this as large of a time period as possible, because Highcharts can only display up to that amount of data.
 * Rather than requery for each time period change, Highcharts "zooms" in on the existing data, hence why you want to initially load as much data as possible.
 * - `periods` - An array of time periods which this component will display as clickable buttons. The chart will animate and zoom to the clicked time period. If you add too many for the width of the chart, the time periods will convert to a dropdown.
 *   - `ChartableSliceChartPeriodDuration` is a type which is a union of all the possible time periods. You can use this type to ensure you are passing in valid time periods that Highcharts supports.
 *     - `d` = days, `w` = weeks, `M` = months, `y` = years - these all allow numbers, ex. `1d`, `2w`, `3M`, `4y`
 *       - `M` is capitalized because Moment uses `m` for minutes, and we need to use the same format for Moment functions
 *     - `ytd` = year to date, `all` = all time - neither of these allow numbers
 * - `features` - An object of features to enable/disable. Adding a key for a feature will display the button, and the boolean value will be if the feature is initially turned on or off
 * - `onRangeSelectionChange` - A callback for when the time period changes, and includes data which can be useful to display elsewhere, such as the percent change between the min and max shown dates.
 * - `chartName` - The name of the chart, which is ONLY used for analytics, do not use to change any component conditions.
 */
export const ChartableSliceChart = ({
  chartableSliceIds,
  chartName,
  defaultPeriod: defaultPeriodProp,
  features = { navigator: true, dateRangeInputs: true, print: false },
  isOnDarkBackground = false,
  initialValueForBacktesting = 100,
  onChangePeriod,
  onRangeSelectionChange,
  periods = ['1w', '1M', '3M', '6M', 'ytd', '1y', '5y', 'all'],
  plotLines,
  height,
  ...rest
}: ChartableSliceChartProps) => {
  const liveDatum = rest.liveDatum;
  const shouldHaveLiveDatum = Object.keys(rest).includes('liveDatum');
  // If live datum is not a provided key in the props, we use default values for
  // these so we don't have to wait for a non-existent live datum below.
  const liveDatumLoading = shouldHaveLiveDatum && !liveDatum;
  const liveDatumReady = shouldHaveLiveDatum ? Boolean(liveDatum) : true;
  const rangeSelectionKey = `${chartName}_range_selection`;
  const [visiblePoints, setVisiblePoints] =
    React.useState<Maybe<Highcharts.Series[]>>(null);
  const getDefaultPeriod = React.useCallback(
    () => {
      return (localStorage.getItem(rangeSelectionKey) ??
        defaultPeriodProp ??
        'all') as ChartableSliceChartPeriodDuration;
    },
    [defaultPeriodProp, rangeSelectionKey], // We don't want this to be a controlled component, so only process default period once
  );

  const theme = useTheme();
  const chartRef = React.useRef<HighchartsReact.RefObject>(null);
  const { setIsChartDataNull } = useChartableSliceChartContext();

  const { data, loading } = useChartableSliceChartQuery({
    variables: {
      ids: chartableSliceIds.filter(Boolean) as string[],
      initialValueForBacktesting,
    },
  });
  const dataNodes = React.useRef<ChartableSliceNode[] | null>(null);

  React.useEffect(() => {
    // Using data directly in useCallbacks will result in it being undefined
    // Using a ref will ensure explicit reference to data
    if (data) {
      dataNodes.current = data.nodes as ChartableSliceNode[];
    }
  }, [data]);

  const currentPeriod =
    React.useRef<ChartableSliceChartPeriodDuration>(getDefaultPeriod());

  const { activeThemeMode } = useThemeMode();

  const handleRangeSelectionChange = React.useCallback(
    (series: Maybe<ChartableSliceSeries[]>) => {
      setVisiblePoints(series);

      if (series?.[0]?.points?.length) {
        let sumDividendsTotal = 0;
        let sumTradesTotal = 0;
        const valueHistory = series?.[0].points;
        // Not sure why these types don't infer correctly
        const start = Object.assign(
          {},
          valueHistory[0],
        ) as ChartableSliceDatumPoint;
        const end = Object.assign(
          {},
          valueHistory[valueHistory.length - 1],
        ) as ChartableSliceDatumPoint;

        const startValue = start.y;
        const endValue = end.y;
        let startDate = moment(start.datum?.date);

        let percentChange = null;
        if (typeof endValue === 'number' && typeof startValue === 'number') {
          const changeInValue = endValue - startValue;
          const percentChangeInValue = changeInValue / (startValue || 1);
          percentChange = roundToDecimal(percentChangeInValue * 100);
        }

        (
          dataNodes.current?.[0] as ChartableSliceNode
        )?.chartData?.additionalData?.forEach((data) => {
          let earliestStartDate = startDate;
          // If the current period is 'all' or 'ytd', we want to get cash flows that may be before the user's value history
          // If 'all', we want all cash flows ever, which may be before the visible value history.
          // If 'ytd', we want cash flows that may have happened since Jan 1 but before their value history started after Jan 1.
          // This is very edge case-y, and should only really happen if the customer asks for cash flows to be manually added.
          if (['all', 'ytd'].includes(currentPeriod.current)) {
            earliestStartDate =
              currentPeriod.current === 'all'
                ? moment(0)
                : moment().startOf('year');
          }
          // Instead of using chart points, use the original data response filtered by the adjusted start date
          // and the chart's end date
          const filteredData = data?.data?.filter((datum) => {
            const date = moment(datum.date);
            return (
              date.isSameOrAfter(earliestStartDate) &&
              date.isSameOrBefore(end.datum?.date)
            );
          });
          // Track the minimum start date for all series so we have an accurate "Starting value date"
          // This starting date tracks the beginning of performance, not just value history, so that's why it can
          // be earlier than the visible value history due to cash flows that may be added before (again, edge-casey)
          startDate = moment.min(startDate, moment(filteredData?.[0]?.date));

          // TODO: These sums are no longer used on the Portfolio Details Page.
          // Can remove if it stays that way when we overhaul Performance.
          if (data?.dataType === 'DIVIDEND' && filteredData) {
            sumDividendsTotal += sumDividends(filteredData);
          }
          if (data?.dataType === 'NET_CASH_FLOW' && filteredData) {
            sumTradesTotal += sumTrades(filteredData);
          }
        });

        // setting this first to ensure the effect below doesn't fire immediately after
        onRangeSelectionChange?.({
          start,
          chartStartDate: moment(startDate),
          end,
          chartEndDate: moment(end.datum?.date),
          percentChange,
          sumDividends: sumDividendsTotal,
          netCashFlow: sumTradesTotal,
        });
      }
    },
    [onRangeSelectionChange, currentPeriod],
  );

  const series = React.useMemo(() => {
    let minYRange = 1;
    let series;
    let additionalSeries: SeriesTypes[] = [];
    if (data?.nodes && liveDatumReady) {
      series = data.nodes
        ?.map((node, i) => {
          if (node) {
            const chartableSlice = node as ChartableSliceNode;

            if (!chartableSlice.chartData.data) {
              return null;
            }
            // make the minimum range 2x the difference between the last close and the second to last close so 1D always shows the general slope
            minYRange = Math.max(
              minYRange,
              2 *
                Math.abs(
                  (chartableSlice.chartData.data?.[
                    chartableSlice.chartData.data?.length - 1
                  ]?.close ?? 0) -
                    (chartableSlice.chartData.data?.[
                      chartableSlice.chartData.data?.length - 2
                    ]?.close ?? 0),
                ),
            );

            const data: ChartKeys =
              chartableSlice.chartData.data?.map((datum) => {
                const parsedDate = Date.parse(datum.date);
                return [
                  parsedDate,
                  datum.close,
                  datum.open,
                  datum.high,
                  datum.low,
                  datum.close,
                  // Above order is required for candlesticks, all values must match keys below
                  datum,
                ];
              }) ?? [];

            if (liveDatum) {
              const liveDatumParsedDate = Date.parse(liveDatum.date);
              const liveDatumChartPoint: ChartKeys[number] = [
                liveDatumParsedDate,
                liveDatum.close,
                liveDatum.open,
                liveDatum.high,
                liveDatum.low,
                liveDatum.close,
                liveDatum,
              ];
              data.push(liveDatumChartPoint);
            }
            if (chartableSlice.chartData.additionalData) {
              additionalSeries = chartableSlice.chartData.additionalData.map(
                (dataSeries) => {
                  const data =
                    dataSeries?.data?.map(
                      ({
                        date,
                        sumDividends,
                        sumTrades,
                        dividends,
                        trades,
                      }) => {
                        const isDividend = dataSeries.dataType === 'DIVIDEND';
                        const parsedDate = Date.parse(date);
                        const item = isDividend ? dividends : trades;
                        const text = item
                          ?.map(({ symbol, amount }) => {
                            return `${symbol}: ${formatCurrency(amount)}`;
                          })
                          .join('<br/>');
                        let title;
                        if (dataSeries?.dataType === 'DIVIDEND') {
                          title = 'D';
                        }
                        if (dataSeries?.dataType === 'NET_CASH_FLOW') {
                          title = 'C';
                        }
                        return {
                          x: parsedDate,
                          sumDividends,
                          sumTrades,
                          title,
                          text,
                          top: item
                            ? ([...item]
                                ?.sort(
                                  (a, b) =>
                                    Math.abs(b.amount) - Math.abs(a.amount),
                                )
                                ?.slice(0, 5) ?? []) // get the top 5 dividends/cashflows by value
                            : null,
                        };
                      },
                    ) ?? [];
                  let shape;
                  let type = 'line';
                  let lineColor;
                  if (dataSeries?.dataType === 'DIVIDEND') {
                    shape = 'circlepin';
                    type = 'flags';
                    lineColor = theme.colors.datapointGood;
                  } else if (dataSeries?.dataType === 'NET_CASH_FLOW') {
                    shape = 'squarepin';
                    type = 'flags';
                    lineColor = theme.colors.datapointTint;
                  }
                  return {
                    name: dataSeries?.name,
                    data,
                    accessibility: {
                      exposeAsGroupOnly: true,
                      description: dataSeries?.name,
                    },
                    shape,
                    width: 16,
                    dataType: dataSeries?.dataType,
                    style: {
                      fontSize: '11px',
                    },
                    type,
                    visible: false,
                    onSeries:
                      dataSeries?.dataType === 'NET_CASH_FLOW'
                        ? dataSeries?.onSeriesId // only cash flows should be attached to the series line
                        : undefined,
                    borderRadius:
                      dataSeries?.dataType === 'NET_CASH_FLOW' ? 4 : undefined,
                    lineColor,
                    fillColor: theme.colors.backgroundNeutralSecondary,
                    states: {
                      hover: {
                        lineColor,
                        fillColor: lineColor,
                      },
                    },
                    tooltip: {
                      customTooltipPerSeries: function (
                        this: Highcharts.TooltipFormatterContextObject,
                      ) {
                        const point = this.point as ChartableSliceDatumPoint;
                        const totalYValue =
                          dataSeries?.dataType === 'DIVIDEND'
                            ? point.sumDividends
                            : point.sumTrades;
                        return renderToString(
                          <ThemeProvider>
                            <ChartableSliceChartFlagTooltip
                              point={point}
                              totalYValue={totalYValue}
                              dataType={dataSeries?.dataType}
                              x={this.x}
                            />
                          </ThemeProvider>,
                        );
                      },
                    },
                  } as SeriesTypes;
                },
              );
            }
            return {
              name: chartableSlice.chartData.name,
              data,
              id: chartableSlice.chartData.seriesId,
              // Order here matters for some types, such as candlesticks. Using `keys` allows us to provide this data for all types
              // and access this data in our callback functions, like in `target.chart.series`
              keys: [
                'x',
                'y',
                'open',
                'high',
                'low',
                'close',
                // Above order is required for candlesticks, all keys must match values above
                CHART_DATUM_KEY,
              ],
              type: 'area',
              tooltip: {
                customTooltipPerSeries: function (
                  this: Highcharts.TooltipFormatterContextObject,
                ) {
                  const firstVisibleY = visiblePoints?.[0]?.points?.[0]?.y;

                  // firstVisibleY could be 0, so we just want to check truthiness so we don't get infinite gains
                  const percentChange =
                    firstVisibleY && typeof this.y === 'number'
                      ? ` (${(((this.y - firstVisibleY) / firstVisibleY) * 100).toFixed(2)}%)`
                      : '';
                  return renderToString(
                    <ThemeProvider>
                      <ChartableSliceChartTooltip
                        percentChange={percentChange}
                        y={this.y}
                        x={this.x}
                      />
                    </ThemeProvider>,
                  );
                },
              },
              color:
                theme.chartColors[i % theme.chartColors.length][
                  isOnDarkBackground ? 'datapointFeature' : 'datapoint'
                ],
              fillColor: gradientFillColor(activeThemeMode),
            } as SeriesTypes;
          }
          return null;
        })
        .filter((s): s is SeriesTypes => Boolean(s));
    }

    if (series && additionalSeries.length) {
      series.push(...additionalSeries);
    }

    return series;
  }, [
    activeThemeMode,
    data?.nodes,
    isOnDarkBackground,
    liveDatum,
    liveDatumReady,
    theme,
    visiblePoints,
  ]);

  React.useEffect(() => {
    if (chartRef.current) {
      const { chart } = chartRef.current;
      if (loading || liveDatumLoading) {
        // @ts-ignore-error no type exported for this module's function
        chart?.hideNoData();
        chart?.showLoading();
      } else if (!loading && !liveDatumLoading) {
        chart?.hideLoading();
        if (chart?.series.length === 0 && series?.length === 0) {
          // @ts-ignore-error no type exported for this module's function
          chart?.showNoData();
          setIsChartDataNull(true);
        }
      }
    }
  }, [series, loading, setIsChartDataNull, liveDatumLoading]);

  return (
    <StockChart
      chartName={chartName}
      chartRef={chartRef}
      features={features}
      height={height}
      isLoading={loading || liveDatumLoading}
      isOnDarkBackground={isOnDarkBackground}
      onChangePeriod={React.useCallback(
        (period: StockChartPeriodDuration) => {
          currentPeriod.current = period;
          onChangePeriod?.(periodDurationToPeriodEnum(period));
        },
        [onChangePeriod],
      )}
      onRangeSelectionChange={handleRangeSelectionChange}
      periods={periods}
      plotLines={plotLines}
      series={series}
    />
  );
};
