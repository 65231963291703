import React from 'react';

import { useWatchlistItemsQuery } from '~/graphql/hooks';
import type { WatchlistItemFragment } from '~/graphql/types';
import { Table } from '~/toolbox/table';
import { tableSort } from '~/utils';

import { WatchlistItemRow } from './WatchlistItemRow';
import { WatchlistTableHeader } from './WatchlistTableHeader';

type WatchlistProps = {
  amountToDisplay?: number;
  emptyMessage?: string;
  nodeIds: Array<string> | null | undefined;
  onlyActive?: boolean;
  sortable?: boolean;
  sortDirection?: 'ASC' | 'DESC';
  sortKey?: 'name' | 'price' | 'change' | 'marketCap' | 'peRatio';
};

type WatchlistState = {
  direction: 'ASC' | 'DESC';
  key: string;
};
const sortKeyMap: Record<string, string> = {
  name: 'name',
  price: 'latestQuote.lastPrice',
  change: 'latestQuote.percentChangeFromPreviousClosePrice',
  marketCap: 'fundamentals.marketCap',
  peRatio: 'fundamentals.peRatio',
};

export const WatchistItemsTable = ({
  amountToDisplay,
  emptyMessage = 'No securities on your watchlist yet',
  nodeIds,
  onlyActive = false,
  sortDirection = 'ASC',
  sortKey = 'name',
  sortable = true,
}: WatchlistProps) => {
  const [sort, setSort] = React.useState<WatchlistState>({
    direction: sortDirection,
    key: sortKey,
  });
  const { data } = useWatchlistItemsQuery({
    variables: {
      ids: nodeIds!,
    },
    skip: !nodeIds,
  });

  if (!data) {
    return null;
  }

  const positions = data.nodes as WatchlistItemFragment[] | null | undefined;

  const handleTableHeaderClick = (key: string): void => {
    if (!sortable) {
      return;
    }

    let direction = sort.direction;
    if (sort.key === key) {
      direction = direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      direction = 'ASC'; // Default to Ascending
    }

    setSort({
      direction,
      key,
    });
  };

  const sortedSliceables = positions
    ? tableSort(positions, sortKeyMap[sort.key], sort.direction)
        .filter(Boolean)
        .filter((position) => {
          return onlyActive ? position?.isActive : true;
        })
    : [];

  return (
    <Table>
      <Table.Head fixed>
        <Table.Row>
          <WatchlistTableHeader
            headerKey="name"
            label="Name"
            handleTableHeaderClick={handleTableHeaderClick}
            width="40%"
            align="left"
            shouldSort={sortable && sort.key === 'name' ? sort.direction : null}
          />
          <WatchlistTableHeader
            headerKey="price"
            label="Price"
            handleTableHeaderClick={handleTableHeaderClick}
            width="15%"
            align="right"
            shouldSort={
              sortable && sort.key === 'price' ? sort.direction : null
            }
          />
          <WatchlistTableHeader
            headerKey="change"
            label="Change"
            handleTableHeaderClick={handleTableHeaderClick}
            width="15%"
            align="right"
            shouldSort={
              sortable && sort.key === 'change' ? sort.direction : null
            }
          />
          <WatchlistTableHeader
            headerKey="marketCap"
            label="Market Cap"
            handleTableHeaderClick={handleTableHeaderClick}
            width="15%"
            align="right"
            shouldSort={
              sortable && sort.key === 'marketCap' ? sort.direction : null
            }
          />
          <WatchlistTableHeader
            headerKey="peRatio"
            label="P/E Ratio"
            handleTableHeaderClick={handleTableHeaderClick}
            width="15%"
            align="right"
            shouldSort={
              sortable && sort.key === 'peRatio' ? sort.direction : null
            }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body emptyMessage={emptyMessage}>
        {sortedSliceables
          .map((sliceable, index) => (
            <WatchlistItemRow
              amountToDisplay={amountToDisplay}
              index={index}
              item={sliceable}
              key={index}
            />
          ))
          .filter(Boolean)}
      </Table.Body>
    </Table>
  );
};
