import { Button } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { connectForm } from '~/hocs';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { MicroDepositField } from './fields';

type Props = InjectedFormProps<any> & {
  onCancel: () => void;
  routeOnSuccess: string;
};

class VerifyMicroDepositsFormComponent extends React.Component<Props> {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <MicroDepositField name="depositAmount1" label="Deposit 1" />
        <MicroDepositField name="depositAmount2" label="Deposit 2" />
        <ButtonGroup mt={64}>
          <Button
            label="Cancel"
            kind="secondary"
            onClick={this.props.onCancel}
            size="large"
          />
          <Button label="Verify" kind="primary" size="large" type="submit" />
        </ButtonGroup>
      </form>
    );
  }
}

export const VerifyMicroDepositsForm = connectForm({
  form: 'verify-micro-deposits',
})(VerifyMicroDepositsFormComponent);
