import React from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import {
  usePersonalLoansDirectQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type { PersonalLoanDirectQuestion } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import type { AppState } from '~/redux';
import { submitReactFormData } from '~/redux/actions';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { BackButton } from '../BackButton';
import { FinancialDisclosuresForm } from '../components/FinancialDisclosuresForm';
import {
  financialDisclosuresFormDefaultValues,
  type FinancialDisclosuresFormValues,
} from '../components/FinancialDisclosuresForm.constants';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export const FinancialDisclosures = () => {
  const analytics = useAnalytics();
  const { goTo, back } = usePersonalLoanDirectWizardContext();
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const [updateProfileMutation, { loading: isUpdating }] =
    useUpdateProfileMutation();

  const persistedFormValues = useSelector((state: AppState) => {
    return state.reactHookForm['financialDisclosures'];
  });

  const { employment } = useSelector((state: AppState) => {
    return state.reactHookForm['directToPersonalLoansEmploymentInfo'];
  });
  const { availableCashNetWorth } = useSelector((state: AppState) => {
    return state.reactHookForm[
      'directToPersonalLoansAvailableCashNetWorthInfo'
    ];
  });

  const formMethods = useForm<FinancialDisclosuresFormValues>({
    defaultValues: financialDisclosuresFormDefaultValues,
    mode: 'onBlur',
  });

  const { data: questionsData, loading: questionsLoading } =
    usePersonalLoansDirectQuery();

  const questions =
    questionsData?.viewer?.borrow?.personalLoans?.applicationFlowDirect
      ?.questions;

  let props: Maybe<Partial<PersonalLoanDirectQuestion>> | undefined;

  if (questions) {
    props = getQuestionProps({
      question: 'FINANCIAL_DISCLOSURES',
      questions,
    });
  }
  const { continueLabel, title, nextQuestionType } = props || {};

  React.useEffect(() => {
    formMethods.reset(persistedFormValues);
  }, [persistedFormValues, formMethods]);

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/disclosures');
  }, [analytics]);

  const onSubmit: SubmitHandler<FinancialDisclosuresFormValues> = async (
    formData: any,
  ) => {
    updateProfileMutation({
      variables: {
        input: {
          profile: {
            suitability: {
              liquidNetWorth: availableCashNetWorth,
            },
            primary: {
              employment,
              controlPersonDisclosure: formData.controlPersonDisclosure,
              exchangeOrFinraAffiliationDisclosure:
                formData.exchangeOrFinraAffiliationDisclosure,
              politicalExposureDisclosure: formData.politicalExposureDisclosure,
              backupWithholding: {
                isSubjectToBackupWithholding:
                  formData.isSubjectToBackupWithholding,
              },
            },
          },
        },
      },
      onCompleted: () => {
        dispatch(submitReactFormData({ financialDisclosures: formData }));
        goTo(nextQuestionType ?? 'HARD_PULL_CONSENT');
      },
      onError: (error) => {
        addToast({
          content: error.message,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  if (questionsLoading) {
    return <Spinner fullScreen centered />;
  }

  return isUpdating ? (
    <Spinner fullScreen centered />
  ) : (
    <div>
      <BackButton onBackClick={() => back()} />
      <StyledHeader content={title ?? 'Do any of these apply?'} mb={24} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FinancialDisclosuresForm />
          <ResponsiveButton
            mt={48}
            type="submit"
            size="large"
            label={continueLabel || 'Continue'}
            kind="primary"
          />
        </form>
      </FormProvider>
    </div>
  );
};
