import * as React from 'react';

import { CollectProfileInput } from '~/components/form/Inputs/CollectProfileInput';
import { LoadingStatus } from '~/components/LoadingStatus';
import { submittedInvestProfileInput } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import {
  type INVEST_ONBOARDING_FLOW_STEP_VALUES,
  RiskToleranceEnumDropdownOptions,
  INVEST_ONBOARDING_FLOW_STEPS as STEPS,
  TimeHorizonEnumDropdownOptions,
} from '~/static-constants';
import { getEnumEntries } from '~/utils';

import { CollectIntroductionSourceStep } from '../CollectIntroductionSourceStep/CollectIntroductionSourceStep';

import {
  CollectTrustedContactStep,
  InvestConfirmationStep,
  SystemUnavailableStep,
} from './steps';

export type InvestOnboardingContainerProps = {
  step: INVEST_ONBOARDING_FLOW_STEP_VALUES;
};

export const InvestOnboardingContainer = ({
  step,
  ...rest
}: InvestOnboardingContainerProps) => {
  const dispatch = useDispatch();

  const collectProfileOnClick = React.useCallback(
    (field: string, value: string) => {
      dispatch(
        submittedInvestProfileInput({
          field,
          value,
        }),
      );
    },
    [dispatch],
  );

  const steps = {
    [STEPS.LOAD_STATUS]: <LoadingStatus {...rest} />,
    [STEPS.SYSTEM_UNAVAILABLE]: <SystemUnavailableStep {...rest} />,
    [STEPS.COLLECT_INVESTMENT_EXPERIENCE]: (
      <CollectProfileInput
        field="suitability.investmentExperience"
        question="What's your level of investment experience?"
        options={getEnumEntries('InvestmentExperienceEnum')}
        onClick={(value: string) =>
          collectProfileOnClick('suitability.investmentExperience', value)
        }
        {...rest}
      />
    ),
    [STEPS.COLLECT_RISK_TOLERANCE]: (
      <CollectProfileInput
        field="suitability.riskTolerance"
        question="What's your risk tolerance?"
        tooltip={{
          table: {
            header: [
              'Risk Tolerance',
              'Comfort with big swings in investment value',
              'Potential for long-term growth',
            ],
            content: [
              ['Low', '+', '+'],
              ['Medium', '++', '++'],
              ['High', '+++', '+++'],
            ],
          },
        }}
        options={RiskToleranceEnumDropdownOptions}
        onClick={(value: string) =>
          collectProfileOnClick('suitability.riskTolerance', value)
        }
        {...rest}
      />
    ),
    [STEPS.COLLECT_TIME_HORIZON]: (
      <CollectProfileInput
        field="suitability.timeHorizon"
        question="How long are you planning to invest?"
        options={TimeHorizonEnumDropdownOptions}
        onClick={(value: string) =>
          collectProfileOnClick('suitability.timeHorizon', value)
        }
        {...rest}
      />
    ),
    [STEPS.COLLECT_LIQUIDITY_NEEDS]: (
      <CollectProfileInput
        field="suitability.liquidityNeeds"
        question="How important is liquidity to you?"
        tooltip={{
          text: [
            "Here, we're hoping to learn how important is it for you to be able to turn your investments into cash quickly, without losing much value.",
          ],
        }}
        options={getEnumEntries('LiquidityNeedsEnum')}
        onClick={(value: string) =>
          collectProfileOnClick('suitability.liquidityNeeds', value)
        }
        {...rest}
      />
    ),
    [STEPS.COLLECT_INTRODUCTION_SOURCE]: <CollectIntroductionSourceStep />,
    [STEPS.COLLECT_TRUSTED_CONTACT]: <CollectTrustedContactStep {...rest} />,
    [STEPS.REVIEW]: <InvestConfirmationStep {...rest} />,
  } as Record<INVEST_ONBOARDING_FLOW_STEP_VALUES, JSX.Element>;

  const Step = steps[step];
  return Step ? Step : null;
};
