import type { TransferCreationDetailsFragment } from '~/graphql/types';
import type { ToastProps } from '~/toolbox/toast';

export const formatToastConfig = (
  details: TransferCreationDetailsFragment | null | undefined,
  completedToastConfig: ToastProps,
) => {
  completedToastConfig.content =
    details?.description ?? completedToastConfig.content;
  if (details?.detailsLink?.internalPath) {
    completedToastConfig.link = {
      to: details.detailsLink.internalPath,
      children: 'View details',
    };
  }
  return completedToastConfig;
};
