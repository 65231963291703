import { Box, Input } from '@m1/liquid-react';
import * as React from 'react';

import { useAmountRangeValidation } from '~/hooks/useAmountRangeValidation';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import { DEFAULT_FULFILLMENT_CONDITION_FIELDS } from './constants';

type CreditBorrowedCapProps = {
  fulfillmentConditionRequirements: any | null | undefined;
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onIsContinueAllowedChange: (isAllowed: boolean) => void;
  preSelectedFulfillmentConditionDataOfActiveContra:
    | SmartTransferFulfillmentConditionInput
    | null
    | undefined;
};

export const CreditBorrowedCap = ({
  fulfillmentConditionRequirements,
  onFulfillmentDataChange,
  onIsContinueAllowedChange,
  preSelectedFulfillmentConditionDataOfActiveContra,
}: CreditBorrowedCapProps) => {
  const defaultAmountValue =
    typeof preSelectedFulfillmentConditionDataOfActiveContra?.borrowAmount ===
    'number'
      ? `${preSelectedFulfillmentConditionDataOfActiveContra.borrowAmount}`
      : '';
  const [amountValueInitial, setAmountValue] =
    React.useState(defaultAmountValue);
  const { onInputChange, amountErrorMessage, amountValue } =
    // @ts-expect-error - TS2554 - Expected 5-7 arguments, but got 4.
    useAmountRangeValidation(
      amountValueInitial,
      setAmountValue,
      fulfillmentConditionRequirements?.minCapAmount,
      fulfillmentConditionRequirements?.maxCapAmount,
    );
  // fire parent callback to sync continue state
  React.useEffect(() => {
    const isContinueAllowed = amountValue !== '' && !amountErrorMessage;
    onIsContinueAllowedChange(isContinueAllowed);
  }, [amountValue, amountErrorMessage, onIsContinueAllowedChange]);

  // when fulfillment condition data changes, fire callback
  React.useEffect(() => {
    onFulfillmentDataChange({
      ...DEFAULT_FULFILLMENT_CONDITION_FIELDS,
      borrowAmount: amountValue === '' ? null : Number(amountValue),
      fulfillmentConditionType: 'CREDIT_BORROWED_CAP',
    });
  }, [amountValue, onFulfillmentDataChange]);
  return (
    <Box>
      <Input
        autoComplete="off"
        onChange={onInputChange}
        value={amountValue}
        error={amountErrorMessage}
        label="Enter amount"
        maskType="money"
      />
    </Box>
  );
};
