import type {
  ChartableSliceDatumFragment,
  PortfolioSlicePerformancePeriodEnum,
} from '~/graphql/types';

import type { StockChartPeriodDuration } from '../charts/StockChart/StockChart.types';

import type { ChartableSliceDatumPoint } from './ChartableSliceChart.types';

const pointsArePoints = (point: any): point is ChartableSliceDatumPoint => {
  return point?.datum !== undefined;
};

export const sumDividends = (
  points: Array<ChartableSliceDatumPoint | ChartableSliceDatumFragment> | null,
) => {
  return (
    points?.reduce((sum: number, point) => {
      const dividends = pointsArePoints(point)
        ? point.datum?.sumDividends
        : point.sumDividends;
      return sum + (dividends ?? 0);
    }, 0) ?? 0
  );
};

export const sumTrades = (
  points: Array<ChartableSliceDatumPoint | ChartableSliceDatumFragment> | null,
) => {
  return (
    points?.reduce((sum: number, point) => {
      const trades = pointsArePoints(point)
        ? point.datum?.sumTrades
        : point.sumTrades;
      return sum + (trades ?? 0);
    }, 0) ?? 0
  );
};

export const periodDurationToPeriodEnum = (
  periodDuration: StockChartPeriodDuration,
): PortfolioSlicePerformancePeriodEnum => {
  switch (periodDuration) {
    case 'ytd':
      return 'YTD';
    case 'all':
      return 'MAX';
    default: {
      const duration = parseInt(periodDuration, 10);
      if (isNaN(duration)) {
        // return all by default
        return 'MAX';
      }
      const timePeriod = periodDuration.slice(-1);

      switch (timePeriod) {
        case 'd':
          return 'ONE_DAY';
        case 'w':
          return 'ONE_WEEK';
        case 'M':
          return periodDuration === '3M' ? 'ONE_QUARTER' : 'ONE_MONTH';
        case 'y':
          return 'ONE_YEAR';
        default:
          // return all by default
          return 'MAX';
      }
    }
  }
};
