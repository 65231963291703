import { ApolloError } from '@apollo/client';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, getContext, put } from 'redux-saga/effects';

import { AuthManager } from '~/auth';
import {
  ResetPasswordDocument,
  type ResetPasswordMutationResult,
} from '~/graphql/hooks';
import type { ResetPasswordInput } from '~/graphql/types';
import {
  dismissNotifications,
  displayNotification,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';
import {
  NOTIFICATION_LOCATIONS,
  NOTIFICATION_TYPES,
  RESET_PASSWORD_FLOW_STEPS as STEPS,
} from '~/static-constants';

import { apolloMutationSaga } from '../../apolloMutationSaga';
import { bootstrapAuthedUser } from '../../bootstrapAuthedUser';
import { getLoggers, refreshTokenTimeout } from '../../common';
import { changeStep, makeFlowFuncs } from '../utils';

const { selectFlowState, takeFlow, takeFlowStep } =
  makeFlowFuncs('RESET_PASSWORD');

export function* resetPasswordSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginResetPasswordFlow);
}

function* beginResetPasswordFlow(action: any): SagaIterator<void> {
  const { onFinish } = action.payload;

  yield fork(takeFlowStep, STEPS.INPUT_NEW_PASSWORD, submittedNewPassword);
  yield fork(takeFlowStep, STEPS.ACCOUNT_CHALLENGE, submittedAccountChallenge);
  yield fork(
    takeFlowStep,
    STEPS.ACCOUNT_CHALLENGE_FAILED,
    reviewdChallengeFailure,
    onFinish,
  );
  yield fork(takeFlowStep, STEPS.SUCCESS, reviewedSuccess, onFinish);
}

function* submittedNewPassword(): SagaIterator<void> {
  const { analytics } = yield call(getLoggers);

  try {
    yield put(dismissNotifications());
    yield put(showLoadingSpinner());

    const input = yield call(selectFlowState, 'input');
    const result: ResetPasswordMutationResult = yield call(apolloMutationSaga, {
      mutation: ResetPasswordDocument,
      variables: {
        input: {
          newPassword: input.newPassword,
          resetPasswordToken: input.resetPasswordToken,
          ssnLastFour: input.ssnLastFour,
        } satisfies ResetPasswordInput,
      },
    });

    yield fork(handleLoginAfterResetSuccess, result);

    analytics.mutation('account', 'resetPasswordCompleted');
  } catch (e: any) {
    const SSN_CHALLENGE_REQUIRED = (e as ApolloError).graphQLErrors.some(
      (err) => err.extensions.code === 'SSN_CHALLENGE_REQUIRED',
    );
    if (SSN_CHALLENGE_REQUIRED) {
      yield call(changeStep, STEPS.ACCOUNT_CHALLENGE);
    } else {
      const message = yield call(getErrorMessage, e);
      yield put(notify(message));
    }
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* submittedAccountChallenge(): SagaIterator<void> {
  try {
    yield put(showLoadingSpinner());

    const input = yield call(selectFlowState, 'input');
    const result: ResetPasswordMutationResult = yield call(apolloMutationSaga, {
      mutation: ResetPasswordDocument,
      variables: {
        input: {
          newPassword: input.newPassword,
          resetPasswordToken: input.resetPasswordToken,
          ssnLastFour: input.ssnLastFour,
        } satisfies ResetPasswordInput,
      },
    });
    yield fork(handleLoginAfterResetSuccess, result);
  } catch (e: any) {
    yield call(changeStep, STEPS.ACCOUNT_CHALLENGE_FAILED);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

export function* reviewdChallengeFailure(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish, {
    didSucceed: false,
  });
}

export function* reviewedSuccess(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish, {
    didSucceed: true,
  });
}

/*
 * "Private" functions
 */
function* handleLoginAfterResetSuccess(
  tokens: ResetPasswordMutationResult,
): SagaIterator<void> {
  const auth: AuthManager = yield getContext('auth');
  auth.updateRequiredAuth(tokens.data?.resetPassword?.outcome);
  yield all([call(bootstrapAuthedUser), fork(refreshTokenTimeout)]);
  yield call(changeStep, STEPS.SUCCESS);
}

function getErrorMessage(e: ApolloError): string {
  for (const err of e.graphQLErrors) {
    if (err.extensions.code !== null) {
      const code = err.extensions.code;
      switch (code) {
        case 'EXPIRED_RESET_PASSWORD_TOKEN':
          return 'The password reset token has expired. Please try again.';
        case 'UNKNOWN_RESET_PASSWORD_TOKEN':
          return 'Sorry, password reset not valid. Please try again.';
        case 'INVALID_NEW_PASSWORD':
          return 'Please enter a more secure password. (Tip: try adding a symbol!)';
        case 'NO_RESET_WITH_2FA':
          return 'Automated password reset is not allowed when two factor authentication is enabled. Please contact support to reset your password.';
        case 'USER_ACCOUNT_LOCKED':
          return 'Your account is currently locked. Please contact support.';
        case 'RESET_ATTEMPT_REJECTED':
          return 'Sorry, automated password reset is not available for your account. Please contact support to reset your password.';
        default:
          return 'Something went wrong with your request. Please try again later or contact support.';
      }
    }
  }
  return 'Something went wrong with your request. Please try again later or contact support.';
}

function notify(message: string) {
  return displayNotification({
    type: NOTIFICATION_TYPES.ERROR_ALT,
    location: NOTIFICATION_LOCATIONS.RESET_PASSWORD,
    message,
  });
}
