import {
  Flex,
  type CardProps,
  type SemanticColorNames,
  type TooltipProps,
} from '@m1/liquid-react';
import * as React from 'react';

import { StyledCloseIcon } from '~/components/announcement/AnnouncementCard/StyledAnnouncementCard';
import { AppButtons } from '~/components/AppButtons';
import type { AppCardFragment, RichTextFragment } from '~/graphql/types';
import { useRecordDisplayAppAnalyticsEvent } from '~/hooks/useRecordDisplayAppAnalyticsEvent';
import { AppImage } from '~/lens-toolbox/AppImage';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { Collapse } from '~/toolbox/collapse';

import {
  AppCardBodyContainer,
  AppCardButtonContainer,
  StyledCollapseIcon,
  StyledAppCard,
} from './AppCard.styled';

type AppCardProps = {
  appCard: AppCardFragment;
  isElevated?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  /**
   * If false, display analytics will not be triggered. Useful if you need to trigger events manually because of when it may be rendered.
   */
  allowDisplayAnalytics?: boolean;
  hasSiblingCards?: boolean;
} & CardProps;

const linkButtonProps = { fullWidth: true };

export const AppCard = ({
  appCard,
  isElevated,
  tooltipPlacement,
  allowDisplayAnalytics = true,
  ...rest
}: AppCardProps) => {
  const {
    backgroundColor,
    borderColor,
    highlightColor,
    tappableCta,
    icon,
    title,
    isCollapsible,
    isDismissible,
    paragraphs,
    primaryCta,
    secondaryCta,
  } = appCard ?? {};
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const appCardBodyRef = React.useRef<HTMLDivElement>(null);
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);

  const [buttonContainerIsColumn, setButtonContainerIsColumn] =
    React.useState(false);

  const handleAppCardClick = () => {
    // TODO: Implement onClick for AppCard if tappableCta is present when it is needed
  };

  React.useEffect(() => {
    const checkWidths = () => {
      const appCardBodyWidth =
        appCardBodyRef?.current?.getBoundingClientRect().width ?? 0;

      const buttonContainerContentWidth = Array.from(
        buttonContainerRef?.current?.children || [],
      ).reduce((acc, child) => {
        return acc + child.getBoundingClientRect().width;
      }, 0);

      if (buttonContainerContentWidth > appCardBodyWidth) {
        setButtonContainerIsColumn(true);
      } else {
        setButtonContainerIsColumn(false);
      }
    };

    // Run on mount
    checkWidths();

    // Add event listener to re-check on window resize
    window.addEventListener('resize', checkWidths);

    // Cleanup
    return () => window.removeEventListener('resize', checkWidths);
  }, []);

  useRecordDisplayAppAnalyticsEvent(
    allowDisplayAnalytics ? appCard.displayAnalyticsEvent : null,
  );

  return (
    <StyledAppCard
      isElevated={isElevated ?? false}
      backgroundColor={backgroundColor as SemanticColorNames}
      borderColor={borderColor as SemanticColorNames}
      hoverColor={highlightColor as SemanticColorNames}
      onClick={tappableCta ? handleAppCardClick : undefined}
      {...rest}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          {icon && <AppImage appImage={icon} style={{ marginRight: '12px' }} />}
          {title && (
            <RichText
              richText={title as RichTextFragment}
              containerProps={{
                display: 'inline-block',
              }}
              textProps={{ font: 'PXL', fontWeight: 300 }}
            />
          )}
        </Flex>
        {isCollapsible && (
          <StyledCollapseIcon
            name={isCollapsed ? 'caretDown24' : 'caretUp24'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{ marginLeft: '12px' }}
            color="foregroundNeutralSecondary"
          />
        )}
        {/* TODO: Implement isDismissible onClick handling when it is needed */}
        {isDismissible && (
          <StyledCloseIcon name="close24" color="foregroundNeutralSecondary" />
        )}
      </Flex>
      {/* @ts-expect-error - TS2769 - No overload matches this call. */}
      <Collapse isOpened={!isCollapsed}>
        <AppCardBodyContainer
          ref={appCardBodyRef}
          gap={
            (paragraphs?.length && paragraphs.length > 1) ||
            primaryCta ||
            secondaryCta
              ? 24
              : 0
          }
          mt={title ? 16 : 0}
        >
          <Flex flexDirection="column" maxWidth={1000} gap={16}>
            {/* @ts-ignore Temporary until LIRE upgrade. */}
            {paragraphs?.map((paragraph, idx) => (
              <RichText
                key={idx}
                richText={paragraph as RichTextFragment}
                textProps={{ font: 'PL' }}
                containerProps={{
                  display: 'inline-block',
                }}
                linkProps={{
                  paddingRight: '4px',
                }}
                tooltipProps={{
                  placement: tooltipPlacement,
                  // Append to body so tooltips can't be cut off by overflow hidden, etc.
                  appendTo: () => document.body,
                }}
              />
            ))}
          </Flex>
          {(primaryCta || secondaryCta) && (
            <AppCardButtonContainer
              isColumn={Boolean(buttonContainerIsColumn)}
              ref={buttonContainerRef}
            >
              <AppButtons
                button={primaryCta}
                linkButtonProps={linkButtonProps}
              />
              <AppButtons
                button={secondaryCta}
                linkButtonProps={linkButtonProps}
              />
            </AppCardButtonContainer>
          )}
        </AppCardBodyContainer>
      </Collapse>
    </StyledAppCard>
  );
};
