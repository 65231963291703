import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { SelectPaymentsAccountDocument } from '~/graphql/hooks';
import type { SelectPaymentsAccountInput } from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import {
  ACTION_TYPES as ACTIONS,
  displayErrorNotification,
  displaySuccessNotification,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';

import { apolloMutationSaga } from './apolloMutationSaga';

export function* selectPaymentsAccount(): SagaIterator {
  yield takeEvery(
    ACTIONS.SELECT_PAYMENTS_ACCOUNT,
    function* (action: any): SagaIterator {
      const accountId = action.payload.accountId;
      const navigate: NavigateFunction = yield select(
        (state) => state.routing.navigate,
      );

      try {
        yield put(showLoadingSpinner());

        yield call(apolloMutationSaga, {
          mutation: SelectPaymentsAccountDocument,
          variables: {
            input: {
              accountId,
            } satisfies SelectPaymentsAccountInput,
          },
        });

        const message = 'Payment account successfully updated.';
        yield put(displaySuccessNotification(message));
      } catch (e: any) {
        yield put(displayErrorNotification(e.message));
      } finally {
        yield put(hideLoadingSpinner());
        if (action.payload.navigateTo) {
          yield call(navigate, { to: action.payload.navigateTo });
        }
      }
    },
  );
}
