import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { AppRichTextDisclosureLinkFragment } from '~/graphql/types';
import { AppRichTextDisclosureLink } from '~/lens-toolbox/AppDisclosureLink/AppDisclosureLink';

const BorderContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
`;

const StyledDisclosureContainer = styled(Flex)`
  flex-direction: column;
  padding: 8px 16px;
  gap: 8px;
  height: 72px;
  width: 100%;
  max-width: 1200px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    flex-direction: row;
    padding: 24px 80px;
    justify-content: space-between;
  }
`;

export const SharePieDisclosures = ({
  disclosures,
}: {
  disclosures: AppRichTextDisclosureLinkFragment;
}) => {
  return (
    <BorderContainer>
      <StyledDisclosureContainer>
        <AppRichTextDisclosureLink appRichTextDisclosureLink={disclosures} />
      </StyledDisclosureContainer>
    </BorderContainer>
  );
};
