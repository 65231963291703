import * as React from 'react';

import { CommonTransactionAmountCell } from '~/components/CommonTransactionsTable/CommonTransactionAmountCell';
import { CommonTransactionDescriptionCell } from '~/components/CommonTransactionsTable/CommonTransactionDescriptionCell';
import { CommonTransactionValueCell } from '~/components/CommonTransactionsTable/CommonTransactionValueCell';
import type {
  CommonTransaction,
  PersonalLoanCommonTransactionMetadata,
} from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export const PersonalLoanCommonTransactionRow = ({
  transaction,
}: {
  transaction: CommonTransaction & {
    metadata: PersonalLoanCommonTransactionMetadata;
  };
}) => {
  return (
    <GridTable.Row>
      <GridTable.Cell
        content={
          <CommonTransactionDescriptionCell
            icon={transaction?.icon}
            content={transaction?.title}
          />
        }
      />
      <GridTable.Cell
        content={
          <CommonTransactionValueCell
            content={transaction?.metadata?.toPrincipal}
          />
        }
        textAlign="right"
      />
      <GridTable.Cell
        content={
          <CommonTransactionValueCell
            content={transaction?.metadata?.toInterest}
          />
        }
        textAlign="right"
      />
      <GridTable.Cell
        className="stickyColumn"
        content={
          <CommonTransactionAmountCell
            pill={transaction?.pill}
            amount={transaction?.amount}
            strikeThrough={
              transaction?.status === 'CANCELED' ||
              transaction?.status === 'FAILED'
            }
          />
        }
      />
    </GridTable.Row>
  );
};
