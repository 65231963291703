import * as React from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';

import { type Enhancer, withProps } from '~/hocs';
import type { AppState } from '~/redux';
import { dismissNotifications } from '~/redux/actions';
import type { NotificationBundle } from '~/redux/reducers/notificationsReducer';
import { NOTIFICATION_LOCATIONS as LOCATIONS } from '~/static-constants';

import { Notification } from '../notification';

import style from './style.module.scss';

type ProvidedProps = {
  autoDismiss?: boolean;
  disableDismissNotifications?: boolean;
  dismissNotifications: (...args: Array<any>) => any;
  notification: NotificationBundle | null | undefined;
};

export type NotificationsProps = ProvidedProps & {
  location: ValueOf<typeof LOCATIONS>;
  style?: React.CSSProperties;
};

export class NotificationsComponent extends React.Component<NotificationsProps> {
  static defaultProps = {
    location: LOCATIONS.SNACKBAR,
  };
  timeoutId: number | null | undefined;

  componentDidUpdate() {
    if (this.props.autoDismiss && this.props.notification !== null) {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }

      this.timeoutId = window.setTimeout(() => {
        this.timeoutId = null;
        this.props.dismissNotifications(this.props.notification);
      }, 3000);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  render() {
    return (
      <div
        className={style.root}
        onClick={() =>
          !this.props.disableDismissNotifications &&
          this.props.dismissNotifications(this.props.notification)
        }
        style={this.props.style}
      >
        <Transition
          items={this.props.notification}
          from={{
            maxHeight: 0,
          }}
          enter={{
            maxHeight: 74,
          }}
          leave={{
            maxHeight: 0,
          }}
        >
          {/* @ts-expect-error - TS7006 - Parameter 'show' implicitly has an 'any' type. */}
          {(show) =>
            show &&
            // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
            ((props) => (
              <div style={props}>
                <Notification
                  notification={show}
                  disableDismissNotifications={
                    this.props.disableDismissNotifications
                  }
                />
              </div>
            ))
          }
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: Partial<NotificationsProps>,
): Partial<NotificationsProps> => {
  const notification = state.notifications.find(
    (notification) => notification.location === ownProps.location,
  );

  return {
    notification,
  };
};

const mapDispatchToProps = {
  dismissNotifications,
};
// @ts-expect-error - TS2322 - Type 'InferableComponentEnhancerWithProps<Partial<NotificationsProps> & { dismissNotifications: (payload?: string | { location: string | null | undefined; message: string; type: string; } | undefined) => DismissNotificationsAction; }, Partial<...>>' is not assignable to type 'Enhancer<NotificationsProps, Pick<NotificationsProps, 'location' | 'style'>>'.
const enhancer: Enhancer<
  NotificationsProps,
  Pick<NotificationsProps, 'location' | 'style'>
> = connect(mapStateToProps, mapDispatchToProps);

export const Notifications = enhancer(NotificationsComponent) as any;

// Now all the location-specific notifications!
const wrapForLocation = (
  location: string,
  opts?: {
    autoDismiss?: boolean;
    disableDismissNotifications?: boolean;
  },
): React.ComponentType<any> => {
  return withProps({
    autoDismiss: opts ? opts.autoDismiss : undefined,
    disableDismissNotifications: opts
      ? opts.disableDismissNotifications
      : undefined,
    location,
  })(Notifications);
};

export const AppNotifications = wrapForLocation(LOCATIONS.SNACKBAR);

export const CreateSmartTransferNotifications = wrapForLocation(
  LOCATIONS.CREATE_SMART_TRANSFER,
);
export const SmartTransferHelperNotifications = wrapForLocation(
  LOCATIONS.SMART_TRANSFER_HELPER_NOTIFICATIONS,
  {
    autoDismiss: true,
    disableDismissNotifications: true,
  },
);
export const CreateTransferNotifications = wrapForLocation(
  LOCATIONS.CREATE_TRANSFER,
  {
    autoDismiss: true,
  },
);
export const CancelTransferNotifications = wrapForLocation(
  LOCATIONS.CANCEL_TRANSFER,
);
export const LoginNotifications = wrapForLocation(LOCATIONS.LOGIN);
export const TwoFactorNotifications = wrapForLocation(
  LOCATIONS.TWO_FA_AUTH_INPUT,
);
export const SignupNotifications = wrapForLocation(LOCATIONS.SIGNUP);
export const ReviewSetupAccountNotifications = wrapForLocation(
  LOCATIONS.REVIEW_SETUP_ACCOUNT,
);
export const EditorNotifications = wrapForLocation(LOCATIONS.EDITOR);
export const ConfirmPieEditsNotifications = wrapForLocation(
  LOCATIONS.CONFIRM_EDITS_DIALOG,
);

export const ConfirmDepositsNotifications = wrapForLocation(
  LOCATIONS.CONFIRM_DEPOSITS,
);
export const ConnectBankNotifications = wrapForLocation(LOCATIONS.CONNECT_BANK);
export const DebitCardNotifications = wrapForLocation(LOCATIONS.DEBIT_CARD);
export const ReissueDepositsNotifications = wrapForLocation(
  LOCATIONS.REISSUE_DEPOSITS,
);
export const LinkAccountNotifications = wrapForLocation(LOCATIONS.LINK_ACCOUNT);
export const BankRoutingNotifications = wrapForLocation(LOCATIONS.BANK_ROUTING);
export const MakePaymentNotifications = wrapForLocation(LOCATIONS.MAKE_PAYMENT);
export const ReacceptLegalTermsNotifications = wrapForLocation(
  LOCATIONS.REACCEPT_LEGAL_TERMS,
);
export const RequestCreditNotifications = wrapForLocation(
  LOCATIONS.REQUEST_CREDIT,
);
export const ResetPasswordNotifications = wrapForLocation(
  LOCATIONS.RESET_PASSWORD,
);
export const SecurityPageNotifications = wrapForLocation(
  LOCATIONS.SECURITY_PAGE,
);
export const SetOrderNotifications = wrapForLocation(LOCATIONS.SET_ORDER);
export const SetupOtherAccountTypeNotifications = wrapForLocation(
  LOCATIONS.OTHER_ACCOUNT_TYPES,
);
export const OnboardingResendVerificationEmailNotifications = wrapForLocation(
  LOCATIONS.ONBOARDING_RESEND_VERIFICATION_EMAIL,
);
export const ResendVerificationEmailModalNotifications = wrapForLocation(
  LOCATIONS.RESEND_VERIFICATION_EMAIL_MODAL,
);
