import { useIntercomConversation } from './useIntercomConversation';
import type { OnClickLinkableEvent } from './useLinkable';

const EMPTY_CHAT_LINK = 'https://help.m1.com/?action=new_conversation';

export const SUPPORT_LINKS = {
  // For now we will link to `action=new_conversation` to open an empty chat.
  // TODO: update these with workflow IDs once those are available.
  // e.g. https://help.m1.com/?action=new_conversation&workflow=begin_partial_acat
  SUPPORT_REQUEST: EMPTY_CHAT_LINK,
  SUPPORT_2FA: EMPTY_CHAT_LINK,
  SUPPORT_BANKING: EMPTY_CHAT_LINK,
  SUPPORT_CRYPTO: EMPTY_CHAT_LINK,
  SUPPORT_CHANGE_ACCOUNT: EMPTY_CHAT_LINK,
  SUPPORT_TRANSFER_ACCOUNT:
    'https://help.m1.com/?action=new_conversation&workflow=begin_partial_acat',
  SUPPORT_VERIFY_INVEST: EMPTY_CHAT_LINK,
  SUPPORT_TAX_LOT_DOWNLOAD:
    'https://help.m1.com/en/articles/9331949-download-and-view-realized-gains',
} as const;

export type SupportLinkDestination = keyof typeof SUPPORT_LINKS;

export const useSupportLink = (
  destination?: SupportLinkDestination | null | undefined,
) => {
  const handleConversation = useIntercomConversation();
  const href = SUPPORT_LINKS[destination ?? 'SUPPORT_REQUEST'];
  const url = new URL(href);
  let onClick;
  if (url.hostname === 'help.m1.com') {
    const action = url.searchParams.get('action');
    if (action === 'new_conversation') {
      onClick = (e?: OnClickLinkableEvent) => {
        handleConversation(url, e);
      };
    }
  }

  return { to: href, onClick, target: '_blank' };
};
