import {
  Box,
  HXXS,
  PM,
  Skeleton,
  SkeletonProvider,
  styled,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import {
  CreditCardDetailsCardDocument,
  useCreditCardDetailsCardQuery,
  useSetLockOnCreditCardMutation,
} from '~/graphql/hooks';
import type { CreditCardDetailsCardQuery } from '~/graphql/types';
import { LinkOrText } from '~/pages/dashboard/borrow/personal/components/LinkOrText';
import {
  StyledDetailsCard,
  StyledDetailsCardContainer,
  StyledDetailsCardDivider,
} from '~/pages/dashboard/common/ProductLandingPageCard.styled';
import { useToast } from '~/toasts';
import { Switch } from '~/toolbox/switch';

const StyledSwitchContainer = styled(Box)`
  @media (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    margin-top: 4px;
  }
`;

export const CreditCardDetailsCard = ({ accountId }: { accountId: string }) => {
  const { addToast } = useToast();

  const [setCreditCardLock] = useSetLockOnCreditCardMutation();

  const { data, loading } = useCreditCardDetailsCardQuery({
    variables: {
      accountId,
    },
  });

  const node = data?.node as ExtractTypename<
    CreditCardDetailsCardQuery['node'],
    'CreditCardAccount'
  >;

  const handleChange = React.useCallback(() => {
    setCreditCardLock({
      variables: {
        input: {
          setLock: !node?.activeCard?.isLocked,
          creditCardId: node?.activeCard?.id as string,
        },
      },
      optimisticResponse: {
        setLockOnCreditCard: {
          didSucceed: true,
          error: null,
          outcome: {
            __typename: 'SetLockOnCreditCardOutcome',
            creditCard: {
              __typename: 'CreditCard',
              id: node?.activeCard?.id as string,
              isLocked: !node?.activeCard?.isLocked,
            },
          },
        },
      },
      refetchQueries: [
        { query: CreditCardDetailsCardDocument, variables: { accountId } },
      ],
      onError: (err) => {
        addToast({ kind: 'alert', content: err.message });
      },
    });
  }, [data]);

  const title = node?.creditCardProductLandingPage?.detailsCard?.title || '';
  const lockCardLabel =
    node?.creditCardProductLandingPage?.detailsCard?.lockCardLabel || '';
  const details =
    node?.creditCardProductLandingPage?.detailsCard?.details || [];

  return (
    <SkeletonProvider isLoading={loading}>
      <StyledDetailsCardContainer>
        <Skeleton skeletonWidth="100%" skeletonHeight="400px">
          <Card
            minHeight={190}
            p={24}
            mb={16}
            backgroundColor="backgroundNeutralSecondary"
          >
            <HXXS fontWeight={300} content={title} mb={16} />
            {lockCardLabel && (
              <>
                <StyledDetailsCard
                  minHeight={48}
                  alignItems="center"
                  justifyContent="space-between"
                  py={14}
                >
                  <PM
                    whiteSpace="nowrap"
                    content={lockCardLabel}
                    color="foregroundNeutralSecondary"
                  />
                  <StyledSwitchContainer>
                    <Switch
                      id="credit-card-lock-unlock-card"
                      name="credit-card-lock-unlock-card"
                      checked={node?.activeCard?.isLocked}
                      onChange={handleChange}
                    />
                  </StyledSwitchContainer>
                </StyledDetailsCard>
                <StyledDetailsCardDivider />
              </>
            )}
            {details.map((detail, i) => {
              return (
                <Box key={`credit-card-detail-${i}`}>
                  <StyledDetailsCard
                    minHeight={48}
                    alignItems="center"
                    justifyContent="space-between"
                    py={14}
                  >
                    <LinkOrText
                      text={detail?.title}
                      link={detail?.titleLink}
                      label
                    />
                    <LinkOrText text={detail?.value} link={detail?.valueLink} />
                  </StyledDetailsCard>
                  {i !== details.length - 1 && <StyledDetailsCardDivider />}
                </Box>
              );
            })}
          </Card>
        </Skeleton>
      </StyledDetailsCardContainer>
    </SkeletonProvider>
  );
};
