import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import type { GetInvestActivityQueryVariables } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';

import { InvestActivityTable } from './components/InvestActivityTable';

const DEFAULT_FORM_VALUES: InvestActivityFormValues = {
  includeCategoryCash: true,
  includeCategoryDividend: true,
  includeCategoryTrading: true,
  includeCategoryPosition: true,
  includeCategoryTransfer: true,
  fromDate: null,
  toDate: null,
  symbols: [],
  sort: {
    direction: 'DESC',
    type: 'DATE',
  },
};

export type InvestActivityFormValues = {
  sort: GetInvestActivityQueryVariables['sort'];
  toDate: string | null;
  fromDate: string | null;
  symbols: string[];
  includeCategoryCash: boolean;
  includeCategoryDividend: boolean;
  includeCategoryPosition: boolean;
  includeCategoryTrading: boolean;
  includeCategoryTransfer: boolean;
};

export const ActivityPage = () => {
  const formMethods = useForm<InvestActivityFormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'onChange',
  });
  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  if (!activeAccountId) {
    return <GenericSystemError />;
  }

  const { sort, toDate, fromDate, ...filter } = formMethods.watch();

  return (
    <FormProvider {...formMethods}>
      <InvestActivityTable
        variables={{
          id: activeAccountId,
          first: 20,
          sort,
          filter: {
            ...filter,
            toDate: toDate
              ? moment(toDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
              : null,
            fromDate: fromDate
              ? moment(fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
              : null,
          },
        }}
      />
    </FormProvider>
  );
};
