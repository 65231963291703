import { Flex, FloatingPanel, PL, PM } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type {
  AccountOriginatorEnum,
  InvestmentsFragment,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { SUPPORT_LINKS } from '~/hooks/useSupportLink';
import { Link } from '~/toolbox/link';

import { HoldingsDownload } from './HoldingsDowload';
import { TaxLotDownload } from './TaxLotDownload';
type DownloadsProps = {
  investments?: Maybe<InvestmentsFragment>;
  accountOriginator: AccountOriginatorEnum | null;
};

export const Downloads = ({
  accountOriginator,
  investments: downloadInvestments,
}: DownloadsProps): React.ReactElement | null => {
  const analytics = useAnalytics();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOnShow = () => {
    setIsOpen(true);
    analytics.recordEvent('m1_invest_holdings_download_menu_clicked');
  };

  const hasPositions = Boolean(downloadInvestments?.positions?.edges?.length);

  return (
    <FloatingPanel
      controlled
      placement="bottom-end"
      maxWidth={200}
      trigger={
        <Flex
          style={{ cursor: 'pointer' }}
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <PL content="Downloads" />
          <Icon name="moreVert24" />
        </Flex>
      }
      active={isOpen}
      onShow={handleOnShow}
      onHide={() => setIsOpen(false)}
      content={
        <ul style={{ marginTop: 16, cursor: 'pointer' }}>
          {hasPositions && (
            <HoldingsDownload
              header="Holdings summary"
              investments={downloadInvestments}
              setIsOpen={setIsOpen}
            />
          )}
          {accountOriginator === 'M1' ? (
            <>
              <TaxLotDownload
                header="Open tax lots"
                lotType="OPEN"
                setIsOpen={setIsOpen}
              />{' '}
              <TaxLotDownload
                header="Closed tax lots"
                lotType="CLOSED"
                setIsOpen={setIsOpen}
              />
            </>
          ) : (
            <Flex height={40} onClick={() => setIsOpen(false)}>
              <Link to={SUPPORT_LINKS.SUPPORT_TAX_LOT_DOWNLOAD} target="_blank">
                <PM
                  color="foregroundNeutralMain"
                  content="Where to find tax lots"
                  ml={16}
                />
              </Link>
            </Flex>
          )}
        </ul>
      }
    />
  );
};
