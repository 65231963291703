import { useWizardContext } from '~/flows/wizard';

import type { IRAWizardSteps } from '../types';

export const useIRAWizardContext = () => {
  const context = useWizardContext<IRAWizardSteps>();

  if (!context) {
    throw new Error(
      'useIRAWizardContext must be used within a Wizard provider!',
    );
  }

  return context;
};
