/* eslint-disable no-console */
import values from 'lodash-es/values';
import type { SagaIterator } from 'redux-saga';
import { all, spawn } from 'redux-saga/effects';

import * as allFlowSagas from './flows/index';

export function* flowsSaga(): SagaIterator {
  // New style of flow management. Have explicit actions/reducers/sagas for each flow.
  yield all(values(allFlowSagas).map((s) => spawn(s)));
}
