import {
  Box,
  spacingUnits,
  useTheme,
  type LayoutableProps,
} from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import type { UseParticipantSummaryResult } from '~/pages/dashboard/covers/create-transfer/utils/useParticipantSummary';
import { isNil } from '~/utils';

import { TransferParticipantField } from './TransferParticipantField';
import {
  participantsToDropdownOptions,
  sortDropdownOptions,
} from './TransferParticipantFields.utils';
import { summaryToParticipantsList } from './utils';

export type TransferParticipantFieldsProps = LayoutableProps & {
  participantSummary: UseParticipantSummaryResult;
  isIraAction?: Maybe<boolean>;
  isSourceDisabled?: Maybe<boolean>;
  isDestinationDisabled?: Maybe<boolean>;
};

export const TransferParticipantFields = ({
  participantSummary,
  isIraAction,
  isSourceDisabled,
  isDestinationDisabled,
}: TransferParticipantFieldsProps) => {
  const { pivot, isSelectedRelationshipEligible } = participantSummary;
  const theme = useTheme();
  const { control, setValue, trigger, watch } = useFormContext();
  const { isDirty } = useFormState();

  const [sourceId, destinationId, schedule] = watch([
    'sourceId',
    'destinationId',
    'schedule',
  ]);

  // Source accounts (ie: "From" accounts) available
  const sourceParticipantsList = summaryToParticipantsList({
    participantSummary,
    side: 'FROM',
  });
  const sourceOptions = participantsToDropdownOptions(sourceParticipantsList);

  // Need to sort values so that "External Accounts" are first.
  const sortedSourceOptions = React.useMemo(
    () => sortDropdownOptions(sourceOptions),
    [sourceOptions],
  );

  // All destinations (ie: "To" accounts) available
  const destinationParticipantsList = summaryToParticipantsList({
    participantSummary,
    side: 'TO',
  });
  const destinationOptions = participantsToDropdownOptions(
    destinationParticipantsList,
  );

  // Need to sort values so that "External Accounts" are first.
  const sortedDestinationOptions = React.useMemo(
    () => sortDropdownOptions(destinationOptions),
    [destinationOptions],
  );

  React.useEffect(() => {
    if (!isDirty) {
      return;
    }
    /*
     * Reset the participant opposite pivot only when the pivot
     * participant isn't selected and the form is dirty.
     */
    if (isSelectedRelationshipEligible === false) {
      const pivotId = pivot === 'FROM' ? 'destinationId' : 'sourceId';
      setValue(pivotId, null);
    }
    /*
     * This will be triggered when the relationship is valid and the
     * schedule has changed.  This is important, since min/max values
     * vary between one-time and scheduled relationships.
     */
    trigger('amount');
  }, [
    isDirty,
    isSelectedRelationshipEligible,
    schedule,
    setValue,
    trigger,
    pivot,
  ]);

  const sourceIsDisabled =
    isSourceDisabled === true ||
    (isNil(destinationId) && pivot === 'TO') ||
    (isIraAction === true && pivot === 'FROM');

  const destinationIsDisabled =
    isDestinationDisabled === true ||
    (isNil(sourceId) && pivot === 'FROM') ||
    (isIraAction === true && pivot === 'TO');

  return (
    <Box mb={spacingUnits.l}>
      <TransferParticipantField
        control={control}
        disabled={sourceIsDisabled}
        label="From"
        name="sourceId"
        optionsList={sortedSourceOptions}
        controlStyle={{
          borderRadius: '8px 8px 0 0',
          ...(sourceIsDisabled && {
            cursor: 'not-allowed',
            '&:hover': {
              borderColor: theme.colors.borderMain,
            },
          }),
        }}
      />
      <TransferParticipantField
        control={control}
        disabled={destinationIsDisabled}
        label="To"
        name="destinationId"
        optionsList={sortedDestinationOptions}
        style={{
          marginTop: '-9px',
        }}
        controlStyle={{
          borderRadius: '0 0 8px 8px',
          ...(destinationIsDisabled && {
            cursor: 'not-allowed',
            '&:hover': {
              borderColor: theme.colors.borderMain,
            },
          }),
        }}
      />
    </Box>
  );
};
