import { Flex, useLocalStorage, useTheme } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import Highcharts, { type ExportingMenuObject } from 'highcharts/highstock';
import highchartsData from 'highcharts/modules/data';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsCandleStick from 'highcharts/modules/hollowcandlestick';
import highchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import {
  every,
  flow,
  head,
  partialRight,
  reject,
  tail,
  unzip,
} from 'lodash-es';
import moment from 'moment-timezone';
import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { useMediaQuery } from 'react-responsive';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useStableReference } from '~/hooks/useStableReference';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { isNil } from '~/utils';

import { HighchartsStyles, StyledDot } from './StockChart.styled';
import type {
  StockChartPeriodDuration,
  StockChartProps,
} from './StockChart.types';
import {
  generateOptionsConfig,
  getSeriesDatumValue,
  periodDurationToRangeSelectorButton,
  useStockChartFeature,
} from './StockChart.utils';
import { StockChartSkeleton } from './StockChartSkeleton';

export const CHART_DATUM_KEY = 'datum';

/**
 * Highcharts extension to remove columns from CSV exports.
 */
function highchartsExportingDataOmitter(H: typeof Highcharts) {
  H.wrap(
    H.Chart.prototype,
    'getDataRows',
    function (this: unknown, proceed, ...args) {
      // Apply the original function with the original arguments,
      // which are sliced off this function's arguments
      const result = proceed.apply(
        // eslint-disable-next-line no-invalid-this
        this,
        Array.prototype.slice.call(args, 1),
      );

      // Remove second row of Excel export which is duplicate headers
      if (result[1].every((column: any) => typeof column === 'string')) {
        result.splice(1, 1);
      }

      const removeNilColumns = flow(
        unzip,
        partialRight(reject, flow(tail, partialRight(every, isNil))), // Remove columns where all values are nil
        partialRight(reject, (column: any) => {
          // Remove datum column
          const header = head(column);
          return (
            typeof header === 'string' &&
            header.toUpperCase() === CHART_DATUM_KEY.toUpperCase()
          );
        }),
        unzip,
      );
      return removeNilColumns(result);
    },
  );
}

highchartsData(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsCandleStick(Highcharts);
highchartsNoData(Highcharts);
highchartsExportingDataOmitter(Highcharts);

export const StockChart = ({
  chartName,
  chartRef,
  defaultPeriod: defaultPeriodProp,
  features = { navigator: true, dateRangeInputs: true, print: false },
  height,
  isLoading,
  isOnDarkBackground,
  onChangePeriod,
  onRangeSelectionChange,
  periods = ['1w', '1M', '3M', '6M', 'ytd', '1y', '5y', 'all'],
  plotLines,
  series,
}: StockChartProps) => {
  const rangeSelectionKey = `${chartName}_range_selection`;
  const theme = useTheme();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });
  // switching this to `series` as eslint expects will disable animations, so don't do it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSeries = React.useMemo(() => series, [series?.[0]?.id]);
  const visiblePoints = React.useRef<Maybe<Highcharts.Series[]>>(null);
  const [hasActivatedDefaultRange, setHasActivatedDefaultRange] =
    React.useState(false);
  const [showDividendsCashFlowsDot, setShowDividendsCashFlowsDot] =
    useLocalStorage(`${chartName}_show_dividends_cash_flows_dot`, true);
  const {
    featureState,
    isFeatureEnabled,
    supportsFeature,
    updateFeatureState,
  } = useStockChartFeature(features);
  const memoizedPeriods = useStableReference(periods);
  const getDefaultPeriod = React.useCallback(
    () => {
      return (localStorage.getItem(rangeSelectionKey) ??
        defaultPeriodProp ??
        'all') as StockChartPeriodDuration;
    },
    [defaultPeriodProp, rangeSelectionKey], // We don't want this to be a controlled component, so only process default period once
  );
  const currentPeriod =
    React.useRef<StockChartPeriodDuration>(getDefaultPeriod());

  React.useEffect(() => {
    onChangePeriod?.(getDefaultPeriod());
  }, [onChangePeriod, getDefaultPeriod]);

  Highcharts.setOptions({
    lang: {
      noData: 'No data available',
      loading: 'Loading...',
      rangeSelectorFrom: 'From',
      rangeSelectorTo: 'To',
    },
  });

  /**
   * Use this to update the visible points ref. Do not use this as a getter as a first
   * resort, instead use the `visiblePoints` ref.
   */
  const updateVisiblePoints = React.useCallback(() => {
    const chartSeries = chartRef?.current?.chart?.series;
    const xAxisMin = moment(chartSeries?.[0]?.xAxis?.min);
    const xAxisMax = moment(chartSeries?.[0]?.xAxis?.max);
    const mapped = chartSeries?.map((serie) => {
      serie.points = serie.points?.filter((point) => {
        const date = moment(point.x);
        return (
          date.isSameOrAfter(xAxisMin ?? undefined, 'day') &&
          date.isSameOrBefore(xAxisMax ?? undefined, 'day')
        );
      });
      return serie;
    });
    visiblePoints.current = mapped;
    return mapped;
  }, [chartRef]);

  const updateYAxisRange = React.useCallback(() => {
    const chart = chartRef?.current?.chart;
    // Calculate min and max of visible range to set y-axis extremes so we don't always show $0 on y-axis
    const { minY, maxY } = visiblePoints.current?.[0]?.points?.reduce(
      (acc, point) => {
        return {
          minY: Math.min(acc.minY, point.y ?? acc.minY),
          maxY: Math.max(acc.maxY, point.y ?? acc.maxY),
        };
      },
      { minY: Infinity, maxY: -Infinity },
    ) ?? { minY: Infinity, maxY: -Infinity };
    chart?.yAxis[0].setExtremes(minY, maxY);
  }, [chartRef, visiblePoints]);

  const [options, setOptions] = React.useState<Highcharts.Options>(
    generateOptionsConfig({
      theme,
      isOnDarkBackground,
      isMobile,
      isFeatureEnabled,
      fullscreenOpen: function () {
        chartRef?.current?.chart.update({
          chart: {
            backgroundColor: isOnDarkBackground
              ? theme.colors.backgroundPlotFeature
              : theme.colors.backgroundNeutralMain,
          },
        });
      },
      fullscreenClose: function () {
        chartRef?.current?.chart.update({
          chart: {
            backgroundColor: 'transparent',
          },
        });
      },
      afterSetExtremes: function () {
        const series = updateVisiblePoints();
        onRangeSelectionChange?.(series);
        updateYAxisRange();
      },
      plotLines,
    }),
  );

  // Effect for initializing range selectors
  React.useEffect(() => {
    const defaultPeriod = getDefaultPeriod();
    if (series?.[0]?.data?.length && !hasActivatedDefaultRange) {
      // Setting the button after the series is set allows
      // us to update the selected button after all the data is available
      // then like when selecting a button, we adjust the y axis range.
      const buttons = memoizedPeriods.map((periodDuration) =>
        periodDurationToRangeSelectorButton({
          periodDuration,
          analytics,
          chartName,
          onChangePeriod: (...args) => {
            currentPeriod.current = periodDuration;
            onChangePeriod?.(...args);
            localStorage.setItem(rangeSelectionKey, periodDuration);
          },
        }),
      );
      const selectedButton = defaultPeriod
        ? buttons.findIndex((button) => button.text === defaultPeriod)
        : null;

      if (typeof selectedButton === 'number') {
        // Need to use setTimeout here so state update doesn't get lost with other state updates
        // And to give a chance for the chart to load all data before setting default range selection
        setTimeout(() => {
          setOptions({
            series,
            rangeSelector: {
              selected: selectedButton,
              buttons,
            },
          });
          updateYAxisRange();
          setHasActivatedDefaultRange(true);
        }, 20); // Don't set this timeout time lower, the chart may only show the first day of data if it's too short, it only seems to really be an issue in Firefox
      }
    }
  }, [
    analytics,
    chartName,
    chartRef,
    getDefaultPeriod,
    hasActivatedDefaultRange,
    memoizedPeriods,
    onChangePeriod,
    rangeSelectionKey,
    series,
    updateYAxisRange,
  ]);

  // Effect for all other controlled state
  React.useEffect(() => {
    const minYRange = memoizedSeries?.reduce((acc, serie) => {
      return Math.max(
        acc,
        Math.abs(
          (getSeriesDatumValue(serie?.data?.[serie?.data?.length - 1])?.y ??
            0) -
            (getSeriesDatumValue(serie?.data?.[serie?.data?.length - 2])?.y ??
              0),
        ) ?? acc,
      );
    }, 1);

    const defaultPeriod = getDefaultPeriod();
    if (
      currentPeriod.current === 'all' &&
      defaultPeriod === 'all' &&
      memoizedSeries
    ) {
      // For share pie and other charts that always want to show all, we need to recalculate x and y axis extremes
      const chart = chartRef?.current?.chart;
      const seriesOptions = memoizedSeries?.[0];
      if (seriesOptions?.data?.length) {
        const data = seriesOptions.data;
        const firstPoint = data[0];
        const lastPoint = data[data.length - 1];
        const { minY, maxY } = data.reduce(
          (acc, point) => {
            return {
              minY: Math.min(
                acc.minY,
                getSeriesDatumValue(point)?.y ?? acc.minY,
              ),
              maxY: Math.max(
                acc.maxY,
                getSeriesDatumValue(point)?.y ?? acc.maxY,
              ),
            };
          },
          { minY: Infinity, maxY: -Infinity },
        ) ?? { minY: Infinity, maxY: -Infinity };
        chart?.xAxis[0].setExtremes(
          getSeriesDatumValue(firstPoint)?.x,
          getSeriesDatumValue(lastPoint)?.x,
        );
        chart?.yAxis[0].setExtremes(minY, maxY);
      }
    }

    const menuItems: Array<ExportingMenuObject | string> = [
      'viewFullscreen',
      ...(isFeatureEnabled('print') ? ['printChart'] : []),
      'separator',
      'downloadCSV',
      'downloadXLS',
    ];

    if (supportsFeature('candleSticks')) {
      menuItems.push({
        text: 'Toggle candlesticks',
        onclick: function () {
          updateFeatureState('candleSticks');
        },
      });
    }
    if (supportsFeature('navigator')) {
      menuItems.push({
        text: `${isFeatureEnabled('navigator') ? 'Hide' : 'Show'} navigator`,
        onclick: function () {
          updateFeatureState('navigator');
        },
      });
    }
    if (supportsFeature('dividends')) {
      menuItems.push({
        text: renderToString(
          <Flex justifyContent="space-between">
            {isFeatureEnabled('dividends') ? 'Hide' : 'Show'} dividends
            {showDividendsCashFlowsDot && <StyledDot mt={4} ml={8} $relative />}
          </Flex>,
        ),
        onclick: function () {
          updateFeatureState('dividends');
          setShowDividendsCashFlowsDot(false);
        },
      });
    }
    if (supportsFeature('cashFlows')) {
      menuItems.push({
        text: renderToString(
          <Flex justifyContent="space-between">
            {isFeatureEnabled('cashFlows') ? 'Hide' : 'Show'} cash flows
            {showDividendsCashFlowsDot && <StyledDot mt={4} ml={8} $relative />}
          </Flex>,
        ),
        onclick: function () {
          updateFeatureState('cashFlows');
          setShowDividendsCashFlowsDot(false);
        },
      });
    }

    setOptions({
      exporting: {
        buttons: {
          contextButton: {
            // @ts-expect-error Types are wrong, menuItems can be strings or object handlers
            menuItems,
            text: renderToString(
              <>
                <Icon
                  data-cool="true"
                  name="more24"
                  color={
                    isOnDarkBackground
                      ? 'foregroundNeutralOnDark'
                      : 'foregroundNeutralMain'
                  }
                  // @ts-expect-error the `theme` prop is used to retain the ThemeProvider
                  theme={theme}
                />
                {(supportsFeature('cashFlows') ||
                  supportsFeature('cashFlows')) &&
                  showDividendsCashFlowsDot && <StyledDot />}
              </>,
            ),
          },
        },
        filename: `${memoizedSeries?.[0]?.name} - ${moment().format('YYYY-MM-DD')}`,
      },
      // @ts-expect-error allow switching of types
      series: memoizedSeries?.map((series: SeriesTypes, i) => {
        let visible = series.visible;
        if (series?.dataType === 'DIVIDEND') {
          visible = isFeatureEnabled('dividends');
        } else if (series?.dataType === 'NET_CASH_FLOW') {
          visible = isFeatureEnabled('cashFlows');
        }
        return {
          ...series,
          type:
            i === 0 && isFeatureEnabled('candleSticks')
              ? 'hollowcandlestick'
              : series.type,
          visible,
        };
      }),
      chart: {
        height: height ?? (isFeatureEnabled('navigator') ? 433 + 40 : 433),
      },
      navigator: {
        enabled: isFeatureEnabled('navigator'),
      },
      yAxis: {
        minRange: minYRange,
      },
    });
  }, [
    analytics,
    chartName,
    chartRef,
    featureState,
    getDefaultPeriod,
    hasActivatedDefaultRange,
    height,
    isFeatureEnabled,
    isOnDarkBackground,
    memoizedPeriods,
    memoizedSeries, // switching this to `series` as eslint expects will disable animations, so don't do it
    setHasActivatedDefaultRange,
    setShowDividendsCashFlowsDot,
    showDividendsCashFlowsDot,
    supportsFeature,
    theme,
    updateFeatureState,
    updateYAxisRange,
  ]);

  return (
    <HighchartsStyles
      isLoading={Boolean(isLoading)}
      fadeOut
      skeletonWidth="100%"
      skeletonHeight="100%"
      skeletons={<StockChartSkeleton />}
      $isOnDarkBackground={Boolean(isOnDarkBackground)}
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        options={options}
        ref={chartRef}
      />
    </HighchartsStyles>
  );
};
