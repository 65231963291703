import {
  type BeginInvestAccountSetupFlow,
  type ChangeInvestAccountSetupStep,
  type ClickedCancelInvestAccountSetup,
  type CollectedInvestTrustedContact,
  INVEST_ONBOARDING_ACTIONS,
  type InvestAccountSetupFlowCanceled,
  type InvestAccountSetupFlowFinished,
  type SetFullyPaidLendingStatusAction,
  type SetInvestSuitabilityOnboardingQuestionsAction,
  type SkippedInvestTrustedContact,
  type SubmittedInvestIntroductionSource,
  type SubmittedInvestProfileInput,
} from './investOnboardingActions.types';

export const beginInvestAccountSetupFlow = (
  payload: BeginInvestAccountSetupFlow['payload'],
): BeginInvestAccountSetupFlow => ({
  type: INVEST_ONBOARDING_ACTIONS.BEGIN_INVEST_ACCOUNT_SETUP,
  payload,
});

export const changeInvestAccountSetupFlowStep = (
  payload: ChangeInvestAccountSetupStep['payload'],
): ChangeInvestAccountSetupStep => ({
  type: INVEST_ONBOARDING_ACTIONS.CHANGE_INVEST_ACCOUNT_SETUP_FLOW_STEP,
  payload,
});

export const setInvestSuitabilityOnboardingQuestions = (
  payload: SetInvestSuitabilityOnboardingQuestionsAction['payload'],
): SetInvestSuitabilityOnboardingQuestionsAction => ({
  payload,
  type: INVEST_ONBOARDING_ACTIONS.SET_INVEST_SUITABILITY_ONBOARDING_QUESTIONS,
});

export const submittedInvestProfileInput = (
  payload: SubmittedInvestProfileInput['payload'],
): SubmittedInvestProfileInput => ({
  type: INVEST_ONBOARDING_ACTIONS.SUBMITTED_INVEST_PROFILE_INPUT,
  payload: payload,
});

export const investAccountSetupFlowFinished = (
  payload: InvestAccountSetupFlowFinished['payload'],
): InvestAccountSetupFlowFinished => ({
  type: INVEST_ONBOARDING_ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_FINISHED,
  payload,
});

export const investAccountSetupFlowCanceled =
  (): InvestAccountSetupFlowCanceled => ({
    type: INVEST_ONBOARDING_ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_CANCELED,
  });

export const collectedInvestTrustedContact = (
  payload: CollectedInvestTrustedContact['payload'],
): CollectedInvestTrustedContact => ({
  type: INVEST_ONBOARDING_ACTIONS.COLLECTED_INVEST_TRUSTED_CONTACT,
  payload: payload,
});

export const skippedInvestTrustedContact = (): SkippedInvestTrustedContact => ({
  type: INVEST_ONBOARDING_ACTIONS.SKIPPED_INVEST_TRUSTED_CONTACT,
});

export const submittedInvestIntroductionSource = (
  payload: SubmittedInvestIntroductionSource['payload'],
): SubmittedInvestIntroductionSource => ({
  type: INVEST_ONBOARDING_ACTIONS.SUBMITTED_INVEST_INTRODUCTION_SOURCE,
  payload,
});

export const clickedCancelInvestAccountSetup =
  (): ClickedCancelInvestAccountSetup => ({
    type: INVEST_ONBOARDING_ACTIONS.CLICKED_CANCEL_INVEST_ACCOUNT_SETUP,
  });

export const setFullyPaidLendingStatus = (
  payload: SetFullyPaidLendingStatusAction['payload'],
) => ({
  type: INVEST_ONBOARDING_ACTIONS.SET_FULLY_PAID_LENDING_STATUS,
  payload,
});
