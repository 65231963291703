import { Box, Flex, styled } from '@m1/liquid-react';
import { Banner } from '@m1/liquid-react/Banner';
import React from 'react';

import { UnmanagedHoldingsCard } from '~/components/invest/UnmanagedHoldingsCard';
import { Sticky } from '~/components/sticky';

import { useBreakpoints } from '~/hooks/useBreakpoints';
import { useLayout } from '~/hooks/useLayout';

import { PortfolioBannerContainer } from '../../components/portfolioBanner';
import { PortfolioDetailsContext } from '../PortfolioDetailsContext';

import {
  BuyingPowerSection,
  HeaderScrollBackground,
  SliceName,
  StaticHeader,
} from '../PortfolioDetailsPage.styled';

import { PortfolioBuyingPowerDetails } from './PortfolioBuyingPowerDetails';
import { TransferFundsButton } from './TransferFundsButton';

const TopLevelHeader = styled(Flex)`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    min-width: 380px;
    width: 380px;
    margin-right: 30px;
  }
`;

const BuyingPowerDetails = () => {
  const { isMediumBreakpoint } = useBreakpoints();
  const { portfolioSlice } = React.useContext(PortfolioDetailsContext);

  return isMediumBreakpoint ? (
    <Flex>
      <PortfolioBuyingPowerDetails sliceId={portfolioSlice.id} />
    </Flex>
  ) : (
    <PortfolioBuyingPowerDetails sliceId={portfolioSlice.id} />
  );
};

export const PortfolioDetailsHeader = () => {
  const { account, portfolioSlice, viewer } = React.useContext(
    PortfolioDetailsContext,
  );
  const { isXXSmallBreakpoint, isSmallBreakpoint } = useBreakpoints();
  const isMobile = !isXXSmallBreakpoint;
  const isTablet = !isSmallBreakpoint;

  const { scrollTop, topBarHeight } = useLayout();

  const hasRootPieSet = Boolean(account?.hasPortfolio);
  const receivedSecuritiesCard =
    account?.receivedSecurities?.receivedSecuritiesCard;

  const isInactiveOrDelistedContent = React.useMemo(() => {
    const sliceDetails = portfolioSlice?.to;

    if (sliceDetails !== undefined && 'status' in sliceDetails) {
      const { status } = sliceDetails;

      if (status === 'INACTIVE') {
        return 'This asset cannot be bought or sold electronically on our platform. Please contact our support team for more information.';
      } else if (status === 'DELISTED') {
        return 'This asset is liquidation only and can only be sold.  No new purchases can be made.';
      }
    }

    return null;
  }, [portfolioSlice]);

  return (
    <>
      <Sticky zIndex={2}>
        <StaticHeader
          forwardedAs="header"
          flexWrap={isMobile ? 'wrap' : undefined}
        >
          <TopLevelHeader
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexShrink={1}
            minWidth={300}
          >
            <SliceName
              fontWeight={300}
              color="foregroundSecondary"
              flexBasis={isMobile ? '100%' : undefined}
              py={isMobile ? 8 : undefined}
              flexShrink={1}
            >
              {portfolioSlice.to.name}
            </SliceName>
            {isTablet && (
              <TransferFundsButton
                account={account}
                kind="primary"
                size="medium"
                viewer={viewer}
              />
            )}
          </TopLevelHeader>
          <BuyingPowerSection>
            <BuyingPowerDetails />
            {!isTablet && (
              <Flex pt={10}>
                <TransferFundsButton
                  account={account}
                  kind="primary"
                  size="medium"
                  viewer={viewer}
                />
              </Flex>
            )}
          </BuyingPowerSection>
          <HeaderScrollBackground
            $percentTopBarScrolled={Math.min(
              1,
              Math.pow(scrollTop / topBarHeight, 2),
            )}
          />
        </StaticHeader>
      </Sticky>
      {hasRootPieSet && receivedSecuritiesCard && (
        <Box mt={16}>
          <UnmanagedHoldingsCard
            receivedSecuritiesCard={receivedSecuritiesCard}
          />
        </Box>
      )}
      <PortfolioBannerContainer />
      {isInactiveOrDelistedContent && (
        <Banner content={isInactiveOrDelistedContent} mt={16} kind="warning" />
      )}
    </>
  );
};
