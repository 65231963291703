import moment from 'moment';

import type {
  TimeIntervalEnum,
  TimeLengthUnitEnum,
  HistoricalQuotePeriodEnum,
} from '~/graphql/types';

type TimeRangeInput = {
  end?: string;
  inclusive?: boolean;
  length?: {
    amount: number;
    unit: TimeLengthUnitEnum;
  };
  start?: string;
};

export const PERIOD_TO_LENGTH: Partial<
  Record<HistoricalQuotePeriodEnum, TimeRangeInput['length']>
> = {
  YEAR_TO_DATE: {
    amount: moment().dayOfYear(),
    unit: 'DAYS',
  },
  ONE_MONTH: {
    amount: 1,
    unit: 'MONTHS',
  },
  ONE_YEAR: {
    amount: 1,
    unit: 'YEARS',
  },
  TWO_YEARS: {
    amount: 2,
    unit: 'YEARS',
  },
  FIVE_YEARS: {
    amount: 5,
    unit: 'YEARS',
  },
};

export const PERIOD_TO_INTERVAL: Partial<
  Record<HistoricalQuotePeriodEnum, TimeIntervalEnum>
> = {
  YEAR_TO_DATE: 'DAY',
  ONE_MONTH: 'MONTH',
  FIVE_YEARS: 'QUARTER',
};
