import { Button, HXS, PL, Box, Input } from '@m1/liquid-react';
import { LegacyIllustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useForm } from 'react-hook-form';

import { useActivateCreditCardMutation } from '~/graphql/hooks';
import type { ActivateCreditCardInput } from '~/graphql/types';
import { useToast } from '~/toasts';

import { CreditConfirmationPage } from './components/CreditConfirmationPage';

export const ActivateCreditCardPage = () => {
  const { handleSubmit, register, setValue, watch } =
    useForm<ActivateCreditCardInput>({
      defaultValues: { creditCardLastFour: '' },
    });
  const { addToast } = useToast();

  const [activateCreditCardMutation, { data }] =
    useActivateCreditCardMutation();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.replace(/\D/, '');
      setValue('creditCardLastFour', value);
    },
    [],
  );

  const creditCardLastFour = watch('creditCardLastFour');
  const onSubmit = (input: ActivateCreditCardInput) => {
    activateCreditCardMutation({
      variables: { input },
      onError: (error) => addToast({ content: error.message, kind: 'alert' }),
    });
  };

  return (
    <Box width={550} m="0 auto">
      {data?.activateCreditCard?.didSucceed ? (
        <CreditConfirmationPage />
      ) : (
        <Box>
          <Box mt={150}>
            <LegacyIllustration
              alt="Credit card last 4 digits."
              name="creditCard"
            />
            <HXS
              content="Enter the last four digits of your card number"
              mt={32}
            />
            <PL
              content="You are one step closer to being able to use your M1 Credit Card. Enter the last four digits of your credit card."
              mb={16}
              mt={10}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                {...register('creditCardLastFour', { maxLength: 4 })}
                autoFocus
                maxLength={4}
                onChange={handleChange}
                placeholder="Card’s last 4 digits"
              />
              <Button
                disabled={creditCardLastFour.length < 4}
                label="Continue"
                mt={64}
                size="large"
                type="submit"
              />
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};
