import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  RemoteAccountIdDocument,
  type RemoteAccountIdQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../../apolloQuerySaga';

type AccountIds = {
  autoPayId: string | null | undefined;
  creditAccountId: string | null | undefined;
};

export function* queryAccountIds(): SagaIterator<AccountIds> {
  const { data }: RemoteAccountIdQueryResult = yield call(apolloQuerySaga, {
    query: RemoteAccountIdDocument,
  });

  const viewer = data?.viewer;
  return {
    creditAccountId: viewer?.credit?.activeAccount?.id,
    autoPayId: viewer?.credit?.autoPayInstance?.id,
  };
}
