import { styled, Text } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';
import { components } from 'react-select';

import type { OptionProps } from '~/toolbox/Dropdown/Option';
import { isNotNil } from '~/utils';

const ReactSelectOption = components.Option;

type StyledOptionElementProps = Pick<OptionProps, 'isFocused' | 'selectProps'>;

export const StyledContent = styled.div<StyledOptionElementProps>`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background-color: ${(props) =>
    props.isFocused
      ? props.selectProps.appTheme.colors.backgroundNeutralMain
      : undefined};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledText = styled(Text)<StyledOptionElementProps>`
  color: ${(props) => {
    if (props.isFocused) {
      return props.selectProps.appTheme.colors.foregroundNeutralMain;
    }
    return props.selectProps.appTheme.colors.foregroundNeutralMain;
  }};
`;

export const TransferParticipantOption = (props: any) => {
  const { children, ...rest } = props;
  return (
    <ReactSelectOption {...rest}>
      <StyledContent
        isFocused={props.isFocused}
        selectProps={props.selectProps}
      >
        {isNotNil(props.selectProps.optionContent) ? (
          props.selectProps.optionContent(props as any)
        ) : (
          <StyledText
            content={children}
            isFocused={props.isFocused}
            selectProps={props.selectProps}
          />
        )}
        {props.isSelected === true && (
          <Icon name="check24" color="foregroundNeutralMain" ml={8} />
        )}
      </StyledContent>
    </ReactSelectOption>
  );
};
