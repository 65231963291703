import type { AppInfoPageFragment } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { REGISTER_USER_FLOW_STEPS as STEPS } from '~/static-constants';

import type { FlowState } from '../newFlowsReducer.types';

export type RegisterUserFlowState = FlowState<
  string,
  {
    attemptInProgress: boolean;
    promotionSignUpOutcomePage?: AppInfoPageFragment;
    promoCode: string;
  }
>;

const initialState: RegisterUserFlowState = {
  attemptInProgress: false,
  step: STEPS.COLLECT_USER_DETAILS,
  stepTitle: '',
  promoCode: '',
};

export function registerUser(
  state: RegisterUserFlowState = initialState,
  action: any,
): RegisterUserFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_REGISTER_USER_FLOW:
      return initialState;
    case ACTIONS.BEGIN_FLOW:
      if (action.meta.flow === 'registerUser') {
        return { ...state, promoCode: action.payload.promoCode };
      }
      return state;
    case ACTIONS.CHANGE_REGISTER_USER_FLOW_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case ACTIONS.SUBMIT_REGISTER_USER_FORM:
      return {
        ...state,
        attemptInProgress: true,
      };
    case ACTIONS.SET_PROMOTION_SIGNUP_OUTCOME:
      return {
        ...state,
        promotionSignUpOutcomePage: action.payload,
      };
    case ACTIONS.REGISTER_USER_ATTEMPT_FAILED:
    case ACTIONS.HIDE_MODAL:
      return {
        ...state,
        attemptInProgress: false,
      };
    default:
      return state;
  }
}
