import { makeLimit } from '~/components/research/Screener/utils';
import type {
  HistoricalQuotePeriodEnum,
  SecuritySortTypeEnum,
  SecurityScreenerQueryVariables,
} from '~/graphql/types';

export const INITIAL_EQUITY_SCREENER_VARIABLES: SecurityScreenerQueryVariables =
  {
    first: 20,
    period: 'ONE_YEAR',
    after: null,
    sort: {
      direction: 'DESC',
      type: 'MARKET_CAP',
    },
    filterTypes: ['EQUITY'],
    filterCategory: [],
    limit: [],
  };

export const defaultSecurityLimits = {
  MARKET_CAP: makeLimit('MARKET_CAP'),
  PE_RATIO: makeLimit('PE_RATIO'),
  DIVIDEND_YIELD: makeLimit('DIVIDEND_YIELD'),
};

export const PERIOD_TO_SORT: Partial<
  Record<HistoricalQuotePeriodEnum, SecuritySortTypeEnum>
> = {
  ONE_YEAR: 'PRICE_CHANGE_1Y',
  TWO_YEARS: 'PRICE_CHANGE_2Y',
  FIVE_YEARS: 'PRICE_CHANGE_5Y',
};
