import { Box, Button, PL } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext, type SubmitHandler } from 'react-hook-form';

import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';
import { ControlledInput } from '~/components/form/ControlledInput';
import { printableAsciiChars } from '~/forms/validators';
import type { OnboardingIdentityFirstQuestionType } from '~/graphql/types';
import { Checkbox } from '~/toolbox/checkbox';
import { Divider } from '~/toolbox/divider';

import type { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';

type PrimaryDisclosuresFormProps = {
  continueLabel: string;
  nextQuestion?: OnboardingIdentityFirstQuestionType | null;
  onSubmit: SubmitHandler<FinancialSuitabilityFormFields>;
  handleNext: () => void;
};

export const PrimaryDisclosuresForm = ({
  continueLabel,
  nextQuestion,
  onSubmit,
  handleNext,
}: PrimaryDisclosuresFormProps) => {
  const { control, watch, setValue, formState, handleSubmit } =
    useFormContext<FinancialSuitabilityFormFields>();
  const values = watch();
  const disabled = !formState.isValid;

  const { primary } = values;

  const isAffiliated =
    primary?.exchangeOrFinraAffiliationDisclosure?.isAffiliated;
  const isControlPerson = primary?.controlPersonDisclosure?.isControlPerson;
  const isPoliticallyExposed =
    primary?.politicalExposureDisclosure?.isPoliticallyExposed;
  const isSubjectToBackupWithholding =
    primary?.backupWithholding?.isSubjectToBackupWithholding;
  const hasNoDisclosures =
    Boolean(
      isAffiliated ||
        isControlPerson ||
        isPoliticallyExposed ||
        isSubjectToBackupWithholding,
    ) === false;

  const uncheckAllDisclosures = () => {
    // exchangeOrFinraAffiliationDisclosure
    setValue(
      'primary.exchangeOrFinraAffiliationDisclosure.isAffiliated',
      false,
      { shouldValidate: true },
    );
    setValue('primary.exchangeOrFinraAffiliationDisclosure.firmName', null, {
      shouldValidate: true,
    });

    // controlPersonDisclosure
    setValue('primary.controlPersonDisclosure.isControlPerson', false, {
      shouldValidate: true,
    });
    setValue('primary.controlPersonDisclosure.companySymbols', null, {
      shouldValidate: true,
    });

    // politicalExposureDisclosure
    setValue(
      'primary.politicalExposureDisclosure.isPoliticallyExposed',
      false,
      { shouldValidate: true },
    );
    setValue(
      'primary.politicalExposureDisclosure.politicalOrganization',
      null,
      { shouldValidate: true },
    );
    setValue(
      'primary.politicalExposureDisclosure.immediateFamilyMembers',
      null,
      { shouldValidate: true },
    );

    // backupWithholding
    setValue('primary.backupWithholding.isSubjectToBackupWithholding', false, {
      shouldValidate: true,
    });
  };

  React.useEffect(() => {
    // Clear out fields tied to any disclosures if it's unchecked
    if (!isAffiliated) {
      setValue('primary.exchangeOrFinraAffiliationDisclosure.firmName', null, {
        shouldValidate: true,
      });
    }
    if (!isControlPerson) {
      setValue('primary.controlPersonDisclosure.companySymbols', null, {
        shouldValidate: true,
      });
    }
    if (!isPoliticallyExposed) {
      setValue(
        'primary.politicalExposureDisclosure.politicalOrganization',
        null,
        { shouldValidate: true },
      );
      setValue(
        'primary.politicalExposureDisclosure.immediateFamilyMembers',
        null,
        { shouldValidate: true },
      );
    }
  }, [
    isAffiliated,
    isControlPerson,
    isPoliticallyExposed,
    isSubjectToBackupWithholding,
    setValue,
  ]);

  return (
    <>
      <Box>
        <ControlledCheckbox
          name="primary.exchangeOrFinraAffiliationDisclosure.isAffiliated"
          label="I am affiliated with or work for a broker/dealer."
          control={control}
        />
        {isAffiliated && (
          <ControlledInput
            name="primary.exchangeOrFinraAffiliationDisclosure.firmName"
            label="Firm Name"
            placeholder="M1 Finance, etc."
            control={control}
            rules={{
              required: true,
              validate: {
                printableAsciiChars: (value) =>
                  typeof value === 'string' && printableAsciiChars(value),
              },
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
        )}
      </Box>
      <Divider />
      <Box>
        <ControlledCheckbox
          name="primary.controlPersonDisclosure.isControlPerson"
          label="I am a 10% shareholder or director of a publicly traded company."
          control={control}
        />
        {isControlPerson && (
          <ControlledInput
            name="primary.controlPersonDisclosure.companySymbols"
            valueIsArray
            label="Symbols"
            placeholder="GOOG, AMZN, META"
            control={control}
            rules={{
              required: true,
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
        )}
      </Box>
      <Divider />
      <Box>
        <ControlledCheckbox
          name="primary.politicalExposureDisclosure.isPoliticallyExposed"
          label="I am or an immediate family member is a current or former Public Official."
          control={control}
        />
        {isPoliticallyExposed && (
          <>
            <ControlledInput
              name="primary.politicalExposureDisclosure.politicalOrganization"
              label="Affiliated public entities"
              placeholder="e.g. Director of the Port Authority"
              control={control}
              rules={{
                required: true,
                validate: {
                  printableAsciiChars: (value) =>
                    typeof value === 'string' && printableAsciiChars(value),
                },
              }}
            />
            <ControlledInput
              name="primary.politicalExposureDisclosure.immediateFamilyMembers"
              label="Names of immediate family members"
              placeholder="e.g. George Washington, President of USA"
              rules={{
                required: true,
              }}
              valueIsArray
              control={control}
            />
          </>
        )}
      </Box>
      <Divider />
      <Box>
        <ControlledCheckbox
          name="primary.backupWithholding.isSubjectToBackupWithholding"
          label="I am subject to backup withholding by the IRS."
          my={8}
          control={control}
        />
      </Box>
      <Divider />
      <Box>
        <Checkbox
          label={<PL fontWeight={600}>None of these describes me.</PL>}
          checked={hasNoDisclosures}
          onChange={() => !hasNoDisclosures && uncheckAllDisclosures()}
        />
      </Box>
      <Divider />
      <Button
        type={nextQuestion ? 'button' : 'submit'}
        mt={32}
        size="large"
        label={continueLabel}
        onClick={(e) => {
          e.preventDefault();
          nextQuestion ? handleNext() : handleSubmit(onSubmit)();
        }}
        disabled={disabled}
      />
    </>
  );
};
