import { Button, Flex, HXXS } from '@m1/liquid-react';
import * as React from 'react';
import { type InjectedFormProps, FormSection } from 'redux-form';

import { AddressAutofill } from '~/forms/fields/AddressAutofill';
import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { connectForm } from '~/hocs';
import { useSelector } from '~/redux/hooks';
import { Grid } from '~/toolbox/grid';
import { getEnumEntries } from '~/utils';

import {
  AddressFields,
  DropdownField,
  TextField,
  PhoneNumberField,
} from '../fields';
import { required, printableAsciiChars } from '../validators';

import type { ContactInfoFormProps } from './types';
import { middleInitialValidation } from './utils';

type SectionHeaderProps = {
  label: string;
  mt?: number;
};

const SectionHeader = ({ label, mt = 0 }: SectionHeaderProps) => (
  <Grid.Row style={{ marginBottom: 4, marginTop: mt }}>
    <Grid.Col sm={8}>
      <HXXS>{label}</HXXS>
    </Grid.Col>
  </Grid.Row>
);

const ContactInfoFormComponent = ({
  autoFocus,
  condensed,
  disableAddressEditing,
  disableNameEditing,
  disablePhoneNumberEditing,
  handleSubmit,
  hideSubmit,
  initialValues,
  showPhoneNumber,
  skipAddressAutofill,
  tooltip,
}: InjectedFormProps<any> & ContactInfoFormProps) => {
  const suffixSource = [
    {
      description: 'None',
      name: '__NONE__',
    },
    ...getEnumEntries('NameSuffixEnum'),
  ];

  const shouldShowSuffixDropdown =
    !disableNameEditing || (disableNameEditing && initialValues.suffix);

  const isSubmitDisabled =
    disableAddressEditing && disableNameEditing && disablePhoneNumberEditing;

  const { addressAutofill, isAutofillDirty } = useSelector<{
    addressAutofill: string | undefined;
    isAutofillDirty: boolean;
  }>((state) => {
    return {
      addressAutofill:
        state.form['contact-info-modern']?.values?.homeAddress
          ?.autofilledAddress,
      isAutofillDirty: Boolean(
        state.form['contact-info-modern']?.fields?.homeAddress
          ?.autofilledAddress?.touched,
      ),
    };
  });

  const [showAddressAutofill, setShowAddressAutofill] = React.useState<boolean>(
    Boolean(!skipAddressAutofill),
  );

  React.useEffect(() => {
    if (!addressAutofill && isAutofillDirty) {
      setShowAddressAutofill(false);
    }
  }, [setShowAddressAutofill, isAutofillDirty, addressAutofill]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex alignItems="center">
        <HXXS>Legal name</HXXS>
        <Flex mt={4}>{tooltip}</Flex>
      </Flex>
      <Grid.Row>
        <Grid.Col sm={8} xs={12}>
          <TextField
            name="firstName"
            label="First name"
            maxLength={30}
            disabled={disableNameEditing}
            validate={[required, printableAsciiChars]}
            autoFocus={autoFocus}
          />
        </Grid.Col>
        <Grid.Col sm={4} xs={12}>
          <TextField
            name="middleInitial"
            label="M.I. (Opt.)"
            maxLength={1}
            disabled={disableNameEditing}
            validate={middleInitialValidation}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row
        style={{
          marginTop: 16,
        }}
      >
        <Grid.Col xs={shouldShowSuffixDropdown ? 8 : 12}>
          <TextField
            name="lastName"
            label="Last name"
            maxLength={20}
            disabled={disableNameEditing}
            validate={[required, printableAsciiChars]}
          />
        </Grid.Col>
        {shouldShowSuffixDropdown && (
          <Grid.Col xs={4}>
            <DropdownField
              name="suffix"
              placeholder="Suffix"
              label="Suffix"
              source={suffixSource}
              disabled={disableNameEditing}
              // @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
              parse={(v) => {
                return v && v !== '__NONE__' ? v : null;
              }}
            />
          </Grid.Col>
        )}
      </Grid.Row>
      <SectionHeader label="Address" mt={48} />
      <FormSection name="homeAddress">
        {showAddressAutofill ? (
          <AddressAutofill setShowAddressAutofill={setShowAddressAutofill} />
        ) : (
          <AddressFields
            modernLayout
            condensed={condensed}
            kind="NORMAL"
            disabled={disableAddressEditing}
          />
        )}
      </FormSection>
      {showPhoneNumber && (
        <Grid.Row>
          <Grid.Col sm={condensed ? 6 : 5} xs={12}>
            <PhoneNumberField disabled={disablePhoneNumberEditing} />
          </Grid.Col>
        </Grid.Row>
      )}
      {!hideSubmit && (
        <Button
          disabled={isSubmitDisabled}
          type="submit"
          kind="primary"
          size="large"
          label="Continue"
          mt={48}
        />
      )}
      <FormMocker
        formName="contact-info-modern"
        label="Mock names!"
        fields={[
          {
            name: 'firstName',
            value: 'B0b',
          },
          {
            name: 'middleInitial',
            value: 'N',
          },
          {
            name: 'lastName',
            value: 'R0ss',
          },
        ]}
      />
    </form>
  );
};

export const ContactInfoFormModern = connectForm({
  form: 'contact-info-modern',
  destroyOnUnmount: false,
})(ContactInfoFormComponent);
