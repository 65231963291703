import { Flex, PL, type SemanticColorNames } from '@m1/liquid-react';
import * as React from 'react';

type ValueToDisplayProps = {
  value: string | Array<string>;
  color?: SemanticColorNames;
};

export const ValueToDisplay = ({ value, color }: ValueToDisplayProps) => {
  if (Array.isArray(value)) {
    // if there are multiple, stack them vertically
    return (
      <Flex flexDirection="column" py={4}>
        {value.filter(Boolean).map((singleValue, i) => (
          <PL
            color={color ?? 'foregroundNeutralMain'}
            content={singleValue}
            key={i}
            mb={12}
          />
        ))}
      </Flex>
    );
  }

  // otherwise, simply render the single value
  return <PL color={color ?? 'foregroundNeutralMain'} content={value} py={4} />;
};
