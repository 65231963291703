import type { SagaIterator } from 'redux-saga';

import { InitializeUserDataDocument } from '~/graphql/hooks';
import type {
  BootstrapAuthedUserQuery,
  InitializeUserDataQuery,
} from '~/graphql/types';
import {
  loadedUserDataFromServer,
  loadedWatchlistFromServer,
} from '~/redux/actions';

import { apolloQuerySaga } from '../apolloQuerySaga';
import { getSentryReporter } from '../common/getLoggersSaga';
import { call, put, spawn } from '../effects';

import { parseUserData, type ParsedUserData } from './parseUserData';

/*
  Reads and ingests "user data", which is outside-of-schema key/value pairs
  clients can save and read on a user-level basis.

  This is not an encouraged pattern these days. Lens can do a much better job
  providing specific schema for whatever use cases clients might have used
  this in the past for. Opaque schema is also very tricky to see what exists
  and how it's being used.

  This function is kept around primarily because a user's watchlist primary
  store is still user data. I imagine one day we'll make it a real service, but
  until then this pattern must still be supported.
*/

export function* initializeUserData({
  viewer,
}: BootstrapAuthedUserQuery): SagaIterator<void> {
  const data = viewer.user?.data || [];
  const { others, watchlist }: ParsedUserData = yield call(parseUserData, data);

  // User watchlist has different handling than the rest of user data.
  // Spawn a new async saga to handle loading it up.
  if (watchlist) {
    yield spawn(fetchAndLoadWatchlist, watchlist);
  }

  yield put(loadedUserDataFromServer(others));
}

export function* fetchAndLoadWatchlist(
  watchlist: ReadonlyArray<string>,
): SagaIterator<void> {
  const response: InitializeUserDataQuery | null | undefined = yield call(
    fetchBootstrapAuthedUserWatchlistQuery,
    watchlist,
  );
  if (!response?.nodes) {
    return;
  }

  const cleanedWatchlistIds = [];
  for (const node of response.nodes) {
    // Only active securities are "allowed" on a watchlist.
    if (
      node &&
      (node.__typename === 'Equity' || node.__typename === 'Fund') &&
      node.isActive
    ) {
      cleanedWatchlistIds.push(node.id);
    }
  }

  yield put(loadedWatchlistFromServer(cleanedWatchlistIds));
}

function* fetchBootstrapAuthedUserWatchlistQuery(
  // @ts-expect-error - TS7006 - Parameter 'watchlist' implicitly has an 'any' type.
  watchlist,
): SagaIterator<InitializeUserDataQuery | null | undefined> {
  const sentry = yield call(getSentryReporter);
  try {
    const { data } = yield call(apolloQuerySaga, {
      query: InitializeUserDataDocument,
      variables: {
        watchlist,
      },
    });
    return data;
  } catch (e: any) {
    sentry.message('Error fetching initializeUserDataQuery.', {
      rawError: e,
    });
  }

  return null;
}
