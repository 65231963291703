import { PM } from '@m1/liquid-react';
import React from 'react';
import { components } from 'react-select';

export const TransferParticipantGroupHeading = ({ children, ...rest }: any) => {
  return (
    <components.GroupHeading {...rest}>
      <PM color="foregroundNeutralSecondary" content={children} />
    </components.GroupHeading>
  );
};
