import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  PersonalLoansApplicationByIdSagaDocument,
  type PersonalLoansApplicationByIdSagaQueryResult,
} from '~/graphql/hooks';
import type {
  LoanApplicationStatusEnum,
  PersonalLoansApplicationByIdSagaQuery,
  PersonalLoanStatusEnum,
} from '~/graphql/types';

import { apolloQuerySaga } from '../../apolloQuerySaga';

export type ApplicationByIdStatusPolling = {
  loanId: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            PersonalLoansApplicationByIdSagaQuery['viewer']['borrow']
          >['personalLoans']
        >['application']
      >['loan']
    >['id']
  >;

  status: LoanApplicationStatusEnum;
  loanStatus: PersonalLoanStatusEnum;
};

export function* fetchPersonalLoansApplicationByIdInfo(
  applicationId: string,
): SagaIterator<ApplicationByIdStatusPolling | null | undefined> {
  try {
    const { data }: PersonalLoansApplicationByIdSagaQueryResult = yield call(
      apolloQuerySaga,
      {
        query: PersonalLoansApplicationByIdSagaDocument,
        variables: {
          applicationId,
        },
      },
    );

    return {
      status: data?.viewer?.borrow?.personalLoans?.application?.status
        ?.status as any,
      loanId: data?.viewer?.borrow?.personalLoans?.application?.loan?.id as any,
      loanStatus: data?.viewer?.borrow?.personalLoans?.application?.loan
        ?.status as any,
    };
  } catch (e: any) {
    throw new Error(`error fetchPersonalLoansApplicationByIdInfo: ${e}`);
  }
}
