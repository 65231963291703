import { Flex, PL } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { AnnouncementContext } from '~/components/announcement';
import { GenericSystemError } from '~/components/GenericSystemError';
import { TransferEmailVerificationCard } from '~/components/transfer-email-verification-card';
import { useTransfersPageQuery } from '~/graphql/hooks';
import type { TransfersPageQuery } from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

export const TransfersPage = () => {
  const { contentWidth } = useLayout();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(enteredPage('TRANSFERS', undefined));

    return () => dispatch(exitedPage('TRANSFERS', undefined));
  }, [dispatch]);

  const { data, loading } = useTransfersPageQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.transfers) {
    return <GenericSystemError />;
  }

  const { transfers } = data.viewer;

  return (
    <Flex flexDirection="column" width="100%" mt={32}>
      <StyledPageContent contentWidth={contentWidth} flex={1}>
        <TransfersBanner viewer={data.viewer} />
        {transfers ? (
          <Outlet />
        ) : (
          <PL content="There was an error loading your transfers. Please try again later." />
        )}
      </StyledPageContent>
    </Flex>
  );
};

const TransfersBanner = ({
  viewer,
}: {
  viewer: TransfersPageQuery['viewer'];
}) => {
  const isPrimaryEmailVerified = Boolean(viewer.user?.isPrimaryEmailVerified);
  if (viewer.user && !isPrimaryEmailVerified) {
    return (
      <TransferEmailVerificationCard
        resendEmailStyle="button"
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
      />
    );
  }

  const forTransfersAnnouncement = viewer.announcements?.forTransfers;
  if (forTransfersAnnouncement) {
    return (
      <AnnouncementContext
        announcement={forTransfersAnnouncement}
        context="TRANSFERS"
      />
    );
  }

  return null;
};
