import { Box, Flex, LL, PS, Tooltip } from '@m1/liquid-react';
import React from 'react';

import type { AppInformationalMessageCardFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';

export const AppInformationalMessagePopup = ({
  item,
}: {
  item: NonNullable<AppInformationalMessageCardFragment['items']>[number];
}) => {
  return (
    <Flex
      key={item?.label}
      alignItems="center"
      justifyContent="space-between"
      mt={2}
    >
      <Flex alignItems="center">
        <PS content={item?.label} />

        {item?.tooltip && (
          <Tooltip
            placement="bottom-start"
            triggerBoxProps={{ style: { marginLeft: 4 } }}
            body={
              <Box maxWidth={240}>
                {item.tooltip.text?.map((line, idx) => (
                  <LL
                    key={idx}
                    color="foregroundNeutralMain"
                    content={line}
                    mt={4}
                  />
                ))}
              </Box>
            }
          >
            <AppImage appImage={item.tooltip.icon} />
          </Tooltip>
        )}
      </Flex>

      {item?.value && <PS content={item.value} />}
    </Flex>
  );
};
