import * as React from 'react';

import { Route } from 'react-router-dom';

import { AccountSelectionForm } from './components/AccountSelectionForm';
import { WireTransferInstructions } from './components/WireTransferInstructions/WireTransferInstructions';
import { WireTransferProvider } from './WireTransferProvider';

export const WireTransferRoute = () => (
  <Route
    path="wire-transfer"
    handle={{
      fallbackRoute: {
        to: '/d/move-money',
      },
    }}
    element={<WireTransferProvider />}
  >
    <Route index element={<AccountSelectionForm />} />
    <Route path="wire-instructions" element={<WireTransferInstructions />} />
  </Route>
);
