import type { SagaIterator } from 'redux-saga';

import {
  MoveUnmanagedHoldingsDocument,
  type MoveUnmanagedHoldingsMutationResult,
} from '~/graphql/hooks';
import type { MoveUnmanagedHoldingsInput } from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import {
  hideLoadingSpinner,
  type MoveUnmanagedHoldingsAction,
  showLoadingSpinner,
} from '~/redux/actions';
import type { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../apolloMutationSaga';

import { call, put, select, takeEvery } from '../effects';

export function* moveUnmanagedHoldingsSaga(): SagaIterator<void> {
  yield takeEvery('MOVE_UNMANAGED_HOLDINGS', handleMoveUnmanagedHoldings);
}

function* handleMoveUnmanagedHoldings({
  payload,
}: MoveUnmanagedHoldingsAction): SagaIterator<void> {
  const { parentPortfolioSliceId, unmanagedHoldingIds } = payload;
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  try {
    yield put(showLoadingSpinner());

    const { data }: MoveUnmanagedHoldingsMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: MoveUnmanagedHoldingsDocument,
        variables: {
          input: {
            parentPortfolioSliceId,
            unmanagedHoldingIds,
          } satisfies MoveUnmanagedHoldingsInput,
        },
      },
    );

    yield put(hideLoadingSpinner());

    yield call(navigate, { to: '/d/invest/unmanaged-holdings' });

    if (data?.moveUnmanagedHoldings) {
      const isSuccess = Boolean(data.moveUnmanagedHoldings.successMessage);

      yield put({
        payload: {
          content: isSuccess
            ? (data.moveUnmanagedHoldings.successMessage as string)
            : (data.moveUnmanagedHoldings.errorMessage as string),
          duration: 'short',
          kind: isSuccess ? 'success' : 'alert',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    }
  } catch (e: any) {
    yield put(hideLoadingSpinner());

    yield call(navigate, { to: '/d/invest/unmanaged-holdings' });
    yield put({
      payload: {
        content:
          'Something went wrong and your changes could not be saved. Please try again or contact us.',
        duration: 'short',
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  }
}
