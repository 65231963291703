import * as React from 'react';
import { type InjectedFormProps, FormSection } from 'redux-form';

import { connectForm } from '~/hocs';
import { Grid } from '~/toolbox/grid';
import { getEnumEntries } from '~/utils';

import {
  AddressFields,
  DropdownField,
  TextField,
  PhoneNumberField,
} from '../fields';
import { required, printableAsciiChars } from '../validators';

import { SubmitButtons } from './SubmitButtons';
import type { ContactInfoFormProps } from './types';
import { middleInitialValidation } from './utils';

const ContactInfoFormComponent = ({
  autoFocus,
  condensed,
  disableAddressEditing,
  disableNameEditing,
  disablePhoneNumberEditing,
  handleSubmit,
  hideSubmit,
  initialValues,
  showPhoneNumber,
  submitButtonLayout = 'primary',
}: InjectedFormProps<any> & ContactInfoFormProps) => {
  const suffixSource = [
    {
      description: 'None',
      name: '__NONE__',
    },
    ...getEnumEntries('NameSuffixEnum'),
  ];

  const shouldShowSuffixDropdown =
    !disableNameEditing || (disableNameEditing && initialValues.suffix);

  const isSubmitDisabled =
    disableAddressEditing && disableNameEditing && disablePhoneNumberEditing;

  return (
    <form onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col sm={condensed ? 12 : 6} xs={12}>
          <Grid.Row>
            <Grid.Col xs={7}>
              <TextField
                name="firstName"
                label="First name"
                maxLength={30}
                disabled={disableNameEditing}
                validate={[required, printableAsciiChars]}
                autoFocus={autoFocus}
              />
            </Grid.Col>
            <Grid.Col xs={5}>
              <TextField
                name="middleInitial"
                label="M.I. (Opt.)"
                maxLength={1}
                disabled={disableNameEditing}
                validate={middleInitialValidation}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col sm={condensed ? 12 : 6} xs={12}>
          <Grid.Row>
            <Grid.Col xs={shouldShowSuffixDropdown ? 7 : 12}>
              <TextField
                name="lastName"
                label="Last name"
                maxLength={20}
                disabled={disableNameEditing}
                validate={[required, printableAsciiChars]}
              />
            </Grid.Col>
            {shouldShowSuffixDropdown && (
              <Grid.Col xs={5}>
                <DropdownField
                  name="suffix"
                  source={suffixSource}
                  disabled={disableNameEditing}
                  // @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
                  parse={(v) => {
                    return v && v !== '__NONE__' ? v : null;
                  }}
                />
              </Grid.Col>
            )}
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <FormSection name="homeAddress">
        <AddressFields
          condensed={condensed}
          kind="NORMAL"
          disabled={disableAddressEditing}
        />
      </FormSection>
      {showPhoneNumber && (
        <Grid.Row>
          <Grid.Col sm={condensed ? 6 : 5} xs={12}>
            <PhoneNumberField disabled={disablePhoneNumberEditing} />
          </Grid.Col>
        </Grid.Row>
      )}
      {!hideSubmit && (
        <SubmitButtons
          buttonLayout={submitButtonLayout}
          isDisabled={isSubmitDisabled}
        />
      )}
    </form>
  );
};

export const ContactInfoForm = connectForm({
  form: 'contact-info',
})(ContactInfoFormComponent);
