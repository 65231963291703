import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useBorrowSplashPageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import {
  setActiveBorrowAccount,
  setActivePersonalLoanAccount,
  setBorrowAccountDestination,
  type BorrowAccountDestinationEnumValues,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

type BorrowRedirectProps = {
  destination: BorrowAccountDestinationEnumValues;
  id: string;
  path: '/d/borrow/margin' | '/d/borrow/personal/transactions';
};

export const BorrowSplashPage = () => {
  const { data, loading } = useBorrowSplashPageQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const marginAccountId = useSelector((state) => state.global.borrowAccountId);
  const personalLoanAccountId = useSelector(
    (state) => state.global.personalLoanAccountId,
  );
  const borrowAccountDestination = useSelector(
    (state) => state.navigation.borrowAccountDestination,
  );

  const borrow = data?.viewer.borrow;
  const hasActivePersonalLoanApplication =
    borrow?.personalLoans?.activeApplication;

  React.useEffect(() => {
    // Route user as follows, if they have Margin go there, unless they have personal loan and were on personal loan before
    // Otherwise if user has margin go there, if they have personal go there, otherwise show marketing screens
    const borrowRedirect = ({ path, destination, id }: BorrowRedirectProps) => {
      dispatch(setBorrowAccountDestination(destination));
      if (destination === 'margin') {
        dispatch(setActiveBorrowAccount(id));
      } else if (destination === 'personal') {
        dispatch(setActivePersonalLoanAccount(id));
      }

      navigate({ to: path, options: { replace: true } });
    };
    if (
      marginAccountId &&
      borrow?.hasBorrowedBefore &&
      borrowAccountDestination === 'margin'
    ) {
      borrowRedirect({
        path: '/d/borrow/margin',
        destination: 'margin',
        id: marginAccountId,
      });
    } else if (
      personalLoanAccountId &&
      borrowAccountDestination === 'personal' &&
      !hasActivePersonalLoanApplication
    ) {
      borrowRedirect({
        path: '/d/borrow/personal/transactions',
        destination: 'personal',
        id: personalLoanAccountId,
      });
    } else if (marginAccountId && borrow?.hasBorrowedBefore) {
      borrowRedirect({
        path: '/d/borrow/margin',
        destination: 'margin',
        id: marginAccountId,
      });
    } else if (personalLoanAccountId && !hasActivePersonalLoanApplication) {
      borrowRedirect({
        path: '/d/borrow/personal/transactions',
        destination: 'personal',
        id: personalLoanAccountId,
      });
    } else if (marginAccountId && borrow?.hasBorrowAccounts) {
      borrowRedirect({
        path: '/d/borrow/margin',
        destination: 'margin',
        id: marginAccountId,
      });
    }
  }, [
    borrow,
    borrowAccountDestination,
    marginAccountId,
    dispatch,
    navigate,
    personalLoanAccountId,
    hasActivePersonalLoanApplication,
  ]);
  if (loading) {
    return null;
  }
  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  navigate({ to: '/d/borrow/marketing' });
};
