import { TwoColumn4by8, Box } from '@m1/liquid-react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { AnnouncementContext } from '~/components/announcement';
import { Footer } from '~/components/Footer';
import { usePersonalLoanAnnouncementsQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useNavigate } from '~/hooks/useNavigate';
import { StyledLandingPageContent } from '~/pages/dashboard/common/ProductLandingPage.styled';
import { MastHeader } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';
import type { AppState } from '~/redux';

import { PersonalLoanPaginatedTransactions } from '../components/common-transactions/PersonalLoanPaginatedTransactions';

import { LandingPageBanners } from './LandingPageBanners';
import { LoanDetailsCard } from './LoanDetailsCard';
import { PaymentProgressCard } from './PaymentProgressCard';
import { PersonalLoanLandingPageHeader } from './PersonalLoanLandingPageHeader';

export const PersonalLoanLandingPage = () => {
  const { contentWidth } = useLayout();
  const accountId = useSelector(
    (state: AppState) => state.global.personalLoanAccountId,
  );
  const navigate = useNavigate();

  const { data } = usePersonalLoanAnnouncementsQuery();

  if (!accountId) {
    return null;
  }

  const announcement = data?.viewer.announcements?.forPersonalLoans;

  const handleMakePayment = () => {
    navigate({
      to: '/d/c/create-transfer',
      query: {
        toParticipantId: accountId,
        previousRouteName: location.pathname,
      },
    });
  };

  return (
    <Box width="100%">
      <MastHeader />
      <StyledLandingPageContent contentWidth={contentWidth}>
        {announcement && (
          <AnnouncementContext
            announcement={announcement}
            context="PERSONAL_LOANS"
            mt={32}
          />
        )}
        <PersonalLoanLandingPageHeader
          handleMakePayment={handleMakePayment}
          accountId={accountId}
          hasAnnouncement={Boolean(announcement)}
        />
        <LandingPageBanners accountId={accountId} />
        <TwoColumn4by8>
          <TwoColumn4by8.Column1>
            <Box minWidth={225}>
              <PaymentProgressCard accountId={accountId} />
              <LoanDetailsCard accountId={accountId} />
            </Box>
          </TwoColumn4by8.Column1>
          <TwoColumn4by8.Column2>
            <PersonalLoanPaginatedTransactions accountId={accountId} />
          </TwoColumn4by8.Column2>
        </TwoColumn4by8>
      </StyledLandingPageContent>
      <Footer />
    </Box>
  );
};
