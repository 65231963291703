import * as React from 'react';

import type { FundScreenerQueryResultContextValue } from './types';

export const FundScreenerQueryResultContext = React.createContext(
  {} as FundScreenerQueryResultContextValue,
);

export const useFundScreenerResult = () => {
  const queryResult = React.useContext(FundScreenerQueryResultContext);
  if (!queryResult.queryResult) {
    throw new Error(
      'useFundScreenerResult must be used within a FundScreenerProvider',
    );
  }
  return queryResult;
};
