import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  GetSecondaryProfileSagaDocument,
  type GetSecondaryProfileSagaQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../../apolloQuerySaga';

export function* getSecondaryProfile(): SagaIterator<boolean> {
  const { data }: GetSecondaryProfileSagaQueryResult = yield call(
    apolloQuerySaga,
    {
      query: GetSecondaryProfileSagaDocument,
    },
  );

  return Boolean(data?.viewer.profile?.secondary);
}
