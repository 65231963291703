import * as React from 'react';
import type {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkResult,
} from 'react-plaid-link';

import { AppContext } from '~/AppContext';
import { SelectPlaidLink } from '~/components/SelectPlaidLink';
import {
  receivedPlaidLinkToken,
  skippedPlaidConnection,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks/useDispatch';
import { useSelector } from '~/redux/hooks/useSelector';
import { useToast } from '~/toasts/useToast';

type ReceivedPlaidLinkTokenPayload = PlaidLinkResult & {
  fundingSourceId?: string | null | undefined;
};

export type SelectPlaidStepProps = {
  onPlaidExit?: (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata,
  ) => void;
};

export const SelectPlaidStep = ({ onPlaidExit }: SelectPlaidStepProps) => {
  const dispatch = useDispatch();
  const { sentry } = React.useContext(AppContext);
  const { addToast } = useToast();
  const { fundingSourceId } = useSelector(
    (state) => state.newFlows.connectBank,
  );

  const onExit = React.useCallback(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      if (error) {
        sentry.message('PlaidLink exited with error', {
          level: 'warning',
          extra: {
            error,
            plaidLinkTokenExists: Boolean(metadata.link_session_id),
            metadata,
          },
        });
        addToast({
          content:
            'You were unable to connect your bank through Plaid. You can try again or contact Client Support for help.',
          kind: 'alert',
          duration: 'long',
        });
      } else {
        sentry.message('PlaidLink exited without error', {
          extra: {
            metadata,
          },
        });
      }

      if (onPlaidExit) {
        onPlaidExit(error, metadata);
      } else {
        dispatch(skippedPlaidConnection());
      }
    },
    [dispatch, addToast, sentry],
  );

  const onSuccess = React.useCallback(
    (plaidLinkPublicToken: string, metadata: any) => {
      const { account_id: plaidAccountId, account } = metadata;

      const receivedPlaidLinkTokenPayload: ReceivedPlaidLinkTokenPayload = {
        plaidAccountId,
        plaidLinkPublicToken,
        verificationStatus: account['verification_status'],
        fundingSourceId,
      };

      sentry.message('PlaidLink succeeded.', {
        extra: {
          plaidLinkPublicToken,
          plaidAccountId,
          metadata,
        },
      });
      dispatch(receivedPlaidLinkToken(receivedPlaidLinkTokenPayload));
    },
    [dispatch, fundingSourceId, sentry],
  );

  return <SelectPlaidLink onSuccess={onSuccess} onExit={onExit} />;
};
