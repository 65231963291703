import React from 'react';

import { useDocumentManagementPageQuery } from '~/graphql/hooks';
import type {
  DocumentAssociatedService,
  DocumentBusinessPurpose,
  DocumentUploadRequestAssociatedService,
} from '~/graphql/types';
import { useSearchParams } from '~/hooks/useSearchParams';
import { Spinner } from '~/toolbox/spinner';

import { type EnumName, getEnumValues } from '~/utils/enums';

import { DocumentUploadGenericPage } from './components/DocumentUploadGenericPage';
import { DocumentUploadRequestPage } from './components/DocumentUploadRequestPage';

export const DocumentManagementPage = () => {
  const [searchParams] = useSearchParams();
  const { data, loading } = useDocumentManagementPageQuery({
    variables: {
      associatedService:
        (searchParams.get(
          'associatedService',
        ) as DocumentUploadRequestAssociatedService) ?? undefined,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const documentRequestCenter = data?.viewer.documentUploadRequestsCenter;
  if (!documentRequestCenter?.documentUploadRequests?.edges?.[0]?.node) {
    // if there is no document request, show the generic upload page
    // first, check for path parameters
    const associatedService = searchParams.get('associatedService');
    const businessPurpose = searchParams.get('businessPurpose');

    return (
      <DocumentUploadGenericPage
        associatedService={findEnumValue<DocumentAssociatedService>(
          associatedService,
          'DocumentAssociatedService',
          'OTHER',
        )}
        businessPurpose={findEnumValue<DocumentBusinessPurpose>(
          businessPurpose,
          'DocumentBusinessPurpose',
          'OTHER',
        )}
      />
    );
  }

  return (
    <DocumentUploadRequestPage documentRequestCenter={documentRequestCenter} />
  );
};

/**
 * Searches for an enum value associated with the specified enum name that
 * matches the specified input (without spaces and case-insensitive). If the
 * enum isn't found, returns the specified default value.
 */
function findEnumValue<T extends string>(
  input: string | null | undefined,
  enumName: EnumName,
  defaultValue: T,
): T {
  if (typeof input !== 'string') {
    return defaultValue;
  }

  // Check if any enum value matches the input (case-insensitive, and spaces are removed):
  const matchingValue = getEnumValues<T>(enumName).find(
    (enumValue) =>
      enumValue.replace(/[-_\s]/g, '').toLowerCase() ===
      input.replace(/[-_\s]/g, '').toLowerCase(),
  );

  return matchingValue ?? defaultValue;
}
