import { Button, FloatingPanel } from '@m1/liquid-react';
import * as React from 'react';

import {
  type Pie,
  type Slice,
  equalizeSliceListAllocations,
  readPieTreeByPath,
} from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { PieDestinationMenu } from './PieDestinationMenu';

type PortfolioOrganizerSliceEditButtonsProps = {
  setShowCreatePieFormCallback: (arg0: boolean) => void;
};

export const PortfolioOrganizerSliceEditButtons = ({
  setShowCreatePieFormCallback,
}: PortfolioOrganizerSliceEditButtonsProps) => {
  const dispatch = useDispatch();
  const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);

  const { disabledFeatures, isInEditMode } = useSelector((state) => {
    const { disabledFeatures, session } = state.portfolioOrganizer;

    return {
      disabledFeatures,
      isInEditMode:
        session.mode === 'EDIT_PIE' || session.mode === 'EDIT_ROOT_PIE',
    };
  });

  const pieTree = useSelector<Pie>((state) => {
    return readPieTreeByPath(
      state.portfolioOrganizer.pieTree,
      state.portfolioOrganizer.path,
    );
  });

  // If both move and remove are disabled, we hide the checkboxes
  // so we need to modify how equalize works
  const hideCheckboxes =
    disabledFeatures?.includes('REMOVE_SLICES') &&
    disabledFeatures?.includes('MOVE_SLICES');

  const selectedSlices = React.useMemo<Array<Slice>>(() => {
    if (!pieTree.slices) {
      return [];
    }

    return hideCheckboxes
      ? pieTree.slices
      : pieTree.slices.reduce((accumulatedSlices, slice) => {
          if (slice.to.__checked) {
            // @ts-expect-error - TS2345 - Argument of type 'Slice' is not assignable to parameter of type 'never'.
            accumulatedSlices.push(slice);
          }
          return accumulatedSlices;
        }, []);
  }, [hideCheckboxes, pieTree.slices]);

  const onEqualizeClick = React.useCallback(() => {
    const equalizedSlices = equalizeSliceListAllocations(selectedSlices, true);

    equalizedSlices.forEach((slice) => {
      dispatch({
        payload: {
          id: slice.to.__id,
          percentage: slice.percentage,
        },
        type: 'ADJUSTED_PORTFOLIO_ORGANIZER_SLICE_PERCENTAGE',
      });
    });
  }, [dispatch, selectedSlices]);

  const onRemoveClick = React.useCallback(() => {
    dispatch({
      payload: selectedSlices,
      type: 'REMOVE_PORTFOLIO_ORGANIZER_SLICES',
    });

    dispatch({
      payload: {
        ids: selectedSlices.map((slice) => slice.to.__id),
      },
      type: 'REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS',
    });
  }, [dispatch, selectedSlices]);

  const enableEqualize = React.useMemo(
    () =>
      Boolean(
        (selectedSlices.length &&
          selectedSlices.length === pieTree.slices?.length) ||
          hideCheckboxes,
      ),
    [hideCheckboxes, selectedSlices.length, pieTree.slices?.length],
  );

  return (
    <ButtonGroup behavior="centered" gap={8}>
      {!isInEditMode && !disabledFeatures?.includes('MOVE_SLICES') && (
        <FloatingPanel
          active={isPanelOpen}
          onShow={() => setIsPanelOpen(true)}
          onHide={() => setIsPanelOpen(false)}
          controlled
          trigger={
            <Button
              disabled={selectedSlices.length === 0}
              kind="secondary"
              label="Move"
              size="medium"
            />
          }
          placement="auto"
          content={
            <PieDestinationMenu
              onCreatePieClick={() => {
                setShowCreatePieFormCallback(true);
                setIsPanelOpen(false);
              }}
            />
          }
        />
      )}
      {!disabledFeatures?.includes('EQUALIZE_SLICES') && (
        <Button
          disabled={!enableEqualize}
          kind="secondary"
          label="Equalize"
          onClick={onEqualizeClick}
          size="medium"
        />
      )}
      {!disabledFeatures?.includes('REMOVE_SLICES') && (
        <Button
          disabled={selectedSlices.length === 0}
          kind="secondary"
          label="Delete"
          onClick={onRemoveClick}
          size="medium"
        />
      )}
    </ButtonGroup>
  );
};
