import { PL, LL, Flex } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { Gain } from '~/components/gain';
import { LabelWithValue } from '~/components/label-with-value';
import { PositionGainText } from '~/components/PositionGainText';
import { SecurityNavigableCard } from '~/components/security/SecurityNavigableCard';
import type { InvestActivityEntryFragment } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';
import { formatCurrency, formatCryptoOrCurrency } from '~/utils/formatting';

import { NegativeDividendPopup } from './NegativeDividendPopup';

function pluralize(amount: number, noun: string): string {
  return noun + (amount === 1 ? '' : 's');
}

const renderActivityDate = (activity: InvestActivityEntryFragment) => {
  return (
    <>
      <LL color="foregroundNeutralSecondary" content="Date" mt={16} mb={8} />
      <PL
        fontWeight={600}
        content={moment.parseZone(activity.date).format('ll')}
        mb={16}
      />
    </>
  );
};

const renderActivityDescription = (activity: InvestActivityEntryFragment) => {
  return activity.description ? (
    <>
      <LL color="foregroundNeutralSecondary" content="Description" mb={8} />
      <PL fontWeight={600} content={activity.description} />
    </>
  ) : null;
};

const renderActivityDescriptionLink = (
  activity: InvestActivityEntryFragment,
) => {
  return activity.__typename === 'InvestActivityCashEntry' &&
    activity.activityDescriptionLink ? (
    <Link mt={5} to={activity.activityDescriptionLink.url} target="_blank">
      {activity.activityDescriptionLink.title}
    </Link>
  ) : null;
};

export const ActivityEntryCardDetails = ({
  activity,
}: {
  activity: InvestActivityEntryFragment;
}) => {
  switch (activity.__typename) {
    case 'InvestActivityCashEntry':
      return (
        <>
          {activity.transferDetails && (
            <Flex flexDirection="column" mb={16}>
              <LL
                color="foregroundNeutralSecondary"
                content="Transfer ID"
                mb={8}
              />

              {activity.transferDetails.transferId}
            </Flex>
          )}

          <LL color="foregroundNeutralSecondary" content="Value" mb={8} />

          {activity.tooltipCopy ? (
            <NegativeDividendPopup
              label={
                <PL
                  fontWeight={600}
                  content={<Gain value={activity.amount} />}
                />
              }
              tooltipCopy={activity.tooltipCopy}
            />
          ) : (
            <PL fontWeight={600} content={<Gain value={activity.amount} />} />
          )}
          <Grid
            style={{
              padding: 0,
            }}
          >
            <Grid.Row>
              <Grid.Col xs={12}>
                {renderActivityDate(activity)}

                {activity.isRelatedToSecurity && (
                  <LabelWithValue
                    label="Security"
                    value={
                      activity.cashSecurity &&
                      activity.cashSecurity.security ? (
                        <SecurityNavigableCard
                          security={activity.cashSecurity.security}
                          mt={2}
                        />
                      ) : (
                        '--'
                      )
                    }
                  />
                )}
              </Grid.Col>
              <Grid.Col sm={12} xs={12}>
                {renderActivityDescription(activity)}
                {renderActivityDescriptionLink(activity)}
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </>
      );
    case 'InvestActivityPositionEntry':
      return (
        <>
          <LL color="foregroundNeutralMain" content="Value" mb={8} />
          <PL fontWeight={600} mb={16}>
            <PositionGainText
              render={({ shouldPluralize, value }) =>
                `${value} share${shouldPluralize ? 's' : ''}`
              }
              value={activity.quantity}
            />
          </PL>
          <Grid
            style={{
              padding: 0,
            }}
          >
            <Grid.Row>
              <Grid.Col sm={6} xs={12}>
                {renderActivityDate(activity)}
                {renderActivityDescription(activity)}
              </Grid.Col>
              <Grid.Col sm={6} xs={12}>
                <LabelWithValue
                  label="Security"
                  style={{
                    marginBottom: 16,
                  }}
                  value={
                    // @ts-expect-error - TS2339 - Property 'positionSecurity' does not exist on type 'InvestActivityEntry'.
                    activity.positionSecurity.security ? (
                      <SecurityNavigableCard
                        // @ts-expect-error - TS2339 - Property 'positionSecurity' does not exist on type 'InvestActivityEntry'.
                        security={activity.positionSecurity.security}
                        mt={2}
                      />
                    ) : (
                      activity.positionSecurity.descriptor
                    )
                  }
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </>
      );
    case 'InvestActivityTradeEntry': {
      const isCrypto = activity.tradeSecurity.security?.type === 'CRYPTO';

      return (
        <>
          <LL color="foregroundNeutralMain" content="Value" mb={8} />
          <PL
            fontWeight={600}
            content={formatCurrency(activity.amount)}
            mb={16}
          />
          <Grid
            style={{
              padding: 0,
            }}
          >
            <Grid.Row>
              <Grid.Col sm={6} xs={12}>
                {renderActivityDate(activity)}
                <LabelWithValue
                  label="Share price"
                  style={{
                    marginBottom: 16,
                  }}
                  value={formatCryptoOrCurrency(activity.sharePrice, isCrypto)}
                />
                <LabelWithValue
                  label="Quantity"
                  style={{
                    marginBottom: 16,
                  }}
                  value={activity.quantity}
                />
              </Grid.Col>
              <Grid.Col sm={6} xs={12}>
                <LabelWithValue
                  label="Security"
                  style={{
                    marginBottom: 16,
                  }}
                  value={
                    activity.tradeSecurity.security ? (
                      <SecurityNavigableCard
                        security={activity.tradeSecurity.security}
                        mt={2}
                      />
                    ) : (
                      activity.tradeSecurity.descriptor
                    )
                  }
                />
                {renderActivityDescription(activity)}
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </>
      );
    }
    case 'InvestActivityTradeSummaryEntry':
      return (
        <Grid
          style={{
            padding: 0,
          }}
        >
          <Grid.Row>
            <Grid.Col sm={6} xs={12}>
              <LL color="foregroundNeutralMain" content="Value" mb={8} />

              <Grid.Row>
                <Grid.Col xs={6}>
                  <PL
                    fontWeight={600}
                    content={`${activity.countOfBuys} ${pluralize(
                      activity.countOfBuys,
                      'buy',
                    )}`}
                    mb={8}
                  />
                  <PL
                    fontWeight={600}
                    content={formatCurrency(activity.amountOfBuys)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <PL
                    fontWeight={600}
                    content={`${activity.countOfSells} ${pluralize(
                      activity.countOfSells,
                      'sell',
                    )}`}
                    mb={8}
                  />
                  <PL
                    fontWeight={600}
                    content={formatCurrency(activity.amountOfSells)}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col sm={6} xs={12}>
              {renderActivityDate(activity)}
              {renderActivityDescription(activity)}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      );
    default:
      return (
        <>
          {renderActivityDate(activity)}
          {renderActivityDescription(activity)}
        </>
      );
  }
};
