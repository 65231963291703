import { PS } from '@m1/liquid-react';
import * as React from 'react';

import type { InvestDisclosuresType, RichTextFragment } from '~/graphql/types';
import { useModal } from '~/hooks/useModal';
import { RichText } from '~/lens-toolbox/RichText';

export const DisclosureLink = ({
  isCrypto,
  disclosures,
}: {
  isCrypto: boolean;
  disclosures: RichTextFragment[];
}) => {
  const { showModal } = useModal('TRADE_DISCLOSURES');

  const disclosuresFor: InvestDisclosuresType = isCrypto
    ? 'CRYPTO'
    : 'SECURITIES';
  return (
    <PS textAlign="left">
      {disclosures?.map((disclosure, i) => (
        <RichText
          key={i}
          richText={disclosure}
          containerProps={{
            display: 'inline-block',
          }}
          buttonProps={{
            kind: 'link',
            onClick: () => showModal({ disclosuresFor }),
          }}
        />
      ))}
    </PS>
  );
};
