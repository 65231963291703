import { type BoxProps, PM, Box } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { Link } from '~/toolbox/link';

export const CryptoDisclosure = ({
  includeTradeDisclosure = true,
  ...rest
}: BoxProps & {
  includeTradeDisclosure?: boolean;
}) => (
  <Box {...rest}>
    <Illustration name="cryptoDisclosure" height={24} />
    {includeTradeDisclosure && (
      <PM color="foregroundNeutralMain">
        You are trading cryptocurrencies through your Bakkt account.
        Cryptocurrencies are not transacted through a registered broker-dealer
        or FINRA member, and your cryptocurrency holdings are not FDIC or SIPC
        insured.{' '}
        <Link
          to="https://bakkt.com/disclosures"
          font="PM"
          fontWeight={400}
          underline
          target="_blank"
        >
          View disclosures
        </Link>
      </PM>
    )}
  </Box>
);
