import { ApolloError } from '@apollo/client';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { AuthManager } from '~/auth';
import {
  RegisterUserDocument,
  type RegisterUserMutationResult,
} from '~/graphql/hooks';
import type { RegisterUserInput } from '~/graphql/types';
import { getEnvironment } from '~/hooks/useEnvironment';
import type { NavigateFunction } from '~/hooks/useNavigate';
import {
  ACTION_TYPES as ACTIONS,
  changeRegisterUserFlowStep as changeStep,
  displayNotification,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';
import { readAffiliateCode, readReferrerCode } from '~/redux/selectors';
import {
  NOTIFICATION_LOCATIONS,
  NOTIFICATION_TYPES,
  REGISTER_USER_FLOW_STEPS as STEPS,
} from '~/static-constants';

import { apolloMutationSaga } from '../apolloMutationSaga';
import { bootstrapAuthedUser } from '../bootstrapAuthedUser';
import { dismissNotificationsAfterDelay, getLoggers } from '../common';
import { readAttribution } from '../crossDomainStorageSaga';

import { saveRecentLogin } from './loginSaga';
import { makeFlowFuncs } from './utils';

const { takeFlowStep } = makeFlowFuncs('registerUser');

export function* registerUserSaga(): SagaIterator<void> {
  yield takeLatest(ACTIONS.BEGIN_REGISTER_USER_FLOW, beginRegisterUserFlow);
}

function* beginRegisterUserFlow(action: any): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.SUBMIT_REGISTER_USER_FORM,
    continueWithRegistration,
    action,
  );
  yield takeLatest('WARNED_MOBILE_USERS', handleWarnedMobileUsers);

  yield fork(
    takeFlowStep,
    STEPS.PROMOTION_SIGNUP_OUTCOME,
    finishedPromotionSignupOutcome,
  );
}

function* finishedPromotionSignupOutcome(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  yield call(navigate, { to: '/onboarding/phone-verification' });
}

function* handleWarnedMobileUsers(): SagaIterator<void> {
  yield put({
    type: 'FINISHED_REGISTER_USER_FLOW',
  });
}

function* continueWithRegistration(
  flowAction: any,
  action: any,
): SagaIterator<void> {
  const { analytics } = yield call(getLoggers);

  try {
    yield put(showLoadingSpinner());

    const otherInputs = yield all({
      referrerCode: select(readReferrerCode),
      affiliateCode: select(readAffiliateCode),
      attribution: call(readAttribution),
    });

    const mutationInput: RegisterUserInput = {
      ...action.payload,
      ...otherInputs,
    };
    const auth: AuthManager = yield getContext('auth');

    const { data }: RegisterUserMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: RegisterUserDocument,
        variables: {
          input: mutationInput,
        },
      },
    );
    auth.updateRequiredAuth(data?.registerUser?.outcome);
    const createdDate = data?.registerUser?.outcome?.viewer?.user?.created;

    // NOTE: Must bootstrap authenticated user before sending userRegistered
    // analytics event in order to provide the correct User Id to Segment CDP
    yield call(bootstrapAuthedUser);
    analytics.mutation('account', 'userRegistered');
    if (typeof createdDate === 'string') {
      analytics.userProperties({
        created_date: createdDate,
      });
    }

    // Save registered user to User Picker recent list in non-production environments
    const environment = getEnvironment().label;
    saveRecentLogin({ ...action.payload, environment });
    if (action.payload.promoCode) {
      yield put({
        type: 'SET_PROMOTION_SIGNUP_OUTCOME',
        payload: data?.registerUser?.outcome?.promotionSignUpOutcomePage,
      });
      yield put(changeStep(STEPS.PROMOTION_SIGNUP_OUTCOME));
    } else if (window.matchMedia('(max-width: 960px)').matches) {
      yield put(changeStep(STEPS.WARN_MOBILE_USERS));
    } else {
      yield put({
        type: 'FINISHED_REGISTER_USER_FLOW',
      });
    }
  } catch (e: any) {
    yield put({
      type: ACTIONS.REGISTER_USER_ATTEMPT_FAILED,
      error: e,
    });
    yield put(
      displayNotification({
        type: NOTIFICATION_TYPES.ERROR_ALT,
        location: NOTIFICATION_LOCATIONS.SIGNUP,
        message: getErrorMessage(e, Boolean(action.payload.promoCode)),
      }),
    );
    yield fork(dismissNotificationsAfterDelay);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function getErrorMessage(e: ApolloError, hasPromoCode?: boolean): string {
  for (const err of e.graphQLErrors) {
    if (err?.extensions?.code) {
      switch (err?.extensions?.code) {
        case 'USERNAME_ALREADY_EXISTS':
          return hasPromoCode
            ? 'This offer is for new clients only. An M1 profile already exists for this email address.'
            : 'Sorry, an account already exists with this email.';
        case 'INVALID_USERNAME':
          return 'Please provide a valid email address.';
        case 'INVALID_PASSWORD':
          return 'Please enter a more secure password. (Tip: try adding a symbol!)';
        default:
          return 'Something went wrong with your request. Please try again later or contact support.';
      }
    }
  }
  return 'Something went wrong with your request. Please try again later or contact support.';
}

function RegisterUserError(message: string) {
  // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
  this.name = 'RegisterUserError';
  // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
  this.message = message;
}
RegisterUserError.prototype = Object.create(Error.prototype);
RegisterUserError.prototype.constructor = RegisterUserError;
