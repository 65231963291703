/* eslint-disable no-param-reassign */
import { equalizeSliceListAllocations } from './equalizeSliceListAllocations';
import { isNewPie, isOldPie, isPie } from './sliceableHelpers';
import type { Pie, Slice } from './types';

const sliceIsNotInPie = (pie: Pie, sliceToAdd: Slice): boolean =>
  Boolean(pie.slices?.every((slice) => slice.to.__id !== sliceToAdd.to.__id));

const isUnmanagedHolding = (sliceToAdd: Slice) =>
  sliceToAdd.to.type === 'security' &&
  sliceToAdd.to.__typename === 'UnmanagedHolding';

const addSlice = (
  existingSlices: Array<Slice> | null | undefined,
  sliceToAdd: Slice,
): Array<Slice> => {
  if (!existingSlices || !existingSlices.length) {
    return [sliceToAdd];
  }

  if (isUnmanagedHolding(sliceToAdd)) {
    return [sliceToAdd, ...existingSlices];
  }

  return isNewPie(sliceToAdd.to)
    ? [sliceToAdd, ...existingSlices]
    : [...existingSlices, sliceToAdd];
};

const replaceSlice = (
  existingSlices: Array<Slice> | null | undefined,
  newSlice: Slice,
  keepOriginalTarget?: boolean,
): Array<Slice> => {
  const slices = existingSlices ? [...existingSlices] : [];
  const foundIndex = slices.findIndex(
    (slice) => slice.to.__id === newSlice.to.__id,
  );

  if (foundIndex >= 0) {
    const oldSlice = slices[foundIndex];

    slices[foundIndex] = {
      // spread the old slice first so if there are any properties
      // that aren't in the new slice we still keep them.
      ...oldSlice,
      ...newSlice,
      percentage: keepOriginalTarget
        ? oldSlice.percentage
        : newSlice.percentage,
    };
  }

  return slices;
};

/*
  Performs deeply nested operations on a Pie tree. The path through
  the tree is indicated via IDs in the path argument to the function.

  Currently the function mutates the provided pie argument for simplicity.
  If you need to ensure the value is fresh (e.g. in a Redux reducer),
  then use a function like _.cloneDeep.
*/
export const updatePieTreeByPath = (
  pie: Pie,
  path: Array<string>,
  operations: {
    toAdd?: Array<Slice>;
    toAdjustPercentage?: {
      id: string;
      percentage: number;
    };
    toMove?: [Array<Slice>, Pie];
    toRemove?: Array<Slice>;
    toUpdateCheckState?: [Array<string>, boolean];
    toUpdateDescription?: string | null | undefined;
    toUpdateName?: string | null | undefined;
  },
): Pie => {
  if (path.length === 0) {
    if (operations.toAdd) {
      for (const sliceToAdd of operations.toAdd) {
        if (sliceIsNotInPie(pie, sliceToAdd)) {
          pie.slices = addSlice(pie.slices, sliceToAdd);
        } else if (isOldPie(sliceToAdd.to)) {
          pie.slices = replaceSlice(pie.slices, sliceToAdd);
        } else {
          // If we get here then we have a duplicate that came through due to an unmanaged holding.
          // In this case we want to replace the slice so its percentage is reset to 1 and its highlighted.
          pie.slices = replaceSlice(pie.slices, sliceToAdd, true);
        }
      }

      if (pie.__shouldEqualizeSlicePercentagesOnAdd && pie.slices) {
        pie.slices = equalizeSliceListAllocations(pie.slices, true);
      }
    }

    if (operations.toAdjustPercentage) {
      const { id, percentage } = operations.toAdjustPercentage;
      const slice = pie.slices?.find((slice) => slice.to.__id === id);
      if (slice) {
        slice.percentage = percentage;
      }

      if (pie.type === 'new_pie') {
        // if we've adjusted percentages we don't want to equalize automatically
        pie.__shouldEqualizeSlicePercentagesOnAdd = false;
      }
    }

    if (operations.toRemove && pie.slices) {
      const { toRemove } = operations;

      pie.slices =
        pie.slices.filter((slice) => {
          return toRemove.every(
            (sliceToRemove) => slice.to.__id !== sliceToRemove.to.__id,
          );
        }) || [];

      // If we've remove all the slices from the pie
      // reset the hasManuallyAdjustedPercentages flag
      if (!pie.slices.length) {
        pie.__shouldEqualizeSlicePercentagesOnAdd = true;
      }
    }

    if (operations.toMove) {
      const [slicesToMove, destination] = operations.toMove;

      updatePieTreeByPath(pie, path, {
        toRemove: slicesToMove,
      });

      updatePieTreeByPath(destination, [], {
        toAdd: slicesToMove,
      });
    }

    if (
      operations.toUpdateName !== undefined &&
      operations.toUpdateName !== null
    ) {
      pie.name = operations.toUpdateName;
    }

    if (
      operations.toUpdateDescription !== undefined &&
      operations.toUpdateDescription !== null
    ) {
      pie.description = operations.toUpdateDescription;
    }

    if (operations.toUpdateCheckState !== undefined) {
      const [sliceIds, isChecked] = operations.toUpdateCheckState;

      if (pie.slices) {
        const slices = pie.slices.reduce<any>((slicesAccumulated, slice) => {
          const { to } = slice;
          if (sliceIds.includes(to.__id)) {
            slicesAccumulated.push({
              ...slice,
              to: {
                ...to,
                __checked: isChecked,
              },
            });
          } else {
            slicesAccumulated.push(slice);
          }
          return slicesAccumulated;
        }, []);

        pie.slices = [...slices];
      }
    }

    return pie;
  }

  const foundIndex = pie.slices?.findIndex(
    (slice) => slice.to.__id === path[0],
  );

  const sliceable = pie.slices?.[foundIndex!].to;

  if (sliceable && isPie(sliceable)) {
    return updatePieTreeByPath(sliceable, path.slice(1), operations);
  }

  return pie;
};
