import {
  PL,
  Button,
  styled,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
} from '@m1/liquid-react';
import React, { useState } from 'react';

import type { MarginLoanModal } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

import { Radio } from '~/toolbox/radio';

const ButtonContainer = styled(Flex)`
  width: 100%;
  padding: 4px 8px;
  margin-top: 32px;
  justify-content: center;
`;

export const BorrowMarketingPageModal = ({
  isOpen,
  handleToggleModal,
  marginLoanModalData,
}: {
  isOpen: boolean;
  handleToggleModal: () => void;
  marginLoanModalData: MarginLoanModal;
}) => {
  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const { title, subtitle, ctaLabel, marginLoanAccounts } = marginLoanModalData;

  const handleNavigate = () => {
    const query: Record<string, string> = selectedAccount
      ? {
          fromParticipantId: selectedAccount,
          previousRouteName: '/d/borrow',
        }
      : { previousRouteName: '/d/borrow' };

    navigate({
      to: `d/c/transfer`,
      query,
    });

    handleToggleModal();
  };

  return (
    <Modal open={isOpen} onClose={handleToggleModal}>
      <ModalContent width="narrow">
        <ModalTitle>{title}</ModalTitle>
        <PL mb={32} content={subtitle} color="foregroundNeutralMain" />

        <Radio.Group value={selectedAccount} onChange={setSelectedAccount}>
          {marginLoanAccounts?.map((account) => {
            if (
              account?.transferParticipantId &&
              account?.transferParticipantName
            ) {
              return (
                <Radio.Choice
                  key={account?.transferParticipantId}
                  value={account?.transferParticipantId}
                  label={<PL>{account?.transferParticipantName}</PL>}
                />
              );
            }
          })}
        </Radio.Group>

        <ButtonContainer>
          <Button
            kind="text"
            disabled={!selectedAccount}
            size="medium"
            onClick={handleNavigate}
            label={ctaLabel || 'Continue'}
          />
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};
