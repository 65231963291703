import { Flex, PM } from '@m1/liquid-react';
import * as React from 'react';

import {
  IdentityFirstConfirmationScreenSection,
  type SectionRowData,
} from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenSection';
import {
  type IdentityFirstConfirmationProfileFragment,
  type IdentityFirstConfirmationUserFragment,
} from '~/graphql/types';
import { useSupportLink } from '~/hooks/useSupportLink';
import { Link } from '~/toolbox/link';

import {
  getKeyValuePairsFromInput,
  mapResponseToUserProfileData,
} from './IdentityFirstConfirmationScreenProfileSectionHelpers';

type IdentityFirstConfirmationScreenProfileSectionProps = {
  profile: IdentityFirstConfirmationProfileFragment;
  user: IdentityFirstConfirmationUserFragment;
};

export const IdentityFirstConfirmationScreenProfileSection = ({
  profile: remoteProfile,
  user,
}: IdentityFirstConfirmationScreenProfileSectionProps) => {
  const supportLink = useSupportLink('SUPPORT_CHANGE_ACCOUNT');
  const rows: SectionRowData[] = getKeyValuePairsFromInput(
    mapResponseToUserProfileData(remoteProfile),
  );

  const ContactUsFooter = (
    <Flex flexDirection="column" mt={32}>
      <PM
        content="Please contact Client Success to change profile details."
        mb={4}
      />
      <Link {...supportLink}>Contact Us</Link>
    </Flex>
  );

  return (
    <IdentityFirstConfirmationScreenSection
      editLabel={user.hasProduct ? null : 'Edit'}
      headingLabel="Profile"
      rows={rows}
      footer={user.hasProduct ? ContactUsFooter : null}
    />
  );
};
