import { ApolloError } from '@apollo/client';
import type { SagaIterator } from 'redux-saga';
import { call, put, race, take } from 'redux-saga/effects';

import {
  finishedAddSharedPie,
  hideLoadingSpinner,
  hideModal,
  showLoadingSpinner,
  showModal,
} from '~/redux/actions';
import type { ClickedAddSharedPieAction } from '~/redux/actions/events/eventsActions.types';

import {
  INVALID_SLICEABLE,
  createPieFromShareToken,
  getLoggers,
  isLoggedIn,
} from '../common';

export function* clickedAddSharedPie({
  shareToken,
  isCrypto,
  flow,
  username,
  password,
  isPieActive = true,
}: ClickedAddSharedPieAction['payload']): SagaIterator<void> {
  const { analytics } = yield call(getLoggers);
  const activeTab = flow === 'login' ? 0 : 1;
  // only add keys if values are valid
  const modalContext = Object.assign(
    {},
    (username && { username }) || undefined,
    (password && { password }) || undefined,
  );

  let didAttemptPieCreation = false;
  let didRegisterNewUser = false;

  let pieId: string | null | undefined = null;

  if (isPieActive && (yield call(isLoggedIn))) {
    didAttemptPieCreation = true;
    pieId = yield call(createPie, shareToken, isCrypto);
  } else {
    yield put(showModal('LOGIN_OR_REGISTER', { activeTab, ...modalContext }));

    // Listen for various Login and Register events to see which returns based off users actions
    const { register, failedLogin, failedRegisterUser } = yield race({
      login: take('FINISHED_LOGIN_FLOW'),
      register: take('FINISHED_REGISTER_USER_FLOW'),
      failedLogin: take('LOGIN_ATTEMPT_FAILED'),
      failedRegisterUser: take('REGISTER_USER_ATTEMPT_FAILED'),
    });

    if (failedLogin) {
      // Send Analytics
      analytics.recordAppAnalyticsEvent({
        name: 'share_pie_landing_page_userLogin_fail',
        params: [
          {
            name: 'share_token_id',
            value: shareToken,
          },
          {
            name: 'error_code',
            value: failedLogin.payload,
          },
        ],
      });
    }

    if (failedRegisterUser) {
      // Send Analytics
      const error = failedRegisterUser.error as ApolloError;
      const errorCode = error.graphQLErrors[0]?.extensions?.code;
      analytics.recordAppAnalyticsEvent({
        name: 'share_pie_landing_page_userRegister_fail',
        params: [
          {
            name: 'share_token_id',
            value: shareToken,
          },
          {
            name: 'error_code',
            value: errorCode,
          },
        ],
      });
    }

    yield put(hideModal('LOGIN_OR_REGISTER'));

    didRegisterNewUser = Boolean(register);

    if (isPieActive) {
      didAttemptPieCreation = true;
      pieId = yield call(createPie, shareToken, isCrypto);
    }
  }

  if (pieId !== INVALID_SLICEABLE) {
    if (pieId) {
      analytics.mutation(
        'share',
        didRegisterNewUser
          ? 'pieCreatedFromShareSignup'
          : 'pieCreatedFromShareLogin',
      );
    }
    yield put(
      finishedAddSharedPie({
        pieId,
        shareToken,
        didRegisterNewUser,
        didAttemptPieCreation,
        isCrypto,
      }),
    );
  }
}

function* createPie(
  shareToken: string,
  isCrypto?: boolean | null | undefined,
): SagaIterator<string | null | undefined> {
  let pieId = null;

  try {
    yield put(showLoadingSpinner());
    pieId = yield call(createPieFromShareToken, shareToken, isCrypto);
  } catch (e: any) {
    // Do nothing.
  } finally {
    yield put(hideLoadingSpinner());
  }

  return pieId;
}
