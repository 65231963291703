import * as React from 'react';

import { RESOLVE_CALL_STEPS } from '~/static-constants';

import { ResolveMarginCallCreateTransfer } from './create-transfer';
import { ResolveMarginCallPage } from './margin-call-information';
import { SetOrder } from './set-order';

type ResolveMarginCallFlowStep = ValueOf<typeof RESOLVE_CALL_STEPS>;
type ResolveMarginCallFlowContainerProps = {
  onFinishStep: () => void;
  step: ResolveMarginCallFlowStep;
};

export const ResolveMarginCallFlowContainer = ({
  onFinishStep,
  step,
  ...rest
}: ResolveMarginCallFlowContainerProps) => {
  const steps = {
    [RESOLVE_CALL_STEPS.RESOLVE_MARGIN_CALL]: ResolveMarginCallPage,
    [RESOLVE_CALL_STEPS.SET_ORDER]: SetOrder,
    [RESOLVE_CALL_STEPS.CREATE_TRANSFER]: ResolveMarginCallCreateTransfer,
  };

  const Step = steps[step];
  return Step ? <Step {...rest} /> : null;
};
