import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { List } from '~/toolbox/list';

export const BulkActionsDialogMessage = () => (
  <Box>
    <List>
      <List.Item content="Move: Select slices to move to a different or new Pie." />
      <List.Item content="Equalize: Select all slices to split target weights percentages equally." />
      <List.Item content="Remove: Select slices to remove from your portfolio." />
    </List>
  </Box>
);
