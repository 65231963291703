import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useInvestFundingHistoryQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { AccountFundingHistorySection } from './components/AccountFundingHistorySection';
import { AccountFundingIraActions } from './components/AccountFundingIraActions';

export const FundingHistoryPage = () => {
  const activeAccountId = useSelector((state) => state.global.activeAccountId);
  const { data, loading } = useInvestFundingHistoryQuery({
    variables: {
      id: activeAccountId as string,
    },
    skip: !activeAccountId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }
  if (data?.node?.__typename !== 'Account') {
    return <GenericSystemError />;
  }
  return (
    <Box mt={32}>
      {data.node.fundingActions && (
        <AccountFundingIraActions fundingActions={data.node.fundingActions} />
      )}
      <AccountFundingHistorySection account={data.node} />
    </Box>
  );
};
