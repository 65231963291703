import { Box, styled } from '@m1/liquid-react';

export const DismissNotificationButton = styled(Box)<{
  notification: string;
}>`
  text-align: right;
  color: ${({ theme, notification }) => {
    switch (notification) {
      case 'success':
        return `${theme.colors.success}`;
      case 'error':
        return `${theme.colors.critical}`;
      default:
        return `${theme.colors.foregroundNeutralMain}`;
    }
  }};
`;
