import { Button, Flex, styled } from '@m1/liquid-react';
import type { MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import type { EditorFeature } from '../PortfolioEditor.types';

import { CreatePieFormModal } from './Modals';

type Controls = Array<{
  copy: string;
  disabled: boolean;
  icon: MonochromaticIconName<'24'>;
  id: string;
  onClick: () => void;
}>;

const StyledFlex = styled(Flex)`
  gap: 8px;
  @container styledSliceablesContainer (max-width: 659px) {
    padding-bottom: 16px;
  }
`;

type PieControlsProps = {
  disabledFeatures?: Array<EditorFeature>;
  from: string;
};

export const PieControls = ({
  disabledFeatures = [],
  from,
}: PieControlsProps) => {
  const navigate = useNavigate();
  const [showCreatePieForm, setShowCreatePieForm] =
    React.useState<boolean>(false);

  const controls: Controls = [
    {
      copy: 'Create pie',
      disabled: Boolean(disabledFeatures.includes('CREATE_PIE')),
      icon: 'createPie24',
      id: 'create-pie',
      onClick: () => setShowCreatePieForm(true),
    },
    {
      copy: 'Add slice',
      disabled: Boolean(disabledFeatures.includes('ADD_SLICES')),
      icon: 'addSelected24',
      id: 'search-add',
      onClick: () =>
        navigate({ to: '/d/c/add-slices', options: { state: { from } } }),
    },
  ] as const;

  return (
    <StyledFlex>
      <CreatePieFormModal
        closeCreatePieForm={() => setShowCreatePieForm(false)}
        open={showCreatePieForm}
      />

      {controls.map(
        ({ copy, disabled, icon, id, onClick }) =>
          !disabled && (
            <React.Fragment key={id}>
              <Button
                key={id}
                onClick={onClick}
                kind="primary"
                leftIcon={icon}
                size="large"
              >
                {copy}
              </Button>
            </React.Fragment>
          ),
      )}
    </StyledFlex>
  );
};
