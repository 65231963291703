import { css, Flex, styled, type SemanticColorNames } from '@m1/liquid-react';
import React, { useRef } from 'react';

import { DashboardAnnouncements } from '~/components/Dashboard/DashboardAnnouncements';
import { useDashboardHeaderQuery } from '~/graphql/hooks';
import type { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useLayout } from '~/hooks/useLayout';
import { TopNavReferral } from '~/pages/dashboard/navigation/components/TopNavigation/TopNavContent/components/TopNavReferral';

import { DeveloperToolMenu } from '../DeveloperToolMenu';

import { TopNavContents } from './TopNavContent/TopNavContent';
import { TopNavigationContext } from './TopNavigationContext';

const StyledTopNavigation = styled.nav<{ $topBarHeight: number }>(
  ({ theme, $topBarHeight }) => css`
    display: flex;
    height: ${$topBarHeight}px;
    justify-content: space-between;
    padding: 0 40px;

    @media screen and (max-width: ${theme.breakpoints.SMALL}) {
      padding: 0;
    }
  `,
);

const RightActions = styled(Flex)`
  gap: 8px;
  position: relative;
`;

const StyledScrim = styled.span<{
  $background: SemanticColorNames;
  $left?: number;
  $right?: number;
}>`
  background: ${({ $background, theme }) => theme.colors[$background]};
  height: 60px;
  position: absolute;
  left: ${({ $left }) => ($left ? `${$left}px` : 'initial')};
  right: ${({ $right }) => ($right ? `${$right}px` : 'initial')};
  width: 24px;
`;

export type TopNavigationProps = {
  selectedNavigation: DashboardNavigation;
};

export const TopNavigation = ({ selectedNavigation }: TopNavigationProps) => {
  const leftScrimRef = useRef<HTMLSpanElement>(null);
  const rightScrimRef = useRef<HTMLSpanElement>(null);
  const { topBarHeight } = useLayout();
  const { data } = useDashboardHeaderQuery();
  const viewer = data?.viewer;

  // Doesn't surface for marketing/empty state pages
  if (selectedNavigation?.activeL3Nav?.includes('marketing')) {
    return null;
  }

  return (
    <TopNavigationContext.Provider value={{ leftScrimRef, rightScrimRef }}>
      <StyledTopNavigation $topBarHeight={topBarHeight}>
        <StyledScrim
          $background="gradientBackgroundScrimLeft"
          ref={leftScrimRef}
          $left={0}
        />
        <TopNavContents selectedNavigation={selectedNavigation} />
        <RightActions>
          <StyledScrim
            $background="gradientBackgroundScrimRight"
            ref={rightScrimRef}
            $left={-24}
          />
          <TopNavReferral />
          <DeveloperToolMenu viewer={viewer} />
          <DashboardAnnouncements announcements={viewer?.announcements} />
        </RightActions>
      </StyledTopNavigation>
    </TopNavigationContext.Provider>
  );
};
