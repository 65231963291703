import { useMultiStepFormContext } from '~/components/form/MultiStepForm';
import type { SubmitInvestAcatTransferInput } from '~/graphql/types';

import type { AcatWizardFields, AcatSteps } from '../AcatWizard.types';

export const useAcatWizardFormContext = () => {
  const acatWizardFormContext = useMultiStepFormContext<
    AcatWizardFields,
    AcatSteps
  >();
  if (!acatWizardFormContext) {
    throw new Error(
      'You must use useAcatWizardFormContext within AcatWizardFormProvider!',
    );
  }
  return acatWizardFormContext;
};

export function toMutationInput(
  fields: AcatWizardFields,
): SubmitInvestAcatTransferInput {
  if (!fields.selectedTransferType) {
    throw new Error('Mutation requires `selectedTransferType`.');
  }
  const selectedTransferType = fields.selectedTransferType;

  const cashAmount =
    typeof fields.cashAmount === 'string' ? Number(fields.cashAmount) : null;

  if (!fields.destinationAccountId) {
    throw new Error('Mutation requires `destinationAccountId`.');
  }
  const destinationAccountId = fields.destinationAccountId;

  if (!fields.externalBrokerageId) {
    throw new Error('Mutation requires `externalBrokerageId`.');
  }
  const externalBrokerageId = fields.externalBrokerageId;

  return {
    assets: fields.assets.map((asset) => {
      switch (asset.type) {
        case 'STOCK_OR_ETF':
          return {
            symbol: asset.symbol,
            quantity: Number(asset.quantity),
          };
        default:
          throw new Error('Unhandled asset type.');
      }
    }),
    cashAmount,
    destinationAccountId,
    externalBrokerageId,
    isPartial: selectedTransferType === 'PARTIAL_ACAT_TRANSFER',
    originAccountNumber: fields.originAccountNumber,
  };
}
