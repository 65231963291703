import { Flex } from '@m1/liquid-react';
import React from 'react';

import { AppContext } from '~/AppContext';
import type { AppImageFragment, LinkableFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

type SecuritySecUrlProps = {
  securitySecUrl: LinkableFragment;
  secIcon: AppImageFragment | null | undefined;
};

export const SecuritySecUrl = ({
  securitySecUrl,
  secIcon,
}: SecuritySecUrlProps) => {
  const { analytics } = React.useContext(AppContext);
  return (
    <Flex>
      {secIcon && <AppImage appImage={secIcon} height={24} />}
      {securitySecUrl && (
        <LinkableLink
          linkable={securitySecUrl}
          marginLeft={4}
          onClick={() =>
            analytics.recordAppAnalyticsEvent({
              name: 'm1_security_details_data_discovered',
              customParameters: [
                {
                  name: 'data_id',
                  value: 'sec_link',
                },
              ],
              customBoolParameters: [],
              customNumberParameters: [],
              valueParameter: null,
            })
          }
        />
      )}
    </Flex>
  );
};
