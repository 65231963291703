import { Link } from '@m1/liquid-react';
import * as React from 'react';

export const IntercomLink = () => {
  const onContactUsClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  if (!window.Intercom) {
    return (
      <Link href="https://help.m1.com" target="_blank">
        Contact support
      </Link>
    );
  }
  return <Link onClick={onContactUsClick}>Contact support</Link>;
};
