import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Toast } from '~/toolbox/toast';

import { useToast } from './useToast';

const StyledToastContainer = styled(Box)`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 3000;
`;

export const ToastContainer = () => {
  const { clearToast, currentToast } = useToast();

  const handleDismiss = React.useCallback(() => {
    if (currentToast?.onDismiss) {
      currentToast.onDismiss();
    }
    clearToast();
  }, [clearToast, currentToast]);

  if (!currentToast) {
    return null;
  }

  return (
    <StyledToastContainer>
      <Toast {...currentToast} onDismiss={handleDismiss} />
    </StyledToastContainer>
  );
};
