import type { Pie } from './types';

/**
 * This is BFS implementation of returning a pie within a pie via an ID.
 * This function is useful for finding pies by id regardless of the current pieTree "path".
 * */
export const readPieTreeBySliceId = (pie: Pie, pieSliceId: string): Pie => {
  // initialize a queue and enqueue the root node
  const queue = [];
  queue.push(pie);

  // failure exit condition
  while (queue.length) {
    // @ts-expect-error - TS7022 - 'pieSliceToCheck' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
    const pieSliceToCheck = queue.pop();

    // success exit condition
    if (pieSliceToCheck.__id === pieSliceId) {
      return pieSliceToCheck;
    }

    // if no match, enqueue child pie slices and try again
    // @ts-expect-error - TS7022 - 'slices' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
    const slices = pieSliceToCheck.slices || [];

    // @ts-expect-error - TS7022 - 'node' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
    for (const node of slices) {
      if (node.to.type === 'new_pie' || node.to.type === 'old_pie') {
        queue.push(node.to);
      }
    }
  }

  throw new Error(`${pieSliceId} not found within ${pie.__id}`);
};
