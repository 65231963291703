import clamp from 'lodash-es/clamp';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  spawn,
  takeEvery,
} from 'redux-saga/effects';

import {
  GenerateIdempotencyKeyDocument,
  type GenerateIdempotencyKeyMutationResult,
  OpenHighYieldSavingsAccountDocument,
  SavingsPreloadDocument,
  type SavingsPreloadQueryResult,
  SavingsCustomerDueDiligenceQuestionsDocument,
  type SavingsCustomerDueDiligenceQuestionsQueryResult,
} from '~/graphql/hooks';
import {
  type GenerateIdempotencyKeyInput,
  type InvestDueDiligence,
  type OpenHighYieldSavingsAccountInput,
  type SavingsCustomerDueDiligenceAnswer,
  type SavingsCustomerDueDiligenceResponse,
  type SavingsDueDiligenceQuestion,
} from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import {
  ACTION_TYPES,
  hideLoadingSpinner,
  setTransferIdempotencyKey,
  showLoadingSpinner,
  startFlow,
} from '~/redux/actions';
import type {
  SavingsAccountType,
  SavingsOnboardingFlowState,
} from '~/redux/reducers/newFlows/reducers/savingsOnboardingReducer';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import { apolloQuerySaga } from '~/redux/sagas/apolloQuerySaga';
import { userHasIncompleteProfile, getLoggers } from '~/redux/sagas/common';
import {
  CONNECT_BANK_FLOW_STEPS,
  SAVINGS_ONBOARDING_FLOW_STEPS as STEPS,
} from '~/static-constants';
import type { ToastProps } from '~/toolbox/toast';

import {
  changeStep,
  logTotalNetWorthAmount,
  logTotalNetWorthAnalytics,
  makeFlowFuncs,
  replaceRouterHistory,
} from '../../utils';

const { takeFlow, takeFlowStep, selectFlowState } =
  makeFlowFuncs('SAVINGS_ONBOARDING');

export function* savingsOnboardingSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginSavingsOnboardingFlow);
  yield takeEvery('SET_ACCOUNT_TYPE', function* (): SagaIterator<void> {
    yield call(customerDueDiligence);
  });
}

export function* customerDueDiligence() {
  try {
    yield put(showLoadingSpinner());
    const { accountType }: SavingsOnboardingFlowState = yield select(
      (state: AppState) => state.newFlows.SAVINGS_ONBOARDING,
    );

    if (!accountType) {
      yield call(changeStep, STEPS.ACCOUNT_TYPE);
    }
    const { data: questions }: SavingsCustomerDueDiligenceQuestionsQueryResult =
      yield call(apolloQuerySaga, {
        query: SavingsCustomerDueDiligenceQuestionsDocument,
        variables: { onboardingValue: accountType },
      });

    if (
      !questions?.viewer?.save?.savings?.onboarding
        ?.customerDueDiligenceQuestions?.questions
    ) {
      yield call(changeStep, STEPS.SHOW_ERROR_MESSAGE);
      throw new Error('Unable to load customer due diligence questions');
    }

    yield put({
      type: 'SET_DUE_DILIGENCE_QUESTIONS',
      payload: {
        dueDiligenceQuestions:
          questions?.viewer?.save?.savings?.onboarding
            ?.customerDueDiligenceQuestions?.questions,
      },
    });
    yield put(hideLoadingSpinner());
  } catch (e) {
    if (e instanceof Error) {
      yield put({
        payload: {
          content: 'An error has occurred. Please try again.',
          kind: 'alert',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
      throw new Error(e.message);
    }
  }
}

export function* beginSavingsOnboardingFlow(action: any): SagaIterator<void> {
  const { onFinish } = action.payload;

  yield fork(takeFlowStep, STEPS.JOINT_INVITED, finishedJointInvited);
  yield fork(takeFlowStep, STEPS.ACCOUNT_TYPE, finishedAccountType);
  yield fork(takeFlowStep, STEPS.ACCOUNT_INVITATION, finishedInvitationPage);
  yield fork(takeFlowStep, STEPS.NAME_ACCOUNT, finishedNameAccount);
  yield fork(takeFlowStep, STEPS.DUE_DILIGENCE, finishedDueDiligence);
  yield fork(takeFlowStep, STEPS.TRUSTED_CONTACT, finishedTrustedContact);
  yield fork(takeFlowStep, STEPS.ESIGN, finishedESign);
  yield fork(takeFlowStep, STEPS.CONFIRMATION, finishedConfirmation);
  yield fork(takeFlowStep, STEPS.FUND_ACCOUNT, finishedFundAccount);
  yield fork(takeFlowStep, STEPS.CONNECT_BANK, finishedConnectBank);
  yield fork(
    takeFlowStep,
    STEPS.FUNDING_COMPLETE,
    finishSavingsOnboarding,
    onFinish,
  );

  yield put({
    type: 'INITIATE_DATA_PRE_LOAD_REQUEST',
  });

  yield put(showLoadingSpinner());

  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const { data: preloadData }: SavingsPreloadQueryResult = yield call(
    apolloQuerySaga,
    {
      query: SavingsPreloadDocument,
    },
  );

  const viewer = preloadData?.viewer;

  try {
    if (!viewer || !viewer.save) {
      throw new Error('Unable to load savings data');
    } else if (!viewer.profile || !viewer.profile.primary) {
      throw new Error('Unable to load primary profile.');
    }
  } catch (e) {
    yield put({
      type: 'FINISH_DATA_PRE_LOAD_REQUEST',
    });

    if (e instanceof Error) {
      yield put({
        payload: {
          content: 'An error has occurred. Please try again.',
          kind: 'alert',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
      throw new Error(e.message);
    }
  }

  // Lens driven onboarding eligibility field that encapsulates Individual and Joint Savings eligibility as well as CMA
  const isEligibleToOnboard = viewer?.save?.isEligibleToOnboard;

  if (viewer?.save?.savings?.hasSavingsAccounts && !isEligibleToOnboard) {
    yield call(navigate, { to: '/d/spend/savings/transactions' });
    yield put(hideLoadingSpinner());
    return;
  }

  if (!isEligibleToOnboard) {
    yield call(navigate, { to: '/d/save/marketing' });
    yield put(hideLoadingSpinner());
    return;
  }

  // CXIO check for if user has the integrated onboarding feature flag and has an incomplete profile (Module 1 & Module 2)
  const hasIncompleteProfile = yield call(userHasIncompleteProfile);
  // CX IO flow: Navigate user to financial suitability (Module 2) if they have not completed it yet
  if (hasIncompleteProfile) {
    yield put(startFlow('FINANCIAL_SUITABILITY'));
    yield call(navigate, {
      to: '/onboarding/financial-details/disclosures',
      query: { product: 'savings', previousRouteName: '/d/save/marketing' },
    });
    yield put(hideLoadingSpinner());
    return;
  }

  const { save, profile } = viewer ?? {};
  const { data }: GenerateIdempotencyKeyMutationResult = yield call(
    apolloMutationSaga,
    {
      mutation: GenerateIdempotencyKeyDocument,
      variables: { input: {} satisfies GenerateIdempotencyKeyInput },
    },
  );

  yield put({
    type: 'SET_PRE_LOADED_DATA',
    payload: {
      isSubjectToBackupWithholding:
        profile?.primary.backupWithholding?.isSubjectToBackupWithholding,
      hasJointAccountInvitation: save?.savings?.hasJointAccountInvitation,
      isJointAccountsEligible: save?.isJointAccountsEligible,
      isMultipleAccountsEligible: save?.isMultipleAccountsEligible,
      isCashAccountsEligible: save?.isCashAccountsEligible,
      isJointCashAccountsEligible: save?.isJointCashAccountsEligible,
    },
  });

  yield put({
    type: 'FINISH_DATA_PRE_LOAD_REQUEST',
  });

  yield put(
    setTransferIdempotencyKey(
      data?.generateIdempotencyKey?.outcome?.idempotencyKey,
    ),
  );

  yield put(hideLoadingSpinner());
  const initialStep = yield call(getNextStep);
  yield call(changeStep, initialStep, true);
}

export function* finishedJointInvited(action: {
  payload: {
    accepted: boolean;
    accountType?: string | null;
  };
}): SagaIterator<void> {
  if (action.payload.accepted) {
    yield put({
      type: 'SET_ACCOUNT_TYPE',
      payload: action.payload.accountType,
    });
    const nextStep = yield call(getNextStep);
    yield call(changeStep, nextStep);
  } else {
    yield call(changeStep, STEPS.ACCOUNT_TYPE);
  }
}

// TODO If user has an invitation and accepts, thus skipping the account type screen, we should set the account type in redux
export function* finishedAccountType(action: {
  payload: SavingsAccountType;
}): SagaIterator<void> {
  yield put({
    type: 'SET_ACCOUNT_TYPE',
    payload: action.payload,
  });

  if (
    action.payload === 'IndividualSave' ||
    action.payload === 'JointSaveCoOwner' ||
    action.payload === 'IndividualCash' ||
    action.payload === 'JointCashCoOwner'
  ) {
    const step = yield call(getNextStep);
    yield call(changeStep, step);
  } else {
    // go to account invitation screen
    yield call(changeStep, STEPS.ACCOUNT_INVITATION);
  }
}

export function* finishedInvitationPage(): SagaIterator<void> {
  const nextStep = yield call(getNextStep);
  yield call(changeStep, nextStep);
}

export function* finishedNameAccount(): SagaIterator<void> {
  // Reducer handles finish step payload of account name if provided, so just keep them moving
  yield call(changeStep, STEPS.DUE_DILIGENCE);
}

export function* finishedDueDiligence(): SagaIterator<void> {
  const [questions, answers, accountType] = yield all([
    call(selectFlowState, 'dueDiligenceQuestions'),
    call(selectFlowState, 'dueDiligenceAnswers'),
    call(selectFlowState, 'accountType'),
  ]);
  const { answeredQuestions, expectedAnswers } = getDueDiligenceAnswerStats(
    questions,
    answers,
  );
  const isCashAccountType =
    accountType === 'IndividualCash' ||
    accountType === 'JointCashInitiate' ||
    accountType === 'JointCashCoOwner';

  if (answeredQuestions === expectedAnswers) {
    if (isCashAccountType) {
      yield call(changeStep, STEPS.TRUSTED_CONTACT);
    } else {
      yield call(changeStep, STEPS.ESIGN);
    }
  } else {
    yield call(changeStepToDueDiligence);
  }
}

export function* finishedTrustedContact(): SagaIterator<void> {
  yield call(changeStep, STEPS.ESIGN);
}

export function* finishedESign(): SagaIterator<void> {
  yield call(changeStep, STEPS.CONFIRMATION);
}

export function* finishedConfirmation(action: {
  payload?: 'edit' | undefined;
}): SagaIterator<void> {
  const { sentry } = yield call(getLoggers);
  if (action.payload === 'edit') {
    const {
      hasJointAccountInvitation,
      isJointAccountsEligible,
      isJointCashAccountsEligible,
      accountType,
    }: SavingsOnboardingFlowState = yield select(
      (state) => state.newFlows.SAVINGS_ONBOARDING,
    );
    if (accountType === 'IndividualSave' || accountType === 'IndividualCash') {
      return yield call(changeStep, STEPS.NAME_ACCOUNT);
    } else if (isJointAccountsEligible || isJointCashAccountsEligible) {
      // Inviter should go be able to change who they invite
      return yield call(changeStep, STEPS.ACCOUNT_INVITATION);
    } else if (hasJointAccountInvitation) {
      // Invitee should go back to due diligence, they cannot change anything before that
      return yield call(changeStep, STEPS.DUE_DILIGENCE);
    }
    return;
  }

  yield put(showLoadingSpinner());

  const {
    savingsOnboarding,
    investDueDiligence,
  }: {
    savingsOnboarding: SavingsOnboardingFlowState;
    investDueDiligence: InvestDueDiligence;
  } = yield select((state: AppState) => ({
    savingsOnboarding: state.newFlows.SAVINGS_ONBOARDING,
    investDueDiligence: {
      trustedContact: state.newFlows.INVEST_ONBOARDING.input.trustedContact,
    },
  }));

  const {
    termsAndConditionsSignature,
    dueDiligenceAnswers,
    accountName,
    coOwner,
    accountType,
    hasJointAccountInvitation,
    fullyPaidLendingStatus,
  } = savingsOnboarding;

  if (!termsAndConditionsSignature) {
    yield call(changeStep, STEPS.SHOW_ERROR_MESSAGE);
    throw new Error(
      'Missing terms and conditions signature in Earn onboarding.',
    );
  }

  const customerDueDiligence = dueDiligenceAnswers?.map((answer) => ({
    questionId: answer?.questionId,
    selectedAnswers: answer?.selectedAnswers?.map((a) => a?.answerId),
  })) as SavingsCustomerDueDiligenceResponse[];

  const hasMissingAnswer = customerDueDiligence.some(
    ({ selectedAnswers }) => !selectedAnswers || selectedAnswers.length === 0,
  );

  if (hasMissingAnswer) {
    yield put(hideLoadingSpinner());
    yield put({
      type: 'ADD_TOAST',
      payload: {
        content:
          'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
        kind: 'alert',
      } satisfies ToastProps,
    });
    sentry.message(
      'Missing customer due diligence answers in Earn onboarding.',
    );
    return;
  }

  let input: OpenHighYieldSavingsAccountInput = {
    accountType: 'IndividualSave',
    accountName,
    customerDueDiligence,
    termsAndConditionsSignature,
    ...(fullyPaidLendingStatus !== null
      ? {
          fplStatus: fullyPaidLendingStatus,
        }
      : {}),
  };

  let preloadQueryResult;
  try {
    const { data }: SavingsPreloadQueryResult = yield call(apolloQuerySaga, {
      query: SavingsPreloadDocument,
    });
    preloadQueryResult = data;
  } catch (e) {
    yield call(changeStep, STEPS.SHOW_ERROR_MESSAGE);
    throw new Error('Unable to load profile information');
  }

  const { profile, save } = preloadQueryResult?.viewer ?? {};

  const userHasOnboarded = yield select(
    (state) => state.global.userHasOnboarded,
  );
  // if user has not onboarded, fire total net worth analytics
  if (userHasOnboarded === false) {
    const totalNetWorthAmount = profile?.suitability?.totalNetWorth;
    if (totalNetWorthAmount) {
      yield spawn(logTotalNetWorthAnalytics, totalNetWorthAmount);
      yield spawn(logTotalNetWorthAmount, totalNetWorthAmount);
    }
  }

  if (accountType === 'JointSaveCoOwner' && hasJointAccountInvitation) {
    /** if accountType is joint and they have an invitation, this mutation should be set up to ACCEPT the invitation */
    const firstInvitation =
      save?.savings?.jointAccountInvitationsList?.edges?.[0];
    input = {
      ...input,
      accountType: 'JointSaveCoOwner',
      accountId: firstInvitation?.node?.id,
    };
  } else if (accountType === 'JointSaveInitiate' && coOwner) {
    /*
      if accountType is joint and they DON'T have an invitation,
      this mutation should be set up to SEND an invitation (requires coOwner)
    */
    input = {
      ...input,
      accountType: 'JointSaveInitiate',
      coOwners: [coOwner],
    };
  } else if (accountType === 'IndividualCash') {
    input = {
      ...input,
      accountType: 'IndividualCash',
      investDueDiligence,
    };
  } else if (accountType === 'JointCashInitiate' && coOwner) {
    input = {
      ...input,
      accountType: 'JointCashInitiate',
      coOwners: [coOwner],
      investDueDiligence,
    };
  } else if (accountType === 'JointCashCoOwner' && hasJointAccountInvitation) {
    const firstInvitation =
      save?.savings?.jointAccountInvitationsList?.edges?.[0];
    input = {
      ...input,
      accountType: 'JointCashCoOwner',
      investDueDiligence,
      accountId: firstInvitation?.node?.id,
    };
  }

  let openHighYieldSavingsAccountMutationResult;

  try {
    const { data } = yield call(apolloMutationSaga, {
      mutation: OpenHighYieldSavingsAccountDocument,
      variables: {
        input,
      },
    });

    openHighYieldSavingsAccountMutationResult = data;
  } catch (e: any) {
    yield put(hideLoadingSpinner());
    yield put({
      type: 'ADD_TOAST',
      payload: {
        content: e.message,
        kind: 'alert',
      } satisfies ToastProps,
    });
  } finally {
    yield put(hideLoadingSpinner());
  }

  const outcome =
    openHighYieldSavingsAccountMutationResult.openHighYieldSavingsAccount
      ?.outcome;
  const savingsAccountId = outcome?.savingsTransferParticipant?.id;
  const hasExternalFundingSource = outcome?.hasExternalFundingSource;
  const initialTransferParticipant = outcome?.initialTransferParticipant;
  const hasAvailableFundingSources = outcome?.hasAvailableFundingSources;

  const navigate = yield select((state) => state.routing.navigate);
  yield call(replaceRouterHistory, '/d/save');

  yield put({
    type: ACTION_TYPES.SET_ACTIVE_SAVINGS_ACCOUNT,
    payload: savingsAccountId,
  });
  yield put({
    type: 'SET_SAVINGS_ACCOUNT_ID',
    payload: savingsAccountId,
  });
  yield put({
    type: 'SET_USER_HAS_ONBOARDED',
    payload: Boolean(savingsAccountId),
  });
  yield put({
    type: 'SET_INITIAL_TRANSFER_PARTICIPANT',
    payload: initialTransferParticipant,
  });

  if (hasExternalFundingSource) {
    yield call(changeStep, STEPS.FUND_ACCOUNT);
  } else if (hasAvailableFundingSources) {
    yield put(
      navigate({
        to: '/d/bank-connection',
        query: { type: 'savings' },
      }),
    );
  } else {
    yield call(changeStep, STEPS.CONNECT_BANK);
  }
}

// @ts-expect-error - TS7006 - Parameter 'payload' implicitly has an 'any' type.
export function* finishedConnectBank(payload): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  if (payload.payload === 'skip') {
    // go to account skip funding
    yield call(changeStep, STEPS.FUNDING_COMPLETE);
  } else {
    // connect bank and then to fund acct
    yield call(navigate, {
      to: '/d/c/connect-bank',
      query: {
        initialStep: CONNECT_BANK_FLOW_STEPS.SELECT_PLAID,
        connectionType: 'savings',
        previousRouteName: '/d/spend/savings/transactions',
      },
    });
  }
}

export function* finishedFundAccount({
  payload,
}: {
  payload?: string;
}): SagaIterator<void> {
  if (payload === 'general-error') {
    throw new Error('General error in finishedFundAccount.');
  }

  yield call(changeStep, STEPS.FUNDING_COMPLETE);
}

export function* finishSavingsOnboarding(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish);
}

export function* changeStepToDueDiligence(): SagaIterator<void> {
  const [questions, answers] = yield all([
    call(selectFlowState, 'dueDiligenceQuestions'),
    call(selectFlowState, 'dueDiligenceAnswers'),
  ]);
  const { answeredQuestions, expectedAnswers } = getDueDiligenceAnswerStats(
    questions,
    answers,
  );

  yield call(
    changeStep,
    STEPS.DUE_DILIGENCE,
    false,
    {},
    { q: clamp(answeredQuestions, 0, expectedAnswers) },
  );
}

type DueDiligenceAnswerStats = {
  answeredQuestions: number;
  answerIndex: number;
  expectedAnswers: number;
};

export function getDueDiligenceAnswerStats(
  questions: ReadonlyArray<SavingsDueDiligenceQuestion>,
  answers: Array<SavingsCustomerDueDiligenceAnswer | null | undefined>,
): DueDiligenceAnswerStats {
  const expectedAnswers = questions.length;
  const answeredQuestions = answers?.filter(Boolean).length;
  return {
    expectedAnswers,
    answeredQuestions,
    // answerIndex is the 0-index of the last question that was answered (`q` query parameter)
    answerIndex: Math.max(answers.indexOf(answers.filter(Boolean).pop()), 0),
  };
}

/**
 * This function is for determining the next step the user should go to next based on their current state.
 * This is not ALWAYS applicable after each step, mainly steps that have conditional skipping/branching next.
 */
export function* getNextStep(): SagaIterator<ValueOf<typeof STEPS>> {
  let step;
  const {
    isSubjectToBackupWithholding,
    hasJointAccountInvitation,
    isJointAccountsEligible,
    accountType,
    isJointCashAccountsEligible,
  }: SavingsOnboardingFlowState = yield select(
    (state) => state.newFlows.SAVINGS_ONBOARDING,
  );

  const canInitiateJointAccount =
    isJointCashAccountsEligible || isJointAccountsEligible;

  // Joint invitees cannot name the account
  const canNameAccount =
    accountType === 'IndividualSave' ||
    accountType === 'IndividualCash' ||
    canInitiateJointAccount;

  if (!accountType && hasJointAccountInvitation) {
    // If a joint enabled user has an invitation they haven't accepted, let them
    // the accountType is set to JointSaveCoOwner in finishedJointInvited above
    step = STEPS.JOINT_INVITED;
  } else if (!accountType && !hasJointAccountInvitation) {
    // Every user, except those with a joint invitation, should choose their account type
    step = STEPS.ACCOUNT_TYPE;
  } else if (
    typeof isSubjectToBackupWithholding === 'boolean' &&
    canNameAccount
  ) {
    // If backup withholding answer is true or false, user has already answered so skip that step
    step = STEPS.NAME_ACCOUNT;
  }

  return step ?? STEPS.DUE_DILIGENCE;
}
