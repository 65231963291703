import type { ListItemProps } from '@m1/liquid-react';
import React from 'react';

import type {
  AppAnalyticsEvent,
  MoveMoneyListItemFragment,
} from '~/graphql/types';
import { RichText } from '~/lens-toolbox/RichText';

import type { MoveMoneyClick } from './MoveMoneyOptions';

export const toListItemProps = (
  item: MoveMoneyListItemFragment,
  clickHandler: ({ route, event }: MoveMoneyClick) => void,
): ListItemProps => {
  const { size, icon, pill, link, descriptionV2, ...rest } = item;
  return {
    ...rest,
    description: descriptionV2?.map((item, i) => (
      <RichText key={i} richText={item} />
    )),
    size: item.size ?? 'MEDIUM',
    icon: {
      name: icon?.names?.[0],
      color: icon?.color,
    },
    selectable: true,
    withoutArrow: true,
    ...(pill ? { pill: { ...pill, kind: pill.kind.toLowerCase() } } : {}),
    onClick: () => {
      link?.internalPath &&
        clickHandler({
          route: link?.internalPath,
          event: link?.analyticsEvent as AppAnalyticsEvent,
        });
    },
    padding: '16px',
  } as unknown as ListItemProps;
};
