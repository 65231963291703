import { Modal, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { FinancialSuitabilityForm } from '~/components/Onboarding/FinancialSuitabilityForm';
import { useGetFinancialInformationProfileQuery } from '~/graphql/hooks';
import type {
  EmploymentStatusEnum,
  FinancialInformationDueDiligenceFragment,
  FinancialInformationProfileFragment,
  ExchangeOrFinraAffiliationDisclosure,
  ControlPersonDisclosure,
  PoliticalExposureDisclosure,
} from '~/graphql/types';
import type { SegmentEventName } from '~/loggers/analytics-reporter/segment.types';
import { updateProfile } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { IdentityFirstConfirmationScreenFinancialSection } from './IdentityFirstConfirmationScreenHelpers';

export type FinancialInformationConfirmProps = {
  analyticsEvent?: SegmentEventName;
  onboarding: FinancialInformationDueDiligenceFragment;
  profile: FinancialInformationProfileFragment;
};

export type FinancialSuitabilityInfoFormValues = {
  annualIncomeAmount: number | null;
  employer: string | null | undefined;
  employmentStatus: EmploymentStatusEnum | undefined;
  liquidNetWorth: string | null;
  totalNetWorth: string | null;
  occupation: string | null | undefined;
  isSubjectToBackupWithholding: boolean | null | undefined;
  exchangeOrFinraAffiliationDisclosure:
    | ExchangeOrFinraAffiliationDisclosure
    | null
    | undefined;
  controlPersonDisclosure: ControlPersonDisclosure | null | undefined;
  politicalExposureDisclosure: PoliticalExposureDisclosure | null | undefined;
};

type FinancialInformationConfirmModalProps = {
  occupationDropdownOptions:
    | NonNullable<
        FinancialInformationDueDiligenceFragment['dueDiligence']
      >['occupationDropdownOptions']
    | null
    | undefined;
  showModal: boolean;
  setShowModal: (arg0: boolean) => void;
  initialValues: FinancialSuitabilityInfoFormValues;
};

const FinancialInformationConfirmModal = ({
  occupationDropdownOptions,
  showModal,
  setShowModal,
  initialValues,
}: FinancialInformationConfirmModalProps) => {
  const dispatch = useDispatch();
  // TODO: switch this out once mutation is updated to AC
  const { refetch } = useGetFinancialInformationProfileQuery();
  const handleSave = (formData: Record<string, any>) => {
    const {
      annualIncomeAmount,
      employmentStatus,
      employer,
      occupation,
      totalNetWorth,
      liquidNetWorth,
      isSubjectToBackupWithholding,
      exchangeOrFinraAffiliationDisclosure,
      controlPersonDisclosure,
      politicalExposureDisclosure,
    } = formData;
    const backupWithholdingData = {
      isSubjectToBackupWithholding,
    };

    dispatch(
      updateProfile({
        onSuccess: () => {
          refetch();
          setShowModal(false);
        },
        profile: {
          primary: {
            employment: {
              status: employmentStatus,
              employer,
              occupation,
            },
            exchangeOrFinraAffiliationDisclosure,
            controlPersonDisclosure,
            politicalExposureDisclosure,
            backupWithholding: backupWithholdingData,
          },
          suitability: {
            annualIncomeAmount,
            totalNetWorth,
            liquidNetWorth,
          },
        },
      }),
    );
  };

  return (
    <Modal open={showModal} onClose={setShowModal} scroll>
      <ModalContent width="wide">
        <FinancialSuitabilityForm
          occupationOptions={occupationDropdownOptions}
          defaultValues={initialValues}
          onSubmit={handleSave}
        />
      </ModalContent>
    </Modal>
  );
};

export const FinancialInformationConfirm = ({
  analyticsEvent,
  onboarding,
  profile,
}: FinancialInformationConfirmProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const { analytics } = React.useContext(AppContext);

  const initialValues = {
    annualIncomeAmount: profile.suitability?.annualIncomeAmount,
    totalNetWorth: profile.suitability?.totalNetWorth,
    liquidNetWorth: profile.suitability?.liquidNetWorth,
    employmentStatus: profile.primary?.employmentInfo?.status,
    employer: profile.primary?.employmentInfo?.employer,
    occupation: profile.primary?.employmentInfo?.occupation,
    isSubjectToBackupWithholding:
      profile.primary?.backupWithholding?.isSubjectToBackupWithholding,
    exchangeOrFinraAffiliationDisclosure:
      profile?.primary?.exchangeOrFinraAffiliationDisclosure,
    controlPersonDisclosure: profile?.primary?.controlPersonDisclosure,
    politicalExposureDisclosure: profile?.primary?.politicalDisclosure,
  };

  const occupationDropdownOptions =
    onboarding.dueDiligence?.occupationDropdownOptions;

  return (
    <>
      <IdentityFirstConfirmationScreenFinancialSection
        onClickEdit={() => {
          if (analyticsEvent) {
            analytics.recordEvent(analyticsEvent, null, {
              section_clicked: 'financial',
            });
          }

          setShowModal(true);
        }}
        profile={profile}
      />
      <FinancialInformationConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        initialValues={initialValues}
        occupationDropdownOptions={occupationDropdownOptions}
      />
    </>
  );
};
