import { ApolloError } from '@apollo/client';
import { Button, Card, Flex, HM, HS, PS } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { LabelWithValue } from '~/components/label-with-value';
import { TransferRequirementsTimingDescriptionCell } from '~/components/transfer-requirements';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { WidthConstrainerWithArrow } from '~/components/WidthConstrainerWithArrow';
import { useWizardContext } from '~/flows/wizard';
import type { PaymentFormValues } from '~/forms/CreatePaymentForm/CreatePaymentForm';
import {
  useCreateTransferInstanceMutation,
  usePaymentValidationStepQuery,
} from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts/useToast';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';
import { isNil, isNotNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

import { CreatePaymentContext } from '../CreatePaymentContext';

export const PaymentValidationStep = () => {
  const { goTo } = useWizardContext();
  const { addToast } = useToast();

  const [createTransferInstance, { loading: isSubmitting }] =
    useCreateTransferInstanceMutation();

  const handleBack = React.useCallback(() => {
    goTo('PAYMENT_DETAILS');
  }, [goTo]);

  const paymentContext = React.useContext(CreatePaymentContext);

  const {
    otherAmount,
    destinationId,
    sourceId,
    selectedPreset,
  }: PaymentFormValues = useSelector(
    (state) => state.reactHookForm.createPayment ?? {},
  );
  const [amount, displayedAmount] = React.useMemo(() => {
    if (isNotNil(selectedPreset) && selectedPreset !== 'FIXED_AMOUNT') {
      return [parseFloat(selectedPreset), selectedPreset];
    }
    if (isNotNil(otherAmount)) {
      return [otherAmount ?? 0, formatCurrency(otherAmount ?? 0)];
    }
    return [0, '$--'];
  }, [otherAmount, selectedPreset]);

  const hasInitialized = isNotNil(destinationId) && isNotNil(sourceId);

  const { data, loading } = usePaymentValidationStepQuery({
    variables: {
      fromParticipantId: sourceId as string,
      toParticipantId: destinationId as string,
      transferType: 'TRANSFER_INSTANCE',
      amount,
    },
    skip: !hasInitialized,
  });

  const handleConfirm = () => {
    const idempotencyKey = paymentContext?.idempotencyKey;
    if (isNil(idempotencyKey)) {
      return new ApolloError({ errorMessage: 'Failed to generate ID' });
    }
    return createTransferInstance({
      variables: {
        input: {
          amount,
          fromParticipantId: sourceId as string,
          toParticipantId: destinationId as string,
          idempotencyKey,
          isLiquidation: false,
        },
      },
      onCompleted: (result) => {
        const nodeId = result?.createTransferInstance?.outcome?.instance?.id;
        paymentContext?.setCompletedPaymentNodeId(nodeId ?? null);
        goTo('PAYMENT_RECEIPT');
      },
      onError: (error) => {
        addToast({
          content: error.message,
          kind: 'alert',
          duration: 'short',
        });
        goTo('PAYMENT_DETAILS');
      },
    });
  };

  if (loading === true || !hasInitialized) {
    return <Spinner fullScreen />;
  }

  if (isNil(data) && hasInitialized) {
    return <GenericSystemError />;
  }

  const requirements = data?.viewer.transfers?.requirements;

  return (
    <>
      <HS>Confirm one-time payment</HS>
      <Card mt={32} p={32}>
        <LabelWithValue
          label="Amount"
          value={<HM content={displayedAmount} />}
        />
        {isNotNil(requirements?.summaryMessage) && (
          <PS
            color="foregroundNeutralSecondary"
            content={requirements.summaryMessage}
            pt={16}
            pb={8}
          />
        )}
        <Divider />

        <Grid.Row
          style={{
            marginTop: 16,
          }}
        >
          <Grid.Col xs={6}>
            <LabelWithValue
              label="From"
              value={
                isNotNil(data?.source) &&
                'transferParticipantName' in data.source ? (
                  <WidthConstrainerWithArrow>
                    <TransferParticipantCell
                      transferParticipant={data.source}
                    />
                  </WidthConstrainerWithArrow>
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <LabelWithValue
              label="To"
              value={
                isNotNil(data?.destination) &&
                'transferParticipantName' in data.destination ? (
                  <TransferParticipantCell
                    transferParticipant={data.destination}
                  />
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          {isNotNil(requirements) && (
            <TransferRequirementsTimingDescriptionCell
              dependsOnHolds={requirements?.dependsOnHolds}
              dependsOnSells={requirements?.dependsOnSells}
              timingDescription={requirements?.timingDescription}
            />
          )}
        </Grid.Row>
        <Flex justifyContent="space-evenly" flexDirection="row" mt={32}>
          <Flex width={203}>
            <Button
              kind="secondary"
              onClick={handleBack}
              size="large"
              fullWidth
            >
              Back
            </Button>
          </Flex>
          <Flex width={203}>
            <Button
              kind="primary"
              onClick={handleConfirm}
              disabled={isSubmitting}
              size="large"
              fullWidth
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};
