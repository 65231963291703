import { Button, type ButtonProps } from '@m1/liquid-react';

import { type Enhancer, mapProps } from '~/hocs';
import { EXTERNAL_LINKS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { stringifyQuery } from '~/utils';

type ExternalLinkButtonProps = ButtonProps & {
  destination: keyof typeof EXTERNAL_LINKS;
  query?: Record<string, any>;
};

// @ts-expect-error - TS2322 - Type 'InferableComponentEnhancerWithProps<{ as: any; href: string; target: string; label: ReactNode; name?: string | undefined; id?: string | null | undefined; margin?: CssLength | undefined; marginBottom?: CssLength | undefined; ... 26 more ...; autoFocus?: boolean | undefined; }, ExternalLinkButtonProps>' is not assignable to type 'Enhancer<Pick<MarginStyles & { m?: CssLength | undefined; marginX?: CssLength | undefined; marginY?: CssLength | undefined; mb?: CssLength | undefined; ... 4 more ...; my?: CssLength | undefined; } & { ...; }, "label" | ... 17 more ... | "data-testid"> & Partial<...> & Partial<...>, ExternalLinkButtonProps>'.
const enhancer: Enhancer<ButtonProps, ExternalLinkButtonProps> = mapProps(
  ({ destination, query, ...rest }: ExternalLinkButtonProps) => ({
    ...rest,
    as: Link,
    to: query
      ? `${EXTERNAL_LINKS[destination]}?${stringifyQuery(query)}`
      : EXTERNAL_LINKS[destination],
    target: '_blank',
  }),
);

export const ExternalLinkButton = enhancer(Button) as any;
