import * as React from 'react';

import { useCreateFundingSourceRelationshipMutation } from '~/graphql/hooks';
import type { FundingSourceNodeFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';

import { AvailableFundingSourceCard } from './AvailableFundingSourceCard';

type AvailableFundingSourceProps = {
  fundingSource: FundingSourceNodeFragment;
};

export const StandardAvailableFundingSource = ({
  fundingSource,
}: AvailableFundingSourceProps) => {
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [createFundingSourceRelationship, { loading }] =
    useCreateFundingSourceRelationshipMutation();

  usePortaledSpinner(loading);

  const handleStandardAvailableFundingSource = async (
    fundingSourceId: string,
  ): Promise<void> => {
    await createFundingSourceRelationship({
      variables: {
        input: {
          fundingSourceId,
        },
      },
      onCompleted: () => {
        addToast({
          content: 'Success! Your bank connection was changed.',
          duration: 'short',
          kind: 'success',
        });
        navigate({ to: '/d/home' });
      },
      onError: (err) => {
        addToast({
          content: err.message,
          duration: 'short',
          kind: 'alert',
        });
        navigate({ to: '/d/home' });
      },
    });
  };

  return (
    <AvailableFundingSourceCard
      handleActiveFundingSource={handleStandardAvailableFundingSource}
      fundingSource={fundingSource}
    />
  );
};
