import * as React from 'react';

import { useGetTransferTimingQuery } from '~/graphql/hooks';
import { isNil } from '~/utils';

import { TransferDetailsMessageCard } from './TransferDetailsMessageCard';

export const PaymentDetailsMessageCard = ({
  isSchedule,
  sourceId,
  destinationId,
  amount,
}: {
  isSchedule: boolean;
  sourceId: Maybe<string>;
  destinationId: Maybe<string>;
  amount: Maybe<number>;
}) => {
  const { data, previousData, loading } = useGetTransferTimingQuery({
    variables: {
      amount: amount ?? 0,
      transferType: isSchedule
        ? 'SCHEDULED_TRANSFER_RULE'
        : 'TRANSFER_INSTANCE',
      fromParticipantId: sourceId,
      toParticipantId: destinationId,
    },
    // Transfer timing for credit card and personal loan payments
    // is driven by the destination participant.
    skip: isNil(destinationId),
  });

  /*
   * The query re-runs on form change, basically each keystroke.
   * We can continue to show the previous value while the new one is loading.
   * Once we're done loading, don't fall back so we aren't showing stale data.
   */
  const dataSource = data ?? (loading ? previousData : null);
  const transferDetailsMessage =
    dataSource?.viewer.transfers?.requirements?.detailsMessage;

  if (!transferDetailsMessage) {
    return null;
  }

  return (
    <TransferDetailsMessageCard
      mt={16}
      backgroundColor="backgroundNeutralTertiary"
      borderColor="transparent"
      detailsMessage={transferDetailsMessage}
    />
  );
};
