import { Button, LS, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { withProps } from '~/hocs';

import { ActionCard, type ActionCardProps } from '../action-card';

import style from './style.module.scss';

type Props = ActionCardProps & {
  icon?: string | React.ReactNode;
  popup?: React.ReactNode;
  subtitle?: string;
  subtitlePopup?: React.ReactNode;
  title: React.ReactNode;
  // use title for plain text so hover doesn't show `[Object object]`
  titleContent?: React.ReactNode;
  // use titleContent for JSX
  titlePopup?: React.ReactNode;
};

const enhancer = withProps((props: Props) => ({
  children: (
    <>
      <div className={style.contentContainer}>
        <div className={style.linkContainer}>
          {typeof props.icon === 'string' ? (
            <img src={props.icon} width={32} />
          ) : (
            props.icon
          )}
          <div
            style={{
              marginLeft: props.icon ? 16 : undefined,
            }}
          >
            <Button
              kind="link"
              label={props.titleContent ?? props.title}
              style={{
                fontSize: '18px',
                lineHeight: '24px',
                letterSpacing: -0.25,
                fontWeight: 600,
              }}
            />
            {props.popup}
            {typeof props.subtitle === 'string' && props.subtitle ? (
              <Flex alignItems="end">
                <LS
                  as="h4"
                  color="foregroundNeutralMain"
                  content={props.subtitle}
                  cursor="inherit"
                />
                {props.subtitlePopup}
              </Flex>
            ) : null}
          </div>
        </div>
      </div>
      <Icon name="caretRight16" />
    </>
  ),
}));

export const LinkableCard = enhancer(ActionCard) as any;
