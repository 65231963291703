import {
  Button,
  HXS,
  PL,
  Flex,
  Box,
  ModalContent,
  Modal,
} from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useBorrowMarginCallDialogQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { updateHasUserDismissedMarginCallDialog } from '~/redux/actions';
import { Pill } from '~/toolbox/Pill';

type BorrowMarginCallDialogProps = {
  previousRouteName?: string;
};

export const BorrowMarginCallDialog = ({
  previousRouteName = '/d/invest',
}: BorrowMarginCallDialogProps) => {
  const { data, loading, error } = useBorrowMarginCallDialogQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const hasUserDismissedMarginCallDialog = useSelector<boolean>(
    (state: any) => state.borrowMargin.hasUserDismissedMarginCallDialog,
  );

  React.useEffect(() => {
    if (data?.viewer?.borrow?.hasBorrowAccountWithOpenMaintenanceCall) {
      setIsModalOpen(true);
    }
    return () => setIsModalOpen(false);
  }, [data?.viewer.borrow, isModalOpen]);

  if (!data || !data.viewer.borrow || error || loading) {
    return null;
  }

  const borrow = data.viewer.borrow;

  const borrowAccountId = borrow?.borrowAccounts?.edges?.find(
    (edge) => edge?.node?.status?.hasOpenMaintenanceCall,
  )?.node?.status?.borrowAccount?.id;

  const handleOnConfirm = () => {
    setIsModalOpen(false);
    dispatch(updateHasUserDismissedMarginCallDialog(true));

    // If we don't have a borrowAccountId then we can't begin the resolve call flow,
    // so I think its reasonable to just close the modal and not navigate.
    if (borrowAccountId) {
      navigate({
        to: '/d/c/resolve-call',
        query: {
          borrowAccountId,
          from: '/d/invest',
          previousRouteName,
        },
      });
    }
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    dispatch(updateHasUserDismissedMarginCallDialog(true));
  };

  return (
    <Modal
      onClose={handleCloseModal}
      open={!hasUserDismissedMarginCallDialog && isModalOpen}
    >
      <ModalContent>
        <Flex
          backgroundColor="backgroundFeatureFlat"
          borderRadius="8px 8px 0 0"
          flexDirection="column"
          height={224}
          pt={16}
          px={24}
        >
          <Box>
            <Pill kind="danger" label="Important" size="announcement" />
          </Box>
          <Flex justifyContent="center" mt={34}>
            <Illustration
              // @ts-expect-error - TS2322 - Type '{ alt: string; name: "marginCall"; width: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
              alt="Margin call"
              name="marginCall"
              width={150}
            />
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          borderRadius="0 0 8px 8px"
          flexDirection="column"
          pb={32}
          px={32}
        >
          <HXS content="Open margin call" mt={32} textAlign="center" />
          <PL
            content="A margin call has been issued on your account. Please resolve this call by paying back the required amount, or securities will be sold on your behalf to make up the difference."
            mt={16}
            textAlign="center"
          />
          <Button
            kind="primary"
            label="Learn More"
            mt={32}
            onClick={handleOnConfirm}
            size="large"
          />
        </Flex>
      </ModalContent>
    </Modal>
  );
};
