import { Flex, HM, HXXS, LM, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import { usePendingLiquidationPageQuery } from '~/graphql/hooks';
import type { PendingLiquidationUnmanagedHoldingFragment } from '~/graphql/types';
import { useParams } from '~/hooks/useParams';
import { Spinner } from '~/toolbox/spinner';
import { isNil, toArray } from '~/utils';

import { UnmarkUnmanagedHoldingButton } from './UnmarkUnmanagedHoldingButton';

export const PendingLiquidationPage = () => {
  const params = useParams();
  return (
    <PageErrorBoundary>
      {/* TODO -> id can be null */}
      <PendingLiquidationPageContents
        unmanagedHoldingId={params.id as string}
      />
    </PageErrorBoundary>
  );
};

const PendingLiquidationPageContents = ({
  unmanagedHoldingId,
}: {
  unmanagedHoldingId: string;
}) => {
  const { data, loading } = usePendingLiquidationPageQuery({
    variables: {
      unmanagedHoldingId,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.node) {
    return <GenericSystemError />;
  }

  const unmanagedHolding =
    data.node as PendingLiquidationUnmanagedHoldingFragment;

  if (
    isNil(unmanagedHolding.liquidationPendingDetailsScreen) ||
    isNil(unmanagedHolding.liquidationPendingDetailsScreen?.primaryButtonLabel)
  ) {
    return <GenericSystemError />;
  }

  const { details, header, primaryButtonLabel } =
    unmanagedHolding.liquidationPendingDetailsScreen;

  const filteredDetails = toArray(details).filter(Boolean);

  return (
    <Flex flexDirection="column" mx="auto" maxWidth={550}>
      <BackArrow
        content="Holdings not in Pies"
        my={32}
        to="/d/invest/unmanaged-holdings"
      />

      <Card px={64} py={32}>
        <HM>{header}</HM>

        {filteredDetails.map((detail, index) => (
          <Flex flexDirection="column" key={index} my={32}>
            <LM>{detail?.label}</LM>
            <HXXS pt={8}>{detail?.value}</HXXS>
          </Flex>
        ))}

        <UnmarkUnmanagedHoldingButton
          unmanagedHolding={unmanagedHolding}
          primaryButtonLabel={primaryButtonLabel}
        />
      </Card>
    </Flex>
  );
};
