import { Box, Button, HXXS } from '@m1/liquid-react';
import React from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { PaymentDetailsMessageCard } from '~/components/TransferDetailsMessageCard/PaymentDetailsMessageCard';
import { TransferParticipantFields } from '~/components/transfers/TransferParticipantFields/TransferParticipantFields';
import type {
  LinkableFragment,
  PaymentWizardPersonalLoanEdgeFragment,
  ScheduledTransferPresetOption,
  TransferAmountPresetOption,
  TransferParticipantGroupsFragment,
  TransferParticipantSideEnum,
} from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useParticipantSummary } from '~/pages/dashboard/covers/create-transfer/utils/useParticipantSummary';
import { useToast } from '~/toasts/useToast';
import { isNotNil } from '~/utils';

import { AutoPaymentDate } from './AmountPaymentDate';
import { AutoPayButton } from './AutoPayButton';
import { PaymentAmountField } from './PaymentAmountField';
import { PaymentPresets } from './PaymentPresets';

export type PaymentFormValues = {
  otherAmount: number | undefined;
  sourceId: string | undefined;
  destinationId: string | undefined;
  selectedPreset: string | undefined;
};

type CreatePaymentFormProps = {
  amountPills: Maybe<{ label: string }>[] | null | undefined;
  autoPayDueDateMessage: Maybe<string>;
  autoPayInformationContent: Maybe<string>;
  initialValues: PaymentFormValues;
  isPersonalLoanPayment: boolean;
  isPersonalLoansAutoPayEnabled: boolean;
  creditAutoPayInstanceId: Maybe<string>;
  isSchedule: boolean;
  loans: Maybe<Maybe<PaymentWizardPersonalLoanEdgeFragment>[]>;
  onSwitchModes: () => void;
  onSubmit: SubmitHandler<PaymentFormValues>;
  participantGroups: Maybe<TransferParticipantGroupsFragment>;
  pivot: TransferParticipantSideEnum;
  transferOverviewLink: Maybe<LinkableFragment>;
  transferInstancePresets:
    | Maybe<TransferAmountPresetOption>[]
    | undefined
    | null;
  scheduledTransferPresets:
    | Maybe<ScheduledTransferPresetOption>[]
    | undefined
    | null;
};

export const CreatePaymentForm = ({
  amountPills,
  autoPayDueDateMessage,
  autoPayInformationContent,
  initialValues,
  isPersonalLoanPayment,
  isPersonalLoansAutoPayEnabled,
  creditAutoPayInstanceId,
  isSchedule,
  loans,
  onSwitchModes,
  onSubmit,
  participantGroups,
  pivot,
  transferInstancePresets,
  transferOverviewLink,
  scheduledTransferPresets,
}: CreatePaymentFormProps) => {
  const { addToast } = useToast();

  const formMethods = useForm<PaymentFormValues>({
    defaultValues: initialValues,
  });

  const [sourceId, destinationId, otherAmount] = formMethods.watch([
    'sourceId',
    'destinationId',
    'otherAmount',
  ]);

  const participantSummary = useParticipantSummary({
    sourceId,
    destinationId,
    isSchedule,
    pivot,
    participantGroups,
  });

  const selectedLoan = loans?.find((loan) => loan?.node?.id === destinationId);
  const personalLoanAutoPayInstance = selectedLoan?.node?.autoPayInstance;
  const isOneTimePersonalLoanPayment = !isSchedule && isPersonalLoanPayment;

  const showCreditCardAutoPayTermsLink =
    isSchedule &&
    isNotNil(transferOverviewLink) &&
    isNotNil(creditAutoPayInstanceId);

  const handleSubmit = React.useCallback(
    (formValues: PaymentFormValues) => {
      const isEligible = participantSummary.isSelectedRelationshipEligible;

      /*
       * This check prevents users from submitting the form with an invalid
       * account pair via query parameter manipulation.
       */
      if (!isEligible) {
        addToast({
          content: 'Invalid account pair selected. Please try again.',
          kind: 'alert',
        });
        return;
      }

      onSubmit(formValues);
    },
    [onSubmit, participantSummary.isSelectedRelationshipEligible, addToast],
  );

  return (
    <FormProvider {...formMethods}>
      <form
        name="create-payment"
        onSubmit={formMethods.handleSubmit(handleSubmit)}
      >
        <HXXS color="foregroundNeutralMain" content="Accounts" />
        <TransferParticipantFields
          participantSummary={participantSummary}
          isDestinationDisabled
        />
        <HXXS color="foregroundNeutralMain" content="Amount" mt={24} />
        {isOneTimePersonalLoanPayment ? (
          <PaymentAmountField
            mb={24}
            amountPills={amountPills}
            selectedRelationship={participantSummary.selectedRelationship}
            isRequired
          />
        ) : (
          <PaymentPresets
            amountPills={amountPills}
            isSchedule={isSchedule}
            transferInstancePresets={transferInstancePresets}
            scheduledTransferPresets={scheduledTransferPresets}
            selectedRelationship={participantSummary.selectedRelationship}
          />
        )}
        <PaymentDetailsMessageCard
          amount={Number(otherAmount ?? '0')}
          isSchedule={isSchedule}
          sourceId={sourceId}
          destinationId={destinationId}
        />
        {isSchedule && (
          <AutoPaymentDate
            autoPayDueDateMessage={autoPayDueDateMessage}
            autoPayInformationContent={autoPayInformationContent}
            onSwitchModes={onSwitchModes}
          />
        )}
        {showCreditCardAutoPayTermsLink && (
          <LinkableLink linkable={transferOverviewLink} my={32} />
        )}
        <AutoPayButton
          isSchedule={isSchedule}
          isPersonalLoanPayment={isPersonalLoanPayment}
          isPersonalLoansAutoPayEnabled={isPersonalLoansAutoPayEnabled}
          creditAutoPayInstanceId={creditAutoPayInstanceId}
          personalLoanAutoPayInstanceId={personalLoanAutoPayInstance?.id}
          onSwitchModes={onSwitchModes}
        />
        <Box textAlign="center">
          <Button type="submit">Review</Button>
        </Box>
      </form>
    </FormProvider>
  );
};
