import type { InvestAccountOpeningQuestionType } from '~/graphql/types';
import {
  type INVEST_ONBOARDING_FLOW_STEP_VALUES,
  INVEST_ONBOARDING_FLOW_STEPS as STEPS,
} from '~/static-constants';

export const mapSuitabilityToQuestion = (
  suit: string,
): InvestAccountOpeningQuestionType => {
  switch (suit) {
    case 'suitability.investmentExperience':
      return 'INVESTMENT_EXPERIENCE';
    case 'suitability.riskTolerance':
      return 'RISK_TOLERANCE';
    case 'suitability.timeHorizon':
      return 'INVESTMENT_DURATION';
    case 'suitability.liquidityNeeds':
      return 'LIQUIDITY_IMPORTANCE';
    case 'suitability.trustedContact':
      return 'TRUSTED_CONTACT';
    case 'suitability.introductionSource':
      return 'HOW_DID_YOU_HEAR_ABOUT_M1';
    default:
      return 'INVESTMENT_EXPERIENCE';
  }
};

export const mapQuestionToFlowStep = (
  questionType: InvestAccountOpeningQuestionType,
): INVEST_ONBOARDING_FLOW_STEP_VALUES => {
  // TODO: update this when we implement subsequent invest account flows as part of IDF phase 3.
  // const disclosureStep = isPrimary
  //   ? STEPS.COLLECT_PRIMARY_DISCLOSURES
  //   : STEPS.COLLECT_SECONDARY_DISCLOSURES;

  switch (questionType) {
    case 'INVESTMENT_EXPERIENCE':
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
    case 'LIQUIDITY_IMPORTANCE':
      return STEPS.COLLECT_LIQUIDITY_NEEDS;
    case 'HOW_DID_YOU_HEAR_ABOUT_M1':
      return STEPS.COLLECT_INTRODUCTION_SOURCE;
    case 'INVESTMENT_DURATION':
      return STEPS.COLLECT_TIME_HORIZON;
    case 'RISK_TOLERANCE':
      return STEPS.COLLECT_RISK_TOLERANCE;
    case 'TRUSTED_CONTACT':
      return STEPS.COLLECT_TRUSTED_CONTACT;
    default:
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
  }
};

export const primaryQuestionMap = (
  questionType: InvestAccountOpeningQuestionType | null,
): INVEST_ONBOARDING_FLOW_STEP_VALUES => {
  switch (questionType) {
    case 'INVESTMENT_EXPERIENCE':
    case 'RISK_TOLERANCE':
    case 'INVESTMENT_DURATION':
    case 'LIQUIDITY_IMPORTANCE':
    case 'TRUSTED_CONTACT':
    case 'HOW_DID_YOU_HEAR_ABOUT_M1':
    default:
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
  }
};
