import * as React from 'react';

import { TransferParticipantIcon } from '~/components/transfers/TransferParticipantIcon';
import type { TransferInstanceStepCellFragment } from '~/graphql/types';
import { Cell } from '~/toolbox/cell';

type TransferInstanceStepCellProps = {
  transferInstanceStep: TransferInstanceStepCellFragment;
};

export const TransferInstanceStepCell = ({
  transferInstanceStep,
}: TransferInstanceStepCellProps) => {
  const { to, title, status } = transferInstanceStep;

  if (!to) {
    return null;
  }

  return (
    <Cell
      icon={<TransferParticipantIcon transferParticipant={to} />}
      title={title}
      titleWeight={600}
      subtitle={status.description}
    />
  );
};
