import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import type { PieLabelEnum } from '~/graphql/types';

import { createNewPie } from './createNewPieSaga';

export function* createPieFromEditModel(
  input: string | Record<string, any>,
  label?: PieLabelEnum,
  isCrypto?: boolean | null | undefined,
  skipTradeEstimation?: boolean | undefined,
): SagaIterator<string> {
  const editModel = typeof input === 'string' ? JSON.parse(input) : input;
  return yield call(
    createNewPie,
    editModel.name,
    null,
    editModel.slices,
    label,
    isCrypto,
    skipTradeEstimation,
  );
}
