import * as React from 'react';

import { useAutoPayEnrollmentQuery } from '~/graphql/hooks';
import type { AutoPayApplicationSetUpInput } from '~/graphql/types';
import { setPersonalLoanAutoPayAccount } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { AutoPayEnrollmentScreen } from './AutoPayEnrollmentScreen';

export interface AutoPayEnrollmentProps {
  onFinishStep: (
    payload?: Partial<AutoPayApplicationSetUpInput> & {
      skipOnError?: boolean;
    },
  ) => void;
}

export const AutoPayEnrollment: React.FC<AutoPayEnrollmentProps> = ({
  onFinishStep,
}) => {
  const dispatch = useDispatch();

  // Note that this will be undefined after an HMR reload
  const { loanId, transferParticipantId: disbursementAccountId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const { data, loading } = useAutoPayEnrollmentQuery({
    variables: {
      loanId,
    },
  });

  if (loading) {
    return <Spinner fullScreen centered />;
  }
  const { title, subtitle, autoPayAccountList, autoPayInformationalCard } =
    data?.viewer?.borrow?.personalLoans?.disbursementFlow?.autoPaySetup ?? {};
  if (
    !title ||
    !subtitle ||
    !autoPayAccountList ||
    autoPayAccountList.length === 0 ||
    !autoPayInformationalCard
  ) {
    onFinishStep({ skipOnError: true });
    return null;
  }

  const accounts = autoPayAccountList
    .filter(isNotNil)
    .map(({ id: value, transferParticipantName: label }) => ({
      label,
      value,
    }));

  // We want to set the initialAccount to the disbursementAccountId
  // if it's in the list of accounts we got back from lens
  const initialAccount = accounts.find(
    ({ value }) => value === disbursementAccountId,
  )?.value;

  return (
    <AutoPayEnrollmentScreen
      {...{
        title,
        subtitle,
        accounts,
        initialAccount,
        autoPayInformationalCard,
        onSubmit: ({ transferParticipantId }) => {
          const transferParticipantName =
            autoPayAccountList?.find(
              (account) => account?.id === transferParticipantId,
            )?.transferParticipantName ?? '';

          dispatch(setPersonalLoanAutoPayAccount(transferParticipantName));
          onFinishStep({
            loanId,
            transferParticipantId,
          });
        },
        onSkip: () => {
          onFinishStep();
        },
      }}
    />
  );
};
