import { Flex, styled, theme } from '@m1/liquid-react';
import * as React from 'react';

import type { SharePieHeaderPropsFragment } from '~/graphql/types';
import { AppButton } from '~/lens-toolbox/AppButton';
import { AppImage } from '~/lens-toolbox/AppImage';

interface SharePieHeaderProps extends SharePieHeaderPropsFragment {
  handleCTAClick: () => void;
}

const HeaderContainer = styled(Flex)`
  background-color: ${theme.colors.blue05};
  border-radius: 36px;
  margin: 0 32px;
  max-width: calc(1200px - 64px);
  padding: 16px 24px;
  position: fixed;
  top: 24px;
  width: calc(100vw - 64px);
  z-index: 300;

  @media (max-width: ${theme.breakpoints.XSMALL}) {
    & > :first-child {
      flex-grow: 1;
    }
  }
`;

const TextContainer = styled.div`
  color: ${theme.colors.foregroundNeutralInverse};
  flex-grow: 1;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin: 0 40px;

  @media (max-width: ${theme.breakpoints.XSMALL}) {
    display: none;
  }
`;

const CTAButton = styled(AppButton)`
  border-radius: 20px;
`;

export const SharePieHeader = ({
  icon,
  text,
  ctaButton,
  handleCTAClick,
}: SharePieHeaderProps) => {
  return (
    <HeaderContainer alignItems="center" justifyContent="center">
      {icon && <AppImage appImage={icon} />}
      <TextContainer>{text}</TextContainer>
      {ctaButton && (
        <CTAButton
          appButton={ctaButton}
          size="small"
          onClick={handleCTAClick}
        />
      )}
    </HeaderContainer>
  );
};
