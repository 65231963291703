import React, { type CSSProperties } from 'react';

import type { Control, FieldValues } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import type { SelectProps } from '~/toolbox/Dropdown';

import type { DropdownOptionGroup } from './TransferParticipantFields.utils';
import { TransferParticipantGroupHeading } from './TransferParticipantGroupHeading';
import { TransferParticipantOption } from './TransferParticipantOption';
import { TransferParticipantPlaceholder } from './TransferParticipantPlaceholder';
import { TransferParticipantSelectContainer } from './TransferParticipantSelectContainer';

export const TransferParticipantField = ({
  control,
  controlStyle,
  optionsList,
  style,
  ...rest
}: {
  control: Control<FieldValues>;
  controlStyle?: CSSProperties;
  optionsList: DropdownOptionGroup[];
  style?: CSSProperties;
} & SelectProps) => {
  return (
    <ControlledDropdown
      components={{
        GroupHeading: TransferParticipantGroupHeading,
        Option: TransferParticipantOption,
        Placeholder: TransferParticipantPlaceholder,
        SelectContainer: TransferParticipantSelectContainer,
      }}
      control={control}
      isSearchable={false}
      placeholder="Select"
      source={optionsList}
      style={{
        ...participantDropdownStyles,
        control: {
          ...participantDropdownStyles.control,
          ...controlStyle,
        },
        ...style,
      }}
      rules={{ required: 'An account must be selected.' }}
      {...rest}
    />
  );
};

const participantDropdownStyles = {
  control: {
    borderRadius: '8px 8px 0 0px',
    maxHeight: 'auto',
    minHeight: '72px',
    padding: '0 4px 0 0',
  },
  groupHeading: {
    borderTop: 'none',
    borderBottom: 'none',
    marginBottom: '8px',
    marginTop: '16px',
    padding: '0 24px',
    textTransform: 'inherit',
  },
  margin: 0,
  menu: {
    boxShadow:
      '0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 15px 40px 0px rgba(0, 0, 0, 0.20);',
  },
  pointerEvents: 'inherit',
};
