import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { DiscoverSearch } from '~/components/research';

export const TopNavResearch = () => {
  return (
    <Flex alignItems="center" height="60px">
      <DiscoverSearch
        filterStatuses={['ACTIVE']}
        filterTypes={[
          'EQUITY_SECURITY',
          'FUND_SECURITY',
          'SYSTEM_PIE',
          'USER_PIE',
        ]}
      />
    </Flex>
  );
};
