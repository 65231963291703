import { type InputProps, Button } from '@m1/liquid-react';
import * as React from 'react';
import {
  Controller,
  type ControllerProps,
  type FieldValues,
  type Path,
} from 'react-hook-form';

import { TransferFrequencyEnum } from '../../steps/TransferDetails.types';

import { StyledTransferRadioContainer } from './TransferScheduleOptions.styled';
import { FREQUENCY_TO_LABEL } from './TransferScheduleOptions.utils';

type TransferScheduleRadioGroupProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  InputProps & {
    onFrequencyChange: (freq: TransferFrequencyEnum) => void;
    options: TransferFrequencyEnum[];
  };

export const TransferScheduleRadioGroup = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  disabled,
  onFrequencyChange,
  options,
}: TransferScheduleRadioGroupProps<TName, TFieldValues>) => {
  return (
    <Controller
      {...{
        control,
        name,
        rules,
      }}
      render={({ field }) => (
        <>
          {options.map((f) => (
            <StyledTransferRadioContainer key={f}>
              <Button
                kind={field.value === f ? 'inverse-primary' : 'text'}
                disabled={disabled}
                onClick={() => {
                  if (disabled !== true) {
                    field.onChange(f);
                    onFrequencyChange?.(f);
                  }
                }}
                size="medium"
              >
                {FREQUENCY_TO_LABEL[f]}
              </Button>
            </StyledTransferRadioContainer>
          ))}
        </>
      )}
    />
  );
};
