import React from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import {
  useIdentityInfoFormQuery,
  usePersonalLoansDirectQuery,
} from '~/graphql/hooks';
import type { PersonalLoanDirectQuestion } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import type { AppState } from '~/redux';
import { submitReactFormData } from '~/redux/actions';
import { Spinner } from '~/toolbox/spinner';

import { EmploymentStatusAndInfoForm } from '../components/EmploymentStatusAndInfoForm';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export interface EmploymentInfoFormValues {
  employment: {
    status: string;
    employer?: string;
    occupation?: string;
  };
}

export const EmploymentStatusAndInfo = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { goTo } = usePersonalLoanDirectWizardContext();
  const { data, loading } = usePersonalLoansDirectQuery();
  const { data: identityData, loading: identityLoading } =
    useIdentityInfoFormQuery();

  const occupationOptions =
    identityData?.viewer?.onboarding?.dueDiligence?.occupationDropdownOptions;
  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  let props: Maybe<Partial<PersonalLoanDirectQuestion>> | undefined;

  if (questions) {
    props = getQuestionProps({
      question: 'EMPLOYMENT_STATUS_AND_INFO',
      questions,
    });
  }

  const { title, continueLabel, nextQuestionType } = props || {};

  const persistedFormValues = useSelector((state: AppState) => {
    return state.reactHookForm['directToPersonalLoansEmploymentInfo'];
  });

  const methods = useForm<EmploymentInfoFormValues>({
    defaultValues: persistedFormValues,
  });

  const onSubmit: SubmitHandler<EmploymentInfoFormValues> = async (
    formData: any,
  ) => {
    dispatch(
      submitReactFormData({
        directToPersonalLoansEmploymentInfo: formData,
      }),
    );
    goTo(nextQuestionType ?? 'AVAILABLE_CASH');
  };

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/employment');
  }, [analytics]);

  if (loading || identityLoading) {
    return <Spinner fullScreen centered />;
  }

  if (!occupationOptions) {
    return <GenericSystemError />;
  }

  return (
    <div>
      <StyledHeader content={title ?? 'Tell us about your work.'} mb={16} />
      <FormProvider {...methods}>
        <div>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <EmploymentStatusAndInfoForm
              occupationOptions={occupationOptions}
            />
            <ResponsiveButton
              label={continueLabel ?? 'Continue'}
              type="submit"
              size="large"
              mt={48}
            />
          </form>
        </div>
      </FormProvider>
    </div>
  );
};
