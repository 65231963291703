import type {
  InvestAccountOpeningQuestion,
  ProfileInput,
} from '~/graphql/types';
import type { INVEST_ONBOARDING_FLOW_STEP_VALUES } from '~/static-constants';

export const INVEST_ONBOARDING_ACTIONS = Object.freeze({
  BEGIN_INVEST_ACCOUNT_SETUP: 'BEGIN_INVEST_ACCOUNT_SETUP' as const,
  CHANGE_INVEST_ACCOUNT_SETUP_FLOW_STEP:
    'CHANGE_INVEST_ACCOUNT_SETUP_FLOW_STEP' as const,
  CLICKED_CANCEL_INVEST_ACCOUNT_SETUP:
    'CLICKED_CANCEL_INVEST_ACCOUNT_SETUP' as const,
  CLICKED_INVEST_CANCEL_ACCOUNT_SETUP:
    'CLICKED_INVEST_CANCEL_ACCOUNT_SETUP' as const,
  COLLECTED_INVEST_TRUSTED_CONTACT: 'COLLECTED_INVEST_TRUSTED_CONTACT' as const,
  INVEST_ACCOUNT_SETUP_FLOW_FINISHED:
    'INVEST_ACCOUNT_SETUP_FLOW_FINISHED' as const,
  INVEST_ACCOUNT_SETUP_FLOW_CANCELED:
    'INVEST_ACCOUNT_SETUP_FLOW_CANCELED' as const,
  SET_INVEST_SUITABILITY_ONBOARDING_QUESTIONS:
    'SET_INVEST_SUITABILITY_ONBOARDING_QUESTIONS' as const,
  SUBMITTED_INVEST_PROFILE_INPUT: 'SUBMITTED_INVEST_PROFILE_INPUT' as const,
  SKIPPED_INVEST_TRUSTED_CONTACT: 'SKIPPED_INVEST_TRUSTED_CONTACT' as const,
  SUBMITTED_INVEST_INTRODUCTION_SOURCE:
    'SUBMITTED_INVEST_INTRODUCTION_SOURCE' as const,
  SKIPPED_INVEST_INTRODUCTION_SOURCE:
    'SKIPPED_INVEST_INTRODUCTION_SOURCE' as const,
  FINISHED_INVEST_ACCOUNT_SETUP_REVIEW:
    'FINISHED_INVEST_ACCOUNT_SETUP_REVIEW' as const,
  SET_FULLY_PAID_LENDING_STATUS: 'SET_FULLY_PAID_LENDING_STATUS' as const,
});

export type BeginInvestAccountSetupFlow = {
  type: typeof INVEST_ONBOARDING_ACTIONS.BEGIN_INVEST_ACCOUNT_SETUP;
  payload: {
    basePath: string;
    mode: 'RETIREMENT' | null; // TODO: update this to include all possible modes
    onFinish: () => void;
  };
};

export type ChangeInvestAccountSetupStep = {
  type: typeof INVEST_ONBOARDING_ACTIONS.CHANGE_INVEST_ACCOUNT_SETUP_FLOW_STEP;
  payload: INVEST_ONBOARDING_FLOW_STEP_VALUES;
};

export type SetInvestSuitabilityOnboardingQuestionsAction = {
  payload: Array<InvestAccountOpeningQuestion>;
  type: typeof INVEST_ONBOARDING_ACTIONS.SET_INVEST_SUITABILITY_ONBOARDING_QUESTIONS;
};

export type SubmittedInvestProfileInput = {
  payload: {
    field: string;
    value: string;
  };
  type: typeof INVEST_ONBOARDING_ACTIONS.SUBMITTED_INVEST_PROFILE_INPUT;
};

export type InvestAccountSetupFlowFinished = {
  payload: string | null;
  type: typeof INVEST_ONBOARDING_ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_FINISHED;
};

export type InvestAccountSetupFlowCanceled = {
  type: typeof INVEST_ONBOARDING_ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_CANCELED;
};

export type CollectedInvestTrustedContact = {
  payload: Partial<ProfileInput>;
  type: typeof INVEST_ONBOARDING_ACTIONS.COLLECTED_INVEST_TRUSTED_CONTACT;
};

export type SkippedInvestTrustedContact = {
  type: typeof INVEST_ONBOARDING_ACTIONS.SKIPPED_INVEST_TRUSTED_CONTACT;
};

export type SubmittedInvestIntroductionSource = {
  payload: string;
  type: typeof INVEST_ONBOARDING_ACTIONS.SUBMITTED_INVEST_INTRODUCTION_SOURCE;
};

export type ClickedCancelInvestAccountSetup = {
  type: typeof INVEST_ONBOARDING_ACTIONS.CLICKED_CANCEL_INVEST_ACCOUNT_SETUP;
};

export type FinishedInvestAccountSetupReview = {
  payload: {
    isCustodialAccount?: boolean;
    signature: string;
    fplStatus?: Maybe<boolean>;
  };
  type: typeof INVEST_ONBOARDING_ACTIONS.FINISHED_INVEST_ACCOUNT_SETUP_REVIEW;
};

export type SetFullyPaidLendingStatusAction = {
  payload: boolean;
  type: typeof INVEST_ONBOARDING_ACTIONS.SET_FULLY_PAID_LENDING_STATUS;
};

export type InvestOnboardingAction =
  | BeginInvestAccountSetupFlow
  | ChangeInvestAccountSetupStep
  | SetInvestSuitabilityOnboardingQuestionsAction
  | SubmittedInvestProfileInput
  | InvestAccountSetupFlowFinished
  | InvestAccountSetupFlowCanceled
  | CollectedInvestTrustedContact
  | SkippedInvestTrustedContact
  | SubmittedInvestIntroductionSource
  | ClickedCancelInvestAccountSetup
  | FinishedInvestAccountSetupReview;
