import { Box } from '@m1/liquid-react';
import React from 'react';

import type { AppInformationalMessageCardFragment } from '~/graphql/types';

import { AppInformationalMessageCard } from '~/lens-toolbox/AppInformationalMessageCard';

export const PaymentAdditionalInfoBanner = ({
  infoCard,
}: {
  infoCard: AppInformationalMessageCardFragment;
}) => {
  return (
    <Box mt={24}>
      <AppInformationalMessageCard appInformationalMessageCard={infoCard} />
    </Box>
  );
};
