import { Box, Button, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { useUnmanagedHoldingsSectionControlsQuery } from '~/graphql/hooks';
import type { CollectionFragment } from '~/graphql/types';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { UnmanagedHoldingsMoveDrawer } from './UnmanagedHoldingsMoveDrawer';

export type UnmanagedHoldingsSectionControlProps = {
  disabled: boolean;
  holdingsCollection: CollectionFragment;
  selectedCheckboxes: Array<string>;
  toggleLiquidationModal: () => void;
};

export const UnmanagedHoldingsSectionControls = ({
  disabled,
  holdingsCollection,
  selectedCheckboxes,
  toggleLiquidationModal,
}: UnmanagedHoldingsSectionControlProps) => {
  const accountId = useSelector((state) => state.global.activeAccountId);
  const { data } = useUnmanagedHoldingsSectionControlsQuery({
    variables: {
      id: accountId as string,
    },
    skip: !accountId,
  });

  const { type } = holdingsCollection;

  if (data?.node?.__typename !== 'Account' || type === 'DO_NOTHING') {
    return null;
  }

  const receivedSecurities = data.node.receivedSecurities;

  const contactUs = receivedSecurities?.unmanagedHoldingsScreen?.contactUs;
  const renderLiquidate = type === 'LIQUIDATE' || type === 'UPDATE';
  const renderMove = type === 'MOVE' || type === 'UPDATE';
  const renderContactUs = type === 'CONTACT_OPS';

  return (
    <ButtonGroup>
      {renderLiquidate && (
        <Button
          disabled={disabled}
          kind="secondary"
          label="Liquidate"
          onClick={toggleLiquidationModal}
        />
      )}
      {renderMove && receivedSecurities && (
        <Box>
          <Tooltip
            placement="bottom-end"
            trigger="click"
            children={
              <Button disabled={disabled} kind="secondary" label="Move" />
            }
            contentPadding="0px"
            body={
              <UnmanagedHoldingsMoveDrawer
                receivedSecurities={receivedSecurities}
                selectedCheckboxes={selectedCheckboxes}
              />
            }
          />
        </Box>
      )}
      {renderContactUs && contactUs && (
        <LinkableButton kind="secondary" linkable={contactUs} size="medium" />
      )}
    </ButtonGroup>
  );
};
