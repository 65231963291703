import pace from 'pace';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  getContext,
  put,
  select,
  spawn,
  take,
} from 'redux-saga/effects';

import { AuthManager } from '~/auth';
import {
  ReauthenticateDocument,
  type ReauthenticateMutationResult,
} from '~/graphql/hooks';
import type { ReauthenticateMutationVariables } from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import { SentryReporter } from '~/loggers';
import { ACTION_TYPES, determinedReferral } from '~/redux/actions';
import { isNotNil } from '~/utils';

import { apolloMutationSaga } from '../apolloMutationSaga';
import { bootstrapAuthedUser } from '../bootstrapAuthedUser';
import {
  getSentryReporter,
  hasUserOnboarded,
  refreshTokenTimeout,
} from '../common';
import { initializeCrossDomainStorage } from '../crossDomainStorageSaga';
import { logMarketingDeepLinkClickSaga } from '../flows/utils';
import { isSystemBlocked, pollSystemStatus } from '../systemStatus';

import { cacheLocationInput } from './cache-location-input';
import { determineReferral } from './determine-referral';

export function* appInitWatcher(): SagaIterator {
  const [sentry, navigate]: [SentryReporter, NavigateFunction] = yield all([
    call(getSentryReporter),
    select((state) => state.routing.navigate),
    take(ACTION_TYPES.INITIALIZE_APP),
  ]);

  try {
    const auth: AuthManager = yield getContext('auth');
    const {
      tokens: { resetPassword, verifyEmail },
    } = yield call(cacheLocationInput, window.location);

    yield all([
      spawn(logMarketingDeepLinkClickSaga),
      spawn(initializeCrossDomainStorage, window.location),
      spawn(function* () {
        // @ts-expect-error - TS7057 - 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
        const referralCodes = yield call(determineReferral, window.location);
        yield put(determinedReferral(referralCodes));
      }),
    ]);

    let hasOnboarded = true;
    const refreshToken = auth.get('refreshToken');
    if (isNotNil(refreshToken)) {
      const { data }: ReauthenticateMutationResult = yield call(
        apolloMutationSaga,
        {
          mutation: ReauthenticateDocument,
          variables: {
            input: {
              refreshToken,
            },
          } satisfies ReauthenticateMutationVariables,
        },
      );
      auth.updateRequiredAuth(data?.reauthenticate?.outcome);
      yield call(bootstrapAuthedUser);
      yield fork(refreshTokenTimeout);

      hasOnboarded = yield call(hasUserOnboarded);
    }

    // Named routes aren't loaded here yet. These all work because the path
    // just happens to start with the same name as the route.
    if (yield call(isSystemBlocked)) {
      yield call(navigate, { to: '/maintenance' });
    } else if (verifyEmail) {
      yield call(navigate, { to: `/verify-email/${verifyEmail}` });
    } else if (resetPassword) {
      yield call(navigate, { to: `/reset-password/${resetPassword}` });
    } else if (!hasOnboarded) {
      yield call(navigate, { to: '/onboarding/phone-verification' });
    }

    // Initialize pace bar for remote requests
    pace.start();

    // Start SystemInfo background polling.
    yield spawn(pollSystemStatus);

    // All done.
    yield put({
      type: ACTION_TYPES.APP_INITIALIZED,
    });
  } catch (e: any) {
    console.error(e); // eslint-disable-line

    sentry.exception(e);
    yield put({
      type: ACTION_TYPES.APP_INIT_FAILURE,
      payload: e,
    });
  }
}
