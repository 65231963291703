import type { PortfolioOrganizerSagaFetchSliceablesQueryResult } from '~/graphql/hooks';
import { mapRemoteSliceableByType, type Slice } from '~/pie-trees';

/**
 * Maps the response data from FetchSliceablesQuery to an array of Slices
 * @param {*} response - The response to map
 * @returns - The mapped array of slices
 */
export const mapFetchSliceablesResponseToSlices = (
  response: PortfolioOrganizerSagaFetchSliceablesQueryResult['data'],
): Array<Slice> => {
  const nodes = [];

  const responseNodes = response?.nodes;

  if (responseNodes) {
    for (const node of responseNodes) {
      // handle sliceables
      if (
        node &&
        (node.__typename === 'UserPie' ||
          node.__typename === 'SystemPie' ||
          node.__typename === 'Fund' ||
          node.__typename === 'Equity' ||
          node.__typename === 'CryptoAsset') &&
        node.id &&
        node.name &&
        node.type
      ) {
        nodes.push({
          ...node,
          __typename: node.__typename,
          id:
            node.__typename === 'CryptoAsset' && node.legacyId
              ? node.legacyId // TODO - confirm if lens will keep this
              : node.id,
          name: node.name,
          type: node.type,
          slices: 'slices' in node ? node.slices : null,
        });
      }

      // handle unmanaged holdings
      if (node?.__typename === 'UnmanagedHolding' && node.security) {
        nodes.push({
          ...node.security,
          __typename: node.__typename,
          highlight: true,
          id: node.security.id,
          name: node.security.name,
          type: node.security.type,
        });
      }
    }
  }

  return nodes.reduce<any>((slices, sliceable) => {
    if (sliceable) {
      slices.push({
        percentage: 1,
        to: mapRemoteSliceableByType(sliceable as any),
      });
    }

    return slices;
  }, []);
};
