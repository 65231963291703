import { createContext } from 'react';

export type TopNavigationContextType = {
  leftScrimRef: React.RefObject<HTMLSpanElement> | null;
  rightScrimRef: React.RefObject<HTMLSpanElement> | null;
};

export const TopNavigationContext = createContext<TopNavigationContextType>({
  leftScrimRef: null,
  rightScrimRef: null,
});
