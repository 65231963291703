import { Box, Button, Flex, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useCreatePaymentShowReceiptQuery } from '~/graphql/hooks';
import { type CreatePaymentShowReceiptQuery } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Spinner } from '~/toolbox/spinner';
import { isNil, isNotNil } from '~/utils';

import { PaymentAdditionalInfoBanner } from '../components/PaymentAdditionalInfoBanner';
import { PaymentPendingCard } from '../components/PaymentPendingCard';
import { PaymentViewDetailsButton } from '../components/PaymentViewDetailsButton';
import { CreatePaymentContext } from '../CreatePaymentContext';

export const PaymentReceiptStep = () => {
  const navigate = useNavigate();

  const paymentContext = React.useContext(CreatePaymentContext);
  const nodeId = paymentContext?.completedPaymentNodeId;

  const { data, loading } = useCreatePaymentShowReceiptQuery({
    variables: {
      id: nodeId as string,
    },
    skip: isNil(nodeId),
  });

  const node: ExtractNodeTypename<
    CreatePaymentShowReceiptQuery,
    'TransferInstance' | 'ScheduledTransferRule'
  > | null = data?.node && 'to' in data.node ? data.node : null;

  const scheduleDescription = React.useMemo((): string | null | undefined => {
    if (!node) {
      return null;
    }
    if (node.__typename !== 'ScheduledTransferRule' || !node.schedule) {
      return null;
    }
    switch (node.schedule.__typename) {
      case 'MonthlySchedule':
        return 'monthly';
      case 'WeekOfMonthSchedule':
        return 'monthly on a week';
      case 'BiweeklySchedule':
        return 'biweekly';
      case 'WeeklySchedule':
        return 'weekly';
      default:
        return null;
    }
  }, [node]);

  const content = React.useMemo((): [
    Maybe<string>,
    Maybe<React.JSX.Element>,
  ] => {
    if (!node) {
      return [null, null];
    }
    const toName = node.to ? node.to.transferParticipantName : null;

    switch (node.__typename) {
      case 'TransferInstance':
        return [
          `Payment created`,
          <React.Fragment key={toName}>
            Your payment{' '}
            {toName ? (
              <>
                to <b>{toName}</b>
              </>
            ) : null}{' '}
            has been created!
          </React.Fragment>,
        ];
      case 'ScheduledTransferRule': {
        const description = scheduleDescription;
        const isAutoPay =
          node.isCreditCardAutoPay || node.isPersonalLoanAutoPay;
        return isAutoPay
          ? [
              'AutoPay enabled',
              <React.Fragment key={toName}>
                AutoPay{' '}
                {toName && (
                  <>
                    to your <b>{toName}</b>
                  </>
                )}{' '}
                has been enabled!
              </React.Fragment>,
            ]
          : [
              'Schedule created',
              <React.Fragment key={toName}>
                Your {description && <b>{description}</b>} schedule{' '}
                {toName && (
                  <>
                    to <b>{toName}</b>
                  </>
                )}{' '}
                has been created!
              </React.Fragment>,
            ];
      }

      default:
        throw new Error(
          'Invalid node type in ShowPaymentReceiptStep.readContent.',
        );
    }
  }, [node, scheduleDescription]);

  const [title, body] = content;

  const isOneTimePayment = node?.__typename === 'TransferInstance';

  if (!node) {
    return null;
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  return (
    <Flex alignItems="center" flexDirection="column">
      <HXS content={title} mt={16} mb={32} />
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Illustration
          name="transferSuccess"
          style={{ objectFit: 'contain', marginBottom: 16 }}
        />
        <Box width={380}>
          <PL content={body} mt={16} />
          {isOneTimePayment && (
            <PaymentPendingCard
              amount={node.amount}
              timingDescription={node.timingDescription}
            />
          )}
          {isOneTimePayment && isNotNil(node.additionalInfoCard) && (
            <PaymentAdditionalInfoBanner infoCard={node.additionalInfoCard} />
          )}
        </Box>
      </Flex>
      <ButtonGroup mt={256}>
        <PaymentViewDetailsButton
          isAutoPay={!isOneTimePayment}
          paymentId={node?.id}
        />
        <Button
          kind="primary"
          onClick={() => {
            navigate({
              to: paymentContext?.previousRouteName ?? '/d/transfers',
            });
          }}
          size="large"
        >
          Done
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
