import { Button, PM } from '@m1/liquid-react';
import * as React from 'react';

import type { ContactUsCard } from '~/graphql/types';

type CardButtonProps = {
  card: ContactUsCard;
};

export const CardButton = ({ card }: CardButtonProps) => {
  if (!card.buttonLabel) {
    return null;
  }

  if (card.identifier === 'INSTANT_CHAT' && window.Intercom === undefined) {
    return (
      <PM content="Please ensure your browser's content blockers are disabled to chat with us." />
    );
  }

  return (
    <Button
      kind="secondary"
      label={card.buttonLabel}
      onClick={() => {
        if (card.buttonDestinationUrl) {
          window.open(card.buttonDestinationUrl, '_blank');
        }

        if (
          card.identifier === 'INSTANT_CHAT' &&
          window.Intercom !== undefined
        ) {
          window.Intercom('show');
        }
      }}
    />
  );
};
