import { Box, Flex, PM } from '@m1/liquid-react';
import * as React from 'react';

import { DisclosureLink } from '~/components/DisclosureLink/DisclosureLink';
import { Link } from '~/toolbox/link';

const EXTERNAL_BENEFICIARY_FORM = 'https://arsl.at/Gw4Pj38m';
const EXTERNAL_IRA_BENEFICIARY_FORM = 'https://arsl.at/mX0q8gVm';

const BeneficiariesFormLink = ({
  content = 'M1 Beneficiary Form',
  to = EXTERNAL_BENEFICIARY_FORM,
}: {
  content?: string;
  to?: string;
}) => (
  <Link font="PM" to={to}>
    {content}
  </Link>
);

const BulletedList = ({ items }: { items: string[] }) => (
  <ul style={{ marginTop: -16, marginLeft: 16 }}>
    {items.map((item) => (
      <li key={item}>
        <PM>{item}</PM>
      </li>
    ))}
  </ul>
);

export const BeneficiaryDisclosures = () => (
  <Flex flexDirection="column" gap={16}>
    <PM>
      Beneficiary(ies) designees may be elected for the following products:
    </PM>
    <BulletedList
      items={[
        'Retirement accounts¹: Roth IRA, Traditional IRA and SEP IRA',
        'Non-retirement accounts²: Individual Brokerage, Joint Brokerage and High-Yield Cash Account',
        'Banking³: High-Yield Savings Account',
      ]}
    />
    <PM>
      Beneficiary(ies) designees are not permitted for the following products:
    </PM>
    <BulletedList
      items={[
        'Crypto Accounts (powered by Bakkt Crypto)',
        'Trust Accounts',
        'Custodial Accounts',
      ]}
    />
    <PM>
      The name(s) of your designated Beneficiary(ies) must exactly match their
      legal name, and you must provide such Beneficiary(ies) SSN/TIN or EIN.
      These Beneficiary designations will be the default for all your accounts
      on M1 and B2 Bank, NA, and apply to all current and future accounts at the
      time of your election.
    </PM>
    <PM>
      <Box as="span" fontWeight={600}>
        IRA Account:
      </Box>{' '}
      If you would like to add a different beneficiary than the ones designated
      in-app to an IRA account, please use{' '}
      <BeneficiariesFormLink
        to={EXTERNAL_IRA_BENEFICIARY_FORM}
        content="this form"
      />
      .
    </PM>
    <PM>
      <Box as="span" fontWeight={600}>
        Multiple Accounts & Different Beneficiary(ies):
      </Box>{' '}
      If you have multiple accounts, and you would like to designate a different
      Beneficiary(ies) for each account, please use this{' '}
      <BeneficiariesFormLink />.
    </PM>
    <PM>
      <Box as="span" fontWeight={600}>
        Trust as Beneficiary:
      </Box>{' '}
      If you would like to designate a Trust as a Beneficiary, please use this{' '}
      <BeneficiariesFormLink />.
    </PM>
    <PM>
      <Box as="span" fontWeight={600}>
        Changing your Beneficiary(ies):
      </Box>{' '}
      you may change your Beneficiary designation at any time and as often as
      you wish. Any change to your Beneficiary(ies) will automatically void your
      previously elected Beneficiary(ies), whether in-app or via this{' '}
      <BeneficiariesFormLink />. Any designation or change of beneficiary is not
      effective until we receive and accept it, in our sole discretion.
    </PM>
    <PM>
      Designating or changing your Beneficiary(ies) has important consequences.
      Please consult with your attorney or tax advisor before making an
      election.
    </PM>
    <DisclosureLink
      linkText="¹𝄒²𝄒³Additional disclosures"
      paragraphs={[
        '¹Beneficiary may be elected for retirement accounts. Named beneficiaries of an IRA will inherit the IRA upon the death of the original IRA account holder.',
        '²Transfer on Death (“TOD”): may be elected for any non-retirement account. Transfer-on-death allows assets in your taxable Invest account(s) to be passed to named beneficiaries without going through probate. Not available for residents of Louisiana.',
        '³Pay on Death (“POD”): may be elected for banking products. Pay-on-death allows assets in your High-Yield Savings Account to be passed to the named beneficiaries without going through probate. Named beneficiaries listed for a High-Yield Savings Account will inherit the funds upon the death of the original account holder. Products offered by B2 Bank, N.A. Not available for residents of Louisiana.',
        'Important Information: For the purposes of this designation page only, all subsequent references to “Beneficiary(ies)” shall mean either Beneficiary, TOD and/or POD, respective of the account (e.g., product) you are designating a benefactor.',
        <React.Fragment key="beneficiary-disclosure-link">
          <Box as="span">
            Important Information: Beneficiaries designated via the{' '}
          </Box>
          <BeneficiariesFormLink />
          <Box as="span">
            {' '}
            will not display in-app. Please reach out to support if you'd like a
            copy of this form.
          </Box>
        </React.Fragment>,
        'For Louisiana residents only: any designated TOD or POD on your account(s) will be void.',
      ]}
    />
  </Flex>
);
