import { type AppAction, ACTION_TYPES } from '../actions';

import type { NewFlowsState } from './newFlows';

export type GlobalLoadState = {
  done: boolean;
  error: boolean;
  started: boolean;
};

export type GlobalState = {
  // Deprecated. Use investAccountId instead.
  activeAccountId: string | null | undefined;
  activeAccountIsCrypto: boolean;
  activeFlow: keyof NewFlowsState | null;
  anonymousUserId: string | null;
  borrowAccountId: string | null | undefined;
  checkingAccountId: string | null | undefined;
  creditAccountId: string | null | undefined;
  cryptoAccountId: string | null | undefined;
  investAccountId: string | null | undefined;
  savingsAccountId: string | null | undefined;
  isM1Employee: boolean;
  loadState: GlobalLoadState;
  personalLoanAccountId: string | null | undefined;
  profileCreated: boolean | null | undefined;
  environment: EnvironmentOption | null;
  hasLoadedGmapsLibrary: boolean;
  userHasOnboarded: boolean | null;
  hasProduct: boolean | null;
  netWorthNodeId: string | null | undefined;
};

export const initialState: GlobalState = {
  // activeAccountId can be an invest or crypto account
  activeAccountId: null,
  activeAccountIsCrypto: false,
  anonymousUserId: null,
  borrowAccountId: null,
  checkingAccountId: null,
  creditAccountId: null,
  cryptoAccountId: null,
  investAccountId: null,
  savingsAccountId: null,
  isM1Employee: false,
  activeFlow: null,
  loadState: {
    started: false,
    error: false,
    done: false,
  },
  personalLoanAccountId: null,
  profileCreated: null,
  environment: null,
  hasLoadedGmapsLibrary: false,
  userHasOnboarded: null,
  hasProduct: null,
  netWorthNodeId: null,
};

export const globalReducer = (
  state: GlobalState = initialState,
  action: AppAction,
): GlobalState => {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE_APP:
      return Object.assign({}, state, {
        loadState: Object.assign({}, state.loadState, {
          started: true,
        }),
      });
    case ACTION_TYPES.APP_INIT_FAILURE:
      return Object.assign({}, state, {
        loadState: Object.assign({}, state.loadState, {
          error: true,
        }),
      });
    case ACTION_TYPES.APP_INITIALIZED:
      return Object.assign({}, state, {
        loadState: Object.assign({}, state.loadState, {
          done: true,
        }),
      });
    case ACTION_TYPES.SET_ACTIVE_BORROW_ACCOUNT:
      return {
        ...state,
        borrowAccountId: action.payload,
      };
    case 'SET_ACTIVE_INVEST_ACCOUNT':
      return {
        ...state,
        activeAccountId: action.payload,
        investAccountId: action.payload,
        activeAccountIsCrypto: false,
      };
    case 'SET_ACTIVE_CRYPTO_ACCOUNT':
      return {
        ...state,
        activeAccountId: action.payload,
        cryptoAccountId: action.payload,
        activeAccountIsCrypto: true,
      };
    case ACTION_TYPES.SET_ACTIVE_CHECKING_ACCOUNT:
      return {
        ...state,
        checkingAccountId: action.payload,
      };
    case ACTION_TYPES.SET_ACTIVE_CREDIT_ACCOUNT:
      return {
        ...state,
        creditAccountId: action.payload,
      };
    case ACTION_TYPES.SET_ACTIVE_SAVINGS_ACCOUNT:
      return {
        ...state,
        savingsAccountId: action.payload,
      };
    case 'SELECTED_INITIAL_ACCOUNTS': {
      let activeAccountId = action.payload.investAccountId;
      let activeAccountIsCrypto = false;
      if (!activeAccountId) {
        activeAccountId = action.payload.cryptoAccountId;
        activeAccountIsCrypto = Boolean(activeAccountId);
      }
      return {
        ...state,
        activeAccountId,
        ...action.payload,
        activeAccountIsCrypto,
      };
    }
    case ACTION_TYPES.SET_ACTIVE_PERSONAL_LOAN_ACCOUNT:
      return {
        ...state,
        personalLoanAccountId: action.payload,
      };
    case ACTION_TYPES.SET_PROFILE_CREATED:
      return {
        ...state,
        profileCreated: action.payload,
      };
    case 'SET_HAS_PRODUCT':
      return {
        ...state,
        hasProduct: action.payload,
      };
    case ACTION_TYPES.SET_USER_HAS_ONBOARDED:
      return {
        ...state,
        userHasOnboarded: action.payload,
      };
    case ACTION_TYPES.START_FLOW:
      return Object.assign(
        {
          ...state,
        },
        {
          activeFlow: action.payload.flow,
        },
      );
    case ACTION_TYPES.BEGIN_FLOW:
      return {
        ...state,
        activeFlow: action.meta.flow,
      };
    case 'SET_IS_M1_EMPLOYEE':
      return {
        ...state,
        isM1Employee: action.payload,
      };
    case 'SET_ENVIRONMENT':
      return {
        ...state,
        environment: action.payload,
      };
    case 'SET_HAS_LOADED_GMAPS_LIBRARY':
      return {
        ...state,
        hasLoadedGmapsLibrary: true,
      };
    case ACTION_TYPES.SET_ANONYMOUS_USER_ID:
      return {
        ...state,
        anonymousUserId: action.payload,
      };
    default:
      return state;
  }
};
