import { Box, PL, TabularDataSet } from '@m1/liquid-react';
import * as React from 'react';

import type { TransferRuleScheduleFragment } from '~/graphql/types';

import { formatFrequencyTabularData } from './ScheduleDetails.utils';

export const ScheduleDetails = ({
  isAutoPay,
  schedule,
  mt,
}: {
  isAutoPay: boolean;
  schedule: TransferRuleScheduleFragment;
  mt?: Maybe<number | string>;
}) => {
  const tabularData = formatFrequencyTabularData({
    schedule,
    isAutoPay,
  });

  return (
    <Box mt={mt ?? 0}>
      <TabularDataSet
        hasBorder={false}
        tabularData={tabularData.map((item) => {
          return {
            label: item.label,
            data: <PL fontWeight={600}>{item.data}</PL>,
          };
        })}
      />
    </Box>
  );
};
