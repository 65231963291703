import { Box } from '@m1/liquid-react';
import React from 'react';

import { clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { CreateTransferWizard } from '../create-transfer/TransferWizard';

import { EditTransferScheduleProvider } from './EditTransferScheduleProvider';

export const EditTransferSchedulePage = () => {
  const dispatch = useDispatch();

  // Ensure that form fields are cleared whenever the cover-wizard is initially mounted
  React.useEffect(() => {
    dispatch(clearSingleReactHookForm('createTransferDetails'));
  }, [dispatch]);
  return (
    <Box>
      <EditTransferScheduleProvider>
        <CreateTransferWizard />
      </EditTransferScheduleProvider>
    </Box>
  );
};
