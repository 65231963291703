import { type Color, PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import style from './style.module.scss';

export type LegendItemProps = {
  color: Color;
  label: string;
};

const StyledItem = styled.div<{
  $color: Color;
}>`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors[props.$color]};
`;

export const LegendItem = ({ color, label }: LegendItemProps) => (
  <div className={style.item}>
    <StyledItem $color={color} />
    <PM content={label} color="foregroundNeutralTertiary" />
  </div>
);
