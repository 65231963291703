import { Flex } from '@m1/liquid-react';
import React from 'react';

import { AppContext } from '~/AppContext';
import type { AppImageFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { Link } from '~/toolbox/link';

type SecurityWebsiteLinkProps = {
  website: string;
  websiteIcon: AppImageFragment | null | undefined;
  isCrypto: boolean;
};

export const SecurityWebsiteLink = ({
  website,
  websiteIcon,
  isCrypto,
}: SecurityWebsiteLinkProps) => {
  const { analytics } = React.useContext(AppContext);
  return (
    <Flex>
      {websiteIcon && <AppImage appImage={websiteIcon} height={24} />}
      <Link
        to={website}
        mb={21}
        ml={4}
        onClick={() => {
          analytics.recordAppAnalyticsEvent({
            name: 'm1_security_details_data_discovered',
            customParameters: [
              {
                name: 'data_id',
                value: 'website_link',
              },
            ],
            customBoolParameters: [],
            customNumberParameters: [],
            valueParameter: null,
          });
        }}
        target="_blank"
      >
        Visit {isCrypto ? '' : 'company '}website
      </Link>
    </Flex>
  );
};
