import { Box, HXXS } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useTransferActivityPageQuery } from '~/graphql/hooks';
import type { TransferActivityPageQuery } from '~/graphql/types';
import { Spinner } from '~/toolbox/spinner';

import { TransfersEmptyStateCard } from './components/TransfersEmptyStateCard';
import { TransfersPastInstancesTable } from './components/TransfersPastInstancesTable';
import { TransfersPendingAcatInstancesTable } from './components/TransfersPendingAcatInstancesTable';
import { TransfersPendingInstancesTable } from './components/TransfersPendingInstancesTable';

export const TransfersActivityPage = () => {
  const { data, loading } = useTransferActivityPageQuery({
    variables: {
      first: 15,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Spinner />;
  }
  if (!data?.viewer) {
    return <GenericSystemError />;
  }
  return <TransfersActivityPageContent transfers={data.viewer.transfers} />;
};

export const TransfersActivityPageContent = ({
  transfers,
}: {
  transfers: TransferActivityPageQuery['viewer']['transfers'];
}) => {
  const hasPendingInstances = Boolean(
    transfers?.pendingInstances && transfers.pendingInstances.length > 0,
  );

  const hasPastInstances = Boolean(
    transfers?.pastInstances?.edges && transfers.pastInstances.edges.length > 0,
  );

  const hasAcatTransfers = Boolean(
    transfers?.acatInstances && transfers.acatInstances.length > 0,
  );

  const hasTransfers =
    hasPendingInstances || hasPastInstances || hasAcatTransfers;

  if (!transfers || !hasTransfers) {
    return (
      <TransfersEmptyStateCard
        content="Pending and completed one-time transfers or transfer instances of rules will appear here."
        title="No activity yet"
      />
    );
  }

  return (
    <Box width="100%">
      {hasAcatTransfers && (
        <Box mb={24}>
          <HXXS
            content="Account transfers"
            fontWeight={300}
            color="foregroundNeutralSecondary"
            mb={16}
          />
          <TransfersPendingAcatInstancesTable
            style={{
              marginTop: 8,
            }}
            transfers={transfers}
          />
        </Box>
      )}
      {hasPendingInstances && (
        <>
          <HXXS
            content="Pending transfers"
            fontWeight={300}
            color="foregroundNeutralSecondary"
            mb={16}
          />
          <TransfersPendingInstancesTable
            pendingInstances={transfers.pendingInstances}
          />
        </>
      )}
      {hasPastInstances && (
        <TransfersPastInstancesTable
          hasPendingInstances={hasPendingInstances}
        />
      )}
    </Box>
  );
};
