/* eslint-disable no-case-declarations */
import type { AppAction } from '~/redux';
import type {
  SubmitReactHookFormAction,
  SupportedReactHookFormTypes,
} from '~/redux/actions';

export type ReactHookFormState =
  | SupportedReactHookFormTypes
  | Record<string, any>;

const getKeyAsTypedValue = (
  payload: SubmitReactHookFormAction['payload'],
): keyof SupportedReactHookFormTypes => {
  const value = Object.keys(payload)[0];
  return value as keyof SupportedReactHookFormTypes;
};

export const initialState: ReactHookFormState = {
  openCashAccountInvestmentExperience: { investmentExperience: null },
  createTransferDetails: {
    amount: 0,
    destinationId: undefined,
    frequency: 'one-time',
    schedule: undefined,
    sourceId: undefined,
    federalWithholding: undefined,
    stateWithholding: undefined,
    iraContributionYear: undefined,
    niaAmount: undefined,
    withholdingType: undefined,
  },
  niaCalculator: {
    amount: null,
    excessToBeRemoved: null,
    adjestOpeningBalance: null,
    adjustedClosingBalance: null,
  },
};

export const createReactHookFormReducer = () => {
  return (state: ReactHookFormState = initialState, action: AppAction) => {
    switch (action.type) {
      case 'SUBMIT_REACT_HOOK_FORM_DATA':
        const key = getKeyAsTypedValue(action.payload);

        return {
          ...state,
          [key]: {
            ...action.payload[key],
          },
        };
      case 'CLEAR_SINGLE_REACT_HOOK_FORM':
        const keyToBeCleared =
          action.payload as keyof SupportedReactHookFormTypes;

        const newState = { ...state };
        delete newState[keyToBeCleared];

        return newState;
      case 'RESET_ALL_REACT_FORM_DATA':
        return initialState;
      default:
        return state;
    }
  };
};
