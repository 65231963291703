import { Button, Flex, PL, styled, theme } from '@m1/liquid-react';
import React, { useEffect } from 'react';
import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form';

import { TransferParticipantDropdown } from '~/components/form/Dropdowns/TransferParticipantDropdown';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import {
  usePersonalLoansDirectQuery,
  useAutoPayApplicationSetUpMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

const ButtonContainer = styled(Flex)`
  margin-top: 40px;

  @media screen and (max-width: ${theme.breakpoints.XXSMALL}) {
    justify-content: center;
    flex-direction: column;

    button {
      max-width: 100%;
    }
  }
`;

export interface TransferParticipantId {
  transferParticipant: string;
}

export const Autopay = () => {
  const { goTo } = usePersonalLoanDirectWizardContext();
  const analytics = useAnalytics();

  const { formattedMonthlyPayment } = useSelector(
    (state) => state.reactHookForm.directToPersonalLoansPreApprovedOffer,
  );

  const { data: personalLoansDirectData, loading: personalLoansDirectLoading } =
    usePersonalLoansDirectQuery();
  const questions =
    personalLoansDirectData?.viewer?.borrow?.personalLoans
      ?.applicationFlowDirect?.questions;
  const formMethods = useForm<TransferParticipantId>();
  const { control, setValue } = formMethods;
  const { addToast } = useToast();

  let props;

  if (questions) {
    props = getQuestionProps({
      question: 'AUTOPAY',
      questions,
    });
  }

  const { loanId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const [autoPayApplicationSetUp, { loading }] =
    useAutoPayApplicationSetUpMutation();

  useEffect(() => {
    analytics.pageView('/direct-loan-application/autopay-enrollment');
  }, [analytics]);

  if (personalLoansDirectLoading || loading) {
    return <Spinner fullScreen centered />;
  }

  const { title, nextQuestionType } = props || {};

  const handleSubmit: SubmitHandler<TransferParticipantId> = ({
    transferParticipant,
  }: {
    transferParticipant: string;
  }) => {
    autoPayApplicationSetUp({
      variables: {
        input: {
          loanId,
          transferParticipantId: transferParticipant,
        },
      },
      onCompleted: (data) => {
        if (!data?.autoPayApplicationSetUp?.didSucceed) {
          addToast({
            content:
              'There was an error setting up AutoPay. Please try again once the funds reach your account.',
            kind: 'alert',
            duration: 'short',
          });
        } else {
          goTo(nextQuestionType || 'RECEIPT');
        }
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  const handleSkip = () => {
    goTo(nextQuestionType || 'RECEIPT');
  };

  const monthlyPaymentAmount = <strong>{formattedMonthlyPayment}</strong>;

  return (
    <>
      <StyledHeader content={title} mb={8} />

      <PL mb={24}>
        When you turn on AutoPay, you’ll automatically pay{' '}
        {monthlyPaymentAmount} on the day it’s due each month. No stressing over
        due dates or how much to pay. And you can change these settings anytime.
      </PL>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <TransferParticipantDropdown control={control} setValue={setValue} />

          <ButtonContainer>
            <ResponsiveButton label="Continue" type="submit" />

            <Button
              kind="text"
              size="large"
              label="Skip"
              onClick={handleSkip}
            />
          </ButtonContainer>
        </form>
      </FormProvider>
    </>
  );
};
