import type {
  RecurrenceScheduleInput,
  SendCheckRecipientInput,
} from '~/graphql/types';

export const CREATE_SEND_CHECK_ACTIONS = Object.freeze({
  EDITED_RECURRING_CHECK: 'EDITED_RECURRING_CHECK' as const,
  DELETED_RECURRING_CHECK: 'DELETED_RECURRING_CHECK' as const,
  SET_SEND_CHECK_AMOUNT_DATA: 'SET_SEND_CHECK_AMOUNT_DATA' as const,
  SET_SEND_CHECK_MEMO: 'SET_SEND_CHECK_MEMO' as const,
  SET_SEND_CHECK_RECIPIENT_TYPE: 'SET_SEND_CHECK_RECIPIENT_TYPE' as const,
  SUBMITTED_RECIPIENT_INFO_FORM: 'SUBMITTED_RECIPIENT_INFO_FORM' as const,
  SUBMITTED_SEND_CHECK_REQUEST: 'SUBMITTED_SEND_CHECK_REQUEST' as const,
  SET_SEND_CHECK_RECURRING: 'SET_SEND_CHECK_RECURRING' as const,
  SET_SEND_CHECK_SCHEDULE: 'SET_SEND_CHECK_SCHEDULE' as const,
  SET_CHECK_SCHEDULED_TRANSFER_RULE_ID:
    'SET_CHECK_SCHEDULED_TRANSFER_RULE_ID' as const,
  SET_SEND_CHECK: 'SET_SEND_CHECK' as const,
});

export type EditedReccuringCheckAction = {
  type: typeof CREATE_SEND_CHECK_ACTIONS.EDITED_RECURRING_CHECK;
};

export type DeletedRecurringCheckAction = {
  payload: string;
  type: typeof CREATE_SEND_CHECK_ACTIONS.DELETED_RECURRING_CHECK;
};

export type SetSendCheckAmountAction = {
  payload: string;
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_AMOUNT_DATA;
};

export type SetSendCheckMemoAction = {
  payload: string;
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_MEMO;
};

export type SubmittedRecipientInfoForm = {
  payload: SendCheckRecipientInput;
  type: typeof CREATE_SEND_CHECK_ACTIONS.SUBMITTED_RECIPIENT_INFO_FORM;
};

export const setSendCheckAmountDataAction = (
  payload: SetSendCheckAmountAction['payload'],
): SetSendCheckAmountAction => ({
  payload,
  type: CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_AMOUNT_DATA,
});

export const setSendCheckMemoAction = (
  payload: SetSendCheckMemoAction['payload'],
): SetSendCheckMemoAction => ({
  payload,
  type: CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_MEMO,
});

export const submittedRecipientInfoFormAction = (
  payload: SubmittedRecipientInfoForm['payload'],
): SubmittedRecipientInfoForm => ({
  payload,
  type: CREATE_SEND_CHECK_ACTIONS.SUBMITTED_RECIPIENT_INFO_FORM,
});

export type SetSendCheckRecipientType = {
  payload: 'BUSINESS';
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_RECIPIENT_TYPE;
};

export type SetSendCheckRecurring = {
  payload: boolean;
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_RECURRING;
};

export type SetCheckScheduledTransferRuleId = {
  payload: string | null | undefined;
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_CHECK_SCHEDULED_TRANSFER_RULE_ID;
};

export type SetSendCheckSchedule = {
  payload: RecurrenceScheduleInput | null | undefined;
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK_SCHEDULE;
};

export type SetSendCheck = {
  payload: {
    amount?: string | null | undefined;
    memo?: string | null | undefined;
    recipient?: SendCheckRecipientInput | null | undefined;
    schedule?: RecurrenceScheduleInput | null | undefined;
    scheduledTransferRuleId?: string | null | undefined;
  };
  type: typeof CREATE_SEND_CHECK_ACTIONS.SET_SEND_CHECK;
};

export type CreateSendCheckAction =
  | EditedReccuringCheckAction
  | DeletedRecurringCheckAction
  | SetSendCheckAmountAction
  | SetSendCheckMemoAction
  | SetSendCheckRecipientType
  | SetSendCheckRecurring
  | SetCheckScheduledTransferRuleId
  | SetSendCheckSchedule
  | SetSendCheck
  | SubmittedRecipientInfoForm;
