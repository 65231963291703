import {
  Flex,
  HS,
  HXXS,
  PM,
  Skeleton,
  SkeletonProvider,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { ProgressIndicator } from '~/components/ProgressIndicator/ProgressIndicator';
import { useCreditCardLandingPageGraphDetailsQuery } from '~/graphql/hooks';
import type { CreditCardLandingPageGraphDetailsQuery } from '~/graphql/types';
import { StyledDetailsCardContainer } from '~/pages/dashboard/common/ProductLandingPageCard.styled';

export const CreditLimitCard = ({ accountId }: { accountId: string }) => {
  const { data, loading } = useCreditCardLandingPageGraphDetailsQuery({
    variables: { accountId },
  });

  const node = data?.node as ExtractTypename<
    CreditCardLandingPageGraphDetailsQuery['node'],
    'CreditCardAccount'
  >;

  const graphDetails = node?.creditCardProductLandingPage?.graphDetails;

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <Skeleton skeletonWidth="100%" skeletonHeight={200}>
        {typeof graphDetails?.percentage === 'number' ? (
          <StyledDetailsCardContainer>
            <Card backgroundColor="backgroundNeutralSecondary" padding={24}>
              <HXXS
                content={graphDetails?.title ?? 'Credit Limit'}
                fontWeight={300}
              />
              <Flex
                justifyContent="space-between"
                mt={24}
                mb={4}
                alignItems="end"
              >
                <HS content={graphDetails?.currentBalance} fontWeight={300} />
                <PM content={graphDetails?.creditLimit} fontWeight={400} />
              </Flex>
              <ProgressIndicator percentage={graphDetails?.percentage} />
              <PM
                content={graphDetails?.description}
                fontWeight={400}
                color="foregroundNeutralSecondary"
                mt={8}
              />
            </Card>
          </StyledDetailsCardContainer>
        ) : null}
      </Skeleton>
    </SkeletonProvider>
  );
};
