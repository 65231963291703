import { Skeleton, SkeletonProvider } from '@m1/liquid-react';
import React from 'react';

import { LandingPageValuePropCard } from '~/components/LandingPageValuePropCard/LandingPageValuePropCard';
import { useEarnLandingPageValuePropCardQuery } from '~/graphql/hooks';
import type { EarnLandingPageValuePropCardQuery } from '~/graphql/types';
import { StyledDetailsCardContainer } from '~/pages/dashboard/common/ProductLandingPageCard.styled';

export const EarnValuePropositionCard = ({
  accountId,
}: {
  accountId: string;
}) => {
  const { data, loading } = useEarnLandingPageValuePropCardQuery({
    variables: { id: accountId },
  });

  const node = data?.node as ExtractTypename<
    EarnLandingPageValuePropCardQuery['node'],
    'SavingsAccount'
  >;

  const primaryValue =
    node?.earnProductLandingPage?.valuePropCard?.primaryValue;
  const title = node?.earnProductLandingPage?.valuePropCard?.title;
  const details = node?.earnProductLandingPage?.valuePropCard?.details;
  const illustration =
    node?.earnProductLandingPage?.valuePropCard?.illustration;

  return (
    <SkeletonProvider isLoading={loading}>
      <StyledDetailsCardContainer>
        <Skeleton skeletonWidth="100%" skeletonHeight={270}>
          <LandingPageValuePropCard
            title={title as string}
            primaryValue={primaryValue}
            details={details}
            illustration={illustration}
          />
        </Skeleton>
      </StyledDetailsCardContainer>
    </SkeletonProvider>
  );
};
