import { HXS, PL, Box, Modal } from '@m1/liquid-react';
import * as React from 'react';

import type { AnnouncementFragment } from '~/graphql/types';
import { useAnnouncementInViewRef } from '~/hooks/useAnnouncementInViewRef';
import { AppImage } from '~/lens-toolbox/AppImage';

import { AnnouncementCallToAction } from './AnnouncementCallToAction';
import {
  StyledHeaderBox,
  StyledAnnouncementTag,
} from './StyledAnnouncementModal';

export type AnnouncementModalProps = {
  announcement: AnnouncementFragment;
  onDismiss: () => void;
};

export const AnnouncementModal = ({
  announcement,
  onDismiss,
}: AnnouncementModalProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const ref = useAnnouncementInViewRef(announcement.displayAnalyticsEvent);

  const handleDismiss = () => {
    setIsModalOpen(false);
    onDismiss();
  };

  return (
    <Modal onClose={handleDismiss} onExit={handleDismiss} open={isModalOpen}>
      {announcement.announcementIcon && (
        <StyledHeaderBox>
          {announcement.tag && <StyledAnnouncementTag tag={announcement.tag} />}
          <AppImage appImage={announcement.announcementIcon} />
        </StyledHeaderBox>
      )}
      <Box
        borderRadius="0 0 10px 10px"
        backgroundColor="backgroundNeutralSecondary"
        maxWidth={489}
        p={32}
        textAlign="center"
        ref={ref}
      >
        <HXS color="foregroundNeutralMain" content={announcement.title} />
        {announcement.description && (
          <PL
            color="foregroundNeutralMain"
            content={announcement.description}
            mt={16}
          />
        )}

        <AnnouncementCallToAction
          action={announcement.action}
          link={announcement.appLink}
          onDismiss={onDismiss}
        />
      </Box>
    </Modal>
  );
};
