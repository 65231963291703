import { Box, theme } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { RewardsTableCell } from '~/components/credit-card/RewardsTableCell';
import type { OwnersRewardProgramsFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export type RewardsTableOverviewComponentProps = {
  programType: string;
  rewardsProgram: OwnersRewardProgramsFragment;
};

export const RewardsTableOverview = ({
  programType,
  rewardsProgram,
}: RewardsTableOverviewComponentProps) => {
  const isLessThanXXSMALL: boolean = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.XXSMALL} )`,
  });

  return (
    <Box mb={32}>
      <GridTable
        emptyMessage="No rewards available"
        gridTemplateColumns={isLessThanXXSMALL ? 'auto 65px' : 'auto 110px'}
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Security" />
          <GridTable.HeaderCell align="right" label="Cash back rate" />
        </GridTable.HeaderRow>
        {rewardsProgram.programs?.map((rewardDetails, index) => {
          return (
            <RewardsTableCell
              key={index}
              programType={programType}
              rewardDetails={rewardDetails}
              index={index}
            />
          );
        })}
      </GridTable>
    </Box>
  );
};
