import moment from 'moment';

import type { Beneficiary } from '~/graphql/types';

type LocalBeneficiary = Record<string, any> & {
  __id: number;
  __deleted: boolean;
};

export function convertApiBeneficiaries(
  beneficiaries: Array<Beneficiary>,
): Array<LocalBeneficiary> {
  return beneficiaries.map(createLocalBeneficiary);
}

export function createLocalBeneficiary(
  beneficiary: Beneficiary,
  index: number,
): LocalBeneficiary {
  return {
    __id: index,
    __deleted: false,
    ...beneficiary,
    dateOfBirth: moment(beneficiary.dateOfBirth, 'YYYY-MM-DD', true).format(
      'MM/DD/YYYY',
    ),
  };
}

export function getActiveBeneficiaries(
  beneficiaries: Array<LocalBeneficiary>,
): Array<LocalBeneficiary> {
  return beneficiaries.filter((beneficiary) => !beneficiary.__deleted);
}
