import type {
  OnboardingIdentityFirstQuestionType,
  InvestAccountOpeningQuestionType,
} from '~/graphql/types';
import {
  type IDENTITY_FIRST_FLOW_STEP_VALUES,
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
} from '~/static-constants';

export const mapSuitabilityToQuestion = (
  suit: string,
): OnboardingIdentityFirstQuestionType | InvestAccountOpeningQuestionType => {
  switch (suit) {
    case 'suitability.annualIncomeAmount':
      return 'ANNUAL_INCOME';
    case 'suitability.totalNetWorth':
      return 'NET_WORTH';
    case 'suitability.totalNetWorthAmount':
      return 'NET_WORTH';
    case 'suitability.liquidNetWorth':
      return 'LIQUID_NET_WORTH';
    case 'suitability.liquidNetWorthAmount':
      return 'LIQUID_NET_WORTH';
    case 'suitability.investmentExperience':
      return 'INVESTMENT_EXPERIENCE';
    case 'suitability.riskTolerance':
      return 'RISK_TOLERANCE';
    case 'suitability.timeHorizon':
      return 'INVESTMENT_DURATION';
    case 'suitability.liquidityNeeds':
      return 'LIQUIDITY_IMPORTANCE';
    case 'suitability.trustedContact':
      return 'TRUSTED_CONTACT';
    default:
      return 'ANNUAL_INCOME';
  }
};

export const mapQuestionToFlowStep = (
  questionType:
    | OnboardingIdentityFirstQuestionType
    | InvestAccountOpeningQuestionType,
  isPrimary: boolean,
): IDENTITY_FIRST_FLOW_STEP_VALUES => {
  const disclosureStep = isPrimary
    ? STEPS.COLLECT_PRIMARY_DISCLOSURES
    : STEPS.COLLECT_SECONDARY_DISCLOSURES;
  switch (questionType) {
    case 'POLITICALLY_EXPOSED_PERSON':
      return disclosureStep;
    case 'EMPLOYMENT_STATUS_AND_WHERE_DO_YOU_WORK':
      return STEPS.COLLECT_EMPLOYMENT;
    case 'ANNUAL_INCOME':
      return STEPS.COLLECT_ANNUAL_INCOME;
    case 'NET_WORTH':
      return STEPS.COLLECT_TOTAL_NET_WORTH;
    case 'LIQUID_NET_WORTH':
      return STEPS.COLLECT_LIQUID_NET_WORTH;
    case 'INVESTMENT_EXPERIENCE':
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
    case 'LIQUIDITY_IMPORTANCE':
      return STEPS.COLLECT_LIQUIDITY_NEEDS;
    case 'HOW_DID_YOU_HEAR_ABOUT_M1':
      return STEPS.COLLECT_INTRODUCTION_SOURCE;
    case 'INVESTMENT_DURATION':
      return STEPS.COLLECT_TIME_HORIZON;
    case 'RISK_TOLERANCE':
      return STEPS.COLLECT_RISK_TOLERANCE;
    case 'TRUSTED_CONTACT':
      return STEPS.COLLECT_TRUSTED_CONTACT;
    default:
      return disclosureStep;
  }
};

export const primaryQuestionMap = (
  questionType: OnboardingIdentityFirstQuestionType | null,
): IDENTITY_FIRST_FLOW_STEP_VALUES => {
  switch (questionType) {
    case 'SOCIAL_SECURITY_NUMBER':
      return STEPS.COLLECT_PRIMARY_SSN;
    case 'NAME_AND_ADDRESS':
      return STEPS.COLLECT_PRIMARY_CONTACT_INFO;
    case 'DATE_OF_BIRTH_AND_CITIZENSHIP':
      return STEPS.COLLECT_PRIMARY_IDENTITY_INFO;
    case 'ANNUAL_INCOME':
      return STEPS.COLLECT_ANNUAL_INCOME;
    case 'LIQUID_NET_WORTH':
      return STEPS.COLLECT_LIQUID_NET_WORTH;
    default:
      return STEPS.COLLECT_PRIMARY_CONTACT_INFO;
  }
};

export const secondaryQuestionMap = (
  questionType: OnboardingIdentityFirstQuestionType,
): IDENTITY_FIRST_FLOW_STEP_VALUES => {
  switch (questionType) {
    case 'SOCIAL_SECURITY_NUMBER':
      return STEPS.COLLECT_SECONDARY_SSN;
    case 'NAME_AND_ADDRESS':
      return STEPS.COLLECT_SECONDARY_CONTACT_INFO;
    case 'DATE_OF_BIRTH_AND_CITIZENSHIP':
      return STEPS.COLLECT_SECONDARY_IDENTITY_INFO;
    default:
      return STEPS.COLLECT_SECONDARY_CONTACT_INFO;
  }
};
