import { PL, PS } from '@m1/liquid-react';
import React from 'react';

import type { AnnouncementFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

import { AnnouncementTag } from '../AnnouncementTag';

import {
  StyledIcon,
  StyledInlineAnnouncement,
  StyledTimeDescription,
} from './StyledAnnouncementCard';

export const InlineAnnouncement = ({
  announcement,
}: {
  announcement: AnnouncementFragment;
}) => {
  const {
    title,
    description,
    announcementIcon,
    appLink,
    timeDescription,
    tag,
  } = announcement;

  return (
    <>
      {announcementIcon && (
        <StyledIcon inline>
          <AppImage appImage={announcementIcon} />
        </StyledIcon>
      )}
      <StyledInlineAnnouncement isDescriptionEmpty={!description}>
        {tag && <AnnouncementTag mb={8} tag={tag} />}
        <PL content={title} fontWeight={600} />
        {description && <PS content={description} mt={4} />}
        {(Boolean(timeDescription) || Boolean(appLink)) && (
          <StyledTimeDescription timeDescription={timeDescription}>
            {timeDescription && (
              <PS color="foregroundNeutralMain" content={timeDescription} />
            )}
            {appLink && <LinkableLink font="PS" linkable={appLink} />}
          </StyledTimeDescription>
        )}
      </StyledInlineAnnouncement>
    </>
  );
};
