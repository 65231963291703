import {
  Box,
  Button,
  PL,
  PS,
  Flex,
  styled,
  type Theme,
  type SemanticColorNames,
  css,
} from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { useSubmitUserFeedbackMutation } from '~/graphql/hooks';
import type { UserFeedbackTopicEnum } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { Dropdown } from '~/toolbox/Dropdown';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';
import { Radio } from '~/toolbox/radio';
import { getEnumEntries } from '~/utils';

const StyledTextArea = styled.textarea<{
  backgroundColor?: SemanticColorNames;
  theme: Theme;
}>`
  ${({ theme, backgroundColor }) => {
    return css`
      height: 200px;
      outline: none;
      padding: 12px 16px;
      resize: none;
      background-color: ${backgroundColor
        ? theme.colors[backgroundColor]
        : 'inherit'};
      border: 1.5px solid;
      border-color: ${theme.colors.borderMain};
      border-radius: 8px;
      transition: all 100ms ease-in-out;

      &:hover {
        border-color: ${theme.colors.foregroundNeutralTertiary};
      }

      &:focus {
        border-color: ${theme.colors.primary};
      }
    `;
  }};
`;

type FormValues = {
  contactOptIn?: boolean;
  topicSelected: UserFeedbackTopicEnum;
  topicRating: number;
  topicFeedback?: string;
};

type CounterColor = 'warning' | 'critical' | 'foregroundNeutralMain';

const RATING_VALUES = [1, 2, 3, 4, 5];

export const UserFeedbackForm = () => {
  const { register, handleSubmit, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      contactOptIn: true,
      topicSelected: undefined,
      topicRating: undefined,
      topicFeedback: undefined,
    },
  });

  const options = React.useMemo<Array<{ label: string; value: string }>>(() => {
    const topicEntries = getEnumEntries('UserFeedbackTopicEnum');

    const options = topicEntries.map(
      ({ name, description }: { name: string; description: string }) => {
        return { label: description, value: name };
      },
    );

    // return topic options in alphabetical order
    return options.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }, []);

  const selectedTopicValue = watch('topicSelected');

  const selectedTopicRating = watch('topicRating');

  const inputtedTopicFeedback = watch('topicFeedback');
  const charaCount = inputtedTopicFeedback?.length || (0 as number);

  const selectedOptIn = watch('contactOptIn');

  const topicName = options.find(
    (option) => option.value === selectedTopicValue,
  );

  const [counterColor, setCounterColor] = React.useState<CounterColor>(
    'foregroundNeutralMain',
  );

  React.useEffect(() => {
    if (charaCount >= 450 && charaCount < 500) {
      setCounterColor('warning');
    } else if (charaCount >= 500) {
      setCounterColor('critical');
    } else {
      setCounterColor('foregroundNeutralMain');
    }
  }, [charaCount]);

  const navigate = useNavigate();

  const [submitUserFeedback] = useSubmitUserFeedbackMutation({
    onCompleted: () => {
      addToast({
        content: 'Thank you! Your feedback has been submitted.',
        kind: 'success',
        duration: 'short',
      });
      navigate({ to: '/d/home' });
    },
    onError: () => {
      addToast({
        content:
          'Something went wrong and we were unable to submit your feedback. Please try again.',
        kind: 'alert',
      });
    },
  });

  const { addToast } = useToast();
  const [loading, setIsLoading] = React.useState(false);
  usePortaledSpinner(loading);

  const handleFeedbackSubmit = (input: FormValues) => {
    const { contactOptIn, topicFeedback, topicRating, topicSelected } = input;
    setIsLoading(true);

    setTimeout(async () => {
      await submitUserFeedback({
        variables: {
          input: {
            contactOptIn,
            topicFeedback,
            topicRating,
            topicSelected,
          },
        },
      });

      setIsLoading(false);
    }, 200);
  };

  const handleChange = (
    selection:
      | 'contactOptIn'
      | 'topicFeedback'
      | 'topicRating'
      | 'topicSelected',
    input: string | number | boolean | undefined,
  ) => {
    setValue(selection, input);
  };

  return (
    <form>
      <Grid.Row>
        <Grid.Col xs={12}>
          <Box m={32}>
            <PL>
              Offer feedback on (<b>required</b>):
            </PL>

            <Dropdown
              label="Topic"
              placeholder="Select topic"
              options={options}
              {...register('topicSelected')}
              onChange={(e) => handleChange('topicSelected', e)}
              value={selectedTopicValue}
            />
          </Box>

          {selectedTopicValue && (
            <Box m={32}>
              <PL mb={24}>
                How would you rate your experience with{' '}
                {topicName?.value === 'M1_GENERAL_FEEDBACK'
                  ? 'M1'
                  : `M1's ${topicName?.label}`}
                ? (<b>required</b>)
              </PL>

              <Flex flexDirection="column" mb={32}>
                <Radio.Group
                  value={selectedTopicRating}
                  {...register('topicRating')}
                  onChange={(e) => handleChange('topicRating', e)}
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-around"
                >
                  {RATING_VALUES.map((n) => {
                    return <Radio.Choice key={n} value={n} label="" />;
                  })}
                </Radio.Group>

                <Flex justifyContent="space-between" pl={16} pr={22}>
                  <PS>Dissatisfied</PS>
                  <PS>Very Satisfied</PS>
                </Flex>
              </Flex>

              <Flex flexDirection="column" mb={32}>
                <PL mb={16}>Is there anything else you would like to add?</PL>

                <StyledTextArea
                  value={inputtedTopicFeedback}
                  {...register('topicFeedback')}
                  maxLength={500}
                />
                <Flex justifyContent="flex-end" mt={10}>
                  <PS color={counterColor}>{charaCount}/500</PS>
                </Flex>
              </Flex>

              <Flex flexDirection="column" width="60%">
                <PL mb={16}>
                  Would you like to join our research panel? Volunteers are
                  contacted 1-2 times per year.
                </PL>

                <Radio.Group
                  value={selectedOptIn}
                  {...register('contactOptIn')}
                  onChange={(e) => handleChange('contactOptIn', e)}
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Radio.Choice label="Yes" value />
                  <Radio.Choice label="No" value={false} />
                  <PL />
                </Radio.Group>
              </Flex>
            </Box>
          )}

          <Box ml={32} display="inline-block" width="70%">
            <PL>
              If you have any questions or issues with your M1 account
              please&nbsp;
              <Link to="/d/contact-us">
                <PL content="contact our support team." />
              </Link>
            </PL>
          </Box>

          <Flex flexDirection="row" justifyContent="flex-end">
            <Button
              type="submit"
              kind="primary"
              label="Submit Feedback"
              size="large"
              onClick={handleSubmit(handleFeedbackSubmit)}
              m={32}
              disabled={selectedTopicRating === undefined || loading}
            />
          </Flex>
        </Grid.Col>
      </Grid.Row>
    </form>
  );
};
