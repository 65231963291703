import { Box, css, styled } from '@m1/liquid-react';

import type { Alignment, Direction } from './HelperDialog.types';

/**
 * @param {*} alignment - The desired alignment of the arrow and dialog
 * @param {*} value - The height or the width of the dialog.
 * @returns - An object with the pixel values for the arrow and dialog alignment
 */
const calculateAlignment = (alignment: Alignment, value: number) =>
  alignment === 'start' || alignment === 'end'
    ? {
        dialogAlignment: '0',
        arrowAlignment: '15px',
      }
    : {
        dialogAlignment: `calc(50% - ${value / 2}px)`,
        arrowAlignment: `calc(50% - 15px)`,
      };

export const StyledDialog = styled(Box)<{
  alignment: Alignment;
  direction: Direction;
  height: number;
  width: number;
}>`
  z-index: 100;

  // Common styles for the arrow
  ${() => {
    return css`
      &::before {
        position: absolute;
        z-index: 100;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        content: '';
      }
    `;
  }}

  /**
  * A bunch of css to handle the positioning of the dialog and its arrow
  * for all the various combinations of direction and alignment.
  */
 ${(props) => {
    let alignmentStyles = {};
    switch (props.direction) {
      case 'up':
        alignmentStyles = calculateAlignment(props.alignment, props.width);

        return css`
          top: calc(100% + 20px);
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `left: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `right: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-bottom-color: ${props.theme.colors.backgroundPrimarySubtle};
            bottom: 100%;
            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `left: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `right: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'up-reverse':
        alignmentStyles = calculateAlignment(props.alignment, props.width);

        return css`
          top: 20px;
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `left: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `right: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-bottom-color: ${props.theme.colors.backgroundPrimarySubtle};
            bottom: 100%;
            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `left: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `right: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'down':
        alignmentStyles = calculateAlignment(props.alignment, props.width);

        return css`
          bottom: calc(100% + 20px);
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `left: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `right: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-top-color: ${props.theme.colors.backgroundPrimarySubtle};
            top: 100%;
            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `left: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `right: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'down-reverse':
        alignmentStyles = calculateAlignment(props.alignment, props.width);

        return css`
          bottom: 20px;
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `left: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `right: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-top-color: ${props.theme.colors.backgroundPrimarySubtle};
            top: 100%;
            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `left: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `right: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'left':
        alignmentStyles = calculateAlignment(props.alignment, props.height);

        return css`
          left: calc(100% + 20px);
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `top: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `bottom: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-right-color: ${props.theme.colors.backgroundPrimarySubtle};
            right: 100%;

            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `top: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `bottom: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'left-reverse':
        alignmentStyles = calculateAlignment(props.alignment, props.height);

        return css`
          left: 20px;
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `top: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `bottom: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-right-color: ${props.theme.colors.backgroundPrimarySubtle};
            right: 100%;

            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `top: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `bottom: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'right':
        alignmentStyles = calculateAlignment(props.alignment, props.height);

        return css`
          right: calc(100% + 20px);
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `top: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `bottom: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-left-color: ${props.theme.colors.backgroundPrimarySubtle};
            left: 100%;

            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `top: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `bottom: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      case 'right-reverse':
        alignmentStyles = calculateAlignment(props.alignment, props.height);

        return css`
          right: 20px;
          ${props.alignment === 'start'
            ? // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `top: ${alignmentStyles.dialogAlignment}`
            : // @ts-expect-error - TS2339 - Property 'dialogAlignment' does not exist on type '{}'.
              `bottom: ${alignmentStyles.dialogAlignment}`};

          &::before {
            border-left-color: ${props.theme.colors.backgroundPrimarySubtle};
            left: 100%;

            ${props.alignment === 'start'
              ? // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `top: ${alignmentStyles.arrowAlignment}`
              : // @ts-expect-error - TS2339 - Property 'arrowAlignment' does not exist on type '{}'.
                `bottom: ${alignmentStyles.arrowAlignment}`};
          }
        `;
      default:
        return null;
    }
  }}
`;
