import {
  Box,
  Flex,
  PM,
  type FlexProps,
  styled,
  css,
  theme,
} from '@m1/liquid-react';
import * as React from 'react';

import type { TransferAmountLineItem } from '~/graphql/types';

export const TransferLineItems = ({
  items,
}: {
  items: TransferAmountLineItem[];
}) => {
  return (
    <Box pt={4}>
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        return (
          <TransferLineItem
            key={`transfer-line-item-${index}`}
            {...{
              item,
              isFirst,
              isLast,
            }}
          />
        );
      })}
    </Box>
  );
};

type TransferLineItemProps = FlexProps & {
  item: TransferAmountLineItem;
  isFirst: boolean;
  isLast: boolean;
};

const StyledLineItemContainer = styled(Flex)<{
  isFirst: boolean;
  isLast: boolean;
}>`
  justify-content: space-between;
  margin-top: 8px;
  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-top: 0px;
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      border-top-color: ${theme.colors.borderMain};
      border-top-width: 1px;
      border-top-style: dashed;
      padding-top: 8px;
      color: ${theme.colors.foregroundNeutralMain};
    `}
`;

export const TransferLineItem = ({
  item,
  isFirst,
  isLast,
  ...rest
}: TransferLineItemProps) => {
  const { label, formattedValue } = item;
  return (
    <StyledLineItemContainer
      {...{
        isFirst,
        isLast,
        ...rest,
      }}
    >
      <PM>{label}</PM>
      <PM fontWeight={isLast ? 600 : 400}>{formattedValue}</PM>
    </StyledLineItemContainer>
  );
};
