import { styled } from '@m1/liquid-react';
import * as React from 'react';
import { type To } from 'react-router-dom';

import { Link } from '~/toolbox/link/Link';

const StyledNavLink = styled(Link)<{ $isActive: boolean }>`
  color: ${({ $isActive, theme }) =>
    $isActive
      ? theme.colors.foregroundSecondary
      : theme.colors.foregroundNeutralSecondary};
  font-size: 14px;
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
`;

const StyledListItem = styled.li<{ $isActive: boolean }>`
  align-items: center;
  border-bottom: ${({ $isActive, theme }) =>
    $isActive ? `4px solid ${theme.colors.foregroundSecondary}` : 'none'};
  display: inline-flex;
  padding: 20px 12px 16px 12px;
`;

export type NavLinkProps = React.PropsWithChildren<{
  dataTestId?: string;
  onClick: (item: EventTarget) => void;
  to: To;
  isActive: boolean;
}>;

export const NavLink = ({
  dataTestId,
  isActive,
  onClick,
  to,
  children,
}: NavLinkProps) => {
  return (
    <StyledListItem
      $isActive={isActive}
      onClick={(e) => {
        onClick(e.target);
      }}
    >
      <StyledNavLink data-testid={dataTestId} to={to} $isActive={isActive}>
        {children}
      </StyledNavLink>
    </StyledListItem>
  );
};
