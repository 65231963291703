import { Button, Text } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { ResetPasswordNotifications } from '~/components/notifications';
import { connectForm } from '~/hocs';

import { PasswordField } from './fields';

class ResetPasswordFormComponent extends React.Component<
  InjectedFormProps<any>
> {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div
          style={{
            padding: `16px 0`,
          }}
        >
          <Text content="Enter your new password below" />
        </div>
        <PasswordField
          name="newPassword"
          label="New Password"
          securePasswordEnabled
        />
        <div
          style={{
            paddingTop: 8,
          }}
        >
          <ResetPasswordNotifications />
        </div>
        <div
          style={{
            paddingTop: 24,
          }}
        >
          <Button
            type="submit"
            label="Continue"
            kind="primary"
            size="large"
            fullWidth
          />
        </div>
      </form>
    );
  }
}

export const ResetPasswordForm = connectForm({
  form: 'reset-password',
})(ResetPasswordFormComponent);
