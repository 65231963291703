import { PL } from '@m1/liquid-react';
import * as React from 'react';

import { type TransactionType, Gain } from '../gain';

type TextProps = React.ComponentProps<typeof PL>;

export type SpendActivityEntryAmountTextProps = TextProps & {
  amount: number;
  type?: TransactionType;
  isCanceled?: boolean;
};

export const SpendActivityEntryAmountText = ({
  amount,
  type,
  isCanceled = false,
  ...rest
}: SpendActivityEntryAmountTextProps) => {
  return (
    <PL
      {...rest}
      content={<Gain value={amount} type={type} strikeThrough={isCanceled} />}
    />
  );
};
