import {
  HM,
  PL,
  Flex,
  Box,
  styled,
  useThemeMode,
  PM,
  Card,
} from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { MarketingAndDisclaimer } from '~/components/marketing-disclaimer/MarketingAndDisclaimer';
import { RegisterUserFlow } from '~/flows';
import { useOnMount } from '~/hooks/useOnMount';
import { useSupportLink } from '~/hooks/useSupportLink';
import type { AppState } from '~/redux';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { M1Logo } from '~/toolbox/M1Logo';
import { SmallOrWiderScreen } from '~/toolbox/responsive';

import { SignupMarketing } from './components/SignupMarketing';

const useGetDynamicSupportLink = (): React.ReactNode | null | undefined => {
  const supportLink = useSupportLink('SUPPORT_2FA');

  const showTwoFactorSupportLink = useSelector<boolean>((state: AppState) => {
    return Boolean(
      state.newFlows.login.step === 'COLLECT_AMFA_CODE' &&
        state.newFlows.login.error === 'USER_ACCOUNT_LOCKED',
    );
  });

  if (showTwoFactorSupportLink) {
    return (
      <Link pt={4} {...supportLink}>
        <PM content="Contact support" fontWeight={600} />
      </Link>
    );
  }

  return null;
};

const StyledForm = styled(Box)`
  position: relative;
`;

const SignUpColumn = styled(Flex)`
  flex-direction: column;
`;

export const StyledContainer = styled(Box)`
  padding-top: 64px;
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  height: 100vh;
  overflow: scroll;
  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    padding-top: 0;
  }
`;

export const StyledBackgroundImage = styled(Box)`
  position: absolute;
  width: 100%;

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    top: 18%;
  }
`;

export const SignupPage = () => {
  const { activeThemeMode } = useThemeMode();

  const dispatch = useDispatch();

  useOnMount(() => {
    dispatch(enteredPage('SIGNUP', undefined));
    return () => {
      dispatch(exitedPage('SIGNUP', undefined));
    };
  });

  type StyledProps = {
    matches: boolean;
  };

  const StyledFlex = styled(Flex)<StyledProps>`
    justify-content: center;
    ${({ matches }) => {
      if (matches) {
        return `
      padding-bottom: 16px;
      `;
      }
      return `
      padding-top:8px;
      padding-bottom: 16px;
      `;
    }}
  `;
  const StyledLink = styled(Link)`
    text-decoration: underline;
  `;

  const dynamicContactSupportLink = useGetDynamicSupportLink();

  return (
    <StyledContainer p="0 0 3.5rem">
      <StyledBackgroundImage>
        <Illustration name="signupLoginWhisp" width="100%" opacity="0.5" />
      </StyledBackgroundImage>
      <StyledForm>
        <RegisterUserFlow>
          {(props: any) => (
            <Container>
              <Box>
                <SmallOrWiderScreen>
                  {(matches) => (
                    <>
                      <StyledFlex matches={matches}>
                        <ExternalLink destination="M1_STATIC">
                          <M1Logo width={42} />
                        </ExternalLink>
                      </StyledFlex>
                      <TitleCopy matches={matches} />
                    </>
                  )}
                </SmallOrWiderScreen>
                <Flex justifyContent="center" pb={60}>
                  <Flex flexWrap="wrap" justifyContent="space-evenly">
                    <SignUpColumn flexGrow={0} maxWidth={500}>
                      <SmallOrWiderScreen>
                        {(matches) => (
                          <Card
                            isElevated
                            p={matches ? 32 : 16}
                            backgroundColor="backgroundNeutralSecondary"
                          >
                            {props.stepElement}
                            <PL
                              color="foregroundNeutralSecondary"
                              style={{
                                paddingTop: 24,
                                textAlign: 'center',
                              }}
                            >
                              Already have an account?{' '}
                              <StyledLink to="/login">Login</StyledLink>
                            </PL>
                          </Card>
                        )}
                      </SmallOrWiderScreen>
                    </SignUpColumn>
                    <SignUpColumn>
                      <SignupMarketing />
                    </SignUpColumn>
                  </Flex>
                </Flex>
                <Box pt={32}>
                  <MarketingAndDisclaimer
                    themeMode={activeThemeMode}
                    dynamicContactSupportLink={dynamicContactSupportLink}
                  />
                </Box>
              </Box>
            </Container>
          )}
        </RegisterUserFlow>
      </StyledForm>
    </StyledContainer>
  );
};

const TitleCopy = ({ matches }: { matches: boolean }) => {
  const content = 'Invest, borrow, and spend all in one place.';
  if (matches) {
    return (
      <div>
        <HM
          content={content}
          mb={24}
          fontWeight={500}
          textAlign="center"
          color="foregroundNeutralMain"
        />
      </div>
    );
  }

  return (
    <div>
      <PL
        content={content}
        mb={8}
        fontWeight={400}
        textAlign="center"
        color="foregroundNeutralMain"
      />
    </div>
  );
};
