import {
  HXXS,
  PM,
  Flex,
  Pill,
  styled,
  Box,
  Text,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { useUpcomingActivityQuery } from '~/graphql/hooks';
import type {
  PortfolioDetailsSliceQuery,
  UpcomingActivityQuery,
} from '~/graphql/types';
import { useSelector } from '~/redux/hooks';

import {
  buildEstimatedTradesText,
  formatBuySellCount,
} from '../PortfolioDetailsPage.utils';

import { ActivityLink } from './AccountTradingStatus/ActivityLink';

type PortfolioSliceAccount = ExtractTypename<
  PortfolioDetailsSliceQuery['account'],
  'Account'
>;

type UpcomingActivityProps = {
  account: PortfolioSliceAccount;
  portfolioSlice: Extract<
    PortfolioDetailsSliceQuery['portfolioSlice'],
    { id: string }
  >;
};

const Border = styled(Box)`
  height: 1px;
  min-height: 1px;
  border-top: ${({ theme }) => `solid 1px ${theme.colors.borderMain}`};
`;

const TransferPillAndLabel = ({
  name,
  transfersPillLabel,
}: {
  name: string;
  transfersPillLabel: string;
}) => {
  return (
    <Flex minHeight={48} alignItems="center" justifyContent="space-between">
      <Text
        mt={10}
        content={
          <Flex flexDirection="column">
            <PM content="Transfers" />
            <PM
              mt={4}
              content={`To/From ${name}`}
              color="foregroundNeutralSecondary"
            />
          </Flex>
        }
      />
      <Pill kind="neutral" label={transfersPillLabel} />
    </Flex>
  );
};

const UpcomingTransfers = ({
  account,
  name,
}: {
  account: PortfolioSliceAccount;
  name: string;
}) => {
  const { pendingTransferInstances } = account;
  const hasPendingTransferInstances = Boolean(
    account?.hasPendingTransferInstances,
  );
  // Getting the first transfer instance is appropriate because you can only have 1 pending transfer instance at a time
  const firstTransfer =
    Array.isArray(pendingTransferInstances) && pendingTransferInstances.length
      ? pendingTransferInstances[0]
      : null;
  const singleTransferId = firstTransfer?.id;
  const transfersPillLabel = hasPendingTransferInstances ? 'Pending' : 'None';
  return hasPendingTransferInstances ? (
    <ActivityLink
      kind="secondary"
      query={{
        previousRouteName: '/d/invest',
      }}
      params={{
        transferInstanceId: singleTransferId,
      }}
      to={
        Array.isArray(pendingTransferInstances) &&
        pendingTransferInstances.length > 1
          ? '/d/transfers'
          : '/d/c/transfer-details/:transferInstanceId'
      }
    >
      <TransferPillAndLabel
        name={name}
        transfersPillLabel={transfersPillLabel}
      />
    </ActivityLink>
  ) : (
    <TransferPillAndLabel name={name} transfersPillLabel={transfersPillLabel} />
  );
};

const UpcomingTrades = ({
  estimatedTrading,
  portfolioSliceId,
}: {
  estimatedTrading: ExtractTypename<
    UpcomingActivityQuery['node'],
    'Account'
  >['estimatedTrading'];
  portfolioSliceId: string;
}) => {
  const includedBuysTotal = estimatedTrading?.buys.total ?? 0;
  const excludedBuysTotal = estimatedTrading?.excludedBuys.total ?? 0;
  const buysTotal = includedBuysTotal + excludedBuysTotal;
  const buysContent = formatBuySellCount(buysTotal, 'buy');
  const sellsTotal = estimatedTrading?.sells.total ?? 0;
  const sellsContent = formatBuySellCount(sellsTotal, 'sell');
  const totalTrades = buysTotal + sellsTotal;
  const tradePillLabel = buildEstimatedTradesText(buysContent, sellsContent);
  const linkContent = (
    <Flex
      height={48}
      alignItems="center"
      width="100%"
      justifyContent="space-between"
    >
      <PM content="Trades" />
      <Pill kind="neutral" label={tradePillLabel} />
    </Flex>
  );
  return totalTrades > 0 ? (
    <ActivityLink
      to="/d/invest/portfolio/:portfolioSliceId/trading"
      params={{ portfolioSliceId }}
      kind="secondary"
    >
      {linkContent}
    </ActivityLink>
  ) : (
    linkContent
  );
};

export const UpcomingActivity = ({
  account,
  portfolioSlice,
}: UpcomingActivityProps) => {
  const accountId = useSelector((state) => state.global.activeAccountId);
  const { data } = useUpcomingActivityQuery({
    skip: !accountId,
    variables: {
      accountId: accountId as string,
    },
  });

  if (data?.node?.__typename !== 'Account') {
    return null;
  }
  const { isTradingPaused, trading, estimatedTrading } = data.node;

  if (!isTradingPaused && !trading && !estimatedTrading) {
    /*
      Will keep this for now, but I think the new UI will handle this more gracefully.
      Can rethink this when completing updates in Trading Details Section.
      isTradingPaused is pretty self explanatory
      estimatedTrading is to get the totalTrades
      trading is used as part of the AccountTradingStatusCard - for the Next Trade Window and Trading Window Preference sections
    */
    return null;
  }

  return (
    <Card minHeight={204} p={24} mt={24}>
      <HXXS fontWeight={300} content="Upcoming Activity" mb={16} />
      <>
        <UpcomingTrades
          estimatedTrading={estimatedTrading}
          portfolioSliceId={portfolioSlice.id}
        />
        <Border />
        <UpcomingTransfers name={account.name} account={account} />
      </>
    </Card>
  );
};
