import { Box, Flex, PM, PS, Card, type CardProps } from '@m1/liquid-react';
import * as React from 'react';

import { TransferEmailVerificationCard } from '~/components/transfer-email-verification-card';
import type { TransferInstanceBottomMessageCardFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

export type TransferInstanceBottomMessageCardProps = CardProps & {
  transferInstanceDetailsMessage: TransferInstanceBottomMessageCardFragment;
};

export const TransferInstanceBottomMessageCard = ({
  transferInstanceDetailsMessage,
  ...rest
}: TransferInstanceBottomMessageCardProps) => {
  const { icon, description, title, verifyEmailCta, link } =
    transferInstanceDetailsMessage;

  if (verifyEmailCta) {
    return (
      <Box mt={16}>
        <TransferEmailVerificationCard resendEmailStyle="link" />
      </Box>
    );
  }
  if (!description) {
    return null;
  }

  return (
    <Card
      borderColor="transparent"
      backgroundColor="backgroundNeutralTertiary"
      p="12px 16px"
      {...rest}
    >
      <Flex alignItems="center" minWidth={220} pr={16}>
        {icon && (
          <Box mr={16}>
            <AppImage appImage={icon} />
          </Box>
        )}
        <Box>
          {title && <PM fontWeight={600} mb={4} content={title} />}
          <PS content={description} />
          {link && <LinkableLink linkable={link} />}
        </Box>
      </Flex>
    </Card>
  );
};
