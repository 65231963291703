import { Box, type Color, Flex } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet, useMatches } from 'react-router-dom';

import { UNSAFE_connectRedux } from '~/hocs';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import type { AppState } from '~/redux';

import { CloseButton } from './components';

export type CoverProps = {
  closeButtonColor?: Color;
  lastVisited?: string | null;
  canClose?: boolean;
};

const CoverComponent = ({
  closeButtonColor,
  lastVisited,
  canClose,
}: CoverProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matches = useMatches();

  const [trueLastVisited, setTrueLastVisited] = React.useState<
    string | null | undefined
  >(undefined);

  React.useEffect(() => {
    if (lastVisited === null) {
      setTrueLastVisited(null);
    } else if (lastVisited && trueLastVisited === undefined) {
      setTrueLastVisited(lastVisited);
    }
  }, [lastVisited, trueLastVisited]);

  const previousRouteName = searchParams.get('previousRouteName');
  const handleClose = React.useCallback((): void => {
    document.body.style.overflow = 'initial';

    const fallbackRoute = (
      matches[matches.length - 1].handle as
        | { fallbackRoute?: { to: string } }
        | undefined
    )?.fallbackRoute ?? { to: '/d/home' };

    if (previousRouteName) {
      return navigate({
        to: previousRouteName,
        options: { replace: true },
      });
    }

    if (trueLastVisited) {
      return navigate({ to: trueLastVisited });
    }

    return navigate(fallbackRoute);
  }, [navigate, previousRouteName, trueLastVisited, matches]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<any>): void => {
      if (event.code === 'Escape' && canClose) {
        handleClose();
      }
    },
    [handleClose, canClose],
  );

  React.useEffect(() => {
    // @ts-expect-error - TS2769 - No overload matches this call.
    window.addEventListener('keydown', handleKeyDown);

    window.setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 400);

    return () => {
      document.body.style.overflow = 'initial';
      // @ts-expect-error - TS2769 - No overload matches this call.
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      zIndex={300}
      width="100vw"
      height="100vh"
      overflowY="auto"
      backgroundColor="backgroundNeutralSecondary"
      color="foregroundNeutralMain"
      css={{ position: 'fixed' }}
    >
      <Box
        css={{ position: 'fixed' }}
        zIndex={200}
        top={0}
        left={0}
        right={0}
        backgroundColor="backgroundNeutralSecondary"
      >
        <CloseButton
          color={closeButtonColor ?? 'foregroundNeutralMain'}
          onClick={handleClose}
        />
      </Box>
      <Flex
        backgroundColor="backgroundNeutralSecondary"
        flexDirection="column"
        minHeight="100%"
      >
        <Outlet />
      </Flex>
    </Box>
  );
};

const enhancer = UNSAFE_connectRedux(
  (state: AppState, ownProps: Partial<CoverProps>): Partial<CoverProps> => ({
    lastVisited:
      ownProps.lastVisited ||
      (state.routing.appHistory.length > 1
        ? state.routing.appHistory[1]
        : null),
    // if a modal is open, `canClose` is false
    canClose: Boolean(
      !Object.values(state.modals).find((modalState) => modalState.isOpened),
    ),
  }),
);

export const Cover = enhancer(CoverComponent);
