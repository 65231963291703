import { Box, Card, HXXS, Link, PM } from '@m1/liquid-react';
import { Banner } from '@m1/liquid-react/Banner';
import React from 'react';

import { isNotNil } from '~/utils';

export const AutoPaymentDate = ({
  autoPayDueDateMessage,
  autoPayInformationContent,
  onSwitchModes,
}: {
  autoPayDueDateMessage: Maybe<string>;
  autoPayInformationContent: Maybe<string>;
  onSwitchModes: () => void;
}) => {
  return (
    <Box mb={64}>
      {isNotNil(autoPayInformationContent) && (
        <>
          <HXXS content="Payment Date" mt={24} mb={16} />
          <Card label="AutoPay">
            <PM color="foregroundNeutralMain">{autoPayInformationContent}</PM>
          </Card>
        </>
      )}
      {isNotNil(autoPayDueDateMessage) && (
        <Banner
          mt={16}
          kind="warning"
          content={autoPayDueDateMessage}
          action={<Link onClick={() => onSwitchModes()}>One-time payment</Link>}
        />
      )}
    </Box>
  );
};
