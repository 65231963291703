import * as React from 'react';

import type { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useLocation } from '~/hooks/useLocation';

import { NavLinks } from './NavLinks';

export const TopNavTransfers = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  const location = useLocation();
  const items = location.pathname.includes('/transfers/rollovers')
    ? []
    : [
        {
          id: 'activity',
          to: '/d/transfers/activity',
          label: 'All transfers',
        },
        {
          id: 'rules',
          to: '/d/transfers/rules',
          label: 'Recurring',
        },
      ];

  return (
    <NavLinks
      items={items}
      isActive={(item) =>
        selectedNavigation.activeL1Nav === 'transfers' &&
        selectedNavigation.activeL3Nav === item.id
      }
    />
  );
};
