import { css, Flex, PM, styled } from '@m1/liquid-react';
import React from 'react';

import { useReferralsTopNavigationItemQuery } from '~/graphql/hooks';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';

const TopNavReferralContainer = styled(Flex)(
  ({ theme }) => css`
    cursor: pointer;
    white-space: nowrap;

    @media screen and (max-width: ${theme.breakpoints.SMALL}) {
      p {
        display: none;
      }
    }
  `,
);

export const TopNavReferral = () => {
  const { data, loading } = useReferralsTopNavigationItemQuery();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  if (!data || loading) {
    return null;
  }

  const referralsTopNavigationItem =
    data?.viewer.user?.referrals?.referralsTopNavigationItem;

  const icon = referralsTopNavigationItem?.icon;

  const onClick = () => {
    navigate({
      to: referralsTopNavigationItem?.internalPath ?? '/d/home',
    });
    referralsTopNavigationItem?.analyticsEvent &&
      analytics.recordAppAnalyticsEvent(
        referralsTopNavigationItem?.analyticsEvent,
      );
  };

  return (
    <TopNavReferralContainer
      justifyContent="center"
      alignItems="center"
      height="100%"
      onClick={onClick}
    >
      {icon && <AppImage appImage={icon} />}
      {referralsTopNavigationItem && (
        <PM
          fontWeight={600}
          color="foregroundNeutralSecondary"
          content={referralsTopNavigationItem.title}
        />
      )}
    </TopNavReferralContainer>
  );
};
