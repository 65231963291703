import { Box, Button } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { TwoFactorNotifications } from '~/components/notifications';
import { compose, connectForm, UNSAFE_connectRedux } from '~/hocs';
import { submit2faCodeForm } from '~/redux/actions';
import {
  compose as composeNormalizers,
  onlyNumbers,
  normalizeToLength,
} from '~/utils/normalizers';

import { TextField } from './fields';
import { required, length } from './validators';

export type TwoFactorAuthFormProps = InjectedFormProps<any> & {
  color: any;
};

class TwoFactorAuthFormComponent extends React.Component<TwoFactorAuthFormProps> {
  static codeNormalizers = composeNormalizers(
    onlyNumbers,
    normalizeToLength(6),
  );
  static codeValidations = [required, length(6)];

  render() {
    return (
      <>
        <Box>
          <TwoFactorNotifications />
        </Box>
        <form
          style={{
            paddingTop: 8,
          }}
          onSubmit={this.props.handleSubmit}
        >
          <TextField
            name="code"
            label="Two-Factor Auth Code"
            normalize={TwoFactorAuthForm.codeNormalizers}
            validate={TwoFactorAuthForm.codeValidations}
            autoComplete="off"
            autoFocus
            color={this.props.color}
            tabIndex={1}
          />
          <Button
            mt={32}
            type="submit"
            label="Submit"
            kind="primary"
            size="large"
            fullWidth
          />
        </form>
      </>
    );
  }
}

const enhancer = compose<any, any>(
  UNSAFE_connectRedux(null, {
    onSubmit: submit2faCodeForm,
  }),
  connectForm({
    form: 'two-factor-auth-form',
  }),
);
export const TwoFactorAuthForm = enhancer(TwoFactorAuthFormComponent) as any;
