import { PXL, PM, LM, Flex, Box, styled, spacingUnits } from '@m1/liquid-react';
import * as React from 'react';

import type { RichTextFragment } from '~/graphql/types';

import { RichText } from '~/lens-toolbox/RichText';

import type { ConfirmationChange } from './ConfirmationDialog.types';
import { ConfirmationRow } from './ConfirmationRow';

export type ConfirmationGroupProps = {
  changes: Array<ConfirmationChange>;
  className?: string;
  headerLabel?: string;
  message?: string | null | undefined;
  title: string | null | undefined;
  messages?: Maybe<RichTextFragment[]>;
};

const ConfirmationHeader = styled(Flex)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralMain};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
`;

export const ConfirmationGroup = (props: ConfirmationGroupProps) => {
  const { changes, className, headerLabel, message, messages, title } = props;

  if (!changes.length) {
    return null;
  }

  return (
    <Flex className={className} flexDirection="column" mb={32} width="100%">
      {title && (
        <PXL fontWeight={600} color="foregroundNeutralSecondary" mb={8}>
          {title}
        </PXL>
      )}
      {message && (
        <PM color="foregroundNeutralSecondary" mb={24}>
          {message}
        </PM>
      )}
      <Box
        borderColor="borderMain"
        borderStyle="solid"
        borderWidth="1px"
        borderRadius="8px"
      >
        {headerLabel && (
          <ConfirmationHeader
            alignItems="center"
            borderRadius="8px 8px 0 0"
            height="40px"
            justifyContent="space-between"
            pl={16}
          >
            <LM color="foregroundNeutralSecondary">{headerLabel}</LM>
          </ConfirmationHeader>
        )}
        <Box>
          {changes.map((change, index) => (
            <ConfirmationRow change={change} key={`${index}`} />
          ))}
        </Box>
      </Box>
      <Box mt={spacingUnits.xxs}>
        {messages &&
          messages.map((message, index) => {
            return (
              <RichText
                key={index}
                richText={message}
                textProps={{
                  color: 'foregroundNeutralSecondary',
                }}
                linkProps={{
                  font: 'PS',
                  fontWeight: 600,
                  display: 'inline !important',
                }}
                containerProps={{
                  display: 'inline-block',
                  paddingY: '4px',
                }}
              />
            );
          })}
      </Box>
    </Flex>
  );
};
