import {
  PL,
  HXXS,
  LS,
  Flex,
  Box,
  ModalContent,
  ModalTitle,
} from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { CashBalanceControlForm } from '~/components/invest/CashBalanceControlForm';
import { useAccountCashBalanceControlQuery } from '~/graphql/hooks';

import { Spinner } from '~/toolbox/spinner';
import { formatCurrency } from '~/utils/formatting';

type CashControlModalContentProps = {
  accountId: string;
  hideCashMinimumInput: boolean;
};

export const CashControlModalContent = ({
  accountId,
  hideCashMinimumInput,
}: CashControlModalContentProps) => {
  const { data, loading } = useAccountCashBalanceControlQuery({
    variables: { id: accountId },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const account = data?.node?.__typename === 'Account' ? data.node : null;
  if (account === null) {
    // TODO: Should I do something else here?
    return null;
  }

  const cashAvailable = account.balance.cash?.available ?? null;

  const autoInvestTriggerMinimumDisplay =
    (account.autoInvestTriggerMinimum ?? -1) >= 0
      ? formatCurrency(account.autoInvestTriggerMinimum, 'SELECTIVE')
      : '$25';

  return (
    <ModalContent width="wide">
      <ModalTitle>Auto-invest settings</ModalTitle>

      <div>
        <Box pb={8}>
          <Flex flexDirection="row">
            <HXXS>{formatCurrency(cashAvailable, 'SELECTIVE') ?? '$--'}</HXXS>

            <LS pl={8} pt={8} color="foregroundNeutralMain">
              Available cash balance
            </LS>
          </Flex>
        </Box>

        <Box pb={8}>
          <PL>
            {`Auto-invest lets you set how much cash you'd like to keep in your portfolio,
            and it invests overages. Anytime you exceed your auto-invest cash minimum by
            at least ${autoInvestTriggerMinimumDisplay}, we'll automatically invest and
            return you to your minimum. Changes take effect with the next trade window.`}
          </PL>
        </Box>
        <Box pb={8}>
          <ExternalLink destination="CASH_FAQ" label="Learn more" />
        </Box>

        <CashBalanceControlForm
          accountId={account.id}
          hideCashMinimumInput={hideCashMinimumInput}
        />
      </div>
    </ModalContent>
  );
};
