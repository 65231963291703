import type { Action } from 'redux';
// @ts-expect-error - TS2614 - Module '"redux-saga"' has no exported member 'PutEffect'. Did you mean to use 'import PutEffect from "redux-saga"' instead? | TS2614 - Module '"redux-saga"' has no exported member 'SelectEffect'. Did you mean to use 'import SelectEffect from "redux-saga"' instead?
import { PutEffect, SelectEffect } from 'redux-saga';
import {
  put as reduxSagaPut,
  select as reduxSagaSelect,
} from 'redux-saga/effects';

import type { AppState } from '~/redux';

import type { AppAction } from '../actions/types';

export * from 'redux-saga/effects';

/*
  put
  https://redux-saga.js.org/docs/api#putaction
  - Re-exported with argument typed as `AppAction`.
*/
export function put(action: AppAction): PutEffect<AppAction, null> {
  return reduxSagaPut(action as Action<any>);
}

/*
  select
  https://redux-saga.js.org/docs/api#selectselector-args
  - Re-exported with argument provided to selecor as `AppState`.
  - Less flexible than select exported from redux-saga, in that this
    version does not allow any additional arguments to the selector. This
    is a very rare pattern in our codebase.
*/
type Selector<R> = (state: AppState) => R;
export const select = <R>(
  selector: Selector<R>,
): SelectEffect<Selector<R>, []> => {
  return reduxSagaSelect(selector);
};
