import type { NavigateFunction } from '~/hooks/useNavigate';

import type { AppHistoryLocation } from '../reducers/routing';

export const ROUTING_ACTIONS = Object.freeze({
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE' as const,
  SET_NAVIGATE: 'SET_NAVIGATE' as const,
});

export type SetNavigateAction = {
  type: typeof ROUTING_ACTIONS.SET_NAVIGATE;
  payload: NavigateFunction;
};

export type LocationChangeAction = {
  type: typeof ROUTING_ACTIONS.LOCATION_CHANGE;
  payload: AppHistoryLocation;
};

/**
 * An action whose only use is to keep redux in sync with the router location.
 * We should never call this explicitely, except in the AppRoutes component.
 */
export const locationChange = (
  payload: LocationChangeAction['payload'],
): LocationChangeAction => ({
  type: ROUTING_ACTIONS.LOCATION_CHANGE,
  payload,
});

export const setNavigate = (
  payload: SetNavigateAction['payload'],
): SetNavigateAction => ({
  type: ROUTING_ACTIONS.SET_NAVIGATE,
  payload,
});

export type RouteAction = LocationChangeAction | SetNavigateAction;
