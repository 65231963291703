import { Box } from '@m1/liquid-react';
import * as React from 'react';

import type { BorrowAccount } from '~/graphql/types';
import { Tabs, Tab } from '~/toolbox/tabs';

import { BorrowActivityTable } from './BorrowActivityTable';
import { BorrowBillsTable } from './BorrowBillsTable';

export type TransactionsHistoryTabsProps = ProvidedProps & {
  borrowAccount: BorrowAccount;
};

type ProvidedProps = {
  activeTab: number;
  changeTab: (index: number) => void;
};

export const TransactionsHistoryTabs = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <Box minHeight={340}>
      <Tabs>
        <Tab
          isActive={activeTab === 0}
          label="Activity"
          onClick={() => setActiveTab(0)}
        />
        <Tab
          isActive={activeTab === 1}
          label="Statements"
          onClick={() => setActiveTab(1)}
        />
      </Tabs>
      <Box pt={16}>
        {activeTab === 0 && <BorrowActivityTable />}
        {activeTab === 1 && <BorrowBillsTable />}
      </Box>
    </Box>
  );
};
