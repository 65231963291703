import React from 'react';

import { usePersonalLoansDirectQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { Spinner } from '~/toolbox/spinner';

import { DateOfBirthAndCitizenshipForm } from '../components/DateOfBirthAndCitizenshipForm';
import { StyledHeader } from '../components/StyledHeader';
import { getQuestionProps } from '../utils/getQuestionprops';

export const DateOfBirthCitizenship = () => {
  const { data, loading } = usePersonalLoansDirectQuery();
  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/identity-info');
  }, [analytics]);

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  let props;

  if (questions) {
    props = getQuestionProps({
      question: 'DATE_OF_BIRTH_CITIZENSHIP',
      questions,
    });
  }

  const { title } = props || {};

  return (
    <div>
      <StyledHeader
        content={
          title ?? `Great, we need just a few things to create your offers.`
        }
      />
      <DateOfBirthAndCitizenshipForm />
    </div>
  );
};
