import { Icon } from '@m1/liquid-react/icons';
import moment from 'moment';
import * as React from 'react';

import type { TransfersPendingAcatInstancesFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';
import { Cell } from '~/toolbox/cell';
import { GridTable } from '~/toolbox/grid-table';
import { formatCurrency } from '~/utils/formatting';

export type TransfersPendingAcatInstancesTableProps = {
  style?: Record<string, any>;
  transfers: TransfersPendingAcatInstancesFragment;
};

export const TransfersPendingAcatInstancesTable = ({
  style,
  transfers,
}: TransfersPendingAcatInstancesTableProps) => {
  const { acatInstances } = transfers;
  return (
    <GridTable
      emptyMessage={
        acatInstances
          ? 'You have no currently pending ACAT transfers.'
          : 'Error fetching your pending ACAT transfers. Please try again later.'
      }
      gridTemplateColumns="110px 280px 280px auto auto"
      style={style}
      isNewStyle
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="Date" />
        <GridTable.HeaderCell label="From" />
        <GridTable.HeaderCell label="To" />
        <GridTable.HeaderCell label="Type" />
        <GridTable.HeaderCell justifySelf="end" label="Amount" />
      </GridTable.HeaderRow>
      {acatInstances &&
        acatInstances.map((instance) => {
          if (!instance) {
            return null;
          }
          return (
            <GridTable.NavigableRow
              key={instance.id}
              params={{
                transferInstanceId: instance.id,
              }}
              to="/d/c/acat-transfer-details/:transferInstanceId"
            >
              <GridTable.Cell
                // @ts-expect-error - TS2769 - No overload matches this call.
                content={moment(instance.startDate).format('ll')}
              />
              <GridTable.Cell
                content={
                  instance.source ? (
                    <Cell
                      title={instance.source}
                      icon={<Icon name="accountBankPrimary32" />}
                      overflowEllipsis
                    />
                  ) : (
                    '--'
                  )
                }
              />
              <GridTable.Cell
                content={
                  instance.destination?.name ? (
                    <Cell
                      title={instance.destination.name}
                      icon={<Icon name="accountInvestPrimary32" />}
                      overflowEllipsis
                    />
                  ) : (
                    '--'
                  )
                }
              />
              <GridTable.Cell content={instance.type} />
              <GridTable.Cell
                content={
                  <>
                    {instance.details?.pill && (
                      <AppPill appPill={instance.details.pill} mr={16} />
                    )}
                    {formatCurrency(instance.amount, 'SELECTIVE') ??
                      'Available soon'}
                  </>
                }
                justifySelf="end"
              />
            </GridTable.NavigableRow>
          );
        })}
    </GridTable>
  );
};
