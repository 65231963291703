import { HXS, PL } from '@m1/liquid-react';
import React, { useContext } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import { useWizardContext } from '~/flows/wizard';
import {
  CreatePaymentForm,
  type PaymentFormValues,
} from '~/forms/CreatePaymentForm/CreatePaymentForm';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { TransferModeEnum } from '../../create-transfer/steps/TransferDetails.types';

import { StyledPaymentCard } from '../components/CreatePaymentWizard.styled';
import { CreatePaymentContext } from '../CreatePaymentContext';

export const PaymentDetailsStep = () => {
  const paymentContext = useContext(CreatePaymentContext);
  const dispatch = useDispatch();
  const { goTo } = useWizardContext();

  const formValues = useSelector(
    (state) => state?.reactHookForm['createPayment'] ?? {},
  );

  const initialValues = {
    sourceId:
      formValues.sourceId ?? paymentContext?.fromParticipantIdQueryParam,
    destinationId: paymentContext?.toParticipantIdQueryParam ?? undefined,
    otherAmount: formValues.otherAmount ?? undefined,
    selectedPreset: formValues.selectedPreset ?? undefined,
  };

  const viewer = paymentContext?.data?.viewer;
  const transfers = viewer?.transfers;
  const reqs = transfers?.requirements;
  const personalLoans = viewer?.borrow?.personalLoans;

  const isSchedule = paymentContext?.mode === TransferModeEnum.Schedule;

  const onSubmit: SubmitHandler<PaymentFormValues> = (formValues) => {
    /*
     * Below we are employing the ReactHookFormReducer pattern.
     * This pattern is documented in detail here:
     * https://m1finance.atlassian.net/wiki/x/xQCs4Q
     */
    dispatch(
      submitReactFormData({
        createPayment: formValues,
      }),
    );
    goTo(isSchedule ? 'AUTOPAY_VALIDATION' : 'PAYMENT_VALIDATION');
  };

  const onSwitchModes = React.useCallback(() => {
    paymentContext?.setMode((currentMode: TransferModeEnum) =>
      currentMode === TransferModeEnum.OneTime
        ? TransferModeEnum.Schedule
        : TransferModeEnum.OneTime,
    );
  }, [paymentContext]);

  return (
    <>
      <HXS
        color="foregroundNeutralMain"
        content={isSchedule ? 'Set up AutoPay' : 'One-time payment'}
      />
      <PL content={reqs?.transferDueDateLabel} />
      <StyledPaymentCard mt={32} p={32}>
        <CreatePaymentForm
          autoPayDueDateMessage={reqs?.autoPayDueDateMessage}
          autoPayInformationContent={reqs?.autoPayInformationContent}
          isPersonalLoanPayment={reqs?.isLoanPaymentTransfer ?? false}
          isPersonalLoansAutoPayEnabled={
            personalLoans?.isPersonalLoansAutoPayEnabled ?? false
          }
          creditAutoPayInstanceId={viewer?.credit?.autoPayInstance?.id}
          loans={personalLoans?.loans?.edges ?? []}
          onSwitchModes={onSwitchModes}
          amountPills={transfers?.defaultAmountPills}
          initialValues={initialValues}
          isSchedule={isSchedule}
          onSubmit={onSubmit}
          participantGroups={transfers?.participantGroups}
          pivot={paymentContext?.pivot ?? 'TO'}
          transferOverviewLink={reqs?.transferOverviewLink}
          transferInstancePresets={reqs?.transferAmountPresets}
          scheduledTransferPresets={reqs?.scheduledTransferAmountPresets}
        />
      </StyledPaymentCard>
    </>
  );
};
