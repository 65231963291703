import { Button } from '@m1/liquid-react';
import React from 'react';

import { LandingPageHeader } from '~/components/LandingPageHeader';
import { useCreditCardLandingPageHeaderQuery } from '~/graphql/hooks';
import type { CreditCardLandingPageHeaderQuery } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

export const CreditCardLandingPageHeader = ({
  accountId,
  hasAnnouncement,
}: {
  accountId: string;
  hasAnnouncement: boolean;
}) => {
  const navigate = useNavigate();
  const { data, loading } = useCreditCardLandingPageHeaderQuery({
    variables: {
      accountId: accountId as string,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
  });

  const node = data?.node as ExtractTypename<
    CreditCardLandingPageHeaderQuery['node'],
    'CreditCardAccount'
  >;

  const shouldAllowPayment = Boolean(
    node?.creditCardProductLandingPage?.floatingActionButton,
  );
  const title = node?.creditCardProductLandingPage?.title || '';
  const accountDetails =
    node?.creditCardProductLandingPage?.accountDetails || [];
  const header = node?.creditCardProductLandingPage?.header;

  const ctaComponent = (
    <Button
      kind="primary"
      onClick={() => {
        navigate({
          to: '/d/c/create-transfer',
          query: {
            previousRouteName: location.pathname,
            toParticipantId: accountId,
          },
        });
      }}
      disabled={!shouldAllowPayment}
      size="medium"
    >
      Make payment
    </Button>
  );

  return (
    <LandingPageHeader
      loading={loading}
      title={title}
      header={header}
      accountDetails={accountDetails}
      ctaComponent={ctaComponent}
      hasAnnouncement={hasAnnouncement}
    />
  );
};
