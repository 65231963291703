import type {
  MonthlyScheduleDateEnum,
  WeekOfMonthScheduleWeekEnum,
  WeeklyScheduleDayEnum,
} from '~/graphql/types';

import type { EnumDropdown } from './types';

export const WeekOfMonthScheduleWeekEnumDropdownOptions: EnumDropdown<WeekOfMonthScheduleWeekEnum> =
  [
    {
      'name': 'WEEK_1',
      'description': 'First',
    },
    {
      'name': 'WEEK_2',
      'description': 'Second',
    },
    {
      'name': 'WEEK_3',
      'description': 'Third',
    },
    {
      'name': 'WEEK_4',
      'description': 'Fourth',
    },
    {
      'name': 'WEEK_5',
      'description': 'Fifth',
    },
  ];
export const WeeklyScheduleDayEnumDropdownOptions: EnumDropdown<WeeklyScheduleDayEnum> =
  [
    {
      'name': 'SUNDAY',
      'description': 'Sunday',
    },
    {
      'name': 'MONDAY',
      'description': 'Monday',
    },
    {
      'name': 'TUESDAY',
      'description': 'Tuesday',
    },
    {
      'name': 'WEDNESDAY',
      'description': 'Wednesday',
    },
    {
      'name': 'THURSDAY',
      'description': 'Thursday',
    },
    {
      'name': 'FRIDAY',
      'description': 'Friday',
    },
    {
      'name': 'SATURDAY',
      'description': 'Saturday',
    },
  ];

export const MonthlyScheduleDateEnumDropdownOptions: EnumDropdown<MonthlyScheduleDateEnum> =
  [
    {
      'name': 'DAY_1',
      'description': '1st',
    },
    {
      'name': 'DAY_2',
      'description': '2nd',
    },
    {
      'name': 'DAY_3',
      'description': '3rd',
    },
    {
      'name': 'DAY_4',
      'description': '4th',
    },
    {
      'name': 'DAY_5',
      'description': '5th',
    },
    {
      'name': 'DAY_6',
      'description': '6th',
    },
    {
      'name': 'DAY_7',
      'description': '7th',
    },
    {
      'name': 'DAY_8',
      'description': '8th',
    },
    {
      'name': 'DAY_9',
      'description': '9th',
    },
    {
      'name': 'DAY_10',
      'description': '10th',
    },
    {
      'name': 'DAY_11',
      'description': '11th',
    },
    {
      'name': 'DAY_12',
      'description': '12th',
    },
    {
      'name': 'DAY_13',
      'description': '13th',
    },
    {
      'name': 'DAY_14',
      'description': '14th',
    },
    {
      'name': 'DAY_15',
      'description': '15th',
    },
    {
      'name': 'DAY_16',
      'description': '16th',
    },
    {
      'name': 'DAY_17',
      'description': '17th',
    },
    {
      'name': 'DAY_18',
      'description': '18th',
    },
    {
      'name': 'DAY_19',
      'description': '19th',
    },
    {
      'name': 'DAY_20',
      'description': '20th',
    },
    {
      'name': 'DAY_21',
      'description': '21st',
    },
    {
      'name': 'DAY_22',
      'description': '22nd',
    },
    {
      'name': 'DAY_23',
      'description': '23rd',
    },
    {
      'name': 'DAY_24',
      'description': '24th',
    },
    {
      'name': 'DAY_25',
      'description': '25th',
    },
    {
      'name': 'DAY_26',
      'description': '26th',
    },
    {
      'name': 'DAY_27',
      'description': '27th',
    },
    {
      'name': 'DAY_28',
      'description': '28th',
    },
    {
      'name': 'LAST',
      'description': '(Last day of the month)',
    },
  ];
