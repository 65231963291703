import { Button, type LayoutableProps } from '@m1/liquid-react';
import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import type { PortfolioSlicePendingOrderBannerFragment } from '~/graphql/types';
import { clickedCancelOrder } from '~/redux/actions';
import { formatCurrency } from '~/utils/formatting';

export type PortfolioSlicePendingOrderBannerProps = LayoutableProps & {
  portfolioSlice: PortfolioSlicePendingOrderBannerFragment;
};

type OrderStatus = NonNullable<
  PortfolioSlicePendingOrderBannerFragment['orderStatus']
>;

export const PortfolioSlicePendingOrderBanner = ({
  portfolioSlice,
  ...rest
}: PortfolioSlicePendingOrderBannerProps) => {
  const dispatch = useDispatch<any>();
  const readContentFromOrderStatus = React.useCallback(
    (orderStatus: OrderStatus): string => {
      if (!orderStatus.order) {
        return 'Pending order';
      }
      const { cashFlow } = orderStatus.order;
      const direction = cashFlow >= 0 ? 'buy' : 'sell';
      return `Pending ${direction} of ${formatCurrency(Math.abs(cashFlow))}`;
    },
    [],
  );

  if (!portfolioSlice.orderStatus || !portfolioSlice.orderStatus.isSetOnSelf) {
    return null;
  }

  return (
    <Banner
      {...rest}
      action={
        <Button
          kind="link"
          label="Cancel"
          style={{ fontSize: '16px', fontWeight: 600 }}
          onClick={() => dispatch(clickedCancelOrder(portfolioSlice.id))}
        />
      }
      content={readContentFromOrderStatus(portfolioSlice.orderStatus)}
      kind="information"
    />
  );
};
