import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { FormSection, type InjectedFormProps } from 'redux-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useIdentityInfoFormQuery } from '~/graphql/hooks';
import { connectForm } from '~/hocs';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import {
  CountryOfCitizenshipField,
  DateOfBirthField,
  EmploymentFields,
} from '../fields';

const IdentityInfoFormComponent = (props: InjectedFormProps<any>) => {
  const { handleSubmit } = props;
  const { data, loading, error } = useIdentityInfoFormQuery();
  if (loading) {
    return <Spinner />;
  }
  if (
    error ||
    !data?.viewer.onboarding?.dueDiligence?.occupationDropdownOptions
  ) {
    return <GenericSystemError />;
  }
  const { occupationDropdownOptions } = data.viewer.onboarding.dueDiligence;
  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Row>
          <Grid.Col sm={6} xs={12}>
            <DateOfBirthField />
          </Grid.Col>
          <Grid.Col sm={6} xs={12}>
            <CountryOfCitizenshipField accountRegistration="INDIVIDUAL" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12}>
            <FormSection name="employment">
              <EmploymentFields dropdownOptions={occupationDropdownOptions} />
            </FormSection>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row
          style={{
            marginTop: 64,
          }}
          xsTextCenter
        >
          <Grid.Col xs={12}>
            <Button
              type="submit"
              kind="primary"
              size="large"
              label="Continue"
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </form>
  );
};

export const IdentityInfoForm = connectForm({
  form: 'identity-info',
})(IdentityInfoFormComponent);
