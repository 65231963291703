import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@m1/liquid-react';
import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { AppContext, type AppContextType } from './AppContext';

export type ProviderProps = React.PropsWithChildren<AppContextType>;
export const Provider = ({
  apolloClient,
  analytics,
  auth,
  children,
  cdsSender,
  launchDarkly,
  sentry,
  store,
}: ProviderProps) => {
  const isProduction =
    __NODE_ENV__ === 'production' && __ENV__ === 'production';

  const context = {
    auth,
    analytics,
    cdsSender,
    launchDarkly,
    sentry,
    store,
    apolloClient,
  };

  return (
    <ThemeProvider isProduction={isProduction}>
      <AppContext.Provider value={context}>
        <ReduxProvider store={store}>
          <ApolloProvider client={context.apolloClient}>
            <IntercomProvider appId={window.config.intercom.appId}>
              {React.Children.only(children)}
            </IntercomProvider>
          </ApolloProvider>
        </ReduxProvider>
      </AppContext.Provider>
    </ThemeProvider>
  );
};
