import type { IraDistributionReasonEnum } from '~/graphql/types';

import type { EnumDropdown } from './types';

export const IraDistributionReasonEnumDropdownOptions: EnumDropdown<IraDistributionReasonEnum> =
  [
    {
      'name': 'PREMATURE',
      'description': 'Premature',
    },
    {
      'name': 'NORMAL',
      'description': 'Normal',
    },
    {
      'name': 'DISABILITY',
      'description': 'Disability',
    },
    {
      'name': 'DEATH',
      'description': 'Death',
    },
    {
      'name': 'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE',
      'description': 'Excess contribution removal before tax deadline',
    },
    {
      'name': 'EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE',
      'description': 'Excess contribution removal after tax deadline',
    },
  ];
