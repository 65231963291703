import { Card, type CardProps } from '@m1/liquid-react';
import * as React from 'react';

import { withProps } from '~/hocs';

import style from './style.module.scss';

export type ActionCardProps = CardProps;

type Fn = (Component: typeof Card) => React.ComponentType<CardProps>;

const enhancer: Fn = withProps(
  (props: CardProps) =>
    ({
      children: <div className={style.root}>{props.children}</div>,
      style: {
        ...(props.style || {}),
        padding: '12px 16px',
        border: '1px solid var(--colors-border-main)',
      },
    }) as const satisfies CardProps,
);

export const ActionCard = enhancer(Card) as any;
