import { Box, Button, Flex, HXXS } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useProfileSuitabilityQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type { ProfileSuitabilitiesFragment } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import {
  InvestmentExperienceEnumDropdownOptions,
  RiskToleranceEnumDropdownOptions,
  TimeHorizonEnumDropdownOptions,
  LiquidityNeedsEnumDropdownOptions,
} from '~/static-constants/dropdown-options/suitability-dropdown-options';
import { useToast } from '~/toasts';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';
import { getEnumEntries } from '~/utils';

export const InvestmentProfilePage = () => {
  const { data, loading } = useProfileSuitabilityQuery();
  const { addToast } = useToast();
  const [updateProfile, { loading: updatingProfile }] =
    useUpdateProfileMutation();

  const formMethods = useForm<ProfileSuitabilitiesFragment>({
    defaultValues: {},
  });

  React.useEffect(() => {
    if (!loading && data?.viewer?.profile?.suitability) {
      formMethods.reset(data.viewer.profile.suitability);
    }
  }, [data, formMethods, loading]);

  usePortaledSpinner(updatingProfile);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer?.profile) {
    return (
      <GenericSystemError content="Your profile is currently unavailable." />
    );
  }

  const handleSubmit = async (
    suitabilities: ProfileSuitabilitiesFragment,
  ): Promise<void> => {
    await updateProfile({
      variables: {
        input: {
          profile: {
            primary: {},
            suitability: {
              ...suitabilities,
            },
          },
        },
      },
      onCompleted: () => {
        addToast({
          content: 'Your profile has been updated.',
          kind: 'success',
          duration: 'long',
        });
      },
      onError: () =>
        addToast({
          content:
            'There was an error updating your profile. Please try again later or contact support.',
          kind: 'alert',
          duration: 'long',
        }),
    });
  };

  return (
    <Flex flexDirection="column" gap={40}>
      <HXXS content="Investment profile" fontWeight={300} />
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid.Row style={{ marginBottom: 8 }}>
          <Grid.Col xs={12} sm={6}>
            <Box mb={16}>
              <ControlledDropdown
                name="investmentExperience"
                label="Investment experience"
                control={formMethods.control}
                source={InvestmentExperienceEnumDropdownOptions}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <ControlledInput
              name="annualIncomeAmount"
              label="Annual income"
              control={formMethods.control}
              maskType="money"
              mask={{
                decimalScale: 0,
              }}
              rules={{ required: 'Required' }}
              maxLength={11}
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Box mb={16}>
              <ControlledDropdown
                name="totalNetWorth"
                label="Net worth"
                control={formMethods.control}
                source={getEnumEntries('TotalNetWorthEnum')}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Box mb={16}>
              <ControlledDropdown
                name="liquidNetWorth"
                label="Liquid net worth"
                control={formMethods.control}
                source={getEnumEntries('LiquidNetWorthEnum')}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Box mb={16}>
              <ControlledDropdown
                name="riskTolerance"
                label="Risk tolerance"
                control={formMethods.control}
                source={RiskToleranceEnumDropdownOptions}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Box mb={16}>
              <ControlledDropdown
                name="timeHorizon"
                label="Time horizon"
                control={formMethods.control}
                source={TimeHorizonEnumDropdownOptions}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Box mb={16}>
              <ControlledDropdown
                name="liquidityNeeds"
                label="Liquidity needs"
                control={formMethods.control}
                source={LiquidityNeedsEnumDropdownOptions}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col xs={12}>
            <Button mt={8} mb={40} kind="primary" type="submit" size="medium">
              Save
            </Button>
          </Grid.Col>
        </Grid.Row>
      </form>
    </Flex>
  );
};
