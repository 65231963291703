import { Box, Button, HXXS } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { connectForm } from '~/hocs';

import { CountryOfCitizenshipField, DateOfBirthField } from '../fields';

const IdentityInfoFormModernComponent = (props: InjectedFormProps<any>) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <HXXS>Date of birth</HXXS>
        <DateOfBirthField />
        <Box mt={32}>
          <HXXS>Citizenship</HXXS>
          <CountryOfCitizenshipField accountRegistration="INDIVIDUAL" />
        </Box>
        <Box mt={64}>
          <Button type="submit" kind="primary" size="large" label="Continue" />
          <FormMocker
            formName="identity-info-modern"
            fields={[
              {
                name: 'dateOfBirth',
                value: '01/11/1990',
              },
              {
                name: 'countryOfCitizenship',
                value: 'USA',
              },
            ]}
          />
        </Box>
      </Box>
    </form>
  );
};

export const IdentityInfoFormModern = connectForm({
  form: 'identity-info-modern',
})(IdentityInfoFormModernComponent);
