import { Button, HXS, Modal, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import { connectModal } from '~/hocs';
import type { ModalProp } from '~/redux/reducers/modals';

type Props = {
  modal: ModalProp<any>;
};

const InactivityWarningModalComponent = (props: Props) => {
  const handleCancel = (): void => {
    props.modal.hide();
  };

  return (
    <Modal open={props.modal.isOpened} onClose={handleCancel}>
      <ModalContent>
        <HXS content="Session timeout" />
        <PL
          content="As a security measure, M1 logs you out after a period of inactivity. Click the button if you want to stay logged in."
          mt={8}
        />
        <Button
          kind="primary"
          label="I'm still here!"
          mt={32}
          onClick={handleCancel}
          size="medium"
        />
      </ModalContent>
    </Modal>
  );
};

const enhancer = connectModal('INACTIVITY_WARNING');

export const InactivityWarningModal = enhancer(
  InactivityWarningModalComponent,
) as any;
