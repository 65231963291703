import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';

import { SettingsAccountsRoute } from './accounts/SettingsAccountsRoute';
import { SettingsDocumentsRoute } from './documents';
import { FeedbackFormRoute } from './feedback';
import { SettingsPlatformBillingRoute } from './platform-billing';
import { SettingsPreferencesRoute } from './preferences/SettingsPreferencesRoute';
import { ProfileRoute } from './profile/ProfileRoute';
import { SettingsSecurityRoute } from './security';
import { SettingsPage } from './SettingsPage';

export const SettingsRoute = () => {
  return (
    <Route
      path="settings"
      element={
        <RequireAuthGuard>
          <SettingsPage />
        </RequireAuthGuard>
      }
    >
      {ProfileRoute()}
      {SettingsAccountsRoute()}
      {SettingsDocumentsRoute()}
      {SettingsPlatformBillingRoute()}
      {SettingsSecurityRoute()}
      {SettingsPreferencesRoute()}
      {FeedbackFormRoute()}

      {/* Redirects for settings go here. */}
      <Route index element={<Navigate to="/d/settings/profile" replace />} />
    </Route>
  );
};
