import { PM, PL, PXL, Flex, Box, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { SecurityLogo } from '~/components/SecurityLogo';
import type { ChoosePieStepQuery, CryptoAsset } from '~/graphql/types';
import {
  equalizeSliceListAllocations,
  mapRemoteSliceableByType,
  type Pie,
  type ResponseSliceable,
} from '~/pie-trees';
import { createInvestQuickClickPieAndNavigate } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

type Crypto = ChoosePieStepQuery['viewer']['crypto'];

type CryptoQuickClickPiesSectionProps = {
  crypto: Crypto | null | undefined;
};

export const CryptoQuickClickPiesSection = ({
  crypto,
}: CryptoQuickClickPiesSectionProps) => {
  const dispatch = useDispatch();
  const { analytics } = React.useContext(AppContext);

  const createQuickClickAndNav = React.useCallback(
    (pie: Pie) => {
      analytics.recordEvent('m1_crypto_quick_click_pie_selected');
      dispatch(createInvestQuickClickPieAndNavigate({ pie }));
    },
    [analytics, dispatch],
  );

  const allCoins = React.useMemo(() => {
    const edges = crypto?.screenCrypto?.edges || [];

    if (edges.length <= 0) {
      return [];
    }

    return edges.reduce<Array<CryptoAsset>>((allCoins, current) => {
      if (current?.node) {
        // @ts-expect-error - TS2345 - Argument of type '{ readonly id: string; readonly legacyId: string; readonly symbol: string; readonly name: string; readonly restrictions: readonly CryptoAssetRestrictionEnum[] | null; }' is not assignable to parameter of type 'never'.
        allCoins.push(current.node);
      }
      return allCoins;
    }, []);
  }, [crypto?.screenCrypto?.edges]);

  const btcAndEth = allCoins.filter(
    (cryptoAsset) =>
      cryptoAsset.symbol === 'BTC' || cryptoAsset.symbol === 'ETH',
  );

  const allCoinsPieSlices = allCoins
    .filter((cryptoAsset) => !cryptoAsset.restrictions?.length)
    .map((cryptoAsset) => ({
      to: mapRemoteSliceableByType({
        ...cryptoAsset,
        type: 'CRYPTO',
      } as ResponseSliceable),
      percentage: 1, // it will get reset when equalized.
    }));

  const sharedFields = {
    __id: 'root-portfolio-pie',
    __checked: false,
    __highlighted__: false,
    __shouldEqualizeSlicePercentagesOnAdd: false,
    type: 'new_pie',
  };

  const pies: Pie[] = [];
  if (btcAndEth.length === 2) {
    pies.push({
      ...sharedFields,
      name: '70/30 Bitcoin and Ethereum',
      description:
        'Start with a market-cap-weighted allocation of BTC and ETH.',
      slices: btcAndEth.map((cryptoAsset) => ({
        to: mapRemoteSliceableByType({
          ...cryptoAsset,
          type: 'CRYPTO',
        } as ResponseSliceable),
        percentage: cryptoAsset.symbol === 'BTC' ? 70 : 30,
      })),
    } as Pie);
  }

  if (allCoins.length) {
    pies.push({
      ...sharedFields,
      name: 'All coins on M1',
      description: 'Invest equally in all the currently available coins.',
      slices: equalizeSliceListAllocations(allCoinsPieSlices, true),
    } as Pie);
  }

  if (!pies.length) {
    return null;
  }

  return (
    <Card mb={16} p={20}>
      <PXL fontWeight={600} content="Choose a pre-made Pie" mb={8} mx={8} />
      <PL
        color="foregroundNeutralSecondary"
        content="Here are some sample Pies to help you begin. Once your portfolio is set up, you can edit the targets or add coins."
        mb={20}
        mx={8}
      />
      <Flex>
        {pies.map((pie, index) => (
          <Card
            key={index}
            onClick={() => {
              createQuickClickAndNav(pie);
            }}
            p={16}
            width="50%"
            mx={8}
            mb={8}
          >
            <Flex flexDirection="column">
              <Flex alignItems="center" mb={16}>
                <Box height={32} width={32}>
                  <SecurityLogo content={<Icon name="m1Logo32" />} />
                </Box>
                <Box
                  flex="1"
                  px={8}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  ml={16}
                >
                  {pie.name}
                </Box>
                <Flex alignItems="center" mr={16}>
                  <Icon name="caretRight16" />
                </Flex>
              </Flex>
              <Flex>
                <PM
                  color="foregroundNeutralSecondary"
                  content={pie.description}
                />
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
    </Card>
  );
};
