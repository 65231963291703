import React from 'react';

import {
  useAcceptPersonalLoanOfferMutation,
  usePersonalLoansApplicationByIdSagaLazyQuery,
} from '~/graphql/hooks';
import type { LoanApplicationStatusEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { usePollTimer } from '~/hooks/usePollTimer';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { HardPullConsentPage } from '../components/HardPullConsentPage';
import { Loader } from '../components/Loader';
import { POLL_INTERVAL, POLL_LIMIT, GENERAL_ERROR_MESSAGE } from '../constants';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import type { PersonalLoanDirectSteps } from '../types';
import { getApplicationStatusRoute } from '../utils/getApplicationStatusRoute';

export const HardPullConsent = () => {
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );
  const { directToPersonalLoansPreApprovedOffer } = useSelector(
    (state) => state.reactHookForm,
  );
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { goTo } = usePersonalLoanDirectWizardContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [shouldStartApplicationPollTimer, setShouldStartApplicationPollTimer] =
    React.useState<boolean>(false);
  const { isPollLimitReached } = usePollTimer({
    shouldStartPollTimer: shouldStartApplicationPollTimer,
    pollInterval: POLL_INTERVAL,
    pollLimit: POLL_LIMIT,
  });

  const [acceptPersonalLoanOfferMutation] = useAcceptPersonalLoanOfferMutation({
    variables: {
      input: {
        offerId: directToPersonalLoansPreApprovedOffer?.offerId,
      },
    },
  });
  const [
    getPersonalLoanApplicationById,
    {
      data: personalLoanApplicationData,
      startPolling: startPollingApplication,
      stopPolling: stopPollingApplication,
    },
  ] = usePersonalLoansApplicationByIdSagaLazyQuery();

  const applicationStatus =
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.status?.status;
  const loanStatus =
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.loan?.status;
  const loanId =
    personalLoanApplicationData?.viewer?.borrow?.personalLoans?.application
      ?.loan?.id;

  const onSubmit = () => {
    setLoading(true);
    acceptPersonalLoanOfferMutation({
      onCompleted: () => {
        getPersonalLoanApplicationById({
          variables: {
            applicationId,
          },
        });
        // Once submitPersonalLoanApplicationMutation is complete, start polling for application status
        setShouldStartApplicationPollTimer(true);
        startPollingApplication(POLL_INTERVAL);
      },
      onError: () => {
        setLoading(false);
        addToast({
          content: GENERAL_ERROR_MESSAGE,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  // Watching for error states
  React.useEffect(() => {
    const statusesIgnore: LoanApplicationStatusEnum[] = [
      'SUBMITTED',
      'QUEUED',
      'APPROVED',
      'OFFERS_PROVIDED',
      'OFFER_SELECTION_QUEUED',
    ];

    if (
      (applicationStatus && !statusesIgnore?.includes(applicationStatus)) ||
      (isPollLimitReached && applicationStatus)
    ) {
      // If a status is returned and it's a status we can take action on (or if the timer limit is reached), stop polling the application status query and setLoading to false (so loader stops spinning).
      stopPollingApplication();
      setShouldStartApplicationPollTimer(false);
      setLoading(false);

      const { route, isWizardStep } =
        getApplicationStatusRoute(applicationStatus);
      if (isWizardStep) {
        goTo(route as keyof PersonalLoanDirectSteps);
      } else {
        navigate({ to: route });
      }
    }
  }, [applicationStatus, isPollLimitReached]);

  // Watching for happy path states
  React.useEffect(() => {
    if (
      (applicationStatus === 'APPROVED' && loanStatus === 'CREATED') ||
      isPollLimitReached
    ) {
      // If the application status is APPROVED and the loan status is CREATED (or if the timer limit is reached), stop polling the application status query and setLoading to false (so loader stops spinning).
      stopPollingApplication();
      setShouldStartApplicationPollTimer(false);
      setLoading(false);

      if (loanStatus === 'CREATED' && loanId) {
        dispatch({
          type: ACTIONS.PERSONAL_LOANS_STORE_LOAN_ID,
          payload: {
            loanId,
          },
        });
        goTo('BANK_CONNECTION');
      } else {
        navigate({ to: '/direct-loan-application-error/application-received' });
      }
    }
  }, [applicationStatus, loanStatus, isPollLimitReached]);

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/hard-pull-consent');
  }, [analytics]);

  return loading ? (
    <Loader
      heading="We are submitting your application."
      content="This may take up to a minute."
    />
  ) : (
    <HardPullConsentPage onSubmit={onSubmit} />
  );
};
