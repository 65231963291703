import { Box, Flex, HM, PL } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import type { TotalNetWorthEnum } from '~/graphql/types';
import { TotalNetWorthForm } from '~/pages/onboarding/financial-suitability-wizard/forms/TotalNetWorthForm';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export interface NetWorthValues {
  suitability: {
    totalNetWorth: TotalNetWorthEnum;
  };
}

export const NetWorth = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountNetWorth) {
      return {
        suitability: {
          totalNetWorth: undefined,
        },
      };
    }
    return state.reactHookForm.openJointInvestAccountNetWorth;
  });

  const form = useForm<NetWorthValues>({
    defaultValues: submittedFormValues,
  });

  const wiz = useOpenInvestJointAccountWizardContext();

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <Flex pt={48} mb={32} flexDirection="column">
        <HM content="What’s your net worth?" />
        <PL pt={16}>
          This is the total value of everything you own (cash, investments,
          property, cars, etc.), minus everything you owe (mortgages, credit
          cards, student loans, etc.).
        </PL>
      </Flex>
      <FormProvider {...form}>
        <TotalNetWorthForm
          onSubmit={(data) => {
            dispatch(
              submitReactFormData({
                openJointInvestAccountNetWorth: {
                  suitability: {
                    totalNetWorth: data.suitability
                      .totalNetWorth as TotalNetWorthEnum,
                  },
                },
              }),
            );
            wiz.next();
          }}
        />
      </FormProvider>
    </Flex>
  );
};
