import { HXS, PL, Box } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import type { Pie, Slice } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { DragAndDropSlices } from '../DragAndDropSlices';

import { SliceableListItem } from './SliceableListItem';

const isUserPie = (slice: Slice): boolean =>
  slice.to.type === 'old_pie' ? slice.to.__typename === 'USER_PIE' : false;

const isClickablePie = (slice: Slice, isEditMode: boolean): boolean =>
  (!isEditMode && isUserPie(slice)) || slice.to.type === 'new_pie';

export type SliceablesListProps = {
  pie: Pie;
};

export const SliceablesList = ({ pie }: SliceablesListProps) => {
  const dispatch = useDispatch();

  const { addToast } = useToast();
  const [showError, setShowError] = React.useState(false);

  const isEditMode: boolean = useSelector((state) => {
    const { mode } = state.portfolioOrganizer.session;
    return mode === 'EDIT_PIE' || mode === 'EDIT_ROOT_PIE';
  });

  const handleLandableDrop = React.useCallback(
    ({ idOfDroppedItem, idOfLandableItem }: Record<string, string>) => {
      try {
        if (!pie.slices) {
          throw new Error(
            'Attempted to drop a slice from a Pie that does not contain any slices.',
          );
        }

        const landableItem = pie.slices.find(
          (slice) => slice.to.__id === idOfLandableItem,
        );

        if (!landableItem) {
          throw new Error(
            'Attempted to drop a slice into a landable item was not found.',
          );
        }

        if (landableItem.to.type !== 'new_pie') {
          throw new Error(
            'Attempted to drop a slice into a Sliceable of type other than new_pie.',
          );
        }
        const targetPieHasSliceAlready = landableItem.to.slices.some(
          (slice) => slice.to.__id === idOfDroppedItem,
        );
        if (targetPieHasSliceAlready) {
          addToast({
            content: 'Duplicate slice already exists in this Pie.',
            kind: 'alert',
            duration: 'short',
          });
        } else {
          dispatch({
            payload: {
              movedSliceIds: [idOfDroppedItem],
              destinationOntoSliceableId: idOfLandableItem,
              destinationOntoSliceableName: null,
            },
            type: 'MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE',
          });
        }
      } catch (e: any) {
        setShowError(true);
      }
    },
    [addToast, dispatch, pie.slices],
  );

  const onPieClick = React.useCallback(
    (slice: Slice) => {
      if (slice.to.type === 'old_pie' || slice.to.type === 'new_pie') {
        dispatch({
          payload: slice.to,
          type: 'CLICKED_PORTFOLIO_ORGANIZER_PIE_SLICE',
        });
      }
    },
    [dispatch],
  );

  const onSliceClick = React.useCallback(() => {
    // DO nothing for now until we can revist. The problem is,
    // if we let the user navigate away from the pie organizer without
    // saving or canceling then we open the doors to a slew of different bugs
    // related to state persisting when it shouldn't
    // if (slice.to.type !== 'new_pie') {
    //   dispatch(clickedOnSliceable(slice.to.id));
    // }
  }, []);

  if (showError) {
    return (
      <Box maxWidth={500} my={0} mx="auto" py={16} textAlign="center">
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; width: number; name: "warningTriangle"; mt: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="" width={216} name="warningTriangle" mt={32} />
        <HXS content="Uh oh" mt={16} />
        <PL
          content="There was a problem. Please try again later. If the problem persists, please contact support."
          mt={16}
        />
      </Box>
    );
  }

  if (!pie.slices) {
    return null;
  }
  const slices = pie.slices;

  return (
    <DragAndDropSlices
      allowDrag={
        !isEditMode && slices.some((slice) => slice.to.type === 'new_pie')
      }
      onClickContent={(slice: Slice) => {
        isClickablePie(slice, isEditMode) ? onPieClick(slice) : onSliceClick(); // : onSliceClick(slice);
      }}
      items={slices.map((slice) => ({
        id: slice.to.__id,
        isDisabled: false,
        isLandable: slice.to.type === 'new_pie',
        render: ({ allowDrag }) => (
          <SliceableListItem allowDrag={allowDrag} slice={slice} />
        ),
        slice: slice,
      }))}
      onLandableDrop={handleLandableDrop}
    />
  );
};
