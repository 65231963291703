import { useLocalStorage } from '@m1/liquid-react';
import * as React from 'react';
import type {
  PlaidLinkOnSuccessMetadata,
  PlaidLinkResult,
} from 'react-plaid-link';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SelectPlaidLink } from '~/components/SelectPlaidLink';
import { useNavigate } from '~/hooks/useNavigate';
import { useSentry } from '~/hooks/useSentry';

export const PlaidRedirectPage = () => {
  const navigate = useNavigate();
  const sentry = useSentry();
  const redirectUrl = useLocalStorage('redirectUrl', null);

  if (typeof redirectUrl !== 'string') {
    sentry.message(
      'User arrived at plaid-redirect without a redirectUrl',
      null,
    );
    return <GenericSystemError />;
  }

  return (
    <SelectPlaidLink
      onConnectBankFlowStep={false}
      redirectUrl={redirectUrl}
      // FIXME(Wolf): The input to `onSuccess` doesn't look right based on the type definitions.
      // @ts-expect-error We're passing a payload as part of the `onSuccess` callback, but it looks like it should be a string.
      onSuccess={(
        payload: PlaidLinkResult,
        metadata: PlaidLinkOnSuccessMetadata,
      ) => {
        const { plaidLinkPublicToken, plaidAccountId } = payload;
        sentry.message('PlaidLink succeeded.', {
          extra: {
            plaidLinkPublicToken,
            plaidAccountId,
            metadata,
          },
        });

        navigate({
          to: redirectUrl,
          options: { state: { plaidPayload: payload } },
        });
        localStorage.removeItem('link_token');
        localStorage.removeItem('redirectUrl');
      }}
      onExit={(error: any, metadata: any) => {
        if (error) {
          sentry.message('PlaidLink exited with error', {
            level: 'warning',
            extra: {
              error,
              plaidLinkTokenExists: Boolean(metadata.link_session_id),
              metadata,
            },
          });
        } else {
          sentry.message('PlaidLink exited without error', {
            extra: {
              metadata,
            },
          });
        }

        navigate({
          to: redirectUrl,
          options: { state: { plaidFailure: true } },
        });

        localStorage.removeItem('link_token');
        localStorage.removeItem('redirectUrl');
      }}
    />
  );
};
