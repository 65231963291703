import type { SagaIterator } from 'redux-saga';

import { BootstrapAuthedUserDocument } from '~/graphql/hooks';
import type { BootstrapAuthedUserQuery } from '~/graphql/types';

import { apolloQuerySaga } from '../apolloQuerySaga';
import {
  getAnalyticsReporter,
  getLaunchDarkly,
  getSentryReporter,
} from '../common/getLoggersSaga';
import { call, put } from '../effects';

import { initializeUserData } from './initializeUserData';
import { selectInitialAccounts } from './selectInitialAccounts';

/*
  Called whenever a user's identity is known, e.g. after login, registration,
  reset password flow, etc.
*/

export function* bootstrapAuthedUser(): SagaIterator<void> {
  const response: BootstrapAuthedUserQuery | null | undefined = yield call(
    fetchBootstrapAuthedUserQuery,
  );
  if (response) {
    yield call(initializeSentryReporter, response);
    yield call(identifyUserForAnalytics, response);
    yield call(selectInitialAccounts, response);
    yield call(initializeUserData, response);
    yield call(identifyM1Employees, response);
    yield call(identifyUserForLaunchDarkly, response);
  }
}

export function* fetchBootstrapAuthedUserQuery(): SagaIterator<
  BootstrapAuthedUserQuery | null | undefined
> {
  const sentry = yield call(getSentryReporter);
  try {
    const { data } = yield call(apolloQuerySaga, {
      query: BootstrapAuthedUserDocument,
    });
    return data;
  } catch (e: any) {
    sentry.message('Error fetching bootstrapAuthedUserQuery.', {
      rawError: e,
    });
  }

  return null;
}

/*
  Set user context on our Sentry client. This data then appears in Sentry
  error reports, aiding in triage.
*/
export function* initializeSentryReporter({
  viewer,
}: BootstrapAuthedUserQuery): SagaIterator<void> {
  const sentry = yield call(getSentryReporter);

  const context = {
    correlationKey: viewer.user?.correlationKey,
    id: viewer.id,
  };

  sentry.setUserContext(context);
}

/*
  Identifies a user to our analytics clients. Allows more detailed analysis.
*/
export function* identifyUserForAnalytics({
  viewer,
}: BootstrapAuthedUserQuery): SagaIterator<void> {
  const analytics = yield call(getAnalyticsReporter);
  if (viewer.user?.correlationKey) {
    analytics.identifyUser(viewer.user.correlationKey);
  }
}

export function* identifyM1Employees({
  viewer,
}: BootstrapAuthedUserQuery): SagaIterator<void> {
  return yield put({
    type: 'SET_IS_M1_EMPLOYEE',
    payload: viewer.featureFlags?.hasFeature[0] ?? false,
  });
}

export function* identifyUserForLaunchDarkly({
  viewer,
}: BootstrapAuthedUserQuery): SagaIterator<void> {
  const launchDarkly = yield call(getLaunchDarkly);

  if (viewer.user) {
    launchDarkly.identifyUser(viewer.user.correlationKey, viewer.user.username);
  }
}
