import { Box, Flex, PL, PM, PS, PXL, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import type { NewSmartTransferFocusOptionSetFragment } from '~/graphql/types';

type SmartTransferFocusOptionCardsProps = {
  newSmartTransferFocusOptionSet: NewSmartTransferFocusOptionSetFragment;
  onFocusOptionSelect: (focusOptionId: string) => void;
};

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  grid-template-columns: 309px 309px;
  grid-template-rows: 184px 184px;
`;

const StyledCard = styled(Card)<{
  $isEnabled: boolean;
}>`
  height: 100%;
  padding: 16px 12px;
  transition: background-color 0.25s linear;
  ${(props) => {
    if (props.$isEnabled) {
      return `
        cursor: pointer;
        &:hover {
          background-color: ${props.theme.colors.backgroundNeutralTertiary};
        }
      `;
    }
    return `
      border: 1px solid ${props.theme.colors.foregroundNeutralTertiary};
      box-shadow: none;
      pointer-events: none;
    `;
  }};
`;

export const SmartTransferFocusOptionCards = ({
  newSmartTransferFocusOptionSet,
  onFocusOptionSelect,
}: SmartTransferFocusOptionCardsProps) => {
  const onClickFocusOption = React.useCallback(
    (focusOptionId: string) => {
      onFocusOptionSelect(focusOptionId);
    },
    [onFocusOptionSelect],
  );
  const options = newSmartTransferFocusOptionSet.options.filter(Boolean);
  if (options.length < 1) {
    throw new Error('No options available, cannot render a sensical UI.');
  }
  return (
    <StyledGrid>
      {options.map(
        (option) =>
          option && (
            <Box key={option.id}>
              <StyledCard
                isElevated
                backgroundColor="backgroundNeutralSecondary"
                onClick={() => {
                  onClickFocusOption(option.id);
                }}
                $isEnabled={option.isEnabled}
              >
                <Flex justifyContent="space-between">
                  <PXL
                    fontWeight={600}
                    color={
                      option.isEnabled
                        ? 'foregroundNeutralMain'
                        : 'foregroundNeutralTertiary'
                    }
                    content={option.name}
                  />

                  {option.utilizationSummary && (
                    <PM color="success" content={option.utilizationSummary} />
                  )}
                </Flex>
                <PM
                  alignSelf="start"
                  color={
                    option.isEnabled
                      ? 'foregroundNeutralMain'
                      : 'foregroundNeutralTertiary'
                  }
                  content={option.description}
                  mt={4}
                />

                {option.disabledReasonDescription && (
                  <Card
                    borderColor="transparent"
                    backgroundColor="backgroundNeutralTertiary"
                    p="12px 16px"
                    mt={8}
                  >
                    <PS
                      color="foregroundNeutralMain"
                      content={option.disabledReasonDescription}
                    />
                  </Card>
                )}
              </StyledCard>
            </Box>
          ),
      )}
      <PL
        width="max-content"
        content={newSmartTransferFocusOptionSet.optionsFooter}
      />
    </StyledGrid>
  );
};
