import * as React from 'react';

import { TransferInstanceAmountText } from '~/components/transfers/TransferInstanceAmountText';
import { TransferInstanceStatusPill } from '~/components/transfers/TransferInstanceStatusPill';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import type { PendingInstancesFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export const TransfersPendingInstancesTable = ({
  pendingInstances,
}: {
  pendingInstances: PendingInstancesFragment['pendingInstances'];
}) => {
  return (
    <GridTable
      emptyMessage={
        pendingInstances
          ? 'You have no currently pending transfers.'
          : 'Error fetching your pending transfers. Please try again later.'
      }
      gridTemplateColumns="280px 280px 160px 80px auto"
      isNewStyle
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="From" />
        <GridTable.HeaderCell label="To" />
        <GridTable.HeaderCell label="Type" />
        <GridTable.HeaderCell label="Details" />
        <GridTable.HeaderCell justifySelf="end" label="Amount" />
      </GridTable.HeaderRow>
      {pendingInstances &&
        pendingInstances.map((instance) => {
          if (!instance) {
            return null;
          }
          let transferType = 'One-time transfer';
          if (instance.isCheck) {
            transferType = 'One-time check';
          }
          if (instance.isCreditCardPayment) {
            transferType = 'One-time payment';
          }
          if (instance.isCreditCardAutoPayInstance) {
            transferType = 'AutoPay';
          }
          if (instance.iraDistributionReason === 'CONVERSION') {
            transferType = 'IRA conversion';
          }
          if (instance.iraDistributionReason === 'RECHARACTERIZATION') {
            transferType = 'IRA recharacterization';
          }
          return (
            <GridTable.NavigableRow
              key={instance.id}
              params={{
                transferInstanceId: instance.id,
              }}
              to="/d/c/transfer-details/:transferInstanceId"
            >
              <GridTable.Cell
                content={
                  instance.from ? (
                    <TransferParticipantCell
                      transferParticipant={instance.from}
                    />
                  ) : (
                    '--'
                  )
                }
              />
              <GridTable.Cell
                content={
                  instance.to ? (
                    <TransferParticipantCell
                      transferParticipant={instance.to}
                    />
                  ) : (
                    '--'
                  )
                }
              />
              <GridTable.Cell content={transferType} />
              <GridTable.Cell
                content={
                  <TransferInstanceStatusPill
                    onlyShowIf={{
                      isLiquidation: true,
                      isPending: true,
                      isPendingCancel: true,
                    }}
                    transferInstance={instance}
                  />
                }
              />
              <GridTable.Cell
                content={
                  <TransferInstanceAmountText
                    transferInstance={instance}
                    emphasizeFailureState={false}
                  />
                }
                justifySelf="end"
              />
            </GridTable.NavigableRow>
          );
        })}
    </GridTable>
  );
};
