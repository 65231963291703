import type {
  NewBeneficiaryInput,
  OnboardingIdentityFirstQuestionType,
  OnboardingIdentityFirstSectionType,
  ProfileInput,
  SuitabilityProfileInput,
  TrustedContactInput,
  UpdateAccountHolderInput,
} from '~/graphql/types';

export const IDENTITY_FIRST_ACTIONS = Object.freeze({
  BEGIN_ACCOUNT_SETUP_FLOW_MODERN: 'BEGIN_ACCOUNT_SETUP_FLOW_MODERN' as const,
  CHANGE_ACCOUNT_SETUP_FLOW_STEP_MODERN:
    'CHANGE_ACCOUNT_SETUP_FLOW_STEP_MODERN' as const,
  CLICKED_CANCEL_ACCOUNT_SETUP_MODERN:
    'CLICKED_CANCEL_ACCOUNT_SETUP_MODERN' as const,
  COLLECTED_TRUSTED_CONTACT: 'COLLECTED_TRUSTED_CONTACT' as const,
  ACCOUNT_SETUP_FLOW_CANCELED_MODERN:
    'ACCOUNT_SETUP_FLOW_CANCELED_MODERN' as const,
  ACCOUNT_SETUP_FLOW_FINISHED_MODERN:
    'ACCOUNT_SETUP_FLOW_FINISHED_MODERN' as const,
  IDENTITY_FIRST_FLOW_FINISHED: 'IDENTITY_FIRST_FLOW_FINISHED' as const,
  SET_IDENTITY_FIRST_ONBOARDING_QUESTIONS:
    'SET_IDENTITY_FIRST_ONBOARDING_QUESTIONS' as const,
  SET_INITIAL_USER_PROFILE_INPUT: 'SET_INITIAL_USER_PROFILE_INPUT' as const,
  SELECTED_ACCOUNT_TYPE_MODERN: 'SELECTED_ACCOUNT_TYPE_MODERN' as const,
  SELECTED_CUSTODIAL_ACCOUNT_TYPE_MODERN:
    'SELECTED_CUSTODIAL_ACCOUNT_TYPE_MODERN' as const,
  SELECTED_RETIREMENT_ACCOUNT_TYPE_MODERN:
    'SELECTED_RETIREMENT_ACCOUNT_TYPE_MODERN' as const,
  SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE_MODERN:
    'SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE_MODERN' as const,
  SELECTED_ROLLOVER_ACCOUNT_TYPE_MODERN:
    'SELECTED_ROLLOVER_ACCOUNT_TYPE_MODERN' as const,
  SELECTED_OTHER_ACCOUNT_TYPE_MODERN:
    'SELECTED_OTHER_ACCOUNT_TYPE_MODERN' as const,
  SUBMITTED_CONTACT_INFO_FORM_MODERN:
    'SUBMITTED_CONTACT_INFO_FORM_MODERN' as const,
  SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN:
    'SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN' as const,
  SUBMITTED_IDENTITY_INFO_FORM_MODERN:
    'SUBMITTED_IDENTITY_INFO_FORM_MODERN' as const,
  SUBMITTED_ANNUAL_INCOME_FORM: 'SUBMITTED_ANNUAL_INCOME_FORM' as const,
  SUBMITTED_LIQUID_NET_WORTH_FORM: 'SUBMITTED_LIQUID_NET_WORTH_FORM' as const,
  SUBMITTED_DISCLOSURES_FORM_MODERN:
    'SUBMITTED_DISCLOSURES_FORM_MODERN' as const,
  COLLECTED_HOLDER_SSN_MODERN: 'COLLECTED_HOLDER_SSN_MODERN' as const,
  SUBMITTED_CONFIRM_PROFILE: 'SUBMITTED_CONFIRM_PROFILE' as const,
  EXPLAINED_PROFILE_COLLECTION_MODERN:
    'EXPLAINED_PROFILE_COLLECTION_MODERN' as const,
  SUBMITTED_PROFILE_INPUT_MODERN: 'SUBMITTED_PROFILE_INPUT_MODERN' as const,
  SUBMITTED_EMPLOYMENT_INFO_MODERN: 'SUBMITTED_EMPLOYMENT_INFO_MODERN' as const,
  SUBMITTED_INTRODUCTION_SOURCE_MODERN:
    'SUBMITTED_INTRODUCTION_SOURCE_MODERN' as const,
  SET_SELECTED_ROOT_PIE: 'SET_SELECTED_ROOT_PIE' as const,
  FINISHED_ACCOUNT_SETUP_REVIEW_MODERN:
    'FINISHED_ACCOUNT_SETUP_REVIEW_MODERN' as const,
  FINISHED_READING_ROLLOVER_RECEIPT_MODERN:
    'FINISHED_READING_ROLLOVER_RECEIPT_MODERN' as const,
  FINISHED_READING_OTHER_ACCOUNT_RECEIPT_MODERN:
    'FINISHED_READING_OTHER_ACCOUNT_RECEIPT_MODERN' as const,
});

export type EmploymentInfoForm = {
  employment: {
    status: string;
    employer?: string | null;
    occupation?: string | null;
  };
};

export type IdentityInfoForm = {
  countryOfCitizenship: string;
  dateOfBirth: string;
  employment?: {
    status: string;
    employer?: string | null;
    occupation?: string | null;
  };
};

type ContactInfoForm = {
  firstName: string;
  homeAddress: {
    city: string;
    lineOne: string;
    lineTwo: string;
    postalCode: string;
    stateOrProvince: string;
  };
  lastName: string;
};

export type UserProfileInput = {
  primary: Partial<UpdateAccountHolderInput>;
  primarySsn?: string;
  secondary?: Partial<UpdateAccountHolderInput>;
  suitability?: Partial<SuitabilityProfileInput>;
  beneficiaries?: Partial<NewBeneficiaryInput[]>;
  allowsHomeAddressUpdates?: Partial<boolean>;
  trustedContact?: Partial<TrustedContactInput>;
};

export type IdentityFirstOnboardingQuestion = {
  questionType: OnboardingIdentityFirstQuestionType | null;
  title: string | null;
  subtitle: string | null;
  section: OnboardingIdentityFirstSectionType | null;
  continueLabel: string | null;
  skipLabel: string | null;
  disclaimerTooltipContent: ReadonlyArray<string | null> | null;
  previousQuestionType: OnboardingIdentityFirstQuestionType | null;
  nextQuestionType: OnboardingIdentityFirstQuestionType | null;
};

export type SetIdentityFirstOnboardingQuestions = {
  payload: Array<IdentityFirstOnboardingQuestion | null>;
  type: typeof IDENTITY_FIRST_ACTIONS.SET_IDENTITY_FIRST_ONBOARDING_QUESTIONS;
};

export type IdentityFirstFlowFinished = {
  payload: UserProfileInput;
  type: typeof IDENTITY_FIRST_ACTIONS.IDENTITY_FIRST_FLOW_FINISHED;
};

export type SetInitialUserProfileInput = {
  type: typeof IDENTITY_FIRST_ACTIONS.SET_INITIAL_USER_PROFILE_INPUT;
  payload: UserProfileInput;
};

export type SubmittedDisclosuresFormModern = {
  payload: {
    holder: string;
    values: Partial<ProfileInput>;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_DISCLOSURES_FORM_MODERN;
};

export type SubmittedIdentityInfoFormModern = {
  payload: {
    holder: string;
    values: {
      primary?: IdentityInfoForm;
      secondary?: IdentityInfoForm;
    };
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_IDENTITY_INFO_FORM_MODERN;
};

export type SubmittedEmploymentInfoModern = {
  payload: {
    holder: 'primary' | 'secondary';
    values: {
      primary?: EmploymentInfoForm;
      secondary?: EmploymentInfoForm;
    };
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_EMPLOYMENT_INFO_MODERN;
};

export type SubmittedContactInfoFormModern = {
  payload: {
    holder: string;
    values: {
      primary?: ContactInfoForm;
      secondary?: ContactInfoForm;
    };
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_CONTACT_INFO_FORM_MODERN;
};

export type CollectedHolderSsnModern = {
  payload: {
    holder: string;
    ssn: Record<string, any>;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.COLLECTED_HOLDER_SSN_MODERN;
};

export type SubmittedConfirmProfile = {
  payload: {
    holder: string;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_CONFIRM_PROFILE;
};

export type SubmittedProfileInputActionModern = {
  payload: {
    field: string;
    value: number | string;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_PROFILE_INPUT_MODERN;
};

export type SubmittedAnnualIncome = {
  payload: {
    field: string;
    value: number | string;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_ANNUAL_INCOME_FORM;
};

export type SubmittedLiquidNetWorth = {
  payload: {
    field: string;
    value: number | string;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_LIQUID_NET_WORTH_FORM;
};

export type ExplainedProfileCollectionModern = {
  payload: any;
  type: typeof IDENTITY_FIRST_ACTIONS.EXPLAINED_PROFILE_COLLECTION_MODERN;
};

export type CollectedTrustedContactAction = {
  payload: Partial<ProfileInput>;
  type: typeof IDENTITY_FIRST_ACTIONS.COLLECTED_TRUSTED_CONTACT;
};

export type SetSelectedRootPie = {
  payload: string;
  type: typeof IDENTITY_FIRST_ACTIONS.SET_SELECTED_ROOT_PIE;
};

export type FinishedAccountSetupReviewActionModern = {
  payload: {
    isCustodialAccount?: boolean;
    signature: string;
  };
  type: typeof IDENTITY_FIRST_ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW_MODERN;
};

export type BeginAccountSetupFlowModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW_MODERN;
};

export type ChangeAccountSetupFlowStepModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.CHANGE_ACCOUNT_SETUP_FLOW_STEP_MODERN;
};

export type ClickedCancelAccountSetupModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.CLICKED_CANCEL_ACCOUNT_SETUP_MODERN;
};

export type AccountSetupFlowCanceledModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.ACCOUNT_SETUP_FLOW_CANCELED_MODERN;
};

export type AccountSetupFlowFinishedModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED_MODERN;
};

export type SelectedAccountTypeModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SELECTED_ACCOUNT_TYPE_MODERN;
};

export type SelectedRetirementAccountTypeModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE_MODERN;
};

export type SelectedIndividualRetirementAccountTypeModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE_MODERN;
};

export type SelectedRolloverAccountTypeModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE_MODERN;
};

export type SelectedOtherAccountTypeModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE_MODERN;
};

export type SubmittedCustodialContactInfoFormModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN;
};

export type SubmittedIntroductionSourceModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.SUBMITTED_INTRODUCTION_SOURCE_MODERN;
};

export type FinishedAccountSetupReviewModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW_MODERN;
};

export type FinishedReadingRolloverReceiptModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.FINISHED_READING_ROLLOVER_RECEIPT_MODERN;
};

export type FinishedReadingOtherAccountReceiptModernAction = {
  payload: unknown;
  type: typeof IDENTITY_FIRST_ACTIONS.FINISHED_READING_OTHER_ACCOUNT_RECEIPT_MODERN;
};

export type IdentityFirstAction =
  | AccountSetupFlowCanceledModernAction
  | AccountSetupFlowFinishedModernAction
  | BeginAccountSetupFlowModernAction
  | ChangeAccountSetupFlowStepModernAction
  | ClickedCancelAccountSetupModernAction
  | CollectedHolderSsnModern
  | CollectedTrustedContactAction
  | ExplainedProfileCollectionModern
  | FinishedAccountSetupReviewActionModern
  | FinishedAccountSetupReviewModernAction
  | FinishedReadingOtherAccountReceiptModernAction
  | FinishedReadingRolloverReceiptModernAction
  | IdentityFirstFlowFinished
  | SetIdentityFirstOnboardingQuestions
  | SetInitialUserProfileInput
  | SetSelectedRootPie
  | SelectedAccountTypeModernAction
  | SelectedRetirementAccountTypeModernAction
  | SelectedIndividualRetirementAccountTypeModernAction
  | SelectedRolloverAccountTypeModernAction
  | SelectedOtherAccountTypeModernAction
  | SubmittedCustodialContactInfoFormModernAction
  | SubmittedDisclosuresFormModern
  | SubmittedEmploymentInfoModern
  | SubmittedIntroductionSourceModernAction
  | SubmittedIdentityInfoFormModern
  | SubmittedContactInfoFormModern
  | SubmittedProfileInputActionModern
  | SubmittedConfirmProfile
  | SubmittedAnnualIncome
  | SubmittedLiquidNetWorth;
