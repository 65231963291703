import orderBy from 'lodash-es/orderBy';
import React from 'react';

import type { SortDirectionEnum } from '~/graphql/types';

type GetValueFunction<T> = ((listItem: T) => any) | undefined;

type Defaults<T> = {
  path?: string;
  direction?: SortDirectionEnum;
  getValue?: GetValueFunction<T>;
};

export const useSortTable = <T>({
  list,
  defaults,
}: {
  list: T[];
  defaults?: Defaults<T>;
}) => {
  const [activeSortDirection, setSortDirection] =
    React.useState<SortDirectionEnum>(defaults?.direction ?? 'DESC');
  /*
   * The path helps us identify the column that is currently being sorted
   * It also can be used as an iteratee if no getValue function is provided
   * For example if the path is "address.firstLine" then this hook will try
   * and sort by row.address.firstLine
   */
  const [activePath, setPath] = React.useState<string | undefined>(
    defaults?.path,
  );

  /*
   * If a getValue function is provided, it will be used to get a
   * value from a row for sorting (instead of the path).
   * Note that we need to return a function that returns the getValue function,
   * otherwise the stored function will be invoked when it is set.
   */
  const [getValue, setGetValueFunction] = React.useState<
    () => GetValueFunction<T>
  >(() => defaults?.getValue);

  const [sortedList, setSortedList] = React.useState<T[]>([]);

  /*
   * This function is called when a user clicks on a column header to
   * sort the table.
   */
  const onChangeSort = (
    path: string | undefined,
    getValue: GetValueFunction<T>,
  ) => {
    if (path === activePath) {
      setSortDirection(activeSortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      // When we change the column, we default to descending order
      setPath(path);
      setSortDirection('DESC');
      setGetValueFunction(() => getValue);
    }
  };

  /*
   * This function returns props that can be used by the
   * GridTableHeaderCell component.
   */
  const createHeaderSortProps = ({
    path,
    getValue,
  }: {
    path: string | undefined;
    getValue?: GetValueFunction<T>;
  }) => ({
    sortDirection: path === activePath ? activeSortDirection : null,
    onClick: () => onChangeSort(path, getValue),
  });

  React.useEffect(() => {
    const formattedSortDirection = activeSortDirection?.toLowerCase() as
      | undefined
      | 'asc'
      | 'desc';

    /*
     * The lodash "orderBy" function can take either a string or function type "iteratee"
     *  - The iteratee is used to get the value on a row
     *  - For example:
     *  - An iteratee of "lastName" would select row.lastName
     *  - An iteratee of "address.firstLine" would select row.address.firstLine
     *  - An iteratee of (row) => moment(row.startDate).format('YYYY-MM-DD') would select
     *    row.startDate and turn it into a date string for sorting
     *
     * The value function is helpful for formatting a value for proper sorting.
     */
    const iteratee = getValue ?? activePath;
    const result = orderBy(list, iteratee, formattedSortDirection);
    setSortedList(result);
  }, [activeSortDirection, activePath, list, getValue]);

  return {
    createHeaderSortProps,
    sortedList,
  };
};
