import {
  styled,
  HL,
  HS,
  OneColumnConstrained,
  Flex,
  ProductCard,
  theme,
  Box,
  Button,
} from '@m1/liquid-react';
import type { IllustrationsType } from '@m1/liquid-react/illustrations';
import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import type {
  AnnouncementContextEnum,
  AnnouncementFragment,
  AppLink,
  AppRichTextDisclosureLinkFragment,
  EarnMarketingScreen,
  ProductMarketingCard,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLayout } from '~/hooks/useLayout';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { Link } from '~/toolbox/link';

import { LinkableButton } from '../../lens-toolbox/LinkableButton';
import { AnnouncementContext } from '../announcement';
import { Footer } from '../Footer';

const StyledLink = styled(Link)`
  padding: 12px 8px;
  font-size: 14px;
  white-space: nowrap;
  color: ${theme.colors.foregroundPrimary};
  justify-content: center;
  span {
    width: 100%;
  }
`;

const CardsContainer = styled(Flex)`
  justify-content: center;
  gap: 24px;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 64px;
  }
`;

const TemplateContainer = styled(Flex)`
  margin-top: 90px;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;

  #desktopContent {
    display: none;
  }

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    margin-top: 56px;

    #mobileContent {
      display: none;
    }

    #desktopContent {
      display: inherit;
  }
`;

const StyledLinkableButton = styled(LinkableButton)`
  font-size: 14px;
`;

const BannerContainer = styled(Box)`
  margin-top: 50px;
  margin-bottom: 32px;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    margin-top: 0;
  }
`;

type MarketingScreenTemplateProps = {
  announcement?: AnnouncementFragment | null;
  children?: React.ReactNode;
  context?: AnnouncementContextEnum;
  disclosures?: Array<Maybe<AppRichTextDisclosureLinkFragment>> | null;
  disclosureLinks?: Array<Maybe<AppLink>> | null;
  footerLink?: AppLink | null;
  handleToggleModal?: () => void;
  productMarketingCards?: Array<Maybe<ProductMarketingCard>> | null;
  title?: EarnMarketingScreen['header'];
};

export const MarketingScreenTemplate = ({
  announcement,
  children,
  context,
  disclosures,
  disclosureLinks,
  footerLink,
  handleToggleModal,
  productMarketingCards,
  title,
}: MarketingScreenTemplateProps) => {
  const analytics = useAnalytics();
  const [containerHeight, setContainerHeight] = useState(0);
  const ref = useRef(null);
  const isSmallDevice = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.SMALL})`,
  });
  const { contentWidth } = useLayout();
  useEffect(() => {
    if (ref.current && !isSmallDevice) {
      setContainerHeight((ref.current as HTMLElement)?.clientHeight);
    }

    if (isSmallDevice) {
      setContainerHeight(0);
    }
  }, [isSmallDevice]);

  return (
    <TemplateContainer>
      <StyledPageContent height="100%" contentWidth={contentWidth}>
        {announcement && context && (
          <BannerContainer>
            <AnnouncementContext
              announcement={announcement}
              context={context}
            />
          </BannerContainer>
        )}
        <OneColumnConstrained>
          <HL
            id="desktopContent"
            fontWeight={300}
            textAlign="center"
            content={title}
          />
          <HS
            id="mobileContent"
            fontWeight={300}
            textAlign="center"
            content={title}
          />
          <CardsContainer
            mt={64}
            ref={ref}
            height={containerHeight > 0 ? containerHeight : ''}
          >
            {productMarketingCards?.map((card, index) => {
              if (card?.analyticsEvent) {
                analytics.recordAppAnalyticsEvent(card.analyticsEvent);
              }
              return (
                <ProductCard
                  key={index}
                  illustrationName={
                    (card?.illustration?.names?.[0] ??
                      'warningTriangle') as IllustrationsType
                  }
                  title={card?.title || undefined}
                  description={card?.subtitle || undefined}
                >
                  <Flex flexDirection="column" alignContent="center">
                    {card?.primaryCtaButton &&
                      (card.primaryCtaButton.url.includes(
                        'selectMarginAccount=true',
                      ) ? (
                        <Button
                          kind="primary"
                          label={card?.primaryCtaButton?.title}
                          size="medium"
                          onClick={handleToggleModal}
                        />
                      ) : (
                        <StyledLinkableButton
                          linkable={card?.primaryCtaButton}
                        />
                      ))}
                    {card?.secondaryCtaButton && (
                      <StyledLink
                        to={card?.secondaryCtaButton?.url}
                        kind="secondary"
                        target="_blank"
                      >
                        {card.secondaryCtaButton.title}
                      </StyledLink>
                    )}
                  </Flex>
                </ProductCard>
              );
            })}
          </CardsContainer>
          {children && children}
        </OneColumnConstrained>
      </StyledPageContent>
      <Footer
        disclosureLinks={disclosureLinks}
        footerLink={footerLink}
        footerDisclosures={disclosures}
      />
    </TemplateContainer>
  );
};
