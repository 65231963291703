import { Button } from '@m1/liquid-react';
import React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const PaymentViewDetailsButton = ({
  isAutoPay,
  paymentId,
}: {
  isAutoPay: boolean;
  paymentId: string;
}) => {
  const navigate = useNavigate();

  const ruleDetailsRoute = '/d/rule-details/:transferRuleId';

  if (isAutoPay) {
    return (
      <Button
        kind="secondary"
        onClick={() => {
          navigate({
            to: ruleDetailsRoute,
            params: {
              transferRuleId: paymentId,
            },
            query: { previousRouteName: 'transfer-created' },
          });
        }}
        size="large"
      >
        View AutoPay details
      </Button>
    );
  }
  return (
    <Button
      kind="secondary"
      onClick={() => {
        navigate({
          to: '/d/c/transfer-details/:transferInstanceId',
          params: { transferInstanceId: paymentId },
        });
      }}
      size="large"
    >
      View payment details
    </Button>
  );
};
