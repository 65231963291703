import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { DisclosuresAndAgreementsPage } from './DisclosuresAndAgreementsPage/DisclosuresAndAgreementsPage';
import { FullyPaidLendingSettingsPage } from './FullyPaidLendingSettingsPage/FullyPaidLendingSettingsPage';
import { SettingsAccountsLayout } from './SettingsAccountsLayout';
import { SettingsAccountsPage } from './SettingsAccountsPage';

export const SettingsAccountsRoute = () => {
  return (
    <Route path="accounts" element={<SettingsAccountsLayout />}>
      <Route index element={<Navigate to="details" replace />} />
      <Route path="details" element={<SettingsAccountsPage />} />
      <Route
        path="fully-paid-lending"
        element={<FullyPaidLendingSettingsPage />}
      />
      <Route
        path="disclosures-and-agreements"
        element={<DisclosuresAndAgreementsPage />}
      />
    </Route>
  );
};
