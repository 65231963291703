import { useEffect, useRef } from 'react';

const useInViewAction = (
  action: () => void,
  options?: IntersectionObserverInit,
) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const hasFiredRef = useRef(false);

  useEffect(() => {
    if (!elementRef.current || hasFiredRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasFiredRef.current) {
          action();
          hasFiredRef.current = true;
          observer.disconnect(); // Stop observing after firing
        }
      },
      {
        root: options?.root || null,
        rootMargin: options?.rootMargin || '0px',
        threshold: options?.threshold || 0.1,
      },
    );

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [action, options]);

  return elementRef;
};

export { useInViewAction };
