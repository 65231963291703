import * as React from 'react';
import { type Path, useFormContext } from 'react-hook-form';

import { postalCode } from '~/forms/validators';

import { ControlledInput } from '../ControlledInput';

import type { AddressFormFields, AddressSectionProps } from './types';

const FIELD_NAME = 'postalCode';

export const PostalCodeInput = <NS extends string = ''>({
  backgroundColor,
  disabled,
  namespace,
  label,
}: AddressSectionProps<NS>) => {
  const { control } = useFormContext<AddressFormFields<NS>>();
  const name = (
    namespace ? `${namespace as NS}.${FIELD_NAME}` : FIELD_NAME
  ) as Path<AddressFormFields<NS>>;

  return (
    <ControlledInput
      control={control}
      name={name}
      rules={{
        required: 'Required',
        maxLength: 5,
        validate: {
          postalCode: (value) => typeof value === 'string' && postalCode(value),
        },
      }}
      backgroundColor={backgroundColor}
      label={label ?? 'Postal code'}
      maxLength={5}
      disabled={disabled}
    />
  );
};
