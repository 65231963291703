import * as React from 'react';

import { useAnalytics } from './useAnalytics';
import { useNavigate } from './useNavigate';
import { useSentry } from './useSentry';

const CAPITALIZE_REGEX = /^https:\/\/[-a-z]+\.hicapitalize.com/i;

export const useCapitalizeIframe = (): {
  hasCapitalizeError: boolean;
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>;
  handleIframeLoad: () => void;
} => {
  const [hasLoadedIframe, setHasLoadedIframe] = React.useState(false);
  const [hasCapitalizeError, setHasCapitalizeError] = React.useState(false);
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const sentry = useSentry();
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  const handleIframeLoad = React.useCallback(() => {
    setHasLoadedIframe(true);
  }, []);

  React.useEffect(() => {
    const recordCapitalizeEvent = (params: Record<string, string>) => {
      analytics.recordEvent('m1_rollover_capitalize', null, params);
    };

    const handleWebviewMessage = (event: MessageEvent) => {
      // Ensure the message is coming from the expected origin
      if (CAPITALIZE_REGEX.test(event.origin)) {
        // Capitalize events: https://developer.hicapitalize.com/RolloverAPIReference/webviews/#communicate-with-the-webview-in-your-application
        // Password in 1Password
        if (event.data.onError) {
          setHasCapitalizeError(true);
          sentry.message('Capitalize SDK error', {
            message: event.data.onError,
          });
          analytics.recordEvent('m1_rollover_capitalize_error', null, {
            message: event.data.onError,
          });
        }
        if (event.data.onCreateTradIra) {
          recordCapitalizeEvent({
            event: 'TradIRANeeded',
          });
        }
        if (event.data.onCreateRothIra) {
          recordCapitalizeEvent({
            event: 'RothIRANeeded',
          });
        }
        if (event.data.onCreateTradIra || event.data.onCreateRothIra) {
          navigate({ to: '/d/invest' });
        }
        if (event.data.onCapitalizeExit) {
          recordCapitalizeEvent({
            event: 'onCapitalizeExit',
          });
          navigate({ to: '/d/home' });
        }
        if (event.data.onCapitalize401kStepComplete) {
          recordCapitalizeEvent({
            event: 'transaction_created_401kStepComplete',
          });
        }
        if (event.data.onCapitalizeRolloverSubmitted) {
          recordCapitalizeEvent({
            event: 'onCapitalizeRolloverSubmitted',
          });
        }
      }
    };

    if (iframeRef.current && hasLoadedIframe) {
      window.addEventListener('message', handleWebviewMessage);
      return () => {
        window.removeEventListener('message', handleWebviewMessage);
      };
    }
  }, [hasLoadedIframe, analytics, navigate, sentry]);

  return { hasCapitalizeError, iframeRef, handleIframeLoad };
};
