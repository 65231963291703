import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  PL,
} from '@m1/liquid-react';
import * as React from 'react';

import type { InvestBankConnectionAccountNodeFragment } from '~/graphql/types';
import { useCancelAchRelationship } from '~/hooks/useCancelAchRelationship';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

export const ChangeBankLink = ({
  achRelationship,
}: {
  achRelationship:
    | InvestBankConnectionAccountNodeFragment['lastAchRelationship']
    | null
    | undefined;
}) => {
  const cancelAchRelationship = useCancelAchRelationship();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const openModal = React.useCallback(() => setIsModalOpen(true), []);
  const hideModal = React.useCallback(() => setIsModalOpen(false), []);

  const handleChangeBank = () => {
    if (achRelationship) {
      cancelAchRelationship({ achRelationshipId: achRelationship.id });
    }
    hideModal();
  };
  if (!achRelationship || achRelationship.status === 'REJECTED') {
    return null;
  }

  return (
    <Box mt={4} mb={56} textAlign="center">
      <Button kind="link" onClick={openModal} label="Change" />

      <Modal open={isModalOpen} onClose={hideModal}>
        <ModalContent>
          <ModalTitle>Change bank</ModalTitle>
          <PL
            content="Any schedules using this bank will be deleted. You can connect a new bank after removing this one. Are you sure you want to remove your existing bank?"
            mb={32}
          />
          <ButtonGroup>
            <Button
              kind="secondary"
              size="medium"
              label="Cancel"
              onClick={hideModal}
            />
            <Button
              kind="primary"
              size="medium"
              label="Confirm"
              onClick={handleChangeBank}
            />
          </ButtonGroup>
        </ModalContent>
      </Modal>
    </Box>
  );
};
