import type { SagaIterator } from 'redux-saga';
import { call, put, race, take } from 'redux-saga/effects';

import {
  InvestPortfolioDetailsHeaderDocument,
  SetPortfolioSliceOrderDocument,
} from '~/graphql/hooks';
import type { SetPortfolioSliceOrderInput } from '~/graphql/types';

import {
  ACTION_TYPES as ACTIONS,
  hideLoadingSpinner,
  hideModal,
  showLoadingSpinner,
  showModal,
} from '~/redux/actions';

import type { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../apolloMutationSaga';
import { apolloRefetchSaga } from '../apolloQuerySaga';

export function* clickedCancelOrder(
  portfolioSliceId: string,
): SagaIterator<void> {
  yield put(showModal('ORDER_ACTION_CANCEL', portfolioSliceId));

  const { confirm } = yield race({
    cancel: take(ACTIONS.HIDE_MODAL),
    confirm: take(ACTIONS.CONFIRM_CANCEL_ORDER),
  });

  yield put(hideModal('ORDER_ACTION_CANCEL'));
  if (confirm) {
    try {
      yield put(showLoadingSpinner());
      yield call(apolloMutationSaga, {
        mutation: SetPortfolioSliceOrderDocument,
        variables: {
          input: {
            portfolioSliceId,
            cashFlow: 0,
          } satisfies SetPortfolioSliceOrderInput,
        },
      });
      // refetch for updated buying power values.
      yield call(apolloRefetchSaga, [InvestPortfolioDetailsHeaderDocument]);

      yield put({
        payload: {
          content: 'Order canceled.',
          duration: 'long',
          kind: 'success',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    } catch (e: any) {
      yield put({
        payload: {
          content: e.message,
          duration: 'short',
          kind: 'alert',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    } finally {
      yield put(hideLoadingSpinner());
    }
  }
}
