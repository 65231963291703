import { Box, Flex, PS, Card, type CardProps } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useSmartTransferTimingMessageQuery } from '~/graphql/hooks';
import type { SmartTransferBalanceTriggerInput } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

export type SmartTransferTimingMessageProps = CardProps & {
  balanceTrigger: SmartTransferBalanceTriggerInput;
  editRequirementsId: string;
};

export const SmartTransferTimingMessage = ({
  balanceTrigger,
  editRequirementsId,
  ...rest
}: SmartTransferTimingMessageProps) => {
  const { data } = useSmartTransferTimingMessageQuery({
    variables: {
      editRequirementsId,
      balanceTrigger,
    },
  });

  if (data?.node?.__typename !== 'SmartTransferRuleEditRequirements') {
    return null;
  }

  const timingMessage = data.node.balanceTriggerEditRequirements.timingMessage;
  const isAlert = timingMessage?.messageType === 'ALERT';

  return (
    <Card
      borderColor="transparent"
      backgroundColor="backgroundNeutralTertiary"
      p="12px 16px"
      {...rest}
    >
      <Flex alignItems="center">
        <Box>
          <Icon
            name={isAlert ? 'alert24' : 'hold32'}
            color={isAlert ? 'warning' : 'primary'}
          />
        </Box>
        <PS
          color="foregroundNeutralMain"
          content={timingMessage?.description}
          ml={16}
        />

        {timingMessage?.link && (
          <>
            {' '}
            <LinkableLink linkable={timingMessage.link} />
          </>
        )}
      </Flex>
    </Card>
  );
};
