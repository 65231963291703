import { Box, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { useChartableSliceChartContext } from '~/components/ChartableSliceChart/ChartableSliceChartContext';
import type { AppTooltipFragment } from '~/graphql/types';
import { AppTooltip } from '~/lens-toolbox/AppTooltip';
import { isNotNil } from '~/utils';

const assetsAndLiabilitiesTooltipCopy = [
  'Displays of account value/return may not reflect up-to-the-minute calculations. This could be due to fluctuations in market activity; the timing of your trades, payments, deposits, and withdrawals; and the availability of third-party data. Your M1 Account Statement is the official record of your account value.',
  'Balances shown on the tiles below are sums of all accounts in each area, and may include balances from multiple banks.',
];

export const NetWorthTooltip = ({
  netWorthTooltip,
  showAssetsAndLiabilities,
}: {
  showAssetsAndLiabilities: boolean;
  netWorthTooltip: AppTooltipFragment | null | undefined;
}) => {
  const { isChartDataNull } = useChartableSliceChartContext();
  const showLegacyTooltip = isChartDataNull || showAssetsAndLiabilities;
  if (showLegacyTooltip) {
    // Show hardcoded tooltip if not showing the chart.
    return (
      <Tooltip
        icon="tooltip24"
        iconColor="primaryTint"
        placement="bottom"
        body={
          <>
            {assetsAndLiabilitiesTooltipCopy.map((p, i, arr) => (
              <Box as="p" key={p} mb={i >= arr.length - 1 ? 0 : 8}>
                {p}
              </Box>
            ))}
          </>
        }
      />
    );
  }
  if (isNotNil(netWorthTooltip)) {
    return <AppTooltip appTooltip={netWorthTooltip} placement="bottom" />;
  }
  return null;
};
