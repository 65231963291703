import * as React from 'react';

import { stepToProgress } from '~/components/StepToProgress';
import {
  ACCOUNT_SETUP_FLOW_STEPS as STEPS,
  PHONE_VERIFICATION_FLOW_STEPS as PHONE_STEPS,
} from '~/static-constants';

const setupAccountSteps = {
  [STEPS.LOAD_STATUS]: 5,

  [STEPS.SELECT_ACCOUNT_TYPE]: 5,
  [STEPS.SELECT_RETIREMENT_ACCOUNT_TYPE]: 8,
  [STEPS.SELECT_ROLLOVER_ACCOUNT_TYPE]: 12,
  [STEPS.SELECT_OTHER_ACCOUNT_TYPE]: 15,

  [STEPS.COLLECT_PRIMARY_CONTACT_INFO]: 20,
  [STEPS.COLLECT_PRIMARY_IDENTITY_INFO]: 25,

  [STEPS.EXPLAIN_PROFILE_COLLECTION]: 36,
  [STEPS.COLLECT_ANNUAL_INCOME]: 41,
  [STEPS.COLLECT_TOTAL_NET_WORTH]: 46,
  [STEPS.COLLECT_LIQUID_NET_WORTH]: 51,
  [STEPS.COLLECT_INVESTMENT_EXPERIENCE]: 56,
  [STEPS.COLLECT_RISK_TOLERANCE]: 61,
  [STEPS.COLLECT_TIME_HORIZON]: 66,
  [STEPS.COLLECT_LIQUIDITY_NEEDS]: 71,

  [STEPS.COLLECT_PRIMARY_SSN]: 76,
  [STEPS.COLLECT_PRIMARY_DISCLOSURES]: 81,

  [STEPS.INTRODUCE_SECONDARY_HOLDER]: 83,
  [STEPS.COLLECT_SECONDARY_CONTACT_INFO]: 87,
  [STEPS.COLLECT_SECONDARY_IDENTITY_INFO]: 91,
  [STEPS.COLLECT_SECONDARY_SSN]: 95,
  [STEPS.COLLECT_SECONDARY_DISCLOSURES]: 99,

  [STEPS.REVIEW]: 100,
  [STEPS.REVIEW_QUICK]: 100,

  [STEPS.SHOW_PAPER_ACCOUNT_RECEIPT]: 100,
  [STEPS.SHOW_ROLLOVER_RECEIPT]: 100,
  [STEPS.SYSTEM_UNAVAILABLE]: 100,
};

const phoneVerificationAndSetupAccountSteps = {
  [PHONE_STEPS.COLLECT_PHONE_NUMBER]: 4,
  [PHONE_STEPS.VERIFY_RECEIVED_CODE]: 8,
  [STEPS.LOAD_STATUS]: 12,

  [STEPS.SELECT_ACCOUNT_TYPE]: 12,
  [STEPS.SELECT_RETIREMENT_ACCOUNT_TYPE]: 15,
  [STEPS.SELECT_ROLLOVER_ACCOUNT_TYPE]: 20,
  [STEPS.SELECT_OTHER_ACCOUNT_TYPE]: 25,

  [STEPS.COLLECT_PRIMARY_CONTACT_INFO]: 30,
  [STEPS.COLLECT_PRIMARY_IDENTITY_INFO]: 36,

  [STEPS.EXPLAIN_PROFILE_COLLECTION]: 41,
  [STEPS.COLLECT_ANNUAL_INCOME]: 45,
  [STEPS.COLLECT_TOTAL_NET_WORTH]: 49,
  [STEPS.COLLECT_LIQUID_NET_WORTH]: 53,
  [STEPS.COLLECT_INVESTMENT_EXPERIENCE]: 57,
  [STEPS.COLLECT_RISK_TOLERANCE]: 61,
  [STEPS.COLLECT_TIME_HORIZON]: 66,
  [STEPS.COLLECT_LIQUIDITY_NEEDS]: 71,

  [STEPS.COLLECT_PRIMARY_SSN]: 76,
  [STEPS.COLLECT_PRIMARY_DISCLOSURES]: 81,

  [STEPS.INTRODUCE_SECONDARY_HOLDER]: 83,
  [STEPS.COLLECT_SECONDARY_CONTACT_INFO]: 87,
  [STEPS.COLLECT_SECONDARY_IDENTITY_INFO]: 91,
  [STEPS.COLLECT_SECONDARY_SSN]: 95,
  [STEPS.COLLECT_SECONDARY_DISCLOSURES]: 99,

  [STEPS.REVIEW]: 100,
  [STEPS.REVIEW_QUICK]: 100,

  [STEPS.SHOW_PAPER_ACCOUNT_RECEIPT]: 100,
  [STEPS.SHOW_ROLLOVER_RECEIPT]: 100,
  [STEPS.SYSTEM_UNAVAILABLE]: 100,
};

type AccountSetupFlowProgressProps = {
  isPhoneVerificationRequired: boolean;
};

export const AccountSetupFlowProgress = ({
  isPhoneVerificationRequired,
}: AccountSetupFlowProgressProps) => {
  const steps = isPhoneVerificationRequired
    ? phoneVerificationAndSetupAccountSteps
    : setupAccountSteps;
  const ProgressBar = stepToProgress(
    (state) => state.newFlows.accountSetup,
    steps,
  );
  // @ts-expect-error - TS2741 - Property 'percent' is missing in type '{}' but required in type 'Omit<ProgressProps, never>'.
  return <ProgressBar />;
};
