import { HXXS, PL } from '@m1/liquid-react';
import React from 'react';

import { isNotNil } from '~/utils';

import { formatNumber } from '~/utils/formatting';

import { StyledTitle } from './ScreenerTable.styled';

export const ScreenerTableTitle = ({
  total,
}: {
  total: number | null | undefined;
}) => {
  return (
    <StyledTitle>
      {isNotNil(total) && (
        <>
          <HXXS fontWeight={300} content="Results" />
          <PL
            pl={8}
            color="foregroundNeutralMain"
            content={`${formatNumber(total, 'SELECTIVE')} total`}
          />
        </>
      )}
    </StyledTitle>
  );
};
