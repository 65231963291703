import { Box, Button, Flex, PXL } from '@m1/liquid-react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { FormMockerReactHookForm } from '~/forms/FormMockers/FormMockerReactHookForm';
import {
  useCompletePhoneVerificationMutation,
  usePersonalLoansDirectQuery,
} from '~/graphql/hooks';
import type {
  CompletePhoneVerificationInput,
  PersonalLoanDirectQuestion,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { normalizePhoneNumber } from '~/utils';

import { BackButton } from '../BackButton';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export const PhoneNumberVerification = () => {
  const { data, loading: personalLoansDirectLoading } =
    usePersonalLoansDirectQuery();
  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  let props: Maybe<Partial<PersonalLoanDirectQuestion>> | undefined;

  if (questions) {
    props = getQuestionProps({
      question: 'PHONE_NUMBER_VERIFICATION',
      questions,
    });
  }

  const { addToast } = useToast();
  const [completePhoneVerification, { loading: verificationLoading }] =
    useCompletePhoneVerificationMutation();
  const analytics = useAnalytics();
  const { goTo, back } = usePersonalLoanDirectWizardContext();
  const { phoneNumber } = useSelector((state) => {
    return state.reactHookForm['directToPersonalLoansPhoneNumber'];
  });

  usePortaledSpinner(verificationLoading || personalLoansDirectLoading);

  const formMethods = useForm<CompletePhoneVerificationInput>({
    defaultValues: {
      code: '',
    },
  });

  const { control, setValue, handleSubmit } = formMethods;

  React.useEffect(() => {
    analytics.pageView(
      '/direct-loan-application/phone-verification/verify-code',
    );
  }, [analytics]);

  const onSubmit = (input: CompletePhoneVerificationInput) => {
    completePhoneVerification({
      variables: {
        input: {
          code: input.code,
          phone: normalizePhoneNumber(phoneNumber),
        },
      },
      onCompleted: () => {
        const { nextQuestionType } = props || {};
        goTo(nextQuestionType ?? 'DATE_OF_BIRTH_CITIZENSHIP');
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
          duration: 'short',
        });
        if (
          err.graphQLErrors.some(
            (err) =>
              typeof err.extensions.code === 'string' &&
              ['NO_PENDING_VERIFICATION', 'PHONE_ALREADY_USED'].includes(
                err.extensions.code,
              ),
          )
        ) {
          back();
        }
      },
    });
  };

  const handleTryAgain = () => back();

  const { continueLabel, title } = props || {};

  return (
    <>
      <BackButton onBackClick={() => back()} />

      <FormProvider {...formMethods}>
        <Flex flexDirection="column">
          <StyledHeader
            mb={8}
            content={title ?? 'Please enter the code here:'}
          />
          <ControlledInput
            name="code"
            control={control}
            autoFocus
            backgroundColor="backgroundNeutralSecondary"
            label="Confirmation code"
            maxLength={10}
            minLength={4}
            rules={{ required: 'Required.', minLength: 4 }}
          />
          <PXL mt={16} mb={48}>
            <Button kind="link" label="Resend code" onClick={handleTryAgain} />
          </PXL>
          <Box>
            <ResponsiveButton
              label={continueLabel || 'Continue'}
              type="submit"
              size="large"
              onClick={handleSubmit(onSubmit)}
            />
            <FormMockerReactHookForm
              setValue={setValue}
              fields={[
                {
                  name: 'code',
                  value: '5678',
                },
              ]}
            />
          </Box>
        </Flex>
      </FormProvider>
    </>
  );
};
