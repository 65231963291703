import type { PlaidLinkResult } from 'react-plaid-link';

export const CONNECT_BANK_ACTIONS = Object.freeze({
  BEGIN_CONNECT_BANK_FLOW: 'BEGIN_CONNECT_BANK_FLOW' as const,
  CHANGED_CONNECT_BANK_FLOW_STEP: 'CHANGED_CONNECT_BANK_FLOW_STEP' as const,
  EXITED_INITIAL_FUNDING: 'EXITED_INITIAL_FUNDING' as const,
  RECEIVED_PLAID_LINK_TOKEN: 'RECEIVED_PLAID_LINK_TOKEN' as const,
  SKIPPED_BANK_CONNECTION: 'SKIPPED_BANK_CONNECTION' as const,
  SKIPPED_PLAID_CONNECTION: 'SKIPPED_PLAID_CONNECTION' as const,
});

export type BeginConnectBankFlowAction = {
  payload: unknown;
  type: typeof CONNECT_BANK_ACTIONS.BEGIN_CONNECT_BANK_FLOW;
};

export type SkippedBankConnectionAction = {
  type: typeof CONNECT_BANK_ACTIONS.SKIPPED_BANK_CONNECTION;
};

export type ReceivedPlaidLinkTokenAction = {
  payload: PlaidLinkResult & {
    fundingSourceId?: string | null | undefined;
  };
  type: typeof CONNECT_BANK_ACTIONS.RECEIVED_PLAID_LINK_TOKEN;
};

export type ExitedInitialFundingAction = {
  type: typeof CONNECT_BANK_ACTIONS.EXITED_INITIAL_FUNDING;
};

export type ChangedConnectBankFlowStepAction = {
  payload: string;
  type: typeof CONNECT_BANK_ACTIONS.CHANGED_CONNECT_BANK_FLOW_STEP;
};

export type SkippedPlaidConnectionAction = {
  type: typeof CONNECT_BANK_ACTIONS.SKIPPED_PLAID_CONNECTION;
};

export const beginConnectBankFlow = (
  payload: BeginConnectBankFlowAction['payload'],
): BeginConnectBankFlowAction => ({
  payload,
  type: CONNECT_BANK_ACTIONS.BEGIN_CONNECT_BANK_FLOW,
});

export const skippedBankConnection = (): SkippedBankConnectionAction => ({
  type: CONNECT_BANK_ACTIONS.SKIPPED_BANK_CONNECTION,
});

export const receivedPlaidLinkToken = (
  payload: ReceivedPlaidLinkTokenAction['payload'],
): ReceivedPlaidLinkTokenAction => ({
  type: CONNECT_BANK_ACTIONS.RECEIVED_PLAID_LINK_TOKEN,
  payload,
});

export const changedConnectBankFlowStep = (
  payload: ChangedConnectBankFlowStepAction['payload'],
): ChangedConnectBankFlowStepAction => ({
  type: CONNECT_BANK_ACTIONS.CHANGED_CONNECT_BANK_FLOW_STEP,
  payload,
});

export const skippedPlaidConnection = (): SkippedPlaidConnectionAction => ({
  type: CONNECT_BANK_ACTIONS.SKIPPED_PLAID_CONNECTION,
});

export const exitedInitialFunding = (): ExitedInitialFundingAction => ({
  type: CONNECT_BANK_ACTIONS.EXITED_INITIAL_FUNDING,
});

export type ConnectBankAction =
  | BeginConnectBankFlowAction
  | ChangedConnectBankFlowStepAction
  | ExitedInitialFundingAction
  | ReceivedPlaidLinkTokenAction
  | SkippedBankConnectionAction
  | SkippedPlaidConnectionAction;
