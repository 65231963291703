import { Flex, styled } from '@m1/liquid-react';
import chunk from 'lodash-es/chunk';
import * as React from 'react';

import { CopyableCard } from '~/components/CopyableCard';
import type { WireInstructionsFragment } from '~/graphql/types';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { isNil, isNotNil } from '~/utils';

const StyledCard = styled(CopyableCard)<{
  mb?: number;
  mr?: number;
  isDesktop: boolean;
}>`
  height: 80px;
  margin-right: ${({ mr }) => (isNotNil(mr) ? mr : 0)}px;
  margin-bottom: ${({ mb }) => (isNotNil(mb) ? mb : 0)}px;
  width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};

  &:only-child {
    width: ${({ isDesktop }) => (isDesktop ? 'calc(50% - 12px)' : '100%')};
  }

  p {
    font-weight: 400;
  }
`;

const getInstructionFields = (
  instruction: WireInstructionsFragment['wireInstructionValues'][number],
): { title: string; value: string } => {
  if (isNil(instruction.title) || isNil(instruction.value)) {
    return { title: '', value: '' };
  }

  return { title: instruction.title, value: instruction.value };
};

export type WireTransferInstructionCardProps = {
  wireInstructionValues: WireInstructionsFragment['wireInstructionValues'];
};

export const WireTransferInstructionCards = ({
  wireInstructionValues,
}: WireTransferInstructionCardProps) => {
  const { isDesktop } = useBreakpoints();

  // For desktop we're dealing with groupings of instructions since we need to build out rows of two
  const instructions = isDesktop
    ? chunk(wireInstructionValues, 2)
    : wireInstructionValues;

  return (
    <Flex flexDirection="column" mt={32}>
      {instructions.map((instruction, index) => {
        if (Array.isArray(instruction)) {
          let instruction2Fields;
          const { title, value } = getInstructionFields(instruction[0]);

          if (instruction.length > 1) {
            instruction2Fields = getInstructionFields(instruction[1]);
          }

          return (
            <Flex key={index} width="100%" mb={24}>
              <StyledCard
                isDesktop={isDesktop}
                label={title}
                mr={24}
                value={value}
              />
              {isNotNil(instruction2Fields) && (
                <StyledCard
                  isDesktop={isDesktop}
                  label={instruction2Fields.title}
                  value={instruction2Fields.value}
                />
              )}
            </Flex>
          );
        }
        const { title, value } = getInstructionFields(instruction);
        return (
          <StyledCard
            key={index}
            isDesktop={isDesktop}
            label={title}
            mb={16}
            value={value}
          />
        );
      })}
    </Flex>
  );
};
