import { Box, Flex, HXXS, PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import type {
  VideoTutorialsSectionFragment,
  VideoTutorialsVideoFragment,
} from '~/graphql/types';

const StyledFlexVideoTutorialsSection = styled(Flex)`
  transform: translateY(-32px);
`;

const StyledIframe = styled.iframe`
  border-radius: 8px;
  width: 350px;
  height: 260px;
`;

type VideoTutorialsSectionProps = {
  videoTutorialsSection: NonNullable<
    VideoTutorialsSectionFragment['videoTutorialsSection']
  >;
};

type VideoType = VideoTutorialsVideoFragment;

export const ContactUsVideoTutorialsSection = ({
  videoTutorialsSection,
}: VideoTutorialsSectionProps) => {
  return (
    <StyledFlexVideoTutorialsSection flexDirection="column">
      {videoTutorialsSection.title && (
        <HXXS content={videoTutorialsSection.title} />
      )}
      {videoTutorialsSection.subtitle && (
        <PM content={videoTutorialsSection.subtitle} py={16} />
      )}
      <Flex flexWrap="wrap" justifyContent="space-between" pt={16}>
        {videoTutorialsSection.videos &&
          (
            videoTutorialsSection.videos.filter(Boolean) as Array<VideoType>
          ).map((video, i) => {
            if (!video.url) {
              return null;
            }

            return (
              <Box key={i}>
                {video.title && <PM content={video.title} fontWeight={600} />}
                <Box mt={16}>
                  <StyledIframe allow="fullscreen" src={video.url} />
                </Box>
              </Box>
            );
          })}
      </Flex>
    </StyledFlexVideoTutorialsSection>
  );
};
