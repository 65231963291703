import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
  PS,
} from '@m1/liquid-react';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { printableAsciiChars, isValidSsn } from '~/forms/validators';
import type {
  BeneficiaryInfoFragment,
  BeneficiaryInput,
  NewBeneficiaryInput,
} from '~/graphql/types';

type BeneficiaryConfirmationModalProps = {
  open: boolean;
  beneficiaries: null | Array<
    { id: string } & Partial<
      BeneficiaryInfoFragment & {
        address: Partial<BeneficiaryInfoFragment['address']>;
        ssn: string;
      }
    >
  >;
  onClose: () => void;
  onSubmit: (beneficiaries: NewBeneficiaryInput[]) => void;
};

export const BeneficiaryConfirmationModal = ({
  open,
  onClose,
  onSubmit,
  beneficiaries,
}: BeneficiaryConfirmationModalProps) => {
  const formMethods = useForm<{
    beneficiaries: {
      ssn: string;
      beneficiary: BeneficiaryInput;
    }[];
  }>({
    defaultValues: {
      beneficiaries: [],
    },
  });

  React.useEffect(() => {
    formMethods.reset({
      beneficiaries: (beneficiaries ?? []).map(({ id, ...beneficiary }) => ({
        ssn: '',
        beneficiary,
      })),
    });
  }, [beneficiaries, formMethods]);

  if (!open) {
    return null;
  }

  const controlledBeneficiaries = formMethods.watch('beneficiaries');

  return (
    <FormProvider {...formMethods}>
      <Modal open={open} onClose={onClose}>
        <ModalContent width="normal">
          <ModalTitle>Confirm Changes</ModalTitle>
          <PS color="foregroundNeutralMain">
            Please confirm all of your beneficiaries' Social Security numbers by
            entering them below
          </PS>
          <Flex flexDirection="column">
            <form
              onSubmit={formMethods.handleSubmit((data) => {
                onSubmit(
                  data.beneficiaries.map(({ ssn, beneficiary }) => ({
                    ssn: ssn?.replace(/-/g, ''),
                    beneficiary,
                  })) as NewBeneficiaryInput[],
                );
                onClose();
              })}
            >
              {controlledBeneficiaries.map(({ beneficiary }, index) => (
                <Box key={`beneficiary-${index}`}>
                  <ControlledInput
                    name={`beneficiaries.${index}.ssn`}
                    label={`${beneficiary.firstName} ${beneficiary.lastName}`}
                    control={formMethods.control}
                    placeholder="XXX-XX-XXXX"
                    maskType="ssn"
                    rules={{
                      required: true,
                      validate: {
                        printableAsciiChars: (v) =>
                          typeof v === 'string' && printableAsciiChars(v),
                        ssn: (v) => isValidSsn(v),
                      },
                    }}
                  />
                </Box>
              ))}
              <Box pt="1em">
                <Button
                  label="Submit"
                  kind="primary"
                  size="large"
                  type="submit"
                />
              </Box>
            </form>
          </Flex>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
