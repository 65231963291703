import type { SliceableTypeEnum } from '~/graphql/types';

export const WATCHLIST_ACTIONS = Object.freeze({
  LOAD_WATCHLIST_FROM_SERVER: 'LOAD_WATCHLIST_FROM_SERVER' as const,
  ADD_TO_WATCHLIST: 'ADD_TO_WATCHLIST' as const,
  REMOVE_FROM_WATCHLIST: 'REMOVE_FROM_WATCHLIST' as const,
  LOADED_WATCHLIST_FROM_SERVER: 'LOADED_WATCHLIST_FROM_SERVER' as const,
});

export type AddToWatchlist = {
  payload: {
    securityId: string;
    securityType: SliceableTypeEnum;
  };
  type: typeof WATCHLIST_ACTIONS.ADD_TO_WATCHLIST;
};

export type RemoveFromWatchlist = {
  payload: {
    securityId: string;
    securityType: SliceableTypeEnum;
  };
  type: typeof WATCHLIST_ACTIONS.REMOVE_FROM_WATCHLIST;
};

export type LoadedWatchlistFromServer = {
  payload: ReadonlyArray<string>;
  type: typeof WATCHLIST_ACTIONS.LOADED_WATCHLIST_FROM_SERVER;
};

export const addToWatchlist = (
  payload: AddToWatchlist['payload'],
): AddToWatchlist => ({
  type: WATCHLIST_ACTIONS.ADD_TO_WATCHLIST,
  payload,
});

export const removeFromWatchlist = (
  payload: RemoveFromWatchlist['payload'],
): RemoveFromWatchlist => ({
  type: WATCHLIST_ACTIONS.REMOVE_FROM_WATCHLIST,
  payload,
});

export const loadedWatchlistFromServer = (
  payload: LoadedWatchlistFromServer['payload'],
): LoadedWatchlistFromServer => ({
  type: WATCHLIST_ACTIONS.LOADED_WATCHLIST_FROM_SERVER,
  payload: payload,
});

export type WatchlistAction =
  | AddToWatchlist
  | RemoveFromWatchlist
  | LoadedWatchlistFromServer;
