import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext, type SubmitHandler } from 'react-hook-form';

import type {
  OnboardingIdentityFirstQuestionType,
  TotalNetWorthEnum,
} from '~/graphql/types';
import { getEnumEntries } from '~/utils';

import { StyledOption } from '../consts';
import type { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';

type TotalNetWorthFormProps = {
  nextQuestion?: OnboardingIdentityFirstQuestionType | null;
  onSubmit: SubmitHandler<FinancialSuitabilityFormFields>;
  handleNext?: () => void;
};

export const TotalNetWorthForm = ({
  nextQuestion,
  onSubmit,
  handleNext,
}: TotalNetWorthFormProps) => {
  const { setValue, handleSubmit } =
    useFormContext<FinancialSuitabilityFormFields>();
  const options = getEnumEntries('TotalNetWorthEnum');

  const handleOptionClick = (name: TotalNetWorthEnum) => {
    setValue('suitability.totalNetWorth', name);
    nextQuestion && handleNext ? handleNext() : handleSubmit(onSubmit)();
  };

  return (
    <Box>
      {options.map(({ name, description }) => (
        <StyledOption
          tabIndex={0}
          key={name}
          onClick={() => handleOptionClick(name as TotalNetWorthEnum)}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === 'Enter' || event.code === 'Space') {
              handleOptionClick(name as TotalNetWorthEnum);
            }
          }}
        >
          {description}
        </StyledOption>
      ))}
    </Box>
  );
};
