import { Box, Button, Flex, HXXS, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useProfileBeneficiariesQuery,
  useProfileReviewLazyQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type {
  BeneficiaryInfoFragment,
  NewBeneficiaryInput,
} from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

import { BeneficiaryConfirmationModal } from './components/BeneficiaryConfirmationModal';
import { BeneficiaryDisclosures } from './components/BeneficiaryDisclosures';
import { BeneficiaryModal } from './components/BeneficiaryModal';
import { BeneficiaryRow } from './components/BeneficiaryRow';

type EmptyStateProps = {
  disabled: boolean;
  onAddBeneficiary: any;
};

type RemoveBeneficiaryObject = {
  [key: string]: BeneficiaryInfoFragment;
};

const EmptyState = ({ disabled, onAddBeneficiary }: EmptyStateProps) => (
  <Box>
    <HXXS content="Beneficiaries" mb={40} fontWeight={300} />
    <Flex flexDirection="column" alignItems="center">
      <Box>
        <HXXS content="You have not set up any beneficiaries yet." />
      </Box>
      <Box pt={32} pb={8}>
        <Button
          kind="primary"
          size="large"
          onClick={onAddBeneficiary}
          disabled={disabled}
        >
          Add beneficiary
        </Button>
      </Box>
    </Flex>
  </Box>
);

const UNSAVED_CHANGES_ERROR = 'You have unsaved changes';

export const BeneficiariesPage = () => {
  const { addToast } = useToast();
  const [sectionError, setSectionError] = React.useState<string | null>(null);
  const [beneficiariesToRemove, setBeneficiariesToRemove] =
    React.useState<RemoveBeneficiaryObject>({});
  // activeBeneficiaryIndex is the field index of the beneficiary being edited/added
  const [activeBeneficiaryIndex, setActiveBeneficiaryIndex] =
    React.useState(-1);
  // isSavingBeneficiaries triggers the SSN modal required before saving changes
  const [isSavingBeneficiaries, setIsSavingBeneficiaries] =
    React.useState(false);

  const { data, loading, refetch } = useProfileBeneficiariesQuery();
  const [fetchProfileData, { loading: isFetchingProfile }] =
    useProfileReviewLazyQuery();
  const [updateProfile, { loading: isUpdating }] = useUpdateProfileMutation();

  const isBeneficiaryMarkedForRemoval = (index: number) => {
    return Object.keys(beneficiariesToRemove).includes(index.toString());
  };

  usePortaledSpinner(isFetchingProfile || isUpdating);

  const beneficiaries = data?.viewer.profile?.beneficiaries ?? [];

  const formMethods = useForm<{
    beneficiaries: Array<BeneficiaryInfoFragment>;
  }>({ defaultValues: { beneficiaries }, mode: 'all' });

  const {
    control,
    reset: setFormValues,
    formState: { isDirty: hasUnsavedUpdates },
  } = formMethods;

  const { fields, append, update } = useFieldArray({
    control,
    name: 'beneficiaries',
  });

  // Ensures can save is true if there are unsaved changes or beneficiaries to remove and make sure there are no errors
  const canSave =
    (hasUnsavedUpdates || Object.keys(beneficiariesToRemove).length > 0) &&
    (sectionError === null || sectionError === UNSAVED_CHANGES_ERROR);

  const watchedFields = formMethods.watch('beneficiaries');
  // without this, the row fields won't be reflected in the top-level form
  // see: the "Controlled Field Array" section at `https://react-hook-form.com/docs/usefieldarray`
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchedFields[index],
  }));

  React.useEffect(() => {
    const fields = controlledFields;
    const primaryFields = fields.filter(
      ({ isPrimary }, index) =>
        isPrimary && !isBeneficiaryMarkedForRemoval(index),
    );
    const contingentFields = fields.filter(
      ({ isPrimary }, index) =>
        !isPrimary && !isBeneficiaryMarkedForRemoval(index),
    );

    const primaryPercentage = primaryFields
      .map(({ sharePercentage }) => sharePercentage || 0)
      .reduce(
        (totalPercentage, sharePercentage) => totalPercentage + sharePercentage,
        0,
      );

    const contingentPercentage = contingentFields
      .map(({ sharePercentage }) => sharePercentage || 0)
      .reduce(
        (totalPercentage, sharePercentage) => totalPercentage + sharePercentage,
        0,
      );

    if (
      primaryFields.length > 0 &&
      (primaryPercentage < 100 || primaryPercentage > 100)
    ) {
      setSectionError(
        'Primary beneficiary share percentages should add up to 100%',
      );
    } else if (
      contingentFields.length > 0 &&
      (contingentPercentage < 100 || contingentPercentage > 100)
    ) {
      setSectionError(
        'Contingent beneficiary share percentages should add up to 100%',
      );
    } else {
      setSectionError(hasUnsavedUpdates ? UNSAVED_CHANGES_ERROR : null);
    }
  }, [controlledFields]);

  React.useEffect(
    () => setFormValues({ beneficiaries }),
    [beneficiaries, setFormValues],
  );

  function addNewBeneficiary() {
    setActiveBeneficiaryIndex(fields.length);
  }

  async function saveBeneficiaries(beneficiaries: NewBeneficiaryInput[]) {
    const { data } = await fetchProfileData();

    const beneficiariesToSubmit = beneficiaries
      .filter((_, i) => {
        const beneficiaryToRemove = beneficiariesToRemove[i];
        return (
          !beneficiaryToRemove ||
          beneficiaryToRemove.firstName !== _.beneficiary.firstName ||
          beneficiaryToRemove.lastName !== _.beneficiary.lastName ||
          beneficiaryToRemove.dateOfBirth !== _.beneficiary.dateOfBirth
        );
      })
      .map((b) => withoutTypename<NewBeneficiaryInput>(b));

    if (!data?.viewer.profile?.primary) {
      addToast({
        content:
          'Something went wrong! If the problem persists, please contact support.',
        kind: 'alert',
        duration: 'long',
      });
    } else {
      const { primary, secondary } = data.viewer.profile;
      updateProfile({
        variables: {
          input: {
            profile: {
              primary,
              secondary,
              beneficiaries: beneficiariesToSubmit,
            },
          },
        },
        onCompleted() {
          addToast({
            content: 'Updated beneficiaries',
            kind: 'success',
            duration: 'long',
          });
          setSectionError(null);
          setBeneficiariesToRemove({});
          refetch();
        },
        onError(err) {
          addToast({
            content: err.message,
            kind: 'alert',
            duration: 'long',
          });
        },
      });
    }
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.profile) {
    return (
      <GenericSystemError content="Could not load beneficiaries. Please try again later." />
    );
  }

  return (
    <>
      <BeneficiaryModal
        open={activeBeneficiaryIndex > -1}
        beneficiary={controlledFields[activeBeneficiaryIndex]}
        onClose={() => setActiveBeneficiaryIndex(-1)}
        onSubmit={(beneficiary: BeneficiaryInfoFragment) =>
          activeBeneficiaryIndex < fields.length
            ? update(activeBeneficiaryIndex, beneficiary)
            : append(beneficiary)
        }
      />
      <BeneficiaryConfirmationModal
        open={isSavingBeneficiaries}
        beneficiaries={controlledFields.filter(
          (_, i) => !isBeneficiaryMarkedForRemoval(i),
        )}
        onClose={() => setIsSavingBeneficiaries(false)}
        onSubmit={saveBeneficiaries}
      />
      {controlledFields.length === 0 && !hasUnsavedUpdates ? (
        <EmptyState onAddBeneficiary={addNewBeneficiary} disabled={false} />
      ) : (
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <Flex
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <HXXS content="Beneficiaries" fontWeight={300} />
              </Box>
              <ButtonGroup>
                <Button
                  kind="secondary"
                  size="medium"
                  onClick={addNewBeneficiary}
                >
                  Add beneficiary
                </Button>
                <Button
                  kind="secondary"
                  size="medium"
                  disabled={!canSave}
                  onClick={() =>
                    controlledFields.length > 0
                      ? setIsSavingBeneficiaries(true)
                      : saveBeneficiaries([])
                  }
                >
                  Save
                </Button>
              </ButtonGroup>
            </Flex>
            <Box alignSelf="flex-end" mt={40} mb={16}>
              {sectionError ? (
                <Flex alignItems="center">
                  <Icon
                    name="alert16"
                    display="inline-block"
                    color="critical"
                    mr={5}
                  />
                  <PL color="critical">{sectionError}</PL>
                </Flex>
              ) : null}
            </Box>
          </Flex>
          <GridTable
            gridTemplateColumns="minmax(160px, 1fr) minmax(100px, 2fr) 160px 100px 66px"
            isNewStyle
          >
            <GridTable.HeaderRow>
              <GridTable.HeaderCell label="Name" />
              <GridTable.HeaderCell label="Address" />
              <GridTable.HeaderCell label="Type" />
              <GridTable.HeaderCell label="Share %" />
              <GridTable.HeaderCell label="&nbsp;" />
            </GridTable.HeaderRow>
            {controlledFields.map((field, index) => (
              <BeneficiaryRow<{ beneficiaries: BeneficiaryInfoFragment[] }>
                key={field.id}
                name={`beneficiaries.${index}`}
                beneficiary={field}
                formMethods={formMethods}
                isRemoved={isBeneficiaryMarkedForRemoval(index)}
                onEditBeneficiaryInfo={() => setActiveBeneficiaryIndex(index)}
                onRemoveBeneficiary={() => {
                  setBeneficiariesToRemove({
                    ...beneficiariesToRemove,
                    [index]: beneficiaries[index],
                  });
                }}
                onRestoreBeneficiary={() => {
                  setBeneficiariesToRemove(() => {
                    const newBeneficiariesToRemove: RemoveBeneficiaryObject = {
                      ...beneficiariesToRemove,
                    };
                    delete newBeneficiariesToRemove[index];
                    return newBeneficiariesToRemove;
                  });
                }}
              />
            ))}
          </GridTable>
        </Flex>
      )}
      <Box my={32}>
        <BeneficiaryDisclosures />
      </Box>
    </>
  );
};
