import * as React from 'react';

import type { TransferInstanceNodeFragment } from '~/graphql/types';
import { Pill, type PillProps } from '~/toolbox/Pill';

export type DisplayFlags = {
  isCanceled?: boolean;
  isComplete?: boolean;
  isFailed?: boolean;
  isLiquidation?: boolean;
  isPending?: boolean;
  isPendingCancel?: boolean;
};

const defaultOnlyShowIf: DisplayFlags = {
  isFailed: true,
  isCanceled: true,
  isLiquidation: true,
  isPending: true,
  isPendingCancel: true,
  isComplete: true,
};

type TransferInstanceStatusPillProps = {
  onlyShowIf?: Partial<DisplayFlags>;
  transferInstance: TransferInstanceNodeFragment;
};

const getPillProps = (
  transferInstance: TransferInstanceNodeFragment,
  showIf: DisplayFlags,
): Pick<PillProps, 'kind' | 'label'> | undefined => {
  if (transferInstance.status.isFailed && showIf.isFailed) {
    return {
      kind: 'danger',
      label: 'Failed',
    };
  } else if (
    transferInstance.status.code === 'PENDING_CANCELLATION' &&
    showIf.isPendingCancel
  ) {
    return {
      kind: 'caution',
      label: 'Canceling',
    };
  } else if (transferInstance.status.isCanceled && showIf.isCanceled) {
    return {
      kind: 'danger',
      label: 'Canceled',
    };
  } else if (
    typeof transferInstance.isLiquidation === 'boolean' &&
    transferInstance.isLiquidation &&
    showIf.isLiquidation
  ) {
    return {
      kind: 'caution',
      label: 'Liquidation',
    };
  } else if (transferInstance.status.isPending && showIf.isPending) {
    return {
      kind: 'caution',
      label: 'Pending',
    };
  } else if (transferInstance.status.isComplete && showIf.isComplete) {
    return {
      kind: 'success',
      label: 'Complete',
    };
  }
};

export const TransferInstanceStatusPill = (
  props: TransferInstanceStatusPillProps,
) => {
  const { onlyShowIf, transferInstance } = props;
  const pillProps = getPillProps(
    transferInstance,
    onlyShowIf ?? defaultOnlyShowIf,
  );

  return pillProps ? <Pill {...pillProps} /> : null;
};
