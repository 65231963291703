import { Flex, LM, PM, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { CreditCardRewardsSummaryFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';

type RewardsPayoutLocationContainerProps = {
  rewardsPayoutLocation: NonNullable<
    CreditCardRewardsSummaryFragment['rewardsPayoutLocation']
  >;
};

export const RewardsPayoutLocationContainer = ({
  rewardsPayoutLocation,
}: RewardsPayoutLocationContainerProps) => {
  const navigate = useNavigate();

  const { label, value, icon } = rewardsPayoutLocation;
  return (
    <>
      <PM content={label} mt={16} />
      <Card
        mt={4}
        p={8}
        id="rewards-summary-component-payout-card"
        onClick={() => navigate({ to: '/d/c/rewards-payout' })}
        data-testid="card"
      >
        <Flex alignItems="center" justifyContent="space-between" m={10}>
          <Flex alignItems="center">
            {icon && <AppImage height={20} width={20} appImage={icon} />}
            <LM color="foregroundNeutralSecondary" content={value} ml={8} />
          </Flex>
          <Icon name="caretRight16" color="foregroundNeutralSecondary" />
        </Flex>
      </Card>
    </>
  );
};
