import { Box, Flex, HXS, css, styled } from '@m1/liquid-react';

import type { ContainerProps } from '~/toolbox/container';

export const Header = styled.header`
  position: relative;
`;

export const StaticHeader = styled(Flex)<{ $responsive?: boolean }>`
  min-height: 84px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderFeature};
  padding: 20px 0;

  ${({ $responsive = true }) =>
    $responsive &&
    css`
      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.XXSMALL}) {
        flex-direction: column;
      }

      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.MEDIUM}) {
        flex-direction: row;
      }
    `}
`;

export const HeaderScrollBackground = styled(Box)<{
  $percentTopBarScrolled: number;
}>`
  position: absolute;
  z-index: -1;
  right: -50vw;
  width: 150vw;
  height: 100%;
  top: 0;
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  opacity: ${({ $percentTopBarScrolled }) => $percentTopBarScrolled};
`;

export const BuyingPowerSection = styled(Flex)`
  justify-content: space-between;
  margin-left: 0px;
  width: 100%;
  flex-wrap: wrap;
  align-items: end;
`;

export const SliceName = styled(HXS)`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 124px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    min-width: 350px;
    margin-right: 32px;
  }
`;

export const MastHeader = styled(Box)`
  background: ${({ theme }) => theme.colors.gradientLinearMain};
  height: 480px;
  position: absolute; // this is positioned absolutely to the parent PortfolioPageContainer which must be relative
  right: -50vw;
  width: 150vw;
  pointer-events: none;
`;

export const PortfolioPageContainer = styled(Box)`
  width: 100%;
  position: relative;
`;

export const PortfolioDetailsPageRight = styled(Box)`
  flex: 1 1 auto;
  /**
   * This must have overflow hidden to force Highcharts to redraw when the window is resized.
   * Do not remove these styles!
   * https://github.com/highcharts/highcharts/issues/6427#issuecomment-953025663
   */
  overflow-x: hidden;
  /**
   * Negative margin and positive padding allows things like box shadows to not be cut off by
   * hidden overflow, to please the Highcharts gods. Similar to above, do not remove these styles!
   */
  margin: 0 -20px;
  padding: 0 20px;
`;

export const BreadcrumbContainer = styled(Box)`
  width: 100%;
  margin-bottom: 8px;
`;

export const StyledPortfolioDetailsPageContainer = styled(Box)<ContainerProps>`
  padding: 32px 0;
  display: flex;
  width: 100%;
  margin: 0 auto 16px;
  gap: 30px;
`;

/**
 * Adds minimum height to prevent other components from shifting sightly once HighlightedPortfolioSliceName populates with text upon hover
 *
 * This is an unideal workaround the fact that HighlightedPortfolioSliceName is a legacy component
 * that is rendered when it contains no data,
 * therefore it can't render, for example, an empty placeholder
 */
export const StyledHighlightedPortfolioSliceNameContainer = styled(
  'div',
)<ContainerProps>`
  overflow: hidden;
  min-height: 24px;
`;
