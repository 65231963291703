import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import type { SecurityDetail_CryptoAsset_Fragment } from '~/graphql/types';
import { formatCurrency } from '~/utils/formatting';

import { SecurityDataContainer } from '../SecurityDataContainer';
import { SecurityDataRow } from '../SecurityDataRow';

export const CryptoKeyData = ({
  security,
}: {
  security: SecurityDetail_CryptoAsset_Fragment;
}) => (
  <SecurityDataContainer headingContent="Key Data" flexDirection="column">
    <Flex flexDirection="row" justifyContent="space-between">
      <Flex flexDirection="column" maxWidth={380} pr={100}>
        <SecurityDataRow
          headingContent="Current price"
          value={formatCurrency(security.latestQuote?.lastPrice)}
        />
        {Boolean(security.marketCap) && (
          <SecurityDataRow
            headingContent="Market cap"
            value={formatCurrency(security.marketCap, 'COMPACT') ?? '$--'}
          />
        )}
      </Flex>
      <Flex flexDirection="column" maxWidth={380}>
        {Boolean(security.volume) && (
          <SecurityDataRow
            headingContent="Average daily volume"
            value={formatCurrency(security.volume, 'COMPACT') ?? '$--'}
          />
        )}
        {Boolean(security.circulatingSupply) && (
          <SecurityDataRow
            headingContent="Circulating supply"
            value={`${formatCurrency(security.circulatingSupply, 'COMPACT') ?? '$--'} ${security.symbol}`}
          />
        )}
        {Boolean(security.maxSupply) && (
          <SecurityDataRow
            headingContent="Maximum supply"
            value={`${
              formatCurrency(security.maxSupply, 'COMPACT') ?? '$--'
            } ${security.symbol}`}
          />
        )}
      </Flex>
    </Flex>
  </SecurityDataContainer>
);
