import { Flex, Box, Button, HM, PXL, PL, PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { SectionRowData } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers';
import {
  type UserProfileData,
  getKeyValuePairsFromInput,
} from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenProfileSection';
import { ValueToDisplay } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/utils';
import { useAnalytics } from '~/hooks/useAnalytics';
import {
  changeAccountSetupFlowStepModern,
  submittedConfirmProfile,
} from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import type { IDENTITY_FIRST_FLOW_STEP_VALUES } from '~/static-constants';
import { Checkbox } from '~/toolbox/checkbox';
import { Divider } from '~/toolbox/divider';

import { ProfileConfirmationCertificationText } from './ProfileConfirmationCertificationText';
import { ProfileConfirmationRequiredDocuments } from './ProfileConfirmationRequiredDocuments';

const StyledEditLabel = styled(PM)`
  cursor: pointer;
`;

export const ProfileConfirmation = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const userProfileData = useSelector<UserProfileData | null>(
    ({ newFlows: { IDENTITY_FIRST } }) => {
      const primary = IDENTITY_FIRST.input?.primary;
      const primarySsn = IDENTITY_FIRST.input?.primarySsn;
      const suitability = IDENTITY_FIRST.input?.suitability;

      if (!primary) {
        return null;
      }

      return {
        phoneNumber: primary?.phoneNumber,
        firstName: primary?.firstName,
        lastName: primary?.lastName,
        middleInitial: primary?.middleInitial,
        suffix: primary?.suffix,
        homeAddress: primary?.homeAddress,
        dateOfBirth: primary?.dateOfBirth,
        countryOfCitizenship: primary?.countryOfCitizenship,
        trustedContact: primary?.trustedContact,
        primarySsn,
        annualIncome: suitability?.annualIncomeAmount,
        liquidNetWorth: suitability?.liquidNetWorth,
      };
    },
  );

  let rows = [] as SectionRowData[];
  if (userProfileData) {
    rows = getKeyValuePairsFromInput(userProfileData);
  }

  const handleCheckbox = () => {
    if (!isChecked) {
      analytics.recordEvent('onboarding_profile_confirmation_certify');
    }
    setIsChecked(!isChecked);
  };

  const handleSubmit = () => {
    dispatch(submittedConfirmProfile({ holder: 'primary' }));
    analytics.recordEvent('onboarding_profile_confirmation');
  };

  return (
    <Box width={560} mx="auto">
      <HM
        content="Great. Is everything correct? You’ll pick an account next."
        pt={16}
        pb={24}
        textAlign="left"
      />
      <Flex justifyContent="space-between">
        <PL content="Profile details" fontWeight={600} />
        <StyledEditLabel
          content="Edit"
          fontWeight={600}
          onClick={() =>
            dispatch(
              changeAccountSetupFlowStepModern(
                'contact-info' as IDENTITY_FIRST_FLOW_STEP_VALUES,
              ),
            )
          }
          color="primary"
        />
      </Flex>
      <Divider />
      <Flex flexDirection="column">
        {rows.map((row) => {
          return (
            <Flex key={`${row.label}-${JSON.stringify(row.value)}`}>
              <Flex width={150}>
                <PL
                  color="foregroundNeutralSecondary"
                  content={row.label}
                  key={row.label}
                  py={4}
                />
              </Flex>
              <Flex width={256}>
                <ValueToDisplay value={row.value} />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <ProfileConfirmationCertificationText />
      <Checkbox
        label={<PXL content="I certify the above is true" fontWeight={600} />}
        checked={isChecked}
        onChange={handleCheckbox}
        mb={32}
      />
      <ProfileConfirmationRequiredDocuments />
      <Button
        size="large"
        label="Confirm"
        mt={34}
        mb={24}
        disabled={!isChecked}
        onClick={handleSubmit}
      />
    </Box>
  );
};
