import { Flex, HL, HXXS, PM } from '@m1/liquid-react';
import * as React from 'react';

import { formatCurrency } from '~/utils/formatting';

import { BACKTESTING_INITIAL_VALUE } from '../SharePieDetailsSection.utils';

type SharedPieMetricsProps = {
  valuePercentChange?: number | null;
  dividendYield?: number | null;
  netExpenseRatio?: number | null;
  sinceDate: string;
};

export const SharedPieMetrics = ({
  valuePercentChange,
  dividendYield,
  netExpenseRatio,
  sinceDate,
}: SharedPieMetricsProps) => {
  const gainInDollars = React.useMemo((): string => {
    const gain = valuePercentChange
      ? Number(
          (BACKTESTING_INITIAL_VALUE * (valuePercentChange / 100)).toFixed(0),
        )
      : null;

    if (!gain || !Number.isFinite(gain)) {
      return '--';
    }

    const isNegative = gain < 0;

    return `${isNegative ? '-' : ''} ${formatCurrency(Math.abs(gain), 'SELECTIVE')}`;
  }, [valuePercentChange]);

  const divYieldValue =
    typeof dividendYield === 'number' ? `${dividendYield.toFixed(2)}%` : '--';

  const expenseRatioValue =
    netExpenseRatio !== null ? `${netExpenseRatio}%` : '--';

  return (
    <Flex
      justifyContent="space-between"
      maxWidth={1200}
      width="100%"
      margin="56px auto 0"
      flexWrap="wrap"
      gap="32px"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        m="24px auto 0"
        flexBasis="calc(33% - 64px)"
        minWidth={300}
      >
        <HL
          color="foregroundNeutralMain"
          content={gainInDollars}
          fontWeight={300}
        />
        <HXXS fontWeight={300} color="foregroundNeutralSecondary" mt={12}>
          Market gain
        </HXXS>
        <PM
          fontWeight={400}
          color="foregroundNeutralTertiary"
          content={`on a hypothetical initial investment of ${formatCurrency(BACKTESTING_INITIAL_VALUE, 'SELECTIVE')} on ${sinceDate}¹`}
          textAlign="center"
        />
      </Flex>
      <Flex
        alignItems="center"
        flexDirection="column"
        m="24px auto 0"
        flexBasis="calc(33% - 64px)"
        minWidth={300}
      >
        <HL
          color="foregroundNeutralMain"
          content={divYieldValue}
          fontWeight={300}
        />
        <HXXS fontWeight={300} color="foregroundNeutralSecondary" mt={12}>
          Dividend yield
        </HXXS>
        <PM
          fontWeight={400}
          color="foregroundNeutralTertiary"
          textAlign="center"
        >
          Weighted average dividend yield for this portfolio based on trailing
          twelve month dividends
        </PM>
      </Flex>
      <Flex
        alignItems="center"
        flexDirection="column"
        m="24px auto 0"
        flexBasis="calc(33% - 64px)"
        minWidth={300}
      >
        <HL
          color="foregroundNeutralMain"
          content={expenseRatioValue}
          fontWeight={300}
        />
        <HXXS fontWeight={300} color="foregroundNeutralSecondary" mt={12}>
          Expense ratio
        </HXXS>
        <PM
          fontWeight={400}
          color="foregroundNeutralTertiary"
          textAlign="center"
        >
          Weighted average expense ratio for this portfolio. Expense ratio is
          associated with ETFs in this portfolio and charged by the fund, not
          M1.²
        </PM>
      </Flex>
    </Flex>
  );
};
