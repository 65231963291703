import find from 'lodash-es/find';

import { enumsByName } from '~/graphql/enumsByName';

export type EnumName = keyof typeof enumsByName;

/**
 * Entry associated with an enumeration containing a name and corresponding
 * description.
 */
export interface EnumEntry {
  name: string;
  description: string;
}

/**
 * Returns an array of objects containing the name and the description associated
 * with the specified enum name.
 *
 * @example
 *   console.log(getEnumEntries('AchTransferRejectionReasonEnum');
 *   >> [{ 'name': 'UNKNOWN', 'description': 'The rejection reason is unknown.' }]
 */
export function getEnumEntries(enumName: EnumName): EnumEntry[] {
  const matchingEntries = enumsByName[enumName] ?? null;

  if (matchingEntries === null) {
    throw new Error(`Could not find enum ${enumName}`);
  }

  return matchingEntries as EnumEntry[];
}

/**
 * Returns an array of enum values that are associated with the specified enum
 * name.
 */
export function getEnumValues<T extends string>(enumName: EnumName): T[] {
  const entries = getEnumEntries(enumName);

  return entries.map((entry) => entry.name) as T[];
}

/**
 * Finds the description of given searchValue from the specified enum group
 * name (if found) and returns the result.
 * @param searchValue Value of the "name" field to search for within the enum group
 * @param enumName Name of the enum group to search
 * @param defaultValue Value to display if searchValue isn't in enum entries
 */
export function getEnumDescription(
  searchValue: string | null | undefined,
  enumName: EnumName,
  defaultValue: string = '',
): string {
  const enumEntries = getEnumEntries(enumName);

  const found = find(enumEntries, (item) => item.name === searchValue);

  return found?.description ?? defaultValue;
}
