import { type BoxProps } from '@m1/liquid-react';
import { Banner, type BannerKind } from '@m1/liquid-react/Banner';
import type { AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { AppActionEnum, InformationBannerFragment } from '~/graphql/types';
import { Container } from '~/toolbox/container';

import { AppButton } from '../AppButton';
import { LinkableLink } from '../LinkableLink';

export type InformationBannerProps = Omit<BoxProps, 'onClick'> & {
  appBanner: InformationBannerFragment | null | undefined;
  onClick?: (action?: Maybe<AppActionEnum>) => void;
  contain?: boolean;
};

const BannerComponent = ({
  banner,
  onClick,
  ...rest
}: {
  banner: InformationBannerFragment;
  onClick?: (action?: Maybe<AppActionEnum>) => void;
}) => {
  const bannerKind: BannerKind = React.useMemo(() => {
    switch (banner?.kind) {
      case 'CAUTION':
        return 'warning';
      case 'DANGER':
        return 'alert';
      case 'SUCCESS':
        return 'success';
      default:
        return 'information';
    }
  }, [banner?.kind]);

  return (
    <Banner
      {...rest}
      action={
        <>
          {/* Lens ensures link XOR button is returned, so safe to render both conditionally */}
          {banner.link?.__typename === 'AppLink' && (
            <LinkableLink linkable={banner.link} onClick={() => onClick?.()} />
          )}
          {banner.button && (
            <AppButton
              appButton={banner.button}
              kind="link"
              onClick={() => onClick?.(banner.button?.action)}
            />
          )}
        </>
      }
      content={banner.label}
      kind={bannerKind}
      icon={
        (banner.icon?.names && (banner.icon.names[0] as AllIconName)) ||
        undefined
      }
    />
  );
};

export const InformationBanner = ({
  appBanner,
  contain = true,
  ...rest
}: InformationBannerProps) => {
  // Some informational banners require a container to be displayed correctly
  // Some are hindered by the set max-width and other constrictive styles of the container
  // Container defaults to true
  if (appBanner) {
    if (!contain) {
      return <BannerComponent banner={appBanner} {...rest} />;
    }

    return (
      <Container>
        <BannerComponent banner={appBanner} {...rest} />
      </Container>
    );
  }

  return null;
};
