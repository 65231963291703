import { Flex, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';
import { components } from 'react-select';

export const TransferParticipantPlaceholder = (props: any) => {
  const isDisabled = props.selectProps.isDisabled;
  return (
    <components.Placeholder {...props}>
      <Flex alignItems="center" gap={16}>
        <Icon
          name={
            isDisabled === true
              ? 'accountAccountsDisabled32'
              : 'accountAccountsPrimary32'
          }
        />
        <PL
          color={
            isDisabled === true
              ? 'foregroundNeutralTertiary'
              : 'foregroundNeutralMain'
          }
          content="Select account"
        />
      </Flex>
    </components.Placeholder>
  );
};
