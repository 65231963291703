import { type Enhancer, withProps } from '~/hocs';
import { formatCurrencyInput } from '~/utils/formatting';
import { normalizeCurrency } from '~/utils/normalizers';

import { AmountField, type AmountFieldProps } from './amount-field';

const enhancer: Enhancer<AmountFieldProps, any> = withProps({
  name: 'amount',
  normalize: normalizeCurrency,
  format: formatCurrencyInput,
  autoComplete: 'off',
  maxLength: 9,
});

export const SliceOrderAmountField = enhancer(AmountField) as any;
