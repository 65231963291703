import { Box, Flex, HXS, PM, PXL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { PercentGain } from '~/components/percent-gain';
import type { EditModelSlice } from '~/pages/share/SharePiePage.types';
import { useSelector } from '~/redux/hooks';

type SharePiePagePieChartHighlightsProps = {
  sinceDate: string;
  slices: Array<EditModelSlice>;
  percentChange: Maybe<number>;
};

const StyledContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: $z-index-high;
  width: 19rem;
  height: 19rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: 0 0 0;
  text-align: center;
`;

const StyledPercentGain = styled(PercentGain)`
  justify-content: center;
`;

export const SharePieDonutChartHighlights = ({
  slices,
  sinceDate,
  percentChange,
}: SharePiePagePieChartHighlightsProps) => {
  const highlightedSlice = useSelector(
    (state) => state.interface.highlightedSliceId,
  );

  const slice = slices.find(
    (s: any) => s.to.id && s.to.id === highlightedSlice,
  );

  const hasPercentChange = typeof percentChange === 'number';

  return (
    <StyledContainer>
      <Box pt={4}>
        <Flex flexDirection="column" maxWidth="200px">
          {slice && (
            <>
              {slice.to.name && (
                <PXL
                  content={slice.to.name}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                />
              )}
              <PXL content={`${slice.percentage}%`} fontWeight={600} />
            </>
          )}
        </Flex>

        {sinceDate && !slice && hasPercentChange && (
          <>
            <HXS>
              <StyledPercentGain value={percentChange} />
            </HXS>
            <PM mt={4} content="Market gain since" />
            <PM content={`${sinceDate}¹`} />
          </>
        )}
      </Box>
    </StyledContainer>
  );
};
