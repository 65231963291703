import { Box, Button, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { ModelPortfoliosCategoriesQuery } from '~/graphql/types';
import { clickedOnPieCategory } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { CategoryCardBackground } from './CategoryCardBackground';
import { CategoryCardHeader } from './CategoryCardHeader';
import { CategoryGoalDescription } from './CategoryGoalDescription';

export type CategorySnapshotProps = {
  subCategory: ModelPortfoliosCategoriesQuery['viewer']['screenSystemPies']['subCategories'][number];
};

const StyledCategorySnapshotRootBox = styled(Box)`
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;

const StyledCategorySnapshotHeaderBox = styled(Box)`
  position: relative;
  display: flex;
  min-height: 165px;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 16px 20px;
  overflow: hidden;
  background: rgba(15, 15, 15, 0.3);
  border-radius: 1px;
  transition: all 0.2s ease-in-out;

  > * {
    width: 100%;
  }
`;

const StyledCategorySnapshotBottomBox = styled(Box)`
  display: flex;
  min-height: 207px;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 20px 14px 20px;
`;

const StyledCategorySnapshotLinkBox = styled(Box)`
  position: relative;
  text-align: right;
`;

export const CategoryCard = ({ subCategory }: CategorySnapshotProps) => {
  const categoryDetails = subCategory.categoryDetails[0];
  const dispatch = useDispatch();

  const handleClick = React.useCallback(() => {
    dispatch(clickedOnPieCategory(categoryDetails.key));
  }, [categoryDetails.key, dispatch]);

  return (
    <StyledCategorySnapshotRootBox mb={30} onClick={handleClick}>
      <StyledCategorySnapshotHeaderBox>
        {categoryDetails.featureUrl && (
          <CategoryCardBackground featureUrl={categoryDetails.featureUrl} />
        )}
        <CategoryCardHeader
          name={categoryDetails.name}
          description={categoryDetails.description}
        />
      </StyledCategorySnapshotHeaderBox>
      <StyledCategorySnapshotBottomBox>
        {categoryDetails.suitability && (
          <CategoryGoalDescription suitability={categoryDetails.suitability} />
        )}
        <StyledCategorySnapshotLinkBox>
          <Button kind="link" label="View Pies" onClick={handleClick} />
        </StyledCategorySnapshotLinkBox>
      </StyledCategorySnapshotBottomBox>
    </StyledCategorySnapshotRootBox>
  );
};
