import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { SecurityDetailFragment } from '~/graphql/types';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { MissingSecurityData } from '../MissingSecurityData';
import { SecurityDataContainer } from '../SecurityDataContainer';

import { CryptoProfileData } from './CryptoProfileData';
import { CryptoWhitePaperLink } from './CryptoWhitePaperLink';
import { EquityProfileData } from './EquityProfileData';
import { SecurityProfileDescription } from './SecurityProfileDescription';
import { SecuritySecUrl } from './SecuritySecUrl';
import { SecurityWebsiteLink } from './SecurityWebsiteLink';

type SecurityDataProfileDataProps = {
  security: SecurityDetailFragment;
};

const StyledFlexContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;

  @media screen and ${RESPONSIVE_BREAKPOINTS.TABLET},
    screen and ${RESPONSIVE_BREAKPOINTS.MEDIUM} {
    flex-wrap: wrap-reverse;
  }
`;

export const SecurityProfileDataContainer = ({
  security,
}: SecurityDataProfileDataProps) => {
  const { fundamentals, profile, market, __typename } = security;
  const fundamentalsNull =
    !fundamentals ||
    Object.values(fundamentals).every((value) => value === null);
  const profileNull =
    !profile || Object.values(profile).every((value) => value === null);

  const isCrypto = __typename === 'CryptoAsset';

  if (fundamentalsNull && profileNull && !market) {
    return (
      <SecurityDataContainer
        headingContent="Profile"
        flexDirection="column"
        maxWidth={790}
      >
        <MissingSecurityData title="No profile data" />
      </SecurityDataContainer>
    );
  }
  return (
    <SecurityDataContainer
      headingContent="Profile"
      flexDirection="column"
      maxWidth={790}
    >
      <StyledFlexContainer>
        <Flex
          flexDirection="column"
          backgroundColor="backgroundNeutralMain"
          borderRadius={8}
          padding={20}
        >
          <SecurityProfileDescription description={profile?.longDescription} />
          {profile?.website && (
            <SecurityWebsiteLink
              isCrypto={isCrypto}
              website={profile.website}
              websiteIcon={profile.websiteIcon}
            />
          )}
          {profile?.securitySecUrl && (
            <SecuritySecUrl
              securitySecUrl={profile.securitySecUrl}
              secIcon={profile.secIcon}
            />
          )}
          {isCrypto && security.whitepaperLink && (
            <CryptoWhitePaperLink
              whitepaperIcon={security.whitepaperIcon}
              whitepaperLink={security.whitepaperLink}
            />
          )}
        </Flex>
        <Flex flexDirection="column" ml={20}>
          {security.__typename === 'CryptoAsset' ? (
            <CryptoProfileData crypto={security} />
          ) : (
            <EquityProfileData security={security} />
          )}
        </Flex>
      </StyledFlexContainer>
    </SecurityDataContainer>
  );
};
