import { Box, Flex, styled, css } from '@m1/liquid-react';
import * as React from 'react';

import { formatPercentage } from '~/utils/formatting';

export type PercentGainProps = {
  className?: string;
  includeParenthesis?: boolean;
  value: (number | null | undefined) | string;
};

const StyledRoot = styled(Flex)<{
  $value: number;
}>`
  align-items: center;
  justify-content: end;

  ${({ theme, $value }) => {
    if ($value > 0) {
      return css`
        color: ${theme.colors.success};
      `;
    }
    if ($value < 0) {
      return css`
        color: ${theme.colors.critical};
      `;
    }
  }};
`;

const StyledArrow = styled(Flex)`
  display: inline-flex;
`;

export const PercentGain = ({
  className,
  includeParenthesis = false,
  value,
}: PercentGainProps) => {
  if (typeof value !== 'number') {
    return (
      <span className={className} data-testid="percent-gain">
        {includeParenthesis ? (
          <Flex alignItems="center" inline>
            (<PercentGainAmount>--%</PercentGainAmount>)
          </Flex>
        ) : (
          <Flex alignItems="center" inline>
            <PercentGainAmount>--%</PercentGainAmount>
          </Flex>
        )}
      </span>
    );
  }

  return (
    <StyledRoot className={className} $value={value}>
      {includeParenthesis ? (
        <Flex alignItems="center" inline>
          (<StyledArrow>{value >= 0 ? '▲' : '▼'}</StyledArrow>
          <PercentGainAmount>
            {formatPercentage(Math.abs(value) / 100)}
          </PercentGainAmount>
          )
        </Flex>
      ) : (
        <Flex alignItems="center" inline>
          <StyledArrow>{value >= 0 ? '▲' : '▼'}</StyledArrow>
          <PercentGainAmount>
            {formatPercentage(Math.abs(value) / 100)}
          </PercentGainAmount>
        </Flex>
      )}
    </StyledRoot>
  );
};

const PercentGainAmount = ({ children }: React.PropsWithChildren) => (
  <Box as="span" data-testid="percent-gain-amount">
    {children}
  </Box>
);
