import { Box } from '@m1/liquid-react';
import head from 'lodash-es/head';
import last from 'lodash-es/last';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { AudioGraph } from '~/components/AudioGraph';
import { SecondaryHistoricalChart } from '~/components/charts';
import { useGetSecurityHistoricalQuotesQuery } from '~/graphql/hooks';
import type {
  GetSecurityHistoricalQuotesQueryVariables,
  SecurityHistoricalQuoteFragment,
} from '~/graphql/types';
import {
  clearSecurityDateRange,
  clearSecuritySnapshot,
  setSecurityDateRange,
  setSecuritySnapshot,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { INITIAL_VARIABLES, PERIODS } from './constants';
import { makeNotationFromQuote } from './Notations';
import {
  type DataPoint,
  makeHistoricalQuoteData,
  makeIntradayQuoteData,
} from './utils';

type SecurityHistoricalChartProps = {
  chartHeight?: number;
  chartWidth?: number;
  isModal?: boolean;
  securityId: string;
};

const moment = extendMoment(Moment as any);

export const SecurityHistoricalChart = ({
  chartHeight,
  chartWidth,
  isModal = false,
  securityId,
}: SecurityHistoricalChartProps) => {
  const dispatch = useDispatch();
  const { period } = useSelector((state) => state.securityDetails);
  const { analytics } = React.useContext(AppContext);

  const { data, loading, variables, refetch } =
    useGetSecurityHistoricalQuotesQuery({
      variables: {
        ...INITIAL_VARIABLES,
        id: securityId,
      },
    });

  const security = data?.security as
    | SecurityHistoricalQuoteFragment
    | null
    | undefined;

  const intradayQuoteData = security?.intradayQuotes;
  const historicalQuoteData = security?.historicalQuotes;

  const isIntradayPeriod = ['LATEST_DAY', 'LATEST_WEEK'].includes(period);
  const isCrypto = Boolean(security?.__typename === 'CryptoAsset');

  const securityData = React.useMemo(() => {
    if (isIntradayPeriod && intradayQuoteData) {
      return makeIntradayQuoteData(intradayQuoteData.series, isCrypto);
    } else if (historicalQuoteData) {
      return makeHistoricalQuoteData(historicalQuoteData, isCrypto);
    }
    return [] as DataPoint[];
  }, [isIntradayPeriod, intradayQuoteData, historicalQuoteData, isCrypto]);

  const intradayDateMinutes = React.useMemo(() => {
    const isLatestDay = period === 'LATEST_DAY';
    if (!isLatestDay || !intradayQuoteData?.timeSpan) {
      return null;
    }

    const { firstOpenTime, lastCloseTime } = intradayQuoteData.timeSpan;
    return Array.from(
      moment.range(firstOpenTime as any, lastCloseTime as any).by('minutes'),
    ).map((m: any) => m.toISOString());
  }, [period, intradayQuoteData]);

  const getStartLineValue = (data: Array<Record<string, any>>) => {
    if (!Array.isArray(data) || data.length < 2 || period !== 'LATEST_DAY') {
      return null;
    }
    return head(data)?.previousClosePrice;
  };

  const getNotations = () => {
    if (isIntradayPeriod || !historicalQuoteData) {
      return [];
    }

    return historicalQuoteData
      .filter((quote) => quote.dividends.length > 0 || quote.split)
      .map(makeNotationFromQuote);
  };

  React.useEffect(() => {
    const newVariables = isIntradayPeriod
      ? {
          intradayPeriod: period,
          intradayInterval:
            period === 'LATEST_DAY' ? 'ONE_MINUTE' : 'FIVE_MINUTE',
          useIntradayPeriod: true,
          useSecurityPeriod: false,
        }
      : {
          securityPeriod: period,
          useIntradayPeriod: false,
          useSecurityPeriod: true,
        };

    if (variables?.securityPeriod) {
      analytics.recordAppAnalyticsEvent({
        name: 'm1_security_details_chart_filter_changed',
        customParameters: [
          {
            name: 'from_filter',
            value: variables?.securityPeriod,
          },
          {
            name: 'to_filter',
            value: period,
          },
        ],
        customBoolParameters: [],
        customNumberParameters: [],
        valueParameter: null,
      });
    }

    refetch(newVariables as GetSecurityHistoricalQuotesQueryVariables);
  }, [period, analytics, isIntradayPeriod, refetch, variables?.securityPeriod]);

  React.useEffect(() => {
    if (securityData.length > 2 && period !== 'LATEST_DAY') {
      const latestTrade = last([...securityData]);
      dispatch(
        setSecurityDateRange({
          first: head([...securityData])?.date,
          last: latestTrade?.date,
        }),
      );

      if (latestTrade?.percentChange && latestTrade.valueChange) {
        dispatch(
          setSecuritySnapshot({
            percentChange: latestTrade.percentChange,
            valueChange: latestTrade.valueChange,
          }),
        );
      }
    }
    return () => {
      dispatch(clearSecurityDateRange());
      dispatch(clearSecuritySnapshot());
    };
  }, [dispatch, securityData, period]);

  if (loading) {
    return (
      <Box minHeight={360}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box minHeight={360}>
      <SecondaryHistoricalChart
        data={securityData}
        periodOptions={PERIODS}
        period={period}
        startLineValue={getStartLineValue(securityData)}
        notations={getNotations()}
        intradayDateMinutes={intradayDateMinutes}
        loading={loading}
        chartHeight={isModal ? chartHeight : undefined}
        chartWidth={isModal ? chartWidth : undefined}
      />
      <AudioGraph
        valueHistory={securityData}
        dateFieldName="date"
        valueFieldName="value"
      />
    </Box>
  );
};
