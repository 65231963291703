import { Flex, HXXS, Skeleton, styled, theme } from '@m1/liquid-react';
import * as React from 'react';

import type {
  HomeTilesSectionConnectionFragment,
  HomeTilesSectionFragment,
} from '~/graphql/types';
import { isNotNil } from '~/utils';

import { HomeTile } from './HomeTile';

type HomeTilesSectionProps = {
  homeTilesSectionConnection:
    | HomeTilesSectionConnectionFragment
    | null
    | undefined;
  loading: boolean;
};

const Column = styled(Flex)`
  flex: 1;
  order: ${(props) => props.order};
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    width: 50%;
    max-width: 50%;
  }
`;

type HomeTileProps = HomeTilesSectionFragment['tiles'][number];

const aPlaceholderTile = ({
  title,
  ...rest
}: Partial<HomeTileProps> & { title: string }): HomeTileProps => ({
  title,
  subtitle: null,
  icon: null,
  illustration: null,
  primaryAction: null,
  analyticsEvent: null,
  secondaryAction: null,
  value: null,
  subValue: null,
  ...rest,
});

const mockLoadingSections: HomeTilesSectionFragment[] = [
  {
    header: 'Assets',
    tiles: [
      aPlaceholderTile({
        title: 'Placeholder 1',
      }),
      aPlaceholderTile({
        title: 'Placeholder 2',
      }),
    ],
  },
  {
    header: 'Liabilities',
    tiles: [
      aPlaceholderTile({
        title: 'Placeholder 3',
      }),
      aPlaceholderTile({
        title: 'Placeholder 4',
      }),
    ],
  },
];

export const HomeTilesSections = ({
  homeTilesSectionConnection,
  loading,
}: HomeTilesSectionProps) => {
  const homeTilesSections = loading
    ? mockLoadingSections
    : readSectionsFromConnection(homeTilesSectionConnection);
  return homeTilesSections.map((section, idx) => {
    return (
      <Column key={section.header} order={(idx % 2) + 1}>
        <Skeleton>
          <HXXS
            content={section.header}
            fontWeight={300}
            color="foregroundNeutralMain"
          />
        </Skeleton>
        <Flex flexDirection="column" gap={16}>
          {section.tiles.filter(isNotNil).map((tile) => (
            <Skeleton key={tile.title} skeletonWidth="100%">
              <HomeTile tile={tile} />
            </Skeleton>
          ))}
        </Flex>
      </Column>
    );
  });
};

const readSectionsFromConnection = (
  homeTilesSectionConnection:
    | HomeTilesSectionConnectionFragment
    | null
    | undefined,
): HomeTilesSectionFragment[] => {
  const sections: HomeTilesSectionFragment[] = [];
  if (
    homeTilesSectionConnection &&
    Array.isArray(homeTilesSectionConnection.edges)
  ) {
    for (const edge of homeTilesSectionConnection.edges) {
      if (edge && edge.node) {
        sections.push(edge.node);
      }
    }
  }
  return sections;
};
