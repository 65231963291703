import { Box, Flex, PL, PM, Card } from '@m1/liquid-react';
import * as React from 'react';

import type { RelatedTransactionsFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

import { RewardsSingularProgramTableCell } from './RewardsSingularProgramTableCell';
export type RewardsSingularProgramTableComponentProps = {
  rewardsTransactions: RelatedTransactionsFragment | null | undefined;
};

export const RewardsSingularProgramTable = ({
  rewardsTransactions,
}: RewardsSingularProgramTableComponentProps) => {
  if (!rewardsTransactions?.edges?.length) {
    return (
      <Card p={16}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <PL fontWeight={600} pb={16} content="You have no transactions yet" />
          <PM content="When you use your card, the qualifying transactions will appear here" />
        </Flex>
      </Card>
    );
  }

  return (
    <Box mb={32}>
      <GridTable
        emptyMessage="You have no transactions yet"
        gridTemplateColumns="auto 140px"
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Merchant" />
          <GridTable.HeaderCell label="Transaction amount" />
        </GridTable.HeaderRow>
        {rewardsTransactions.edges.map((transaction, index) => {
          return (
            transaction?.node && (
              <RewardsSingularProgramTableCell
                transaction={transaction.node}
                index={index}
              />
            )
          );
        })}
      </GridTable>
    </Box>
  );
};
