import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { useSelector } from '~/redux/hooks';
import { FilterOrTogglePopup } from '~/toolbox/filter-or-toggle-popup';

import type { InvestActivityFormValues } from '../ActivityPage';

import { FilterByTypeOption } from './FilterByTypeOption';
import { ResetTypeButton } from './ResetTypeButton';

export const FilterByTypePopup = () => {
  const { getValues } = useFormContext<InvestActivityFormValues>();
  const isCrypto = useSelector<boolean>(
    (state) => state.global.activeAccountIsCrypto,
  );

  const readCardLabel = (): string => {
    const {
      includeCategoryCash,
      includeCategoryDividend,
      includeCategoryPosition,
      includeCategoryTrading,
      includeCategoryTransfer,
    } = getValues();

    const categoryMappings = [
      { name: 'Trading', value: includeCategoryTrading },
      { name: 'Dividends', value: includeCategoryDividend },
      { name: 'Deposits & withdrawals', value: includeCategoryTransfer },
      { name: 'Cash movements', value: includeCategoryCash },
      { name: 'Position changes', value: includeCategoryPosition },
    ];

    const activeFilters = categoryMappings.reduce<string[]>(
      (acc, { name, value }) => {
        if (value) {
          acc.push(name);
        }
        return acc;
      },
      [],
    );

    const numberOfActiveFilters = activeFilters.length;

    if (
      numberOfActiveFilters === 0 ||
      numberOfActiveFilters === categoryMappings.length
    ) {
      return 'Activity type';
    }

    return numberOfActiveFilters === 1
      ? activeFilters[0]
      : `${activeFilters[0]} + ${numberOfActiveFilters - 1}`;
  };

  return (
    <FilterOrTogglePopup placement="bottom-end" label={readCardLabel()}>
      <Box minWidth={250} py={8} px={4}>
        <ResetTypeButton />
        <FilterByTypeOption name="includeCategoryTrading" label="Trading" />
        {!isCrypto && (
          <FilterByTypeOption
            name="includeCategoryDividend"
            label="Dividends"
          />
        )}
        <FilterByTypeOption
          name="includeCategoryTransfer"
          label="Deposits & withdrawals"
        />
        <FilterByTypeOption name="includeCategoryCash" label="Cash movements" />
        <FilterByTypeOption
          name="includeCategoryPosition"
          label="Position changes"
        />
      </Box>
    </FilterOrTogglePopup>
  );
};
