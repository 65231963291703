import {
  type ClickedAddSharedPieAction,
  type ClickedAddToPortfolioAction,
  type ClickedCancelOrderAction,
  type ClickedConnectBankAction,
  type ClickedOnPieCategoryAction,
  type ClickedOnPortfolioSliceAction,
  type ClickedSetOrderAction,
  type ConfirmCancelOrderAction,
  EVENT_ACTIONS,
} from './eventsActions.types';

export const clickedCancelOrder = (
  payload: ClickedCancelOrderAction['payload'],
): ClickedCancelOrderAction => ({
  payload,
  type: EVENT_ACTIONS.CLICKED_CANCEL_ORDER,
});

export const confirmCancelOrder = (
  payload: ConfirmCancelOrderAction['payload'],
): ConfirmCancelOrderAction => ({
  payload,
  type: EVENT_ACTIONS.CONFIRM_CANCEL_ORDER,
});

export const clickedAddSharedPie = (
  payload: ClickedAddSharedPieAction['payload'],
): ClickedAddSharedPieAction => ({
  payload,
  type: EVENT_ACTIONS.CLICKED_ADD_SHARED_PIE,
});

export const clickedConnectBank = (
  payload: ClickedConnectBankAction['payload'],
): ClickedConnectBankAction => ({
  payload,
  type: EVENT_ACTIONS.CLICKED_CONNECT_BANK,
});

export const clickedAddToPortfolio = (
  payload: ClickedAddToPortfolioAction['payload'],
): ClickedAddToPortfolioAction => ({
  type: EVENT_ACTIONS.CLICKED_ADD_TO_PORTFOLIO,
  payload,
});

export const clickedOnPieCategory = (
  payload: ClickedOnPieCategoryAction['payload'],
): ClickedOnPieCategoryAction => ({
  type: EVENT_ACTIONS.CLICKED_ON_PIE_CATEGORY,
  payload: payload,
});

export const clickedOnPortfolioSlice = (
  payload: ClickedOnPortfolioSliceAction['payload'],
): ClickedOnPortfolioSliceAction => ({
  type: EVENT_ACTIONS.CLICKED_ON_PORTFOLIO_SLICE,
  payload: payload,
});

export const clickedSetOrder = (
  payload: ClickedSetOrderAction['payload'],
): ClickedSetOrderAction => ({
  type: EVENT_ACTIONS.CLICKED_SET_ORDER,
  payload: payload,
});
