import isEqual from 'lodash-es/isEqual';
import { useSelector as useReduxSelector } from 'react-redux';

import type { AppState } from '~/redux';

export const useSelector = <S>(
  selector: (state: AppState) => S,
  equalityFn?: (a: S, b: S) => boolean,
): S => {
  return useReduxSelector<AppState, S>(selector, equalityFn);
};

export const useIsEqualSelector = <S>(selector: (state: AppState) => S): S => {
  return useReduxSelector<AppState, S>(selector, isEqual);
};
