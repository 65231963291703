import { ApolloError } from '@apollo/client';
import first from 'lodash-es/first';
import type { PlaidLinkResult } from 'react-plaid-link';
import type { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  put,
  setContext,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  ConfirmManualPlaidMicroDepositsDocument,
  type ConfirmManualPlaidMicroDepositsMutationResult,
  CreateAchRelationshipViaFundingSourceDocument,
  type CreateAchRelationshipViaFundingSourceMutationResult,
  CreateFundingSourceRelationshipDocument,
  type CreateFundingSourceRelationshipMutationResult,
  LinkFundingSourcesDocument,
  type LinkFundingSourcesMutationResult,
  LinkFundingSourcesRefetchDocument,
} from '~/graphql/hooks';
import type {
  ConfirmManualPlaidMicroDepositsInput,
  CreateAchRelationshipViaFundingSourceInput,
  CreateAchRelationshipViaFundingSourceMutation,
  CreateFundingSourceRelationshipInput,
  CreateFundingSourceRelationshipMutation,
  CreateFundingSourceRelationshipMutationVariables,
  LinkFundingSourcesInput,
} from '~/graphql/types';
import type { Navigate, NavigateFunction } from '~/hooks/useNavigate';
import {
  ACTION_TYPES as ACTIONS,
  changedConnectBankFlowStep,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';
import type { BankConnectionType } from '~/redux/reducers/newFlows/reducers/connectBankReducer';
import { fetchPersonalLoansApplicationInfo } from '~/redux/sagas/flows/personalLoansApplication/personalLoansApplicationQuery';
import {
  CONNECT_BANK_FLOW_STEPS as STEPS,
  PLAID_VERIFICATION_STATUS,
} from '~/static-constants';
import type { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../apolloMutationSaga';
import { getLaunchDarkly, getLoggers } from '../common';
import { select } from '../effects';

import { pickContext } from './utils';

/*
 * Controller for Connect Bank flow
 */
export function* connectBankSaga(): Generator<any, any, any> {
  yield takeLatest(ACTIONS.BEGIN_CONNECT_BANK_FLOW, beginConnectBankFlow);
}

// [redux-action-consolidate]
type BeginConnectBankFlowAction = {
  payload: {
    accountId?: string;
    canNavigateBackFromMicrodeposits: boolean;
    connectionType: BankConnectionType;
    fundingSourceId: string | null | undefined;
    initialStep: string;
    onFinish: (...args: Array<any>) => any;
    plaidFailure: boolean;
    plaidRedirect: PlaidLinkResult;
    redirectUrl: string;
    isInitialFundingFlow?: boolean;
  };
  type: 'BEGIN_CONNECT_BANK_FLOW';
};

type HandleCreateFundingSourceRelationshipArgs = {
  fundingSourceId: string | null | undefined;
};

const FUNDING_SOURCE_CONNECTION_TYPES = [
  'savings',
  'personal_loans',
  'personal_loans_direct',
  'fs_standard',
];

const PLAID_VERIFICATION_PENDING_STATES = [
  PLAID_VERIFICATION_STATUS.PENDING_MANUAL_VERIFICATION,
  PLAID_VERIFICATION_STATUS.PENDING_AUTOMATIC_VERIFICATION,
];

export function* beginConnectBankFlow(
  action: BeginConnectBankFlowAction,
): SagaIterator {
  const { initialStep, onFinish, plaidFailure, plaidRedirect } = action.payload;

  const achLinkEvent = action.payload.redirectUrl.includes('onboarding')
    ? 'm1_ach_relationship_link_onboarding'
    : 'm1_ach_relationship_link';
  yield setContext({
    achLinkEvent,
  });

  yield setContext(action.payload);

  if (plaidRedirect) {
    yield call(handleReceivedPlaidLinkToken, {
      payload: plaidRedirect,
    });
  } else if (plaidFailure) {
    yield call(onFinish);
  } else if (initialStep) {
    yield put(changedConnectBankFlowStep(initialStep));
  } else {
    yield put(changedConnectBankFlowStep(STEPS.SELECT_PLAID));
  }

  yield takeEvery(
    ACTIONS.RECEIVED_PLAID_LINK_TOKEN,
    handleReceivedPlaidLinkToken,
  );
  yield takeEvery(ACTIONS.SKIPPED_BANK_CONNECTION, handleSkippedBankConnection);
  yield takeEvery(
    ACTIONS.SKIPPED_PLAID_CONNECTION,
    handleSkippedPlaidConnection,
  );
}

function* getConnectionType(): SagaIterator<BankConnectionType> {
  return yield getContext('connectionType');
}

export function getRouteByConnectionType(
  connectionType: BankConnectionType,
  context:
    | 'SKIPPED'
    | 'PENDING_VERIFICATION'
    | 'LINKED_FUNDING_SOURCE_SUCCESS'
    | 'LINKED_FUNDING_SOURCE_FAILURE',
  personalLoansBasePath?: string,
): Navigate {
  if (context === 'SKIPPED') {
    switch (connectionType) {
      case 'invest':
        return { to: '/d/invest/bank-connection' };
      case 'savings':
        return { to: '/d/bank-connection', query: { type: 'savings' } };
      case 'personal_loans':
      case 'personal_loans_direct':
        return { to: '/d/borrow' };
      default:
        return { to: '/d/home' };
    }
  }

  if (context === 'PENDING_VERIFICATION') {
    switch (connectionType) {
      case 'invest':
        return { to: '/d/invest/bank-connection' };
      case 'savings':
        return { to: '/d/save' };
      case 'personal_loans':
      case 'personal_loans_direct':
        return { to: '/d/borrow' };
      default:
        return { to: '/d/home' };
    }
  }

  if (context === 'LINKED_FUNDING_SOURCE_SUCCESS') {
    switch (connectionType) {
      case 'personal_loans':
        return personalLoansBasePath?.includes(
          'onboarding/personal-loans-onboarding',
        )
          ? { to: '/onboarding/personal-loans-onboarding/deposit-info' }
          : { to: '/d/borrow/personal/loan-application/deposit-info' };
      case 'personal_loans_direct':
        return { to: '/direct-loan-application?step=BANK_DEPOSIT' };
      case 'savings':
        return { to: '/d/onboarding/savings-onboarding-initial-funding' };
      default:
        return { to: '/d/home' };
    }
  }

  if (context === 'LINKED_FUNDING_SOURCE_FAILURE') {
    switch (connectionType) {
      case 'personal_loans':
      case 'personal_loans_direct':
        return { to: '/d/borrow' };
      default:
        return { to: '/d/home' };
    }
  }

  return { to: '/d/home' };
}

function* handleSkippedPlaidConnection(): SagaIterator {
  yield call(handleSkippedBankConnection);
}

/*
 * Action handlers
 */

function* handleSkippedBankConnection(): SagaIterator {
  const connectionType = yield call(getConnectionType);
  const redirectUrl = yield getContext('redirectUrl');

  let route = getRouteByConnectionType(connectionType, 'SKIPPED');

  if (redirectUrl.includes('onboarding')) {
    route = { to: '/onboarding/initial-funding/' };
  }

  const { onFinish } = yield pickContext('onFinish');

  yield call(onFinish, { route });
}

function* handleLinkFundingSources({
  plaidLinkPublicToken,
  verificationStatus,
}: {
  plaidLinkPublicToken: string;
  verificationStatus: ValueOf<typeof PLAID_VERIFICATION_STATUS>;
  plaidAccountId: string;
}): SagaIterator {
  const { onFinish } = yield pickContext('onFinish');
  const connectionType = yield call(getConnectionType);

  try {
    const { data }: LinkFundingSourcesMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: LinkFundingSourcesDocument,
        variables: {
          input: { plaidLinkPublicToken } satisfies LinkFundingSourcesInput,
        },
        refetchQueries: [{ query: LinkFundingSourcesRefetchDocument }],
      },
    );

    const linkedFundingSource = first(
      data?.linkFundingSources?.outcome?.linkedFundingSources,
    );

    if (!data?.linkFundingSources?.didSucceed || !linkedFundingSource) {
      yield call(handleBankConnectionToast, 'alert');
      yield call(onFinish);

      return;
    }

    const fundingSourceId = linkedFundingSource.id;

    /* Bank connection status is pending, cannot create external bank relationship until verification is complete. */
    if (PLAID_VERIFICATION_PENDING_STATES.includes(verificationStatus)) {
      yield put(hideLoadingSpinner());

      const route = getRouteByConnectionType(
        connectionType,
        'PENDING_VERIFICATION',
      );

      yield call(onFinish, {
        route,
        verificationStatus,
      });
    } else if (FUNDING_SOURCE_CONNECTION_TYPES.includes(connectionType)) {
      /* Handle connecting funding sources */
      if (connectionType === 'savings') {
        yield call(handleCreateFundingSourceRelationshipSavings, {
          fundingSourceId,
        });
      }

      if (
        connectionType === 'personal_loans' ||
        connectionType === 'personal_loans_direct'
      ) {
        yield call(handleCreateFundingSourceRelationshipPersonalLoans, {
          fundingSourceId,
        });
      }

      if (connectionType === 'fs_standard') {
        yield call(handleCreateFundingSourceRelationshipNoAccount, {
          fundingSourceId,
        });
      }
    } else {
      /* for instant match / auth, and manually verified */
      yield call(handleCreateFundingSourceRelationship, {
        fundingSourceId,
      });
    }
  } catch (e: any) {
    yield call(handleBankConnectionToast, 'alert');
    yield call(onFinish);
    return;
  }
}

function* handleManuallyVerifiedMicroDeposits({
  // @ts-expect-error - TS7031 - Binding element 'fundingSourceId' implicitly has an 'any' type.
  fundingSourceId,
  // @ts-expect-error - TS7031 - Binding element 'plaidLinkPublicToken' implicitly has an 'any' type.
  plaidLinkPublicToken,
}): SagaIterator {
  // handle confirm and create ach relationship for same day micros
  const { analytics } = yield call(getLoggers);
  const { onFinish } = yield pickContext('onFinish');
  const connectionType = yield call(getConnectionType);

  try {
    const { data }: ConfirmManualPlaidMicroDepositsMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: ConfirmManualPlaidMicroDepositsDocument,
        variables: {
          input: {
            fundingSourceId,
            plaidPublicToken: plaidLinkPublicToken,
          } satisfies ConfirmManualPlaidMicroDepositsInput,
        },
      },
    );

    if (data?.confirmManualPlaidMicroDeposits?.didSucceed) {
      analytics.mutation('funding', 'manuallyVerifiedPlaidMicros');

      if (FUNDING_SOURCE_CONNECTION_TYPES.includes(connectionType)) {
        if (connectionType === 'fs_standard') {
          yield call(handleCreateFundingSourceRelationshipNoAccount, {
            fundingSourceId,
          });
        }
        if (connectionType === 'savings') {
          yield call(handleCreateFundingSourceRelationshipSavings, {
            fundingSourceId,
          });
        } else if (
          connectionType === 'personal_loans' ||
          connectionType === 'personal_loans_direct'
        ) {
          yield call(handleCreateFundingSourceRelationshipPersonalLoans, {
            fundingSourceId,
          });
        }
      } else {
        yield call(handleCreateFundingSourceRelationship, {
          fundingSourceId,
        });
      }
    }
  } catch (e: any) {
    yield call(handleBankConnectionToast, 'alert', e);
    yield call(onFinish);
  }
}

function* handleCreateFundingSourceRelationshipSavings({
  fundingSourceId,
}: HandleCreateFundingSourceRelationshipArgs): SagaIterator<void> {
  const { onFinish } = yield pickContext('onFinish');
  const { analytics } = yield call(getLoggers);
  const {
    accountId,
    navigate,
  }: { accountId: string | null | undefined; navigate: NavigateFunction } =
    yield select((state) => ({
      accountId: state.global.savingsAccountId,
      navigate: state.routing.navigate,
    }));

  try {
    yield call(apolloMutationSaga, {
      mutation: CreateFundingSourceRelationshipDocument,
      variables: {
        input: { accountId, fundingSourceId: fundingSourceId as string },
      } satisfies CreateFundingSourceRelationshipMutationVariables,
    });

    yield call(handleBankConnectionToast, 'success');

    analytics.recordEvent('m1_savings_relationship_created');

    const route = getRouteByConnectionType(
      'savings',
      'LINKED_FUNDING_SOURCE_SUCCESS',
    );

    yield call(navigate, route);
  } catch (e: any) {
    yield call(handleBankConnectionToast, 'alert', e);
    yield call(onFinish);
  }
}

function* handleCreateFundingSourceRelationshipPersonalLoans({
  fundingSourceId,
}: HandleCreateFundingSourceRelationshipArgs): SagaIterator<void> {
  const { onFinish } = yield pickContext('onFinish');
  const { analytics } = yield call(getLoggers);

  // pull the loan ID from the users loan application.
  // this allows a funding source to be established if the use leaves and comes back.
  const response = yield call(fetchPersonalLoansApplicationInfo, null);
  const personalLoanAccountId = response.loanId;

  // if we're connecting a bank to a loan,
  // let's ensure the loan is set within the store so the user can access it after
  yield put({
    type: ACTIONS.SET_ACTIVE_PERSONAL_LOAN_ACCOUNT,
    payload: personalLoanAccountId,
  });

  try {
    yield call(apolloMutationSaga, {
      mutation: CreateFundingSourceRelationshipDocument,
      variables: {
        input: {
          accountId: personalLoanAccountId,
          fundingSourceId: fundingSourceId as string,
        },
      } satisfies CreateFundingSourceRelationshipMutationVariables,
    });

    yield call(handleBankConnectionToast, 'success');

    const connectionType = yield call(getConnectionType);
    const personalLoansBasePath = yield select(
      (state) => state.newFlows.PERSONAL_LOANS_APPLICATION.basePath,
    );

    const route = getRouteByConnectionType(
      connectionType,
      'LINKED_FUNDING_SOURCE_SUCCESS',
      personalLoansBasePath,
    );

    yield call(onFinish, {
      route,
    });

    analytics.recordEvent('m1_personal_loans_relationship_created');
  } catch (e: any) {
    const connectionType = yield call(getConnectionType);
    yield call(handleBankConnectionToast, 'alert', e);

    const route = getRouteByConnectionType(
      connectionType,
      'LINKED_FUNDING_SOURCE_FAILURE',
    );

    yield call(onFinish, {
      route,
    });
  }
}

function* handleCreateFundingSourceRelationshipNoAccount({
  fundingSourceId,
}: HandleCreateFundingSourceRelationshipArgs): SagaIterator<void> {
  const { onFinish } = yield pickContext('onFinish');
  const { analytics } = yield call(getLoggers);

  try {
    yield call(apolloMutationSaga, {
      mutation: CreateFundingSourceRelationshipDocument,
      variables: {
        input: { fundingSourceId: fundingSourceId as string },
      } satisfies CreateFundingSourceRelationshipMutationVariables,
    });

    yield call(handleBankConnectionToast, 'success');

    const route = getRouteByConnectionType(
      'fs_standard',
      'LINKED_FUNDING_SOURCE_SUCCESS',
    );

    yield call(onFinish, {
      route,
    });

    analytics.recordEvent('m1_funding_source_no_account_relationship_created');
  } catch (e: any) {
    yield call(handleBankConnectionToast, 'alert', e);

    const route = getRouteByConnectionType(
      'fs_standard',
      'LINKED_FUNDING_SOURCE_FAILURE',
    );

    yield call(onFinish, {
      route,
    });
  }
}

export function* handleCreateFundingSourceRelationship({
  fundingSourceId,
}: {
  fundingSourceId: string;
}): SagaIterator {
  const { analytics } = yield call(getLoggers);
  const launchDarkly = yield call(getLaunchDarkly);
  const flagResult = launchDarkly.evaluateFlag(
    'create-funding-source-relationship',
  );
  const { onFinish } = yield pickContext('onFinish');
  const achLinkEvent = yield getContext('achLinkEvent');
  const isInitialFundingFlow = yield getContext('isInitialFundingFlow');

  const accountId = yield select((state) => state.global.activeAccountId);

  // TODO - After large enough sample, remove launch darkly call & CreateAchRelationshpViaFundingSource.

  if (flagResult === true) {
    try {
      const { data }: CreateFundingSourceRelationshipMutationResult =
        yield call(apolloMutationSaga, {
          mutation: CreateFundingSourceRelationshipDocument,
          variables: {
            input: {
              accountId,
              fundingSourceId,
            } satisfies CreateFundingSourceRelationshipInput,
          },
        });

      analytics.recordEvent(achLinkEvent);

      /*
       * We do not specify a redirect route for the bank connection experiments.
       * Instead, we let the wizard handle the routing.
       */
      const route = isInitialFundingFlow
        ? undefined
        : { to: '/d/invest/fund-account' };
      // do we actually need the transfer participant here? initial funding flow makes a few checks for routing. Address with feature flag clean up.
      yield call(onFinish, {
        route,
        achRelationshipId: readFundingSourceRelationship(
          data?.createFundingSourceRelationship,
        ),
      });
    } catch (e: any) {
      yield call(handleBankConnectionToast, 'alert', e);
      yield call(onFinish);
    }
  } else {
    yield call(handleCreateAchRelationshipViaFundingSource, {
      fundingSourceId,
    });
  }
}

export function* handleCreateAchRelationshipViaFundingSource({
  fundingSourceId,
}: {
  fundingSourceId: string;
}): SagaIterator {
  const { analytics } = yield call(getLoggers);
  const { onFinish } = yield pickContext('onFinish');
  const achLinkEvent = yield getContext('achLinkEvent');
  const isInitialFundingFlow = yield getContext('isInitialFundingFlow');

  const accountId = yield select((state) => state.global.activeAccountId);
  // TODO - After large enough sample, remove launch darkly call & CreateAchRelationshpViaFundingSource.
  try {
    const { data }: CreateAchRelationshipViaFundingSourceMutationResult =
      yield call(apolloMutationSaga, {
        mutation: CreateAchRelationshipViaFundingSourceDocument,
        variables: {
          input: {
            accountId,
            fundingSourceId,
          } satisfies CreateAchRelationshipViaFundingSourceInput,
        },
      });

    analytics.recordEvent(achLinkEvent);

    /*
     * We do not specify a redirect route for the bank connection experiments.
     * Instead, we let the wizard handle the routing.
     */
    const route = isInitialFundingFlow
      ? undefined
      : { to: '/d/invest/fund-account' };

    yield call(onFinish, {
      route,
      achRelationshipId: readAchRelationshipId(
        data?.createAchRelationshipViaFundingSource,
      ),
    });
  } catch (e: any) {
    yield call(handleBankConnectionToast, 'alert', e);
    yield call(onFinish);
  }
}

function* handleReceivedPlaidLinkToken(action: any): SagaIterator {
  const {
    fundingSourceId,
    plaidLinkPublicToken,
    plaidAccountId,
    verificationStatus,
  } = action.payload;

  yield put(showLoadingSpinner());

  if (!fundingSourceId) {
    /* New account connection */
    yield call(handleLinkFundingSources, {
      plaidLinkPublicToken,
      verificationStatus,
      plaidAccountId,
    });
  } else if (
    verificationStatus === PLAID_VERIFICATION_STATUS.MANUALLY_VERIFIED
  ) {
    /* Same Day Micros - after a user manually verified micro deposit amounts */
    yield call(handleManuallyVerifiedMicroDeposits, {
      fundingSourceId,
      plaidLinkPublicToken,
    });
  } else {
    yield call(handleCreateFundingSourceRelationship, {
      fundingSourceId,
    });
  }
  yield put(hideLoadingSpinner());
}

function* handleBankConnectionToast(
  kind: ToastProps['kind'] | null | undefined,
  e?: ApolloError,
): SagaIterator {
  let content;
  switch (kind) {
    case 'success':
      content =
        'Your bank is now connected and you are now able to make a deposit';
      break;
    case 'warning':
      content = 'Your bank connection is pending';
      break;
    case 'alert': {
      /*
       * We return early here because the failure case has
       * a different payload. It returns a toast that is
       * dismissible, and also returns a link to connect
       * a different bank.
       */
      const errorCode = e?.graphQLErrors?.[0]?.extensions?.code;
      const content =
        errorCode === 'UNALLOWED_BANK'
          ? 'Unfortunately, we don’t support the bank you connected through Plaid. Please try connecting a different bank or contact Client Success for help.'
          : 'We were unable to connect your bank account. Please try again or contact Client Success for help.';

      const onFinish = yield getContext('onFinish');
      yield put({
        payload: {
          content,
          kind,
          buttonProps: {
            label: 'Connect a different bank',
            onClick: () => {
              onFinish({
                shouldClearToast: true,
                route: { to: '/d/c/connect-bank' },
                locationParams: {
                  connectionType: 'invest',
                  initialStep: 'select-plaid',
                },
              });
            },
          },
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
      return;
    }
    default:
      return null;
  }

  yield put({
    payload: {
      content,
      duration: 'long',
      kind,
    } satisfies ToastProps,
    type: 'ADD_TOAST',
  });
}

// Remove once fully adopting create funding source relationship mutation.
function readAchRelationshipId(
  createAchRelationshipViaFundingSource:
    | CreateAchRelationshipViaFundingSourceMutation['createAchRelationshipViaFundingSource']
    | null
    | undefined,
): string | null | undefined {
  if (createAchRelationshipViaFundingSource?.outcome?.achRelationship) {
    return createAchRelationshipViaFundingSource.outcome.achRelationship.id;
  }
  return null;
}

function readFundingSourceRelationship(
  relationship:
    | CreateFundingSourceRelationshipMutation['createFundingSourceRelationship']
    | null
    | undefined,
): string | null | undefined {
  if (relationship?.outcome?.fundingSourceTransferParticipant) {
    return relationship.outcome.fundingSourceTransferParticipant.id;
  }
  return null;
}
