/* eslint-disable no-param-reassign */
import type { AppAction } from '~/redux/actions';

export type UserDataState = any;

export const initialState: UserDataState = {};

export const userDataReducer = (
  state: UserDataState = initialState,
  action: AppAction,
): UserDataState => {
  switch (action.type) {
    case 'LOADED_USER_DATA_FROM_SERVER':
      return action.payload.reduce((p, v) => {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        p[v.key] = v.value;
        return p;
      }, {});
    case 'UPDATE_USER_DATA': {
      const { key, value } = action.payload;
      return Object.assign({}, state, {
        [key]: value,
      });
    }
    default:
      return state;
  }
};
