import React from 'react';

import { ScreenerFilterContainer } from '~/components/research/Screener';

import { FundLimits } from './FundLimits';
import { FundScreenerCategories } from './FundScreenerCategories';
import { useFundScreenerResult } from './useFundScreenerResult';

type FundFilterContainerProps = {
  setFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filtersOpen: boolean;
};

export const FundFilterContainer = ({
  setFiltersOpen,
  filtersOpen,
}: FundFilterContainerProps) => {
  const { handleReset, hasFilters } = useFundScreenerResult();

  return (
    <ScreenerFilterContainer
      handleReset={handleReset}
      hasFilters={hasFilters}
      filtersOpen={filtersOpen}
      setFiltersOpen={setFiltersOpen}
    >
      <FundLimits />
      <FundScreenerCategories />
    </ScreenerFilterContainer>
  );
};
