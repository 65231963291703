import * as React from 'react';

import type { FlowStep } from '~/redux/reducers/newFlows';
import { SetOrderDefinition } from '~/redux/reducers/newFlows/reducers/setOrderReducer';

import { BlockOrderStep } from './steps/BlockOrderStep';
import { BuyingPowerOverviewStep } from './steps/BuyingPowerOverviewStep';
import { ConfirmCryptoOrder } from './steps/ConfirmCryptoOrder';
import { LoadStatusStep } from './steps/LoadStatusStep';
import { OrderLockedStep } from './steps/OrderLockedStep';
import { SetupOrderStep } from './steps/SetupOrderStep';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
  step: FlowStep<typeof SetOrderDefinition>;
};

export const SetOrderContainer = (props: Props) => {
  const steps = {
    LOAD_STATUS: LoadStatusStep,
    BUYING_POWER_OVERVIEW: BuyingPowerOverviewStep,
    SETUP_ORDER: SetupOrderStep,
    ORDER_LOCKED: OrderLockedStep,
    ORDER_BLOCKED: BlockOrderStep,
    CONFIRM_CRYPTO_ORDER: ConfirmCryptoOrder,
  };

  const Step = steps[props.step];
  // @ts-expect-error BuyingPowerOverviewStep using props that are not properly typed here
  return Step ? <Step {...props} /> : null;
};
