import * as React from 'react';

import { ScreenerPriceHistory } from '~/components/research/Screener/ScreenerTable';
import type {
  CryptoSortOptionInput,
  CryptoSortTypeEnum,
} from '~/graphql/types';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { Table } from '~/toolbox/table';

import { PERIOD_TO_SORT } from './constants';
import { useCryptoScreenerResult } from './useCryptoScreenerResult';

export const CryptoResultsHeader = ({
  hasCryptoAccount,
}: {
  hasCryptoAccount: boolean | undefined;
}) => {
  const {
    queryResult: { variables },
    handleSortChange,
    handlePeriodChange,
  } = useCryptoScreenerResult();

  const addMode = useSelector((state: AppState) => state.mode === 'ADD');

  const sort = Array.isArray(variables?.sort)
    ? variables?.sort[0]
    : variables?.sort;
  const period = variables?.period;

  const currentPeriodSort =
    PERIOD_TO_SORT[period || 'ONE_YEAR'] || 'PRICE_CHANGE_1Y';

  const readSortDirection = (
    type: CryptoSortTypeEnum,
    sort: CryptoSortOptionInput | null | undefined,
  ): CryptoSortOptionInput['direction'] | null | undefined => {
    return sort && sort.type === type ? sort.direction : null;
  };
  return (
    <Table.Row>
      <Table.HeaderCell
        content="Name"
        width="33%"
        onClick={() => handleSortChange('NAME')}
        sort={readSortDirection('NAME', sort)}
      />
      <Table.HeaderCell
        content="Current Price"
        align="right"
        width="10%"
        onClick={() => handleSortChange('PRICE')}
        sort={readSortDirection('PRICE', sort)}
      />
      <Table.HeaderCell
        content="Market Cap"
        align="right"
        width="10%"
        onClick={() => handleSortChange('MARKET_CAP')}
        sort={readSortDirection('MARKET_CAP', sort)}
      />
      <Table.HeaderCell
        content="Volume"
        align="right"
        width="10%"
        onClick={() => handleSortChange('VOLUME')}
        sort={readSortDirection('VOLUME', sort)}
      />
      <Table.HeaderCell
        content="Circulating Supply"
        align="right"
        width="15%"
        onClick={() => handleSortChange('SUPPLY')}
        sort={readSortDirection('SUPPLY', sort)}
      />
      <Table.HeaderCell
        align="right"
        onClick={() => handleSortChange(currentPeriodSort)}
        sort={readSortDirection(currentPeriodSort, sort)}
        width="20%"
      >
        <ScreenerPriceHistory handlePeriodChange={handlePeriodChange} />
      </Table.HeaderCell>
      <Table.HeaderCell
        align="right"
        width={hasCryptoAccount && !addMode ? '12%' : '0%'}
      >
        {hasCryptoAccount && !addMode ? 'Add to Portfolio' : ''}
      </Table.HeaderCell>
    </Table.Row>
  );
};
