import { Box, HXS, PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import type { CreditCardRewardsSummaryFragment } from '~/graphql/types';

import { RewardsCurrentCycleEstimateContainer } from './RewardsCurrentCycleEstimate';
import { RewardsPayoutLocationContainer } from './RewardsPayoutLocationContainer';

const StyledBox = styled(Box)`
  background: linear-gradient(
    125deg,
    ${(props) => props.theme.colors.orcScrim} 50%,
    ${(props) => props.theme.colors.backgroundShadow} 50%
  );
  color: ${(props) => props.theme.colors.foregroundNeutralOnDark};
  border-radius: 8px;
  display: table;
  width: 100%;
  padding: 16px;
`;

export type RewardsSummaryComponentProps = {
  rewardsSummary: CreditCardRewardsSummaryFragment;
};

export const RewardsSummary = ({
  rewardsSummary,
}: RewardsSummaryComponentProps) => {
  const {
    currentCycleRewardsEstimate,
    lifetimeRewardsOverview,
    rewardsPayoutLocation,
  } = rewardsSummary;

  return (
    <Box>
      <StyledBox>
        {currentCycleRewardsEstimate && (
          <RewardsCurrentCycleEstimateContainer
            currentCycleRewardsEstimate={currentCycleRewardsEstimate}
          />
        )}
        {lifetimeRewardsOverview && (
          <>
            <PM mt={16} content={lifetimeRewardsOverview.label} />
            <HXS content={lifetimeRewardsOverview.value} />
          </>
        )}
        {rewardsPayoutLocation && (
          <RewardsPayoutLocationContainer
            rewardsPayoutLocation={rewardsPayoutLocation}
          />
        )}
      </StyledBox>
    </Box>
  );
};
