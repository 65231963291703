import cloneDeep from 'lodash-es/cloneDeep';

import {
  type HighlightEvent,
  type IndividualHistoricalTree,
  type PieEditorModelKind,
  type TreeHistoryType,
  type TreeItem,
} from '~/pages/dashboard/wizards/pie-editor/PieEditor.types';
import {
  changePercentage,
  collapseAll,
  equalizePie,
  expandAll,
  findItemDeep,
  findParentItemDeep,
  removeItem,
  renamePie,
  setProperty,
} from '~/pages/dashboard/wizards/pie-editor/PieEditor.utils';

import type { AppAction } from '../actions';
import {
  PIE_EDITOR_ACTIONS,
  type PieEditorAddSlicesStart,
} from '../actions/PieEditorAction';

export type PieEditorState = {
  history: IndividualHistoricalTree[];
  historyIndex: number;
  rootId: string | null;
  rootItem: TreeItem | null;
  kind: PieEditorModelKind;
  autoFix: boolean;
  addSlices: PieEditorAddSlicesStart['payload'] | null;
  isCrypto: boolean | null;
};

export const initialState: PieEditorState = {
  history: [],
  historyIndex: 0,
  rootId: null,
  rootItem: null,
  kind: 'PIE',
  autoFix: false,
  addSlices: null,
  isCrypto: null,
};

const addHistoryTree = (
  state: PieEditorState,
  newTree: IndividualHistoricalTree,
) => {
  const slicedHistory = state.history.slice(0, state.historyIndex + 1);
  return {
    ...state,
    history: [...slicedHistory, newTree],
    historyIndex: slicedHistory.length,
  };
};

export const pieEditorReducer = (
  state: PieEditorState = initialState,
  action: AppAction,
): PieEditorState => {
  const getCurrentTree = () =>
    cloneDeep(state.history[state.historyIndex].tree);

  switch (action.type) {
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_HISTORY_TREE: {
      return addHistoryTree(state, action.payload);
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_SET_INITIAL_PIE: {
      const tree = action.payload.treeItems;
      return {
        ...state,
        history: [
          {
            tree: action.payload.uncollapse ? expandAll(tree) : tree,
            type: 'init',
          },
        ],
        rootId: action.payload.rootId,
        rootItem: tree[0],
        kind: action.payload.kind,
        isCrypto: action.payload.isCrypto,
      };
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_CLEAR_PIE: {
      return {
        ...initialState,
      };
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_ADD_SLICES_START: {
      return {
        ...state,
        addSlices: action.payload,
      };
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_REMOVE_SLICE: {
      const currentTree = getCurrentTree();
      const { id } = action.payload;
      const item = findItemDeep(currentTree, id);
      const parent = findParentItemDeep(currentTree, id);
      const newTree = removeItem(currentTree, id);
      return {
        ...state,
        ...addHistoryTree(state, {
          tree: newTree,
          type: 'remove',
          meta: {
            slice:
              item?.meta.name ?? item?.meta.securityInfo?.symbol ?? 'slice',
            pie: parent?.meta.name ?? 'pie',
          },
        }),
      };
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_PERCENTAGE: {
      const { id, slice, fromPercentage, percentage } = action.payload;
      const newTree = changePercentage(getCurrentTree(), id, percentage);
      return addHistoryTree(state, {
        tree: newTree,
        type: 'percentage',
        meta: { slice, fromPercentage, percentage },
      });
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_EQUALIZE_PIE: {
      const { id, meta } = action.payload;
      const newTree = equalizePie(getCurrentTree(), id);
      // Get item in tree by id recursively
      const item = findItemDeep(newTree, id);
      if (item) {
        // dispatch js event that children should be equalized
        const e = new CustomEvent<HighlightEvent>('pie-editor-highlight', {
          detail: {
            ids: item.children.map((child) => child.id),
            type: 'percentage',
          },
        });
        window.dispatchEvent(e);
      }
      return addHistoryTree(state, { tree: newTree, type: 'equalize', meta });
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_RENAME_PIE: {
      const { id, name, meta } = action.payload;
      const newTree = renamePie(getCurrentTree(), id, name);
      return addHistoryTree(state, { tree: newTree, type: 'rename', meta });
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_COLLAPSED: {
      const tree = getCurrentTree();
      const { collapsed } = action.payload ?? {};
      let newTree;
      let type: TreeHistoryType;
      if ('id' in action.payload) {
        // If we want collapse action on a specific id
        let collapsedId = action.payload.id;
        if (action.payload.actOnParent) {
          collapsedId =
            findParentItemDeep(tree, collapsedId)?.id ?? collapsedId;
        }
        if (collapsed === true) {
          newTree = collapseAll(tree, collapsedId);
        } else if (collapsed === false) {
          newTree = expandAll(tree, collapsedId);
        } else {
          // If no collapsed arg, just flip it
          newTree = setProperty(tree, collapsedId, 'collapsed', (prev) => {
            return !prev;
          });
        }
        type = collapsed ? 'collapse' : 'expand';
        const newMeta = {
          pie:
            action.payload.meta?.pie ??
            findItemDeep(tree, collapsedId)?.meta.name ??
            'pie',
        };
        return addHistoryTree(state, { tree: newTree, type, meta: newMeta });
      } else if (collapsed) {
        newTree = collapseAll(tree);
        type = 'collapse_all';
      } else {
        newTree = expandAll(tree);
        type = 'expand_all';
      }
      return addHistoryTree(state, { tree: newTree, type });
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_HISTORY_INDEX: {
      if (action.payload.isReset) {
        return {
          ...state,
          historyIndex: 0,
          history: [state.history[0]],
        };
      }
      return {
        ...state,
        historyIndex: action.payload.index,
      };
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_CONVERT_SYSTEM_PIE: {
      const { id, meta } = action.payload;
      const newTree = setProperty(getCurrentTree(), id, 'meta', (prev) => {
        return { ...prev, id: undefined, isSystemPie: false };
      });
      const withUpdatedChildren = setProperty(
        newTree,
        id,
        'children',
        (children) => {
          return children.map((child) => {
            return cloneDeep({
              ...child,
              draggable: true,
              removeable: true,
            });
          });
        },
      );
      return addHistoryTree(state, {
        tree: withUpdatedChildren,
        type: 'convert',
        meta,
      });
    }
    case PIE_EDITOR_ACTIONS.PIE_EDITOR_AUTO_FIX: {
      return {
        ...state,
        autoFix: action.payload.enabled,
      };
    }
    default:
      return state;
  }
};
