import { Box, Button } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';
import { type Path, useFormContext } from 'react-hook-form';

import { DateInput } from '~/components/form/Inputs/DateInput';
import { FilterOrTogglePopup } from '~/toolbox/filter-or-toggle-popup';

import type { InvestActivityFormValues } from '../ActivityPage';

export const FilterByDateRange = () => {
  const { watch, setValue } = useFormContext<InvestActivityFormValues>();

  const handleChangeInput = (
    field: Path<InvestActivityFormValues>,
    newValue: string,
  ) => {
    if (
      /^\d{2}(\/|-)?\d{2}(\/|-)?\d{2,4}$/.test(newValue) &&
      moment(newValue, 'MM-DD-YYYY').isValid()
    ) {
      setValue(field, newValue, { shouldDirty: true });
    } else if (!newValue) {
      setValue(field, null, { shouldDirty: true });
    }
  };
  const [fromDate, toDate] = watch(['fromDate', 'toDate']);

  const readCardLabel = (): string => {
    if (fromDate && toDate) {
      return `${formatDate(fromDate)} – ${formatDate(toDate)}`;
    } else if (fromDate) {
      return `From ${formatDate(fromDate)}`;
    } else if (toDate) {
      return `To ${formatDate(toDate)}`;
    }
    return 'Date range';
  };

  const handleDateReset = () => {
    setValue('fromDate', '');
    setValue('toDate', '');
  };
  return (
    <FilterOrTogglePopup placement="bottom-end" label={readCardLabel()}>
      <Box
        display="grid"
        p="12px 14px 16px"
        gridTemplateColumns="196px 196px auto"
        columnGap={12}
        alignItems="center"
      >
        <DateInput
          label="From"
          name="fromDate"
          onDateChange={(date) => handleChangeInput('fromDate', date)}
          value={fromDate ?? ''}
        />
        <DateInput
          label="To"
          name="toDate"
          onDateChange={(date) => handleChangeInput('toDate', date)}
          value={toDate ?? ''}
        />
        <Box mt={8}>
          <Button
            kind="link"
            label="Clear"
            style={{ fontSize: '16px' }}
            disabled={!fromDate && !toDate}
            onClick={handleDateReset}
          />
        </Box>
      </Box>
    </FilterOrTogglePopup>
  );
};

function formatDate(date: string): string {
  return moment(date, 'MM-DD-YYYY').format('MM/DD/YYYY');
}
