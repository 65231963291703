import { Box, css, styled } from '@m1/liquid-react';
import React from 'react';

import type { SliceableLogoFragment } from '~/graphql/types';
import { clickedOnPortfolioSlice } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Section } from '~/toolbox/section';

import { SliceableLogo } from '../SliceableLogo';

import { usePieDetailsContext } from './hooks/usePieDetailsContext';
import { PieLinkedLocationListItem } from './PieLinkedLocationListItem';

const StyledPortfolioListItem = styled(Box)<{ disableLinks?: boolean }>`
  display: flex;
  align-items: center;
  padding: 7px 1rem;
  border-bottom: 1px solid var(--colors-border-main);

  &:first-child {
    border-top: 1px solid var(--colors-border-main);
  }

  ${({ disableLinks }) =>
    !disableLinks &&
    css`
      &:hover {
        cursor: pointer;
        background: var(--colors-background-neutral-tertiary);
      }
    `}
`;

const StyledLogo = styled(Box)`
  width: 4rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

export const PieLinkedLocationSection = () => {
  const dispatch = useDispatch();
  const { pie } = usePieDetailsContext();

  if (!pie) {
    return null;
  }

  const { name, portfolioLinks } = pie;

  return (
    <Section>
      <Section.Header label="Portfolio locations" />
      <Box>
        {portfolioLinks?.map((portfolioLink) => (
          <StyledPortfolioListItem
            onClick={() => dispatch(clickedOnPortfolioSlice(portfolioLink.id))}
            key={portfolioLink.id}
          >
            <StyledLogo>
              {pie.portfolioLinks && (
                <SliceableLogo sliceable={pie as SliceableLogoFragment} />
              )}
            </StyledLogo>
            <PieLinkedLocationListItem
              portfolioLink={portfolioLink}
              pieName={name}
            />
          </StyledPortfolioListItem>
        ))}
      </Box>
    </Section>
  );
};
