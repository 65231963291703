import * as React from 'react';

type ChartableSliceChartState = {
  isChartDataNull: boolean;
  setIsChartDataNull: (isChartDataNull: boolean) => void;
};

const defaultChartableSliceChartState: ChartableSliceChartState = {
  isChartDataNull: false,
  setIsChartDataNull: () => {},
};

export const ChartableSliceChartContext =
  React.createContext<ChartableSliceChartState>(
    defaultChartableSliceChartState,
  );

export const useChartableSliceChartContext = () => {
  return React.useContext(ChartableSliceChartContext);
};
