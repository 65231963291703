import { Button, PL } from '@m1/liquid-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  type InjectedFormProps,
  FormSection,
  formValues,
  reduxForm,
} from 'redux-form';

import { clickedNoDisclosuresApply } from '~/redux/actions';
import { Checkbox } from '~/toolbox/checkbox';
import { Collapse } from '~/toolbox/collapse';

import { CheckboxField, TextField } from './fields';
import { makeFormConfig } from './utils';
import { required, printableAsciiChars } from './validators';

const formConfig = makeFormConfig({
  form: 'disclosures',
});

type DisclosureFormProps = InjectedFormProps<any> & {
  backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
  handleSubmit: (...args: Array<any>) => any;
  submitButtonLayout: 'primary' | 'secondary';
};

export const DisclosuresForm = reduxForm(formConfig)(
  // @ts-expect-error - TS2345 - Argument of type '(props: DisclosureFormProps) => JSX.Element' is not assignable to parameter of type 'ComponentType<InjectedFormProps<{}, {}, string>>'.
  (props: DisclosureFormProps) => {
    const renderSubmitButtonLayout = () => {
      switch (props.submitButtonLayout) {
        case 'secondary':
          return (
            <Button type="submit" kind="primary" size="medium" label="Save" />
          );
        default:
          return (
            <Button
              type="submit"
              kind="primary"
              size="large"
              label="Continue"
            />
          );
      }
    };

    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    const formatToString = (value): string => {
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return value;
    };

    return (
      <form onSubmit={props.handleSubmit}>
        <FormSection name="exchangeOrFinraAffiliationDisclosure">
          <CheckboxField
            name="isAffiliated"
            label="I am affiliated with or work for a broker/dealer"
            my={8}
          />
          {/* @ts-expect-error - TS2741 - Property 'field' is missing in type '{ children: Element; }' but required in type 'Readonly<{ field: any; }>'. | TS2786 - 'RenderIfAffiliated' cannot be used as a JSX component. */}
          <RenderIfAffiliated>
            <TextField
              backgroundColor={props.backgroundColor}
              name="firmName"
              label="Firm Name"
              placeholder="M1 Finance, etc."
              validate={[required, printableAsciiChars]}
            />
          </RenderIfAffiliated>
        </FormSection>
        <FormSection name="controlPersonDisclosure">
          <CheckboxField
            name="isControlPerson"
            label="I am a 10% shareholder or director of a publicly traded company"
            my={8}
          />
          {/* @ts-expect-error - TS2741 - Property 'field' is missing in type '{ children: Element; }' but required in type 'Readonly<{ field: any; }>'. | TS2786 - 'RenderIfControlPerson' cannot be used as a JSX component. */}
          <RenderIfControlPerson>
            <TextField
              backgroundColor={props.backgroundColor}
              name="companySymbols"
              label="Symbols"
              placeholder="GOOG, AMZN, META"
              validate={[required, printableAsciiChars]}
              format={formatToString}
            />
          </RenderIfControlPerson>
        </FormSection>
        <FormSection name="politicalExposureDisclosure">
          <CheckboxField
            name="isPoliticallyExposed"
            label="I am or an immediate family member is a current or former Public Official"
            my={8}
          />
          {/* @ts-expect-error - TS2741 - Property 'field' is missing in type '{ children: Element[]; }' but required in type 'Readonly<{ field: any; }>'. | TS2786 - 'RenderIfPoliticallyExposed' cannot be used as a JSX component. */}
          <RenderIfPoliticallyExposed>
            <TextField
              backgroundColor={props.backgroundColor}
              name="politicalOrganization"
              label="Affiliated public entities"
              placeholder="e.g. Director of the Port Authority"
              valueIsArray
              validate={[required, printableAsciiChars]}
            />
            <TextField
              backgroundColor={props.backgroundColor}
              name="immediateFamilyMembers"
              label="Names of immediate family members"
              placeholder="e.g. George Washington, President of USA"
              validate={[required, printableAsciiChars]}
              format={formatToString}
            />
          </RenderIfPoliticallyExposed>
        </FormSection>
        <FormSection name="backupWithholding">
          <CheckboxField
            name="isSubjectToBackupWithholding"
            label="I am subject to backup withholding by the IRS."
            my={8}
          />
        </FormSection>
        <NoneOfTheseApplyCheckbox />
        <br />
        {renderSubmitButtonLayout()}
      </form>
    );
  },
);

type RenderIfFieldProps = {
  children: React.ReactNode;
  field: boolean;
};

const RenderIfField = ({ children, field }: RenderIfFieldProps) => {
  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <Collapse isOpened={field || false} unmountOnClose>
      {children}
    </Collapse>
  );
};

const RenderIfAffiliated = formValues({
  field: 'isAffiliated',
  // @ts-expect-error - TS2345 - Argument of type '({ children, field }: RenderIfFieldProps) => JSX.Element' is not assignable to parameter of type 'ComponentType<{ field: any; }>'.
})(RenderIfField);
const RenderIfControlPerson = formValues({
  field: 'isControlPerson',
  // @ts-expect-error - TS2345 - Argument of type '({ children, field }: RenderIfFieldProps) => JSX.Element' is not assignable to parameter of type 'ComponentType<{ field: any; }>'.
})(RenderIfField);
const RenderIfPoliticallyExposed = formValues({
  field: 'isPoliticallyExposed',
  // @ts-expect-error - TS2345 - Argument of type '({ children, field }: RenderIfFieldProps) => JSX.Element' is not assignable to parameter of type 'ComponentType<{ field: any; }>'.
})(RenderIfField);

const createConnectedComponent = connect(null, {
  clickedNoDisclosuresApply,
});
const createFormedComponent = formValues({
  isAffiliated: 'exchangeOrFinraAffiliationDisclosure.isAffiliated',
  isControlPerson: 'controlPersonDisclosure.isControlPerson',
  isPoliticallyExposed: 'politicalExposureDisclosure.isPoliticallyExposed',
  isSubjectToBackupWithholding:
    'backupWithholding.isSubjectToBackupWithholding',
});
const NoneOfTheseApplyCheckbox = compose<any, any>(
  createConnectedComponent,
  // @ts-expect-error - TS2345 - Argument of type '(component: ComponentType<{ isAffiliated: any; isControlPerson: any; isPoliticallyExposed: any; }>) => ComponentClass<{ isAffiliated: any; isControlPerson: any; isPoliticallyExposed: any; }, any>' is not assignable to parameter of type 'Func0<any>'.
  createFormedComponent,
)(
  // @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
  class extends React.Component<any> {
    render() {
      const {
        isAffiliated,
        isControlPerson,
        isPoliticallyExposed,
        isSubjectToBackupWithholding,
      } = this.props;

      const hasDisclosure =
        isAffiliated ||
        isControlPerson ||
        isPoliticallyExposed ||
        isSubjectToBackupWithholding;
      return (
        <Checkbox
          checked={!hasDisclosure}
          label={
            <PL fontWeight={600} content="None of these describes me." pl={4} />
          }
          my={8}
          autoFocus
          onChange={() =>
            hasDisclosure && this.props.clickedNoDisclosuresApply()
          }
        />
      );
    }
  },
);
