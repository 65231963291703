import type { AllIconName } from '@m1/liquid-react/icons';

import type { TransferParticipantFragment } from '~/graphql/types';

export const getParticipantIconName = (
  transferParticipantType: TransferParticipantFragment['transferParticipantType'],
) => {
  switch (transferParticipantType) {
    case 'CRYPTO':
      return 'accountCryptoPrimary32';
    case 'INVEST':
      return 'accountInvestPrimary32';
    case 'BORROW':
      return 'accountBorrowPrimary32';
    case 'SAVE':
      return 'accountSavingsPrimary32';
    case 'LOAN':
      return 'accountBorrowPrimary32';
    case 'CREDIT_CARD':
      return 'accountCreditCardPrimary32';
    default:
      return 'accountCheckingPrimary32' as AllIconName;
  }
};
