import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { FormSection, type InjectedFormProps } from 'redux-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { useEmploymentInfoFormModernQuery } from '~/graphql/hooks';
import { connectForm } from '~/hocs';
import { Spinner } from '~/toolbox/spinner';

import { EmploymentFields } from '../fields';

const EmploymentInfoFormComponent = (props: InjectedFormProps<any>) => {
  const { handleSubmit } = props;

  const { data, loading } = useEmploymentInfoFormModernQuery({
    errorPolicy: 'all',
  });

  if (loading) {
    return <Spinner />;
  }

  if (!data?.viewer.onboarding?.dueDiligence?.occupationDropdownOptions) {
    return <GenericSystemError />;
  }

  const { occupationDropdownOptions } = data.viewer.onboarding.dueDiligence;
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="employment">
        <EmploymentFields
          modernLayout
          dropdownOptions={occupationDropdownOptions}
        />
      </FormSection>
      <Button
        kind="primary"
        type="submit"
        size="large"
        label="Continue"
        mt={32}
      />
      <FormMocker
        formName="employment-profile-modern"
        fields={[
          {
            name: 'employment.status',
            value: 'STUDENT',
          },
        ]}
      />
    </form>
  );
};

export const EmploymentInfoFormModern = connectForm({
  form: 'employment-profile-modern',
})(EmploymentInfoFormComponent);
