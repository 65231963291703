import { PM, Box, type TextProps } from '@m1/liquid-react';
import * as React from 'react';

import { isNotNil } from '~/utils';

type EmphasisEnum = 'none' | 'label' | 'value' | 'neither';

type Props = {
  emphasis?: EmphasisEnum;
  label: React.ReactNode;
  style?: Record<string, any>;
  value: React.ReactNode;
};

type FontAndWeight = Pick<TextProps, 'font'> & {
  fontWeight: 400 | 600;
};

const Value = ({
  value,
  emphasis,
}: {
  emphasis?: EmphasisEnum;
  value: React.ReactNode;
}) => {
  const readValueFont = (): FontAndWeight => {
    const fontAndWeight: FontAndWeight = {
      font: 'HXS',
      fontWeight: 600,
    };
    switch (emphasis) {
      case 'label':
        fontAndWeight.font = 'HXXS';
        break;
      case 'value':
        fontAndWeight.font = 'HS';
        break;
      case 'neither':
        fontAndWeight.font = 'PM';
        fontAndWeight.fontWeight = 400;
        break;
      default:
        break;
    }
    return fontAndWeight;
  };

  if (value !== undefined && value !== null) {
    const { font, fontWeight } = readValueFont();
    switch (typeof value) {
      case 'string':
      case 'number':
        return <PM fontWeight={fontWeight} content={value} font={font} />;
      default:
        return value;
    }
  }

  return null;
};

const Label = ({
  emphasis,
  label,
}: {
  emphasis?: EmphasisEnum;
  label: React.ReactNode;
}) => {
  const readLabelFont = (): TextProps['font'] => {
    switch (emphasis) {
      case 'label':
        // TODO(Wolf): Find out if this is the right size.
        return 'PXL';
      case 'value':
      case 'neither':
        return 'PM';
      default:
        return 'PL';
    }
  };

  return (
    <Box
      color="foregroundNeutralMain"
      font={readLabelFont()}
      style={{
        paddingBottom: 4,
      }}
    >
      {label}
    </Box>
  );
};

export const LabelWithValue = ({
  emphasis = 'none',
  label,
  style,
  value,
}: Props) => {
  const values = Array.isArray(value) ? value : [value];
  return (
    <div style={style}>
      {isNotNil(label) && <Label label={label} emphasis={emphasis} />}
      {values.map((value, i) => (
        <Value key={i} emphasis={emphasis} value={value} />
      ))}
    </div>
  );
};
