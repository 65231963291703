import { Box, LS, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import type {
  InvestAccountModalAccountNodeFragment,
  InvestAccountModalSliceableNodeFragment,
} from '~/graphql/types';
import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { isNil } from '~/utils';

const StyledSelectInvestAccountListRowContainer = styled(Box)<{
  selected: boolean;
  disabled: boolean;
}>`
  border-style: solid;
  border-width: 1px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-color: ${(props) =>
    props.selected ? props.theme.colors.borderMain : 'transparent'};
  background-color: ${(props) =>
    props.selected || props.disabled
      ? props.theme.colors.backgroundNeutralTertiary
      : 'initial'};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:not(:last-child) {
    border-bottom-color: ${(props) =>
      !props.selected && !props.disabled
        ? props.theme.colors.borderMain
        : undefined};
  }

  &:hover {
    background-color: ${(props) =>
      !props.selected && !props.disabled
        ? props.theme.colors.backgroundNeutralTertiary
        : undefined};
    border-color: ${(props) =>
      !props.selected && !props.disabled
        ? props.theme.colors.borderMain
        : undefined};
  }
`;

export const SelectInvestAccountListRow = ({
  account,
  sliceable,
  zIndex,
  selectedAccountId,
  onSelect,
}: {
  account: InvestAccountModalAccountNodeFragment;
  sliceable: InvestAccountModalSliceableNodeFragment;
  zIndex: number;
  selectedAccountId: string;
  onSelect: (accountId: string) => void;
}) => {
  const { showNewPieEditor } = useNewPieEditor();
  const reasonWhyInvalid = checkAccountValidity(
    account,
    sliceable,
    showNewPieEditor,
  );

  const handleClick = () => {
    if (reasonWhyInvalid === null) {
      onSelect(account.id);
    }
  };

  return (
    <StyledSelectInvestAccountListRowContainer
      position="relative"
      padding="6px 12px 10px"
      zIndex={zIndex}
      disabled={reasonWhyInvalid !== null}
      selected={selectedAccountId === account.id}
      onClick={handleClick}
      data-testid={`select-invest-account-row-${account.name}`.trim()}
    >
      <PL fontWeight={600}>{account.name}</PL>
      <LS as="small">{reasonWhyInvalid ?? account.number}</LS>
    </StyledSelectInvestAccountListRowContainer>
  );
};

function checkAccountValidity(
  account: InvestAccountModalAccountNodeFragment,
  sliceable: InvestAccountModalSliceableNodeFragment,
  alwaysAllowExistingSlices: boolean,
): string | null {
  if (isNil(account.rootPortfolioSlice)) {
    return 'No portfolio set for account.';
  }

  if (account.rootPortfolioSlice.to.id === sliceable.id) {
    return `Can't add something to itself!`;
  }

  if (
    !alwaysAllowExistingSlices &&
    doesSliceAlreadyExistInPortfolio(account.rootPortfolioSlice, sliceable)
  ) {
    return `Already exists in this account.`;
  }

  // Set up the checks to determine validity of the account action. We specify
  // variables here to check if a condition is met, and specify additional
  // variables below to check if a condition is _not_ met. The negation variables
  // are primarily for readability:
  const cryptoTradingAllowed = account.registration === 'CRYPTO';
  const isPieSliceable = getIfSliceableIsPie(sliceable);
  const isCryptoSliceable = sliceable.type === 'CRYPTO';
  const sliceableContainsCrypto = getIfSliceableContainCrypto(sliceable);

  // Negation variables to ensure we don't miss a `!` in our checks:
  const cryptoTradingForbidden = !cryptoTradingAllowed;
  const isNotPieSliceable = !isPieSliceable;
  const isNotCryptoSliceable = !isCryptoSliceable;
  const sliceableDoesNotContainCrypto = !sliceableContainsCrypto;

  if (cryptoTradingAllowed && isPieSliceable && sliceableDoesNotContainCrypto) {
    return `Can't add a non-crypto pie to a crypto account.`;
  }

  if (cryptoTradingForbidden && isPieSliceable && sliceableContainsCrypto) {
    return `Can't add a crypto pie to a non-crypto account.`;
  }

  if (cryptoTradingAllowed && isNotPieSliceable && isNotCryptoSliceable) {
    return `Can't add non-crypto slices to a crypto account.`;
  }

  if (cryptoTradingForbidden && isCryptoSliceable) {
    return `Can't add crypto slices to a non-crypto account.`;
  }

  return null;
}

function doesSliceAlreadyExistInPortfolio(
  rootPortfolioSlice: NonNullable<
    InvestAccountModalAccountNodeFragment['rootPortfolioSlice']
  >,
  sliceable: InvestAccountModalSliceableNodeFragment,
): boolean {
  return rootPortfolioSlice.to.slices.some((slice) => {
    if (slice.to.id === sliceable.id) {
      return true;
    }

    return (
      slice.to.__typename === 'CryptoAsset' &&
      sliceable.__typename === 'CryptoAsset' &&
      slice.to.legacyId === sliceable.legacyId
    );
  });
}

function getIfSliceableContainCrypto(
  sliceable: InvestAccountModalSliceableNodeFragment,
): boolean {
  if ('containsCrypto' in sliceable) {
    return sliceable.containsCrypto;
  }

  return false;
}

function getIfSliceableIsPie(
  sliceable: InvestAccountModalSliceableNodeFragment,
): boolean {
  return (
    sliceable.type === 'CRYPTO_SYSTEM_PIE' ||
    sliceable.type === 'SYSTEM_PIE' ||
    sliceable.type === 'USER_PIE'
  );
}
