import * as React from 'react';

// for reference
export const MAX_WIDTH = 1600;
export const SIDE_EXPANDED = 260;
export const TOP_BAR_HEIGHT = 60;
export const CONTENT_PADDING = 80;
export const TOTAL_CONTENT_WIDTH = MAX_WIDTH + 2 * CONTENT_PADDING;

// open: side nav panel is expanded and pushes content
// overlay: side nav panel is expanded and overlays content
// collapsed: side nav panel shows icons only
export type PanelStatus = 'open' | 'overlay' | 'collapsed';

interface LayoutState {
  panelStatus: PanelStatus;
  isOpen: boolean;
  sideNavWidth: number;
  contentWidth: number;
  contentPadding: number;
  topBarHeight: number;
  openPanel: () => void;
  closePanel: () => void;
  scrollTop: number;
  setScrollTop: (top: number) => void;
}

export const defaultLayoutState: LayoutState = {
  panelStatus: 'open',
  isOpen: true,
  sideNavWidth: SIDE_EXPANDED,
  contentWidth: TOTAL_CONTENT_WIDTH,
  contentPadding: CONTENT_PADDING,
  topBarHeight: TOP_BAR_HEIGHT,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openPanel: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closePanel: () => {},
  scrollTop: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setScrollTop: () => {},
};

export const LayoutContext = React.createContext(defaultLayoutState);

export const useLayout = () => React.useContext(LayoutContext);
