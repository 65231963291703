import moment from 'moment';

import { getOnlyNumbers } from '../normalizers';

/**
 * Formats a date string into MM/DD/YYYY format with automatic slash insertion
 * @param value - The current input value to format
 * @param previousValue - The previous input value, used to determine if user is typing forward
 * @returns Formatted date string in MM/DD/YYYY format or partial format while typing
 */
export function formatDate(value: string, previousValue: string): string {
  if (!value) {
    return value;
  }

  const onlyNums = getOnlyNumbers(value);
  const splitValue = value.split('/');
  if (
    splitValue.length - 1 === 2 &&
    onlyNums.length < 8 &&
    onlyNums.length > 6 &&
    splitValue[0].length <= 2 &&
    splitValue[1].length <= 2
  ) {
    return value;
  }

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2);
    }
    if (onlyNums.length === 4) {
      return (
        onlyNums.slice(0, 2) +
        '/' +
        onlyNums.slice(2, 4) +
        '/' +
        onlyNums.slice(4, 8)
      );
    }
  }

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2);
  }

  return (
    onlyNums.slice(0, 2) +
    '/' +
    onlyNums.slice(2, 4) +
    '/' +
    onlyNums.slice(4, 8)
  );
}

export const formatLongFormDate = (date: string): string => {
  return moment(date).format('MMM D, YYYY');
};

export function formatDateForAccountMutation(date: string): string {
  return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
}

/* Takes in a date of MM/DD/YYYY and returns it in the format of YYYY-MM-DD */
export function formatDateDashes(value: string): string {
  if (!value) {
    return value;
  }

  const match = value.match(/(\d{2})\/(\d{2})\/(\d{4})/);
  if (!match) {
    throw new Error('Invalid date format. Expected MM/DD/YYYY');
  }

  const onlyNums = getOnlyNumbers(value);

  if (onlyNums.length <= 4) {
    return onlyNums;
  }

  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4);
  }

  return (
    onlyNums.slice(4, 8) +
    '-' +
    onlyNums.slice(0, 2) +
    '-' +
    onlyNums.slice(2, 4)
  );
}

export function transformDateToSlashes(
  value: string,
  previousValue: string,
): string {
  /* Sometimes BE will send date in dash format so need to convert */
  if (value.includes('-')) {
    const splitValue = value.split('-');
    if (
      splitValue.length === 3 &&
      splitValue[0].length === 4 &&
      splitValue[1].length === 2 &&
      splitValue[2].length === 2
    ) {
      return `${splitValue[1]}/${splitValue[2]}/${splitValue[0]}`;
    }
  }

  // Use existing date formatting function if the date is already in slashes
  return formatDate(value, previousValue);
}

/**
 * Formats time stamp to adhere to logic for rendering trading window times
 * @param {string} timeStamp of the trading window start time
 * @returns {string} formatted string to be rendered
 */
export function formatTradingTimeByTimeStamp(timeStamp: string) {
  // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'MomentInput | undefined'.
  return moment(timeStamp).calendar(null, {
    sameDay: () =>
      moment(timeStamp).minutes() === 0 ? 'h A [today]' : 'h:mm A [today]',
    nextDay: () =>
      moment(timeStamp).minutes() === 0
        ? 'h A [tomorrow]'
        : 'h:mm A [tomorrow]',
    nextWeek: () =>
      moment(timeStamp).minutes() === 0 ? 'h A dddd' : 'h:mm A dddd',
  });
}
