import { Box, Button, Flex, PL, PXL, styled } from '@m1/liquid-react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AddressFieldsSection } from '~/components/form/address/AddressFieldsSection';
import {
  CustodialBeneficiaryInfoDocument,
  useUpdateCustodialAccountBeneficiaryLegalAddressMutation,
} from '~/graphql/hooks';
import type {
  CustodialAccountBeneficiaryFragment,
  UpdateCustodialAccountBeneficiaryLegalAddressInput,
} from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

const StyledDisplayContainer = styled(Box)<{
  shouldDisplayComponent: boolean;
}>`
  max-height: 180px;
  ${(props) =>
    !props.shouldDisplayComponent
      ? `max-height: 0;  overflow: hidden;`
      : `overflow: visible`};
  transition: all 0.2s linear;
`;

type FormFields =
  UpdateCustodialAccountBeneficiaryLegalAddressInput['updatedCustodialAccountBeneficiaryLegalAddress'];

export const CustodialBeneficiaryForm = ({
  custodialAccountBeneficiary,
}: {
  custodialAccountBeneficiary: NonNullable<
    CustodialAccountBeneficiaryFragment['custodialAccountBeneficiary']
  >;
}) => {
  const [updateCustodialAccountBeneficiaryLegalAddress, { loading }] =
    useUpdateCustodialAccountBeneficiaryLegalAddressMutation();

  usePortaledSpinner(loading);

  const currentAddress = custodialAccountBeneficiary.legalResidence;
  const accountId = useSelector((state) => state.global.investAccountId);

  const [isInEditAddressMode, setIsInEditAddressMode] = React.useState(false);

  const { addToast } = useToast();
  const formMethods = useForm<FormFields>({
    defaultValues: {
      city: currentAddress?.city,
      country: 'USA',
      lineOne: currentAddress?.lineOne,
      lineTwo: currentAddress?.lineTwo,
      postalCode: currentAddress?.postalCode,
      stateOrProvince:
        currentAddress?.stateOrProvince as unknown as FormFields['stateOrProvince'],
    },
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (input: FormFields) => {
    updateCustodialAccountBeneficiaryLegalAddress({
      variables: {
        input: {
          custodialAccountBeneficiaryId: custodialAccountBeneficiary?.id,
          updatedCustodialAccountBeneficiaryLegalAddress: input,
        },
      },
      onCompleted: () => {
        addToast({
          content: `Your custodial account for ${custodialAccountBeneficiary?.firstName} ${custodialAccountBeneficiary?.lastName} has been updated`,
          kind: 'success',
          duration: 'short',
        });
        setIsInEditAddressMode(false);
      },
      onError: (e) => {
        addToast({
          content: e.message,
          kind: 'alert',
          duration: 'short',
        });
      },
      refetchQueries: [
        {
          query: CustodialBeneficiaryInfoDocument,
          variables: {
            accountId,
          },
        },
      ],
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Flex alignItems="flex-end" mb={16}>
        <PXL fontWeight={600} content="Legal Residence" />
        {!isInEditAddressMode && (
          <Button
            kind="link"
            label="Edit"
            ml={16}
            onClick={() => setIsInEditAddressMode(!isInEditAddressMode)}
          />
        )}
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledDisplayContainer shouldDisplayComponent={isInEditAddressMode}>
          <AddressFieldsSection
            kind="CUSTODIAL"
            backgroundColor="backgroundNeutralMain"
          />
        </StyledDisplayContainer>
        {!isInEditAddressMode && (
          <PL content={custodialAccountBeneficiary?.formattedLegalResidence} />
        )}

        <StyledDisplayContainer shouldDisplayComponent={isInEditAddressMode}>
          <ButtonGroup mt={16}>
            <Button
              disabled={loading}
              label="Cancel"
              kind="secondary"
              size="medium"
              onClick={() => setIsInEditAddressMode(false)}
            />
            <Button
              disabled={!formMethods.formState.isDirty || loading}
              type="submit"
              label="Save"
              size="medium"
            />
          </ButtonGroup>
        </StyledDisplayContainer>
      </form>
    </FormProvider>
  );
};
