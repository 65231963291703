import { PXS, Flex, styled, css, type CardProps } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { NavigableCard } from '~/components/cards';
import type { SecurityCellFragment } from '~/graphql/types';

import { SecurityCell } from './SecurityCell';

export type SecurityNavigableCardProps = CardProps & {
  security: SecurityCellFragment;
};

const StyledSecurityCell = styled(SecurityCell)<Record<string, any>>`
  max-width: 400px;
`;

const StyledNavigableCard = styled(NavigableCard)(
  ({ theme }) => css`
    &:hover {
      background-color: ${theme.colors.foregroundNeutralInverse};
      border: 1px solid ${theme.colors.primary};
    }
    > :first-child {
      flex: 1;
    }
  `,
);

export const SecurityNavigableCard = ({
  security,
  ...rest
}: SecurityNavigableCardProps) => (
  <StyledNavigableCard
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    p="12px 16px"
    cursor="pointer"
    params={{
      equityId: security.id,
    }}
    to="/d/research/stocks/details/:equityId"
    {...rest}
  >
    <StyledSecurityCell security={security} />
    <Flex flexBasis={1} alignItems="center">
      <PXS fontWeight={600} color="primary" content="View Details" />
      <Icon name="caretRight16" color="foregroundNeutralSecondary" />
    </Flex>
  </StyledNavigableCard>
);
