export const SEQUENCED_DIALOGS_WITHOUT_SLICES = {
  PORTFOLIO_NAME_AND_DESCRIPTION: 1,
  SLICES: 2,
  CREATE_PIE: 3,
  SEARCH_AND_ADD: 4,
  BULK_ACTIONS: 5,
  SET_TARGETS: null,
  DRAG_AND_DROP: null,
};

export const SEQUENCED_DIALOGS_WITH_SLICES = {
  PORTFOLIO_NAME_AND_DESCRIPTION: 1,
  SLICES: 2,
  SET_TARGETS: 3,
  CREATE_PIE: 4,
  SEARCH_AND_ADD: 5,
  DRAG_AND_DROP: 6,
  BULK_ACTIONS: 7,
};

export const slicesDialogMessage = `Each row is a slice of this Pie and has a
  target weight percentage. Click on each row
  to view details of a slice. Click on the target
  input area to change the percentage.`;

export const setTargetWeightDialogMessage = `The sum of your slices per Pie must
  equal 100%. Set a slice to 0% to remove from your portfolio.`;

export const noSlicesDialogMessage = `Once you add slices, you'll see that each
  row is a slice of this Pie and has a target
  weight percentage. Tap each row to view
  details of a slice. Tap the target input
  area to change the percentage.
  Swipe left to remove a slice.`;
