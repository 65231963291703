import {
  Box,
  Flex,
  type LayoutableProps,
  PL,
  PXL,
  styled,
} from '@m1/liquid-react';
import * as React from 'react';

import { TransferParticipantIcon } from '~/components/transfers/TransferParticipantIcon';
import type { SmartTransferRuleSummaryFragment } from '~/graphql/types';

import { DottedArrow } from '../DottedArrow';

const StyledTransferParticipantIcon = styled(TransferParticipantIcon)<{
  index: number;
}>`
  margin-left: ${({ index }) => (index > 0 ? '4px' : 0)};
`;

export type SmartTransferRuleSummaryProps = LayoutableProps & {
  smartTransferRule: SmartTransferRuleSummaryFragment;
};

type FocusParticipant = NonNullable<
  SmartTransferRuleSummaryFragment['focusParticipant']
>;

type NonNullableContraParticipant = NonNullable<
  NonNullable<
    NonNullable<
      SmartTransferRuleSummaryFragment['contraParticipantEntries']
    >[number]
  >['contraParticipant']
>;

export const SmartTransferRuleSummary = ({
  smartTransferRule,
  ...rest
}: SmartTransferRuleSummaryProps) => {
  const participants = React.useMemo<
    | [FocusParticipant, ReadonlyArray<NonNullableContraParticipant>]
    | null
    | undefined
  >(() => {
    const { contraParticipantEntries, focusParticipant } = smartTransferRule;
    if (!focusParticipant || !contraParticipantEntries) {
      return null;
    }

    const contras = [];
    for (const entry of contraParticipantEntries) {
      if (!entry?.contraParticipant) {
        return null;
      }
      contras.push(entry.contraParticipant);
    }

    return [focusParticipant, contras];
  }, [smartTransferRule]);

  const [focusParticipant, contraParticipants] = participants || [null, null];

  return (
    <Box {...rest}>
      {focusParticipant && contraParticipants && (
        <Flex alignItems="center">
          <TransferParticipantIcon transferParticipant={focusParticipant} />
          <DottedArrow
            direction={
              smartTransferRule.balanceTrigger.balanceTriggerType ===
              'UNDER_BALANCE'
                ? 'LEFT'
                : 'RIGHT'
            }
          />
          {contraParticipants.map((participant, index) => (
            <StyledTransferParticipantIcon
              key={participant.id}
              index={index}
              transferParticipant={participant}
            />
          ))}
        </Flex>
      )}
      <PXL
        fontWeight={600}
        content={smartTransferRule.title}
        mt={participants ? 16 : 0}
      />
      {smartTransferRule.fullDescription && (
        <PL
          color="foregroundNeutralSecondary"
          content={smartTransferRule.fullDescription}
        />
      )}
    </Box>
  );
};
