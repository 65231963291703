import { Flex, PL, PXL, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import type { CreditCardRewardsDetailsFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';

export type RewardsDetailsStandardProps = {
  standardRewards: NonNullable<
    CreditCardRewardsDetailsFragment['standardRewardsOverview']
  >;
};

export const RewardsDetailsStandard = ({
  standardRewards,
}: RewardsDetailsStandardProps) => {
  const navigate = useNavigate();

  const { title, highlights } = standardRewards;
  const id = highlights?.creditCardRewardsDetailsScreenId;
  return (
    <>
      <PXL fontWeight={600} content={title} mb={16} />
      <Card
        mb={32}
        onClick={() =>
          id ? navigate({ to: `/d/c/rewards/program/standard/${id}` }) : null
        }
        data-testid="card"
      >
        <Flex alignItems="center" justifyContent="space-between" px={16}>
          {highlights?.icon && <AppImage appImage={highlights.icon} />}
          <PL
            width="100%"
            p="16px 8px"
            color="foregroundNeutralSecondary"
            content={highlights?.description}
          />
          <Icon name="caretRight16" color="foregroundNeutralSecondary" />
        </Flex>
      </Card>
    </>
  );
};
