import { Text } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { components } from 'react-select';

import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';
import { withProps } from '~/hocs';
import { Dropdown, type DropdownGroup } from '~/toolbox/Dropdown';

import { required } from '../validators';

import { DropdownField } from './dropdown-field';
import style from './style.module.scss';

type Props = {
  disabled: boolean;
  options?: Array<DropdownGroup> | null | undefined;
};

class Placeholder extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    return (
      // @ts-expect-error - TS2740 - Type '{ children: Element[]; className: string; disabled: boolean; options?: DropdownGroup[] | null | undefined; }' is missing the following properties from type 'CommonProps<any>': clearValue, cx, getStyles, getValue, and 5 more. | TS2786 - 'components.Placeholder' cannot be used as a JSX component.
      <components.Placeholder {...this.props} className={style.placeholder}>
        <Icon
          name="accountAccountsDisabled32"
          style={{
            marginRight: 12,
          }}
        />
        {/* @ts-expect-error - TS2322 - Type '{ color: string; content: string; }' is not assignable to type '{ color?: "success" | "warning" | "grey00" | "grey01" | "grey02" | "grey03" | "grey04" | "grey05" | "grey06" | "teal01" | "teal02" | "teal03" | "teal04" | "teal05" | "blue01" | "blue02" | ... 83 more ... | undefined; }'. */}
        <Text {...this.readTextProps()} />
      </components.Placeholder>
    );
  }

  readTextProps() {
    const { options } = this.props;
    const hasOptionsLoaded = Boolean(options);
    const hasOptions = Array.isArray(options) && options.length > 0;

    if (!hasOptionsLoaded) {
      return {
        color: 'foregroundNeutralSecondary',
        content: 'Loading...',
      };
    } else if (!hasOptions) {
      return {
        color: 'foregroundNeutralSecondary',
        content: 'No account available',
      };
    }
    return {
      color: 'foregroundNeutralSecondary',
      content: 'Select account',
    };
  }
}

class SingleValue extends React.Component<any> {
  render() {
    return (
      // @ts-expect-error - TS2740 - Type '{ children: Element; }' is missing the following properties from type 'CommonProps<any>': clearValue, cx, getStyles, getValue, and 6 more. | TS2786 - 'components.SingleValue' cannot be used as a JSX component.
      <components.SingleValue {...this.props} className={style.fullWidth}>
        <TransferParticipantCellRenderer
          includeDescription
          id={this.props.selectProps.value.value}
        />
      </components.SingleValue>
    );
  }
}
const enhancer = withProps((props) => {
  return {
    components: {
      Placeholder,
      SingleValue,
    },
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '{ disabled: any; options: any; }'.
    disabled: isDisabled(props),
    isSearchable: false,
    // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS7006 - Parameter 'option' implicitly has an 'any' type.
    options: props.options?.map((option) => ({
      ...option,
      label: option.label.replace('_', ' '),
    })),
    // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
    optionContent: (props) => (
      <TransferParticipantCellRenderer
        includeDescription
        id={props.data.value}
      />
    ),
    size: 'large',
    validate: [required],
  };
});

// @ts-expect-error - TS7031 - Binding element 'disabled' implicitly has an 'any' type. | TS7031 - Binding element 'options' implicitly has an 'any' type.
function isDisabled({ disabled, options }): boolean {
  return Boolean(disabled || !(Array.isArray(options) && options.length > 0));
}

export const TransferParticipantDropdownField = enhancer(DropdownField) as any;

export const TransferParticipantDropdown = enhancer(
  // @ts-expect-error legacy enhancer pattern.
  Dropdown,
) as any;
