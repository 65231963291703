import { Button, type LayoutableProps } from '@m1/liquid-react';
import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';

import type { PortfolioSlicePendingRebalanceBannerFragment } from '~/graphql/types';
import { useModal } from '~/hooks/useModal';

export type PortfolioSlicePendingRebalanceBannerProps = LayoutableProps & {
  portfolioSlice: PortfolioSlicePendingRebalanceBannerFragment;
};

export const PortfolioSlicePendingRebalanceBanner = ({
  portfolioSlice,
  ...rest
}: PortfolioSlicePendingRebalanceBannerProps) => {
  const { showModal } = useModal('REBALANCE_ACTION_CANCEL');

  if (
    !portfolioSlice.rebalanceStatus ||
    !portfolioSlice.rebalanceStatus.isSetOnSelf
  ) {
    return null;
  }

  return (
    <Banner
      {...rest}
      action={
        <Button
          kind="link"
          label="Cancel"
          style={{ fontSize: '16px', fontWeight: 600 }}
          onClick={() => showModal({ portfolioSliceId: portfolioSlice.id })}
        />
      }
      content="Pending rebalance"
      kind="information"
    />
  );
};
