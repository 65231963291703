import { Flex, Box } from '@m1/liquid-react';
import React from 'react';
import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form';

import { CityInput } from '~/components/form/address/CityInput';
import { LineTwoInput } from '~/components/form/address/LineTwoInput';
import { PostalCodeInput } from '~/components/form/address/PostalCodeInput';
import { StateOrProvinceDropdown } from '~/components/form/address/StateOrProvinceDropdown';
import type { AddressFormFields } from '~/components/form/address/types';
import { GooglePlaces } from '~/components/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { FormMockerReactHookForm } from '~/forms/FormMockers/FormMockerReactHookForm';
import { usePersonalLoansDirectQuery } from '~/graphql/hooks';
import type { PersonalLoanDirectQuestion } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { BackButton } from '../BackButton';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export const HomeAddress = () => {
  const { data, loading } = usePersonalLoansDirectQuery();
  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;
  let props: Maybe<Partial<PersonalLoanDirectQuestion>> | undefined;

  if (questions) {
    props = getQuestionProps({
      question: 'HOME_ADDRESS',
      questions,
    });
  }

  usePortaledSpinner(loading);

  const homeAddress = useSelector((state) => {
    if (state.reactHookForm['homeAddress']) {
      return state.reactHookForm['homeAddress'];
    }

    return {};
  });

  const analytics = useAnalytics();
  const { goTo, back } = usePersonalLoanDirectWizardContext();
  const formMethods = useForm<AddressFormFields>({
    defaultValues: {
      lineOne: homeAddress?.lineOne,
      lineTwo: homeAddress?.lineTwo,
      city: homeAddress?.city,
      stateOrProvince: homeAddress?.stateOrProvince,
      postalCode: homeAddress?.postalCode,
    },
  });
  const { handleSubmit, setValue } = formMethods;
  const dispatch = useDispatch();

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/contact-info');
  }, [analytics]);

  const { continueLabel, title, nextQuestionType } = props || {};

  const onSubmit: SubmitHandler<AddressFormFields> = (data: any) => {
    dispatch(
      submitReactFormData({
        homeAddress: data,
      }),
    );

    goTo(nextQuestionType ?? 'PHONE_NUMBER');
  };

  const handleAutocompleteSelect = (mailingAddress: any) => {
    Object.keys(mailingAddress).forEach((key) => {
      if (key in mailingAddress) {
        setValue(key as keyof AddressFormFields, mailingAddress[key]);
      }
    });
  };

  return (
    <>
      <BackButton onBackClick={() => back()} />
      <StyledHeader content={title ?? 'What’s your address?'} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex mt={8}>
            <Box width="70%">
              <GooglePlaces
                handleAutocompleteSelect={handleAutocompleteSelect}
              />
            </Box>
            <Box width="30%" pl={8}>
              <LineTwoInput label="Apt. #" />
            </Box>
          </Flex>
          <Flex>
            <Box width="100%">
              <CityInput />
            </Box>
          </Flex>
          <Flex>
            <Box width="70%">
              <StateOrProvinceDropdown kind="NORMAL" />
            </Box>
            <Box width="30%" pl={8}>
              <PostalCodeInput label="Zip code" />
            </Box>
          </Flex>
          <ResponsiveButton
            mt={48}
            type="submit"
            size="large"
            label={continueLabel || 'Continue'}
          />
          <FormMockerReactHookForm
            setValue={setValue}
            fields={[
              {
                name: 'lineOne',
                value: '123 Main St',
              },
              {
                name: 'lineTwo',
                value: 'Apt. 1',
              },
              {
                name: 'city',
                value: 'San Francisco',
              },
              {
                name: 'stateOrProvince',
                value: 'CA',
              },
              {
                name: 'postalCode',
                value: '94103',
              },
            ]}
          />
        </form>
      </FormProvider>
    </>
  );
};
