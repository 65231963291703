import * as React from 'react';

import { Navigation } from '~/toolbox/navigation';

const navigationLinks: Array<{
  to: string;
  label: string;
}> = [
  {
    to: '/d/settings/accounts/details',
    label: 'Accounts',
  },
  {
    to: '/d/settings/accounts/fully-paid-lending',
    label: 'Fully paid securities lending',
  },
  {
    to: '/d/settings/accounts/disclosures-and-agreements',
    label: 'Disclosures and Agreements',
  },
];

export const SettingsAccountsNavigation = () => (
  <Navigation
    orientation="vertical"
    items={navigationLinks.map(({ to, label }) => ({
      to,
      label,
    }))}
  />
);
