import { Box, HXXS, PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import { formatCurrency } from '~/utils/formatting';

import { PortfolioDetailsContext } from '../PortfolioDetailsContext';

const StyledPieMiddleContentRoot = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 176px;
  height: 176px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0 0;
`;

export const PieMiddleContent = ({
  metricsToDisplay = 'PERCENTAGE',
  slice,
}: {
  metricsToDisplay?: 'PERFORMANCE' | 'PERCENTAGE';
  slice: {
    id: string;
    percentage?: number | null | undefined;
    portionOfParentValuePercent?: number | null | undefined;
    value:
      | {
          total: number | null | undefined;
        }
      | null
      | undefined;
  };
}) => {
  const portfolioContext = React.useContext(PortfolioDetailsContext);
  const totalHoldingsValue = formatCurrency(slice?.value?.total) ?? '$--';
  const endDate = portfolioContext.chartRange?.chartEndDate.format('l') ?? '';

  return (
    <StyledPieMiddleContentRoot>
      <Box pt={4}>
        <HXXS as="span" fontWeight={300} content={totalHoldingsValue} />
        <Box>
          {metricsToDisplay === 'PERFORMANCE' ? (
            <>
              <Box>
                <PM content={`as of ${endDate}`} />
              </Box>
            </>
          ) : (
            typeof slice.portionOfParentValuePercent === 'number' &&
            typeof slice.percentage === 'number' && (
              <>
                <PM color="foregroundNeutralSecondary">
                  {slice.portionOfParentValuePercent}% / {slice.percentage}%
                </PM>
                {slice.portionOfParentValuePercent !== slice.percentage && (
                  <>
                    <PM color="foregroundNeutralSecondary">
                      {slice.portionOfParentValuePercent > slice.percentage
                        ? 'Overweight'
                        : 'Underweight'}
                    </PM>
                  </>
                )}
              </>
            )
          )}
        </Box>
      </Box>
    </StyledPieMiddleContentRoot>
  );
};
