import { styled } from '@m1/liquid-react';
import * as React from 'react';
import type { To } from 'react-router-dom';

import { useLayout } from '~/hooks/useLayout';

import { TopNavigationContext } from '../../TopNavigationContext';

import { NavLink } from './NavLink';

const StyledNavLinksContainer = styled.ul<{ $height: number }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  height: ${({ $height }) => `${$height}px`};
  padding: 0 24px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-color: ${({ theme }) => theme.colors.backgroundNeutralMain}
    ${({ theme }) => theme.colors.backgroundNeutralSecondary};

  /* Safari  */
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralMain};
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track
    background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  }
`;

export type NavLinkItem = {
  id: string;
  to: To;
  label: string;
};

export type NavLinksProps = React.PropsWithChildren<{
  items: Array<NavLinkItem>;
  isActive: (item: NavLinkItem) => boolean;
}>;

export const NavLinks = ({ items, isActive }: NavLinksProps) => {
  const { leftScrimRef, rightScrimRef } =
    React.useContext(TopNavigationContext);
  const linkContainerRef = React.useRef<HTMLUListElement>(null);
  const { topBarHeight } = useLayout();

  const onNavItemClick = React.useCallback(
    (target: EventTarget) => {
      const linkContainer = linkContainerRef.current;

      if (
        target instanceof HTMLElement &&
        linkContainer &&
        leftScrimRef?.current &&
        rightScrimRef?.current
      ) {
        const {
          left: targetLeft,
          right: targetRight,
          width: targetWidth,
        } = target.getBoundingClientRect();
        const { right: leftScrimRight } =
          leftScrimRef.current.getBoundingClientRect();
        const { left: rightScrimLeft } =
          rightScrimRef.current.getBoundingClientRect();

        const targetOverlapsLeftScrim = targetLeft < leftScrimRight;
        const targetOverlapsRightScrim = targetRight > rightScrimLeft;

        if (targetOverlapsLeftScrim) {
          const newLeft = linkContainer.scrollLeft - targetWidth;

          linkContainer.scroll({
            behavior: 'smooth',
            left: newLeft,
          });
        }

        if (targetOverlapsRightScrim) {
          const newLeft = linkContainer.scrollLeft + targetWidth;

          linkContainer.scroll({
            behavior: 'smooth',
            left: newLeft,
          });
        }
      }
    },
    [leftScrimRef, rightScrimRef, linkContainerRef],
  );

  return (
    <StyledNavLinksContainer $height={topBarHeight} ref={linkContainerRef}>
      {items.map((item) => (
        <NavLink
          dataTestId={`top-nav-link-${item.label.toLocaleLowerCase().replaceAll(' ', '')}`}
          key={item.id}
          onClick={onNavItemClick}
          to={item.to}
          isActive={isActive(item)}
        >
          {item.label}
        </NavLink>
      ))}
    </StyledNavLinksContainer>
  );
};
