import { Box, Flex, PM, PXL, Tooltip } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useSupportLink } from '~/hooks/useSupportLink';
import type { AppState } from '~/redux';
import type { NewFlowsState } from '~/redux/reducers/newFlows';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';

import {
  OnboardingProgressIndicator,
  SavingsProgressIndicator,
  IdentityFirstFlowProgress,
} from './indicators';

type OnboardingHeaderProps = {
  showDivider?: boolean;
  flowName?: keyof NewFlowsState;
};

const PROGRESS_INDICATORS: Partial<Record<keyof NewFlowsState, JSX.Element>> = {
  onboarding: <OnboardingProgressIndicator />,
  PHONE_VERIFICATION: <OnboardingProgressIndicator />,
  initialFunding: <OnboardingProgressIndicator />,
  connectBank: <OnboardingProgressIndicator />,
  accountSetup: <OnboardingProgressIndicator />,
  // @ts-expect-error - TS2741
  SAVINGS_ONBOARDING: <SavingsProgressIndicator />,
  IDENTITY_FIRST: <IdentityFirstFlowProgress isPhoneVerificationRequired />,
};

export const OnboardingHeader = ({
  showDivider,
  flowName,
}: OnboardingHeaderProps) => {
  const flow = useSelector((state: AppState) => {
    return state.global.activeFlow ?? flowName;
  });
  let stepTitle = useSelector((state: AppState) => {
    const flow = state.global.activeFlow ?? flowName;
    // @ts-ignore Not sure why TS is complaining here. Some context, https://github.com/m1finance/web/pull/8215#discussion_r1187490371.
    return flow ? state.newFlows[flow]?.stepTitle : null;
  });

  const progressIndicator = flow ? PROGRESS_INDICATORS[flow] : null;

  const supportLink = useSupportLink();

  // this is in place for now until we implement updated Module 2 and Module 3 progress bars for CX IO
  if (flow === 'FINANCIAL_SUITABILITY') {
    showDivider = true;
    stepTitle = 'Financial details';
  }

  return (
    <>
      <Box padding="1.5rem 0">
        <Flex maxWidth={1200} mx="auto" px={16} alignItems="center">
          <Icon name="m1Logo32" mr={64} />
          <Box flex="1">{stepTitle && <PXL content={stepTitle} />}</Box>
          <Tooltip
            placement="bottom-end"
            body={
              <PM>
                If you need assistance, please visit our{' '}
                <Link {...supportLink}>Support Center</Link>
              </PM>
            }
          >
            <Icon name="help24" />
          </Tooltip>
        </Flex>
      </Box>
      {showDivider ? <Divider spacing="compact" /> : progressIndicator}
    </>
  );
};
