import {
  type AddSlicesToPieOrganizerAction,
  type AddSlicesToPieOrganizerFailedAction,
  type ConfirmedPieOrganizerExitAction,
  type CreatePieFromPopupPieOrganizerAction,
  type FetchPieDataForOrganizerFailedAction,
  type FetchedPieDataForOrganizerSetRootPieAction,
  type FetchedPieSliceablesAction,
  type InitializePieOrganizerAction,
  PIE_ORGANIZAER_ACTIONS,
  type SavePieOrganizerAction,
  type SavePieOrganizerFailedAction,
  type SavedPieOrganizerAction,
  type TogglePortfolioOrganizerHelperDialogAction,
  type UpdatePortfolioDescriptionAction,
  type UpdatePortfolioNameAction,
} from './pieOrganizerActions.types';

export const initializePieOrganizerAction = (
  payload: InitializePieOrganizerAction['payload'],
): InitializePieOrganizerAction => ({
  type: PIE_ORGANIZAER_ACTIONS.INITIALIZE_PIE_ORGANIZER,
  payload,
});

export const addSlicesToPieOrganizerAction = (
  payload: AddSlicesToPieOrganizerAction['payload'],
): AddSlicesToPieOrganizerAction => ({
  type: PIE_ORGANIZAER_ACTIONS.ADD_SLICES_TO_PIE_ORGANIZER,
  payload,
});

export const addSlicesToPieOrganizerFailed =
  (): AddSlicesToPieOrganizerFailedAction => ({
    type: PIE_ORGANIZAER_ACTIONS.ADD_SLICES_TO_PIE_ORGANIZER_FAILED,
  });

export const fetchedPieSliceables = (
  payload: FetchedPieSliceablesAction['payload'],
): FetchedPieSliceablesAction => ({
  type: PIE_ORGANIZAER_ACTIONS.FETCHED_PIE_SLICEABLES,
  payload,
});

export const fetchedPieDataForOrganizerSetRootPie = (
  payload: FetchedPieDataForOrganizerSetRootPieAction['payload'],
): FetchedPieDataForOrganizerSetRootPieAction => ({
  type: PIE_ORGANIZAER_ACTIONS.FETCHED_PIE_DATA_FOR_ORGANIZER_SET_ROOT_PIE,
  payload,
});

export const fetchPieDataForOrganizerFailedAction =
  (): FetchPieDataForOrganizerFailedAction => ({
    type: PIE_ORGANIZAER_ACTIONS.FETCH_PIE_DATA_FOR_ORGANIZER_FAILED,
  });

export const savePieOrganizerAction = (
  payload: SavePieOrganizerAction['payload'],
): SavePieOrganizerAction => ({
  type: PIE_ORGANIZAER_ACTIONS.SAVE_PIE_ORGANIZER,
  payload,
});

export const savedPieOrganizerAction = (): SavedPieOrganizerAction => ({
  type: PIE_ORGANIZAER_ACTIONS.SAVED_PIE_ORGANIZER,
});

export const savePieOrganizerFailedAction =
  (): SavePieOrganizerFailedAction => ({
    type: PIE_ORGANIZAER_ACTIONS.SAVE_PIE_ORGANIZER_FAILED,
  });

export const confirmedPieOrganizerExit =
  (): ConfirmedPieOrganizerExitAction => ({
    type: PIE_ORGANIZAER_ACTIONS.CONFIRMED_PIE_ORGANIZER_EXIT,
  });

export const updatePortfolioNameAction = (
  payload: UpdatePortfolioNameAction['payload'],
): UpdatePortfolioNameAction => ({
  type: PIE_ORGANIZAER_ACTIONS.UPDATE_PORTFOLIO_NAME,
  payload,
});

export const updatePortfolioDescriptionAction = (
  payload: UpdatePortfolioDescriptionAction['payload'],
): UpdatePortfolioDescriptionAction => ({
  type: PIE_ORGANIZAER_ACTIONS.UPDATE_PORTFOLIO_DESCRIPTION,
  payload,
});

export const togglePortfolioOrganizerHelperDialogAction =
  (): TogglePortfolioOrganizerHelperDialogAction => ({
    type: PIE_ORGANIZAER_ACTIONS.TOGGLE_PORTFOLIO_ORGANIZER_HELPER_DIALOGS,
  });

export const createPieFromPopupPortfolioOrganizerAction = (
  payload: CreatePieFromPopupPieOrganizerAction['payload'],
): CreatePieFromPopupPieOrganizerAction => ({
  payload,
  type: PIE_ORGANIZAER_ACTIONS.CLICKED_CREATE_PIE_FROM_DROP_DOWN,
});
