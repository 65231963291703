import * as React from 'react';

import type { VirtualizationProps } from './table';

export type GridContextValue<T> = {
  defaultAlignSelf: React.CSSProperties['alignSelf'];
  gridTemplateColumns: string;
  isNewStyle: boolean;
  isVirtualized: boolean;
  virtualization: VirtualizationProps<T> | undefined;
};

export const GridContext = React.createContext<GridContextValue<any>>({
  defaultAlignSelf: 'center',
  gridTemplateColumns: 'repeat(auto)',
  isNewStyle: false,
  isVirtualized: false,
  virtualization: undefined,
});
