import { HM } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import debounce from 'lodash-es/debounce';
import * as React from 'react';

import { FundAccountForm } from '~/forms';
import { useFundAccountPageQuery } from '~/graphql/hooks';
import type { IraContributionYearEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useOnMount } from '~/hooks/useOnMount';
import {
  submittedInitialDepositFundsForm,
  skippedOnboardingDeposit,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export type InitialDepositFormValues = {
  accountId: Maybe<string>;
  fromParticipantId: Maybe<string>;
  schedule: any; // TODO: type this.
  fundAccountAmount: number;
  isIraRollover: boolean;
  iraContributionYear: Maybe<IraContributionYearEnum>;
  isOneTimeDeposit: boolean;
};

type MakeInitialDepositStepProps = {
  onSubmit?: (formValues: InitialDepositFormValues) => void;
  showSkipButton?: boolean;
  hideAccountNameAndNumber?: boolean;
};

export const MakeInitialDepositStep = ({
  onSubmit,
  showSkipButton = true,
  hideAccountNameAndNumber = false,
}: MakeInitialDepositStepProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  useOnMount(() => {
    // Screen view analytics event.
    analytics.recordEvent('m1_initial_funding_deposit_plaid');
  });

  const fromParticipantId: string | null | undefined = useSelector(
    (state) => state.form['fund-account']?.values?.fromParticipantId ?? null,
  );
  const schedule = useSelector(
    (state) => state.form['fund-account']?.values?.schedule ?? null,
  );

  const fundAccountAmount = useSelector(
    (state) => state.form['fund-account']?.values?.fundAccountAmount ?? 0,
  );

  const accountId = useSelector(
    (state) =>
      state.newFlows.initialFunding.accountId ??
      state.newFlows.accountSetup.accountId,
  );
  const isIraRollover: boolean | null = useSelector(
    (state) => state.form['fund-account']?.values?.isIraRollover ?? null,
  );

  const iraContributionYear: IraContributionYearEnum | null = useSelector(
    (state) => state.form['fund-account']?.values?.iraContributionYear ?? null,
  );

  const date = React.useMemo(() => new Date().toISOString(), []);
  const { data } = useFundAccountPageQuery({
    variables: {
      accountId: accountId as string,
      date,
    },
    skip: !accountId,
  });

  const [isScheduleSwitchOn, setIsScheduleSwitchOn] =
    React.useState<boolean>(true);
  const isFundingFirstInvestAccount =
    data?.viewer?.accounts?.edges?.length === 1;

  const handleSubmit = (): void => {
    analytics.recordEvent('m1_initial_deposit_created_onboarding');

    if (fromParticipantId) {
      dispatch(
        submittedInitialDepositFundsForm({
          schedule,
          amount: fundAccountAmount,
          achRelationshipId: fromParticipantId,
          isOneTimeDeposit: !isScheduleSwitchOn,
          isIraRollover,
          iraContributionYear,
        }),
      );
    }
  };

  const onSubmitWrapper = () => {
    if (onSubmit) {
      return onSubmit({
        accountId,
        fundAccountAmount,
        fromParticipantId,
        schedule,
        isIraRollover: Boolean(isIraRollover),
        iraContributionYear,
        isOneTimeDeposit: !isScheduleSwitchOn,
      });
    }
    return handleSubmit();
  };

  const handleSubmitDebounced = debounce(onSubmitWrapper, 1000);

  const onSkip = () => {
    analytics.recordEvent('m1_onboarding_initial_deposit_skipped');
    if (showSkipButton) {
      // legacy saga route
      dispatch(skippedOnboardingDeposit());
    } else {
      navigate({
        to: '/onboarding/initial-funding/skipped-deposit-confirmation',
      });
    }
  };

  return (
    <>
      {/* If SkipButton isn't shown, then we show the experimental transfers phase 2 CTA */}
      {!showSkipButton ? (
        <Icon
          name="close24"
          ml={32}
          onClick={onSkip}
          style={{ cursor: 'pointer', position: 'absolute', right: 32 }}
        />
      ) : null}
      <div
        style={{
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          width: 426,
        }}
      >
        <HM content="Make an initial deposit" mb={24} />
        <FundAccountForm
          showForm
          useOnboardingLayout
          hideAccountNameAndNumber={hideAccountNameAndNumber}
          fromParticipantId={fromParticipantId}
          transfers={data?.viewer?.transfers}
          isFundingFirstInvestAccount={isFundingFirstInvestAccount}
          account={data?.account}
          onNextStepClick={handleSubmitDebounced}
          isScheduleSwitchOn={isScheduleSwitchOn}
          setIsScheduleSwitchOn={setIsScheduleSwitchOn}
          schedule={schedule}
          onSubmit={() => {}}
          onSkip={onSkip}
          isCryptoAccount={false}
          showSkipCTA={showSkipButton}
        />
      </div>
    </>
  );
};
