import type { SagaIterator } from 'redux-saga';
import { all, call, put, select, take } from 'redux-saga/effects';

import type { PieOrganizerEvent } from '~/components/PortfolioOrganizerPage';
import {
  SliceableSagaDocument,
  type SliceableSagaQueryResult,
  ViewerAccountsRootSlicesSagaDocument,
  type ViewerAccountsRootSlicesSagaQueryResult,
} from '~/graphql/hooks';

import type {
  ViewerAccountRootSliceNodeFragment,
  ViewerAccountsRootSlicesSagaQuery,
} from '~/graphql/types';
import type { NavigateFunction } from '~/hooks/useNavigate';
import {
  ACTION_TYPES as ACTIONS,
  displayErrorNotification,
  showModal,
} from '~/redux/actions';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* clickedAddToPortfolio(
  sliceableId: string,
): SagaIterator<void> {
  const [{ data: viewerAccountRoot }, { data: slicebaleData }]: [
    ViewerAccountsRootSlicesSagaQueryResult,
    SliceableSagaQueryResult,
  ] = yield all([
    call(apolloQuerySaga, {
      query: ViewerAccountsRootSlicesSagaDocument,
    }),
    call(apolloQuerySaga, {
      query: SliceableSagaDocument,
      variables: {
        sliceableId,
      },
    }),
  ]);

  const accountsWithRootPies = readAccountsWithRootPies(
    viewerAccountRoot?.viewer,
  );

  if (accountsWithRootPies.length === 0) {
    // User has no accounts with root pies. Ideally this function isn't called at
    // all in this scenario.
    yield put(displayErrorNotification('Please create a portfolio first'));
  } else if (
    // This case will only handle when the user has 1 total root pie set for all accounts
    // AND it is a CRYPTO slice being add to is a CRYPTO portfolio
    accountsWithRootPies.length === 1 &&
    slicebaleData?.node?.__typename === 'UserPie' &&
    Boolean(slicebaleData.node.containsCrypto) &&
    accountsWithRootPies[0].registration === 'CRYPTO'
  ) {
    yield call(addToAccount, slicebaleData?.node, accountsWithRootPies[0]);
  } else if (slicebaleData?.node?.id) {
    // All other cases will be handle by the select invest account modal
    yield put(showModal('SELECT_INVEST_ACCOUNT', slicebaleData.node.id));
    const action = yield take(ACTIONS.INVEST_ACCOUNT_SELECTED);
    const i = accountsWithRootPies.findIndex((a) => a.id === action.payload);
    if (i === -1) {
      yield put(displayErrorNotification('Error selecting this account'));
    } else {
      yield call(addToAccount, slicebaleData?.node, accountsWithRootPies[i]);
    }
  }
}

function* addToAccount(sliceable: any, account: any): SagaIterator<void> {
  const rootPortfolioSlice = account.rootPortfolioSlice;
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  if (!rootPortfolioSlice) {
    yield put(displayErrorNotification('Account does not have a portfolio!'));
  } else {
    const organizerEvent: PieOrganizerEvent = {
      type: 'INITIALIZE_ORGANIZER',
      initConfig: {
        confirmationDialog: {
          showApplicableLocations: true,
        },
        mode: 'EDIT_ROOT_PIE',
        pieId: rootPortfolioSlice.to.id,
        returnTo: '/d/invest',
        sliceableIds: [sliceable.id],
        isCrypto: sliceable.type === 'CRYPTO',
      },
    };

    // Navigate
    yield call(navigate, {
      to: '/d/invest/portfolio-organizer',
      options: {
        state: {
          event: organizerEvent,
        },
      },
    });
  }
}

function readAccountsWithRootPies(
  viewer: ViewerAccountsRootSlicesSagaQuery['viewer'] | null | undefined,
): Array<ViewerAccountRootSliceNodeFragment> {
  if (viewer && viewer.accounts && Array.isArray(viewer.accounts.edges)) {
    return viewer.accounts.edges
      .map((e) => {
        return e && e.node && e.node.rootPortfolioSlice ? e.node : null;
      })
      .filter(Boolean) as Array<ViewerAccountRootSliceNodeFragment>;
  }

  return [];
}
