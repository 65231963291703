import { Box, PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  ContactInformation,
  FinancialInformation,
  PersonalInformation,
  ResidentialInformation,
} from '~/components/FormSections';
import type { CreditCardApplicationSummaryApplicantFragment } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';

const StyledBox = styled(Box)`
  padding: 32px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
`;

export type SummaryComponentProps = {
  creditInformation: CreditCardApplicationSummaryApplicantFragment;
  onFinishStep: () => void;
  showIncomeInformation?: boolean;
};

export const CreditCardApplicationSummary = ({
  creditInformation,
  showIncomeInformation,
  onFinishStep,
}: SummaryComponentProps) => {
  const { totalAnnualIncome, monthlyPayments, housingType } = useSelector(
    (state) => state.newFlows.CREDIT_CARD_APPLICATION,
  );

  const { applicantInformation } = creditInformation;
  const finalSummaryHeader =
    'View and update your profile information if there have been any changes.';
  const prefilledSummaryHeader =
    'We have prefilled most of your application with information we already have on file. View and update your profile information if there have been any changes.';
  return (
    <>
      {applicantInformation && (
        <Box>
          <StyledBox>
            <PM
              content={
                showIncomeInformation
                  ? finalSummaryHeader
                  : prefilledSummaryHeader
              }
              mt={32}
            />
            <PersonalInformation
              fullName={applicantInformation.fullName}
              dateOfBirth={applicantInformation.dateOfBirth}
              ssnLastFour={applicantInformation.ssnLastFour}
            />
          </StyledBox>
          <StyledBox>
            <ContactInformation
              email={applicantInformation.email}
              phoneNumber={applicantInformation.phoneNumber}
            />
          </StyledBox>
          {showIncomeInformation &&
          totalAnnualIncome &&
          monthlyPayments &&
          housingType ? (
            <Box>
              <StyledBox>
                <ResidentialInformation
                  legalResidence={applicantInformation.legalResidence}
                />
              </StyledBox>
              <Box pt={32}>
                <FinancialInformation
                  totalAnnualIncome={totalAnnualIncome}
                  monthlyPayments={monthlyPayments}
                  housingType={housingType}
                  onFinishStep={onFinishStep}
                />
              </Box>
            </Box>
          ) : (
            <Box p="32px 0px">
              <ResidentialInformation
                legalResidence={applicantInformation.legalResidence}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
