import { Box } from '@m1/liquid-react';
import React from 'react';
import { FormSection } from 'redux-form';

import { ScheduleFrequencyFields } from '~/forms/fields';

import { TRANSFER_SCHEDULE_FREQUENCIES } from '~/static-constants';

import { DepositInfoCard } from '../DepositInfoCard';

type RecurringDepositFrequencyFieldsProps = {
  isEvenWeek: boolean;
  isScheduleSwitchOn: boolean;
};

export const RecurringDepositFrequencyFields = ({
  isEvenWeek,
  isScheduleSwitchOn,
}: RecurringDepositFrequencyFieldsProps) => {
  if (!isScheduleSwitchOn) {
    return null;
  }
  return (
    <Box>
      <FormSection name="schedule">
        <ScheduleFrequencyFields
          initialFrequency={TRANSFER_SCHEDULE_FREQUENCIES.MONTHLY}
          isEvenWeek={isEvenWeek}
        />
      </FormSection>
      <DepositInfoCard />
    </Box>
  );
};
