import * as React from 'react';

import type { TransferParticipantFragment } from '~/graphql/types';

export type WireTransferContextType = {
  accounts: TransferParticipantFragment[] | null | undefined;
  selectedAccount: string | null;
  setSelectedAccount: React.Dispatch<
    React.SetStateAction<string | null>
  > | null;
};

export const WireTransferContext = React.createContext<WireTransferContextType>(
  {
    accounts: null,
    selectedAccount: null,
    setSelectedAccount: null,
  },
);

export const useWireTransferContext = () => {
  const wireTransfer = React.useContext(WireTransferContext);
  if (!wireTransfer) {
    throw new Error(
      'You must use useWireTransferContext within WireTransferProvider!',
    );
  }
  return wireTransfer;
};
