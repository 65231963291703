import { Box, Button, Flex, HXS, PXL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { useNavigate } from '~/hooks/useNavigate';
import type {
  SetSmartTransferRuleInput,
  SmartTransferContraParticipantEntryInput,
} from '~/redux/reducers/newFlows/reducers/createSmartTransferReducer';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';

import { ConfirmModal } from '../modals/confirm-modal';
import { SmartTransferContraParticipantEntryOptions } from '../SmartTransferContraParticipantEntryOptions';
import { SmartTransferBalanceTriggerInput } from '../SmartTransferCreateForm/SmartTransferBalanceTriggerInput';
import { SmartTransferRefillBalanceInput } from '../SmartTransferCreateForm/SmartTransferRefillBalanceInput';
import { SmartTransferWaterfall } from '../SmartTransferCreateForm/SmartTransferWaterfall';
import { SmartTransferTimingMessage } from '../SmartTransferTimingMessage';

import { AddContraEntry } from './AddContraEntry';
import { EditContraEntry } from './EditContraEntry';
import type { LocalContraEntry } from './types';

export type SmartTransferEditFormProps = {
  mode: FormMode;
  onClickAddContraEntry: (newContraParticipantEntryOptionId: string) => void;
  onClickEditContraEntry: (
    contraParticipantEntryEditRequirementsId: string,
  ) => void;
  onDelete: (arg0: string) => void;
  onSubmit: (arg0: SetSmartTransferRuleInput) => void;
  smartTransferRule: any;
  refetch: any;
};

type FormMode =
  | {
      type: 'EDIT_RULE';
    }
  | {
      newContraParticipantEntryOptionId: string;
      type: 'ADD_CONTRA_ENTRY';
    }
  | {
      contraParticipantEntryEditRequirementsId: string;
      type: 'EDIT_CONTRA_ENTRY';
    };

/**
 * This function is used to map `LocalContraEntry` to `SmartTransferContraParticipantEntryInput` for our Waterfall component.
 * Passing along the extra keys in 'LocalContraEntry' will cause the mutation to fail
 */
function mapEditEntriesToInputEntries(
  contraParticipantEntriesForEdit: Array<LocalContraEntry>,
): Array<SmartTransferContraParticipantEntryInput> {
  // @ts-expect-error - TS2322 - Type '({ contraParticipantId: string | null | undefined; existingContraParticipantEntryId: string; fulfillmentCondition: SmartTransferFulfillmentConditionInput; } | { ...; })[]' is not assignable to type 'SmartTransferContraParticipantEntryInput[]'.
  return contraParticipantEntriesForEdit.map((entry) => {
    if (entry.type === 'CONTRA_PARTICIPANT_ENTRY') {
      return {
        contraParticipantId: entry.contraParticipantId,
        existingContraParticipantEntryId:
          entry.existingContraParticipantEntryId,
        fulfillmentCondition: entry.fulfillmentCondition,
      };
    }
    return {
      contraParticipantId: entry.contraParticipantId,
      existingContraParticipantEntryId: null,
      fulfillmentCondition: entry.fulfillmentCondition,
    };
  });
}

/**
 * For the waterfall, we must pass along a mapping of our __editRequirementsId for each contra.
 * This id allows us to query for the correct fulfillment conditions when editing a ST.
 */
function getContraParticipantIdToEditRequirementsIdMapping(
  contraParticipantEntriesForEdit: Array<LocalContraEntry>,
): Record<string, string> {
  let result = {};
  contraParticipantEntriesForEdit.forEach((entry) => {
    if (entry.contraParticipantId) {
      result = {
        ...result,
        [entry.contraParticipantId]: entry.__editRequirementsId,
      };
    }
  });
  return result;
}

export const SmartTransferEditForm = ({
  mode,
  onClickAddContraEntry,
  onClickEditContraEntry,
  onDelete,
  onSubmit,
  refetch,
  smartTransferRule,
}: SmartTransferEditFormProps) => {
  const navigate = useNavigate();
  // State handling for balance trigger threshold
  const [balanceTriggerAmountValue, setBalanceTriggerAmountValue] =
    React.useState<number>(smartTransferRule.balanceTrigger.balanceThreshold);
  const [amountErrorMessage, setAmountErrorMessage] =
    React.useState<string>('');

  // State handling for balance trigger underbalance refill
  const [refillTargetValue, setRefillTargetValue] = React.useState<
    number | null | undefined
  >(smartTransferRule.balanceTrigger.underBalanceRefillTarget);
  const [refillTargetErrorMessage, setRefillTargetErrorMessage] =
    React.useState<string>('');

  // State handling for contra participant entries
  const [contraParticipantEntries, setContraParticipantEntries] =
    React.useState<Array<LocalContraEntry>>(
      toLocalContraEntries(smartTransferRule),
    );

  // for edit order mode
  const [contraParticipantIdOrder, setContraParticipantIdOrder] =
    React.useState([]);

  const [isInEditOrderMode, setIsInEditOrderMode] = React.useState(false);
  const handleEditOrder = () => {
    if (isInEditOrderMode && contraParticipantIdOrder.length > 1) {
      const sortedEntries = contraParticipantIdOrder
        .map((participantId) =>
          contraParticipantEntries.find(
            (participant) => participant.contraParticipantId === participantId,
          ),
        )
        .filter(Boolean);
      // @ts-expect-error - TS2345 - Argument of type '(LocalContraEntry | undefined)[]' is not assignable to parameter of type 'SetStateAction<LocalContraEntry[]>'.
      setContraParticipantEntries(sortedEntries);
    }
    setIsInEditOrderMode(!isInEditOrderMode);
  };
  const [
    shouldDisplayNewContraEntryOptions,
    setShouldDisplayNewContraEntryOptions,
  ] = React.useState<boolean>(false);
  React.useEffect(() => {
    refetch({
      contraParticipantEntries: contraParticipantEntries.map((entry) =>
        toRemoteContraEntryInput(entry),
      ),
    });
  }, [contraParticipantEntries, refetch]);

  const { balanceTrigger, editRequirements, focusParticipant } =
    smartTransferRule;

  if (!editRequirements) {
    throw new Error('Editing a rule requires its edit requirements.');
  }

  const isUpdateButtonDisabled = Boolean(
    amountErrorMessage || refillTargetErrorMessage || isInEditOrderMode,
  );

  const handleFinishAddContraEntry = (
    newContraEntry: LocalContraEntry,
  ): void => {
    // if an entry with a fulfillment condition of type, 'INDEFINITE' is found,
    // insert the contra directly before it. otherwise, insert at the end.
    // @ts-expect-error - TS7006 - Parameter 'entry' implicitly has an 'any' type.
    const isIndefinite = (entry) =>
      entry.fulfillmentCondition?.fulfillmentConditionType === 'INDEFINITE';
    const indexOfIndefinite = contraParticipantEntries.findIndex(isIndefinite);
    if (indexOfIndefinite !== -1) {
      setContraParticipantEntries([
        ...contraParticipantEntries.slice(0, indexOfIndefinite),
        newContraEntry,
        ...contraParticipantEntries.slice(indexOfIndefinite),
      ]);
    } else {
      setContraParticipantEntries([
        ...contraParticipantEntries,
        newContraEntry,
      ]);
    }

    navigate({
      to: '/d/c/edit-smart-transfer/:smartTransferRuleId',
      params: { smartTransferRuleId: smartTransferRule.id },
    });
  };

  const handleFinishEditContraEntry = (
    editedContraEntry: LocalContraEntry,
    editType: 'REPLACE' | 'REMOVE',
  ): void => {
    // @ts-expect-error - TS7034 - Variable 'editedEntries' implicitly has type 'any[]' in some locations where its type cannot be determined.
    const editedEntries = [];
    contraParticipantEntries.forEach((existingEntry) => {
      if (
        existingEntry.__editRequirementsId ===
        editedContraEntry.__editRequirementsId
      ) {
        // if we are removing, exit iteration so we do not add the entry to result set.
        if (editType === 'REMOVE') {
          return;
        }
        editedEntries.push(editedContraEntry);
      } else {
        editedEntries.push(existingEntry);
      }
    });

    // @ts-expect-error - TS7005 - Variable 'editedEntries' implicitly has an 'any[]' type.
    setContraParticipantEntries(editedEntries);

    navigate({
      to: '/d/c/edit-smart-transfer/:smartTransferRuleId',
      params: { smartTransferRuleId: smartTransferRule.id },
    });
  };

  const handleClickConfirmDelete = () => {
    onDelete(smartTransferRule.id);
  };

  const handleClickUpdate = () => {
    onSubmit({
      existingSmartTransferRuleId: smartTransferRule.id,
      focusParticipantId: null,
      balanceTrigger: {
        balanceTriggerType: balanceTrigger.balanceTriggerType,
        balanceThreshold: balanceTriggerAmountValue,
        underBalanceRefillTarget: refillTargetValue,
      },
      contraParticipantEntries: contraParticipantEntries.map((entry) =>
        toRemoteContraEntryInput(entry),
      ),
    });
  };

  if (mode.type === 'ADD_CONTRA_ENTRY') {
    return (
      <AddContraEntry
        onFinishAddContraEntry={handleFinishAddContraEntry}
        newContraParticipantEntryOptionId={
          mode.newContraParticipantEntryOptionId
        }
        smartTransferRuleId={smartTransferRule.id}
      />
    );
  } else if (mode.type === 'EDIT_CONTRA_ENTRY') {
    const contraEntryToEdit = contraParticipantEntries.find((entry) => {
      return (
        entry.__editRequirementsId ===
        mode.contraParticipantEntryEditRequirementsId
      );
    });

    // It is entirely possible to edit a contra that has not yet been submitted.
    // This means it does not make sense to discern between LocalContraParticipant and LocalContraParticipantEntry at this level.
    if (contraEntryToEdit) {
      return (
        <EditContraEntry
          contraEntryToEdit={contraEntryToEdit}
          editRequirementsId={mode.contraParticipantEntryEditRequirementsId}
          onFinishEditContraEntry={handleFinishEditContraEntry}
          smartTransferRuleId={smartTransferRule.id}
        />
      );
    }
  }

  const handleAddMoreContraParticipantsClick = () => {
    refetch({
      insertionContext: {
        contraParticipantEntries: contraParticipantEntries.map((localEntry) =>
          toRemoteContraEntryInput(localEntry),
        ),
        newEntryInsertionIndex: contraParticipantEntries.length,
      },
    });

    setShouldDisplayNewContraEntryOptions(true);
  };

  return (
    <Box width={582} py={100}>
      <BackArrow
        content="Smart Transfer Details"
        mb={16}
        params={{
          smartTransferRuleId: smartTransferRule.id,
        }}
        to="/d/c/smart-transfer-details/:smartTransferRuleId"
      />
      <HXS content="Edit Smart Transfer" />
      <Card mt={32} py={12}>
        {focusParticipant && (
          <>
            <Box px={32} py={20}>
              <TransferParticipantCell
                includeDescription
                transferParticipant={focusParticipant}
              />
            </Box>
            <Divider spacing="none" />
          </>
        )}
        <PXL
          fontWeight={600}
          content={editRequirements.balanceThresholdPreamble}
          mt={32}
          mx={32}
        />
        <Box mx={32}>
          <SmartTransferBalanceTriggerInput
            balanceTriggerEditRequirements={
              editRequirements.balanceTriggerEditRequirements
            }
            mt={8}
            mx={32}
            onInputDataChange={({ amountValue, amountErrorMessage }) => {
              setBalanceTriggerAmountValue(Number(amountValue));
              setAmountErrorMessage(amountErrorMessage);
            }}
            preFilledAmount={`${balanceTriggerAmountValue}`}
          />
          {typeof balanceTrigger.underBalanceRefillTarget === 'number' && (
            <>
              <PXL fontWeight={600} content="Refill balance to" mt={16} />
              <SmartTransferRefillBalanceInput
                balanceTriggerEditRequirements={
                  editRequirements.balanceTriggerEditRequirements
                }
                balanceThresholdAmount={`${balanceTriggerAmountValue}`}
                mt={16}
                onInputDataChange={({
                  underBalanceRefillTargetAmount,
                  underBalanceRefillTargetAmountErrorMessage,
                }) => {
                  setRefillTargetValue(Number(underBalanceRefillTargetAmount));
                  setRefillTargetErrorMessage(
                    underBalanceRefillTargetAmountErrorMessage,
                  );
                }}
                preFilledAmount={
                  typeof refillTargetValue === 'number'
                    ? `${refillTargetValue}`
                    : ''
                }
              />
            </>
          )}
          <Flex alignItems="baseline" justifyContent="space-between" mx={32}>
            <PXL
              fontWeight={600}
              content={editRequirements.contraParticipantListPreamble}
              mt={32}
            />
            {editRequirements.shouldShowContraParticipantEntryReordering && (
              <Button
                kind="link"
                label={isInEditOrderMode ? 'Done' : 'Edit Order'}
                onClick={handleEditOrder}
              />
            )}
          </Flex>
          <SmartTransferWaterfall
            balanceTriggerType={balanceTrigger.balanceTriggerType}
            contraParticipantEntries={mapEditEntriesToInputEntries(
              contraParticipantEntries,
            )}
            contraParticipantIdToEditRequirementsIdMapping={getContraParticipantIdToEditRequirementsIdMapping(
              contraParticipantEntries,
            )}
            editRequirements={editRequirements}
            focusAccountId={focusParticipant ? focusParticipant.id : ''}
            isInEditOrderMode={isInEditOrderMode}
            isShowingEntryOptions={shouldDisplayNewContraEntryOptions}
            onAddMoreContraParticipantsClick={
              handleAddMoreContraParticipantsClick
            }
            onEditContraClick={(editRequirementsId) => {
              onClickEditContraEntry(editRequirementsId);
            }}
            setContraParticipantIdOrder={setContraParticipantIdOrder as any}
          />
          {shouldDisplayNewContraEntryOptions &&
            editRequirements.newContraParticipantEntryOptionSet && (
              <Box mb={32} mt={16}>
                <SmartTransferContraParticipantEntryOptions
                  newContraParticipantEntryOptionSet={
                    editRequirements.newContraParticipantEntryOptionSet
                  }
                  onOptionSelect={(
                    newSmartTransferContraParticipantEntryOptionId,
                  ) => {
                    onClickAddContraEntry(
                      newSmartTransferContraParticipantEntryOptionId,
                    );
                    setShouldDisplayNewContraEntryOptions(false);
                  }}
                />
              </Box>
            )}
          <SmartTransferTimingMessage
            balanceTrigger={{
              balanceTriggerType: balanceTrigger.balanceTriggerType as any,
              balanceThreshold: balanceTriggerAmountValue,
              underBalanceRefillTarget: refillTargetValue,
            }}
            editRequirementsId={editRequirements.id}
            mt={16}
            mx={32}
          />
          <ButtonGroup behavior="centered" mt={48} mb={16}>
            <ConfirmModal
              title="Are you sure you want to delete this Smart Transfer?"
              trigger={
                <Button kind="destructive" label="Delete" size="large" />
              }
              onConfirm={handleClickConfirmDelete}
            />
            <Button
              disabled={isUpdateButtonDisabled}
              label="Update"
              onClick={handleClickUpdate}
              size="large"
            />
          </ButtonGroup>
        </Box>
      </Card>
    </Box>
  );
};

const toLocalContraEntries = (
  smartTransferRule: any,
): Array<LocalContraEntry> => {
  if (!smartTransferRule.contraParticipantEntries) {
    throw new Error('Requires contra entries to generate input.');
  }

  const entries: Array<LocalContraEntry> = [];
  for (const entry of smartTransferRule.contraParticipantEntries) {
    if (!entry) {
      throw new Error('Unable to collect contra entries if an entry is null!');
    }
    if (!entry.contraParticipant) {
      throw new Error('Each entry must have the existing contra ID available.');
    }
    if (!entry.editRequirements) {
      throw new Error('Each entry must have the existing edit requirements.');
    }
    entries.push({
      __editRequirementsId: entry.editRequirements.id,
      contraParticipantId: entry.contraParticipant.id,
      existingContraParticipantEntryId: entry.id,
      fulfillmentCondition: {
        fulfillmentConditionType:
          entry.fulfillmentCondition.fulfillmentConditionType,
        borrowAmount: entry.fulfillmentCondition.borrowAmount,
        borrowUtilization: entry.fulfillmentCondition.utilization,
        capAmount: entry.fulfillmentCondition.capAmount,
        fundingAmount: entry.fulfillmentCondition.fundingAmount,
        fundingPeriod: entry.fulfillmentCondition.fundingPeriod,
      },
      type: 'CONTRA_PARTICIPANT_ENTRY',
    });
  }
  return entries;
};

const toRemoteContraEntryInput = (
  localEntry: LocalContraEntry,
): SmartTransferContraParticipantEntryInput => {
  return {
    existingContraParticipantEntryId:
      // @ts-expect-error - TS2339 - Property 'existingContraParticipantEntryId' does not exist on type 'LocalContraEntry'.
      localEntry.existingContraParticipantEntryId || null,
    contraParticipantId: localEntry.contraParticipantId || null,
    // Ignoring because we are reasonably assuming fulfillmentCondition will always be "ready" when this function is called.
    // This is despite that fact that this field is typed in such a way that allows a fulfillCondition to be "in progress", or not "ready" as some point in time.
    // @ts-expect-error - TS2322 - Type 'SmartTransferFulfillmentConditionInput | null | undefined' is not assignable to type 'SmartTransferFulfillmentConditionInput'.
    fulfillmentCondition: localEntry.fulfillmentCondition,
  };
};
