import padStart from 'lodash-es/padStart';
import trimStart from 'lodash-es/trimStart';

import { defaultProps } from '~/hocs';
import { formatCurrency } from '~/utils/formatting';

import { TextField } from './text-field';

const enhancer = defaultProps({
  name: 'depositAmount',
  label: 'Deposit Amount',
  format: format,
  normalize: normalize,
  validate: validate,
});

function format(value: number): string {
  return formatCurrency(value) ?? '--';
}

function normalize(value: string): number {
  const [, deposit] = value.replace(/[^\d.]/g, '').split('.');
  const trimmed = trimStart(deposit, '0').slice(0, 2);

  return parseFloat(`0.${padStart(trimmed, 2, '0')}`);
}

function validate(value: number): string | null | undefined {
  if (typeof value !== 'number' || value >= 1 || value <= 0) {
    return 'Not a valid deposit amount';
  }
}

export const MicroDepositField = enhancer(TextField) as any;
