import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  IdentityFirstAccountSetupCountDocument,
  type IdentityFirstAccountSetupCountQueryResult,
  IdentityFirstAccountSetupProfileDocument,
  type IdentityFirstAccountSetupProfileQueryResult,
  IdentityFirstQuestionsDocument,
  type IdentityFirstQuestionsQueryResult,
  InvestAccountOpeningQuestionsDocument,
  type InvestAccountOpeningQuestionsQueryResult,
} from '~/graphql/hooks';
import {
  type IdentityFirstAccountSetupProfileQuery,
  type InvestAccountOpening,
  type OnboardingIdentityFirst,
} from '~/graphql/types';
import { apolloQuerySaga } from '~/redux/sagas/apolloQuerySaga';

export function* queryInvestAccountQuestions(): SagaIterator<
  InvestAccountOpening | null | undefined
> {
  const { data }: InvestAccountOpeningQuestionsQueryResult = yield call(
    apolloQuerySaga,
    {
      query: InvestAccountOpeningQuestionsDocument,
    },
  );

  return data?.viewer?.invest?.accountOpening || null;
}

export function* queryOnboardingQuestions(): SagaIterator<
  Partial<OnboardingIdentityFirst> | null | undefined
> {
  const { data }: IdentityFirstQuestionsQueryResult = yield call(
    apolloQuerySaga,
    {
      query: IdentityFirstQuestionsDocument,
    },
  );
  return data?.viewer?.user?.onboardingIdentityFirst || null;
}

export function* queryViewerProfile(): SagaIterator<
  IdentityFirstAccountSetupProfileQuery['viewer']['profile'] | null | undefined
> {
  const { data }: IdentityFirstAccountSetupProfileQueryResult = yield call(
    apolloQuerySaga,
    {
      query: IdentityFirstAccountSetupProfileDocument,
    },
  );
  return data?.viewer?.profile;
}

export function* queryAccountCount(): SagaIterator<number | null | undefined> {
  const { data }: IdentityFirstAccountSetupCountQueryResult = yield call(
    apolloQuerySaga,
    {
      query: IdentityFirstAccountSetupCountDocument,
    },
  );
  return data?.viewer?.accounts?.edges?.length || null;
}
