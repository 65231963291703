import * as React from 'react';

import type { PieDetailsContextType } from '../PieDetailsPage.types';

export const PieDetailsContext =
  React.createContext<null | PieDetailsContextType>(null);

export const usePieDetailsContext = () => {
  const pieDetails = React.useContext(PieDetailsContext);
  if (!pieDetails) {
    throw new Error(
      'usePieDetailsContext must be used within PieDetailsProvider.',
    );
  }

  return pieDetails;
};
