import type { Pie, ResponseSliceable, Slice } from '~/pie-trees';

import type { PieOrganizerConfig } from './pieOrganizerConfig.types';

export const PIE_ORGANIZAER_ACTIONS = {
  ADD_SLICES_TO_PIE_ORGANIZER: 'ADD_SLICES_TO_PIE_ORGANIZER' as const,
  ADD_SLICES_TO_PIE_ORGANIZER_FAILED:
    'ADD_SLICES_TO_PIE_ORGANIZER_FAILED' as const,
  ADJUSTED_PORTFOLIO_ORGANIZER_SLICE_PERCENTAGE:
    'ADJUSTED_PORTFOLIO_ORGANIZER_SLICE_PERCENTAGE' as const,
  BEGAN_NEW_ROOT_PIE_ORGANIZER_SESSION:
    'BEGAN_NEW_ROOT_PIE_ORGANIZER_SESSION' as const,
  CLICKED_PORTFOLIO_ORGANIZER_BREADCRUMB:
    'CLICKED_PORTFOLIO_ORGANIZER_BREADCRUMB' as const,
  CLICKED_PORTFOLIO_ORGANIZER_PIE_SLICE:
    'CLICKED_PORTFOLIO_ORGANIZER_PIE_SLICE' as const,
  CREATED_PORTFOLIO_ORGANIZER_NEW_PIE_SLICE:
    'CREATED_PORTFOLIO_ORGANIZER_NEW_PIE_SLICE' as const,
  ENDED_NEW_ROOT_PIE_ORGANIZER_SESSION:
    'ENDED_NEW_ROOT_PIE_ORGANIZER_SESSION' as const,
  MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE:
    'MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE' as const,
  CLICKED_CREATE_PIE_FROM_DROP_DOWN:
    'CLICKED_CREATE_PIE_FROM_DROP_DOWN' as const,
  REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS:
    'REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS' as const,
  REMOVE_PORTFOLIO_ORGANIZER_SLICES:
    'REMOVE_PORTFOLIO_ORGANIZER_SLICES' as const,
  TOGGLE_PORTFOLIO_ORGANIZER_HELPER_DIALOGS:
    'TOGGLE_PORTFOLIO_ORGANIZER_HELPER_DIALOGS' as const,
  UPDATE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS:
    'UPDATE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS' as const,
  UPDATE_PORTFOLIO_NAME: 'UPDATE_PORTFOLIO_NAME' as const,
  UPDATE_PORTFOLIO_DESCRIPTION: 'UPDATE_PORTFOLIO_DESCRIPTION' as const,
  INITIALIZE_PIE_ORGANIZER: 'INITIALIZE_PIE_ORGANIZER' as const,
  FETCHING_DATA_IN_PIE_ORGANIZER: 'FETCHING_DATA_IN_PIE_ORGANIZER' as const,
  FETCHED_PIE_DATA_FOR_ORGANIZER_SET_ROOT_PIE:
    'FETCHED_PIE_DATA_FOR_ORGANIZER_SET_ROOT_PIE' as const,
  FETCHED_PIE_DATA_FOR_ORGANIZER: 'FETCHED_PIE_DATA_FOR_ORGANIZER' as const,
  FETCHED_PIE_SLICEABLES: 'FETCHED_PIE_SLICEABLES' as const,
  FETCH_PIE_DATA_FOR_ORGANIZER_FAILED:
    'FETCH_PIE_DATA_FOR_ORGANIZER_FAILED' as const,
  SAVE_PIE_ORGANIZER: 'SAVE_PIE_ORGANIZER' as const,
  SAVED_PIE_ORGANIZER: 'SAVED_PIE_ORGANIZER' as const,
  SAVE_PIE_ORGANIZER_FAILED: 'SAVE_PIE_ORGANIZER_FAILED' as const,
  CONFIRMED_PIE_ORGANIZER_EXIT: 'CONFIRMED_PIE_ORGANIZER_EXIT' as const,
  UPDATE_IS_CRYPTO_PORTFOLIO_ORGANIZER:
    'UPDATE_IS_CRYPTO_PORTFOLIO_ORGANIZER' as const,
};

export type UpdateIsCryptoPortfolioOrganizer = {
  payload: boolean;
  type: typeof PIE_ORGANIZAER_ACTIONS.UPDATE_IS_CRYPTO_PORTFOLIO_ORGANIZER;
};

export type RemovePortfolioOrganizerSelectedSliceIdsAction = {
  payload: {
    ids: Array<string>;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS;
};

export type RemovePortfolioOrganizerSlicesAction = {
  payload: Array<Slice>;
  type: typeof PIE_ORGANIZAER_ACTIONS.REMOVE_PORTFOLIO_ORGANIZER_SLICES;
};

export type BeganPortfolioOrganizerSessionAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.BEGAN_NEW_ROOT_PIE_ORGANIZER_SESSION;
};

export type EndedPortfolioOrganizerSessionAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.ENDED_NEW_ROOT_PIE_ORGANIZER_SESSION;
};

export type UpdatePortfolioNameAction = {
  payload: string;
  type: typeof PIE_ORGANIZAER_ACTIONS.UPDATE_PORTFOLIO_NAME;
};

export type UpdatePortfolioDescriptionAction = {
  payload: string;
  type: typeof PIE_ORGANIZAER_ACTIONS.UPDATE_PORTFOLIO_DESCRIPTION;
};

export type UpdatePortfolioOrganizerSelectedSliceIdsAction = {
  payload: {
    ids: Array<string>;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.UPDATE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS;
};

type SliceableIds = Array<string>;
export type AddSlicesToPieOrganizerAction = {
  payload: {
    legacyIds?: Array<string> | null | undefined;
    sliceableIds: SliceableIds;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.ADD_SLICES_TO_PIE_ORGANIZER;
};

export type AddSlicesToPieOrganizerFailedAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.ADD_SLICES_TO_PIE_ORGANIZER_FAILED;
};

export type InitializePieOrganizerAction = {
  payload: PieOrganizerConfig;
  type: typeof PIE_ORGANIZAER_ACTIONS.INITIALIZE_PIE_ORGANIZER;
};

export type FetchingDataInPieOrganizer = {
  type: typeof PIE_ORGANIZAER_ACTIONS.FETCHING_DATA_IN_PIE_ORGANIZER;
};

export type FetchedPieSliceablesAction = {
  payload: Array<Slice>;
  type: typeof PIE_ORGANIZAER_ACTIONS.FETCHED_PIE_SLICEABLES;
};

export type FetchedPieDataForOrganizerSetRootPieAction = {
  payload: {
    pie: ResponseSliceable;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.FETCHED_PIE_DATA_FOR_ORGANIZER_SET_ROOT_PIE;
};

export type FetchPieDataForOrganizerFailedAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.FETCH_PIE_DATA_FOR_ORGANIZER_FAILED;
};

export type SavePieOrganizerAction = {
  payload: string | null | undefined;
  type: typeof PIE_ORGANIZAER_ACTIONS.SAVE_PIE_ORGANIZER;
};

export type SavedPieOrganizerAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.SAVED_PIE_ORGANIZER;
};

export type SavePieOrganizerFailedAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.SAVE_PIE_ORGANIZER_FAILED;
};

export type ConfirmedPieOrganizerExitAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.CONFIRMED_PIE_ORGANIZER_EXIT;
};

export type CreatePieFromPopupPieOrganizerAction = {
  payload: {
    id: string;
    pieName: string;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.CLICKED_CREATE_PIE_FROM_DROP_DOWN;
};

export type TogglePortfolioOrganizerHelperDialogAction = {
  type: typeof PIE_ORGANIZAER_ACTIONS.TOGGLE_PORTFOLIO_ORGANIZER_HELPER_DIALOGS;
};

export type MovedPortfolioOrganizerSlicesOntoOtherSliceAction = {
  payload: {
    destinationOntoSliceableId: string;
    destinationOntoSliceableName: string | null | undefined;
    movedSliceIds: Array<string>;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE;
};

export type CreatedPortfolioOrganizerNewPieSliceAction = {
  payload: Slice;
  type: typeof PIE_ORGANIZAER_ACTIONS.CREATED_PORTFOLIO_ORGANIZER_NEW_PIE_SLICE;
};

export type ClickedPortfolioOrganizerBreadcrumbAction = {
  payload: Array<string>;
  type: typeof PIE_ORGANIZAER_ACTIONS.CLICKED_PORTFOLIO_ORGANIZER_BREADCRUMB;
};

export type ClickedPortfolioOrganizerPieSliceAction = {
  payload: Pie;
  type: typeof PIE_ORGANIZAER_ACTIONS.CLICKED_PORTFOLIO_ORGANIZER_PIE_SLICE;
};

export type AdjustedPortfolioOrganizerSlicePercentageAction = {
  payload: {
    id: string;
    percentage: number;
  };
  type: typeof PIE_ORGANIZAER_ACTIONS.ADJUSTED_PORTFOLIO_ORGANIZER_SLICE_PERCENTAGE;
};

export type PieOrganizerAction =
  | AdjustedPortfolioOrganizerSlicePercentageAction
  | CreatedPortfolioOrganizerNewPieSliceAction
  | ClickedPortfolioOrganizerPieSliceAction
  | ClickedPortfolioOrganizerBreadcrumbAction
  | RemovePortfolioOrganizerSelectedSliceIdsAction
  | RemovePortfolioOrganizerSlicesAction
  | BeganPortfolioOrganizerSessionAction
  | EndedPortfolioOrganizerSessionAction
  | UpdatePortfolioNameAction
  | UpdatePortfolioDescriptionAction
  | UpdateIsCryptoPortfolioOrganizer
  | UpdatePortfolioOrganizerSelectedSliceIdsAction
  | AddSlicesToPieOrganizerAction
  | AddSlicesToPieOrganizerFailedAction
  | InitializePieOrganizerAction
  | FetchingDataInPieOrganizer
  | FetchedPieSliceablesAction
  | FetchedPieDataForOrganizerSetRootPieAction
  | FetchPieDataForOrganizerFailedAction
  | MovedPortfolioOrganizerSlicesOntoOtherSliceAction
  | SavePieOrganizerAction
  | SavedPieOrganizerAction
  | SavePieOrganizerFailedAction
  | ConfirmedPieOrganizerExitAction
  | CreatePieFromPopupPieOrganizerAction
  | TogglePortfolioOrganizerHelperDialogAction;
