// TODO(Wolf): Try moving this back into the component file after HMR is working.
export const STEPS = {
  START_APPLICATION: 'start',
  PREFILLED_SUMMARY: 'summary',
  FINANCIAL_INFORMATION: 'finances',
  EDIT_FINANCIAL_INFORMATION: 'edit',
  SUBMIT_APPLICATION: 'submit',
  APPLICATION_APPROVED: 'approved',
  APPLICATION_REJECTED: 'submitted',
  APPLICATION_SUBMITTED: 'pending',
  REMOVE_CREDIT_FREEZE: 'frozen',
  OFFER_ACCEPTED: 'account-open',
  UPLOAD_DOCUMENTS: 'upload-documents',
  INITIAL_ERROR_PAGE: 'oops',
  PROCESSING_APPLICATION: 'hang-tight',
  PROCESSING_OPEN_ACCOUNT: 'get-excited',
};
