import { Button, Flex, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useSentry } from '~/hooks/useSentry';
import { isNotNil } from '~/utils';

type Props = {
  content?: React.ReactNode;
  isRetryAllowed?: boolean;
  onClickRetry?: () => void;
  retryButtonLabel?: string;
  title?: string;
};

type State = {
  error: [Error, React.ErrorInfo] | null | undefined;
};

const ErrorState = ({
  title = 'Oh no!',
  content = 'There was a problem. Please try again later. If the problem persists, please contact support.',
  isRetryAllowed = false,
  onClickRetry,
  retryButtonLabel = 'Try again',
}: Props) => {
  const handleRetry = React.useCallback(() => {
    if (typeof onClickRetry === 'function') {
      onClickRetry();
    } else {
      document.location.reload();
    }
  }, [onClickRetry]);

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      gap={32}
      maxWidth={500}
      mx="auto"
      py={32}
      textAlign="center"
    >
      <Illustration width={216} name="warningTriangle" />
      <HXS content={title} />
      <PL content={content} />
      {(isRetryAllowed || typeof onClickRetry === 'function') && (
        <Button kind="primary" onClick={handleRetry} size="large">
          {retryButtonLabel}
        </Button>
      )}
    </Flex>
  );
};

type ErrorWrapperProps = React.PropsWithChildren<Props> & {
  onError: (error: Error, info: React.ErrorInfo) => void;
};

class ErrorWrapper extends React.Component<ErrorWrapperProps, State> {
  state = {
    error: null,
  };

  componentDidCatch(e: Error, info: React.ErrorInfo) {
    this.setState({
      error: [e, info],
    });
    this.props.onError(e, info);
  }

  render() {
    const { error } = this.state;
    const { onError, children, ...props } = this.props;
    return isNotNil(error) ? <ErrorState {...props} /> : children;
  }
}

export const PageErrorBoundary = ({
  children,
  ...props
}: React.PropsWithChildren<Props>) => {
  const sentry = useSentry();
  const handleError = React.useCallback(
    (error: Error, info: React.ErrorInfo) => {
      sentry.exception(error, {
        extra: info,
      });
    },
    [sentry],
  );
  return (
    <ErrorWrapper onError={handleError} {...props}>
      {children}
    </ErrorWrapper>
  );
};
