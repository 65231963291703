import type { AppAction } from '~/redux/actions';

import type {
  BorrowAccountDestinationEnumValues,
  SpendAccountDestinationEnumValues,
} from '../actions/navigationActions';

export type NavigationState = {
  borrowAccountDestination:
    | BorrowAccountDestinationEnumValues
    | null
    | undefined;
  investLinkDestination:
    | '/d/invest'
    | '/d/invest/portfolio-organizer'
    | '/d/invest/invest-marketing';
  spendAccountDestination: SpendAccountDestinationEnumValues | null;
  financialSuitabilityProduct: string | null;
};

const initialState: NavigationState = {
  investLinkDestination: '/d/invest',
  spendAccountDestination: null,
  borrowAccountDestination: null,
  financialSuitabilityProduct: null,
};

export const navigationReducer = (
  state: NavigationState = initialState,
  action: AppAction,
): NavigationState => {
  switch (action.type) {
    case 'BEGAN_NEW_ROOT_PIE_ORGANIZER_SESSION':
      return {
        ...state,
        investLinkDestination: '/d/invest/portfolio-organizer',
      };
    case 'ENDED_INVEST_MARKETING_SESSION':
    case 'ENDED_NEW_ROOT_PIE_ORGANIZER_SESSION':
      return {
        ...state,
        investLinkDestination: '/d/invest',
      };
    case 'SET_INVEST_MARKETING_SESSION':
      return {
        ...state,
        investLinkDestination: '/d/invest/invest-marketing',
      };
    case 'SET_SPEND_ACCOUNT_DESTINATION':
      return {
        ...state,
        spendAccountDestination: action.payload,
      };
    case 'SET_BORROW_ACCOUNT_DESTINATION':
      return {
        ...state,
        borrowAccountDestination: action.payload,
      };
    case 'SET_FINANCIAL_SUITABILITY_PRODUCT':
      return {
        ...state,
        financialSuitabilityProduct: action.payload,
      };
    default:
      return state;
  }
};
