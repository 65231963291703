import * as React from 'react';

import type { CryptoScreenerQueryResultContextValue } from './types';

export const CryptoScreenerQueryResultContext = React.createContext(
  {} as CryptoScreenerQueryResultContextValue,
);

export const useCryptoScreenerResult = () => {
  const queryResult = React.useContext(CryptoScreenerQueryResultContext);
  if (!queryResult) {
    throw new Error(
      'useCryptoScreenerResult must be used within a CryptoScreenerProvider',
    );
  }
  return queryResult;
};
