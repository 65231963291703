import without from 'lodash-es/without';

import { type AppAction, ACTION_TYPES } from '~/redux/actions';

export type WatchlistState = ReadonlyArray<string>;

export const initialState: WatchlistState = [];

export const watchlistReducer = (
  state: WatchlistState = initialState,
  action: AppAction,
): WatchlistState => {
  switch (action.type) {
    case 'LOADED_WATCHLIST_FROM_SERVER':
      return action.payload;
    case ACTION_TYPES.ADD_TO_WATCHLIST:
      return [...state, action.payload.securityId];
    case ACTION_TYPES.REMOVE_FROM_WATCHLIST:
      return without(state, action.payload.securityId);
    default:
      return state;
  }
};
