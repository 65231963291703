import * as React from 'react';
import {
  type BaseFieldProps,
  Field,
  type WrappedFieldMetaProps,
  type WrappedFieldProps,
} from 'redux-form';

import { AmountInput, type AmountInputProps } from '~/components/amount-input';
import { type Enhancer, withProps } from '~/hocs';

export type AmountFieldProps = WrappedFieldProps &
  Omit<AmountInputProps, 'error'> & {
    showErrorBeforeSubmit: boolean;
    isFocused: boolean;
    error?: string | null;
  };

const renderInput = ({
  input,
  meta,
  showErrorBeforeSubmit = false,
  isFocused = false,
  error = null,
  ...rest
}: AmountFieldProps) => {
  return React.createElement(AmountInput, {
    ...rest,
    ...meta,
    ...input,
    error: error ?? readError(meta, showErrorBeforeSubmit, isFocused),
  });
};

const readError = (
  meta: WrappedFieldMetaProps,
  showErrorBeforeSubmit: boolean,
  isFocused: boolean,
) => {
  if (
    (meta.submitFailed || showErrorBeforeSubmit) &&
    meta.dirty &&
    !isFocused
  ) {
    return meta.error;
  }

  return null;
};

const enhancer: Enhancer<BaseFieldProps, any> = withProps({
  component: renderInput,
});

export const AmountField = enhancer(Field) as any;
