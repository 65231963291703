import * as React from 'react';

import type { SliceableLogoFragment } from '~/graphql/types';

import { AssetLogo } from './AssetLogo';
import { SystemPieLogo } from './SystemPieLogo';
import { UserPieLogo } from './UserPieLogo';

export const SliceableLogo = ({
  sliceable,
}: {
  sliceable: SliceableLogoFragment;
}) => {
  const { __typename } = sliceable;

  if (__typename === 'SystemPie') {
    return (
      <SystemPieLogo
        name={sliceable.name}
        logoUrl={sliceable.logoUrl}
        categorizationDetails={sliceable.categorizationDetails}
      />
    );
  }

  if (__typename === 'UserPie') {
    return <UserPieLogo portfolioLinks={sliceable.portfolioLinks} />;
  }

  if (__typename === 'SharedPie') {
    return <UserPieLogo />;
  }

  if (__typename === 'CryptoSystemPie') {
    return null;
  }

  return <AssetLogo symbol={sliceable.symbol} profile={sliceable.profile} />;
};
