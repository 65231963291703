import { Box } from '@m1/liquid-react';
import React from 'react';

import type { HistoricalQuote } from '~/graphql/types';
import { formatCurrency } from '~/utils/formatting';

type DividendsProps = {
  dividends: Pick<HistoricalQuote['dividends'][number], 'amount' | 'type'>[];
};

export const Dividends = ({ dividends }: DividendsProps) => {
  return (
    <Box>
      <Box mt={5}>
        <b>Dividends</b>
      </Box>
      {dividends.map((dividend, index) => (
        <Box key={index}>
          {dividend.type === 'STOCK_DIVIDEND'
            ? `${dividend.amount} shares`
            : formatCurrency(dividend.amount)}
        </Box>
      ))}
    </Box>
  );
};
