import { spacingUnits, styled, Card, Box } from '@m1/liquid-react';

export const StyledPaymentCard = styled(Card)`
  width: 100%;
  padding: ${spacingUnits.l}px;
`;

export const PaymentContainer = styled(Box)`
  margin: 96px auto 64px;
  max-width: 500px;
`;
