import * as React from 'react';

import { IncomeForm } from '~/forms';
import { submittedAnnualIncome } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export const CollectAnnualIncomeStep = () => {
  const dispatch = useDispatch();

  const cachedAnnualIncomeAmount = useSelector<string | null>((state) => {
    return state.newFlows.IDENTITY_FIRST.input.suitability?.annualIncomeAmount;
  });

  return (
    <IncomeForm
      // @ts-expect-error - TS7031 - Binding element 'annualIncomeAmount' implicitly has an 'any' type.
      onSubmit={({ annualIncomeAmount }) => {
        dispatch(
          submittedAnnualIncome({
            field: 'suitability.annualIncomeAmount',
            value: annualIncomeAmount,
          }),
        );
      }}
      initialValues={{
        annualIncomeAmount: cachedAnnualIncomeAmount,
      }}
    />
  );
};
