import type { ProfileInput } from '~/graphql/types';

export const USER_DATA_ACTIONS = Object.freeze({
  LOAD_USER_DATA_FROM_SERVER: 'LOAD_USER_DATA_FROM_SERVER' as const,
  LOADED_USER_DATA_FROM_SERVER: 'LOADED_USER_DATA_FROM_SERVER' as const,
  UPDATE_CLIENT_TOKEN: 'UPDATE_CLIENT_TOKEN' as const,
  UPDATE_PROFILE: 'UPDATE_PROFILE' as const,
  UPDATE_USER_DATA: 'UPDATE_USER_DATA' as const,
});

export type PartialProfileInput = {
  suitability?: Partial<ProfileInput['suitability']>;
  primary?: Partial<ProfileInput['primary']>;
  secondary?: Partial<ProfileInput['secondary']>;
  beneficiaries?: Partial<ProfileInput['beneficiaries']>;
  allowsHomeAddressUpdates?: Partial<ProfileInput['beneficiaries']>;
  trustedContact?: Partial<ProfileInput['trustedContact']>;
};

export type UpdateProfileAction = {
  payload: {
    analytics?: {
      name: string;
      event: string;
    };
    successMessage?: string;
    onSuccess?: () => void;
    profile: PartialProfileInput;
  };
  type: typeof USER_DATA_ACTIONS.UPDATE_PROFILE;
};

export type UpdateClientTokenAction = {
  payload: {
    clientToken: string | null | undefined;
  };
  type: typeof USER_DATA_ACTIONS.UPDATE_CLIENT_TOKEN;
};

export type LoadedUserDataFromServer = {
  payload: ReadonlyArray<{
    key: string;
    value: string | null | undefined;
  }>;
  type: typeof USER_DATA_ACTIONS.LOADED_USER_DATA_FROM_SERVER;
};

export type UpdateUserDataAction = {
  payload: {
    key: string;
    value?: string | null | undefined;
  };
  type: typeof USER_DATA_ACTIONS.UPDATE_USER_DATA;
};

export const updateProfile = (
  payload: UpdateProfileAction['payload'],
): UpdateProfileAction => ({
  type: USER_DATA_ACTIONS.UPDATE_PROFILE,
  payload,
});

export const updateClientToken = (
  payload: UpdateClientTokenAction['payload'],
): UpdateClientTokenAction => ({
  type: USER_DATA_ACTIONS.UPDATE_CLIENT_TOKEN,
  payload: payload,
});

export const updateUserData = (
  payload: UpdateUserDataAction['payload'],
): UpdateUserDataAction => ({
  type: USER_DATA_ACTIONS.UPDATE_USER_DATA,
  payload,
});

export const loadedUserDataFromServer = (
  payload: LoadedUserDataFromServer['payload'],
): LoadedUserDataFromServer => ({
  type: USER_DATA_ACTIONS.LOADED_USER_DATA_FROM_SERVER,
  payload: payload,
});

export type UserDataAction =
  | LoadedUserDataFromServer
  | UpdateProfileAction
  | UpdateClientTokenAction
  | UpdateUserDataAction;
