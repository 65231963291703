import { Flex, HM, HXXS, Box } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { useSavingsAccountInvitationPageQuery } from '~/graphql/hooks';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import type { AppState } from '~/redux';
import { useDispatch, useSelector } from '~/redux/hooks';
import { normalizePhoneNumber } from '~/utils';

import { SavingsAccountInvitationForm } from '../components/SavingsAccountInvitationForm';

type SavingsAccountInvitationPageProps = {
  onFinishStep: () => void;
};

export const SavingsAccountInvitationPage = ({
  onFinishStep,
}: SavingsAccountInvitationPageProps) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(
    (state: AppState) => state.newFlows.SAVINGS_ONBOARDING.coOwner,
  );
  const { loading, data } = useSavingsAccountInvitationPageQuery({});
  const accountInvitationPage =
    data?.viewer.save?.savings?.onboarding?.accountInvitationPage;
  const primaryUserEmail = data?.viewer.user?.username;
  const primaryUserPhoneNumber = data?.viewer.profile?.primary.phoneNumber;

  usePortaledSpinner(loading);

  return (
    <Flex
      alignItems="baseline"
      flexDirection="column"
      justifyContent="center"
      maxWidth={588}
      mx="auto"
    >
      <Box my={32}>
        <BackArrow content="Back" onClick={() => window.history.back()} />
      </Box>
      <HM mb={16} content={accountInvitationPage?.title} />
      <HXXS
        mb={48}
        fontWeight={300}
        content={accountInvitationPage?.subtitle}
      />
      <SavingsAccountInvitationForm
        primaryCtaButton={accountInvitationPage?.primaryCtaButton}
        initialValues={initialValues}
        primaryUserEmail={primaryUserEmail}
        primaryUserPhoneNumber={primaryUserPhoneNumber}
        onSubmit={(formValues) => {
          const { firstName, lastName, phone, email } = formValues;
          dispatch({
            type: 'SET_JOINT_ACCOUNT_INVITEE',
            payload: {
              firstName,
              lastName,
              phone: normalizePhoneNumber(phone),
              email,
            },
          });
          onFinishStep();
        }}
      />
    </Flex>
  );
};
