import { Button, Flex, PL, styled } from '@m1/liquid-react';
import * as React from 'react';
import { type InjectedFormProps, FormSection, formValues } from 'redux-form';

import { compose, connectForm, withProps } from '~/hocs';

import { TrustedContactFields } from './fields';

type TrustedContactFormProps = InjectedFormProps<any> & {
  onSkip?: () => void;
};

const SubmitButton = compose<any, any>(
  // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
  formValues('trustedContact', 'firstName'),
  // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
  formValues('trustedContact', 'lastName'),
  // @ts-expect-error - TS2345 - Argument of type '({ firstName, lastName }: { firstName: any; lastName: any; }) => { disabled: boolean; }' is not assignable to parameter of type '{ disabled: boolean; } | mapper<unknown, { disabled: boolean; }>'.
  withProps(({ firstName, lastName }) => ({
    disabled: !firstName || !lastName,
  })),
)(Button);

const StyledSkip = styled(PL)`
  cursor: pointer;
`;

const CollectTrustedContactFormComponent = (props: TrustedContactFormProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      {/* @ts-expect-error - TS2769 - No overload matches this call. | TS2786 - 'FormSection' cannot be used as a JSX component. */}
      <FormSection>
        <TrustedContactFields showLongInputs />
      </FormSection>
      <Flex mt={32} alignItems="center" mx="auto">
        <SubmitButton
          kind="primary"
          type="submit"
          size="large"
          label="Add trusted contact"
        />
        {props.onSkip && (
          <StyledSkip
            onClick={props.onSkip}
            color="primary"
            content="Skip"
            fontWeight={600}
            pl={32}
          />
        )}
      </Flex>
    </form>
  );
};

export const CollectTrustedContactForm = connectForm({
  form: 'trusted-contact',
})(CollectTrustedContactFormComponent);
