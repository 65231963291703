import type {
  InvestmentExperienceEnum,
  LiquidityNeedsEnum,
  RiskToleranceEnum,
  TimeHorizonEnum,
} from '~/graphql/types';

import type { EnumDropdown } from './types';

export const InvestmentExperienceEnumDropdownOptions: EnumDropdown<InvestmentExperienceEnum> =
  [
    {
      'name': 'NONE',
      'description': 'None',
    },
    {
      'name': 'LIMITED',
      'description': 'Limited',
    },
    {
      'name': 'GOOD',
      'description': 'Good',
    },
    {
      'name': 'EXTENSIVE',
      'description': 'Extensive',
    },
  ];
export const RiskToleranceEnumDropdownOptions: EnumDropdown<RiskToleranceEnum> =
  [
    {
      'name': 'LOW',
      'description': 'Low',
    },
    {
      'name': 'MEDIUM',
      'description': 'Medium',
    },
    {
      'name': 'HIGH',
      'description': 'High',
    },
  ];
export const TimeHorizonEnumDropdownOptions: EnumDropdown<TimeHorizonEnum> = [
  {
    'name': 'SHORT',
    'description': 'Short (Less than 3 years)',
  },
  {
    'name': 'AVERAGE',
    'description': 'Average (4 to 7 years)',
  },
  {
    'name': 'LONGEST',
    'description': 'Long (8 years or more)',
  },
];
export const LiquidityNeedsEnumDropdownOptions: EnumDropdown<LiquidityNeedsEnum> =
  [
    {
      'name': 'VERY_IMPORTANT',
      'description': 'Very Important',
    },
    {
      'name': 'SOMEWHAT_IMPORTANT',
      'description': 'Somewhat Important',
    },
    {
      'name': 'NOT_IMPORTANT',
      'description': 'Not Important',
    },
  ];
