import { PL, PM, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { printableAsciiChars } from '~/forms/validators';
import { usePersonalLoansDirectQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { BackButton } from '../BackButton';
import { SSN_MAX_LENGTH } from '../constants';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';
import { isValidSsn } from '../utils/isValidSsn';

import { StyledHeader } from './StyledHeader';

export interface DirectToPersonalLoansSSNFormValues {
  socialSecurityNumber: string;
}

type SocialSecurityNumberFormProps = {
  onSubmit: SubmitHandler<DirectToPersonalLoansSSNFormValues>;
};

export const SocialSecurityNumberForm = ({
  onSubmit,
}: SocialSecurityNumberFormProps) => {
  const { back } = usePersonalLoanDirectWizardContext();

  const { data: questionsData, loading: questionsLoading } =
    usePersonalLoansDirectQuery();

  const { control, setValue, handleSubmit } =
    useForm<DirectToPersonalLoansSSNFormValues>();

  const questions =
    questionsData?.viewer?.borrow?.personalLoans?.applicationFlowDirect
      ?.questions;
  let props;
  if (questions) {
    props = getQuestionProps({
      question: 'SOCIAL_SECURITY_NUMBER',
      questions,
    });
  }

  const { continueLabel, title, subtitle, footer } = props || {};

  if (questionsLoading) {
    return <Spinner fullScreen centered />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton onBackClick={() => back()} />
      <StyledHeader
        content={
          title ??
          'Help us protect your account and identity with your Social Security number.'
        }
      />
      <PL
        content={
          subtitle ??
          'We won’t run a hard credit check unless you decide to accept one of your loan offers.'
        }
        my={8}
      />
      <ControlledInput
        name="socialSecurityNumber"
        backgroundColor="backgroundNeutralSecondary"
        control={control}
        placeholder="XXX-XX-XXXX"
        autoFocus
        label="Social Security"
        maskType="ssn"
        rules={{
          required: 'Required',
          validate: {
            printableAsciiChars: (v) =>
              typeof v === 'string' && printableAsciiChars(v),
            ssn: (v) => isValidSsn(v),
          },
        }}
        maxLength={SSN_MAX_LENGTH}
      />
      <Flex alignItems="center" mt={8}>
        <Icon
          name="lock20"
          style={{
            ariaHidden: true,
          }}
        />
        <PM
          ml={8}
          content={
            footer ??
            'We protect your personal information using security and encryption best practices.'
          }
          color="foregroundNeutralSecondary"
        />
      </Flex>
      <ResponsiveButton
        mt={48}
        label={continueLabel ?? 'See my offers'}
        type="submit"
        size="large"
      />
      <FormMockerReactHookForm
        setValue={setValue}
        fields={[
          {
            name: 'socialSecurityNumber',
            value: '666-00-9258',
          },
        ]}
      />
    </form>
  );
};
