import { useForm } from 'react-hook-form';

import type { OnlineAccountRegistrationEnum } from '~/graphql/types';

export type IRAWizardFormFields = {
  accountType: Extract<
    OnlineAccountRegistrationEnum,
    'ROTH_IRA' | 'SEP_IRA' | 'TRADITIONAL_IRA'
  > | null;
  isRolloverOrTransfer: boolean;
};

export const useIRAWizardForm = () => {
  return useForm<IRAWizardFormFields>({
    mode: 'onChange',
    defaultValues: {
      accountType: null,
      isRolloverOrTransfer: false,
    },
  });
};
