import * as React from 'react';

import { useScreenerState } from '../../useScreenerState';

import { defaultLimits, INITIAL_FUND_SCREENER_VARIABLES } from './constants';
import { FundScreenerQueryResultContext } from './useFundScreenerResult';

type FundScreenerProviderProps = {
  children: React.ReactNode;
};

export const FundScreenerProvider = ({
  children,
}: FundScreenerProviderProps) => {
  const screenerState = useScreenerState({
    screenerType: 'FUND',
    defaultVariables: INITIAL_FUND_SCREENER_VARIABLES,
    defaultLimits: defaultLimits,
  });

  return (
    <FundScreenerQueryResultContext.Provider value={screenerState}>
      {children}
    </FundScreenerQueryResultContext.Provider>
  );
};
