import { Box, Button, Flex, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { connectForm } from '~/hocs';
import type { DropdownOption } from '~/toolbox/Dropdown';
import { normalizeStringAsNumber, onlyNumbers } from '~/utils/normalizers';

import { DropdownField, TextField } from './fields';
import { required, requireNonNil } from './validators';

export type FinancialInformationFormProps = InjectedFormProps<any> & {
  isEditing: boolean;
  onFinishStep: () => void;
};

const housingOptions: DropdownOption[] = [
  {
    value: 'Own',
    label: 'Own',
  },
  {
    value: 'Rent',
    label: 'Rent',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const FinancialInformationFormComponent = ({
  onFinishStep,
  handleSubmit,
  isEditing,
}: FinancialInformationFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <Flex
        alignItems="center"
        flexDirection="column"
        alignContent="center"
        pt={64}
      >
        <Box width={750}>
          <HXS content="Financial information" />
          {!isEditing && (
            <PL mt={16}>Enter your income and housing information.</PL>
          )}
          <Box width={320} mt={32}>
            <PL fontWeight={600} content="Income" />
            <TextField
              name="totalAnnualIncome"
              label="Total annual income"
              maskType="money"
              normalize={normalizeStringAsNumber}
              validate={[required, requireNonNil]}
              maxLength={11}
            />
          </Box>
          <Box mt={16}>
            <PL fontWeight={600} content="Housing" />
            <Flex>
              <Box width={320}>
                <DropdownField
                  name="housingType"
                  label="Rent or own"
                  options={housingOptions}
                  validate={[required, requireNonNil]}
                />
              </Box>
              <Box width={340} ml={16}>
                <TextField
                  maskType="money"
                  name="monthlyPayments"
                  label="Monthly payment"
                  normalize={onlyNumbers}
                  validate={[required]}
                  maxLength={11}
                />
              </Box>
            </Flex>
          </Box>
          <div
            style={{
              marginTop: 82,
            }}
          >
            {isEditing && (
              <Button
                kind="secondary"
                size="large"
                label="Back to Summary"
                mr={16}
                onClick={onFinishStep}
              />
            )}
            <Button
              kind="primary"
              size="large"
              label={isEditing ? 'Save' : 'Next: Summary'}
              type="submit"
            />
          </div>
        </Box>
      </Flex>
    </form>
  );
};

export const FinancialInformationForm = connectForm({
  form: 'financial-information',
})(FinancialInformationFormComponent);
