import { FloatingPanel, ListItem } from '@m1/liquid-react';
import type { MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useSelector } from '~/redux/hooks';

import { CondensableButton } from './PieEditor.styled';
import type {
  IndividualHistoricalTree,
  TreeHistoryType,
} from './PieEditor.types';

export const PieEditorUndoHistory = ({
  onIndexChange,
}: {
  onIndexChange: (change: number, isReset?: boolean) => void;
}) => {
  const { history, historyIndex } = useSelector((state) => state.pieEditor);
  const historyLength = history.length;

  const historyList = React.useMemo(() => {
    return history
      .map((tree, i) => (
        <ListItem
          key={`${tree.type}_${crypto.randomUUID()}`}
          {...{
            header: treeHistoryTypeToLabel(tree),
            pill:
              i === historyIndex
                ? {
                    kind: 'important',
                    label: 'Current',
                  }
                : undefined,
            onClick: () => {
              onIndexChange(i - historyIndex);
            },
            icon: {
              name: treeHistoryTypeToIcon(tree.type),
            },
            size: 'MEDIUM',
            selectable: true,
            padding: '16px',
            withoutArrow: true,
          }}
        />
      ))
      .reverse();
  }, [history, historyIndex, onIndexChange]);

  return (
    <FloatingPanel
      controlled
      active={false}
      placement="bottom-end"
      content={
        <ul
          style={{
            marginTop: -16,
            maxHeight: '50vh',
            overflowY: 'scroll',
          }}
        >
          {historyList}
        </ul>
      }
      maxWidth={400}
      trigger={
        <CondensableButton
          // @ts-ignore title works but not typed
          title="History"
          kind="text"
          leftIcon="history20"
          label=""
          size="medium"
          disabled={historyLength <= 1}
          condensed
        />
      }
    />
  );
};

const treeHistoryTypeToIcon = (
  type: TreeHistoryType,
): MonochromaticIconName<'24'> => {
  switch (type) {
    case 'init':
      return 'rootPies24';
    case 'add':
      return 'createPie24';
    case 'remove':
      return 'minusBubble24';
    case 'rename':
      return 'edit24';
    case 'percentage':
      return 'percentage24';
    case 'equalize':
      return 'rebalance24';
    case 'move':
      return 'movePie24';
    case 'expand':
    case 'expand_all':
      return 'expandMenu24';
    case 'collapse':
    case 'collapse_all':
      return 'collapseMenu24';
    case 'convert':
      return 'switch24';
    default:
      return 'edit24';
  }
};

const treeHistoryTypeToLabel = ({
  type,
  meta,
}: IndividualHistoricalTree): string => {
  switch (type) {
    case 'init':
      return 'Initialized root pie';
    case 'add':
      return `Added ${meta?.slice ?? 'slice'} to ${meta?.pie ?? 'pie'}`;
    case 'remove':
      return `Removed ${meta?.slice ?? 'slice'} from ${meta?.pie ?? 'pie'}`;
    case 'rename':
      return `Renamed ${meta?.old ?? 'slice'} to ${meta?.new ?? 'something else'}`;
    case 'percentage': {
      if (typeof meta?.fromPercentage !== 'number') {
        return `Changed percentage of ${meta?.slice ?? 'slice'}${typeof meta.percentage === 'number' ? ` to ${meta.percentage}%` : ''}`;
      }
      const increase = meta.percentage > meta.fromPercentage;
      return `${increase ? 'Increased' : 'Decreased'} percentage of ${meta?.slice ?? 'slice'} from ${meta.fromPercentage}% to ${meta.percentage}%`;
    }
    case 'equalize':
      return `Equalized ${meta?.pie ?? 'pie'}`;
    case 'move': {
      if (meta?.isMerged) {
        return `Merged ${meta?.slice ?? 'slice'} from ${meta?.oldPie ?? 'one pie'} into ${meta?.newPie ?? 'another'}`;
      } else if (meta?.isReorder) {
        return `Reordered ${meta?.slice ?? 'slice'} in ${meta?.newPie ?? 'a pie'}`;
      }
      return `Moved ${meta?.slice ?? 'slice'} from ${meta?.oldPie ?? 'one pie'} to ${meta?.newPie ?? 'another'}`;
    }
    case 'expand':
      return `Expanded ${meta?.pie ?? 'pie'}`;
    case 'expand_all':
      return 'Expanded all pies';
    case 'collapse':
      return `Collapsed ${meta?.pie ?? 'pie'}`;
    case 'collapse_all':
      return 'Collapsed all pies';
    case 'convert':
      return `Converted ${meta?.pie ?? 'pie'} to a normal pie.`;
    default:
      return 'Edited pie';
  }
};
