import type { LayoutableProps } from '@m1/liquid-react';
import * as React from 'react';

import { FulfillmentConditionInlineDescriptionTags } from '~/components/FulfillmentConditionInlineDescriptionTags';
import { useSelector } from '~/redux/hooks';

export type ActiveContraParticipantEntryTagsProps = LayoutableProps;

export const ActiveContraParticipantEntryTags = ({
  ...rest
}: ActiveContraParticipantEntryTagsProps) => {
  // TODO: Make this efficient.
  const entry = useSelector(
    (state) =>
      state.newFlows.CREATE_SMART_TRANSFER.activeContraParticipantEntry,
  );
  if (!entry) {
    return null;
  }
  return (
    <FulfillmentConditionInlineDescriptionTags
      {...rest}
      {...entry.fulfillmentCondition}
    />
  );
};
