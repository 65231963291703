import React from 'react';

import type { DividendTablePositionFragment } from '~/graphql/types';
import { useSortTable } from '~/hooks/useSortTable';
import { GridTable } from '~/toolbox/grid-table';
import { parseCurrency } from '~/utils/parseCurrency';

import { DividendHoldingsTableRows } from './DividendHoldingsTableRows';

type DividendHoldingsTableProps = {
  holdings?: DividendTablePositionFragment[];
};

export const DividendHoldingsTable = ({
  holdings,
}: DividendHoldingsTableProps) => {
  const list = React.useMemo(() => holdings ?? [], [holdings]);
  const { createHeaderSortProps, sortedList } = useSortTable({
    list,
    defaults: {
      path: 'dividends.lastTwelveMonthsPaid',
      direction: 'DESC',
      getValue: (holding) => {
        return parseCurrency(holding.dividends?.lastTwelveMonthsPaid) ?? 0;
      },
    },
  });

  return (
    <GridTable
      emptyMessage="All holdings will be listed here, including those that don't pay dividends."
      gridTemplateColumns="minmax(200px, 4fr) 160px repeat(3, 130px) 140px"
      css={{ padding: 0, margin: 0 }}
      isTableEmpty={sortedList.length === 0}
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell
          {...{
            label: 'Name',
            ...createHeaderSortProps({
              path: 'positionSecurity.security.symbol',
            }),
          }}
        />
        <GridTable.HeaderCell
          {...{
            justifySelf: 'right',
            label: 'Last 12 months paid',
            ...createHeaderSortProps({
              path: 'dividends.lastTwelveMonthsPaid',
              getValue: (holding) =>
                parseCurrency(holding.dividends?.lastTwelveMonthsPaid) ?? 0,
            }),
          }}
        />
        <GridTable.HeaderCell
          {...{
            justifySelf: 'right',
            label: 'Dividend yield',
            ...createHeaderSortProps({ path: 'dividends.dividendYield' }),
          }}
        />
        <GridTable.HeaderCell
          {...{
            justifySelf: 'right',
            label: 'Yield on cost',
            ...createHeaderSortProps({ path: 'dividends.yieldOnCost' }),
          }}
        />
        <GridTable.HeaderCell
          {...{
            justifySelf: 'right',
            label: 'Average cost',
            ...createHeaderSortProps({
              path: 'dividends.averageCost',
              getValue: (holding) =>
                parseCurrency(holding.dividends?.averageCost) ?? 0,
            }),
          }}
        />
        <GridTable.HeaderCell
          {...{
            justifySelf: 'right',
            label: 'Payout frequency',
            style: { paddingRight: 0 },
          }}
        />
      </GridTable.HeaderRow>
      <DividendHoldingsTableRows positions={sortedList} />
    </GridTable>
  );
};
