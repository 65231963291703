import {
  PM,
  Box,
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@m1/liquid-react';
import * as React from 'react';

import { MyPiesPageDocument, useArchivePieMutation } from '~/graphql/hooks';
import type { ArchivePieInput } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

type ArchivePieModalProps = {
  pieArchiveMutationData: Pick<ArchivePieInput, 'isCrypto' | 'pieId'> & {
    pieName?: string | null | undefined;
  };
  setShowDeleteModal: (arg0: boolean) => void;
  showDeleteModal: boolean;
};

export const ArchivePieModal = ({
  pieArchiveMutationData,
  showDeleteModal,
  setShowDeleteModal,
}: ArchivePieModalProps) => {
  const { addToast } = useToast();

  const [archivePie, { loading }] = useArchivePieMutation();
  usePortaledSpinner(loading);

  const closeModal = () => setShowDeleteModal(false);

  const onConfirm = () => {
    if (!pieArchiveMutationData) {
      throw new Error(
        'Unable to perform mutation due to invalid, pieArchiveMutationData',
      );
    }

    const { isCrypto, pieId } = pieArchiveMutationData;

    archivePie({
      variables: {
        input: { isCrypto, pieId },
      },
      onCompleted: (d) => {
        if (d.archivePie?.didSucceed) {
          addToast({
            content: 'Success! Your Pie was successfully deleted.',
            kind: 'success',
            duration: 'short',
          });
        } else {
          addToast({
            content:
              'Something went wrong with your request. Please try again later or contact support.',
            kind: 'alert',
            duration: 'short',
          });
        }
      },
      onError: () => {
        addToast({
          content:
            'Something went wrong with your request. Please try again later or contact support.',
          kind: 'alert',
          duration: 'short',
        });
      },
      refetchQueries: [{ query: MyPiesPageDocument }],
    });
    closeModal();
  };

  return (
    <Modal open={showDeleteModal} onClose={closeModal}>
      <ModalContent width="narrow">
        <ModalTitle>Confirm Delete</ModalTitle>
        <Box pb={24}>
          {pieArchiveMutationData?.pieName ? (
            <PM>
              Are you sure you want to delete{' '}
              <b>{pieArchiveMutationData.pieName}</b>?
            </PM>
          ) : (
            <PM content="Are you sure you want to delete this Pie?" />
          )}
        </Box>
        <ButtonGroup>
          <Button
            label="Cancel"
            kind="secondary"
            size="medium"
            onClick={closeModal}
          />
          <Button
            label="Confirm"
            kind="primary"
            size="medium"
            onClick={onConfirm}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
