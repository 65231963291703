import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

export const InvalidPaymentsAccountBanner = () => {
  return (
    <Container>
      <Banner
        action={<Link to="/d/c/select-payment-account">Select Account</Link>}
        content="You have money waiting! Let us know where we should send your credits."
        kind="information"
      />
    </Container>
  );
};
