import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { AnnouncementContext } from '~/components/announcement/AnnouncementContext';
import { AnnouncementNudgeContent } from '~/components/announcement/AnnouncementNudge/StyledAnnouncementNudge';
import { useHomeAnnouncementsQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';

const HomeAnnouncementsContainer = styled(Box)<{ $contentWidth: number }>`
  // Making the media queries and padding match the rest of the Home page
  ${AnnouncementNudgeContent} {
    max-width: ${({ $contentWidth }) => `${$contentWidth}px`};
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
      padding: 24px 48px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
      padding: 24px 24px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
      padding: 24px 16px;
    }
  }
`;

export const HomeAnnouncements = () => {
  const { data } = useHomeAnnouncementsQuery();
  const { contentWidth } = useLayout();
  const announcements = data?.viewer?.announcements;
  const homeAnnouncement = announcements?.forHome;
  const homeModalAnnouncement = announcements?.forHomeModal;

  const homeAnnouncementIsBanner =
    homeAnnouncement?.displayPreference === 'BANNER';
  const homeModalAnnouncementIsModal =
    homeModalAnnouncement?.displayPreference === 'MODAL';

  if (!announcements || (!homeAnnouncement && !homeModalAnnouncement)) {
    return null;
  }

  // HOME context announcements should not be Modals, but doing a check here just in case of user error
  // HOME_MODAL context announcements should always be Modals, but doing a check here just in case of user error
  return (
    <HomeAnnouncementsContainer $contentWidth={contentWidth}>
      {homeAnnouncement && homeAnnouncement?.displayPreference !== 'MODAL' && (
        <Box mt={homeAnnouncementIsBanner ? 30 : 0}>
          <AnnouncementContext announcement={homeAnnouncement} context="HOME" />
        </Box>
      )}
      {homeModalAnnouncement && homeModalAnnouncementIsModal && (
        <AnnouncementContext
          announcement={homeModalAnnouncement}
          context="HOME_MODAL"
        />
      )}
    </HomeAnnouncementsContainer>
  );
};
