import { Button, HXS, PL, Flex, Box } from '@m1/liquid-react';
import * as React from 'react';

import { formatCurrency } from '~/utils/formatting';

export const PersonalInformation = ({
  dateOfBirth,
  fullName,
  ssnLastFour,
}: {
  dateOfBirth: string;
  fullName: string;
  ssnLastFour?: string;
}) => (
  <>
    <HXS mb={10} mt={32} content="Personal information" />
    <PL content="Full name" fontWeight={600} />
    <PL content={fullName} mb={16} />
    <PL content="Date of birth" fontWeight={600} />
    <PL content={dateOfBirth} mb={16} />
    {ssnLastFour && (
      <>
        <PL content="Social security number" fontWeight={600} />
        <PL content={ssnLastFour} />
      </>
    )}
  </>
);

export const FinancialInformation = ({
  totalAnnualIncome,
  monthlyPayments,
  housingType,
  onFinishStep,
}: {
  housingType: string;
  monthlyPayments: number;
  onFinishStep: (isEditing: string) => void;
  totalAnnualIncome: number;
}) => (
  <Box>
    <Flex justifyContent="space-between" alignItems="flex-start">
      <Box>
        <HXS mb={10} content="Financial information" />
        <PL content="Total annual income" fontWeight={600} />
        <PL content={formatCurrency(totalAnnualIncome, 'SELECTIVE') ?? '$--'} />
      </Box>
      <Box>
        <Button kind="link" onClick={() => onFinishStep('edit')}>
          Edit
        </Button>
      </Box>
    </Flex>
    <Box mt={16}>
      <PL content="Housing type" fontWeight={600} />
      <PL content={housingType} />
    </Box>
    <Box mt={16}>
      <PL content="Monthly payments" fontWeight={600} />
      <PL content={formatCurrency(monthlyPayments, 'SELECTIVE') ?? '$--'} />
    </Box>
  </Box>
);

export const ContactInformation = ({
  email,
  phoneNumber,
}: {
  email?: string;
  phoneNumber: string;
}) => (
  <>
    <HXS mb={10} content="Contact information" />
    <PL content="Email" fontWeight={600} />
    <PL content={email} mb={16} />
    <PL content="Phone number" fontWeight={600} />
    <PL content={phoneNumber} />
  </>
);

export const ResidentialInformation = ({
  legalResidence,
}: {
  legalResidence: string;
}) => (
  <>
    <HXS mb={10} content="Residential information" />
    <PL content="Legal residence" fontWeight={600} />
    <PL content={legalResidence} />
  </>
);
