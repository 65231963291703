import { Box, Input } from '@m1/liquid-react';
import * as React from 'react';

import { useAmountRangeValidation } from '~/hooks/useAmountRangeValidation';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import { DEFAULT_FULFILLMENT_CONDITION_FIELDS } from './constants';

type RetirementFundingCapProps = {
  fulfillmentConditionRequirements: any | null | undefined;
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onIsContinueAllowedChange: (arg0: boolean) => void;
  preSelectedFulfillmentConditionDataOfActiveContra:
    | SmartTransferFulfillmentConditionInput
    | null
    | undefined;
};

export const RetirementFundingCap = ({
  fulfillmentConditionRequirements,
  onFulfillmentDataChange,
  onIsContinueAllowedChange,
  preSelectedFulfillmentConditionDataOfActiveContra,
}: RetirementFundingCapProps) => {
  const defaultAmountValue =
    typeof preSelectedFulfillmentConditionDataOfActiveContra?.fundingAmount ===
    'number'
      ? `${preSelectedFulfillmentConditionDataOfActiveContra.fundingAmount}`
      : '';
  const [amountValueInitial, setAmountValue] =
    React.useState(defaultAmountValue);
  const { onInputChange, amountErrorMessage, amountValue } =
    // @ts-expect-error - TS2554 - Expected 5-7 arguments, but got 4.
    useAmountRangeValidation(
      amountValueInitial,
      setAmountValue,
      fulfillmentConditionRequirements?.minFundingAmount,
      fulfillmentConditionRequirements?.maxFundingAmount,
    );
  const fulfillmentConditionType =
    fulfillmentConditionRequirements?.fulfillmentConditionType;
  // fire parent callback to sync continue state
  React.useEffect(() => {
    const isContinueAllowed = Boolean(
      fulfillmentConditionType === 'IRA_YEARLY_FUNDING' ||
        (amountValue !== '' && !amountErrorMessage),
    );
    onIsContinueAllowedChange(isContinueAllowed);
  }, [
    amountValue,
    amountErrorMessage,
    fulfillmentConditionType,
    onIsContinueAllowedChange,
  ]);

  // when fulfillment condition data changes, fire callback
  React.useEffect(() => {
    if (fulfillmentConditionType) {
      onFulfillmentDataChange({
        ...DEFAULT_FULFILLMENT_CONDITION_FIELDS,
        fulfillmentConditionType,
        fundingAmount:
          amountValue === '' ||
          fulfillmentConditionType === 'IRA_YEARLY_FUNDING'
            ? null
            : Number(amountValue),
      });
    }
  }, [amountValue, fulfillmentConditionType, onFulfillmentDataChange]);
  if (fulfillmentConditionType === 'IRA_YEARLY_FUNDING') {
    return null;
  }
  return (
    <Box>
      <Input
        autoComplete="off"
        onChange={onInputChange}
        value={amountValue}
        error={amountErrorMessage}
        label="Enter amount"
        maskType="money"
      />
    </Box>
  );
};
