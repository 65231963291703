import type { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import {
  LoanAutoPaySagaDocument,
  type LoanAutoPaySagaQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../../apolloQuerySaga';

export type LoanAutoPayQueryType = {
  autoPayId: string | null | undefined;
};

export function* loanAutoPayQuery(): SagaIterator<
  LoanAutoPayQueryType | null | undefined
> {
  const personalLoanAccountId: string = yield select(
    (state) => state.global.personalLoanAccountId,
  );
  if (personalLoanAccountId) {
    try {
      const { data }: LoanAutoPaySagaQueryResult = yield call(apolloQuerySaga, {
        query: LoanAutoPaySagaDocument,
        variables: {
          personalLoanAccountId,
        },
      });
      return {
        autoPayId:
          data?.loan?.__typename === 'PersonalLoan'
            ? data.loan.autoPayInstance?.id
            : null,
      };
    } catch (e: any) {
      throw new Error(`error fetchloanAutoPayQueryData: ${e}`);
    }
  }
  return {
    autoPayId: null,
  };
}
