import { type BoxProps, type Color, Flex, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

const StyledCloseButtonContainer = styled(Flex)`
  position: absolute;
  top: ${(props) => props.top ?? '8px'};
  right: ${(props) => props.right ?? '8px'};
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export type CloseButtonProps = {
  color: Color;
  onClick: () => void;
} & BoxProps;

export const CloseButton = ({ color, onClick, ...rest }: CloseButtonProps) => {
  return (
    <StyledCloseButtonContainer
      id="cover-close-button"
      data-testid="cover-close-button"
      onClick={onClick}
      {...rest}
    >
      <Icon aria-label="close" color={color} name="close24" />
    </StyledCloseButtonContainer>
  );
};
