import type { ToastProps } from '~/toolbox/toast';

export const TOAST_ACTIONS = Object.freeze({
  ADD_TOAST: 'ADD_TOAST' as const,
  CLEAR_TOAST: 'CLEAR_TOAST' as const,
});

export type AddToastAction = {
  payload: ToastProps;
  type: typeof TOAST_ACTIONS.ADD_TOAST;
};

export type ClearToastAction = {
  type: typeof TOAST_ACTIONS.CLEAR_TOAST;
};

export type ToastAction = AddToastAction | ClearToastAction;
