import { PXL, PM, Flex, Tooltip, Card } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledRadioGroup } from '~/components/form/ControlledRadioGroup';
import { PaymentAmountField } from '~/forms/CreatePaymentForm/PaymentAmountField';
import type {
  TransferAmountPresetOption,
  ScheduledTransferPresetOption,
  TransferParticipantRelationshipFragment,
} from '~/graphql/types';
import { Radio } from '~/toolbox/radio';
import { isNotNil, isNil } from '~/utils';
import { formatCurrency } from '~/utils/formatting';

type PaymentPresetsProps = {
  amountPills: Maybe<Maybe<{ label: string }>[]>;
  isSchedule: boolean;
  transferInstancePresets:
    | Maybe<TransferAmountPresetOption>[]
    | null
    | undefined;
  scheduledTransferPresets:
    | Maybe<ScheduledTransferPresetOption>[]
    | null
    | undefined;
  selectedRelationship: Maybe<TransferParticipantRelationshipFragment>;
};

export const PaymentPresets = ({
  amountPills,
  isSchedule,
  scheduledTransferPresets,
  selectedRelationship,
  transferInstancePresets,
}: PaymentPresetsProps) => {
  const { control, watch, setValue } = useFormContext();

  const selectedPreset = watch('selectedPreset');

  const presets = isSchedule
    ? scheduledTransferPresets
    : transferInstancePresets;

  // If a user selects a preset other than the fixed amount,
  // we clear the other amount field.
  const onSelect = React.useCallback(
    (value: string) => {
      if (value !== 'FIXED_AMOUNT') {
        setValue('otherAmount', undefined);
      }
    },
    [setValue],
  );

  if (isNil(presets)) {
    return null;
  }

  const validPresets = presets.filter((preset) => isNotNil(preset));

  const isOtherAmountDisabled = validPresets.every(
    (preset) => preset?.value === 0,
  );

  return (
    <Card label="Select amount" mt={16}>
      <ControlledRadioGroup
        style={{
          padding: '16px 0 64px 16px',
        }}
        name="selectedPreset"
        size="small"
        onChange={onSelect}
        control={control}
        rules={{
          required: true,
        }}
      >
        {validPresets.map((preset) => {
          const numericValue =
            typeof preset.value === 'string'
              ? parseFloat(preset.value)
              : (preset.value ?? 0);
          const disabled = numericValue <= 0;

          const paymentValue =
            typeof preset.value === 'number' ? preset.value.toFixed(2) : '0.00';

          const secondaryContent = isSchedule
            ? preset.value
            : formatCurrency(Number(paymentValue ?? 0));

          // Create a unique value in case multiple options of same value for one-time payments
          // Scheduled CC payments just use the indicator as the value
          const presetValue =
            preset.__typename === 'ScheduledTransferPresetOption'
              ? preset.indicator
              : `${String(preset.value)}-${String(preset.label)}`;

          return (
            <Radio.Choice
              key={preset.label}
              label={
                <>
                  <PXL
                    fontWeight={600}
                    display="inline-flex"
                    alignItems="center"
                    content={
                      <>
                        {preset.label}{' '}
                        {preset.description && (
                          <Tooltip
                            placement="bottom-start"
                            body={<PM content={preset.description} />}
                            icon="tooltip16"
                            iconColor="foregroundPrimary"
                          />
                        )}
                      </>
                    }
                    color={
                      disabled
                        ? 'foregroundNeutralSecondary'
                        : 'foregroundNeutralMain'
                    }
                  />
                  <PM
                    color={
                      disabled
                        ? 'foregroundNeutralSecondary'
                        : 'foregroundNeutralMain'
                    }
                    content={secondaryContent}
                  />
                </>
              }
              disabled={disabled}
              value={presetValue}
              checked={presetValue === selectedPreset}
            />
          );
        })}
        <Radio.Choice
          key="FIXED_AMOUNT"
          disabled={isOtherAmountDisabled}
          checked={selectedPreset === 'FIXED_AMOUNT'}
          label={
            <Flex inline alignItems="center" m="-16px 0 -8px 0" height={70}>
              <PXL
                fontWeight={600}
                content={isSchedule ? 'Fixed amount' : 'Other amount'}
                color={
                  isOtherAmountDisabled === true
                    ? 'foregroundNeutralSecondary'
                    : 'foregroundNeutralMain'
                }
                minWidth={130}
                mt={8}
              />
              <Flex height={60}>
                <PaymentAmountField
                  amountPills={amountPills}
                  selectedRelationship={selectedRelationship}
                  isRequired={selectedPreset === 'FIXED_AMOUNT'}
                  isDisabled={isOtherAmountDisabled === true}
                  onChange={(value) => {
                    if (
                      selectedPreset !== 'FIXED_AMOUNT' &&
                      isNotNil(value) &&
                      value !== ''
                    ) {
                      setValue('selectedPreset', 'FIXED_AMOUNT');
                    }
                  }}
                />
              </Flex>
            </Flex>
          }
          value="FIXED_AMOUNT"
        />
      </ControlledRadioGroup>
    </Card>
  );
};
