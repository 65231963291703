import * as React from 'react';

import { NewsList } from '~/components/news-list/NewsList';
import { useMarketNewsPaginationQuery } from '~/graphql/hooks';
import type { News } from '~/graphql/types';

import { MarketResearchEmptyStateCard } from './MarketResearchEmptyStateCard';

const DEFAULT_FIRST = 8;

export const MarketNews = () => {
  const { data, loading, error, fetchMore } = useMarketNewsPaginationQuery({
    variables: {
      first: DEFAULT_FIRST,
    },
  });

  const marketNews = data?.viewer.markets.marketNews;

  // if markets dependency changes, rebuild articles array
  const articles = React.useMemo(() => {
    const articles: Array<News> = [];
    const edges = marketNews?.edges;

    if (!edges) {
      return articles;
    }

    for (const articleSet of edges) {
      if (articleSet?.node) {
        articles.push(articleSet.node);
      }
    }

    return articles;
  }, [marketNews]);

  const handleClickLoadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        after: data?.viewer.markets.marketNews?.pageInfo.endCursor,
      },
    });
  }, [data, fetchMore]);

  if (loading) {
    return null;
  }

  if (error && !marketNews) {
    return <MarketResearchEmptyStateCard />;
  }

  const pageInfo = marketNews?.pageInfo;

  return (
    <NewsList
      hasNextPage={pageInfo?.hasNextPage || false}
      canFetchMore
      onLoadMoreClick={handleClickLoadMore}
      articles={articles}
      size="large"
    />
  );
};
