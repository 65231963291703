import { Banner } from '@m1/liquid-react/Banner';
import * as React from 'react';

import { IntercomLink } from '~/components/IntercomLink';
import type { ProfileUpdateBannerFlagsFragment } from '~/graphql/types';

type Props = {
  profile: ProfileUpdateBannerFlagsFragment;
};

function getProfileMessage(profile: ProfileUpdateBannerFlagsFragment) {
  if (profile.identityStatus === 'Rejected') {
    return `There is an issue with your account. Please contact support.`;
  }
  if (profile.allowsHomeAddressUpdates) {
    // User can update address in app.
    // Still need to contact support to change name or phone number.
    return 'Please contact support to change your name or phone number.';
  }
  // User cannot update address in app.
  // Contact support for all of these changes.
  return 'Please contact support to change your name, address, or phone number.';
}

export const ProfileUpdateBanner = ({ profile }: Props) => {
  const message = getProfileMessage(profile);

  return (
    <Banner
      action={<IntercomLink />}
      content={message}
      kind="information"
      mb={40}
    />
  );
};
