import { type Theme, Box, Flex, useTheme } from '@m1/liquid-react';
import * as React from 'react';
import { useSprings, animated, interpolate } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import { useDelay } from '~/hooks/useDelay';
import { type Slice, isPieTreeValid } from '~/pie-trees';
import { highlightSlice } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { GridTable } from '~/toolbox/grid-table';
import {
  generateSliceFillColorsArray,
  pickSliceFillColor,
} from '~/utils/slices';

import { PhantomLandingZone } from './PhantomLandingZone';
import { SelectAllCheckbox } from './SelectAllCheckbox';

export type RenderPropCallbackProps = {
  allowDrag: boolean;
};

type DraggableItemSlice = {
  id: string;
  isDisabled: boolean;
  isLandable: boolean;
  render: (props: RenderPropCallbackProps) => React.ReactNode;
  slice: Slice;
};

type DragAndDropSlicesProps = {
  allowDrag: boolean;
  items: Array<DraggableItemSlice>;
  onClickContent: (slice: Slice) => void;
  onLandableDrop: (arg0: {
    idOfDroppedItem: string;
    idOfLandableItem: string;
  }) => void;
};

type PositionDimensions = {
  end: number;
  start: number;
};

type DraggableRowProps = {
  allowDrag: boolean;
  bind: any;
  height: number;
  index: number;
  isDragging: boolean;
  isMouseDown: boolean;
  item: DraggableItemSlice;
  onClickContent: (slice: Slice) => void;
  sliceColor: string | null | undefined;
  zIndex: number;
};

const AnimatedGridTableRow = animated(GridTable.Row);

export const DRAGGABLE_ITEM_HEIGHT = 56;

const TABLE_HEADER_HEIGHT = 42;

// helper func to add a slight delay to allow our click event handler to be ignored when dragging
// this allows us to discern between a drag and a click
function setDraggingStateOnDelay(
  setIsDragging: (arg0: boolean) => void,
  flag: boolean,
) {
  window.setTimeout(() => setIsDragging(flag), 200);
}

export const DragAndDropSlices = ({
  allowDrag,
  items,
  onClickContent,
  onLandableDrop,
}: DragAndDropSlicesProps) => {
  const theme = useTheme();
  const disabledFeatures = useSelector(
    (state) => state.portfolioOrganizer.disabledFeatures,
  );
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false);
  const [showLandingZone, setShowLandingZone] = React.useState<boolean>(false);
  const [landableId, setLandableId] = React.useState<string | null | undefined>(
    null,
  );
  const [landablePositionDimensions, setLandablePositionDimensions] =
    React.useState<Array<PositionDimensions>>([]);

  // Each item needs a unique key to identify each child
  const itemIds = items.map((item) => item.id);

  React.useEffect(() => {
    const result = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].isLandable) {
        if (result.length) {
          // @ts-expect-error - TS7022 - 'priorEnd' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
          const priorEnd = result[result.length - 1].end;
          result.push({
            start: priorEnd,
            end: priorEnd + DRAGGABLE_ITEM_HEIGHT,
          });
        } else {
          result.push({
            start: i * DRAGGABLE_ITEM_HEIGHT,
            end: i * DRAGGABLE_ITEM_HEIGHT + DRAGGABLE_ITEM_HEIGHT,
          });
        }
      }
    }
    setLandablePositionDimensions(result);
  }, [items, items.length]);

  // Map Springs - Animates items as they are dragged
  const mapSprings = React.useMemo(() => {
    // @ts-expect-error - TS7006 - Parameter 'down' implicitly has an 'any' type. | TS7006 - Parameter 'activeKey' implicitly has an 'any' type. | TS7006 - Parameter 'activePos' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
    return (down, activeKey, activePos) => (index) => {
      const key = itemIds[index];

      // If the mouse is down and the current spring is selected and is not disabled, return
      // the below configuration that is fed into <animated.div />;
      // height: activePos keeps the element at the same height as the mouse position.
      if (down && key === activeKey && typeof activePos === 'number') {
        return {
          active: 'true',
          height: activePos,
          zIndex: 2,
          // @ts-expect-error - TS7006 - Parameter 'n' implicitly has an 'any' type.
          immediate: (n) => n === 'active' || n === 'height' || n === 'zIndex',
        };
      }

      // if the current item is landable, determine if active item has been dragged over it.
      // this is done by comparing our landable position with our vertical displacement
      if (items[index].isLandable && typeof activePos === 'number') {
        for (let i = 0; i < landablePositionDimensions.length; i++) {
          const verticalDisplacementOfDraggedItem =
            itemIds.indexOf(activeKey) * DRAGGABLE_ITEM_HEIGHT + activePos;

          // if we dragged the item into a landable zone and that zone is not within the item itself, enable our landing zone.
          // otherwise, clear our landing zone state
          if (
            verticalDisplacementOfDraggedItem >
              landablePositionDimensions[i].start &&
            verticalDisplacementOfDraggedItem <
              landablePositionDimensions[i].end &&
            items[i].id !== activeKey
          ) {
            setLandableId(items[i].id);
            setShowLandingZone(true);

            return {
              active: '',
              zIndex: 0,
            };
          }
        }

        setLandableId(null);
        setShowLandingZone(false);
      }
      return {
        active: '',
        height: 0,
        zIndex: 0,
      };
    };
  }, [itemIds, items, landablePositionDimensions]);

  // @ts-expect-error - TS2554 - Expected 3 arguments, but got 0. | TS2769 - No overload matches this call.
  const [springs, setSprings] = useSprings(items.length, mapSprings());
  const [isDragging, setIsDragging] = React.useState<boolean>(false);

  const bind = useGesture({
    onDrag({ args: [activeKey, index], down, movement }) {
      setDraggingStateOnDelay(setIsDragging, true);

      // if we've disabled drag or the current item is disabled, let's simply return
      if (!allowDrag || items[index].isDisabled) {
        return;
      }
      setIsMouseDown(down);
      // use-gesture provides a movement param that allows us to track the xy displacement of a dragged item
      const activePos = movement[1];
      // @ts-expect-error - TS2349 - This expression is not callable.
      setSprings(mapSprings(down, activeKey, activePos));
    },
    onDragEnd({ args: [activeKey] }) {
      setDraggingStateOnDelay(setIsDragging, false);

      if (showLandingZone && landableId) {
        onLandableDrop({
          idOfDroppedItem: activeKey,
          idOfLandableItem: landableId,
        });
        setShowLandingZone(false);
      }
    },
  });

  const landableIdIndex = items.findIndex((item) => item.id === landableId);
  const numOfLandables = React.useMemo(
    () =>
      items.reduce(
        // @ts-expect-error - TS2365 - Operator '+' cannot be applied to types 'number' and 'boolean'.
        (totalLandables, item) => totalLandables + item.isLandable,
        0,
      ),
    [items],
  );

  // if an landable id is found, set the the y positioning of the phantom backdrop to match it.
  // otherwise, set the y positioning to the very last landable item so that it's ready to fade in as the user drags.
  const verticalPositionOfLandingBackground =
    landableIdIndex !== -1
      ? landableIdIndex * DRAGGABLE_ITEM_HEIGHT + TABLE_HEADER_HEIGHT
      : (numOfLandables - 1) * DRAGGABLE_ITEM_HEIGHT + TABLE_HEADER_HEIGHT;

  const colors = generateSliceFillColorsArray(
    theme.pieSliceColors,
    // @ts-expect-error - TS2339 - Property 'length' does not exist on type 'ForwardedProps<CSSProperties>'.
    springs.length,
  );

  const hideCheckbox =
    disabledFeatures?.includes('REMOVE_SLICES') &&
    disabledFeatures?.includes('MOVE_SLICES');

  return (
    <Box
      maxHeight={500}
      style={{
        overflow: 'auto',
      }}
    >
      <PhantomLandingZone
        height={DRAGGABLE_ITEM_HEIGHT}
        showLandingZone={showLandingZone}
        verticalPositionOfLandingBackground={
          verticalPositionOfLandingBackground
        }
      />
      <GridTable
        emptyMessage="Slices will appear here"
        gridTemplateColumns="70% auto"
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell
            label={hideCheckbox ? null : <SelectAllCheckbox />}
          />
          <Flex
            display="inline-flex"
            justifyContent="flex-end"
            pr={allowDrag ? 70 : 40}
          >
            <GridTable.HeaderCell align="right" label="Target (%)" />
          </Flex>
        </GridTable.HeaderRow>

        {/* @ts-expect-error - TS2339 - Property 'map' does not exist on type 'ForwardedProps<CSSProperties>'. | TS7031 - Binding element 'height' implicitly has an 'any' type. | TS7031 - Binding element 'zIndex' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type. */}
        {springs.map(({ height, zIndex }, index) => {
          const rowProps = {
            allowDrag,
            bind,
            height,
            isDragging,
            isMouseDown,
            index,
            onClickContent,
            sliceColor: pickSliceFillColor(colors, index, false),
            zIndex,
          };
          return <DraggableRow {...rowProps} item={items[index]} key={index} />;
        })}
      </GridTable>
    </Box>
  );
};

const getRowColorProps = (
  slice: Slice,
  theme: Theme,
):
  | {
      backgroundColor?: string;
      color?: string;
    }
  | null
  | undefined => {
  const sliceIsPieAndIsInvalid =
    (slice.to.type === 'new_pie' || slice.to.type === 'old_pie') &&
    !isPieTreeValid(slice.to);

  const sliceIsSecurityAndIsHighlighted =
    slice.to.type === 'security' && slice.to.__highlighted__;

  let colorProps = null;
  if (sliceIsPieAndIsInvalid) {
    colorProps = {
      backgroundColor: theme.colors.backgroundWarningSubtle,
      color: theme.colors.warning,
    };
  } else if (sliceIsSecurityAndIsHighlighted) {
    colorProps = {
      backgroundColor: theme.colors.backgroundPrimarySubtle,
    };
  }

  return colorProps;
};

const DraggableRow = ({
  allowDrag,
  bind,
  height,
  index,
  isDragging,
  isMouseDown,
  item,
  onClickContent,
  sliceColor,
  zIndex,
}: DraggableRowProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { highlightedSliceId: highlightedSlice } = useSelector(
    (state) => state.interface,
  );

  const [fade, setFade] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  const { id, render, slice } = item;

  const rowColorProps = getRowColorProps(slice, theme);
  const hasBackgroundColor = rowColorProps?.backgroundColor;

  const onDelayCallback = React.useCallback(() => {
    if (hasBackgroundColor && !fade) {
      setFade(true);
    }
  }, [fade, hasBackgroundColor]);

  useDelay(onDelayCallback, 2000);

  const hoveringSlice = isHover || slice.to.__id === highlightedSlice;

  const backgroundColor = React.useMemo(() => {
    if (hoveringSlice) {
      return theme.colors.backgroundNeutralTertiary;
    }

    if (hasBackgroundColor && !fade) {
      return rowColorProps?.backgroundColor;
    }

    return theme.colors.backgroundNeutralSecondary;
  }, [
    fade,
    hasBackgroundColor,
    hoveringSlice,
    rowColorProps?.backgroundColor,
    theme,
  ]);

  return (
    <AnimatedGridTableRow
      {...bind(id, index)}
      gridTemplateColumns="minmax(0, 1fr) auto"
      key={id}
      onClick={() => {
        if (onClickContent && !isDragging) {
          onClickContent(slice);
        }
      }} // This is a stop-gap solution for a Firefox bug with react-use-gesture.
      // It is a known issue that should be resolved in version 8.
      // See, https://github.com/pmndrs/react-use-gesture/issues/188
      // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
      onDragStart={(e) => e.preventDefault()}
      onTouchStart={() => dispatch(highlightSlice(slice.to.__id))}
      // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
      onTouchEnd={() => dispatch(highlightSlice())}
      onMouseEnter={() => {
        setIsHover(true);
        dispatch(highlightSlice(slice.to.__id));
      }}
      onMouseLeave={() => {
        setIsHover(false);
        // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
        dispatch(highlightSlice());
      }}
      style={{
        borderLeft: sliceColor ? `4px solid ${sliceColor}` : null,
        backgroundColor,
        cursor: isMouseDown ? 'grabbing' : 'pointer',
        height: 56,
        position: 'relative',
        transform: interpolate([height], (y) => `translate3d(0, ${y}px, 0)`),
        userSelect: 'none',
        transition: 'background-color 800ms ease',
        zIndex,
      }}
    >
      {render({
        allowDrag,
      })}
    </AnimatedGridTableRow>
  );
};
