import React from 'react';

import type { ChartableSliceChartRangeSelectionChange } from '~/components/ChartableSliceChart/ChartableSliceChart.types';
import type {
  PortfolioDetailsSliceQuery,
  PortfolioDetailsSliceQueryVariables,
  PortfolioSlicePerformancePeriodEnum,
  SortDirectionEnum,
} from '~/graphql/types';
import type { PortfolioSliceSortKey } from '~/portfolio-slice';

import type { PortfolioSliceSorter } from './PortfolioDetailsPage.utils';

export type PortfolioDetailsContextType = {
  sorter: PortfolioSliceSorter;
  account: ExtractTypename<PortfolioDetailsSliceQuery['account'], 'Account'>;
  portfolioSlice: Extract<
    PortfolioDetailsSliceQuery['portfolioSlice'],
    { id: string; description?: string | null }
  >;
  viewer: PortfolioDetailsSliceQuery['viewer'];
  isCrypto: boolean;
  memoizedGenerateColorsForPie: Map<
    string,
    {
      activeColor: string;
      inactiveColor: string;
    }
  >;
  hasMarginCallDialogOnMount: boolean;
  hasUserDismissedMarginCallDialog: boolean;
  queryVariables: PortfolioDetailsSliceQueryVariables | null | undefined;
  sort: SortState;
  chartRange: ChartableSliceChartRangeSelectionChange | null;
  chartPeriod: PortfolioSlicePerformancePeriodEnum;
  onChangeSort: (sortKey: PortfolioSliceSortKey) => void;
  onChangePeriod: (period: PortfolioSlicePerformancePeriodEnum) => void;
  onChangeChartRange: (change: ChartableSliceChartRangeSelectionChange) => void;
};

export type SortState = {
  sortDirection: SortDirectionEnum;
  sortKey: PortfolioSliceSortKey;
}[];

export const PortfolioDetailsContext =
  React.createContext<PortfolioDetailsContextType>(
    {} as PortfolioDetailsContextType,
  );
