import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import type { TotalNetWorthEnum } from '~/graphql/types';

import { getLoggers } from '../../common';

export function* logTotalNetWorthAnalytics(
  totalNetWorth: TotalNetWorthEnum,
): SagaIterator {
  const { analytics } = yield call(getLoggers);

  switch (totalNetWorth) {
    case 'UP_TO_INFINITY':
      analytics.mutation('account', 'initialTotalNetWorthOver5m');

    // falls through
    case 'UP_TO_5000000':
      analytics.mutation('account', 'initialTotalNetWorthOver1m');

    // falls through
    case 'UP_TO_1000000':
      analytics.mutation('account', 'initialTotalNetWorthOver500k');

    // falls through
    case 'UP_TO_500000':
      analytics.mutation('account', 'initialTotalNetWorthOver200k');

    // falls through
    case 'UP_TO_200000':
      analytics.mutation('account', 'initialTotalNetWorthOver100k');

    // falls through
    case 'UP_TO_100000':
      analytics.mutation('account', 'initialTotalNetWorthOver50k');
      break;
    default:
      break;
  }
}
