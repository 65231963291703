import type {
  IntradayQuotePeriodEnum,
  HistoricalQuotePeriodEnum,
} from '~/graphql/types';

export const SECURITY_DETAIL_ACTIONS = Object.freeze({
  CLEAR_SECURITY_DATE_RANGE: 'CLEAR_SECURITY_DATE_RANGE' as const,
  CLEAR_SECURITY_SNAPSHOT: 'CLEAR_SECURITY_SNAPSHOT' as const,
  SET_SECURITY_QUOTE_PERIOD: 'SET_SECURITY_QUOTE_PERIOD' as const,
  SET_SECURITY_DATE_RANGE: 'SET_SECURITY_DATE_RANGE' as const,
  SET_SECURITY_SNAPSHOT: 'SET_SECURITY_SNAPSHOT' as const,
});

export type ClearSecurityDateRange = {
  type: typeof SECURITY_DETAIL_ACTIONS.CLEAR_SECURITY_DATE_RANGE;
};

export type ClearSecuritySnapshotAction = {
  type: typeof SECURITY_DETAIL_ACTIONS.CLEAR_SECURITY_SNAPSHOT;
};

export type SetSecurityDateRange = {
  payload: {
    first: string | null | undefined;
    last: string | null | undefined;
  };
  type: typeof SECURITY_DETAIL_ACTIONS.SET_SECURITY_DATE_RANGE;
};

export type SetSecurityQuotePeriod = {
  payload: IntradayQuotePeriodEnum | HistoricalQuotePeriodEnum;
  type: typeof SECURITY_DETAIL_ACTIONS.SET_SECURITY_QUOTE_PERIOD;
};

export type SetSecuritySnapshotAction = {
  payload: {
    date?: string;
    percentChange: number | string;
    shareVolume?: number | null | undefined;
    value?: number | string;
    valueChange: number;
  };
  type: typeof SECURITY_DETAIL_ACTIONS.SET_SECURITY_SNAPSHOT;
};

export const clearSecurityDateRange = (): ClearSecurityDateRange => ({
  type: SECURITY_DETAIL_ACTIONS.CLEAR_SECURITY_DATE_RANGE,
});

export const clearSecuritySnapshot = (): ClearSecuritySnapshotAction => ({
  type: SECURITY_DETAIL_ACTIONS.CLEAR_SECURITY_SNAPSHOT,
});

export const setSecurityDateRange = (
  payload: SetSecurityDateRange['payload'],
): SetSecurityDateRange => ({
  type: SECURITY_DETAIL_ACTIONS.SET_SECURITY_DATE_RANGE,
  payload: payload,
});

export const setSecurityQuotePeriod = (
  payload: SetSecurityQuotePeriod['payload'],
): SetSecurityQuotePeriod => ({
  type: SECURITY_DETAIL_ACTIONS.SET_SECURITY_QUOTE_PERIOD,
  payload: payload,
});

export const setSecuritySnapshot = (
  payload: SetSecuritySnapshotAction['payload'],
): SetSecuritySnapshotAction => ({
  type: SECURITY_DETAIL_ACTIONS.SET_SECURITY_SNAPSHOT,
  payload: payload,
});

export type SecurityDetailsAction =
  | ClearSecurityDateRange
  | ClearSecuritySnapshotAction
  | SetSecurityDateRange
  | SetSecurityQuotePeriod
  | SetSecuritySnapshotAction;
