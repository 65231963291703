import { Button, HXS, Modal, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import type { PortfolioSliceRebalanceLockReasonEnum } from '~/graphql/types';

import { useModal } from '~/hooks/useModal';

const lockReasonErrorMap: Record<
  PortfolioSliceRebalanceLockReasonEnum,
  string
> = {
  LOCKED_BY_ALLOCATION: 'Rebalancing is unavailable until your pie is funded.',
  LOCKED_BY_ORDER:
    'Rebalancing is unavailable while there are pending orders. To rebalance, you must first cancel all orders.',
  LOCKED_BY_TRADING:
    'Trading for your account is currently in progress. Please try again after the trade window.',
  LOCKED_BY_LIQUIDATION:
    'Rebalancing is unavailable while your account is liquidating.',
  UNLOCKED: 'Rebalancing is currently unavailable. Please try again later.',
};

export const RebalanceLockedModal = () => {
  const { hideModal, modalState } = useModal('REBALANCE_ACTION_LOCKED');

  const handleClose = (): void => {
    hideModal();
  };

  const lockReason = modalState.payload?.rebalanceStatus?.lockReason;
  const lockedMessage = lockReason
    ? lockReasonErrorMap[lockReason]
    : 'Rebalancing is currently unavailable. Please try again later.';

  return (
    <Modal open={modalState.isOpened} onClose={handleClose}>
      <ModalContent>
        <HXS content="Rebalancing unavailable" />
        <PL content={lockedMessage} mt={8} />
        <Button
          kind="primary"
          label="Ok"
          mt={32}
          onClick={handleClose}
          size="medium"
        />
      </ModalContent>
    </Modal>
  );
};
