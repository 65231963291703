import { Box, PL, PM } from '@m1/liquid-react';
import * as React from 'react';

import type { TransferDetailsMessageTooltipBodyFragment } from '~/graphql/types';

type TransferDetailsMessageTooltipBodyProps = {
  tooltipBody:
    | (TransferDetailsMessageTooltipBodyFragment | null | undefined)[]
    | null
    | undefined;
};

export const TransferDetailsMessageTooltipBody = ({
  tooltipBody,
}: TransferDetailsMessageTooltipBodyProps) => {
  if (tooltipBody) {
    return (
      <Box>
        {tooltipBody?.map((tooltip) => (
          <Box mb={16} key={tooltip?.title}>
            <PL fontWeight={600} mb={4}>
              {tooltip?.title}
            </PL>
            <PM color="foregroundNeutralSecondary">{tooltip?.subtitle}</PM>
            {tooltip?.numberedList?.map((listItem) => (
              <PM color="foregroundNeutralSecondary" key={listItem}>
                {listItem}
              </PM>
            ))}
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};
