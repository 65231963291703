import {
  type DismissNotificationsAction,
  type DisplayErrorNotificationAction,
  type DisplayNotificationAction,
  type DisplayNotificationLocation,
  type DisplaySuccessNotificationAction,
  NOTIFICATION_ACTIONS,
} from './notificationActions.types';

export const displaySuccessNotification = (
  payload: DisplaySuccessNotificationAction['payload'],
): DisplaySuccessNotificationAction => ({
  type: 'DISPLAY_SUCCESS_NOTIFICATION',
  payload: payload,
});

export const displayErrorNotification = (
  payload: DisplayErrorNotificationAction['payload'],
): DisplayErrorNotificationAction => ({
  type: NOTIFICATION_ACTIONS.DISPLAY_ERROR_NOTIFICATION,
  payload: payload,
});

export function displayInlineErrorNotification({
  message = 'Something went wrong with your request. Please try again later or contact support.',
  location,
}: {
  location: DisplayNotificationLocation;
  message: string;
}): DisplayNotificationAction {
  return {
    type: NOTIFICATION_ACTIONS.DISPLAY_NOTIFICATION,
    payload: {
      type: 'ERROR_ALT',
      location,
      message,
    },
  };
}

export const displayNotification = (
  payload: DisplayNotificationAction['payload'],
): DisplayNotificationAction => ({
  type: NOTIFICATION_ACTIONS.DISPLAY_NOTIFICATION,
  payload: payload,
});

export const dismissNotifications = (
  payload?: DismissNotificationsAction['payload'],
): DismissNotificationsAction => ({
  type: NOTIFICATION_ACTIONS.DISMISS_NOTIFICATIONS,
  payload,
});
