import { Box, PS, PXL } from '@m1/liquid-react';
import React from 'react';

import type { AcatTransferInstanceDetailsExplainer } from '~/graphql/types';
import { List } from '~/toolbox/list';

type AcatNextStepsContainerProps = {
  nextSteps: AcatTransferInstanceDetailsExplainer;
};

export const AcatNextStepsContainer = ({
  nextSteps,
}: AcatNextStepsContainerProps) => {
  return (
    <Box>
      <PXL fontWeight={600} content={nextSteps.title} mb={8} />
      {nextSteps.steps && (
        <List>
          {nextSteps.steps.map((step, i) => (
            <List.Item
              key={`next-step-${i}`}
              content={<PS>{step}</PS>}
              mb={4}
            />
          ))}
        </List>
      )}
    </Box>
  );
};
