import type { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  SearchSliceablesSagaDocument,
  type SearchSliceablesSagaQueryResult,
} from '~/graphql/hooks';
import type {
  SearchSliceablesSagaQuery,
  SearchSliceablesSagaQueryVariables,
} from '~/graphql/types';
import {
  ACTION_TYPES,
  type FinishedSearchSliceables,
  clickedOnSliceable,
} from '~/redux/actions';

import { apolloQuerySaga } from './apolloQuerySaga';

export function* searchSliceablesSaga(): SagaIterator {
  yield takeEvery(ACTION_TYPES.FINISHED_SEARCH_SLICEABLES, handleFinished);
}

type SearchedSliceableEdge = NonNullable<
  SearchSliceablesSagaQuery['viewer']['searchSliceables']['edges']
>[number];

function* handleFinished(action: FinishedSearchSliceables): SagaIterator {
  const { data }: SearchSliceablesSagaQueryResult = yield call(
    apolloQuerySaga,
    {
      query: SearchSliceablesSagaDocument,
      variables: {
        query: action.payload.query,
      } satisfies SearchSliceablesSagaQueryVariables,
      fetchPolicy: 'network-only',
    },
  );

  const sliceableEdges = (data?.viewer?.searchSliceables?.edges ??
    []) as SearchedSliceableEdge[];
  if (sliceableEdges.length === 0) {
    return;
  }

  const firstSliceableNode = sliceableEdges[0]?.node ?? null;
  if (sliceableEdges.length === 1 && firstSliceableNode !== null) {
    yield put(clickedOnSliceable(firstSliceableNode.id));
  } else {
    const matchingSliceableNode = findExactSymbolMatch(sliceableEdges);
    if (matchingSliceableNode !== null) {
      yield put(clickedOnSliceable(matchingSliceableNode.id));
    }
  }
}

type SearchedSliceableHiglights =
  NonNullable<SearchedSliceableEdge>['highlights'];

type SearchedSliceableNode = NonNullable<SearchedSliceableEdge>['node'];

/**
 * Iterates through the specified sliceable edges and tries to find the
 * exact symbol match that corresponds with the current query value. If
 * the edge is found, return the associated node.
 */
function findExactSymbolMatch(
  sliceableEdges: SearchedSliceableEdge[],
): SearchedSliceableNode | null {
  for (const sliceableEdge of sliceableEdges) {
    const highlights: SearchedSliceableHiglights =
      sliceableEdge?.highlights ?? [];

    const highlightsMatch = highlights.some(
      ({ match, snippet }) =>
        // Match on the _symbol_ (not the _name_):
        match === 'SYMBOL' &&
        // Symbol is child text in em element: `<em>{symbol}</em>`:
        snippet.startsWith('<em>') &&
        snippet.endsWith('</em>'),
    );
    if (highlightsMatch) {
      return sliceableEdge?.node ?? null;
    }
  }

  return null;
}
