import { Box, Flex, LM, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { TruncateStringContent } from '~/components/TruncateStringContent';
import type { EditModelSlice } from '~/pages/share/SharePiePage.types';
import type { AppState } from '~/redux';
import { highlightSlice } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { useSelector } from '~/redux/hooks/useSelector';

const StyledContainer = styled(Flex)<{
  isHighlighted: boolean;
}>`
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: ${({ isHighlighted, theme }) =>
    isHighlighted ? theme.colors.backgroundNeutralTertiary : 'transparent'};

  &:hover {
    cursor: pointer;
    background-color: var(--colors-background-neutral-tertiary);
  }
`;

const StyledNameBox = styled(Box)`
  min-width: 0;
  &:hover,
  &:hover * {
    cursor: pointer;
  }
`;

export type SharePiePageUserPieSliceContentProps = {
  onClick?: (sliceId: string) => void;
  slice: EditModelSlice;
};

export const SharePiePageUserPieSliceContent = ({
  onClick,
  slice,
}: SharePiePageUserPieSliceContentProps) => {
  const dispatch = useDispatch();
  const highlightedSlice = useSelector(
    (state: AppState) => state.interface.highlightedSliceId,
  );

  const sliceable = slice.to;
  const name = sliceable?.name;

  const handleEnter = (): void => dispatch(highlightSlice(sliceable.id));
  const handleExit = (): void => dispatch(highlightSlice(null));

  return (
    <StyledContainer
      isHighlighted={Boolean(
        highlightedSlice && highlightedSlice === sliceable.id,
      )}
      onClick={onClick ? () => onClick(slice.to.id) : undefined}
      onTouchStart={handleEnter}
      onTouchEnd={handleExit}
      onMouseEnter={handleEnter}
      onMouseLeave={handleExit}
      width="100%"
    >
      <Flex alignItems="center" minWidth={0}>
        <Icon
          backgroundColor="backgroundNeutralMain"
          borderRadius="20%"
          name="myPies32"
          mr={12}
        />
        <StyledNameBox>
          <TruncateStringContent>
            <LM content={name} />
          </TruncateStringContent>
        </StyledNameBox>
      </Flex>
      <Flex justifyContent="flex-end" pl={8}>
        <LM content={`${slice.percentage}%`} />
      </Flex>
    </StyledContainer>
  );
};
